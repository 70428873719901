import React from "react";
import SalarieNavBar from '../../SalarieGlobal/NavBar/SalarieNavBar.jsx';
import SalarieCard from '../../SalarieGlobal/Card/SalarieCard.jsx';

import NavBarGlobal from "../../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import ContratSection from "./ContratSection.jsx";
import styles from "./ContratSection.module.css";


export const Contrat = () => {


  return (
    
    <div>

    <NavBarGlobal section = {<ContratSection />} />
   
    </div>



)}
export default Contrat;