import React, { useState, useEffect } from 'react';
import APIService from '../../../APIService';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import logosolway from "./../../img/newLogoSolway.png";
import Styles from './Login.module.css';
import solwayGroup from '../../img/solwayGroup.png';
import solwayGroup2 from '../../img/solwayGroup_2.png';

import faviconsolway from '../../img/faviconsolway.png';
import Alert from '../../GlobalComponents/alert/Alert';
import LoadingPopup from './../../GlobalComponents/loadingPopup/LoadingPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/CompenentGlobale/CompenentButton/Button';
import "./../../../App.css";
import { MdSubdirectoryArrowRight } from "react-icons/md";

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState(false);
    const [validerAdmin, setValiderAdmin] = useState(null);
    const [usernameInvalid, setUsernameInvalid] = useState(false);
    const [idEmp, setIdEmp] = useState(null);
    const [click, setClick] = useState(false);
    const [token, setToken] = useCookies(['mytoken', 'username']);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token['mytoken'] || token['mytoken'] === 'undefined') {
            navigate('/');
            localStorage.clear();
        } else {
            APIService.getUsers(token['mytoken'])
                .then(resp => resp.filter(resp => resp.username === username).map(resp => setToken("idCurrentEmp", resp.id_employe)))
                .then(() => navigate('/accueil'));
        }
    }, [token]);

    const loginBtn = (e) => {
        e.preventDefault();
        if (username && password) {
            APIService.LoginUser({ username, password })
                .then(resp => {
                    setToken('mytoken', resp.token);
                })
                .catch(error => console.log(error));

            if (token['mytoken'] === 'undefined') {
                localStorage.removeItem("mytoken");
            }

            setToken('username', username);
        } else {
            setAlert(true);
        }
    };

    const [isHovered, setIsHovered] = useState(false);
    const [isIn, setIsIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        let timeoutId;
        if (isIn) {
            clearTimeout(timeoutId);
        } else if (isIn === false) {
            timeoutId = setTimeout(() => {
                setIsHovered(false);
            }, 15000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isIn]);

    return (
        <> 
        <div className={Styles.classDivGlobal} id={Styles.web}>
            <div className={Styles.leftDiv}>
                <div className={Styles.leftRectangle}>
                    <div className={Styles.logoDiv}>
                        <div id={Styles.logoSolwayHrContainer}>
                            <img className={Styles.solwayGroupLogo} src={faviconsolway} /> 
                        </div>
                        <span id={Styles.textTitleCard}>SOLWAY HR - Une gestion complète et efficace de votre écosystème RH</span>

                    </div>
                    <div className={Styles.discDiv} >
                        <span className={Styles.discSpan} style={{fontFamily: "Cambria"}}>
                        <p style={{fontFamily: "Cambria"}}>SOLWAY HR est un système de gestion des ressources humaines avancé, qui exploite la puissance de l'intelligence artificielle pour simplifier les tâches et optimiser les processus RH. Notre outil offre une gestion complète et efficace de votre écosystème RH, incluant :</p>
                        <ul style={{fontFamily: "Cambria"}}>
                            <li style={{fontFamily: "Cambria"}}>Le processus de recrutement</li>
                            <li style={{fontFamily: "Cambria"}}>L'embauche</li>
                            <li style={{fontFamily: "Cambria"}}>La gestion des employés</li>
                            <li style={{fontFamily: "Cambria"}}>Le suivi du temps de travail</li>
                            <li style={{fontFamily: "Cambria"}}>La paie</li>
                            <li style={{fontFamily: "Cambria"}}>Les entretiens annuels</li>
                            <li style={{fontFamily: "Cambria"}}>Et bien plus encore.</li>
                        </ul>
                        <p style={{fontFamily: "Cambria"}}>Avec SOLWAY HR, bénéficiez d'une gestion RH optimale et concentrez-vous sur l'essentiel : le développement de votre entreprise.</p>

                         </span>
                    </div>
                    <a href="https://youtu.be/jo4wcRrbMr4?si=NO8tHC71Hj7oJFTh" style={{color:"black",fontSize:"12px"}}>Voir plus</a>

                </div>
          
            </div>
            <div className={Styles.rightDiv}>
                <div className={Styles.headerDiv}>
                    <span className={Styles.headerLogin}>
                        {/* <img id={Styles.logoConnect} src={faviconsolway} alt="" /> */}
                        <img id={Styles.logoConnect} src={solwayGroup2} alt="" />

                        <br />
                        {/* Bienvenue <br /> */}
                        <span id={Styles.idTextConnect}>Connectez-vous</span>
                        
                    </span>
                </div>
                <div className={Styles.formContainer}>
                    <form className={Styles.formStyle} onSubmit={loginBtn}>
                        <div className={Styles.inputsDiv}>
                            <div className={Styles.inputContainer}>
                            <i class="fa-regular fa-user"></i>
            <input type="text" 
                  value = {username} 
                  onChange={e =>  setUsername(e.target.value.toString().toLowerCase())} 
                  placeholder={("Nom d'utilisateur")}/>
                            </div>
                            {/* <div className={Styles.inputContainer}>
                                {showPassword ? (
                                    <FontAwesomeIcon icon={faEye} onClick={toggleShowPassword} />
                                ) : (
                                    <FontAwesomeIcon icon={faEyeSlash} onClick={toggleShowPassword} />
                                )}
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Mot de passe" />
                            </div> */}

<div class={Styles.inputContainer}>
            {/* <i class="fa-solid fa-lock"></i> */}
            {
            showPassword ?
            <i class="fa-regular fa-eye" onClick={toggleShowPassword}></i>
            :
            <i class="fa-regular fa-eye-slash" onClick={toggleShowPassword}></i>

            }
            <input 
            type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={("Mot de passe")}/>
        </div>
                            <div className={Styles.reinitialisationDiv}>
                                <a onClick={() => navigate('/reset')}>Réinitialisation de mot de passe</a>
                            </div>
                        </div>
                        <div className={Styles.alertsDiv}>
                            {validerAdmin === false && click ?
                                <Alert type="danger" text="Votre compte n'a pas encore été validé par l'administrateur." bottomMsg="" handleCloseClick={() => setValiderAdmin(null)} />
                                : null}
                            {usernameInvalid ?
                                <Alert type="danger" text="Le mot de passe ou le nom d'utilisateur est incorrect" bottomMsg="" handleCloseClick={() => setUsernameInvalid(false)} />
                                : null}
                            {alert ?
                                <Alert type="danger" text="Veuillez remplir tous les champs." bottomMsg="" handleCloseClick={() => setAlert(false)} />
                                : null}
                            {isLoading && <LoadingPopup />}
                        </div>
                        <div className={Styles.loginButtonDiv}>
                            <button type="submit">
                                Se connecter
                            </button>
                        </div>
                    </form>
                </div>
                <div className={Styles.signUpDiv}>
                    <span>Vous n’avez pas de compte ? <a onClick={() => navigate('/register')}>S'inscrire</a></span>
                </div>
                <div className={Styles.signUpDiv}>
                <span>Pour plus d'informations,   <a href='https://www.solway-cs.com/contact/' style={{textDecoration:"none"}}> contactez-nous</a></span>

                
                </div>
            </div>
        </div>

        {/* // mobile */}
        <div className={Styles.classDivGlobalMobile}  id={Styles.mobile}>

    <div className={Styles.topDiv}>
    <div className={Styles.logoMobileDiv}>
      <img className={Styles.solwayGroupLogoMobile}  src={solwayGroup}/>
    </div>
    </div>

    <div className={Styles.bottomDiv}>
    <div className={Styles.descDivMobile}>
    <spam className={Styles.discSpamMobile}>
    SOLWAY HR : système RH avancé utilisant l'IA pour simplifier les tâches et optimiser les processus. Gestion complète de l'embauche, des employés, du temps de travail, de la paie et des entretiens annuels.
    </spam>
    </div>
    <a href="https://youtu.be/jo4wcRrbMr4?si=NO8tHC71Hj7oJFTh" style={{color:"black", fontWeight:"bold",marginBottom:"12px"}}>Voir plus</a>

    <div className={Styles.formMobileDiv}>
    <div className={Styles.heaerDivMobile}>
      <spam className={Styles.headerLoginMobile}>
      {/* <img id={Styles.logoConnectMobile} src={solwayGroup2} alt="" /> */}
      <img id={Styles.logoConnectMobile} src={faviconsolway} /> 


<br />
<span id={Styles.idTextConnectMobile}>Connectez-vous</span>
      </spam>
    </div>

    <div className={Styles.formContainerMobile}>
    <form className={Styles.formStyleMobile}  onSubmit = {e => loginBtn(e)}>
    <div className={Styles.inputsDivMobile}>
        <div class={Styles.inputContainerMobile}>
            <i class="fa-regular fa-user"></i>
            <input type="text" 
                  value = {username} 
                  onChange={e =>  setUsername(e.target.value.toString().toLowerCase())} 
                  placeholder="Nom d'utilisateur"/>
        </div>

        <div class={Styles.inputContainerMobile}>
            {/* <i class="fa-solid fa-lock"></i> */}
            {
            showPassword ?
            <i class="fa-regular fa-eye" onClick={toggleShowPassword}></i>
            :
            <i class="fa-regular fa-eye-slash" onClick={toggleShowPassword}></i>

            }
            <input 
            type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Mot de passe"/>
        </div>
        <div className={Styles.reinitialisationDivMobile}>
          <a onClick={() => navigate('/reset')}>Réinitialisation de mot de passe</a>
        </div>
    </div>

    <div className={Styles.alertsDivMobile}>

    {validerAdmin === false && click ? 
                 <Alert type = "danger" text = "Votre compte n'a pas encore été validé par l'administrateur. " bottomMsg = "" handleCloseClick={() => setValiderAdmin(null)}/>

                :<>
                
                </>}

                    
                    {usernameInvalid ?
                 <Alert type = "danger" text = "Le mot de passe ou le nom d'utilisateur est incorrect " bottomMsg = "" handleCloseClick={() => setUsernameInvalid(false)}/>

                 :<>
                
                </>}

                {alert ? 
               <Alert type = "danger" text = "Veuillez remplir tous les champs." bottomMsg = "" handleCloseClick={() => setAlert(false)}/>

                 : <div></div>}

                {
                        isLoading
                        &&
                        <LoadingPopup />

                      }

    </div>


    <div className={Styles.loginButtonDivMobile}>
      <button type="submit" onClick={e => loginBtn(e)}>
          Se connecter
      </button>
    </div>

    </form>
    </div>

    <div className={Styles.signUpDivMobile}>
      <span>Vous n’avez pas de compte ? <a onClick={() => navigate('/register')}>S'inscrire</a></span>
    </div>

    <div className={Styles.signUpDivMobile}>
                <a href='https://www.solway-cs.com/contact/' style={{textDecoration:"none"}}> <MdSubdirectoryArrowRight size={15}/>contactez-nous</a>

                
                </div>
    </div>



    </div>
    </div>
    </>
    );
}

export default Login;