import React from "react";
import styles from './DateComponent.module.css'

function DateComponent(props) {

  return (
    
     <span className={styles.inputgroup}>
        <label for="inputEmail4 " className={styles.label} > {props.text} </label>
        <input type="date"  placeholder="Select date" value={props.date} onChange={props.onChange} className={styles.test}/>
     </span>
  )
}

export default DateComponent