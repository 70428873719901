
import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import NombreLignesAdministrationSection from "./NombreLignesAdministrationSection.jsx";
// import NombreLingnesAdministrationSection from './NombreLingnesAdministrationSection.jsx';
// import NombreLingnesAdministrationSection from "./NombreLingnesAdministrationSection.jsx";


export const NombreLignesAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<NombreLignesAdministrationSection />} />
    
   
 </div>
)}


export default NombreLignesAdministration


