import React from "react";
import styles from "./AdminSection.module.css";
import AdminSection from "./AdminSection.jsx";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";


function Admin(){
  return(
    <div>
      <NavBarGlobal section = {<AdminSection />} />
    </div>
  )
}

export default Admin;