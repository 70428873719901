import React from 'react';
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../APIService.js';
import styles from './AdminSection.module.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import EmbaucheNavBar from './../EmbaucheGlobal/EmbaucheNavBar.jsx';
import Modal from '../../Modal/Modal.js';
import moment from 'moment';
import 'moment/locale/fr' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import NavBarGlobal from '../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx';
import PopupComponent from '../PopupComponent.jsx';

moment.locale('fr')

function AdminSection() {
    const [classTopSection, setClassTopSection] = useState(styles.une);
  const [show, setShow] = useState(false);
  // test popup
  const [showtest,setShowtest]=useState(false);
    const [etatModalPopup, setEtatModalPopup] = useState(false)
    const [token, setToken, removeToken] = useCookies(['mytoken', 'username', 'matriculeManager', 'matriculeEmployeAttente', 'matriculeActuel'])

    // Calendrier
    
    const listMois = {1: "Janvier", 2: "Février", 3: "Mars", 4: "Avril", 5: "Mai", 6: "Juin", 7: "Juillet", 8: "Août", 9: "Septembre", 10: "Octobre", 11: "Novembre", 12: "Décembre"}  
    const listJours = {1:"Lun", 2:"Mar", 3:"Mer", 4:"Jeu", 5:"Ven", 6:"Sam", 0:"Dim"}
    const yearAct = new Date().getFullYear();
    const monthAct = new Date().getMonth() + 1;
    // var allDays = {1:0, 2:0, 3:0, 4:0, 5:0, 6:0, 7:0, 8:0, 9:0, 10:0, 11:0, 12:0, 13:0, 14:0, 15:0, 16:0, 17:0, 18:0, 19:0, 20:0, 21:0, 22:0, 23:0, 24:0, 25:0, 26:0, 27:0, 28:0, 29:0, 30:0, 31:0, 32:0, 33:0, 34:0, 35:0};
    var allDays = {1:"", 2:"", 3:"", 4:"", 5:"", 6:"", 7:"", 8:"", 9:"", 10:"", 11:"", 12:"", 13:"", 14:"", 15:"", 16:"", 17:"", 18:"", 19:"", 20:"", 21:"", 22:"", 23:"", 24:"", 25:"", 26:"", 27:"", 28:"", 29:"", 30:"", 31:"", 32:"", 33:"", 34:"", 35:"", 36:"", 37:"", 38:"", 39:"", 40:"", 41:"", 42:""}
    var allDaysNotifs = {1:"", 2:"", 3:"", 4:"", 5:"", 6:"", 7:[], 8:"", 9:"", 10:"", 11:"", 12:"", 13:"", 14:"", 15:"", 16:"", 17:"", 18:"", 19:"", 20:"", 21:"", 22:"", 23:"", 24:"", 25:"", 26:"", 27:"", 28:"", 29:"", 30:"", 31:"", 32:"", 33:"", 34:"", 35:"", 36:"", 37:"", 38:"", 39:"", 40:"", 41:"", 42:""}
    var allDaysNotifsHtmlInit = {1:[], 2:[], 3:[], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10:[], 11:[], 12:[], 13:[], 14:[], 15:[], 16:[], 17:[], 18:[], 19:[], 20:[], 21:[], 22:[], 23:[], 24:[], 25:[], 26:[], 27:[], 28:[], 29:[], 30:[], 31:[], 32:[], 33:[], 34:[], 35:[], 36:[], 37:[], 38:[], 39:[], 40:[], 41:[], 42:[]}
    var allDaysNotifsHtml = {1:[], 2:[], 3:[], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10:[], 11:[], 12:[], 13:[], 14:[], 15:[], 16:[], 17:[], 18:[], 19:[], 20:[], 21:[], 22:[], 23:[], 24:[], 25:[], 26:[], 27:[], 28:[], 29:[], 30:[], 31:[], 32:[], 33:[], 34:[], 35:[], 36:[], 37:[], 38:[], 39:[], 40:[], 41:[], 42:[]}
    var allDaysNotifsHtmlResult = {1:"", 2:"", 3:"", 4:"", 5:"", 6:"", 7:"", 8:"", 9:"", 10:"", 11:"", 12:"", 13:"", 14:"", 15:"", 16:"", 17:"", 18:"", 19:"", 20:"", 21:"", 22:"", 23:"", 24:"", 25:"", 26:"", 27:"", 28:"", 29:"", 30:"", 31:"", 32:"", 33:"", 34:"", 35:"", 36:"", 37:"", 38:"", 39:"", 40:"", 41:"", 42:""}

    var listDaysMois = {};
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1)
    const [numberDays, setNumberDays] = useState(new Date(currentYear, currentMonth, 0).getDate())
    const [allJours, setAllJours] = useState();
    const [allNotifications, setAllNotifications] = useState()
    const [notifsHtml, setNotifsHtml] = useState();
    const [idPopup, setIdPopup] = useState();
    const [idPopupHist, setIdPopupHist] = useState();
    const [affichage, setAffichage] = useState();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [idShowPopup, setIdShowPopup] = useState(null);
    const contentStyle = { textAlign : "center" , fontSize: "1vw", color: "black",background:"red",maxHeight:"200px",overflowY:"auto"};

    const handleActionClick = (user) => {
      allNotifications
        .filter(nots => nots.id === user.id)
        .forEach(resp => {
          APIService.ModifierNotification(
            resp.id,
            {
              username: resp.username,
              matricule_employe: resp.matricule_employe,
              notification: resp.notification,
              dateNotification: resp.dateNotification,
              statutNotification: resp.statutNotification,
              estVu: true
            },
            token
          );
        });
    
      setToken('matriculeEmployeAttente', user.matricule_employe);
      window.location.href = '/saisie';
      console.log("action button clicked",user.username,"notif :::::",user.notification,"date ",user.dateNotification,"statut ",user.statutNotification)
    };
    

useEffect(() => {
  if (currentMonth && affichage === "calendrier") {
    let numDays = new Date(currentYear, currentMonth, 0).getDate();
    for (let i = 1; i <= numDays; i++) {
      listDaysMois[i] = new Date(currentYear, currentMonth - 1, i).getDay();
    }
    let v;
    for (let i = 1; i <= numDays; i++) {
      v = listDaysMois[1];
      if (v == 0) {
        v = 7;
      }
      allDays[i + (v - 1)] = i;
      if (allNotifications) {
        for (let j in allNotifications) {
          if (
            new Date(currentYear, currentMonth - 1, i).toLocaleDateString() ===
            new Date(
              allNotifications[j]["dateNotification"]
                .toString()
                .split("T")[0]
            ).toLocaleDateString()
          ) {
            var classNotif;
            if (allNotifications[j]["estVu"] === true) {
              classNotif = "classVuNotif";
            } else {
              classNotif = "classNonVuNotif";
            }
            
            allDaysNotifsHtml[i + (v - 1)].push(
            <>
                {allNotifications[j]["notification"]}
                { moment.utc(allNotifications[j]["dateNotification"]).local().format('DD MMMM YYYY')}
                { moment.utc(allNotifications[j]["dateNotification"]).format('HH:mm:ss')}
                <button className={styles.btnAction}
                 onClick={
                  () => {
                      allNotifications.filter(nots => nots.id === allNotifications[j]["id"]
               ).map(
                       resp => APIService.ModifierNotification(resp.id, {username:resp.username,matricule_employe:resp.matricule_employe,notification:resp.notification,dateNotification:resp.dateNotification,statutNotification:resp.statutNotification, estVu:true}, token)
                )
               setToken('matriculeEmployeAttente', allNotifications[j]["matricule_employe"])
            window.location.href = '/saisie'
            console.log("action button clicked",allNotifications[j]["notification"])

            }}
              >Action</button>
            </>
            );
          }
        }
      }
      
      // if(allNotifications){
      //   console.log("All notifications : ", allNotifications[1].dateNotification)
      // }
      setNotifsHtml(allDaysNotifsHtml);
    }
    setAllJours(allDays);
  }
}, [currentMonth, allNotifications, idPopup, currentYear, affichage]);



    const btnSuivantMois = () => {

      if(currentMonth === 12){
          setCurrentMonth(1)
          setCurrentYear(prevYear => prevYear + 1);
      }else{
          setCurrentMonth(prevMonth => prevMonth + 1)

      }
  }

  const btnPrecedantMois = () => {

      if(currentMonth === 1){
          setCurrentMonth(12);
          setCurrentYear(prevYear => prevYear - 1);
      }else{
          setCurrentMonth(prevMonth => prevMonth - 1)

      }
  }


  // events 

  const [inputDate, setInputDate] = useState(new Date())
  const [listDayDate, setListDayDate] = useState()
  const [eventHtml, setEventHtml] = useState()


  useEffect(() => {
    var day;
    var dateOffset;
    var myDate;
    var listDate = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":"", "Dimanche":""}
    

    if(inputDate){


      myDate = new Date(inputDate);
      day = new Date(inputDate).getUTCDay();
      dateOffset = (24*60*60*1000) * (day-1);

      myDate.setTime(myDate.getTime() - dateOffset);
      listDate["Lundi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Mardi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Mercredi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Jeudi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Vendredi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Samedi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Dimanche"]=moment(myDate).format("DD/MM/YYYY")
      setListDayDate(listDate)
    }
  
  }, [inputDate])


  useEffect(() => {
    if(listDayDate){
      var eventListHtml = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":"", "Dimanche":""}

      for(let k in listDayDate){
        eventListHtml[k] = moment.utc(new Date(moment(listDayDate[k], "DD/MM/YYYY").format("MM-DD-YYYY"))).local().format('dddd, DD MMMM YYYY')
      }


      setEventHtml(eventListHtml);
    }
  }, [listDayDate])
useEffect(() => {
 if(notifsHtml){
  console.log("notif html testttt",notifsHtml[5])
 }
}, [notifsHtml])




  const [eventListDaysHist, setEventListDaysHist] = useState()
  const [timeListDaysHist, setTimeListDaysHist] = useState()
  const [timeEventListHist, setTimeEventListHist] = useState()

  useEffect(() => {

    if(eventHtml){

      if(allNotifications){

        var eventListDays = {"Lundi":[], "Mardi":[], "Mercredi":[], "Jeudi":[], "Vendredi":[], "Samedi":[], "Dimanche":[]}
        var timeListDays = {"Lundi":[], "Mardi":[], "Mercredi":[], "Jeudi":[], "Vendredi":[], "Samedi":[], "Dimanche":[]}

        for(let jr in eventHtml){
      
          for(let i in allNotifications){

            if(allNotifications[i]["dateNotification"].toString().split("T")[0] === moment(eventHtml[jr.toString()], 'dddd, DD MMMM YYYY').format("YYYY-MM-DD").toString()){
              
  
              eventListDays[jr.toString()].push(
              <span  className={styles.divTdEvent}>{allNotifications[i]["notification"]}
              </span>
              );
              
              timeListDays[jr.toString()].push(
            <> 
            <span className={styles.divTdEvent}><i class="fas fa-clock"></i>
            &nbsp;
              {new Date(allNotifications[i]["dateNotification"]).toLocaleTimeString('fr-FR')}
            </span> 
          
            </>
            );
            }
            
          }
        }
        setEventListDaysHist(eventListDays)
        setTimeListDaysHist(timeListDays)
        var timeEventList = {"Lundi":[], "Mardi":[], "Mercredi":[], "Jeudi":[], "Vendredi":[], "Samedi":[], "Dimanche":[]}
        if(eventListDays){
          console.log("semaine");
          for(let v in eventListDays){
            for(let i = 0; i < eventListDays[v].length; i++){

              timeEventList[v].push(
<>
                <span className={styles.eventStylePC}>
                  
                 <span className={styles.heurePc}> {timeListDays[v][i]}</span>
                  &nbsp;&nbsp;&nbsp;
                  <span className={styles.nitifPc}>{eventListDays[v][i]}</span>
                  
                </span>
                <span className={styles.eventStyleMobile}>
                <div className={styles.notifmobile}>
                  {eventListDays[v][i]}
                  </div>
                  <div className={styles.heuremobile}>
                  {timeListDays[v][i]}
                  </div>
                </span>
                <span className={styles.eventStyleTablet}>
                <div className={styles.notiftablet}>
                  {eventListDays[v][i]}
                  </div>
                <div className={styles.heuretablet}>
                  {timeListDays[v][i]}
                  </div>
                </span> 

</>
                


              )

            }
          }
          setTimeEventListHist(timeEventList);
        }

      }
    }
    
  }, [eventHtml, allNotifications,])
  // vue 
  useEffect(() => {
    if(eventHtml){
      console.log("event html",eventHtml)
    }
  }, [eventHtml])
  

  useEffect(() => {

    if(token){

      APIService.getAllNotifications(token)
      .then(resp => setAllNotifications(resp.filter(ntf => ntf.username === token["username"])))

      setAffichage("calendrier")

    }


  },[token])




const [clickedButton, setClickedButton] = useState(null);
useEffect(() => {
 console.log("ok",clickedButton)
}, [clickedButton])

useEffect(() => {
  if(notifsHtml){
    console.log('notification html : ', notifsHtml)
  }
}, [notifsHtml])

const handlePopupActionButton = () => {
  console.log('Action inside popup clicked');
  window.location.href = '/saisie'
};

const calendarCells = 42;
  const currentDay = currentDate.getDate();
  const currentMonth_ = currentDate.getMonth() + 1; 
  const currentYear_=currentDate.getFullYear();
  let numDays = new Date(currentYear, currentMonth, 0).getDate();

   
   const totalCalendarCells = 42;
   const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate(); 
   const startingDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay(); 
   
   let currentDayPosition = currentDay + startingDay - 1;
   
   console.log("Current day position ", currentDayPosition);
   useEffect(() => {
    console.log("current date",currentDate,"current month of the calendar",currentMonth,"current year ", currentYear, "current day",currentDay,
    "the real current month ",currentMonth_,"the real current year ",currentYear_,"NUM DAYS  ", numDays,"days in month ",daysInMonth,"startng day ",startingDay)
   }, [currentDate,currentMonth,currentYear,currentDay])
   

function returnContentCol(day) {
 return <>
 <span className={styles.classMobileEvents} >
 <button 
className={`${styles.buttonpopup} ${notifsHtml[day].length !== 0 ? styles.hoverButtonNotif: ''}
${(day===currentDayPosition && currentMonth_===currentMonth && currentYear_===currentYear ) ? styles.currentDateStyle : ''}
${(clickedButton === day && notifsHtml[day].length !== 0 && idShowPopup === day) ? styles.clickedButton : ''}`}

 onClick={() => {
      notifsHtml[day] ? setShow(true): setShow(false);
      setIdShowPopup(day);
      setClickedButton(day);
    }}>  {allJours[day]} </button>
     {notifsHtml[day].length !== 0 && idShowPopup === day ? <>
<PopupComponent  width="280px" height="280px" onClose={() => {setShow(false);setClickedButton(null)}} show={show}
  notifications= 
  {
    notifsHtml[day]
                }
  headerDate= {
    notifsHtml[day]
                }
              >
              
</PopupComponent>
</>:
<>
</>} 



 </span>
 <span className={styles.classPCEvents}>
  <button
    className={`${styles.buttonCalendPc} ${notifsHtml[day].length !== 0 ? styles.hoverButtonNotif: ''}
      ${(day===currentDayPosition && currentMonth_===currentMonth && currentYear_===currentYear ) ? styles.currentDateStyle : ''}
    ${(clickedButton === day && notifsHtml[day].length !== 0 && idShowPopup === day) ? styles.clickedButton : ''}`}
    onClick={() => {
      notifsHtml[day] ? setShowtest(true): setShowtest(false);
      setIdShowPopup(day);
      setClickedButton(day);
    }}> 
     {allJours[day]} {idPopup}
  </button>
         {notifsHtml[day].length !== 0 && idShowPopup === day ? <>

            <PopupComponent  width="300px" height="200px" onClose={() => {setShowtest(false);setClickedButton(null)}} show={showtest}
              notifications= 
              {
                notifsHtml[day]
                            }
              headerDate= {
                notifsHtml[day]
                            }
                          >
                          
            </PopupComponent>
            </>:
            <>
            </>} 
            
</span>
 </>;
    
  }
  return(
<div className={styles.classCard}>
    <section className={styles.topSection}>
          <div className={classTopSection}>
                  <span>

                  </span>
                  <span>
                          <button id={styles.maintenant} className={styles.classBtnCalendrier} onClick={() => {
                            setCurrentYear(yearAct);
                            setCurrentMonth(monthAct);
                            setInputDate(new Date());

                            }}>
                            <b>Maintenant</b>
                          </button>
                  </span>
                  { affichage === "calendrier" ? 
                    <>
                                    <div className={styles.mobileCalendrier}>
                                      <span className="col_" id="idBtnAgenda_">
                                                <button className={styles.classBtnCalendrier} onClick={() => setAffichage("agenda")}>
                                                  <b>Agenda</b>
                                                </button>
                                      </span>
                                      <span className="col_" id="idMonthYearNavigationCalendrier">
                                              <div className={styles.classBtnCalendrier} id={styles.bouttonfleche}>
                                                <span className={styles.arrowIcons}  onClick={btnPrecedantMois}>&lt;</span>
                                                <b >{listMois[currentMonth]} &nbsp;{currentYear}</b>
                                                <span className={styles.arrowIconsRight}  onClick={btnSuivantMois}>&gt;</span>
                                              </div>
                                      </span>
                                      
                                    </div>


                                    <span>

                                  </span>
                                      <span id="idMonthNavigationCalendrier">
                                                                           <div className={`${styles.classBtnCalendrier} ${styles.buttonWeb_}`} id={styles.buttonweb}>
                                                                            <span className={styles.arrowIcons} onClick={btnPrecedantMois}>&lt;</span>
                                                                            <b >{listMois[currentMonth]}</b>
                                                                            <span className={styles.arrowIconsRight} onClick={btnSuivantMois}>&gt;</span>
                                                                          </div>
                                      </span>


                                      <span>

                                      </span>

                                      <span id="idYearNavigationCalendrier">
                                              <div className={`${styles.classBtnCalendrier} ${styles.buttonWeb_}`} id={styles.buttonweb}>
                                                <span className={styles.arrowIcons}  onClick={() => setCurrentYear(currentYear - 1)}>&lt;</span>
                                                <b >{currentYear}</b>
                                                <span className={styles.arrowIconsRight}  onClick={() => setCurrentYear(currentYear + 1)}>&gt;</span>
                                              </div>
                                            </span>

                                          <span>

                                          </span>
                                          <span id="idBtnAgenda">
                                              <button className={styles.classBtnCalendrier}  id={styles.buttonweb} onClick={() => {setAffichage("agenda"); setClassTopSection(styles.uneAgenda)}}>
                                                <b>Agenda</b>
                                              </button>
                                            </span>
                                              <span>

                                              </span>

                    </>:<>

                              <span>

                              </span>
                                {
                                  inputDate ?  
                                  <span  id="idDaySearch" >
                                  <input className={styles.classBtnCalendrier_} id={styles.btnsearch} type="date" value={moment(inputDate).format('YYYY-MM-DD')} onChange={e => setInputDate(e.target.value)}/>

                                </span>
                                :
                                <></>
                                }

                              <span>

                              </span>
                              <span className="col__" id="idBtnCalendrier">
                                <button className={styles.classBtnCalendrier_} onClick={() => {setAffichage("calendrier"); setClassTopSection(styles.une)}}>
                                Calendrier
                                </button>
                              </span>

                              <span>

                              </span>
                        
                        </>}
          </div>
    </section>
    <section className={styles.bottomSection}>

    <div 
    className={styles.containerZone}
    >
    {

     affichage === "calendrier" ?
      <>

      <div id={styles.divZone1}>
      <button id={styles.btnPrecedant} onClick={btnPrecedantMois}>
        <FontAwesomeIcon icon={faArrowLeft} />
      
      </button>
      </div>
      <div id={styles.divZone2}>
        <div id={styles.zoneTable}>
        <table id={styles.tableEmplacement} onClick={() => setEtatModalPopup(true)}>
          <span className={styles.myTable}>
  <thead>
  <tr>
    <th className={styles.JourSemaineTable}>Lundi</th>
    <th className={styles.JourSemaineTable}>Mardi</th>
    <th className={styles.JourSemaineTable}>Mercredi</th>
    <th className={styles.JourSemaineTable}>Jeudi</th>
    <th className={styles.JourSemaineTable}>Vendredi</th>
    <th className={styles.JourSemaineTable}>Samedi</th>
    <th className={styles.JourSemaineTable}>Dimanche</th>
  </tr>
  </thead>
<tbody>
  {   allJours ?
  <>

  <tr className={styles.trTableCalendrier}>
  <td id={styles.divTd}>
    {returnContentCol(1)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(2)}
    </td>
    <td id={styles.divTd}>
      {returnContentCol(3)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(4)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(5)}
    </td>
    <td id={styles.divTd}>
      {returnContentCol(6)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(7)}
    </td>
  </tr>
  <tr className={styles.trTableCalendrier}>
  <td id={styles.divTd}>
  {returnContentCol(8)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(9)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(10)}
    </td>
    <td id={styles.divTd}>
       {returnContentCol(11)}
     
    </td>
    <td id={styles.divTd}>
    {returnContentCol(12)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(13)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(14)}
    </td>
  </tr>
  <tr className={styles.trTableCalendrier}>
  <td id={styles.divTd}>
  {returnContentCol(15)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(16)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(17)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(18)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(19)} 
    </td>
    <td id={styles.divTd}>
    {returnContentCol(20)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(21)}
    </td>
  </tr>
  <tr className={styles.trTableCalendrier}>
  <td id={styles.divTd}>
  {returnContentCol(22)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(23)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(24)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(25)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(26)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(27)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(28)}
    </td>
  </tr>
  <tr className={styles.trTableCalendrier}>
  <td id={styles.divTd}>
  {returnContentCol(29)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(30)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(31)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(32)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(33)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(34)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(35)}
    </td>
  </tr>

  {
    allJours[36] !== "" ?

    <tr className={styles.trTableCalendrier}>
  <td id={styles.divTd}>
  {returnContentCol(36)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(37)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(38)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(39)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(40)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(41)}
    </td>
    <td id={styles.divTd}>
    {returnContentCol(42)}
    </td>
  </tr>
  
    :<></>
  }
  
  </>:<></>}
  </tbody>

  
  </span>
</table>
  </div>
  </div>
      <div id={styles.divZone3}> 
      <button id={styles.btnSuivant} onClick={btnSuivantMois}>
      <FontAwesomeIcon icon={faArrowRight} />

      </button>
      </div>
</> :<>  
        <div id={styles.divZone1}>
         </div>
         <div id={styles.divZone2Tablette}>
        <div id={styles.zoneTable}>
        <table id={styles.tableEvents} onClick={() => setEtatModalPopup(true)}>
          <span className={styles.myTable_}>
  <thead>
  <tr >
    <th className={styles.JourSemaineTable_}>Date</th>
    <th className={styles.JourSemaineTable_}>Temps / Événement</th>
  </tr>
  </thead>
  {
    eventHtml ?
    <>
    <tbody>
     <tr>
      <td className={styles.dateAgenda} id={styles.divTd_}>
        {eventHtml["Lundi"]}
        </td>
      {timeEventListHist && timeEventListHist["Lundi"].length !== 0 ? 
          <td id={styles.divTd_}>
            
          <>{timeEventListHist["Lundi"].map(
            resp => <div className={styles.spanNotif}>{resp}<br /></div>
          )}</>
          
          </td>
          :
          <td className={styles.tdHover} id={styles.divTd_}>Aucun événement disponible !</td>
      }
    </tr>
    <tr>
      <td  className={styles.dateAgenda} id={styles.divTd_}>
        {eventHtml["Mardi"]}
        </td>
      {
          timeEventListHist && timeEventListHist["Mardi"].length !== 0 ? 
          <td id={styles.divTd_}>
            
          <>{timeEventListHist["Mardi"].map(
            resp => <div className={styles.spanNotif}>{resp}<br /></div>
          )}</>
          
          </td>
          :
          <td className={styles.tdHover} id={styles.divTd_}>Aucun événement disponible !</td>
        }
    </tr> 
    <tr>
      <td className={styles.dateAgenda} id={styles.divTd_}>
        {eventHtml["Mercredi"]}
        </td>

        {
          timeEventListHist && timeEventListHist["Mercredi"].length !== 0 ? 
          <td id={styles.divTd_}>
            
          <>{timeEventListHist["Mercredi"].map(
            resp => <div className={styles.spanNotif}>{resp}<br /></div>
          )}</>
          
          </td>
          :
          <td className={styles.tdHover} id={styles.divTd_}>Aucun événement disponible !</td>
        }
    </tr>
     <tr>
      <td className={styles.dateAgenda} id={styles.divTd_}>
        {eventHtml["Jeudi"]}
        </td>
      {
        timeEventListHist && timeEventListHist["Jeudi"].length !== 0 ? 
        <td id={styles.divTd_}>
          
        <>{timeEventListHist["Jeudi"].map(
          resp => <div className={styles.spanNotif}>{resp}<br /></div>
        )}</>
        
        </td>
        :
        <td className={styles.tdHover} id={styles.divTd_}>Aucun événement disponible !</td>
      }
    </tr>
    <tr>
      <td className={styles.dateAgenda} id={styles.divTd_}>
        {eventHtml["Vendredi"]}
        </td>
      {
        timeEventListHist && timeEventListHist["Vendredi"].length !== 0 ? 
        <td id={styles.divTd_}>
          
        <>{timeEventListHist["Vendredi"].map(
          resp => <div className={styles.spanNotif}>{resp}<br /></div>
        )}</>
        
        </td>
        :
        <td className={styles.tdHover} id={styles.divTd_}>Aucun événement disponible !</td>
      }
    </tr>
    <tr>
      <td className={styles.dateAgenda} id={styles.divTd_}>

        {eventHtml["Samedi"]}
        </td>
      {
        timeEventListHist && timeEventListHist["Samedi"].length !== 0 ? 
        <td id={styles.divTd_}>
          
        <>{timeEventListHist["Samedi"].map(
          resp => <div className={styles.spanNotif}>{resp}<br /></div>
        )}</>
        
        </td>
        :
        <td className={styles.tdHover} id={styles.divTd_}>Aucun événement disponible !</td>
      }
    </tr>
    <tr>
      <td className={styles.dateAgenda} id={styles.divTd_}>
        {eventHtml["Dimanche"]}
        </td>
      {
        timeEventListHist && timeEventListHist["Dimanche"].length !== 0 ? 
        <td id={styles.divTd_}>
          
        <>{timeEventListHist["Dimanche"].map(
          resp => <div className={styles.spanNotif}>{resp}<br /></div>
        )}</>
        
        </td>
        :
        <td className={styles.tdHover} id={styles.divTd_}>Aucun événement disponible !</td>
      }
    </tr> 
    </tbody>
    
    </>
    :
    <>
    

    </>
  }
  </span>
  </table>
  </div>
</div>
<div id={styles.divZone3}>
</div>
    </>}

    </div>

    </section>
</div>
)
}

export default AdminSection;