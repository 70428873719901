import React, { Component }  from 'react'
import { useState } from 'react'
// Import the main component
import { Viewer, LocalizationMap } from '@react-pdf-viewer/core';
// Import the localization file
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';
import { Trigger } from '@react-pdf-viewer/highlight';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

import { highlightPlugin } from '@react-pdf-viewer/highlight';

// Import styles
import '@react-pdf-viewer/highlight/lib/styles/index.css';


import { Worker } from '@react-pdf-viewer/core';

import {sidebarTabs} from  '@react-pdf-viewer/default-layout';

import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

// Import styles
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

// Import styles
import '@react-pdf-viewer/toolbar/lib/styles/index.css';


import { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar'
import { useEffect } from 'react';


function PdfReview(props) {
    // var url = ''
    // var ext = ''
    // useEffect(() => {
    //     ext = props.url.split(".")[-1]
   
        
    // }, [props.url])


    
    const highlightPluginInstance = highlightPlugin()
    // const defaultLayoutPluginInstance = defaultLayoutPlugin()  
    //   const defaultLayoutPluginInstance = defaultLayoutPlugin(
    //     {
    //         defaultLayoutPlugin: defaultLayoutPlugin,
    //     }
    // );  
    const toolbarPluginInstance = toolbarPlugin();
    // const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        // Download: () => <></>,
        // DownloadMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => [],
    });
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

   

  return (
        
    <div>



                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <div
                            style={{
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                height: '51vh',
                                width: '35vw',
                            }}
                        >
                            <Viewer   fileUrl={props.url} 
                            localization={fr_FR}
                            plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance
                            , highlightPluginInstance]} 
                            />
                        </div>
                    </Worker>

                   



                    

    </div>
  )
}

export default PdfReview;

