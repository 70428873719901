import React, { useEffect } from "react";
import { useState } from "react";


function Component({num, jilali}) {

    return(
        <button onClick={() => jilali()}>Numéro : {num}</button>
    )
    
}
function listTables() {
const numbers =[1, 2, 3, 4, 5]
const afficher =numbers.map(lib =>lib*2 );
console.log(afficher);
    return(
       <div> {numbers} </div>
        )
    
}

function TestHooks(params) {

const[ nombre1 , setNombre1]= useState(null);
const[nombre2 , setNombre2]= useState(null);
const[resultat , setResultat] = useState(null);
useEffect(
    () => {
        if(resultat===10){
            console.log("yees")

        }
    }
    ,
    [resultat]

)
console.log(resultat);


// formation 

 const [number, setNumber] = useState(0)

  const incrFunction = () => setNumber(number + 1)
    return(
        <div>

            <input type="number" value={(nombre1)} onChange={(e) => setNombre1(e.target.value)}/> 
            +
            <input type="number" value={(nombre2)} onChange={(e) => {setNombre2(e.target.value)
            
            
            }}/> 
            <button onClick={()=> setResultat(parseInt(nombre1)+parseInt(nombre2))
            }> = </button>
         <input type="number" value={resultat} /> 
         { resultat===10 &&

         <p> yees </p>
            

         }

<hr />
         <p>Exercices</p>

         <hr />

         <Component num={number} jilali={incrFunction}/>

         <listTables/>



        </div>
    )
    
}

export default TestHooks;