import react, {useEffect, useState} from "react";
import styles from "./AbsenceSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";
import LineFilterTableInput from "../../GlobalComponents/lineFilterTableInput/LineFilterTableInput";
import PaginationTable from "../../GlobalComponents/paginationTable/PaginationTable";
import GeneralCardTest from "../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import FourthSectionTablePage from "../../GlobalComponents/fourthSectionTablePage/FourthSectionTablePage.jsx"
import CheckboxTableInput from "../../GlobalComponents/checkboxTableInput/CheckboxTableInput.jsx";
import SelectNbreLigneTableInput from "../../GlobalComponents/selectNbreLigneTableInput/SelectNbreLigneTableInput.jsx";
import SecondSectionTablePage from "../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import ListColumns from "../../GlobalComponents/listColumns/ListColumns.jsx";
import DescriptionPaieComponent from "../../GlobalComponents/descriptionPaieComponent/DescriptionPaieComponent.jsx";
import PopupComponentGstTemps from "../../GlobalComponents/popupComponentGstTemps/PopupComponentGstTemps.jsx";
import ButtonValider from "../../GlobalComponents/buttonValider/ButtonValider.jsx";

function AbsenceSection(){
    const today = new Date("2999-12-31").toISOString().slice(0, 10);
    const navigate = useNavigate();

    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const [matriculePaie, setMatriculePaie] = useState();
    const [listAbsence, setListAbsence] = useState();
    const [listAbsenceEtat, setListAbsenceEtat] = useState([]);
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState(today);
    const [absence, setAbsence] = useState();
    const [nombreJour, setNombreJour] = useState("");
    const [nombreHeure, setNombreHeure] = useState("");
    const [dateDebutDelimiter, setDateDebutDelimiter] = useState();
    const [dateFinDelimiter, setDateFinDelimiter] = useState();
    const [absenceDelimiter, setAbsenceDelimiter] = useState();
    const [nombreJourDelimiter, setNombreJourDelimiter] = useState("");
    const [nombreHeureDelimiter, setNombreHeureDelimiter] = useState("");
    const [idModifier, setIdModifier] = useState();
    const [idDelimiter, setIdDelimiter] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();

    const [isIconUp, setIsIconUp] = useState(false);

    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])
    

    useEffect(() => {
      if(matriculePaie){
        APIService.getAbsencePaie(matriculePaie, token).then(resp => setListAbsence(resp));
      }
    }, [matriculePaie, listAbsenceEtat])

    useEffect(() => {

        if(JSON.stringify(listAbsenceEtat) !== JSON.stringify(listAbsence)){
            setListAbsenceEtat(listAbsence)
        }
    }, [listAbsence])
    


    const btnEnregistrer = () => {
        let body = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            absence : "",
            nombreJour : "",
            nombreHeure : ""
        }

        let bodyDelimiter = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            absence : "",
            nombreJour : "",
            nombreHeure : ""
        }

        if(dateDebut && (dateFin || today) && absence){
            body.matricule_employe = matriculePaie;
            body.matricule_manager = matriculePaie;
            body.dateDebut = dateDebut;
            if(dateFin) {
                body.dateFin = dateFin
            } else {
                body.dateFin = today
            }
            body.absence = absence;
            body.nombreJour = nombreJour;
            body.nombreHeure = nombreHeure;




            if(idModifier){

                APIService.putAbsencePaie(idModifier, body, token);
                setIdModifier();
                setShow(false)
                setDateDebut("")
                setDateFin("")
                setAbsence("")
                setNombreJour("")
                setNombreHeure("")


            } else {
                if(idDelimiter){

                    bodyDelimiter.matricule_employe = matriculePaie;
                    bodyDelimiter.matricule_manager = matriculePaie;
                    bodyDelimiter.dateDebut = dateDebutDelimiter;
                    bodyDelimiter.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                    bodyDelimiter.absence = absenceDelimiter;
                    bodyDelimiter.nombreJour = nombreJourDelimiter;
                    bodyDelimiter.nombreHeure = nombreHeureDelimiter;
                   

                    APIService.putAbsencePaie(idDelimiter, bodyDelimiter, token);
                    APIService.postAbsencePaie(body, token);

                    setIdDelimiter();
                    setShow(false)
                    setDateDebut("")
                    setDateFin("")
                    setAbsence("")
                    setNombreJour("")
                    setNombreHeure("")


                } else {
                    listAbsence.map(
                        resp => {
                            let bodyTemp = {
                                matricule_employe : "",
                                matricule_manager : "", 
                                dateDebut : "",
                                dateFin : "",
                                absence : "",
                                nombreJour : "",
                                nombreHeure : ""
                            }
                            setDateDebut("")
                            setAbsence("")
                            setNombreJour("")
                            setNombreHeure("")


                            if(new Date(resp.dateFin) > new Date(dateDebut)){
                                bodyTemp.matricule_employe = resp.matricule_employe;
                                bodyTemp.matricule_manager = resp.matricule_manager;
                                bodyTemp.dateDebut = resp.dateDebut;
                                bodyTemp.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                                bodyTemp.absence = resp.absence;
                                bodyTemp.nombreJour = resp.nombreJour;
                                bodyTemp.nombreHeure = resp.nombreHeure;


                                APIService.putAbsencePaie(resp.id, bodyTemp, token);

                            }
                        }
                    )
                    APIService.postAbsencePaie(body, token);

                }
            }

            APIService.getAbsencePaie(matriculePaie, token).then(resp => {setListAbsence(resp); setListAbsenceEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deleteAbsencePaie(idRem, token);
        APIService.getAbsencePaie(matriculePaie, token).then(resp => {setListAbsence(resp); setListAbsenceEtat(resp);});

        setIdModifier();
        setIdDelimiter();
        
    }
   

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listAbsence.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setDateDebut(resp.dateDebut);
            setDateFin(resp.dateFin);
            setAbsence(resp.absence);
            setNombreJour(resp.nombreJour);
            setNombreHeure(resp.nombreHeure);


            setIdDelimiter();

        })


    }

    
    

    const btnDelimiter = (e) => {

        if(!etatAjouter) {
            setEtatAjouter(true);
        }

        listAbsence.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdDelimiter(resp.id);
            setDateDebutDelimiter(resp.dateDebut);
            setDateFinDelimiter(resp.dateFin);
            setAbsenceDelimiter(resp.absence);
            setNombreJourDelimiter(resp.nombreJour);
            setNombreHeureDelimiter(resp.nombreHeure);

            setDateDebut("");
            setDateFin(resp.dateFin);
            setAbsence(resp.absence);
            setNombreJour(resp.nombreJour);
            setNombreHeure(resp.nombreHeure);

            setIdModifier();


        })
    }

    const [show, setShow] = useState(false)

    const btnAjouter = () => {
        if(etatAjouter){
          setEtatAjouter(prevState => !prevState);
          setIsIconUp(prevState => !prevState);
          setShow(prevState => !prevState);
        } else {
            setDateDebut("")
            setDateFin("")
            setAbsence("")
            setNombreJour("")
            setNombreHeure("")
            setEtatAjouter(prevState => !prevState);
            setIsIconUp(prevState => !prevState);
            setShow(prevState => !prevState);
        }

        setIdModifier();
        setIdDelimiter();
        setDateDebut("")
        setDateFin("")
        setAbsence("")
        setNombreJour("")
        setNombreHeure("")
    }

    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
      // **********
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }

      // *****************************************************
const [listAbsenceResult, setListAbsenceResult] = useState(null)
const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()

useEffect(() => {

  if(matriculePaie){
    APIService.getAbsencePaie(matriculePaie, token).then(
        resp => setListAbsence(resp));
  }
}, [matriculePaie,listAbsenceEtat])

// useEffect(() => {
//   if(listRemuneration){
//   listRemuneration.map(resp =>{
//     resp.matricule_manager="ABC"

//       }
//   )
//   setListRemunerationResult(listRemuneration)

// }

// },[listRemuneration])




useEffect(() => {
  if(valeurRechercheGlobale && listAbsence){
    setListAbsenceResult(
      listAbsence.filter(resp =>  
         
            (Object.keys(headerTest).includes("dateFin") && resp.dateFin.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("dateDebut") && resp.dateDebut.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))
             ||
            (Object.keys(headerTest).includes("matricule_employe") && resp.matricule_employe.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("matricule_manager") && resp.matricule_manager.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("nombreHeure") && resp.nombreHeure.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))||
            (Object.keys(headerTest).includes("nombreJour") && resp.nombreJour.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))||
            (Object.keys(headerTest).includes("absence") && resp.absence.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))


             )

            )
  }else{
    if(listAbsence){
      setListAbsenceResult(listAbsence)
    }
  }
}, [valeurRechercheGlobale,listAbsence])

// Pagination

const [index, setIndex] = useState(0)
const [page, setPage] = useState(null)
const [pageTickets, setPageTickets] = useState([])
const [nombrePageArray_, setNombrePageArray_] = useState([])
const [nombrePageValeurFixed, setNombrePageValeurFixed] = useState(null)
const [afficherChecked, setAfficherChecked] = useState(false)


function transformNumberToArray(n) {
   const array = [];
   for (let i = 1; i <= n; i++) {
       array.push(i);
   }
   return array;
}


useEffect(() => {
 if(listAbsenceResult && parseInt(index) !== 0 && page !== 0 )

 {
   let indexInt = parseInt(index);

   setPageTickets(listAbsenceResult.slice(page*indexInt - indexInt, page*indexInt))
   setNombrePageArray_(transformNumberToArray(Math.ceil(listAbsenceResult.length/parseInt(index))))

 } else {

   if( listAbsenceResult && parseInt(index) === 0){
     setPage(1)
     setPageTickets(listAbsenceResult)
     setNombrePageArray_([])
   


   }

 }

}, [listAbsenceResult, index, page])




useEffect(() => {
 if(afficherChecked && listAbsenceResult){
   setNombrePageValeurFixed(0);
   setIndex(0);
 }
}, [afficherChecked, listAbsenceResult])

const handleChangeNombrePage = (e) => {
 setAfficherChecked(false)
 setNombrePageValeurFixed(parseInt(e.target.value))
 setIndex(e.target.value)
 setPage(1);
}

const [dataUsed, setDataUsed] = useState(null)

useEffect(() => {

 if(pageTickets){

   setDataUsed(pageTickets)
 }

}, [pageTickets])


// *********************************************************



// ****************************************************
const [showTest, setShowTest] = useState(false)
const [idTest, setIdTest] = useState()
const [errorMessage, setErrorMessage] = useState('');
// const [errorMessageDelimiter, setErrorMessageDelimiter] = useState('');

       const handleDateDebutChange = (e) => {
        const startDate = e.target.value;
        if (startDate > dateFin) {
            setErrorMessage("date debut doit etre inferieure a date fin ! ");
        } else {
            setErrorMessage("");
        }
        setDateDebut(startDate);
    };
  //   const handleDateDebutChangeDelimiter = (e) => {
  //     const startDate = e.target.value;
  //     if (startDate > dateFin) {
  //         setErrorMessageDelimiter("date debut doit etre inferieure a date fin ! ");
  //     } else {
  //         setErrorMessageDelimiter("");
  //     }
  //     setDateDebut(startDate);
  // };


      const deleteButton = (id) => {
        if(id && token){
          console.log("delete button",id)
          setShowTest(true); 
          setIdTest(id)
        }
      }
      const deleteButtonOui=()=>{
         APIService.deleteAbsencePaie(idTest, token);
          APIService.getAbsencePaie(matriculePaie, token).then(resp => {setListAbsence(resp); setListAbsenceEtat(resp);});
          setIdModifier();
          setIdDelimiter(); 
          setShowTest(false)

      }
      const deleteButtonNon=()=>{
        setIdTest();
        setShowTest(false)

     }
      const modifyButton = (id) => {
        if(id){
          console.log("modify button",id)
          if(!etatAjouter){
            setEtatAjouter(true);
        }
        if(!show){
          setShow(true)
        }else{
          setShow(!show)
        }
          setErrorMessage("");
        const selectedResp = listAbsence.find(resp => resp.id === parseInt(id));
         console.log("modify button", selectedResp)
        if (selectedResp) {
            setIdModifier(selectedResp.id);
            setDateDebut(selectedResp.dateDebut);
            setDateFin(selectedResp.dateFin);
            setAbsence(selectedResp.absence);
            setNombreHeure(selectedResp.nombreHeure);
            setNombreJour(selectedResp.nombreJour);
            setIdDelimiter();

        }
        }
        
      }
      const hideButton = (id) => {
        if(id){
          console.log("hide button",id)
        }
      }
      const sendButton = (id) => {
        if(id){
          console.log("send button",id)
        }
      }
      const delimiterButton = (id) => {
        if(id){
          console.log("modify button",id)
          if(!etatAjouter){
            setEtatAjouter(true);
        }
        if(!show){
          setShow(true)
        }else{
          setShow(!show)
        }
        setErrorMessage("");
        const selectedResp = listAbsence.find(resp => resp.id === parseInt(id));
         console.log("Delimited button", selectedResp)
        if (selectedResp) {
          setIdDelimiter(selectedResp.id);
            setDateDebutDelimiter(selectedResp.dateDebut);
            setDateFinDelimiter(selectedResp.dateFin);
            setAbsenceDelimiter(selectedResp.absence);
            setNombreJourDelimiter(selectedResp.nombreJour);
            setNombreHeureDelimiter(selectedResp.nombreHeure);
            setDateDebut("");
            setDateFin(selectedResp.dateFin);
            setAbsence(selectedResp.absence);
            setNombreJour(selectedResp.nombreJour);
            setNombreHeure(selectedResp.nombreHeure);
            setIdModifier();
        }
        }
      }



      const [headerTest, setHeaderTest] = useState({dateDebut:"Date de Début",dateFin:"Date de Fin"
      // ,salaireBase:"Valeur"
    });
    //   const test={ dateDebut:"Date de Début",dateFin:"Date de Fin"
    //   ,salaireBase:"Valeur"
    // }
       console.log("test list remuneration",listAbsence)
    
       const componentsList={ 1: "Send",2:"Delete",3:"Modify",4:"Hide",5:"Delete",6:"Delimiter"}
       const methodsList={ 1: sendButton,2:deleteButton,3:modifyButton,4:hideButton,5:deleteButton,6:delimiterButton}
       

    

    return(
    //     <div className={styles.classCard}>
    //     <div className={styles.generalContainer}>
    //         <div className={styles.firstPart}>
    //         <TopMenuPaie page="absence"  />
    //         {/* Absences */}
    //         </div>
    //         <div className={styles.secondPart}>
    //                 <div className={styles.tableAndFormSection}>
    //                     <div className={styles.buttonAndFormSection}>
    //                         <div className={styles.buttonSection_}>
    //                             <button className={styles.btnAjouter} onClick = {btnAjouter}>
    //                                                     Ajouter une nouvelle ligne &nbsp; {' '}
    //                                                     {isIconUp ? (
    //                                                         <i className="fa-solid fa-caret-up"></i>
    //                                                     ) : (
    //                                                         <i className="fa-solid fa-caret-down"></i>
    //                                                     )}
    //                             </button>
    //                         </div>
    //                         <div className={styles.formSection}>
    //                             <br />
    //                             {idModifier ? 
    //                                 <div className={styles.modifiableTexte2}>
    //                                     <i class="fa-solid fa-pencil"></i> &nbsp;
    //                                     Modifier</div>

    //                                 :
    //                                 <>
    //                                 {
    //                                     idDelimiter ?
    //                                     <div className={styles.modifiableTexte2}>
    //                                         <i class="fa-solid fa-scissors"></i> &nbsp;
    //                                         Délimiter</div> :<></>
    //                                 }
                                    
    //                                 </>
    //                             }
                                
    //                             {
    //                                 etatAjouter ? 
    //                                 <>
    //                                 <div className={styles.modern_container}>
    //                                     <div className={styles.modern_inputs}>
    //                                     <span className={styles.modern_label}>
    //                                     Période
    //                                     </span>
    //                                     <div class={styles.modern_input_wrapper}>
    //                                         <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
    //                                         <span className={styles.timeSeparator}>-</span>
    //                                         <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
    //                                     </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.container1}>
    //                                         <div className={styles.zoneInput}>
    //                                             <div className={styles.fileNameTxtStyle1}>
    //                                             Absence
    //                                             </div>
    //                                             <select className={styles.goldenInput1} value={absence} onChange={e => setAbsence(e.target.value)} id="choix">
    //                                                                         <option></option>
    //                                                                         <option value="Maladie">Maladie</option>
    //                                                                         <option value="Autre">Autre</option>
    //                                                                     </select>
    //                                         </div>
    //                                         </div>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                         Nombre de jours
    //                                         </div>
    //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                          Nombre d'heures
    //                                         </div>
    //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.btnSave}>
    //                                     <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
    //                                 </div>
                                     
    //                                  </>:<></>
    //                                 }
                                    

    //                         </div>

    //                     </div>
    //                         <div className={styles.tableSection}>
    //                             <div className={styles.tableZone} >
    //                             <table className={styles.classTable}>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Date de début</th>
    //                                     <th>Date de fin</th>
    //                                     <th>Absence</th>
    //                                     <th>Nombre de j/h</th>
    //                                     <th>Action</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {
    //                                     listAbsence ? 
                                    
    //                                     listAbsence.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
    //                                         <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
    //                                             <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
    //                                             <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
    //                                             <td>{resp.absence}</td>
    //                                             <td>---</td>
    //                                             <td>
    //                                                 <button id={styles.trashButton} value={resp.id} onClick={ () => 
    //                                                     handleRowClick(resp.id)
    //                                                     }>
    //                                                         <i class="fa-solid fa-trash-can"></i>
    //                                                         </button>
    //                                                         {idSupprimerAlert && idSupprimerAlert===resp.id ? (
    //                                                             <>
    //                                                             <div className={styles.overlay}></div>
    //                                                             <div className={styles.popup}>
    //                                                                 <div id={styles.supprimerTexte}>Supprimer</div>
    //                                                                 <br />
    //                                                                 <div className={styles.buttonsPopup}>
    //                                                                 <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
    //                                                                 <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
    //                                                                 </div>
    //                                                             </div>
    //                                                             </>
    //                                                             ):<></>
    //                                                         }


    //                                                     {
    //                                                     moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
    //                                                     <>
    //                                                             &nbsp;
    //                                                             <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
    //                                                              <i class="fa-solid fa-pencil"></i>
    //                                                                 </button>


    //                                                             &nbsp;

    //                                                             <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
    //                                                             <i class="fa-solid fa-scissors"></i>
    //                                                                 </button>

    //                                                     </>:<></>
    //                                                     }

    //                                                 </td>
    //                                         </tr>
    //                                     )
                                    
                                        
    //                                     :<></>

    //                                 }
                                    
    //                             </tbody>
    //                             </table>
    //                             </div>
    //                         </div>
    //                 </div>
    //                 {/* **************** Mobile ******** */}
    //                 <div className={styles.tableAndFormSectionMobile}>
    //                     <div className={styles.tableSection}>
    //                             <div className={styles.tableZone} >
    //                             <table className={styles.classTable}>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Date de début</th>
    //                                     <th>Date de fin</th>
    //                                     <th>Absence</th>
    //                                     <th>Nombre de j/h</th>
    //                                     <th>Action</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {
    //                                     listAbsence ? 
                                    
    //                                     listAbsence.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
    //                                         <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
    //                                             <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
    //                                             <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
    //                                             <td>{resp.absence}</td>
    //                                             <td>---</td>
    //                                             <td>
    //                                                 <button id={styles.trashButton} value={resp.id} onClick={ () => 
    //                                                     handleRowClick(resp.id)
    //                                                     }>
    //                                                         <i class="fa-solid fa-trash-can"></i>
    //                                                         </button>
    //                                                         {idSupprimerAlert && idSupprimerAlert===resp.id ? (
    //                                                             <>
    //                                                             <div className={styles.overlay}></div>
    //                                                             <div className={styles.popup}>
    //                                                                 <div id={styles.supprimerTexte}>Supprimer</div>
    //                                                                 <br />
    //                                                                 <div className={styles.buttonsPopup}>
    //                                                                 <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
    //                                                                 <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
    //                                                                 </div>
    //                                                             </div>
    //                                                             </>
    //                                                             ):<></>
    //                                                         }


    //                                                     {
    //                                                     moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
    //                                                     <>
    //                                                             &nbsp;
    //                                                             <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
    //                                                              <i class="fa-solid fa-pencil"></i>
    //                                                                 </button>

                                                            
    //                                                             &nbsp;
                                                            
    //                                                             <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
    //                                                             <i class="fa-solid fa-scissors"></i>
    //                                                                 </button>
                                                                
    //                                                     </>:<></>
    //                                                     }
                                                    
                                                    


                                                    
    //                                                 </td>
    //                                         </tr>
    //                                     )
                                    
                                        
    //                                     :<></>

    //                                 }
                                    
    //                             </tbody>
    //                             </table>
    //                             </div>
    //                     </div>
    //                     <div className={styles.buttonAndFormSection}>
    //                         <div className={styles.buttonSection_}>
    //                             <button className={styles.btnAjouter} onClick = {btnAjouter}>
    //                                                     Ajouter une nouvelle ligne &nbsp; {' '}
    //                                                     {isIconUp ? (
    //                                                         <i className="fa-solid fa-caret-up"></i>
    //                                                     ) : (
    //                                                         <i className="fa-solid fa-caret-down"></i>
    //                                                     )}
    //                             </button>
    //                         </div>
    //                         <div className={styles.formSection}>
    //                             <br />
    //                             {idModifier ? 
    //                                 <div className={styles.modifiableTexte2}>
    //                                     <i class="fa-solid fa-pencil"></i> &nbsp;
    //                                     Modifier</div>

    //                                 :
    //                                 <>
    //                                 {
    //                                     idDelimiter ?
    //                                     <div className={styles.modifiableTexte2}>
    //                                         <i class="fa-solid fa-scissors"></i> &nbsp;
    //                                         Délimiter</div> :<></>
    //                                 }
                                    
    //                                 </>
    //                             }
                                
    //                             {
    //                                 etatAjouter ? 
    //                                 <>
    //                                 <div className={styles.modern_container}>
    //                                     <div className={styles.modern_inputs}>
    //                                     <span className={styles.modern_label}>
    //                                     Période
    //                                     </span>
    //                                     <div class={styles.modern_input_wrapper}>
    //                                         <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
    //                                         <span className={styles.timeSeparator}>-</span>
    //                                         <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
    //                                     </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.container1}>
    //                                         <div className={styles.zoneInput}>
    //                                             <div className={styles.fileNameTxtStyle1}>
    //                                             Absence
    //                                             </div>
    //                                             <select className={styles.goldenInput1} value={absence} onChange={e => setAbsence(e.target.value)} id="choix">
    //                                                                         <option></option>
    //                                                                         <option value="Maladie">Maladie</option>
    //                                                                         <option value="Autre">Autre</option>
    //                                                                     </select>
    //                                         </div>
    //                                         </div>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                         Nombre de jours
    //                                         </div>
    //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                          Nombre d'heures
    //                                         </div>
    //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.btnSave}>
    //                                     <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
    //                                 </div>
                                     
    //                                  </>:<></>
    //                                 }
                                    

    //                         </div>

    //                     </div>
                            
    //                 </div>
    //         </div>
    //     </div>
    // </div>
    <div>
    <div className={styles.web} >

    <GeneralCardTest 
    section1={
    <TopMenuPaie page="absence"/>
    } 
    section2={
    <SecondSectionTablePage 
    nbreComponents={4}
    component1={<DescriptionPaieComponent/>
    } 
    component2={ <ListColumns listColumn={listAbsenceResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
    component3={<>
    <div className={styles.inputgroup}>
            <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
                <div className={styles.zoneComponent}>
                    <div className={styles.text}>
                        <span className={styles.creeravenat_}>
                        Ajouter une nouvelle ligne 
                        </span> 
                    </div>
                    <div className={styles.iconeRight}>
                    {isIconUp ? (
                        <i className="fa-solid fa-caret-up"></i>
                        ) : (
                        <i className="fa-solid fa-caret-down"></i>
                        )}

                    </div>
                </div>
            </div>
    </div>
    <PopupComponentGstTemps onClose={() => {
        setShow(false);
        setIsIconUp(false);
        setIdModifier();
        setIdDelimiter();
    }} show={show} width="400px" height="280px"
    popUpHeader={
      idModifier ? (
          <>
              <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
          </>
      ) : idDelimiter ? (
          <>
              <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
          </>
      ) : (
          <></>
      )
    }
                                                    
                                                    >
    <div className={
    ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

      }>
                                            <div className={styles.modern_container}>
                                                <div className={styles.modern_inputs}>
                                                <span className={styles.modern_label}>
                                                Période
                                                </span>
                                                <div class={styles.modern_input_wrapper}>
                                                    <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                    onChange={handleDateDebutChange}
                                                    />
                                                    <span className={styles.timeSeparator}>-</span>
                                                    <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                                </div>
                                                </div>
                                            </div>
                                            {errorMessage && idModifier &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }
                                              {errorMessage && idDelimiter &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }

                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Absence
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                         <select className={styles.goldenInputDisabled} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" disabled>
                                                                           <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                          <option value="Autre">Autre</option>
                                                                       </select>
                                                      </>:<>
                                                      <select className={styles.goldenInput1} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" >
                                                                          <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                           <option value="Autre">Autre</option>
                                                                      </select>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre de jours
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre d'heures
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div  className={styles.divBtnSubmit}>
                                                  {(!dateDebut || !nombreHeure || !nombreJour || !absence
                                                  || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                  <>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                  :<>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                }
                                            </div>
                                            

                                    </div>
    </PopupComponentGstTemps>

    {showTest && (
                    <PopupComponentGstTemps
                        onClose={() => {setShowTest(false);setIdTest()}}
                        show={showTest}
                        width="400px"
                        height="250px"
                        popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                    >
                        <div className={styles.popUpDeleteContent}>
                          <div className={styles.firstPartDelete}>
                            <div className={styles.textDelete}>
                            Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                            </div>
                          </div>
                          <div className={styles.thirdPartDelete}>
                            <ButtonValider textButton={"Annuler"}
                              buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                            <div></div>
                            <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                          </div>
                        </div>



                  </PopupComponentGstTemps>
                )}
    </>}  
    component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
    }
    />}
    section3={
    <>
    {
      ( dataUsed && headerTest) && 
        <TableComponent data={dataUsed} cols={headerTest} 
        addAction={true} 
        contentAction={methodsList}
        componentsList={componentsList}
        clickableRow={false}
        idDelete={idTest}
        idModify={idModifier}
        idDelimit={idDelimiter}

    />  
    }
    </>    
    } 
    section4={
              <FourthSectionTablePage 
              component1={ 
              <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
              } 
              component2={
              <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
            } 
              component3={
              <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
            }
              /> 
            } 
    NbreSections={4} 
    prctgeSection1={8}
    prctgeSection2={6} 
    prctgeSection3={80}
    prctgeSection4={6}
    />
    </div>
    <div className={styles.tablet}>
<GeneralCardTest 
section1={
<TopMenuPaie page="absence"/>
}  
section2={
  <SecondSectionTablePageTablet 
  nbreComponents={4}
  component1={<DescriptionPaieComponent/>
  } 
  component2={
     <ListColumns listColumn={listAbsenceResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
  component3={<>
  <div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="400px" height="280px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
  <div className={
    ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

      }>
                                            <div className={styles.modern_container}>
                                                <div className={styles.modern_inputs}>
                                                <span className={styles.modern_label}>
                                                Période
                                                </span>
                                                <div class={styles.modern_input_wrapper}>
                                                    <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                    onChange={handleDateDebutChange}
                                                    />
                                                    <span className={styles.timeSeparator}>-</span>
                                                    <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                                </div>
                                                </div>
                                            </div>
                                            {errorMessage && idModifier &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }
                                              {errorMessage && idDelimiter &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }

                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Absence
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                         <select className={styles.goldenInputDisabled} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" disabled>
                                                                           <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                          <option value="Autre">Autre</option>
                                                                       </select>
                                                      </>:<>
                                                      <select className={styles.goldenInput1} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" >
                                                                          <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                           <option value="Autre">Autre</option>
                                                                      </select>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre de jours
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre d'heures
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div  className={styles.divBtnSubmit}>
                                                  {(!dateDebut || !nombreHeure || !nombreJour || !absence
                                                  || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                  <>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                  :<>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                }
                                            </div>
                                            

                                    </div>
    </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="400px"
                      height="250px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                          <ButtonValider textButton={"Annuler"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
  </>}  
  component4={
  <LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
  }
  />}
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
            {
              ( dataUsed && headerTest) && 
                <TableComponent data={dataUsed} cols={headerTest} 
                addAction={true} 
                contentAction={methodsList}
                componentsList={componentsList}
                clickableRow={false}
                idDelete={idTest}
                idModify={idModifier}
                idDelimit={idDelimiter}
                //  condition={showTest}

            />  
            }
            </div>   
                } 
section4={
        <FourthSectionTablePageTablet 
        component1={ 
          <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
          component2={
          <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
        } 
          component3={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }/>

        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={styles.mobile}>
<GeneralCardTest 
 section1={
<TopMenuPaie page="absence"/>
}  
section2={
  <SecondSectionTablePageMobile
  nbreComponents={5}
  component1={<DescriptionPaieComponent/>
  } 
  component2={
    <>
<div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="250px" height="200px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
    <div className={
    ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

      }>
                                            <div className={styles.modern_container}>
                                                <div className={styles.modern_inputs}>
                                                <span className={styles.modern_label}>
                                                Période
                                                </span>
                                                <div class={styles.modern_input_wrapper}>
                                                    <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                    onChange={handleDateDebutChange}
                                                    />
                                                    <span className={styles.timeSeparator}>-</span>
                                                    <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                                </div>
                                                </div>
                                            </div>
                                            {errorMessage && idModifier &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }
                                              {errorMessage && idDelimiter &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }

                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Absence
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                         <select className={styles.goldenInputDisabled} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" disabled>
                                                                           <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                          <option value="Autre">Autre</option>
                                                                       </select>
                                                      </>:<>
                                                      <select className={styles.goldenInput1} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" >
                                                                          <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                           <option value="Autre">Autre</option>
                                                                      </select>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre de jours
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre d'heures
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div  className={styles.divBtnSubmit}>
                                                  {(!dateDebut || !nombreHeure || !nombreJour || !absence
                                                  || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                  <>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                  :<>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                }
                                            </div>
                                            

                                    </div>
    </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="250px"
                      height="200px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                          <ButtonValider textButton={"Annuler"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
              </>

   }
  component3={
     <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>

  }  
  component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
  }
  component5={ 
    <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
    } 
  />}
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
    ( dataUsed && headerTest) && 
      <TableComponent data={dataUsed} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={false}
      idDelete={idTest}
      idModify={idModifier}
      idDelimit={idDelimiter}
      //  condition={showTest}

  />  
  }
  </div>   
                } 
section4={
        <FourthSectionTablePageMobile 
          component1={
            <ListColumns listColumn={listAbsenceResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />
        } 
          component2={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }
        />
        } 
NbreSections={4} 
prctgeSection1={7}
prctgeSection2={6} 
prctgeSection3={81}
prctgeSection4={6}
/>
</div>
<div className={styles.mobileInversePetit}>
<GeneralCardTest 
section1={
    <TopMenuPaie page="absence"/>
} 
section2={
<SecondSectionTablePageMobileInversePetit 
nbreComponents={5}
component1={<DescriptionPaieComponent/>
} 
component2={
  <>
<div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="300px" height="180px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
      <div className={
    ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

      }>
                                            <div className={styles.modern_container}>
                                                <div className={styles.modern_inputs}>
                                                <span className={styles.modern_label}>
                                                Période
                                                </span>
                                                <div class={styles.modern_input_wrapper}>
                                                    <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                    onChange={handleDateDebutChange}
                                                    />
                                                    <span className={styles.timeSeparator}>-</span>
                                                    <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                                </div>
                                                </div>
                                            </div>
                                            {errorMessage && idModifier &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }
                                              {errorMessage && idDelimiter &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }

                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Absence
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                         <select className={styles.goldenInputDisabled} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" disabled>
                                                                           <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                          <option value="Autre">Autre</option>
                                                                       </select>
                                                      </>:<>
                                                      <select className={styles.goldenInput1} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" >
                                                                          <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                           <option value="Autre">Autre</option>
                                                                      </select>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre de jours
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre d'heures
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div  className={styles.divBtnSubmit}>
                                                  {(!dateDebut || !nombreHeure || !nombreJour || !absence
                                                  || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                  <>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                  :<>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                }
                                            </div>
                                            

                                    </div>
    </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="300px"
                      height="180px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                          <ButtonValider textButton={"Annuler"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
              </>
}

component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
component5={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
/>

}  
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
 {
    ( dataUsed && headerTest) && 
      <TableComponent data={dataUsed} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={false}
      idDelete={idTest}
      idModify={idModifier}
      idDelimit={idDelimiter}
      //  condition={showTest}

  />  
  }
</div>
                } 
section4={
        <FourthSectionTablePageMobileInversePetit 
        component1={ 
           <ListColumns listColumn={listAbsenceResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}

          component2={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }
        
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div>
<div className={styles.mobileInverseGrand}>
<GeneralCardTest 
section1={
<TopMenuPaie page="absence"/>
}
section2={
<SecondSectionTablePageMobileInverseGrand 
nbreComponents={4}
component1={<DescriptionPaieComponent/>
} 
component2={
  <ListColumns listColumn={listAbsenceResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
component3={
  <>
  <div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="350px" height="200px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
     <div className={
    ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

      }>
                                            <div className={styles.modern_container}>
                                                <div className={styles.modern_inputs}>
                                                <span className={styles.modern_label}>
                                                Période
                                                </span>
                                                <div class={styles.modern_input_wrapper}>
                                                    <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                    onChange={handleDateDebutChange}
                                                    />
                                                    <span className={styles.timeSeparator}>-</span>
                                                    <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                                </div>
                                                </div>
                                            </div>
                                            {errorMessage && idModifier &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }
                                              {errorMessage && idDelimiter &&
                                                <div className={styles.errorMessageStyle}>
                                                  {errorMessage}
                                                </div>
                                            }

                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Absence
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                         <select className={styles.goldenInputDisabled} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" disabled>
                                                                           <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                          <option value="Autre">Autre</option>
                                                                       </select>
                                                      </>:<>
                                                      <select className={styles.goldenInput1} value={absence} onChange={e => setAbsence(e.target.value)} id="choix" >
                                                                          <option></option>
                                                                           <option value="Maladie">Maladie</option>
                                                                           <option value="Autre">Autre</option>
                                                                      </select>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre de jours
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreJour} onChange={(e) => setNombreJour(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                    Nombre d'heures
                                                    </div>
                                                    {
                                                      idDelimiter ?
                                                      <>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)} disabled/>
                                                      </>:<>
                                                    <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreHeure} onChange={(e) => setNombreHeure(e.target.value)}/>
                                                      </>
                                                    }
                                                </div>
                                            </div>
                                            <div  className={styles.divBtnSubmit}>
                                                  {(!dateDebut || !nombreHeure || !nombreJour || !absence
                                                  || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                  <>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                  :<>
                                                  <div className={styles.sectionButtonEnregistrer}> 
                                                    <div></div>
                                                    <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                    </div>
                                                  </>
                                                }
                                            </div>
                                            

                                    </div>
    </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="350px"
                      height="200px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                          <ButtonValider textButton={"Annuler"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
  </>
}
component4={ 
  <LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
} 
/>

} 
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
    ( dataUsed && headerTest) && 
      <TableComponent data={dataUsed} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={false}
      idDelete={idTest}
      idModify={idModifier}
      idDelimit={idDelimiter}
      //  condition={showTest}

  />  
  }
</div>  
                } 
section4={
        <FourthSectionTablePageMobileInverseGrand  
        component1={ 
          <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
          component2={
          <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
        } 
          component3={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div> 

</div>
    )
}


export default AbsenceSection;