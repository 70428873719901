import React from 'react';
import styles from './TopMenuGestionDuTemps.module.css';
import {useEffect, useState, useRef} from 'react';
import {useCookies} from 'react-cookie';
import APIService from '../../APIService';


function TopMenuGestionDuTemps(props) {

    const [show, setShow] = useState(false);
    const popupRef = useRef(null);
    const barButtonRef = useRef(null);
  
    // const handleClick = (event) => {
    //   if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)) {
    //     setShow(false);
    //   }
    // };
  
    const showMenu = () => {
      setShow(prevShow => !prevShow);
      if (show) {
        console.log("Show True : ", show)
            popupRef.current.classList.remove(styles.fadeOut);
        setTimeout(() => {
              popupRef.current.style.display = 'none';
              console.log("New ref after .51s : ", popupRef.current.style.display)
          }, 510); 
    }else{
      console.log("Show False : ", show)
      popupRef.current.classList.add(styles.fadeIn);
      popupRef.current.style.display = 'block';
    }
    };

    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)
    ) {
      showMenu()
      }
    };
  
    useEffect(() => {
      if (show) {
        document.addEventListener('mousedown', handleClick);
      } else {
        document.removeEventListener('mousedown', handleClick);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, [show]);
  
  
    const [showAdministrationMenu, setShowAdministrationMenu] = useState(false);
    const [showPlannificationMenu, setShowPlannificationMenu] = useState(false);
    const [showGestionMenu, setShowGestionMenu] = useState(false);
  
    const [styleAdministration, setStyleAdministration] = useState()
    const [stylePlanification, setStylePlanification] = useState({backgroundColor:"transparent"})
    const [styleGestion, setStyleGestion] = useState({backgroundColor:"transparent"})
  
    function showMenuAdministraion() {
      if(showAdministrationMenu){
        setShowAdministrationMenu(false)
        setStyleAdministration({})
  
  
      }else{
        setShowAdministrationMenu(true)
        setStyleAdministration({backgroundColor:"#ddd", color:"black"})
  
        setShowPlannificationMenu(false)
        setStylePlanification({})
  
        setShowGestionMenu(false)
        setStyleGestion({})
  
  
      }
    }
  
  
    function showMenuPlannification() {
      if(showPlannificationMenu){
        setShowPlannificationMenu(false)
        setStylePlanification({})
  
  
      }else{
        setShowPlannificationMenu(true)
        setStylePlanification({backgroundColor:"#ddd", color:"black"})
  
        setShowAdministrationMenu(false)
        setStyleAdministration({})
  
        setShowGestionMenu(false)
        setStyleGestion({})
        
      }
    }
  
    function showMenuGestion() {
      if(showGestionMenu){
        setShowGestionMenu(false)
        setStyleGestion({})
  
  
      }else{
        setShowGestionMenu(true)
        setStyleGestion({backgroundColor:"#ddd", color:"black"})
  
  
        setShowPlannificationMenu(false)
        setStylePlanification({})
  
        setShowAdministrationMenu(false)
        setStyleAdministration({})
      }
    }

  var styleDashboard = {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};
  var styleActivite = {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};
  var styleDeclarationActivite = {backgroundColor:"",color: "grey"};
  var styleHistoriqueActivite = {backgroundColor:"",color: "grey"};
  var styleAbsence = {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};
  var styleDemandeAbsence = {backgroundColor:"",color: "grey"};
  var styleHistoriqueAbsence = {backgroundColor:"",color: "grey"};
  var stylePlanning = {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};
  var styleTaches = {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};

  const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
  const [role, setRole] = useState();


    useEffect(() => {

      if(token['mytoken']){

        APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));

  
      }
  
    }, [token])

    useEffect(() => {
      if(role){
        if(role === "Salarie"){

          if(document.getElementById("dashboard")){
            let elem = document.getElementById("dashboard");
            elem.parentNode.removeChild(elem);
          }

          if(document.getElementById("listTaches")){
            let elem = document.getElementById("listTaches");
            elem.parentNode.removeChild(elem);
          }

        }
      }
    }, [role])


if(props.page){
  if(props.page === "dashboard"){
    styleDashboard ={borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
  }
  if(props.page === "declarationActivite"){
    styleActivite = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
    styleDeclarationActivite = {backgroundColor:"goldenrod",color:"white",transform:"initial !important"};

  }
  if(props.page === "historiqueActivite"){
    styleActivite = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
    styleHistoriqueActivite = {backgroundColor:"goldenrod",color:"white",transform:"initial !important"};
  }

  if(props.page === "demandeAbsence"){
    styleAbsence = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
    styleDemandeAbsence = {backgroundColor:"goldenrod",color:"white",transform:"initial !important"};

  }
  if(props.page === "historiqueAbsence"){
    styleAbsence = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
    styleHistoriqueAbsence = {backgroundColor:"goldenrod",color:"white",transform:"initial !important"};
  }
  if(props.page === "planning"){
    stylePlanning = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
  }
  if(props.page === "taches"){
    styleTaches = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
  }
}
// test
const [showSublistActivite, setShowSublistActivite] = useState(false);
const [showSublistAbsence, setShowSublistAbsence] = useState(false);
const [caretDirectionActivite, setCaretDirectionActivite] = useState('down');
const [caretDirectionAbsence, setCaretDirectionAbsence] = useState('down');


const toggleSublistActivite = () => {
  setShowSublistActivite(!showSublistActivite);
  setCaretDirectionActivite(showSublistActivite ? 'down' : 'up');
  // setShowSublistAbsence(false);
};

const toggleSublistAbsence = () => {
  setShowSublistAbsence(!showSublistAbsence);
  setCaretDirectionAbsence(showSublistAbsence ? 'down' : 'up');
  // setShowSublistActivite(false);
};
  
  return (
  
    <div className={styles.sectionStyle}>
    <div className={styles.emptycontainer}>
      <div className={styles.firstPart}></div>
      <div className={styles.container}>
          <div className={styles.topMenuContainer} id={styles.idTopNavBar}>
                    <div className={styles.vide}></div>
                    <div className={styles.column} id="dashboard" style={styleDashboard}>
                      <a href="dashboard" className={styles.txtLinkBtn}>
                        <button className={styles.classBtnCalendrier}  >
                        Dashboard
                           </button>
                            </a>
                    </div>
                    <div className={styles.vide}></div>
                    <div className={`${styles.column} ${styles.dropdownActivite}`} id="contrat" style={styleActivite}>
                      <a className={`${styles.txtLinkBtn} ${styles.dropbtnActivite}`}><button className={styles.classBtnCalendrier} >
                                Activité
                               &nbsp;
                               <i className="fa-solid fa-caret-down"></i>
                        </button> </a>
                        <div className={styles["dropdown-content-Activite"]}>
                                <div className={styles.textStyle}> 
                                 Activité
                                &nbsp;
                                <i className="fa-solid fa-caret-up"></i>
                                </div>
                                <a href="declaration" style={styleDeclarationActivite}>Déclaration</a>
                              <a href="historiqueActivite" style={styleHistoriqueActivite}>Historique</a>
                            </div>
                    </div>
                    <div className={styles.vide}></div>
                    <div className={`${styles.column} ${styles.dropdownAbsence}`} id="contrat"  style={styleAbsence} >
                      <a className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier}>
                      Absence
                      &nbsp;
                      <i className="fa-solid fa-caret-down"></i>
                        </button> </a>
                        <div className={styles["dropdown-content-Absence"]}>
                                <div className={styles.textStyle}>
                                Absence
                                &nbsp;
                                <i className="fa-solid fa-caret-up"></i>
                                </div>
                                <a href="demandeAbsence" style={styleDemandeAbsence}>Demande</a>
                                <a href="historiqueAbsence" style={styleHistoriqueAbsence}>Historique</a>
                           </div>
                    </div>
                    <div  className={styles.vide}></div>
                    <div className={styles.column} id="planning" style={stylePlanning}>
                      <a href="PlanningAbsence" className={styles.txtLinkBtn}>
                        <button className={styles.classBtnCalendrier} > Plannings des absences</button> </a>
                    </div>
                    <div className={styles.vide}></div>
                    <div className={styles.column} id="taches" style={styleTaches}>
                      <a href="listeTaches" className={styles.txtLinkBtn}>
                        <button className={styles.classBtnCalendrier} > Liste des taches </button> </a>
                    </div> 
                    <div className={styles.vide}></div>
          </div>
      </div>
      <div className={styles.lastPart}></div>
    </div>
    {/* ****************MOBILE ************** */}
    <div className={styles.emptycontainerMobile}>
      <div className={styles.firstPartMobile}>
           <span className={styles.element} ref={barButtonRef} onClick={() => showMenu()} style={{ backgroundColor: show ? "rgb(218, 165, 32,80%)" : "" }}>
                <i className="fa fa-bars"></i>
          </span>
          <div id={styles.itemsMenu}  ref={popupRef}   className={show ? `${styles.active}` : `${styles.hidden}`}>
            <div className={styles.containerPopUP}>
            <div className={styles.buttonMobileContainer}>
              <div className={styles.buttonMobile} style={styleDashboard}>
                <a href="dashboard"  className={styles.txtLinkBtn} >
                  <button  className={styles.btnMenu} > 
                  Dashboard
                  </button> 
                </a>
              </div>
                <div className={styles.buttonMobile} style={styleActivite}>
                  <a className={styles.txtLinkBtn}>
                    <button className={styles.classBtnCalendrier} onClick={toggleSublistActivite}>
                      Activité
                      &nbsp;
                      {/* <i className="fa-solid fa-caret-down"></i> */}
                      <i className={`fa-solid fa-caret-${caretDirectionActivite}`}></i>
                    </button>
                  </a>
                  <div className={styles.sublistContainer} style={{ display: showSublistActivite ? 'flex' : 'none' }}>
                    <a href="declaration" className={styles.sublistPage} style={styleDeclarationActivite}>
                      Déclaration
                    </a>
                    <a href="historiqueActivite" className={styles.sublistPage} style={styleHistoriqueActivite}>
                      Historique
                    </a>
                  </div>
                </div>
                <div className={styles.buttonMobile} style={{ ...(showSublistActivite ? { marginTop: '50.7%' } : {}), ...styleAbsence }}>
                  <a className={styles.txtLinkBtn}>
                    <button className={styles.classBtnCalendrier} onClick={toggleSublistAbsence}>
                      Absence
                      &nbsp;
                      {/* <i className="fa-solid fa-caret-down"></i> */}
                      <i className={`fa-solid fa-caret-${caretDirectionAbsence}`}></i>
                    </button>
                  </a>
                  <div className={styles.sublistContainer} style={{ display: showSublistAbsence ? 'flex' : 'none' }}>
                    <a href="historiqueAbsence" className={styles.sublistPage} style={styleHistoriqueAbsence}>
                      Historique 
                      {/* <br /> d'Absence */}
                    </a>
                    <a href="demandeAbsence" className={styles.sublistPage} style={styleDemandeAbsence}>
                      Demande 
                      {/* <br /> d'Absence */}
                    </a>
                  </div>
                </div>
                {/* 85% */}
                <div className={styles.buttonMobile} style={{ ...(showSublistAbsence ? { marginTop: '50.7%' } : {}), ...stylePlanning }}>
                  <a href="PlanningAbsence" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}>Plannings des absences</button>
                  </a>
                </div>
              </div>
              <div className={styles.buttonMobile} style={styleTaches}>
                <a href="listeTaches" className={styles.txtLinkBtn}>
                  <button className={styles.btnMenu}  > 
                  Liste des taches
                  </button> 
                </a>
              </div>
              </div>
          </div>
      </div>
      <div className={styles.secondPartMobile}>
                {
                  props.page == "dashboard" ?
                  <>
                  <div className={styles.buttonShowingMobile}>
                  <div className={styles.btnMenu_}  > 
                  Dashboard
                  </div> 
                  </div>   
                  </>:<></>
                }
                {
                  props.page == "declarationActivite" ?
                  <>
                  <div className={styles.buttonShowingMobile}>
                  <button className={styles.btnMenu_} > 
                  Déclaration d'Activité
                  </button> 
                  </div>   
                  </>:<></>
                }
                {
                  props.page == "historiqueActivite" ?
                  <>
                  <div className={styles.buttonShowingMobile}>
                  <button className={styles.btnMenu_}> 
                  Historique d'Activité
                  </button> 
                  </div>   
                  </>:<></>
                }
                {
                  props.page == "demandeAbsence" ?
                  <>
                  <div className={styles.buttonShowingMobile}>
                  <button className={styles.btnMenu_}> 
                  Demande d'Absence
                  </button> 
                  </div>   
                  </>:<></>
                }
                {
                  props.page == "historiqueAbsence" ?
                  <>
                  <div className={styles.buttonShowingMobile}>
                  <button className={styles.btnMenu_}> 
                  Historique d'Absence
                  </button> 
                  </div>   
                  </>:<></>
                }
                {
                  props.page == "planning" ?
                  <>
                  <div className={styles.buttonShowingMobile}>
                  <button className={styles.btnMenu_}> 
                  Plannings des absences
                  </button> 
                  </div>   
                  </>:<></>
                }
                {
                  props.page == "taches" ?
                  <>
                  <div className={styles.buttonShowingMobile}>
                  <button className={styles.btnMenu_}> 
                  Liste des taches
                  </button> 
                  </div>   
                  </>:<></>
                }
      </div>
    </div>

  </div>
          
        
  )
}

export default TopMenuGestionDuTemps