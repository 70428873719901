import React from 'react';
import styles from './GestionTempsCard.module.css';


import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import APIService from './../../../../APIService';


function GestionTempsCard(props) {
  var styleDashboard = {backgroundColor:""};
  var styleActivite = {backgroundColor:""};
  var styleDeclarationActivite = {backgroundColor:""};
  var styleHistoriqueActivite = {backgroundColor:""};
  var styleAbsence = {backgroundColor:""};
  var styleDemandeAbsence = {backgroundColor:""};
  var styleHistoriqueAbsence = {backgroundColor:""};
  var stylePlanning = {backgroundColor:""};
  var styleTaches = {backgroundColor:""};

  const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
  const [role, setRole] = useState();


    useEffect(() => {

      if(token['mytoken']){

        APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));

  
      }
  
    }, [token])

    useEffect(() => {
      if(role){
        if(role === "Salarie"){

          if(document.getElementById("dashboard")){
            let elem = document.getElementById("dashboard");
            elem.parentNode.removeChild(elem);
          }

          if(document.getElementById("listTaches")){
            let elem = document.getElementById("listTaches");
            elem.parentNode.removeChild(elem);
          }

        }
      }
    }, [role])


if(props.page){
  if(props.page === "dashboard"){
    styleDashboard = {backgroundColor:"goldenrod"};
  }
  if(props.page === "declarationActivite"){
    styleActivite = {backgroundColor:"goldenrod"};
    styleDeclarationActivite = {backgroundColor:"goldenrod"};

  }
  if(props.page === "historiqueActivite"){
    styleActivite = {backgroundColor:"goldenrod"};
    styleHistoriqueActivite = {backgroundColor:"goldenrod"};
  }

  if(props.page === "demandeAbsence"){
    styleAbsence = {backgroundColor:"goldenrod"};
    styleDemandeAbsence = {backgroundColor:"goldenrod"};

  }
  if(props.page === "historiqueAbsence"){
    styleAbsence = {backgroundColor:"goldenrod"};
    styleHistoriqueAbsence = {backgroundColor:"goldenrod"};
  }
  if(props.page === "planning"){
    stylePlanning = {backgroundColor:"goldenrod"};
  }
  if(props.page === "taches"){
    styleTaches = {backgroundColor:"goldenrod"};
  }
}
 
  
  return (
  
          <div className="row" id={styles.idTopNavBar}>

                    {
                      role === "Manager" ?
                      <>
                      <div className="col" id="dashboard">
                        <a href="dashboard" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleDashboard} > Dashboard </button> </a>
                    </div>
                      </>
                      :<></>
                    }
                  
                    
                    
                    { role === "Salarie" ?
                    
                    <>
                    <div className="col" id="formation">
                      <a href="declaration" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleDeclarationActivite} > Déclaration d'activité </button> </a>
                    </div> 

                    <div className="col" id="formation">
                      <a href="demandeAbsence" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleDemandeAbsence} > Demande d'absence </button> </a>
                    </div> 
                    
                    
                    </>:<>
                    {
                      role === "Manager" ? 

                      <>
                      
                      <div className="col" id="contrat">
                        <div className={styles.dropdownActivite}>
                            <button className={styles.dropbtnActivite} style={styleActivite}>
                            Activité
                            &nbsp;
                            <i  class="fa fa-angle-down" aria-hidden="true"></i>
                            
                            </button>
                            <div className={styles["dropdown-content-Activite"]}>
                                <a href="declaration" style={styleDeclarationActivite}>Déclaration</a>
                                <a href="historiqueActivite" style={styleHistoriqueActivite}>Historique</a>
                            </div>
                        </div>
                    </div>
                    <div className="col" id="contrat">
                        <div className={styles.dropdownAbsence}> 
                          
                            <button className={styles.dropbtnAbsence} style={styleAbsence}>
                            Absence
                            &nbsp;
                            <i  class="fa fa-angle-down" aria-hidden="true"></i>
     
                               
                              
                               </button>
                            <div className={styles["dropdown-content-Absence"]}>
                                <a href="demandeAbsence" style={styleDemandeAbsence}>Demande</a>
                                <a href="historiqueAbsence" style={styleHistoriqueAbsence}>Historique</a>
                            </div>
                        </div>
                    </div>
                      
                      
                      </>:<></>
                    }
                    
                    
                    </>
                  
                  }

                  {
                      role === "Manager" || role === "Salarie" ?
                      <>
                      
                        <div className="col" id="formation">
                          <a href="PlanningAbsence" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={stylePlanning} > Plannings des absences </button> </a>
                        </div>
                      </>
                      :<></>
                    } 


                    


                    {
                      role === "Manager" ?
                      <>
                      <div className="col" id="listTaches">
                      <a href="listeTaches" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={{styleTaches, width:"100%"}}  > Liste des taches </button> </a>
                    </div> 
                    
                      </>
                      :<></>
                    } 
                    
          </div>
          
        
  )
}

export default GestionTempsCard