import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import DemandeAbsenceAdministrationSection from './DemandeAbsenceAdministrationSection.jsx';



export const DemandeAbsenceAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<DemandeAbsenceAdministrationSection />} />
    {/* ok demande absence  */}
   
 </div>
)}


export default DemandeAbsenceAdministration
