import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import VilleSection from './VilleSection.jsx';



export const Ville = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<VilleSection />} />
    
   
 </div>
)}


export default Ville


