import React from 'react'
import { useState } from 'react'
import APIService from './../../../APIService.js';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import download from './../../img/download.png';
// import validate from 'deep-email-validator'
import Styles from './SaisieSection.module.css';
import Pdf from './../../Pdf';
import EmbaucheNavBar from '../EmbaucheGlobal/EmbaucheNavBar.jsx';
import Menu from "./Menu/Menu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMessageExclamaion, faXmark, faSquareExclamation,faTriangleExclamation, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {faCheck, faMoneyCheck,faDatabase, faFile , faFileCircleCheck, faIdCard, faBrain, faCheckToSlot, } from '@fortawesome/free-solid-svg-icons'
import Alert from "./../EmbaucheGlobal/Alerts/Alert";
import {useNavigate} from 'react-router-dom';
import Input from './inputs/Input';
import InputTemps from './inputTemps/InputTemps';



// document.getElementById('iban').addEventListener('input', function (e) {
//             e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
//           });

function SaisieSection() {


      // var input = document.getElementById("iban");

      // input.onkeydown = function () {
      //     if (input.value.length > 0) {
      
      //         if (input.value.length % 4 == 0) {
      //             input.value += "    ";
      //         }
      //     }
      // }
      var dateSortie = ''

      const [respFromServer, setRespFromServer] = useState()
      const [output, setOutput] = useState(false)

      const handleUpload = async (event) => {
            var formdata = new FormData();
    
            formdata.append("carteVitalePath", carteVitale, carteVitale.name);
            if (CV)
                  {formdata.append("cinPath", CIN, CIN.name);}
            if (TS)
                  {formdata.append("tsPath", TS, TS.name);}
            // else
            //       {formdata.append("tsPath", '', '');}
                  
            formdata.append("ribPath", RIB, RIB.name);
            formdata.append("cvPath", CV, CV.name);
    
    
            var requestOptions = {
                method: 'POST', 
                body: formdata
              };
    
            fetch("http://127.0.0.1:8000/extraction", requestOptions)
            .then(response => response.json())
            .then(result => setRespFromServer(result.result))
            .catch(error => console.log('error', error));
    
            setOutput(true)
        
        }
      
      const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeManager', 'matriculeEmployeAttente', 'matriculeActuel'])
      const donneesSalariesFormInitiale = {nom:"", prenom:"", sexe:"", numeroSS:"", cle:"", dateNaissance:"", paysNaissance:"", departementNaissance:"", nationalite:"", nationalite2:"", situationFamiliale:"", nombreEnfants:""};
      const affectationFormInitiale = {etablissement:"", departement:"", poste:"", typeContrat:"", dateDebut:"",dateFin:"", modeTravail:"", nbrHeuresHebdo:"", jourRepos1:"", jourRepos2:"" };
      const coordonneesFormInitiale = {NRue:"", NomRue:"", complementAdresse:"", chez:"", lieuDeNaissance:"", pays:"", codePostale:"", ville:"", telephoneFixe:"", telephoneMobile:"", email:""}
      const competencesFormInitiale = {anneeFormation1:"", formation1:"",anneeFormation2:"", formation2:"",anneeFormation3:"", formation3:"",anneeFormation4:"", formation4:"", anneeExperience1:"", experience1:"",anneeExperience2:"", experience2:"",anneeExperience3:"", experience3:"",anneeExperience4:"", experience4:"", langue1:"", niveauLangue1:"", langue2:"", niveauLangue2:"", langue3:"", niveauLangue3:"", langue4:"", niveauLangue4:"", competence:""}
      const idBancaireFormInitiale = {iban:"", banque:"", domiciliation:"", numeroCompte:"", codeBanque:"", codeGuichet:"", cleRib:""}
      const validationFormInitiale = {heurePrisePoste:""}
      const [donneesSalariesValeurs, setDonneesSalariesValeurs] = useState(donneesSalariesFormInitiale)
      const [affectationValeurs, setAffectationValeurs] = useState(affectationFormInitiale)
      const [coordonneesValeurs, setCoordonneesValeurs] = useState(coordonneesFormInitiale)
      const [competencesValeurs, setCompetencesValeurs] = useState(competencesFormInitiale)
      const [idBancaireValeurs, setIdBancaireValeurs] = useState(idBancaireFormInitiale)
      const [validationValeurs, setValidationValeurs] = useState(validationFormInitiale)
      const [etat, setEtat] = useState('bancaire')
      
      const [etatNationalite2, setEtatNationalite2] = useState(false)
      const [etatAjouterFormation1, setEtatAjouterFormation1] = useState(false)
      const [etatAjouterFormation2, setEtatAjouterFormation2] = useState(false)
      const [etatAjouterFormation3, setEtatAjouterFormation3] = useState(false)
      const [etatAjouterExperience1, setEtatAjouterExperience1] = useState(false)
      const [etatAjouterExperience2, setEtatAjouterExperience2] = useState(false)
      const [etatAjouterExperience3, setEtatAjouterExperience3] = useState(false)
      const [etatAjouterLangue1, setEtatAjouterLangue1] = useState(false)
      const [etatAjouterLangue2, setEtatAjouterLangue2] = useState(false)
      const [etatAjouterLangue3, setEtatAjouterLangue3] = useState(false)
      const [departement, setDepartement] = useState('') 
      const [nom, setNom] = useState('')
      // const [dateSortie, setDateSortie] =useState('')
      const [prenom, setPrenom] = useState('')
      const [dateDebut, setDateDebut] = useState('')
      const [contrat, setContrat] = useState('')
      const [etatValidation, setEtatValidation] = useState(0)
      

      //-----------------------Submition-------------------------

      const [isSubmit, setIsSubmit] = useState(false);
      const [isSubmitAffectation, setIsSubmitAffectation] = useState(false);
      const [isSubmitCoordonnees, setIsSubmitCoordonnees] = useState(false);
      const [isSubmitCompetences, setIsSubmitCompetences] = useState(false);
      const [isSubmitIdBancaire, setIsSubmitIdBancaire] = useState(false);


      //-----------------------FormErrors-------------------------

      const [formErrors, setFormErrors] = useState({});
      const [formErrorsAffectation, setFormErrorsAffectation] = useState({});
      const [formErrorsCoordonnees, setFormErrorsCoordonnees] = useState({});
      const [formErrorsCompetences, setFormErrorsCompetences] = useState({});
      const [formErrorsIdBancaire, setFormErrorsIdBancaire] = useState({});
      const [formErrorsValidation, setFormErrorsValidation] = useState({});
      const [formErrorsFichiers, setFormErrorsFichiers] = useState({});

      //-----------------------Administration-------------------------
      const [listSexe, setListSexe] = useState(null);
      const [listTypeContrat, setListTypeContrat] = useState(null)
      const [listJours, setListJours] = useState(null)
      const [listTemps, setListTemps] = useState(null)
      const [listBanque, setListBanque] = useState(null)
      const [listEtablissement, setListEtablissement] = useState(null)
      const [listDepartement, setListDepartement] = useState(null);
      const [listPoste, setListPoste] = useState(null);
      const [listPays, setListPays] = useState(null);
      const [listVille, setListVille] = useState(null);
      const [listDepartementVille, setListDepartmentVille] = useState(null);
      const [listNationalite, setListNationalite] = useState(null);
      const [listSituationFamiliale, setListSituationFamiliale] = useState(null);
      const [listCodePostale, setListCodePostale] = useState(null);
      const [listLangue, setListLangue] = useState(null);
      const [listNiveauLanguesAdministration, setListNiveauLanguesAdministration] = useState(null)


        useEffect(() => {

            if(etat){
                  console.log("Lol etat : ", etat)
                  if(donneesSalariesValeurs.nom){
                        console.log("donneesSalariesValeurs.nom : ", donneesSalariesValeurs.nom)

                  }
            }
          
        }, [etat, donneesSalariesValeurs.nom])
        
      

      // Etat 
      useEffect(() => {

            if(token['mytoken']){
                APIService.getNiveauLanguesAdministration(token)
                .then(resp => setListNiveauLanguesAdministration(resp))
            }
          
        }, [token['mytoken']])
      const [etatDateFinDisabled, setEtatDateFinDisabled] = useState(null);


        useEffect(() => {

            if(affectationValeurs.typeContrat){
                  console.log("affectationValeurs.typeContrat : ", affectationValeurs.typeContrat)
                  if(parseInt(affectationValeurs.typeContrat) === 1){
                        setEtatDateFinDisabled(true)
                  }
                  else {
                        setEtatDateFinDisabled(false)

                  }

            }

        }, [affectationValeurs.typeContrat])
      useEffect(() => {
            if(listVille && listPays && listCodePostale && coordonneesValeurs.ville && coordonneesValeurs.ville !== ""){
                  let target = {name: null, value: null};
                  //     Pays
                  
                  if(!coordonneesValeurs.pays || coordonneesValeurs.pays === ""){
                       
                              target.name = 'pays'

                              listVille.filter(
                                    ville => ville.id === parseInt(coordonneesValeurs.ville)
                              ).map(
                                    ville => target.value = ville.id_pays
                              )
                        

                              // console.log("Pays : ", target)

                        handleChangeCoordonnees({target: target})

                        
                  }

            }
      }, [coordonneesValeurs.ville])
      
      useEffect(() => {

            if(listVille && listPays && listCodePostale && coordonneesValeurs.codePostale && coordonneesValeurs.codePostale !== ""){
               let target = {name: null, value: null};
               console.log("target : ", target.name)
                
            //    Ville
                  if(!coordonneesValeurs.ville || coordonneesValeurs.ville === ""){
                        target.name = 'ville'
                        listCodePostale.filter(
                              code => code.id === parseInt(coordonneesValeurs.codePostale)
                        ).map(
                              code => target.value = code.id_ville
                        )

                        handleChangeCoordonnees({target: target})

                        
                  }
            
                 
               

            }          

        }, [coordonneesValeurs.codePostale])


        useEffect(() => {

            if(token['mytoken']){
                APIService.getLangueAdministration(token)
                .then(resp => setListLangue(resp))
            }
          
        }, [token['mytoken']])


      useEffect(() => {

            if(token['mytoken']){
                APIService.getCodePostale(token)
                .then(resp => setListCodePostale(resp))
            }
          
        }, [token['mytoken']])

      useEffect(() => {

            if(token['mytoken']){
                APIService.getSituationFamilialeAdministration(token)
                .then(resp => setListSituationFamiliale(resp))
            }
          
        }, [token['mytoken']])
      useEffect(() => {

            if(token['mytoken']){
                APIService.getNationalite(token)
                .then(resp => setListNationalite(resp))
            }
          
        }, [token['mytoken']])

      useEffect(() => {

            if(token['mytoken']){
                APIService.getDepartementville(token)
                .then(resp => setListDepartmentVille(resp))
            }
          
        }, [token['mytoken']])

        useEffect(() => {

            if(token['mytoken']){
                APIService.getVille(token)
                .then(resp => setListVille(resp))
            }
          
        }, [token['mytoken']])
      

      useEffect(() => {

            if(token['mytoken']){
                APIService.getPays(token)
                .then(resp => setListPays(resp))
            }
          
        }, [token['mytoken']])

      useEffect(() => {

            if(token['mytoken']){
                APIService.getSexeAdministration(token)
                .then(resp => setListSexe(resp))
            }
          
        }, [token['mytoken']])

        useEffect(() => {
            if (token['mytoken']) {
              APIService.getPoste(token)
                .then((resp) => setListPoste(resp))
                .catch((error) => console.error('Erreur lors de la récupération des données :', error));
        
              APIService.getDepartement(token)
                .then((resp) => setListDepartement(resp))
                .catch((error) => console.error('Erreur lors de la récupération des établissements :', error));
            }
          }, [token['mytoken']]);

        useEffect(() => {
            if (token['mytoken']) {
              APIService.getDepartement(token)
                .then((resp) => setListDepartement(resp))
                .catch((error) => console.error('Erreur lors de la récupération des données :', error));
        
              APIService.getEtablissement(token)
                .then((resp) => setListEtablissement(resp))
                .catch((error) => console.error('Erreur lors de la récupération des établissements :', error));
            }
          }, [token['mytoken']]);
        useEffect(() => {

            if(token['mytoken']){
                APIService.getTypeContrat(token)
                .then(resp => setListTypeContrat(resp))
            }
          
        }, [token['mytoken']])

        useEffect(() => {

            if(token['mytoken']){
                APIService.getJours(token)
                .then(resp => setListJours(resp))
            }
          
        }, [token['mytoken']])
        
    useEffect(() => {

      if(token['mytoken']){
          APIService.getTemps(token)
          .then(resp => setListTemps(resp))
      }
    
  }, [token['mytoken']])

  useEffect(() => {

      if(token['mytoken']){
          APIService.getBanque(token)
          .then(resp => setListBanque(resp))
      }
    
  }, [token['mytoken']])

  useEffect(() => {

      if(token['mytoken']){
          APIService.getEtablissement(token)
          .then(resp => setListEtablissement(resp))
      }
    
  }, [token['mytoken']])

      const [etatReembauche, setEtatReembauche] = useState(false)


      const [employe, setEmploye] = useState({})
      let navigate = useNavigate()

      const [dataFichier, setDataFichier] = useState({}) 


      

        useEffect(() => {
          if(affectationValeurs.modeTravail){
            console.log("affectationValeurs.modeTravail :", affectationValeurs.modeTravail)
          }
        }, [affectationValeurs.modeTravail])
        



      // -----------------------------------

      const [users, setUsers] = useState([])
    const [afficher, SetAfficher] = useState(false)

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          // navigate('/')
          window.location.href = '/'
          localStorage.clear()
  
      }
    }, [token])
    
    


    useEffect(() => {
        APIService.getUsers(token['mytoken'])
      .then(resp => setUsers(resp))
    },token['mytoken'])


    useEffect(() => {
        if(users.length){
          SetAfficher(true)
        }
          
        
    }, [users])
      

      useEffect(() => {
            if(token['id']){
                  APIService.getEmploye(token['id'], token)
                  .then(resp => setEmploye(resp))
                  .catch(error => console.log(error))

                  setEtatReembauche(true)
            }
          }, [token['id']]);

      
      useEffect(() => {
            if(token['matriculeEmployeAttente'] ){
                  APIService.getEmployeTemporaire(token['matriculeEmployeAttente'], token)
                  .then(resp => setEmploye(resp))
                  .catch(error => console.log(error))
            }
          }, [token['matriculeEmployeAttente']]);
      
      
      
     
      
      
      function setValeursDonnees(valeurs){
            setDonneesSalariesValeurs(valeurs)
      }
      function setValeursAffectation(valeurs){
            setAffectationValeurs(valeurs)
      }
      function setValeursCoordonnees(valeurs){
            setCoordonneesValeurs(valeurs)
      }
      function setValeursCompetences(valeurs){
            setCompetencesValeurs(valeurs)
      }
      function setValeursIdBancaire(valeurs){
            setIdBancaireValeurs(valeurs)
      }
      
      const [fichiers, setFichiers] = useState([])
      const [fichier, setFichier] = useState({})
      


      




      useEffect(()=>{

            if(employe){
                  setValeursDonnees({nom:employe.nom_employe, prenom:employe.prenom_employe, sexe:employe.sexe_employe, numeroSS:employe.numeroSS_employe, cle:employe.cle_employe, dateNaissance:employe.dateNaissance_employe, paysNaissance:employe.paysNaissance_employe, departementNaissance:employe.departementNaissance_employe, nationalite:employe.nationnalite1_employe, nationalite2:employe.nationnalite2_employe, situationFamiliale:employe.situationFamiliale_employe, nombreEnfants:employe.nombreEnfant_employe})
                  setValeursAffectation({etablissement:employe.etablissement_employe, departement:employe.departement_employe, poste:employe.poste_employe, typeContrat:employe.typeContrat_employe, dateDebut:employe.dateEntree_employe,dateFin:employe.dateSortie_employe, modeTravail:employe.modeTravail_employe, nbrHeuresHebdo:employe.plageHoraire_employe, jourRepos1:employe.jourRepos1_employe, jourRepos2:employe.jourRepos2_employe })
                  setValeursCoordonnees({NRue:employe.NRue_employe, NomRue:employe.nomRue_employe, complementAdresse:employe.complementAdresse_employe, chez:employe.chez_employe, lieuDeNaissance:"", pays:employe.pays_employe, codePostale:employe.codePostale_employe, ville:employe.ville_employe, telephoneFixe:employe.telephoneFixe_employe, telephoneMobile:employe.tel_employe, email:employe.email_employe})
                  setValeursCompetences({anneeFormation1:employe.anneeFormation1_employe, formation1:employe.formation1_employe,anneeFormation2:employe.anneeFormation2_employe, formation2:employe.formation2_employe,anneeFormation3:employe.anneeFormation3_employe, formation3:employe.formation3_employe,anneeFormation4:employe.anneeFormation4_employe, formation4:employe.formation4_employe, anneeExperience1:employe.anneeExperience1_employe, experience1:employe.experience1_employe,anneeExperience2:employe.anneeExperience2_employe, experience2:employe.experience2_employe,anneeExperience3:employe.anneeExperience3_employe, experience3:employe.experience3_employe,anneeExperience4:employe.anneeExperience4_employe, experience4:employe.experience4_employe, langue1:employe.langue1_employe, niveauLangue1:employe.niveauLangue1_employe, langue2:employe.langue2_employe, niveauLangue2:employe.niveauLangue2_employe, langue3:employe.langue3_employe, niveauLangue3:employe.niveauLangue3_employe, langue4:employe.langue4_employe, niveauLangue4:employe.niveauLangue4_employe, competence:employe.competence_employe})
                  setValeursIdBancaire({iban:employe.iban_employe, banque:employe.banque_employe, domiciliation:employe.domiciliation_employe, numeroCompte:employe.numeroCompte_employe, codeBanque:employe.codeBanque_employe, codeGuichet:employe.codeGuichet_employe, cleRib:employe.cleRib_employe})
                  setValidationValeurs({heurePrisePoste:employe.heurePrisePoste_employe})
                  setCarteVitale(employe.carteVitale_employe)
                  setCIN(employe.cin_employe)
                  setRIB(employe.rib_employe)
                  setCV(employe.cv_employe)
                  setTS(employe.ts_employe)
                  if(employe.cin_employe){
                        setEtatChoiceCIN(true)
                        setInputChoiceCIN("btnAfficherExtract")
                  }else{
                        if(employe.ts_employe){
                              setEtatChoiceTS(true)
                              setInputChoiceTS("btnAfficherExtract")
                        }
                  }
                  // if(token['id']){
                  //       APIService.getFichiers(token).then(
                  //             resp => setFichiers(resp)
                  //       )
                  // }
                  // else{
                  //       if(token['matriculeEmployeAttente']){
                  //             APIService.getFichieTemporaire(token['matriculeEmployeAttente'],token).then(
                  //                   resp => setFichier(resp)
                  //             )
                  //       }
                  // }
                  // setEtatReembauche(true)
            }
           


      },[employe])
        
      useEffect(() => {
            setFichier(fichiers.filter(fichier => fichier.matricule_employe === employe.matricule_employe && fichier.dateEntree_employe === employe.dateEntree_employe && fichier.dateModification_employe === employe.dateModification_employe))
      }, [fichiers])

      useEffect(() => {
            if(fichier.data){
                  setCarteVitale(fichier.data.carteVitale_employe)
                  setCIN(fichier.data.cin_employe)
                  setRIB(fichier.data.rib_employe)
                  setCV(fichier.cv_employe)
                  setTS(fichier.ts_employe)

                  if(fichier.data.cin_employe){
                        setEtatChoiceCIN(true)
                        setInputChoiceCIN("btnAfficherExtract")
                  }else{
                        if(fichier.ts_employe){
                              setEtatChoiceTS(true)
                              setInputChoiceTS("btnAfficherExtract")
                        }
                  }
                  

            }
      }, [fichier])

      useEffect(() => {
            
            if(fichier[0]){
                  setCarteVitale(fichier[0].carteVitale_employe)
                  setCIN(fichier[0].cin_employe)
                  setRIB(fichier[0].rib_employe)
                  setCV(fichier[0].cv_employe)
                  setTS(fichier[0].ts_employe)

                  if(fichier[0].cin_employe){
                        setEtatChoiceCIN(true)
                        setInputChoiceCIN("btnAfficherExtract")
                  }else{
                        if(fichier[0].ts_employe){
                              setEtatChoiceTS(true)
                              setInputChoiceTS("btnAfficherExtract")
                        }
                  }

            }
            
            
      }, [fichier[0]])



      



      function isEmail(val) {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!regEmail.test(val)){
              return false;
            }else{
                  return true;
            }
        }
      function setDepartementNaissance(dept){
            donneesSalariesValeurs.departementNaissance = dept;
      }
      function setVille(ville){
            coordonneesValeurs.ville = ville;
      }

      function underAgeValidate(birthday){
            // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
            var optimizedBirthday = birthday.replace(/-/g, "/");
      
            //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
            var myBirthday = new Date(optimizedBirthday);
      
            // set current day on 01:00:00 hours GMT+0100 (CET)
            var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
      
            // calculate age comparing current date and borthday
            var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
      
            if(myAge < 18) {
                     return false;
              }else{
                return true;
            }
      
      } 

      
      const handleChangeDonneesSalaries = (e) => {

            // e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, "");
            // e.target.value = e.clipboardData.getData('Text').trim();*
            e.target.value = e.target.value.replace(/^\s+/g, "");

            e.target.value = e.target.value.replace(/ +(?= )/g, "");
            

            if(e.target.name === 'nom' || e.target.name === 'prenom'){

                 
                  // e.target.value = e.target.value.replace(/[^A-Za-z]/g,"");
                  e.target.value = e.target.value.replace(/[^a-zA-Z\u00C0-\u017F-\s]/g,"");
                  e.target.value = e.target.value.toUpperCase() 
                  

            }

            if(e.target.value === 'nombreEnfants'){
                  // e.target.value = e.target.value.replace(/^[0-9]/g, "")
                  e.target.value = e.target.value.replace(/^[0-9\b]+$/,"")
            }
            

            
            const {name, value} = e.target;
            
            setDonneesSalariesValeurs({...donneesSalariesValeurs, [name]: value});
            
      }

      const handleChangeAffectation = (e) => {
          
            
            e.target.value = e.target.value.replace(/^\s+/g, "");

            e.target.value = e.target.value.replace(/ +(?= )/g,"");
      

            if( e.target.name === 'nbrHeuresHebdo' ){
                  e.target.value = e.target.value.replace(/^(.{0,3}).*$/, '$1');
                  e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                  let numericValue = parseInt(e.target.value, 10);
                  if (isNaN(numericValue) || numericValue < 0) {
                  numericValue = 0;
                  } else if (numericValue > 120) {
                  numericValue = 120;
                  }
                  e.target.value = numericValue.toString();
                

                 }

            const {name, value} = e.target;
           
            setAffectationValeurs({...affectationValeurs, [name]: value});

          
      
                   }


      const handleChangeValidation = (e) => {
            const {name, value} = e.target;

            setValidationValeurs({...validationValeurs, [name]: value});
      }

      const handlePhoneChange = (value, country, event) => {
            
            setCoordonneesValeurs({
              ...coordonneesValeurs,
              [event.target.name]: value,
            });
          };
      
      const handleChangeCoordonnees = (e) => {
            // e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, "");
            // e.target.value = e.clipboardData.getData('Text').trim();*

            if(Object.keys(e.target).length === 2){
                  console.log("Handle value : ", e.target.value)
                  if(e.target.name === 'ville' || e.target.name === 'pays'){
                        const {name, value} = e.target;
                        setCoordonneesValeurs({...coordonneesValeurs, [name]: value});
                  }



            } else {
                  e.target.value = e.target.value.replace(/^\s+/g, "");

                  e.target.value = e.target.value.replace(/ +(?= )/g,"");
      
                  if(e.target.name === 'NomRue' || e.target.name === 'NRue' || e.target.name === 'complementAdresse' || e.target.name === 'chez'){
      
                        
                        // e.target.value = e.target.value.replace(/[^A-Za-z]/g,"");
                        e.target.value = e.target.value.replace(/[^a-zA-Z\u00C0-\u017F0-9\s]/g,"");
                        e.target.value = e.target.value.toUpperCase() 
                        
      
                  }
      
                  if(e.target.name === 'codePostale' ){
      
                       
                        // e.target.value = e.target.value.replace(/[^A-Za-z]/g,"");
                        e.target.value = e.target.value.replace(/[^0-9\s]/g,"");
                        // e.target.value = e.target.value.toUpperCase() 
                        
      
                  }
                 
            
      
                  
                  const {name, value} = e.target;
                 
                  setCoordonneesValeurs({...coordonneesValeurs, [name]: value});
            }
      
            
      }
      
      
      const handleChangeCompetences = (e) => {
            // e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, "");
            // e.target.value = e.clipboardData.getData('Text').trim();*
            e.target.value = e.target.value.replace(/^\s+/g, "");

            e.target.value = e.target.value.replace(/ +(?= )/g,"");

            if(e.target.name === 'competence'){

                  e.target.value = e.target.value.replace(/[^A-Za-z\u00C0-\u017F0-9\s]/g,"");

            }

            if(e.target.name === 'formation1' || e.target.name === 'formation2' || e.target.name === 'formation3'
            || e.target.name === 'formation4' || e.target.name === 'experience1' || e.target.name === 'experience2'
            || e.target.name === 'experience3'|| e.target.name === 'experience4' ){

                 
                  // e.target.value = e.target.value.replace(/[^A-Za-z]/g,"");
                  e.target.value = e.target.value.replace(/[^A-Za-z\u00C0-\u017F0-9\s]/g,"");
                  e.target.value = e.target.value.toUpperCase() 
                  

            }
      

            
            const {name, value} = e.target;

            if(value === 'undefined'){

            setCompetencesValeurs({...competencesValeurs, [name]: null});
                  

            } else {
                  setCompetencesValeurs({...competencesValeurs, [name]: value});
            }
           
      }
      
function handleChangeIdBancaire1(event) {
  // Récupération de la valeur du champ
  const valeur = event.target.value;
  // Suppression des espaces
  const valeurSansEspaces = valeur.replace(/\s/g, '');
  // Ajout des espaces tous les 4 caractères
  const valeurAvecEspaces = valeurSansEspaces.match(/.{1,4}/g).join(' ');
  // Mise à jour de la valeur du champ
  event.target.value = valeurAvecEspaces;
}


      const handleChangeIdBancaire = (e) => {
            
            // e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, "");
            // e.target.value = e.clipboardData.getData('Text').trim();*
            e.target.value = e.target.value.replace(/^\s+/g, "");

            e.target.value = e.target.value.replace(/ +(?= )/g,"");

            if(e.target.name === 'banque' || e.target.name === 'domiciliation' || e.target.name === 'iban' ){

                  e.target.value = e.target.value.replace(/[^A-Za-z\u00C0-\u017F0-9\s]/g,"");
            }
            if( e.target.name === 'iban' ){
             e.target.value = e.target.value.replace(/[^A-Za-z0-9]{0,16}|([A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{4}[0-9]{7}([A-Za-z0-9]?){0,16})/g, '');
             e.target.value = e.target.value.replace(/^(.{0,28}).*$/, '$1');
             e.target.value = e.target.value.replace(/[^A-Za-z0-9\s]/g, '');


            }

            if(e.target.name === 'numeroCompte' ){
                

                  e.target.value = e.target.value.replace(/[^A-Za-z\u00C0-\u017F0-9\s]/g,"");
                  e.target.value = e.target.value.replace(/\s/g,"");
                  e.target.value = e.target.value.replace(/^(.{0,12}).*$/, '$1');

            }
            if(e.target.name === 'codeBanque'  ){
                  e.target.value = e.target.value.replace(/^(.{0,5}).*$/, '$1');

                  
            }
            if(e.target.name === 'codeGuichet'  ){
                  e.target.value = e.target.value.replace(/^(.{0,5}).*$/, '$1');

                  
            }
            if(e.target.name === 'cleRib'  ){
                  e.target.value = e.target.value.replace(/^(.{0,2}).*$/, '$1');

                  
            }

            if(e.target.name === 'codeBanque' || e.target.name === 'codeGuichet' || e.target.name === 'cleRib' ){

                  e.target.value = e.target.value.replace(/[^0-9\s]/g,"");
                  e.target.value = e.target.value.replace(/\s/g,"");
                  
            }
      

            
            const {name, value} = e.target;

            console.log("ook : ", e.target.value);
           
            setIdBancaireValeurs({...idBancaireValeurs, [name]: value});
      }
      const btnFichiers = () => {

            setEtat('donnees')

      }
      
      const btnDonnees = () => {

            setFormErrors(validate(donneesSalariesValeurs));
            // setFormErrors(formErrors)
            setIsSubmit(true);    
            setEtatValidation(0)
      }

      const btnAffectation = () => {
            setFormErrorsAffectation(validateAffectation(affectationValeurs));
            setIsSubmitAffectation(true);
            setEtatValidation(0)
      }

      const btnCoordonnes = () => {
            setFormErrorsCoordonnees(validateCoordonnees(coordonneesValeurs));
            setIsSubmitCoordonnees(true);
            setEtatValidation(0)
      }

      const btnCompetences = () => {
            setFormErrorsCompetences(validateCompetences(competencesValeurs));
            setIsSubmitCompetences(true);
            setEtatValidation(0)

      }
      const btnBancaire = () => {
            setFormErrorsIdBancaire(validateIdBancaire(idBancaireValeurs));
            setIsSubmitIdBancaire(true);
            setEtatValidation(0)
      }
      
      const btnInitiale = () => {
            setEtat('fichiers')
            setEtatValidation(0)
      }

      useEffect(() => {

            if (Object.keys(formErrors).length === 0 && isSubmit) {
            
            setEtat('affectation')
            }
          }, [formErrors]);

      
      useEffect(() => {
            if (Object.keys(formErrorsAffectation).length === 0 && isSubmitAffectation) {
            
            setEtat('coordonnees')
            }
          }, [formErrorsAffectation]);

      useEffect(() => {
            if (Object.keys(formErrorsCoordonnees).length === 0 && isSubmitCoordonnees) {
            
            setEtat('competences')
            }
          }, [formErrorsCoordonnees]);

      useEffect(() => {
            if (Object.keys(formErrorsCompetences).length === 0 && isSubmitCompetences) {
            
            setEtat('bancaire')
            }
          }, [formErrorsCompetences]);
      
      useEffect(() => {
            if (Object.keys(formErrorsIdBancaire).length === 0 && isSubmitIdBancaire) {
            setEtat('validation')

            }
          }, [formErrorsIdBancaire]);


      //Validation champ : iban
      useEffect(() => {
            
            if((typeof idBancaireValeurs.iban === 'undefined' || idBancaireValeurs.iban === "") && Object.keys(formErrorsIdBancaire).length === 0){
                        console.log('')
            }else{
                  if(!idBancaireValeurs.iban) {
                        formErrorsIdBancaire.iban = "L'IBAN est obligatoire !";
                  }else{
                        formErrorsIdBancaire.iban = "";
                  }  
            } 
      }, 
      [idBancaireValeurs.iban])

      //Validation champ : banque
      useEffect(() => {
            if((typeof idBancaireValeurs.banque === 'undefined' || idBancaireValeurs.banque === "") && Object.keys(formErrorsIdBancaire).length === 0){
                        console.log('')
            }else{
                  if(!idBancaireValeurs.banque) {
                        formErrorsIdBancaire.banque = "La banque est obligatoire !";
                  }else{
                        formErrorsIdBancaire.banque = "";
                  }  
            } 
      }, 
      [idBancaireValeurs.banque])


      //Validation champ : numeroCompte
      useEffect(() => {
            if((typeof idBancaireValeurs.numeroCompte === 'undefined' || idBancaireValeurs.numeroCompte === "") && Object.keys(formErrorsIdBancaire).length === 0){
                        console.log('')
            }else{
                  if(!idBancaireValeurs.numeroCompte) {
                        formErrorsIdBancaire.numeroCompte = "Le numero de compte est obligatoire !";
                  }else{
                        formErrorsIdBancaire.numeroCompte = "";
                  }  
            } 
      }, 
      [idBancaireValeurs.numeroCompte])

      //Validation champ : codeBanque
      useEffect(() => {
            if((typeof idBancaireValeurs.codeBanque === 'undefined' || idBancaireValeurs.codeBanque === "") && Object.keys(formErrorsIdBancaire).length === 0){
                        console.log('')
            }else{
                  if(!idBancaireValeurs.codeBanque) {
                        formErrorsIdBancaire.codeBanque = "Le code de la banque est obligatoire !";
                  }else{
                        formErrorsIdBancaire.codeBanque = "";
                  }  
            } 
      }, 
      [idBancaireValeurs.codeBanque])

      //Validation champ : codeGuichet
      useEffect(() => {
            if((typeof idBancaireValeurs.codeGuichet === 'undefined' || idBancaireValeurs.codeGuichet === "") && Object.keys(formErrorsIdBancaire).length === 0){
                        console.log('')
            }else{
                  if(!idBancaireValeurs.codeGuichet) {
                        formErrorsIdBancaire.codeGuichet = "Le code du guichet est obligatoire !";
                  }else{
                        formErrorsIdBancaire.codeGuichet = "";
                  }  
            } 
      }, 
      [idBancaireValeurs.codeGuichet])

      //Validation champ : cleRib
//     {  idBancaireValeurs.numeroCompte &&
//             <input
//             type="text"
//             value={idBancaireValeurs.numeroCompte}
//             onChange={(e) => handleChangeIdBancaire(e, "inputNumeroCompte")}
//             maxLength="12" 
//           />
//       }

//       { idBancaireValeurs.numeroCompte &&
//             <input
//               type="text"
//               value={idBancaireValeurs.numeroCompte}
//               onChange={(e) => handleChangeIdBancaire(e, "inputNumeroCompte")}
//               maxLength="12"
//             />
//           }
          

      // <input type="text" className={Styles.goldenInput1} maxLength="12" id={inputNumeroCompte} name='numeroCompte' value={idBancaireValeurs.numeroCompte} onChange={handleChangeIdBancaire}/>


      useEffect(() => {
            if((typeof idBancaireValeurs.cleRib === 'undefined' || idBancaireValeurs.cleRib === "") && Object.keys(formErrorsIdBancaire).length === 0){
                        console.log('')
            }else{
                  if(!idBancaireValeurs.cleRib) {
                        formErrorsIdBancaire.cleRib = "La clé RIB est obligatoire !";
                  }else{
                        formErrorsIdBancaire.cleRib = "";
                  }  
            } 
      }, 
      [idBancaireValeurs.cleRib])
      
      const validateIdBancaire = (valeurs) => {

            const errors = {};

            if(!valeurs.iban){
                  errors.iban = "L'IBAN est obligatoire !";

            }
            if(!valeurs.banque){
                  errors.banque = "La banque est obligatoire !";

            }
            if(!valeurs.numeroCompte){
                  errors.numeroCompte = "Le numero de compte est obligatoire !";

            }
            if(!valeurs.codeBanque){
                  errors.codeBanque = "Le code de la banque est obligatoire !";

            }
            if(!valeurs.codeGuichet){
                  errors.codeGuichet = "Le code du guichet est obligatoire !";

            }
            if(!valeurs.cleRib){
                  errors.cleRib = "La clé RIB est obligatoire !";

            }

            return errors;

      };   

      //Validation champ : anneeFormation1
      useEffect(() => {
            if((typeof competencesValeurs.anneeFormation1 === 'undefined' || competencesValeurs.anneeFormation1 === "") && Object.keys(formErrorsCompetences).length === 0){
                        console.log('')
            }else{
                  if(!competencesValeurs.anneeFormation1) {
                        formErrorsCompetences.anneeFormation1 = "L'année de la formation est obligatoire !";
                  }else{
                        formErrorsCompetences.anneeFormation1 = "";
                  }  
            } 
      }, 
      [competencesValeurs.anneeFormation1])

      //Validation champ : formation1
      useEffect(() => {
            if((typeof competencesValeurs.formation1 === 'undefined' || competencesValeurs.formation1 === "") && Object.keys(formErrorsCompetences).length === 0){
                        console.log('')
            }else{
                  if(!competencesValeurs.formation1) {
                        formErrorsCompetences.formation1 = "L'intitulé de la formation est obligatoire !";
                  }else{
                        formErrorsCompetences.formation1 = "";
                  }  
            } 
      }, 
      [competencesValeurs.formation1])

      //Validation champ : anneeExperience1
      useEffect(() => {
            if((typeof competencesValeurs.anneeExperience1 === 'undefined' || competencesValeurs.anneeExperience1 === "") && Object.keys(formErrorsCompetences).length === 0){
                        console.log('')
            }else{
                  if(!competencesValeurs.anneeExperience1) {
                        formErrorsCompetences.anneeExperience1 = "L'année de l'expérience est obligatoire !";
                  }else{
                        formErrorsCompetences.anneeExperience1 = "";
                  }  
            } 
      }, 
      [competencesValeurs.anneeExperience1])

      //Validation champ : experience1
      useEffect(() => {
            if((typeof competencesValeurs.experience1 === 'undefined' || competencesValeurs.experience1 === "") && Object.keys(formErrorsCompetences).length === 0){
                        console.log('')
            }else{
                  if(!competencesValeurs.experience1) {
                        formErrorsCompetences.experience1 = "L'intitulé de l'expérience est obligatoire !";
                  }else{
                        formErrorsCompetences.experience1 = "";
                  }  
            } 
      }, 
      [competencesValeurs.experience1])

      //Validation champ : langue1
      useEffect(() => {
            if((typeof competencesValeurs.langue1 === 'undefined' || competencesValeurs.langue1 === "") && Object.keys(formErrorsCompetences).length === 0){
                        console.log('')
            }else{
                  if(!competencesValeurs.langue1) {
                        formErrorsCompetences.langue1 = "La langue est obligatoire !";
                  }else{
                        formErrorsCompetences.langue1 = "";
                  }  
            } 
      }, 
      [competencesValeurs.langue1])

      //Validation champ : niveauLangue1
      useEffect(() => {
            if((typeof competencesValeurs.niveauLangue1 === 'undefined' || competencesValeurs.niveauLangue1 === "") && Object.keys(formErrorsCompetences).length === 0){
                        console.log('')
            }else{
                  if(!competencesValeurs.niveauLangue1) {
                        formErrorsCompetences.niveauLangue1 = "Le niveau est obligatoire !";
                  }else{
                        formErrorsCompetences.niveauLangue1 = "";
                  }  
            } 
      }, 
      [competencesValeurs.niveauLangue1])


      const validateCompetences = (valeurs) => {

            const errors = {};

            if(!valeurs.anneeFormation1){
                  errors.anneeFormation1 = "L'année de la formation est obligatoire !";
            }
            if(!valeurs.formation1){
                  errors.formation1 = "L'intitulé de la formation est obligatoire !";
            }
            if(!valeurs.anneeExperience1){
                  errors.anneeExperience1 = "L'année de l'expérience est obligatoire !";
            }
            if(!valeurs.experience1){
                  errors.experience1 = "L'intitulé de l'expérience est obligatoire !";
            }
            if(!valeurs.langue1){
                  errors.langue1 = "La langue est obligatoire !";
            }
            if(!valeurs.niveauLangue1){
                  errors.niveauLangue1 = "Le niveau est obligatoire !";
            }

            return errors;

      };


      //Validation champ : NomRue
      useEffect(() => {
            if((typeof coordonneesValeurs.NomRue === 'undefined' || coordonneesValeurs.NomRue === "") && Object.keys(formErrorsCoordonnees).length === 0){
                        console.log('')
            }else{
                  if(!coordonneesValeurs.NomRue) {
                        formErrorsCoordonnees.NomRue = "Le nom de la rue est obligatoire !";
                  }else{
                        formErrorsCoordonnees.NomRue = "";
                  }  
            } 
      }, 
      [coordonneesValeurs.NomRue])

      //Validation champ : NRue
      useEffect(() => {
            if((typeof coordonneesValeurs.NRue === 'undefined' || coordonneesValeurs.NRue === "") && Object.keys(formErrorsCoordonnees).length === 0){
                        console.log('')
            }else{
                  if(!coordonneesValeurs.NRue) {
                        formErrorsCoordonnees.NRue = "Le numéro de la rue est obligatoire !";
                  }else{
                        formErrorsCoordonnees.NRue = "";
                  }  
            } 
      }, 
      [coordonneesValeurs.NRue])

      //Validation champ : pays
      useEffect(() => {
            if((typeof coordonneesValeurs.pays === 'undefined' || coordonneesValeurs.pays === "") && Object.keys(formErrorsCoordonnees).length === 0){
                        console.log('')
            }else{
                  if(!coordonneesValeurs.pays) {
                        formErrorsCoordonnees.pays = "Le pays est obligatoire !";
                  }else{
                        formErrorsCoordonnees.pays = "";
                  }  
            } 
      }, 
      [coordonneesValeurs.pays])

      //Validation champ : codePostale
      useEffect(() => {
            if((typeof coordonneesValeurs.codePostale === 'undefined' || coordonneesValeurs.codePostale === "") && Object.keys(formErrorsCoordonnees).length === 0){
                        console.log('')
            }else{
                  if(!coordonneesValeurs.codePostale) {
                        formErrorsCoordonnees.codePostale = "Le code postale est obligatoire !";
                  }else{
                        formErrorsCoordonnees.codePostale = "";
                  }  
            } 
      }, 
      [coordonneesValeurs.codePostale])

      //Validation champ : ville
      useEffect(() => {
            if((typeof coordonneesValeurs.ville === 'undefined' || coordonneesValeurs.ville === "") && Object.keys(formErrorsCoordonnees).length === 0){
                        console.log('')
            }else{
                  if(!coordonneesValeurs.ville) {
                        formErrorsCoordonnees.ville = "La ville est obligatoire !";
                  }else{
                        formErrorsCoordonnees.ville = "";
                  }  
            } 
      }, 
      [coordonneesValeurs.ville])

      

      //Validation champ : telephoneMobile
      useEffect(() => {
            if((typeof coordonneesValeurs.telephoneMobile === 'undefined' || coordonneesValeurs.telephoneMobile === "") && Object.keys(formErrorsCoordonnees).length === 0){
                        console.log('')
            }else{
                  if(!coordonneesValeurs.telephoneMobile) {
                        formErrorsCoordonnees.telephoneMobile = "Le téléphone mobile est obligatoire !";
                  }else{
                        formErrorsCoordonnees.telephoneMobile = "";
                  }  
            } 
      }, 
      [coordonneesValeurs.telephoneMobile])

      //Validation champ : email
      useEffect(() => {
            if((typeof coordonneesValeurs.email === 'undefined' || coordonneesValeurs.email === "") && Object.keys(formErrorsCoordonnees).length === 0){
                        console.log('')
            }else{
                  if(!coordonneesValeurs.email) {
                        formErrorsCoordonnees.email = "L'email est obligatoire !";
                  }else{
                        if(!isEmail(coordonneesValeurs.email)){
                              formErrorsCoordonnees.email = "Adresse e-mail non valide !";
                        }else{
                              formErrorsCoordonnees.email = "";
                        }
                        
                  }  
            } 
      }, 
      [coordonneesValeurs.email])
      

      const validateCoordonnees = (valeurs) => {

            const errors = {};

            if(!valeurs.NRue){
                  errors.NRue = "Le numéro de la rue est obligatoire !";
            }
            if(!valeurs.NomRue){
                  errors.NomRue = "Le nom de la rue est obligatoire !";
            }
            
            if(!valeurs.pays){
                  errors.pays = "Le pays est obligatoire !";
            }
            if(!valeurs.codePostale){
                  errors.codePostale = "Le code postale est obligatoire !";
            }
            if(!valeurs.ville){
                  errors.ville = "La ville est obligatoire !";
            }
            if(!valeurs.telephoneMobile){
                  errors.telephoneMobile = "Le téléphone mobile est obligatoire !";
            }
            if(!valeurs.email){
                  errors.email = "L'email est obligatoire !";
            }else{
                  if(!isEmail(valeurs.email)){
                        errors.email = "Adresse e-mail non valide !";
                  }
            }
            

            return errors;

      };

      //Validation champ : etablissement
      useEffect(() => {
            if((typeof affectationValeurs.etablissement === 'undefined' || affectationValeurs.etablissement === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{
                  if(!affectationValeurs.etablissement) {
                        formErrorsAffectation.etablissement = "L'établissement est obligatoire !";
                  }else{
                        formErrorsAffectation.etablissement = "";
                  }  
            } 
      }, 
      [affectationValeurs.etablissement])

      //Validation champ : departement

      useEffect(() => {
            if((typeof affectationValeurs.departement === 'undefined' || affectationValeurs.departement === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{
                  if(!affectationValeurs.departement) {
                        formErrorsAffectation.departement = "Le département est obligatoire !";
                  }else{
                        formErrorsAffectation.departement = "";
                  }  
            } 
      }, 
      [affectationValeurs.departement])

      //validation champ : heurePrisePoste

      useEffect(() => {
            if((typeof validationValeurs.heurePrisePoste === 'undefined' || validationValeurs.heurePrisePoste === "") && Object.keys(formErrorsValidation).length === 0){
                        console.log('')
            }else{
                  if(!validationValeurs.heurePrisePoste) {
                        formErrorsValidation.heurePrisePoste = "L'heure de prise de poste est obligatoire !";
                  }else{
                        formErrorsValidation.heurePrisePoste = "";
                  }  
            } 
      }, 
      [validationValeurs.heurePrisePoste])
      
      //Validation champ : poste
      useEffect(() => {
            if((typeof affectationValeurs.poste === 'undefined' || affectationValeurs.poste === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{
                  if(!affectationValeurs.poste) {
                        formErrorsAffectation.poste = "Le poste est obligatoire !";
                  }else{
                        formErrorsAffectation.poste = "";
                  }  
            } 
      }, 
      [affectationValeurs.poste])

      useEffect(() => {
            if((typeof affectationValeurs.modeTravail === 'undefined' || affectationValeurs.modeTravail === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{
                  if(!affectationValeurs.modeTravail) {
                        formErrorsAffectation.modeTravail = "Le mode de travail est obligatoire !";
                  }else{
                        formErrorsAffectation.modeTravail = "";
                  }  
            } 
      }, 
      [affectationValeurs.modeTravail])

      //Validation champ : typeContrat
      useEffect(() => {
            if((typeof affectationValeurs.typeContrat === 'undefined' || affectationValeurs.typeContrat === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{
                  if(!affectationValeurs.typeContrat) {
                        formErrorsAffectation.typeContrat = "Le type du contrat est obligatoire !";
                  }else{
                        formErrorsAffectation.typeContrat = "";
                  }  
            } 
      }, 
      [affectationValeurs.typeContrat])

      // Validation champ : dateDebut
      useEffect(() => {
                  if((typeof affectationValeurs.dateDebut === 'undefined' || affectationValeurs.dateDebut === "") && Object.keys(formErrorsAffectation).length === 0){
                              console.log('')
                  }else{
                        if(!affectationValeurs.dateDebut) {
                              formErrorsAffectation.dateDebut = "La date de début est obligatoire !";
                        }else{
                              // formErrorsAffectation.dateDebut = "";
                              var day = new Date(affectationValeurs.dateDebut).getUTCDay();
                 
                  
                  
                              if(affectationValeurs.dateDebut === employe.dateEntree_employe){

                                    formErrorsAffectation.dateDebut = "La date de début doit être différente !";
                  
                              }else{
                                    
                                          var jour1 = 0
                                          var jour2 = 0
                                          if(affectationValeurs.jourRepos1){
                                                
                                                if(affectationValeurs.jourRepos1 === "Dimanche")
                                                {jour1 = 0}
                                                if(affectationValeurs.jourRepos1 === "Lundi")
                                                {jour1 = 1}
                                                if(affectationValeurs.jourRepos1 === "Mardi")
                                                {jour1 = 2}
                                                if(affectationValeurs.jourRepos1 === "Mercredi")
                                                {jour1 = 3}
                                                if(affectationValeurs.jourRepos1 === "Jeudi")
                                                {jour1 = 4}
                                                if(affectationValeurs.jourRepos1 === "Vendredi")
                                                {jour1 = 5}
                                                if(affectationValeurs.jourRepos1 === "Samedi")
                                                {jour1 = 6}

                              
                                                }
                                                if(affectationValeurs.jourRepos2){
                                                
                                                      if(affectationValeurs.jourRepos2 === "Dimanche")
                                                      {jour2 = 0}
                                                      if(affectationValeurs.jourRepos2 === "Lundi")
                                                      {jour2 = 1}
                                                      if(affectationValeurs.jourRepos2 === "Mardi")
                                                      {jour2 = 2}
                                                      if(affectationValeurs.jourRepos2 === "Mercredi")
                                                      {jour2 = 3}
                                                      if(affectationValeurs.jourRepos2 === "Jeudi")
                                                      {jour2 = 4}
                                                      if(affectationValeurs.jourRepos2 === "Vendredi")
                                                      {jour2 = 5}
                                                      if(affectationValeurs.jourRepos2 === "Samedi")
                                                      {jour2 = 6}
                                    
                                                      }
                                                if(day === jour1 || day === jour2){

                                                      formErrorsAffectation.dateDebut = "La date de début doit être différente des jours de repos !";

                                                }else{
                                                      formErrorsAffectation.dateDebut = "";
                                                }
                                                
                                    
                              }
                        }  
                  } 
      }, 
      [affectationValeurs.dateDebut])

      //Validation champ : dateFin
      useEffect(() => {
            if((typeof affectationValeurs.dateFin === 'undefined' || affectationValeurs.dateFin === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{                  
                  if(affectationValeurs.typeContrat === 'CDD' || affectationValeurs.typeContrat==='Apprentissage' || affectationValeurs.typeContrat==='Stage'){

                  {if(!affectationValeurs.dateFin) {
                              formErrorsAffectation.dateFin = "La date de fin est obligatoire !";
                        }else{
                              if((affectationValeurs.dateFin < affectationValeurs.dateDebut) || (affectationValeurs.dateFin === affectationValeurs.dateDebut)){
            
                              formErrorsAffectation.dateFin = "La date de fin doit être supérieure à la date de début !";

                              }else{formErrorsAffectation.dateFin = "";}
                              
                        }  
                  }}

                  
            } 
      }, 
      [affectationValeurs.dateFin])

      useEffect(() => {
            if((typeof affectationValeurs.dateFin === 'undefined' || affectationValeurs.dateFin === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{                  
                  if(affectationValeurs.typeContrat === 'CDD' || affectationValeurs.typeContrat==='Apprentissage' || affectationValeurs.typeContrat==='Stage'){

                  {if(!affectationValeurs.dateFin) {
                              formErrorsAffectation.dateFin = "La date de fin est obligatoire !";
                        }else{
                              if((affectationValeurs.dateFin < affectationValeurs.dateDebut) || (affectationValeurs.dateFin === affectationValeurs.dateDebut)){
                              
                              formErrorsAffectation.dateFin = "La date de fin doit être supérieure à la date de début !";

                              }else{formErrorsAffectation.dateFin = "";}
                              
                        }  
                  }}

                  
            } 
      }, 
      [affectationValeurs.dateDebut])

      //Validation champ : nbrHeuresHebdo
      useEffect(() => {
            if((typeof affectationValeurs.nbrHeuresHebdo === 'undefined' || affectationValeurs.nbrHeuresHebdo === "") && Object.keys(formErrorsAffectation).length === 0){
                        console.log('')
            }else{
                  if(!affectationValeurs.nbrHeuresHebdo) {
                        formErrorsAffectation.nbrHeuresHebdo = "Le nombre d'heures hebdo est obligatoire !";
                  }else{
                        formErrorsAffectation.nbrHeuresHebdo = "";
                  }  
            } 
      }, 
      [affectationValeurs.nbrHeuresHebdo])
     
      
      const validateAffectation = (valeurs) => {

            const errors = {};
            if(!valeurs.modeTravail){
                  errors.modeTravail = "Le mode de travail est obligatoire !";
            }
            if(!valeurs.etablissement){
                  errors.etablissement = "L'établissement est obligatoire !";
            }
            if(!valeurs.departement){
                  errors.departement = "Le département est obligatoire !";
            }
            if(!valeurs.poste){
                  errors.poste = "Le poste est obligatoire !";
            }
            if(!valeurs.typeContrat){
                  errors.typeContrat = "Le type du contrat est obligatoire !";
            }
            
            if(!valeurs.dateDebut){
                  errors.dateDebut = "La date de début est obligatoire !";
            }else{
                  var day = new Date(valeurs.dateDebut).getUTCDay();
                 
                  
                  
                  if(valeurs.dateDebut === employe.dateEntree_employe){

                        errors.dateDebut = "La date de début doit être différente !";
      
                  }else{
                              var jour1 = 0
                              var jour2 = 0
                              if(valeurs.jourRepos1){
                                    
                                    if(valeurs.jourRepos1 === "Dimanche")
                                    {jour1 = 0}
                                    if(valeurs.jourRepos1 === "Lundi")
                                    {jour1 = 1}
                                    if(valeurs.jourRepos1 === "Mardi")
                                    {jour1 = 2}
                                    if(valeurs.jourRepos1 === "Mercredi")
                                    {jour1 = 3}
                                    if(valeurs.jourRepos1 === "Jeudi")
                                    {jour1 = 4}
                                    if(valeurs.jourRepos1 === "Vendredi")
                                    {jour1 = 5}
                                    if(valeurs.jourRepos1 === "Samedi")
                                    {jour1 = 6}

                  
                                    }
                                    if(valeurs.jourRepos2){
                                    
                                          if(valeurs.jourRepos2 === "Dimanche")
                                          {jour2 = 0}
                                          if(valeurs.jourRepos2 === "Lundi")
                                          {jour2 = 1}
                                          if(valeurs.jourRepos2 === "Mardi")
                                          {jour2 = 2}
                                          if(valeurs.jourRepos2 === "Mercredi")
                                          {jour2 = 3}
                                          if(valeurs.jourRepos2 === "Jeudi")
                                          {jour2 = 4}
                                          if(valeurs.jourRepos2 === "Vendredi")
                                          {jour2 = 5}
                                          if(valeurs.jourRepos2 === "Samedi")
                                          {jour2 = 6}
      
                        
                                          }
                                    
                                    



                                    if(day === jour1 || day === jour2){

                                          errors.dateDebut = "La date de début doit être différente des jours de repos !";

                                    }
                                    
                        
                  }
            }

            if(valeurs.typeContrat === 'CDD' || valeurs.typeContrat==='Apprentissage' || valeurs.typeContrat==='Stage'){
                  if(!valeurs.dateFin){
                        errors.dateFin = "La date de fin est obligatoire !";
                  }else{
                        
                        if((valeurs.dateFin < valeurs.dateDebut) || (valeurs.dateFin === valeurs.dateDebut)){

                              errors.dateFin = "La date de fin doit être supérieure à la date de début !";

                        }
                        
                  }
            }
            
            if(!valeurs.nbrHeuresHebdo){
                  errors.nbrHeuresHebdo = "Le nombre d'heures hebdo est obligatoire !";
            }
            if(!valeurs.jourRepos1){
                  errors.jourRepos1 = "Le jour de repos 1 est obligatoire !";
            }
            if(!valeurs.jourRepos2){
                  errors.jourRepos2 = "Le jour de repos 2 est obligatoire !";
            }

            return errors;

      };

      //Validation champ : nom
      useEffect(() => {
            if((typeof donneesSalariesValeurs.nom === 'undefined' || donneesSalariesValeurs.nom === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.nom) {
                        
                        formErrors.nom = "Le nom est obligatoire !";
                  }
                  else{
                        formErrors.nom = "";
                        
                        
                  }  
            } 
      }, 
      [donneesSalariesValeurs.nom])

      //Validation champ : prenom
      useEffect(() => {
            if((typeof donneesSalariesValeurs.prenom === 'undefined' || donneesSalariesValeurs.prenom === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.prenom) {
                        formErrors.prenom = "Le prenom est obligatoire !";
                  }else{
                        formErrors.prenom = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.prenom])

      //Validation champ : sexe
      useEffect(() => {
                  if((typeof donneesSalariesValeurs.sexe === 'undefined' || donneesSalariesValeurs.sexe === "") && Object.keys(formErrors).length === 0){
                              console.log('')
                  }else{
                        if(!donneesSalariesValeurs.sexe) {
                              formErrors.sexe = "Le sexe est obligatoire !";
                        }else{
                              formErrors.sexe = "";
                        }  
                  } 
      }, 
      [donneesSalariesValeurs.sexe])

      //Validation champ : numeroSS
      useEffect(() => {
            if((typeof donneesSalariesValeurs.numeroSS === 'undefined' || donneesSalariesValeurs.numeroSS === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.numeroSS) {
                        formErrors.numeroSS = "Le numero SS est obligatoire !";
                  }else{
                        formErrors.numeroSS = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.numeroSS])

      //Validation champ : cle
      useEffect(() => {
            if((typeof donneesSalariesValeurs.cle === 'undefined' || donneesSalariesValeurs.cle === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.cle) {
                        formErrors.cle = "La cle est obligatoire !";
                  }else{
                        formErrors.cle = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.cle])

      //Validation champ : paysNaissance
      useEffect(() => {
            if((typeof donneesSalariesValeurs.paysNaissance === 'undefined' || donneesSalariesValeurs.paysNaissance === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.paysNaissance) {
                        formErrors.paysNaissance = "Le pays de naissance est obligatoire !";
                  }else{
                        formErrors.paysNaissance = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.paysNaissance])

      //Validation champ : departementNaissance
      useEffect(() => {
            if((typeof donneesSalariesValeurs.departementNaissance === 'undefined' || donneesSalariesValeurs.departementNaissance === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.departementNaissance) {
                        formErrors.departementNaissance = "Le departement de naissance est obligatoire !";
                  }else{
                        formErrors.departementNaissance = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.departementNaissance])

      //Validation champ : nationalite
      useEffect(() => {
            if((typeof donneesSalariesValeurs.nationalite === 'undefined' || donneesSalariesValeurs.nationalite === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.nationalite) {
                        formErrors.nationalite = "La nationalité de naissance est obligatoire !";
                  }else{
                        formErrors.nationalite = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.nationalite])

      //Validation champ : situationFamiliale
      useEffect(() => {
            if((typeof donneesSalariesValeurs.situationFamiliale === 'undefined' || donneesSalariesValeurs.situationFamiliale === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.situationFamiliale) {
                        formErrors.situationFamiliale = "La situation familiale est obligatoire !";
                  }else{
                        formErrors.situationFamiliale = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.situationFamiliale])

      //Validation champ : nombreEnfants
      useEffect(() => {
            if((typeof donneesSalariesValeurs.nombreEnfants === 'undefined' || donneesSalariesValeurs.nombreEnfants === "") && Object.keys(formErrors).length === 0){
                        console.log('')
            }else{
                  if(!donneesSalariesValeurs.nombreEnfants) {
                        formErrors.nombreEnfants = "Le nombre d'enfants est obligatoire !";
                  }else{
                        formErrors.nombreEnfants = "";
                  }  
            } 
      }, 
      [donneesSalariesValeurs.nombreEnfants])
      const [etatErreurDateNaissance, setEtatErreurDateNaissance] = useState(false)

      //Validation champ : dateNaissance
      //Erreur

      useEffect(() => {
            
            if((typeof donneesSalariesValeurs.dateNaissance === 'undefined' || donneesSalariesValeurs.dateNaissance === "") && Object.keys(formErrors).length === 0){
                        console.log('')
                        
                        setEtatErreurDateNaissance(true)
            }else{
                  formErrors.dateNaissance = ""
                  
                  if(donneesSalariesValeurs.dateNaissance && etatErreurDateNaissance === true) {
                        if(!underAgeValidate(donneesSalariesValeurs.dateNaissance)){
                        formErrors.dateNaissance = "L'âge doit être supérieur à 18 ans !";
                        }
                  }

                  if(!donneesSalariesValeurs.dateNaissance && etatErreurDateNaissance === true) {
                        formErrors.dateNaissance="La date de naissance est obligatoire !";
                  }

            } 


      }, 
      [donneesSalariesValeurs.dateNaissance, formErrors.dateNaissance])



      const validate = (valeurs) => {

            const errors = {};

            if (!valeurs.nom) {
                  errors.nom = "Le nom est obligatoire !";
            }
            if (!valeurs.prenom) {
                  errors.prenom = "Le prenom est obligatoire !";
            }
            if(!valeurs.sexe) {
                  errors.sexe = "Le sexe est obligatoire !";
            }
            if(!valeurs.numeroSS) {
                  errors.numeroSS = "Le numero SS est obligatoire !";
            }
            if(!valeurs.cle) {
                  errors.cle = "La cle est obligatoire !";
            }



            if(!valeurs.dateNaissance) {
                  errors.dateNaissance = "La date de naissance est obligatoire !";
            }else {
                  if(!underAgeValidate(valeurs.dateNaissance)){

                        errors.dateNaissance = "L'âge doit être supérieur à 18 ans !";

                  }
            }

            if(!valeurs.paysNaissance){

                  errors.paysNaissance = "Le pays de naissance est obligatoire !";

            }
            if(!valeurs.departementNaissance){

                  errors.departementNaissance = "Le département de naissance est obligatoire !";

            }
            if(!valeurs.nationalite){

                  errors.nationalite = "La nationalité est obligatoire !";

            }
            if(!valeurs.situationFamiliale){
                  errors.situationFamiliale = "La situation familiale est obligatoire !";
            }
            if(!valeurs.nombreEnfants){
                  errors.nombreEnfants = "Le nombre d'entants est obligatoire !";
            }

            return errors;

      };


      

      
      
      
      
      const btnAjouterNationalite = () => {
            setEtatNationalite2(true)
      }

      const btnAjouterExperience1 = () => {

            setEtatAjouterExperience1(true)     

      }
      const btnAjouterExperience2 = () => {

            setEtatAjouterExperience2(true)     

      }
      const btnAjouterExperience3 = () => {

            setEtatAjouterExperience3(true)     

      }
      const btnAjouterFormation1 = () => {

            setEtatAjouterFormation1(true)     

      }
      const btnAjouterFormation2 = () => {

            setEtatAjouterFormation2(true)     

      }
      const btnAjouterFormation3 = () => {

            setEtatAjouterFormation3(true)     

      }
      const btnAjouterLangue1 = () => {

            setEtatAjouterLangue1(true)     

      }
      const btnAjouterLangue2 = () => {

            setEtatAjouterLangue2(true)     

      }
      const btnAjouterLangue3 = () => {

            setEtatAjouterLangue3(true)     

      }
      
      const [carteVitale, setCarteVitale] = useState()
      const [CIN, setCIN] = useState()
      const [RIB, setRIB] = useState()
      const [CV, setCV] = useState()
      const [TS, setTS] = useState()
      const [etatValidationCarteVitale, setEtatValidationCarteVitale] = useState(true)
      const [etatValidationCIN, setEtatValidationCIN] = useState(true)
      const [etatValidationRIB, setEtatValidationRIB] = useState(true)
      const [etatValidationCV, setEtatValidationCV] = useState(true)
      const [etatValidationTS, setEtatValidationTS] = useState(true)



      
      const [etatValidationWithoudCarteVitale, setEtatValidationWithoudCarteVitale] = useState(false)
      const [etatValidationWithoudCIN, setEtatValidationWithoudCIN] = useState(false)
      const [etatValidationWithoudRIB, setEtatValidationWithoudRIB] = useState(false)
      const [etatValidationWithoudCV, setEtatValidationWithoudCV] = useState(false)
      const [etatValidationWithoudTS, setEtatValidationWithoudTS] = useState(false)



      
      let currentDateNotification = Date();

      const moment = require('moment-timezone');

      

      

      


      useEffect(() => {
            if(etatValidationWithoudCarteVitale === true){
                  btnEmbaucher()
            }

      }, [etatValidationWithoudCarteVitale])

      useEffect(() => {
            if(etatValidationWithoudRIB === true){
                  btnEmbaucher()
            }

      }, [etatValidationWithoudRIB])

      

      const btnCarteVitaleOui = () => {
      
            setEtatValidationWithoudCarteVitale(true)
            btnEmbaucher()
      }

      const btnCVOui = () => {
      
            setEtatValidationWithoudCV(true)
            btnEmbaucher()
      }

      useEffect(() => {
            if(etatValidationWithoudCIN === true){
                  btnEmbaucher()
            }

      }, [etatValidationWithoudCIN])

      useEffect(() => {
            if(etatValidationWithoudTS === true){
                  btnEmbaucher()
            }

      }, [etatValidationWithoudTS])

      const btnCINOui = () => {
            
            setEtatValidationWithoudCIN(true)
            btnEmbaucher()
      }

      const btnTSOui = () => {
      
            setEtatValidationWithoudTS(true)
            btnEmbaucher()
      }

      useEffect(() => {
            if(etatValidationWithoudCV === true){
                  APIService.InsererNotification({username:token["username"], matricule_employe:'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), notification:"Copie CV n'est pas jointe pour le salarié "+donneesSalariesValeurs.nom+" "+donneesSalariesValeurs.prenom
      }, token)
            if(etatValidationWithoudCarteVitale === true){
            APIService.InsererNotification({username:token["username"], matricule_employe:'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), notification:"Copie Carte vitale n'est pas jointe pour le salarié "+donneesSalariesValeurs.nom+" "+donneesSalariesValeurs.prenom
      }, token)
            }

            

            if(etatValidationWithoudRIB === true){
                  APIService.InsererNotification({username:token["username"], matricule_employe:'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), notification:"Copie RIB n'est pas jointe pour le salarié "+donneesSalariesValeurs.nom+" "+donneesSalariesValeurs.prenom
            }, token)
                  }
            if(etatChoiceCIN){
                  if(etatValidationWithoudCIN === true){
                        APIService.InsererNotification({username:token["username"], matricule_employe:'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), notification:"Copie CIN n'est pas jointe pour le salarié "+donneesSalariesValeurs.nom+" "+donneesSalariesValeurs.prenom
                  }, token)
                  }
            }
            else{
                  if(etatChoiceTS){
                        if(etatValidationWithoudTS === true){
                              APIService.InsererNotification({username:token["username"], matricule_employe:'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), notification:"Copie Titre de séjeur n'est pas jointe pour le salarié "+donneesSalariesValeurs.nom+" "+donneesSalariesValeurs.prenom
                        }, token)
                              }
                  }
            }
            
            

                  btnEmbaucher()
            }

      }, [etatValidationWithoudCV])

      const btnRIBOui = () => {
      
            setEtatValidationWithoudRIB(true)
            btnEmbaucher()
      }

      const btnTestEmbauche = () => {

            if(employe){
                  console.log("Employe : ", employe)
                  let formDataPut = new FormData();
                                          formDataPut.append("id", employe.id); 
                                          formDataPut.append("matricule_employe", employe.matricule_employe); 
                                          formDataPut.append("dateEntree_employe", employe.dateEntree_employe);
                                          formDataPut.append("dateModification_employe", employe.dateModification_employe);
                                          formDataPut.append("dateFinModification_employe", employe.dateFinModification_employe);
                                          formDataPut.append("dateSortie_employe", employe.dateSortie_employe);
                                          formDataPut.append("nom_employe", employe.nom_employe);
                                          formDataPut.append("prenom_employe", employe.prenom_employe);
                                          formDataPut.append("sexe_employe", parseInt(employe.sexe_employe));
                                          formDataPut.append("numeroSS_employe", employe.numeroSS_employe);
                                          formDataPut.append("cle_employe", employe.cle_employe);
                                          formDataPut.append("dateNaissance_employe", employe.dateNaissance_employe);
                                          formDataPut.append("paysNaissance_employe", parseInt(employe.paysNaissance_employe));
                                          formDataPut.append("departementNaissance_employe", parseInt(employe.departementNaissance_employe));
                                          formDataPut.append("nationnalite1_employe", parseInt(employe.nationnalite1_employe));
                                          formDataPut.append("nationnalite2_employe", parseInt(employe.nationnalite2_employe));
                                          formDataPut.append("situationFamiliale_employe", parseInt(employe.situationFamiliale_employe));
                                          formDataPut.append("nombreEnfant_employe", parseInt(employe.nombreEnfant_employe));
                                          formDataPut.append("adresse_employe", employe.adresse_employe);
                                          formDataPut.append("pays_employe", parseInt(employe.pays_employe));
                                          formDataPut.append("codePostale_employe", parseInt(employe.codePostale_employe));
                                          formDataPut.append("ville_employe", parseInt(employe.ville_employe));
                                          formDataPut.append("tel_employe", employe.tel_employe);
                                          formDataPut.append("email_employe", employe.email_employe);
                                          formDataPut.append("etablissement_employe", parseInt(employe.etablissement_employe));
                                          formDataPut.append("departement_employe", parseInt(employe.departement_employe));
                                          formDataPut.append("poste_employe", parseInt(employe.poste_employe));
                                          formDataPut.append("typeContrat_employe", parseInt(employe.typeContrat_employe));
                                          formDataPut.append("statut_employe", employe.statut_employe);
                                          formDataPut.append("plageHoraire_employe", parseInt(employe.plageHoraire_employe));
                                          formDataPut.append("jourRepos1_employe", parseInt(employe.jourRepos1_employe));
                                          formDataPut.append("jourRepos2_employe", parseInt(employe.jourRepos2_employe));
                                          formDataPut.append("nomRue_employe", employe.nomRue_employe);
                                          formDataPut.append("NRue_employe", employe.NRue_employe);
                                          formDataPut.append("complementAdresse_employe", employe.complementAdresse_employe);
                                          formDataPut.append("chez_employe", employe.chez_employe);
                                          formDataPut.append("telephoneFixe_employe", employe.telephoneFixe_employe);
                                          formDataPut.append("formation1_employe", employe.formation1_employe);
                                          formDataPut.append("anneeFormation1_employe", employe.anneeFormation1_employe);
                                          formDataPut.append("formation2_employe", employe.formation2_employe);
                                          formDataPut.append("anneeFormation2_employe", employe.anneeFormation2_employe);
                                          formDataPut.append("formation3_employe", employe.formation3_employe);
                                          formDataPut.append("anneeFormation3_employe", employe.anneeFormation3_employe);
                                          formDataPut.append("formation4_employe", employe.formation4_employe);
                                          formDataPut.append("anneeFormation4_employe", employe.anneeFormation4_employe);
                                          formDataPut.append("experience1_employe", employe.experience1_employe);
                                          formDataPut.append("anneeExperience1_employe", employe.anneeExperience1_employe);
                                          formDataPut.append("experience2_employe", employe.experience2_employe);
                                          formDataPut.append("anneeExperience2_employe", employe.anneeExperience2_employe);
                                          formDataPut.append("experience3_employe", employe.experience3_employe);
                                          formDataPut.append("anneeExperience3_employe", employe.anneeExperience3_employe);
                                          formDataPut.append("experience4_employe", employe.experience4_employe);
                                          formDataPut.append("anneeExperience4_employe", employe.anneeExperience4_employe);
                                          formDataPut.append("langue1_employe", parseInt(employe.langue1_employe));
                                          formDataPut.append("niveauLangue1_employe", parseInt(employe.niveauLangue1_employe));
                                          formDataPut.append("langue2_employe", parseInt(employe.langue2_employe));
                                          formDataPut.append("niveauLangue2_employe", parseInt(employe.niveauLangue2_employe));
                                          formDataPut.append("langue3_employe", parseInt(employe.langue3_employe));
                                          formDataPut.append("niveauLangue3_employe", parseInt(employe.niveauLangue3_employe));
                                          formDataPut.append("langue4_employe", parseInt(employe.langue4_employe));
                                          formDataPut.append("niveauLangue4_employe", parseInt(employe.niveauLangue4_employe));
                                          formDataPut.append("iban_employe", employe.iban_employe);
                                          formDataPut.append("banque_employe", parseInt(employe.banque_employe));
                                          formDataPut.append("domiciliation_employe", employe.domiciliation_employe);
                                          formDataPut.append("numeroCompte_employe", employe.numeroCompte_employe);
                                          formDataPut.append("codeBanque_employe", parseInt(employe.codeBanque_employe));
                                          formDataPut.append("codeGuichet_employe", parseInt(employe.codeGuichet_employe));
                                          formDataPut.append("cleRib_employe", parseInt(employe.cleRib_employe));
                                          formDataPut.append("stateOccur_employe", false);
                                          formDataPut.append("motifSortie_employe",  parseInt(employe.motifSortie_employe));
                                          formDataPut.append("role_employe", employe.role_employe);
                                          formDataPut.append("matricule_manager", employe.matricule_manager);
                                          formDataPut.append("modeTravail_employe", parseInt(employe.modeTravail_employe));
                                          formDataPut.append("competence_employe", employe.competence_employe);
                                          formDataPut.append("heurePrisePoste_employe", employe.heurePrisePoste_employe);
                                         
                                          if (carteVitale) formDataPut.append('carteVitale_employe', employe.carteVitale_employe);
                                          if (CIN) formDataPut.append('cin_employe', employe.cin_employe);
                                          if (TS) formDataPut.append('ts_employe', employe.ts_employe);
                                          if (CV) formDataPut.append('cv_employe', employe.cv_employe);
                                          if (RIB) formDataPut.append('rib_employe', employe.rib_employe);
                                          


                  APIService.putEmploye(employe.id, formDataPut, token)
                  .then(resp => console.log("Resp : ", resp))


                  for (var pair of formDataPut.entries()) {
                        console.log(pair[0]+ ', ' + pair[1]); 
                  }

                  
            }

            






















            // let data =  {
        
            //       "matricule_employe": "M4",
            //       "dateEntree_employe": "2023-12-08",
            //       "dateModification_employe": "2023-12-08",
            //       "dateFinModification_employe": "2023-12-07",
            //       "dateSortie_employe": "",
            //       "nom_employe": "ANSAIR",
            //       "prenom_employe": "Ayoub",
            //       "sexe_employe": 1,
            //       "numeroSS_employe": "ok",
            //       "cle_employe": "ok",
            //       "dateNaissance_employe": "2023-12-07",
            //       "paysNaissance_employe": 1,
            //       "departementNaissance_employe": 2,
            //       "nationnalite1_employe": 4,
            //       "nationnalite2_employe": 4,
            //       "situationFamiliale_employe": 8,
            //       "nombreEnfant_employe": 3,
            //       "adresse_employe": "ok",
            //       "pays_employe": 1,
            //       "codePostale_employe": 1,
            //       "ville_employe": 1,
            //       "tel_employe": "0654433",
            //       "email_employe": "ansair.ayoub@gmail.com",
            //       "etablissement_employe": 6,
            //       "departement_employe": 7,
            //       "poste_employe": 1,
            //       "typeContrat_employe": 1,
            //       "statut_employe": "actif",
            //       "plageHoraire_employe": 7,
            //       "jourRepos1_employe": 1,
            //       "jourRepos2_employe": 3,
            //       "nomRue_employe": "ok",
            //       "NRue_employe": "ok",
            //       "complementAdresse_employe": "",
            //       "chez_employe": "",
            //       "telephoneFixe_employe": "",
            //       "formation1_employe": "ok",
            //       "anneeFormation1_employe": "ok",
            //       "formation2_employe": "",
            //       "anneeFormation2_employe": "",
            //       "formation3_employe": "",
            //       "anneeFormation3_employe": "",
            //       "formation4_employe": "",
            //       "anneeFormation4_employe": "",
            //       "experience1_employe": "ok",
            //       "anneeExperience1_employe": "ok",
            //       "experience2_employe": "",
            //       "anneeExperience2_employe": "",
            //       "experience3_employe": "",
            //       "anneeExperience3_employe": "",
            //       "experience4_employe": "",
            //       "anneeExperience4_employe": "",
            //       "langue1_employe": 19,
            //       "niveauLangue1_employe": 3,
            //       "langue2_employe": 20,
            //       "niveauLangue2_employe": 4,
            //       "langue3_employe": 20,
            //       "niveauLangue3_employe": 3,
            //       "langue4_employe": 20,
            //       "niveauLangue4_employe": 4,
            //       "iban_employe": "ok",
            //       "banque_employe": 1,
            //       "domiciliation_employe": "",
            //       "numeroCompte_employe": "23",
            //       "codeBanque_employe": 4,
            //       "codeGuichet_employe": 7,
            //       "cleRib_employe": 3,
            //       "stateOccur_employe": true,
            //       "motifSortie_employe": 1,
            //       "role_employe": "Manager",
            //       "matricule_manager": "M1",
            //       "modeTravail_employe": 2,
            //       "competence_employe": "",
            //       "heurePrisePoste_employe": "10:54:55",
            //       "carteVitale_employe": null,
            //       "cin_employe": null,
            //       "ts_employe": null,
            //       "cv_employe": null,
            //       "rib_employe": null
            //   }

            //   APIService.AjouterEmploye(data, token)

              
      }

      const btnEmbaucher = () => {  
            
            

            if(affectationValeurs.typeContrat==='CDI'){
                  dateSortie = ''
            }else{
                  dateSortie = affectationValeurs.dateFin     
            }

            
            if(!validationValeurs.heurePrisePoste){
                  formErrorsValidation.heurePrisePoste = "L'heure de prise de poste est obligatoire";

            }else{

                  // etatChoiceCIN

                  if(!carteVitale && !etatValidationWithoudCarteVitale){
                        setEtatValidationCarteVitale(false)
                  }
                  else{ 
                        setEtatValidationCarteVitale(true)
                        
                        if((!CIN && !etatValidationWithoudCIN && etatChoiceCIN) || (!TS && !etatValidationWithoudTS && etatChoiceTS)){
                              
                              if(etatChoiceCIN){
                                    setEtatValidationCIN(false)
                              }
                              else{
                                    if(etatChoiceTS){
                                          setEtatValidationTS(false)
                                    }
                              }
                        }
                        else{

                              if(etatChoiceCIN){
                                    setEtatValidationCIN(true)
                              }
                              else{ 
                                    
                                    if(etatChoiceTS){
                                        setEtatValidationTS(true)
                                    }
                              }

                        //  setEtatValidationCIN(true)
                         
                         if(!RIB && !etatValidationWithoudRIB){
                              setEtatValidationRIB(false)
                              }
                        else{
      
                         setEtatValidationRIB(true)

                        if(!CV && !etatValidationWithoudCV){
                              setEtatValidationCV(false)
                        }
                        else{

                        setEtatValidationCV(true)

                        console.log("Test etat : ", !etatValidationWithoudCV && (!etatValidationWithoudCIN || !etatValidationWithoudTS) && !etatValidationWithoudCarteVitale && !etatValidationWithoudRIB
                        && RIB && (CIN || TS) && carteVitale && CV)
                        
                        if(!etatValidationWithoudCV && (!etatValidationWithoudCIN || !etatValidationWithoudTS) && !etatValidationWithoudCarteVitale && !etatValidationWithoudRIB
                              && RIB && (CIN || TS) && carteVitale && CV){
      
                                    
                                    if(token['matriculeEmployeAttente']){
                                          APIService.SupprimerEmployeTemporaire(employe.matricule_employe, token)

                                          APIService.SupprimerFichierTemporaire(employe.matricule_employe, token)
      
                                          APIService.SupprimerNotifications(employe.matricule_employe, token)
                                    }

                                    console.log(etatReembauche)
      
                                    if(etatReembauche){
      
                                          console.log('Reembauche')

                                          
                                     
                                          console.log("Emp data : ", employe)

                                          let formDataPut = new FormData();
                                          formDataPut.append("id", employe.id); 
                                          formDataPut.append("matricule_employe", employe.matricule_employe); 
                                          formDataPut.append("dateEntree_employe", employe.dateEntree_employe);
                                          formDataPut.append("dateModification_employe", employe.dateModification_employe);
                                          formDataPut.append("dateFinModification_employe", employe.dateFinModification_employe);
                                          formDataPut.append("dateSortie_employe", employe.dateSortie_employe);
                                          formDataPut.append("nom_employe", employe.nom_employe);
                                          formDataPut.append("prenom_employe", employe.prenom_employe);
                                          formDataPut.append("sexe_employe", parseInt(employe.sexe_employe));
                                          formDataPut.append("numeroSS_employe", employe.numeroSS_employe);
                                          formDataPut.append("cle_employe", employe.cle_employe);
                                          formDataPut.append("dateNaissance_employe", employe.dateNaissance_employe);
                                          formDataPut.append("paysNaissance_employe", parseInt(employe.paysNaissance_employe));
                                          formDataPut.append("departementNaissance_employe", parseInt(employe.departementNaissance_employe));
                                          formDataPut.append("nationnalite1_employe", parseInt(employe.nationnalite1_employe));
                                          formDataPut.append("nationnalite2_employe", parseInt(employe.nationnalite2_employe));
                                          formDataPut.append("situationFamiliale_employe", parseInt(employe.situationFamiliale_employe));
                                          formDataPut.append("nombreEnfant_employe", parseInt(employe.nombreEnfant_employe));
                                          formDataPut.append("adresse_employe", employe.adresse_employe);
                                          formDataPut.append("pays_employe", parseInt(employe.pays_employe));
                                          formDataPut.append("codePostale_employe", parseInt(employe.codePostale_employe));
                                          formDataPut.append("ville_employe", parseInt(employe.ville_employe));
                                          formDataPut.append("tel_employe", employe.tel_employe);
                                          formDataPut.append("email_employe", employe.email_employe);
                                          formDataPut.append("etablissement_employe", parseInt(employe.etablissement_employe));
                                          formDataPut.append("departement_employe", parseInt(employe.departement_employe));
                                          formDataPut.append("poste_employe", parseInt(employe.poste_employe));
                                          formDataPut.append("typeContrat_employe", parseInt(employe.typeContrat_employe));
                                          formDataPut.append("statut_employe", employe.statut_employe);
                                          formDataPut.append("plageHoraire_employe", parseInt(employe.plageHoraire_employe));
                                          formDataPut.append("jourRepos1_employe", parseInt(employe.jourRepos1_employe));
                                          formDataPut.append("jourRepos2_employe", parseInt(employe.jourRepos2_employe));
                                          formDataPut.append("nomRue_employe", employe.nomRue_employe);
                                          formDataPut.append("NRue_employe", employe.NRue_employe);
                                          formDataPut.append("complementAdresse_employe", employe.complementAdresse_employe);
                                          formDataPut.append("chez_employe", employe.chez_employe);
                                          formDataPut.append("telephoneFixe_employe", employe.telephoneFixe_employe);
                                          formDataPut.append("formation1_employe", employe.formation1_employe);
                                          formDataPut.append("anneeFormation1_employe", employe.anneeFormation1_employe);
                                          formDataPut.append("formation2_employe", employe.formation2_employe);
                                          formDataPut.append("anneeFormation2_employe", employe.anneeFormation2_employe);
                                          formDataPut.append("formation3_employe", employe.formation3_employe);
                                          formDataPut.append("anneeFormation3_employe", employe.anneeFormation3_employe);
                                          formDataPut.append("formation4_employe", employe.formation4_employe);
                                          formDataPut.append("anneeFormation4_employe", employe.anneeFormation4_employe);
                                          formDataPut.append("experience1_employe", employe.experience1_employe);
                                          formDataPut.append("anneeExperience1_employe", employe.anneeExperience1_employe);
                                          formDataPut.append("experience2_employe", employe.experience2_employe);
                                          formDataPut.append("anneeExperience2_employe", employe.anneeExperience2_employe);
                                          formDataPut.append("experience3_employe", employe.experience3_employe);
                                          formDataPut.append("anneeExperience3_employe", employe.anneeExperience3_employe);
                                          formDataPut.append("experience4_employe", employe.experience4_employe);
                                          formDataPut.append("anneeExperience4_employe", employe.anneeExperience4_employe);
                                          formDataPut.append("langue1_employe", parseInt(employe.langue1_employe));
                                          formDataPut.append("niveauLangue1_employe", parseInt(employe.niveauLangue1_employe));
                                          formDataPut.append("langue2_employe", parseInt(employe.langue2_employe));
                                          formDataPut.append("niveauLangue2_employe", parseInt(employe.niveauLangue2_employe));
                                          formDataPut.append("langue3_employe", parseInt(employe.langue3_employe));
                                          formDataPut.append("niveauLangue3_employe", parseInt(employe.niveauLangue3_employe));
                                          formDataPut.append("langue4_employe", parseInt(employe.langue4_employe));
                                          formDataPut.append("niveauLangue4_employe", parseInt(employe.niveauLangue4_employe));
                                          formDataPut.append("iban_employe", employe.iban_employe);
                                          formDataPut.append("banque_employe", parseInt(employe.banque_employe));
                                          formDataPut.append("domiciliation_employe", employe.domiciliation_employe);
                                          formDataPut.append("numeroCompte_employe", employe.numeroCompte_employe);
                                          formDataPut.append("codeBanque_employe", parseInt(employe.codeBanque_employe));
                                          formDataPut.append("codeGuichet_employe", parseInt(employe.codeGuichet_employe));
                                          formDataPut.append("cleRib_employe", parseInt(employe.cleRib_employe));
                                          formDataPut.append("stateOccur_employe", false);
                                          formDataPut.append("motifSortie_employe",  parseInt(employe.motifSortie_employe));
                                          formDataPut.append("role_employe", employe.role_employe);
                                          formDataPut.append("matricule_manager", employe.matricule_manager);
                                          formDataPut.append("modeTravail_employe", parseInt(employe.modeTravail_employe));
                                          formDataPut.append("competence_employe", employe.competence_employe);
                                          formDataPut.append("heurePrisePoste_employe", employe.heurePrisePoste_employe);
                                         
                                         
                                         
                                          // formDataPut.append("matricule_employe", 'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS))); 
                                          // formDataPut.append("dateEntree_employe", affectationValeurs.dateDebut);
                                          // formDataPut.append("dateModification_employe", affectationValeurs.dateDebut);
                                          // formDataPut.append("dateFinModification_employe", affectationValeurs.dateDebut);
                                          // formDataPut.append("dateSortie_employe", '2023-12-13');
                                          // formDataPut.append("nom_employe", donneesSalariesValeurs.nom);
                                          // formDataPut.append("prenom_employe", donneesSalariesValeurs.prenom);
                                          // formDataPut.append("sexe_employe", parseInt(donneesSalariesValeurs.sexe));
                                          // formDataPut.append("numeroSS_employe", donneesSalariesValeurs.numeroSS);
                                          // formDataPut.append("cle_employe", donneesSalariesValeurs.cle);
                                          // formDataPut.append("dateNaissance_employe", donneesSalariesValeurs.dateNaissance);
                                          // formDataPut.append("paysNaissance_employe", parseInt(donneesSalariesValeurs.paysNaissance));
                                          // formDataPut.append("departementNaissance_employe", parseInt(donneesSalariesValeurs.departementNaissance));
                                          // formDataPut.append("nationnalite1_employe", parseInt(donneesSalariesValeurs.nationalite));
                                          // formDataPut.append("nationnalite2_employe", parseInt(donneesSalariesValeurs.nationalite));
                                          // formDataPut.append("situationFamiliale_employe", parseInt(donneesSalariesValeurs.situationFamiliale));
                                          // formDataPut.append("nombreEnfant_employe", parseInt(donneesSalariesValeurs.nombreEnfants));
                                          // formDataPut.append("adresse_employe", coordonneesValeurs.NomRue);
                                          // formDataPut.append("pays_employe", parseInt(coordonneesValeurs.pays));
                                          // formDataPut.append("codePostale_employe", parseInt(coordonneesValeurs.codePostale));
                                          // formDataPut.append("ville_employe", parseInt(coordonneesValeurs.ville));
                                          // formDataPut.append("tel_employe", coordonneesValeurs.telephoneMobile);
                                          // formDataPut.append("email_employe", coordonneesValeurs.email);
                                          // formDataPut.append("etablissement_employe", parseInt(affectationValeurs.etablissement));
                                          // formDataPut.append("departement_employe", parseInt(affectationValeurs.departement));
                                          // formDataPut.append("poste_employe", parseInt(affectationValeurs.poste));
                                          // formDataPut.append("typeContrat_employe", parseInt(affectationValeurs.typeContrat));
                                          // formDataPut.append("statut_employe", "actif");
                                          // formDataPut.append("plageHoraire_employe", parseInt(affectationValeurs.nbrHeuresHebdo));
                                          // formDataPut.append("jourRepos1_employe", parseInt(affectationValeurs.jourRepos1));
                                          // formDataPut.append("jourRepos2_employe", parseInt(affectationValeurs.jourRepos2));
                                          // formDataPut.append("nomRue_employe", coordonneesValeurs.NomRue);
                                          // formDataPut.append("NRue_employe", coordonneesValeurs.NRue);
                                          // formDataPut.append("complementAdresse_employe", "");
                                          // formDataPut.append("chez_employe", coordonneesValeurs.chez);
                                          // formDataPut.append("telephoneFixe_employe", coordonneesValeurs.telephoneFixe);
                                          // formDataPut.append("formation1_employe", competencesValeurs.formation1);
                                          // formDataPut.append("anneeFormation1_employe", competencesValeurs.anneeFormation1);
                                          // formDataPut.append("formation2_employe", competencesValeurs.formation2);
                                          // formDataPut.append("anneeFormation2_employe", competencesValeurs.anneeFormation2);
                                          // formDataPut.append("formation3_employe", competencesValeurs.formation3);
                                          // formDataPut.append("anneeFormation3_employe", competencesValeurs.anneeFormation3);
                                          // formDataPut.append("formation4_employe", competencesValeurs.formation4);
                                          // formDataPut.append("anneeFormation4_employe", competencesValeurs.anneeFormation4);
                                          // formDataPut.append("experience1_employe", competencesValeurs.experience1);
                                          // formDataPut.append("anneeExperience1_employe", competencesValeurs.anneeExperience1);
                                          // formDataPut.append("experience2_employe", competencesValeurs.experience2);
                                          // formDataPut.append("anneeExperience2_employe", competencesValeurs.anneeExperience2);
                                          // formDataPut.append("experience3_employe", competencesValeurs.experience3);
                                          // formDataPut.append("anneeExperience3_employe", competencesValeurs.anneeExperience3);
                                          // formDataPut.append("experience4_employe", competencesValeurs.experience4);
                                          // formDataPut.append("anneeExperience4_employe", competencesValeurs.anneeExperience4);
                                          // formDataPut.append("langue1_employe", parseInt(competencesValeurs.langue1));
                                          // formDataPut.append("niveauLangue1_employe", parseInt(competencesValeurs.niveauLangue1));
                                          // formDataPut.append("langue2_employe", parseInt(competencesValeurs.langue2));
                                          // formDataPut.append("niveauLangue2_employe", parseInt(competencesValeurs.niveauLangue2));
                                          // formDataPut.append("langue3_employe", parseInt(competencesValeurs.langue3));
                                          // formDataPut.append("niveauLangue3_employe", parseInt(competencesValeurs.niveauLangue3));
                                          // formDataPut.append("langue4_employe", parseInt(competencesValeurs.langue4));
                                          // formDataPut.append("niveauLangue4_employe", parseInt(competencesValeurs.niveauLangue4));
                                          // formDataPut.append("iban_employe", idBancaireValeurs.iban);
                                          // formDataPut.append("banque_employe", parseInt(idBancaireValeurs.banque));
                                          // formDataPut.append("domiciliation_employe", idBancaireValeurs.domiciliation);
                                          // formDataPut.append("numeroCompte_employe", idBancaireValeurs.numeroCompte);
                                          // formDataPut.append("codeBanque_employe", parseInt(idBancaireValeurs.codeBanque));
                                          // formDataPut.append("codeGuichet_employe", parseInt(idBancaireValeurs.codeGuichet));
                                          // formDataPut.append("cleRib_employe", parseInt(idBancaireValeurs.cleRib));
                                          // formDataPut.append("stateOccur_employe", true);
                                          // formDataPut.append("motifSortie_employe", 1);
                                          // formDataPut.append("role_employe", "Manager");
                                          // formDataPut.append("matricule_manager", "M1");
                                          // formDataPut.append("modeTravail_employe", parseInt(affectationValeurs.modeTravail));
                                          // formDataPut.append("competence_employe", competencesValeurs.competence);
                                          // formDataPut.append("heurePrisePoste_employe", validationValeurs.heurePrisePoste);
                                    
                                          if (carteVitale) formDataPut.append('carteVitale_employe', CV);
                                          if (CIN) formDataPut.append('cin_employe', CV);
                                          if (TS) formDataPut.append('ts_employe', CV);
                                          if (CV) formDataPut.append('cv_employe', CV);
                                          if (RIB) formDataPut.append('rib_employe', CV);
                  


                                          APIService.putEmploye(employe.id, formDataPut, token)
                                          .then(resp => console.log("Resp : ", resp))



                  
                        
                                          // APIService.ModifierEmploye(token['id'],
                                          //       {matricule_employe:employe.matricule_employe, dateEntree_employe:employe.dateEntree_employe, dateModification_employe:employe.dateModification_employe, dateFinModification_employe:employe.dateFinModification_employe,dateSortie_employe:employe.dateSortie_employe,
                                          //       nom_employe:employe.nom_employe, prenom_employe:employe.prenom_employe, sexe_employe:employe.sexe_employe, numeroSS_employe:employe.numeroSS_employe, cle_employe:employe.cle_employe, dateNaissance_employe:employe.dateNaissance_employe, 
                                          //       paysNaissance_employe:employe.paysNaissance_employe, departementNaissance_employe:employe.departementNaissance_employe, nationnalite1_employe:employe.anneeFormation1_employe, nationnalite2_employe:employe.nationnalite2_employe, 
                                          //       situationFamiliale_employe:employe.situationFamiliale_employe, nombreEnfant_employe:employe.nombreEnfant_employe, adresse_employe:employe.adresse_employe, pays_employe:employe.pays_employe, 
                                          //       codePostale_employe:employe.codePostale_employe, ville_employe:employe.ville_employe, tel_employe:employe.tel_employe, 
                                          //       email_employe:employe.email_employe, etablissement_employe:employe.etablissement_employe, departement_employe:employe.departement_employe, 
                                          //       poste_employe:employe.poste_employe, typeContrat_employe:employe.typeContrat_employe, plageHoraire_employe:employe.plageHoraire_employe, jourRepos1_employe:employe.jourRepos1_employe, jourRepos2_employe:employe.jourRepos2_employe, nomRue_employe:employe.nomRue_employe, NRue_employe:employe.NRue_employe, complementAdresse_employe:employe.complementAdresse_employe, chez_employe:employe.chez_employe, telephoneFixe_employe:employe.telephoneFixe_employe,formation1_employe:employe.formation1_employe, anneeFormation1_employe:employe.anneeFormation1_employe, formation2_employe:employe.formation2_employe, anneeFormation2_employe:employe.anneeExperience2_employe,formation3_employe:employe.formation3_employe, anneeFormation3_employe:employe.anneeExperience3_employe,formation4_employe:employe.formation4_employe, anneeFormation4_employe:employe.anneeFormation4_employe,
                                          //       experience1_employe:employe.experience1_employe, anneeExperience1_employe:employe.anneeExperience1_employe, experience2_employe:employe.experience2_employe, anneeExperience2_employe:employe.anneeExperience2_employe,experience3_employe:employe.experience3_employe, anneeExperience3_employe:employe.anneeExperience3_employe,experience4_employe:employe.experience4_employe, anneeExperience4_employe:employe.anneeExperience4_employe, 
                                          //       langue1_employe:employe.langue1_employe, niveauLangue1_employe:employe.niveauLangue1_employe,
                                          //       langue2_employe:employe.langue2_employe, niveauLangue2_employe:employe.niveauLangue2_employe,
                                          //       langue3_employe:employe.langue3_employe, niveauLangue3_employe:employe.niveauLangue3_employe,
                                          //       langue4_employe:employe.langue4_employe, niveauLangue4_employe:employe.niveauLangue4_employe,
                                          //       iban_employe:employe.iban_employe, banque_employe:employe.banque_employe,
                                          //       domiciliation_employe:employe.domiciliation_employe, numeroCompte_employe:employe.numeroCompte_employe,
                                          //       codeBanque_employe:employe.codeBanque_employe, codeGuichet_employe:employe.codeGuichet_employe,
                                          //       cleRib_employe:employe.cleRib_employe, state_employe:0, modeTravail_employe : employe.modeTravail_employe, competence_employe: employe.competence_employe,
                                          //       heurePrisePoste_employe: employe.heurePrisePoste_employe,            
                                          //       motifSortie_employe:3, matricule_manager: token['matriculeManager']},token)
                        
                                          
                        
                                          
                        
                                          // Ajout d'EMP 

                                          let formData = new FormData();

                                          formData.append("matricule_employe", 'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS))); 
                                          formData.append("dateEntree_employe", affectationValeurs.dateDebut);
                                          formData.append("dateModification_employe", affectationValeurs.dateDebut);
                                          formData.append("dateFinModification_employe", affectationValeurs.dateDebut);
                                          formData.append("dateSortie_employe", '2023-12-13');
                                          formData.append("nom_employe", donneesSalariesValeurs.nom);
                                          formData.append("prenom_employe", donneesSalariesValeurs.prenom);
                                          formData.append("sexe_employe", parseInt(donneesSalariesValeurs.sexe));
                                          formData.append("numeroSS_employe", donneesSalariesValeurs.numeroSS);
                                          formData.append("cle_employe", donneesSalariesValeurs.cle);
                                          formData.append("dateNaissance_employe", donneesSalariesValeurs.dateNaissance);
                                          formData.append("paysNaissance_employe", parseInt(donneesSalariesValeurs.paysNaissance));
                                          formData.append("departementNaissance_employe", parseInt(donneesSalariesValeurs.departementNaissance));
                                          formData.append("nationnalite1_employe", parseInt(donneesSalariesValeurs.nationalite));
                                          formData.append("nationnalite2_employe", parseInt(donneesSalariesValeurs.nationalite));
                                          formData.append("situationFamiliale_employe", parseInt(donneesSalariesValeurs.situationFamiliale));
                                          formData.append("nombreEnfant_employe", parseInt(donneesSalariesValeurs.nombreEnfants));
                                          formData.append("adresse_employe", coordonneesValeurs.NomRue);
                                          formData.append("pays_employe", parseInt(coordonneesValeurs.pays));
                                          formData.append("codePostale_employe", parseInt(coordonneesValeurs.codePostale));
                                          formData.append("ville_employe", parseInt(coordonneesValeurs.ville));
                                          formData.append("tel_employe", coordonneesValeurs.telephoneMobile);
                                          formData.append("email_employe", coordonneesValeurs.email);
                                          formData.append("etablissement_employe", parseInt(affectationValeurs.etablissement));
                                          formData.append("departement_employe", parseInt(affectationValeurs.departement));
                                          formData.append("poste_employe", parseInt(affectationValeurs.poste));
                                          formData.append("typeContrat_employe", parseInt(affectationValeurs.typeContrat));
                                          formData.append("statut_employe", "actif");
                                          formData.append("plageHoraire_employe", parseInt(affectationValeurs.nbrHeuresHebdo));
                                          formData.append("jourRepos1_employe", parseInt(affectationValeurs.jourRepos1));
                                          formData.append("jourRepos2_employe", parseInt(affectationValeurs.jourRepos2));
                                          formData.append("nomRue_employe", coordonneesValeurs.NomRue);
                                          formData.append("NRue_employe", coordonneesValeurs.NRue);
                                          formData.append("complementAdresse_employe", "");
                                          formData.append("chez_employe", coordonneesValeurs.chez);
                                          formData.append("telephoneFixe_employe", coordonneesValeurs.telephoneFixe);
                                          formData.append("formation1_employe", competencesValeurs.formation1);
                                          formData.append("anneeFormation1_employe", competencesValeurs.anneeFormation1);
                                          formData.append("formation2_employe", competencesValeurs.formation2);
                                          formData.append("anneeFormation2_employe", competencesValeurs.anneeFormation2);
                                          formData.append("formation3_employe", competencesValeurs.formation3);
                                          formData.append("anneeFormation3_employe", competencesValeurs.anneeFormation3);
                                          formData.append("formation4_employe", competencesValeurs.formation4);
                                          formData.append("anneeFormation4_employe", competencesValeurs.anneeFormation4);
                                          formData.append("experience1_employe", competencesValeurs.experience1);
                                          formData.append("anneeExperience1_employe", competencesValeurs.anneeExperience1);
                                          formData.append("experience2_employe", competencesValeurs.experience2);
                                          formData.append("anneeExperience2_employe", competencesValeurs.anneeExperience2);
                                          formData.append("experience3_employe", competencesValeurs.experience3);
                                          formData.append("anneeExperience3_employe", competencesValeurs.anneeExperience3);
                                          formData.append("experience4_employe", competencesValeurs.experience4);
                                          formData.append("anneeExperience4_employe", competencesValeurs.anneeExperience4);
                                          formData.append("langue1_employe", parseInt(competencesValeurs.langue1));
                                          formData.append("niveauLangue1_employe", parseInt(competencesValeurs.niveauLangue1));
                                          formData.append("langue2_employe", parseInt(competencesValeurs.langue2));
                                          formData.append("niveauLangue2_employe", parseInt(competencesValeurs.niveauLangue2));
                                          formData.append("langue3_employe", parseInt(competencesValeurs.langue3));
                                          formData.append("niveauLangue3_employe", parseInt(competencesValeurs.niveauLangue3));
                                          formData.append("langue4_employe", parseInt(competencesValeurs.langue4));
                                          formData.append("niveauLangue4_employe", parseInt(competencesValeurs.niveauLangue4));
                                          formData.append("iban_employe", idBancaireValeurs.iban);
                                          formData.append("banque_employe", parseInt(idBancaireValeurs.banque));
                                          formData.append("domiciliation_employe", idBancaireValeurs.domiciliation);
                                          formData.append("numeroCompte_employe", idBancaireValeurs.numeroCompte);
                                          formData.append("codeBanque_employe", parseInt(idBancaireValeurs.codeBanque));
                                          formData.append("codeGuichet_employe", parseInt(idBancaireValeurs.codeGuichet));
                                          formData.append("cleRib_employe", parseInt(idBancaireValeurs.cleRib));
                                          formData.append("stateOccur_employe", true);
                                          formData.append("motifSortie_employe", 1);
                                          formData.append("role_employe", "Manager");
                                          formData.append("matricule_manager", "M1");
                                          formData.append("modeTravail_employe", parseInt(affectationValeurs.modeTravail));
                                          formData.append("competence_employe", competencesValeurs.competence);
                                          formData.append("heurePrisePoste_employe", validationValeurs.heurePrisePoste);
                                         
                                          if (carteVitale) formData.append('carteVitale_employe', carteVitale);
                                          if (CIN) {
                                                formData.append('cin_employe', CIN);
                                                // formData.append('ts_employe', null);

                                                } else {
                                                            // formData.append('cin_employe', null);
                                                            formData.append('ts_employe', TS);

                                                }
                                          if (CV) formData.append('cv_employe', CV);
                                          if (RIB) formData.append('rib_employe', RIB);

                                          APIService.postEmploye(formData, token)
                                          .then(resp => console.log("Erreur Data : ", resp))
      
      
                                                  removeToken("id")
                                                  removeToken("matriculeEmployeAttente")
                                                  setEtatValidation(2)
                        
                                    }else{
                        
                                          console.log('Embauche')

                                          let formData = new FormData();

                                          formData.append("matricule_employe", 'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS))); 
                                          formData.append("dateEntree_employe", affectationValeurs.dateDebut);
                                          formData.append("dateModification_employe", affectationValeurs.dateDebut);
                                          formData.append("dateFinModification_employe", affectationValeurs.dateDebut);
                                          formData.append("dateSortie_employe", '2023-12-13');
                                          formData.append("nom_employe", donneesSalariesValeurs.nom);
                                          formData.append("prenom_employe", donneesSalariesValeurs.prenom);
                                          formData.append("sexe_employe", parseInt(donneesSalariesValeurs.sexe));
                                          formData.append("numeroSS_employe", donneesSalariesValeurs.numeroSS);
                                          formData.append("cle_employe", donneesSalariesValeurs.cle);
                                          formData.append("dateNaissance_employe", donneesSalariesValeurs.dateNaissance);
                                          formData.append("paysNaissance_employe", parseInt(donneesSalariesValeurs.paysNaissance));
                                          formData.append("departementNaissance_employe", parseInt(donneesSalariesValeurs.departementNaissance));
                                          formData.append("nationnalite1_employe", parseInt(donneesSalariesValeurs.nationalite));
                                          formData.append("nationnalite2_employe", parseInt(donneesSalariesValeurs.nationalite));
                                          formData.append("situationFamiliale_employe", parseInt(donneesSalariesValeurs.situationFamiliale));
                                          formData.append("nombreEnfant_employe", parseInt(donneesSalariesValeurs.nombreEnfants));
                                          formData.append("adresse_employe", coordonneesValeurs.NomRue);
                                          formData.append("pays_employe", parseInt(coordonneesValeurs.pays));
                                          formData.append("codePostale_employe", parseInt(coordonneesValeurs.codePostale));
                                          formData.append("ville_employe", parseInt(coordonneesValeurs.ville));
                                          formData.append("tel_employe", coordonneesValeurs.telephoneMobile);
                                          formData.append("email_employe", coordonneesValeurs.email);
                                          formData.append("etablissement_employe", parseInt(affectationValeurs.etablissement));
                                          formData.append("departement_employe", parseInt(affectationValeurs.departement));
                                          formData.append("poste_employe", parseInt(affectationValeurs.poste));
                                          formData.append("typeContrat_employe", parseInt(affectationValeurs.typeContrat));
                                          formData.append("statut_employe", "actif");
                                          formData.append("plageHoraire_employe", parseInt(affectationValeurs.nbrHeuresHebdo));
                                          formData.append("jourRepos1_employe", parseInt(affectationValeurs.jourRepos1));
                                          formData.append("jourRepos2_employe", parseInt(affectationValeurs.jourRepos2));
                                          formData.append("nomRue_employe", coordonneesValeurs.NomRue);
                                          formData.append("NRue_employe", coordonneesValeurs.NRue);
                                          formData.append("complementAdresse_employe", "");
                                          formData.append("chez_employe", coordonneesValeurs.chez);
                                          formData.append("telephoneFixe_employe", coordonneesValeurs.telephoneFixe);
                                          formData.append("formation1_employe", competencesValeurs.formation1);
                                          formData.append("anneeFormation1_employe", competencesValeurs.anneeFormation1);
                                          formData.append("formation2_employe", competencesValeurs.formation2);
                                          formData.append("anneeFormation2_employe", competencesValeurs.anneeFormation2);
                                          formData.append("formation3_employe", competencesValeurs.formation3);
                                          formData.append("anneeFormation3_employe", competencesValeurs.anneeFormation3);
                                          formData.append("formation4_employe", competencesValeurs.formation4);
                                          formData.append("anneeFormation4_employe", competencesValeurs.anneeFormation4);
                                          formData.append("experience1_employe", competencesValeurs.experience1);
                                          formData.append("anneeExperience1_employe", competencesValeurs.anneeExperience1);
                                          formData.append("experience2_employe", competencesValeurs.experience2);
                                          formData.append("anneeExperience2_employe", competencesValeurs.anneeExperience2);
                                          formData.append("experience3_employe", competencesValeurs.experience3);
                                          formData.append("anneeExperience3_employe", competencesValeurs.anneeExperience3);
                                          formData.append("experience4_employe", competencesValeurs.experience4);
                                          formData.append("anneeExperience4_employe", competencesValeurs.anneeExperience4);
                                          formData.append("langue1_employe", parseInt(competencesValeurs.langue1));
                                          formData.append("niveauLangue1_employe", parseInt(competencesValeurs.niveauLangue1));
                                          formData.append("langue2_employe", parseInt(competencesValeurs.langue2));
                                          formData.append("niveauLangue2_employe", parseInt(competencesValeurs.niveauLangue2));
                                          formData.append("langue3_employe", parseInt(competencesValeurs.langue3));
                                          formData.append("niveauLangue3_employe", parseInt(competencesValeurs.niveauLangue3));
                                          formData.append("langue4_employe", parseInt(competencesValeurs.langue4));
                                          formData.append("niveauLangue4_employe", parseInt(competencesValeurs.niveauLangue4));
                                          formData.append("iban_employe", idBancaireValeurs.iban);
                                          formData.append("banque_employe", parseInt(idBancaireValeurs.banque));
                                          formData.append("domiciliation_employe", idBancaireValeurs.domiciliation);
                                          formData.append("numeroCompte_employe", idBancaireValeurs.numeroCompte);
                                          formData.append("codeBanque_employe", parseInt(idBancaireValeurs.codeBanque));
                                          formData.append("codeGuichet_employe", parseInt(idBancaireValeurs.codeGuichet));
                                          formData.append("cleRib_employe", parseInt(idBancaireValeurs.cleRib));
                                          formData.append("stateOccur_employe", true);
                                          formData.append("motifSortie_employe", 1);
                                          formData.append("role_employe", "Manager");
                                          formData.append("matricule_manager", "M1");
                                          formData.append("modeTravail_employe", parseInt(affectationValeurs.modeTravail));
                                          formData.append("competence_employe", competencesValeurs.competence);
                                          formData.append("heurePrisePoste_employe", validationValeurs.heurePrisePoste);
                                         
                                          if (carteVitale) formData.append('carteVitale_employe', carteVitale);
                                          if (CIN) formData.append('cin_employe', CIN);
                                          if (TS) formData.append('ts_employe', TS);
                                          if (CV) formData.append('cv_employe', CV);
                                          if (RIB) formData.append('rib_employe', RIB);
                                          


                                          for (var pair of formData.entries()) {
                                                console.log(pair[0]+ ', ' + pair[1]); 
                                          }


                                          


                                          









                                          
                                          



                                          
                                    






                                    //       let exampleObject =  {
                                    //       "matricule_employe":'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), 
                                    //       "dateEntree_employe":affectationValeurs.dateDebut,
                                    //       "dateModification_employe":affectationValeurs.dateDebut, 
                                    //       "dateFinModification_employe":affectationValeurs.dateDebut,
                                    //       "dateSortie_employe":'2023-12-13',
                                    //       "nom_employe":donneesSalariesValeurs.nom,
                                    //       "prenom_employe":donneesSalariesValeurs.prenom, 
                                    //       "sexe_employe":parseInt(donneesSalariesValeurs.sexe), 
                                    //       "numeroSS_employe":donneesSalariesValeurs.numeroSS,
                                    //        "cle_employe":donneesSalariesValeurs.cle,
                                    //         "dateNaissance_employe":donneesSalariesValeurs.dateNaissance, 
                                    //       "paysNaissance_employe":parseInt(donneesSalariesValeurs.paysNaissance),
                                    //        "departementNaissance_employe":parseInt(donneesSalariesValeurs.departementNaissance),
                                    //         "nationnalite1_employe":parseInt(donneesSalariesValeurs.nationalite),
                                    //          "nationnalite2_employe":1, 
                                    //       "situationFamiliale_employe":parseInt(donneesSalariesValeurs.situationFamiliale),
                                    //        "nombreEnfant_employe":parseInt(donneesSalariesValeurs.nombreEnfants),
                                    //         "adresse_employe":coordonneesValeurs.NomRue,
                                    //          "pays_employe":parseInt(coordonneesValeurs.pays), 
                                    //       "codePostale_employe":parseInt(coordonneesValeurs.codePostale),
                                    //        "ville_employe":parseInt(coordonneesValeurs.ville),
                                    //         "tel_employe":coordonneesValeurs.telephoneMobile, 
                                    //       "email_employe":coordonneesValeurs.email,
                                    //        "etablissement_employe":parseInt(affectationValeurs.etablissement),
                                    //         "departement_employe":parseInt(affectationValeurs.departement), 
                                    //       "poste_employe":parseInt(affectationValeurs.poste),
                                    //       "typeContrat_employe":parseInt(affectationValeurs.typeContrat),
                                    //       "statut_employe": "actif",
                                    //             "plageHoraire_employe":parseInt(affectationValeurs.nbrHeuresHebdo),
                                    //             "jourRepos1_employe":parseInt(affectationValeurs.jourRepos1),
                                    //             "jourRepos2_employe":parseInt(affectationValeurs.jourRepos2),
                                    //             "nomRue_employe":coordonneesValeurs.NomRue,
                                    //             "NRue_employe":coordonneesValeurs.NRue,
                                    //       "complementAdresse_employe":"", 
                                    //       "chez_employe":coordonneesValeurs.chez, 
                                    //       "telephoneFixe_employe":coordonneesValeurs.telephoneFixe,
                                    //       "formation1_employe":competencesValeurs.formation1,
                                    //        "anneeFormation1_employe":competencesValeurs.anneeFormation1,
                                    //         "formation2_employe":competencesValeurs.formation2, 
                                    //         "anneeFormation2_employe":competencesValeurs.anneeFormation2,
                                    //         "formation3_employe":competencesValeurs.formation3, 
                                    //         "anneeFormation3_employe":competencesValeurs.anneeFormation3,
                                    //         "formation4_employe":competencesValeurs.formation4,
                                    //          "anneeFormation4_employe":competencesValeurs.anneeFormation4,
                                    //       "experience1_employe":competencesValeurs.experience1,
                                    //        "anneeExperience1_employe":competencesValeurs.anneeExperience1,
                                    //         "experience2_employe":competencesValeurs.experience2,
                                    //          "anneeExperience2_employe":competencesValeurs.anneeExperience2,
                                    //          "experience3_employe":competencesValeurs.experience3,
                                    //           "anneeExperience3_employe":competencesValeurs.anneeExperience3,
                                    //           "experience4_employe":competencesValeurs.experience4, 
                                    //           "anneeExperience4_employe":competencesValeurs.anneeExperience4, 
                                    //       "langue1_employe":parseInt(competencesValeurs.langue1), 
                                    //       "niveauLangue1_employe":parseInt(competencesValeurs.niveauLangue1),
                                    //       "langue2_employe":parseInt(competencesValeurs.langue2), 
                                    //       "niveauLangue2_employe":parseInt(competencesValeurs.niveauLangue2),
                                    //       "langue3_employe":parseInt(competencesValeurs.langue3),
                                    //        "niveauLangue3_employe":parseInt(competencesValeurs.niveauLangue3),
                                    //       "langue4_employe":parseInt(competencesValeurs.langue4), 
                                    //       "niveauLangue4_employe":parseInt(competencesValeurs.niveauLangue4),
                                    //       "iban_employe":idBancaireValeurs.iban, 
                                    //       "banque_employe":parseInt(idBancaireValeurs.banque),
                                    //       "domiciliation_employe":idBancaireValeurs.domiciliation, 
                                    //       "numeroCompte_employe":idBancaireValeurs.numeroCompte,
                                    //       "codeBanque_employe":parseInt(idBancaireValeurs.codeBanque), 
                                    //       "codeGuichet_employe":parseInt(idBancaireValeurs.codeGuichet),
                                    //       "cleRib_employe":parseInt(idBancaireValeurs.cleRib), 
                                    //       "stateOccur_employe": true,
                                          
                                    //       "modeTravail_employe":parseInt(affectationValeurs.modeTravail), 
                                    //       "competence_employe": competencesValeurs.competence,
                                    //       "heurePrisePoste_employe": validationValeurs.heurePrisePoste,
                                    //       "motifSortie_employe":3, 
                                    //       "matricule_manager": "m1",
                                    //       "role_employe": "Manager",
                                    //      "carteVitale_employe": CV,
                                    //       "cin_employe": CV,
                                    //       "ts_employe": CV,
                                    //       "cv_employe": CV,
                                    //       "rib_employe": CV,

                                    // }

                                    //       console.log("Data Embauche Test: ", 
                                    //                   exampleObject
                                    //       )

                                          // APIService.AjouterEmploye(exampleObject, token)
                                          APIService.postEmploye(formData, token)
                                          

                                          // APIService.AjouterEmploye(
                                          //       {matricule_employe:'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), 
                                          //       dateEntree_employe:affectationValeurs.dateDebut, dateModification_employe:affectationValeurs.dateDebut, dateFinModification_employe:affectationValeurs.dateDebut,dateSortie_employe:dateSortie,
                                          //       nom_employe:donneesSalariesValeurs.nom, prenom_employe:donneesSalariesValeurs.prenom, sexe_employe:donneesSalariesValeurs.sexe, numeroSS_employe:donneesSalariesValeurs.numeroSS, cle_employe:donneesSalariesValeurs.cle, dateNaissance_employe:donneesSalariesValeurs.dateNaissance, 
                                          //       paysNaissance_employe:donneesSalariesValeurs.paysNaissance, departementNaissance_employe:donneesSalariesValeurs.departementNaissance, nationnalite1_employe:donneesSalariesValeurs.nationalite, nationnalite2_employe:donneesSalariesValeurs.nationalite2, 
                                          //       situationFamiliale_employe:donneesSalariesValeurs.situationFamiliale, nombreEnfant_employe:donneesSalariesValeurs.nombreEnfants, adresse_employe:coordonneesValeurs.NomRue, pays_employe:coordonneesValeurs.pays, 
                                          //       codePostale_employe:coordonneesValeurs.codePostale, ville_employe:coordonneesValeurs.ville, tel_employe:coordonneesValeurs.telephoneMobile, 
                                          //       email_employe:coordonneesValeurs.email, etablissement_employe:affectationValeurs.etablissement, departement_employe:affectationValeurs.departement, 
                                          //       poste_employe:affectationValeurs.poste, typeContrat_employe:affectationValeurs.typeContrat, plageHoraire_employe:affectationValeurs.nbrHeuresHebdo, jourRepos1_employe:affectationValeurs.jourRepos1, jourRepos2_employe:affectationValeurs.jourRepos2, nomRue_employe:coordonneesValeurs.NomRue, NRue_employe:coordonneesValeurs.NRue,
                                          //       // complementAdresse_employe:coordonneesValeurs.complementAdresse, 
                                          //       complementAdresse_employe:"", 
                                          //       chez_employe:coordonneesValeurs.chez, telephoneFixe_employe:coordonneesValeurs.telephoneFixe,formation1_employe:competencesValeurs.formation1, anneeFormation1_employe:competencesValeurs.anneeFormation1, formation2_employe:competencesValeurs.formation2, anneeFormation2_employe:competencesValeurs.anneeFormation2,formation3_employe:competencesValeurs.formation3, anneeFormation3_employe:competencesValeurs.anneeFormation3,formation4_employe:competencesValeurs.formation4, anneeFormation4_employe:competencesValeurs.anneeFormation4,
                                          //       experience1_employe:competencesValeurs.experience1, anneeExperience1_employe:competencesValeurs.anneeExperience1, experience2_employe:competencesValeurs.experience2, anneeExperience2_employe:competencesValeurs.anneeExperience2,experience3_employe:competencesValeurs.experience3, anneeExperience3_employe:competencesValeurs.anneeExperience3,experience4_employe:competencesValeurs.experience4, anneeExperience4_employe:competencesValeurs.anneeExperience4, 
                                          //       langue1_employe:competencesValeurs.langue1, niveauLangue1_employe:competencesValeurs.niveauLangue1,
                                          //       langue2_employe:competencesValeurs.langue2, niveauLangue2_employe:competencesValeurs.niveauLangue2,
                                          //       langue3_employe:competencesValeurs.langue3, niveauLangue3_employe:competencesValeurs.niveauLangue3,
                                          //       langue4_employe:competencesValeurs.langue4, niveauLangue4_employe:competencesValeurs.niveauLangue4,
                                          //       iban_employe:idBancaireValeurs.iban, banque_employe:idBancaireValeurs.banque,
                                          //       domiciliation_employe:idBancaireValeurs.domiciliation, numeroCompte_employe:idBancaireValeurs.numeroCompte,
                                          //       codeBanque_employe:idBancaireValeurs.codeBanque, codeGuichet_employe:idBancaireValeurs.codeGuichet,
                                          //       cleRib_employe:idBancaireValeurs.cleRib, state_employe:1, modeTravail_employe:affectationValeurs.modeTravail, competence_employe: competencesValeurs.competence,
                                          //       heurePrisePoste_employe: validationValeurs.heurePrisePoste,
                                          //       motifSortie_employe:3, 
                                          //       matricule_manager: token['matriculeActuel']

                                          // },token).catch(error => console.log('error', error));
                                                

      
                                          // let data = new FormData(); 
                  
                                          // data.append("matricule_employe", 'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)) ); 
                                          // data.append("dateEntree_employe", affectationValeurs.dateDebut);
                                          // data.append("dateModification_employe", affectationValeurs.dateDebut);
                                          // data.append("carteVitale_employe", carteVitale);
                                          // data.append("cin_employe", CIN);
                                          // data.append("ts_employe", TS);
                                          // data.append("cv_employe", CV);
                                          // data.append("rib_employe", RIB);
      
                                          // APIService.InsererFichier(data, token)
      
                                          removeToken("id")
                        
                                          removeToken("matriculeEmployeAttente")
                                          
                                          setEtatValidation(1)
                        
                                    }
      
                              }else{
                                    console.log("Attente")
                                    if(affectationValeurs.typeContrat==='CDI'){
                                          dateSortie = ''
                                    }else{
                                          dateSortie = affectationValeurs.dateFin     
                                    }
      
                                    APIService.AjouterEmployeTemporaire({matricule_employe:'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS)), dateEntree_employe:affectationValeurs.dateDebut, dateModification_employe:affectationValeurs.dateDebut, dateFinModification_employe:affectationValeurs.dateDebut,dateSortie_employe:dateSortie,
                                          nom_employe:donneesSalariesValeurs.nom, prenom_employe:donneesSalariesValeurs.prenom, sexe_employe:donneesSalariesValeurs.sexe, numeroSS_employe:donneesSalariesValeurs.numeroSS, cle_employe:donneesSalariesValeurs.cle, dateNaissance_employe:donneesSalariesValeurs.dateNaissance, 
                                          paysNaissance_employe:donneesSalariesValeurs.paysNaissance, departementNaissance_employe:donneesSalariesValeurs.departementNaissance, nationnalite1_employe:donneesSalariesValeurs.nationalite, nationnalite2_employe:donneesSalariesValeurs.nationalite2, 
                                          situationFamiliale_employe:donneesSalariesValeurs.situationFamiliale, nombreEnfant_employe:donneesSalariesValeurs.nombreEnfants, adresse_employe:coordonneesValeurs.NomRue, pays_employe:coordonneesValeurs.pays, 
                                          codePostale_employe:coordonneesValeurs.codePostale, ville_employe:coordonneesValeurs.ville, tel_employe:coordonneesValeurs.telephoneMobile, 
                                          email_employe:coordonneesValeurs.email, etablissement_employe:affectationValeurs.etablissement, departement_employe:affectationValeurs.departement, 
                                          poste_employe:affectationValeurs.poste, typeContrat_employe:affectationValeurs.typeContrat, plageHoraire_employe:affectationValeurs.nbrHeuresHebdo, jourRepos1_employe:affectationValeurs.jourRepos1, jourRepos2_employe:affectationValeurs.jourRepos2, nomRue_employe:coordonneesValeurs.NomRue, NRue_employe:coordonneesValeurs.NRue, complementAdresse_employe:"", chez_employe:coordonneesValeurs.chez, telephoneFixe_employe:coordonneesValeurs.telephoneFixe,formation1_employe:competencesValeurs.formation1, anneeFormation1_employe:competencesValeurs.anneeFormation1, formation2_employe:competencesValeurs.formation2, anneeFormation2_employe:competencesValeurs.anneeFormation2,formation3_employe:competencesValeurs.formation3, anneeFormation3_employe:competencesValeurs.anneeFormation3,formation4_employe:competencesValeurs.formation4, anneeFormation4_employe:competencesValeurs.anneeFormation4,
                                          experience1_employe:competencesValeurs.experience1, anneeExperience1_employe:competencesValeurs.anneeExperience1, experience2_employe:competencesValeurs.experience2, anneeExperience2_employe:competencesValeurs.anneeExperience2,experience3_employe:competencesValeurs.experience3, anneeExperience3_employe:competencesValeurs.anneeExperience3,experience4_employe:competencesValeurs.experience4, anneeExperience4_employe:competencesValeurs.anneeExperience4, 
                                          langue1_employe:competencesValeurs.langue1, niveauLangue1_employe:competencesValeurs.niveauLangue1,
                                          langue2_employe:competencesValeurs.langue2, niveauLangue2_employe:competencesValeurs.niveauLangue2,
                                          langue3_employe:competencesValeurs.langue3, niveauLangue3_employe:competencesValeurs.niveauLangue3,
                                          langue4_employe:competencesValeurs.langue4, niveauLangue4_employe:competencesValeurs.niveauLangue4,
                                          iban_employe:idBancaireValeurs.iban, banque_employe:idBancaireValeurs.banque,
                                          domiciliation_employe:idBancaireValeurs.domiciliation, numeroCompte_employe:idBancaireValeurs.numeroCompte,
                                          codeBanque_employe:idBancaireValeurs.codeBanque, codeGuichet_employe:idBancaireValeurs.codeGuichet,
                                          cleRib_employe:idBancaireValeurs.cleRib, state_employe:true,  modeTravail_employe: affectationValeurs.modeTravail,
                                          competence_employe: competencesValeurs.competence, heurePrisePoste_employe: validationValeurs.heurePrisePoste,
                                          motifSortie_employe:3, 
                                          matricule_manager: token['matriculeManager']
                                          // matricule_manager: "m0"

                                    },token)
      
                                          // let data = new FormData(); 
                  
                                          // data.append("matricule_employe", 'M'.concat((donneesSalariesValeurs.nom).concat(donneesSalariesValeurs.numeroSS))); 
                                          // data.append("dateEntree_employe", affectationValeurs.dateDebut);
                                          // data.append("dateModification_employe", affectationValeurs.dateDebut);
                                          // data.append("carteVitale_employe", carteVitale);
                                          // data.append("cin_employe", CIN);
                                          // data.append("ts_employe", TS);
                                          // data.append("cv_employe", CV);
                                          // data.append("rib_employe", RIB);
                                          
                                          // APIService.InsererFichierTemporaire(data, token)
      
                                         
      
                                          removeToken("id")
                                          removeToken("matriculeEmployeAttente")
      
                                          setEtatValidation(3)
      
                              }
                        }
                        // CV
                        }
                        
                        }
      
                  } 
                  // carte vitale

            }
           
            

            

            
            
      }
      


  function inputId(valeur, erreur, nomId){
            if(!valeur && !erreur)
                  {return nomId }
                  else {
                        if(valeur && !erreur){       
                              return Styles.inputVert;
                        }else{
                              if(erreur){
                                    return Styles.inputRouge;
                              }
                        }
                  }
        }   

      // Déclaration des Ids des inputs 
      const [inputNom, setInputNom] = useState('')
      const [inputPrenom, setInputPrenom] = useState('')
      const [inputSexe, setInputSexe] = useState('')
      const [inputDateNaissance, setInputDateNaissance] = useState('')
      const [inputPaysNaissance, setInputPaysNaissance] = useState('')
      const [inputDepartementNaissance, setInputDepartementNaissance] = useState('')
      const [inputNationalite1, setInputNationalite1] = useState('')
      const [inputNumeroSS, setInputNumeroSS] = useState('')
      const [inputCle, setInputCle] = useState('')
      const [inputSituationFamiliale, setInputSituationFamiliale] = useState('')
      const [inputNombreEnfants, setInputNombreEnfants] = useState('')
      const [inputEtablissement, setInputEtablissement] = useState('')
      const [inputDepartement, setInputDepartement] = useState('')
      const [inputPoste, setInputPoste] = useState('')
      const [inputModeTravail, setInputModeTravail] = useState('')
      const [inputTypeContrat, setInputTypeContrat] = useState('')
      const [inputJour1, setInputJour1] = useState('')
      const [inputJour2, setInputJour2] = useState('')
      const [inputDateDebut, setInputDateDebut] = useState('')
      const [inputDateFin, setInputDateFin] = useState('')
      const [inputNbreHeureHebdo, setInputNbreHeureHebdo] = useState('')

      //Formulaire coordonnees
      const [inputNomRue, setInputNomRue] = useState('')
      const [inputNRue, setInputNRue] = useState('')
      const [inputPays, setInputPays] = useState('')
      const [inputCodePostale, setInputCodePostale] = useState('')
      const [inputVille, setInputVille] = useState('')
      const [inputTelephoneMobile, setInputTelephoneMobile] = useState('')

      const [inputEmail, setInputEmail] = useState('')

      //Formulaire compétences
      const [inputAnneeFormation, setInputAnneeFormation] = useState('')
      const [inputIntituleDiplome, setInputIntituleDiplome] = useState('')
      const [inputAnneeExperience, setInputAnneeExperience] = useState('')
      const [inputExperience, setInputExperience] = useState('')
      const [inputLangue1, setInputLangue1] = useState('')
      const [inputNiveauLangue1, setInputNiveauLangue1] = useState('')

      //Formulaire ID. Bancaire
      const [inputBanque, setInputBanque] = useState('')
      const [inputNumeroCompte, setInputInputNumeroCompte] = useState('')
      const [inputCodeBanque, setInputCodeBanque] = useState('')
      const [inputCodeGuichet, setInputCodeGuichet] = useState('')
      const [inputCleRIB, setInputCleRIB] = useState('')
      const [inputIBAN, setInputIBAN] = useState('')
      const [inputBIC, setInputBIC] = useState('')


      //Formulaire Validation

      const [inputHeurePrisePoste, setInputHeurePrisePoste] = useState('')
      useEffect(() => {      
            setInputIBAN(inputId(idBancaireValeurs.bic, formErrorsIdBancaire.iban, "inputBIC"))
        }, [idBancaireValeurs.bic, formErrorsIdBancaire.bic])


      useEffect(() => {      
            setInputIBAN(inputId(idBancaireValeurs.iban, formErrorsIdBancaire.iban, "inputIBAN"))
        }, [idBancaireValeurs.iban, formErrorsIdBancaire.iban])

      useEffect(() => {      
            setInputCleRIB(inputId(idBancaireValeurs.cleRib, formErrorsIdBancaire.cleRib, "inputCleRIB"))
        }, [idBancaireValeurs.cleRib, formErrorsIdBancaire.cleRib])

      useEffect(() => {      
            setInputCodeGuichet(inputId(idBancaireValeurs.codeGuichet, formErrorsIdBancaire.codeGuichet, "inputCodeGuichet"))
        }, [idBancaireValeurs.codeGuichet, formErrorsIdBancaire.codeGuichet])

      useEffect(() => {      
            setInputCodeBanque(inputId(idBancaireValeurs.codeBanque, formErrorsIdBancaire.codeBanque, "inputCodeBanque"))
        }, [idBancaireValeurs.codeBanque, formErrorsIdBancaire.codeBanque])

      useEffect(() => {      
            setInputInputNumeroCompte(inputId(idBancaireValeurs.numeroCompte, formErrorsIdBancaire.numeroCompte, "inputNumeroCompte"))
        }, [idBancaireValeurs.numeroCompte, formErrorsIdBancaire.numeroCompte])

      useEffect(() => {      
            setInputBanque(inputId(idBancaireValeurs.banque, formErrorsIdBancaire.banque, "inputBanque"))
        }, [idBancaireValeurs.banque, formErrorsIdBancaire.banque])


      useEffect(() => {      
            setInputNiveauLangue1(inputId(competencesValeurs.niveauLangue1, formErrorsCompetences.niveauLangue1, "inputNiveauLangue1"))
        }, [competencesValeurs.niveauLangue1, formErrorsCompetences.niveauLangue1])

      useEffect(() => {      
            setInputLangue1(inputId(competencesValeurs.langue1, formErrorsCompetences.langue1, "inputLangue1"))
        }, [competencesValeurs.langue1, formErrorsCompetences.langue1])

      useEffect(() => {      
            setInputExperience(inputId(competencesValeurs.experience1, formErrorsCompetences.experience1, "inputExperience"))
        }, [competencesValeurs.experience1, formErrorsCompetences.experience1])

      useEffect(() => {      
            setInputAnneeExperience(inputId(competencesValeurs.anneeExperience1, formErrorsCompetences.anneeExperience1, "inputAnneeExperience"))
        }, [competencesValeurs.anneeExperience1, formErrorsCompetences.anneeExperience1])

      useEffect(() => {      
            setInputIntituleDiplome(inputId(competencesValeurs.formation1, formErrorsCompetences.formation1, "inputIntituleDiplome"))
        }, [competencesValeurs.formation1, formErrorsCompetences.formation1])

      useEffect(() => {      
            setInputAnneeFormation(inputId(competencesValeurs.anneeFormation1, formErrorsCompetences.anneeFormation1, "inputAnneeFormation"))
        }, [competencesValeurs.anneeFormation1, formErrorsCompetences.anneeFormation1])


      useEffect(() => {      
            setInputEmail(inputId(coordonneesValeurs.email, formErrorsCoordonnees.email, "inputEmail"))
        }, [coordonneesValeurs.email, formErrorsCoordonnees.email])

      useEffect(() => {      
            setInputVille(inputId(coordonneesValeurs.ville, formErrorsCoordonnees.ville, "inputVille"))
        }, [coordonneesValeurs.ville, formErrorsCoordonnees.ville])

        useEffect(() => {      
            setInputTelephoneMobile(inputId(coordonneesValeurs.telephoneMobile, formErrorsCoordonnees.telephoneMobile, "inputTelephoneMobile"))
        }, [coordonneesValeurs.telephoneMobile, formErrorsCoordonnees.telephoneMobile])

      useEffect(() => {      
            setInputCodePostale(inputId(coordonneesValeurs.codePostale, formErrorsCoordonnees.codePostale, "inputCodePostale"))
        }, [coordonneesValeurs.codePostale, formErrorsCoordonnees.codePostale])

      useEffect(() => {      
            setInputPays(inputId(coordonneesValeurs.pays, formErrorsCoordonnees.pays, "inputPays"))
        }, [coordonneesValeurs.pays, formErrorsCoordonnees.pays])

      useEffect(() => {      
            setInputNRue(inputId(coordonneesValeurs.NRue, formErrorsCoordonnees.NRue, "inputNRue"))
        }, [coordonneesValeurs.NRue, formErrorsCoordonnees.NRue])

      useEffect(() => {      
            setInputNomRue(inputId(coordonneesValeurs.NomRue, formErrorsCoordonnees.NomRue, "inputNomRue"))
        }, [coordonneesValeurs.NomRue, formErrorsCoordonnees.NomRue])




      useEffect(() => {      
            setInputNbreHeureHebdo(inputId(affectationValeurs.nbrHeuresHebdo, formErrorsAffectation.nbrHeuresHebdo, "inputNbreHeureHebdo"))
        }, [affectationValeurs.nbrHeuresHebdo, formErrorsAffectation.nbrHeuresHebdo])

      useEffect(() => {      
            setInputDateFin(inputId(affectationValeurs.dateFin, formErrorsAffectation.dateFin, "inputDateFin"))
        }, [affectationValeurs.dateFin, formErrorsAffectation.dateFin])

      useEffect(() => {      
            setInputDateDebut(inputId(affectationValeurs.dateDebut, formErrorsAffectation.dateDebut, "inputDateDebut"))
        }, [affectationValeurs.dateDebut, formErrorsAffectation.dateDebut])

      useEffect(() => {      
            setInputJour2(inputId(affectationValeurs.jourRepos2, formErrorsAffectation.jourRepos2, "inputJour2"))
        }, [affectationValeurs.jourRepos2, formErrorsAffectation.jourRepos2])

      useEffect(() => {      
            setInputJour1(inputId(affectationValeurs.jourRepos1, formErrorsAffectation.jourRepos1, "inputJour1"))
        }, [affectationValeurs.jourRepos1, formErrorsAffectation.jourRepos1])

      useEffect(() => {      
            setInputTypeContrat(inputId(affectationValeurs.typeContrat, formErrorsAffectation.typeContrat, "inputTypeContrat"))
        }, [affectationValeurs.typeContrat, formErrorsAffectation.typeContrat])

      useEffect(() => {      
            setInputTypeContrat(inputId(affectationValeurs.typeContrat, formErrorsAffectation.typeContrat, "inputTypeContrat"))
        }, [affectationValeurs.typeContrat, formErrorsAffectation.typeContrat])

      useEffect(() => {      
            setInputPoste(inputId(affectationValeurs.poste, formErrorsAffectation.poste, "inputPoste"))
        }, [affectationValeurs.poste, formErrorsAffectation.poste])

      
      useEffect(() => {      
            setInputHeurePrisePoste(inputId(validationValeurs.heurePrisePoste, formErrorsValidation.heurePrisePoste, "inputHeurePrisePoste"))
        }, [validationValeurs.heurePrisePoste, formErrorsValidation.heurePrisePoste])

      useEffect(() => {      
            setInputModeTravail(inputId(affectationValeurs.modeTravail, formErrorsAffectation.modeTravail, "inputModeTravail"))
        }, [affectationValeurs.modeTravail, formErrorsAffectation.modeTravail])

      useEffect(() => {      
            setInputDepartement(inputId(affectationValeurs.departement, formErrorsAffectation.departement, "inputDepartement"))
            
        }, [affectationValeurs.departement, formErrorsAffectation.departement])


      useEffect(() => {      
            setInputEtablissement(inputId(affectationValeurs.etablissement, formErrorsAffectation.etablissement, "inputEtablissement"))
            
        }, [affectationValeurs.etablissement, formErrorsAffectation.etablissement])





      
 
  useEffect(() => {
      
      setInputNom(inputId(donneesSalariesValeurs.nom, formErrors.nom, "inputNom"))
      
  }, [donneesSalariesValeurs.nom, formErrors.nom])

  useEffect(() => {
      setInputPrenom(inputId(donneesSalariesValeurs.prenom, formErrors.prenom, "inputPrenom"))
  }, [donneesSalariesValeurs.prenom, formErrors.prenom])

  useEffect(() => {

            setInputSexe(inputId(donneesSalariesValeurs.sexe, formErrors.sexe, "inputSexe"))
      
  }, [donneesSalariesValeurs.sexe, formErrors.sexe])


  useEffect(() => {

            setInputDateNaissance(inputId(donneesSalariesValeurs.dateNaissance, formErrors.dateNaissance, "inputDateNaissance"))
      
  }, [donneesSalariesValeurs.dateNaissance, formErrors.dateNaissance])

  useEffect(() => {


      setInputPaysNaissance(inputId(donneesSalariesValeurs.paysNaissance, formErrors.paysNaissance, "inputPaysNaissance"))

}, [donneesSalariesValeurs.paysNaissance, formErrors.paysNaissance])

useEffect(() => {


      setInputDepartementNaissance(inputId(donneesSalariesValeurs.departementNaissance, formErrors.departementNaissance, "inputDepartementNaissance"))

}, [donneesSalariesValeurs.departementNaissance, formErrors.departementNaissance])

useEffect(() => {


      setInputNationalite1(inputId(donneesSalariesValeurs.nationalite, formErrors.nationalite, "inputNationalite1"))

}, [donneesSalariesValeurs.nationalite, formErrors.nationalite])

useEffect(() => {


      setInputNumeroSS(inputId(donneesSalariesValeurs.numeroSS, formErrors.numeroSS, "inputNumeroSS"))

}, [donneesSalariesValeurs.numeroSS, formErrors.numeroSS])

useEffect(() => {

      setInputCle(inputId(donneesSalariesValeurs.cle, formErrors.cle, "inputCle"))

}, [donneesSalariesValeurs.cle, formErrors.cle])



useEffect(() => {

      setInputSituationFamiliale(inputId(donneesSalariesValeurs.situationFamiliale, formErrors.situationFamiliale, "inputSituationFamiliale"))

}, [donneesSalariesValeurs.situationFamiliale, formErrors.situationFamiliale])

useEffect(() => {

      setInputNombreEnfants(inputId(donneesSalariesValeurs.nombreEnfants, formErrors.nombreEnfants, "inputNombreEnfants"))

}, [donneesSalariesValeurs.nombreEnfants, formErrors.nombreEnfants])
 
// data extraction
useEffect(() => {
      if (respFromServer){
      
      setValeursDonnees({nom:respFromServer.nom, prenom:respFromServer.prenom, sexe:respFromServer.sexe, numeroSS:respFromServer.numeroSS, cle:'', dateNaissance:respFromServer.dateNaissance, paysNaissance:employe.paysNaissance_employe, departementNaissance:'', nationalite:respFromServer.nationalite, nationalite2:'', situationFamiliale:'', nombreEnfants:''})
      setValeursCoordonnees({NRue:'', NomRue:'', complementAdresse:'', chez:'', lieuDeNaissance:"", pays:'', codePostale:'', ville:'', telephoneFixe:'', telephoneMobile:respFromServer.numero, email:respFromServer.email})
      setValeursCompetences({anneeFormation1:'', formation1:'',anneeFormation2:'', formation2:'',anneeFormation3:'', formation3:'',anneeFormation4:'', formation4:'', anneeExperience1:'', experience1:'',anneeExperience2:'', experience2:'',anneeExperience3:'', experience3:'',anneeExperience4:'', experience4:'', langue1:respFromServer.langue.split(",")[0].replace(" ", ""), niveauLangue1:'', langue2:respFromServer.langue.split(",")[1].replace(" ", ""), niveauLangue2:'', langue3:respFromServer.langue.split(",")[2].replace(" ", ""), niveauLangue3:'', langue4:respFromServer.langue.split(",")[3].replace(" ", ""), niveauLangue4:'', competence:respFromServer.competence})
      setValeursIdBancaire({iban:respFromServer.iban, banque:respFromServer.banque, domiciliation:respFromServer.domiciliation, numeroCompte:respFromServer.nc, codeBanque:respFromServer.cb, codeGuichet:respFromServer.cg, cleRib:respFromServer.cr})
      }
}, [respFromServer])

const [urlCIN, setUrlCIN] = useState('')
const [extCIN, setExtCIN] = useState('')

const [urlCarteVitale, setUrlCarteVitale] = useState('')
const [extCarteVitale, setExtCarteVitale] = useState('')

const [urlTS, setUrlTS] = useState('')
const [extTS, setExtTS] = useState('')

const [urlCV, setUrlCV] = useState('')
const [extCV, setExtCV] = useState('')

const [urlRIB, setUrlRIB] = useState('')
const [extRIB, setExtRIB] = useState('')

const [btnAfficherCarteVitale, setBtnAfficherCarteVitale] = useState('')
const [btnAfficherCIN, setBtnAfficherCIN] = useState('')
const [btnAfficherTS, setBtnAfficherTS] = useState('')
const [btnAfficherCV, setBtnAfficherCV] = useState('')
const [btnAfficherRIB, setBtnAfficherRIB] = useState('')


useEffect(() => {
            
      if(RIB)
      {
      
            
            if(typeof(RIB) === "string"){
                  if(RIB.includes("http")){
                        setUrlRIB(RIB)
                        
                  }
            }
            else{      
                        setExtRIB(RIB.name.split(".").at(-1))
                        
                        setUrlRIB(URL.createObjectURL(RIB))
                  }

}
      

}, [RIB])

const [etatAfficherRIB, setEtatAfficherRIB] = useState(false)

const afficherRIB = () => {

      if(etatAfficherRIB){
            setEtatAfficherRIB(false)
            setBtnAfficherRIB('')

      }else{
            setEtatAfficherCarteVitale(false)
            setEtatAfficherCIN(false)
            setEtatAfficherTS(false)
            setEtatAfficherCV(false)
            setEtatAfficherRIB(true)

            setBtnAfficherCarteVitale('')
            setBtnAfficherCIN('')
            setBtnAfficherTS('')
            setBtnAfficherCV('')
            setBtnAfficherRIB("btnAfficherExtract")

      }

}



useEffect(() => {
            
      if(CV)
      {
      
            
            if(typeof(CV) === "string"){
                  if(CV.includes("http")){
                        setUrlCV(CV)
                  }
            }
            else{      
                        setExtCV(CV.name.split(".").at(-1))
                        setUrlCV(URL.createObjectURL(CV))
                  }

}
      

}, [CV])

const [etatAfficherCV, setEtatAfficherCV] = useState(false)

const afficherCV = () => {

      if(etatAfficherCV){
            setEtatAfficherCV(false)
            setBtnAfficherCV('')

      }else{
            setEtatAfficherCarteVitale(false)
            setEtatAfficherCIN(false)
            setEtatAfficherTS(false)
            setEtatAfficherCV(true)
            setEtatAfficherRIB(false)

            setBtnAfficherCarteVitale('')
            setBtnAfficherCIN('')
            setBtnAfficherTS('')
            setBtnAfficherCV("btnAfficherExtract")
            setBtnAfficherRIB('')
      }


}

useEffect(() => {
            
      if(TS)
      {
      
            if(typeof(TS) === "string"){
                  if(TS.includes("http")){
                        setUrlTS(TS)
                  }
            }
            else{      
                        setExtTS(TS.name.split(".").at(-1))
                        setUrlTS(URL.createObjectURL(TS))
                  }

}
      

}, [TS])

const [etatAfficherTS, setEtatAfficherTS] = useState(false)

const afficherTS = () => {

      if(etatAfficherTS){
            setEtatAfficherTS(false)
            setBtnAfficherTS('')
      }else{
            setEtatAfficherCarteVitale(false)
            setEtatAfficherCIN(false)
            setEtatAfficherTS(true)
            setEtatAfficherCV(false)
            setEtatAfficherRIB(false)


            setBtnAfficherCarteVitale('')
            setBtnAfficherCIN('')
            setBtnAfficherTS("btnAfficherExtract")
            setBtnAfficherCV('')
            setBtnAfficherRIB('')
      }


}


useEffect(() => {
            
      if(carteVitale)
      {
      
            if(typeof(carteVitale) === "string"){
                  if(carteVitale.includes("http")){
                        setUrlCarteVitale(carteVitale)
                  }
            }
            else{       
                        setExtCarteVitale(carteVitale.name.split(".").at(-1))
                        setUrlCarteVitale(URL.createObjectURL(carteVitale))
                  }

}
      

}, [carteVitale])

const [etatAfficherCarteVitale, setEtatAfficherCarteVitale] = useState(false)

const afficherCarteVitale = () => {

      if(etatAfficherCarteVitale){
            setEtatAfficherCarteVitale(false)
            setBtnAfficherCarteVitale('')

      }else{
            setEtatAfficherCarteVitale(true)
            setEtatAfficherCIN(false)
            setEtatAfficherTS(false)
            setEtatAfficherCV(false)
            setEtatAfficherRIB(false)

            setBtnAfficherCarteVitale("btnAfficherExtract")
            setBtnAfficherCIN('')
            setBtnAfficherTS('')
            setBtnAfficherCV('')
            setBtnAfficherRIB('')
      }


}


useEffect(() => {
            
            if(CIN)
            {
            
                  if(typeof(CIN) === "string"){
                        if(CIN.includes("http")){
                              setUrlCIN(CIN)
                        }
                  }
                  else{      
                              setExtCIN(CIN.name.split(".").at(-1))
                              setUrlCIN(URL.createObjectURL(CIN))
                        }
      
      }
            

      }, [CIN])

const [etatAfficherCIN, setEtatAfficherCIN] = useState(false)

const afficherCIN = () => {
            if(etatAfficherCIN){
                  setEtatAfficherCIN(false)
                  setBtnAfficherCIN('')

            }else{
                  setEtatAfficherCarteVitale(false)
                  setEtatAfficherCIN(true)
                  setEtatAfficherTS(false)
                  setEtatAfficherCV(false)
                  setEtatAfficherRIB(false)

                  setBtnAfficherCarteVitale('')
                  setBtnAfficherCIN("btnAfficherExtract")
                  setBtnAfficherTS('')
                  setBtnAfficherCV('')
                  setBtnAfficherRIB('')

            }


      }

const [etatChoiceCIN, setEtatChoiceCIN] = useState(false)
const [etatChoiceTS, setEtatChoiceTS] = useState(false)
const [inputChoiceCIN, setInputChoiceCIN] = useState(false)
const [inputChoiceTS, setInputChoiceTS] = useState(false)


const btnChoiceTS = () => {

      if(etatChoiceTS){
            setEtatChoiceTS(false)
            setInputChoiceTS("")

            

      }
      else{
            setEtatChoiceTS(true)
            setCIN('')
            setEtatChoiceCIN(false)

            setInputChoiceCIN("")
            setInputChoiceTS("btnAfficherExtract")
            formErrorsFichiers.CIN = ' '

      }
}

const btnChoiceCIN = () => {
      if(etatChoiceCIN){
            
            setEtatChoiceCIN(false)
            setInputChoiceCIN("")


            

      }
      else{    
            setEtatChoiceCIN(true)
            setTS('')
            setEtatChoiceTS(false)

            setInputChoiceCIN("btnAfficherExtract")
            setInputChoiceTS("")

            formErrorsFichiers.TS = ' '

      }
}

// Formulaire fichiers erreurs 

let varExtCarteVitale = ''
const [fileNameCarteVitale, setFileNameCarteVitale] = useState(null)
useEffect(() => {

      if(carteVitale){

            if(carteVitale.name){
                  // console.log("File name : ", carteVitale.name.split(".").at(0).toString().toLowerCase().length)
                  let ext = carteVitale.name.split(".").at(-1).toString().toLowerCase()
                  if(carteVitale.name.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameCarteVitale(carteVitale.name.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameCarteVitale(carteVitale.name)

                  }



                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(carteVitale.name.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.carteVitale = ' '
                  }else{
                        varExtCarteVitale = carteVitale.name.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.carteVitale = "L'extention '"+ varExtCarteVitale +"' n'est pas valide."
      
                        setCarteVitale('')
                  }
            }
            else{


                  let ext = carteVitale.split(".").at(-1).toString().toLowerCase()
                  if(carteVitale.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameCarteVitale(carteVitale.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameCarteVitale(carteVitale)

                  }


                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(carteVitale.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.carteVitale = ' '
                  }else{
                        varExtCarteVitale = carteVitale.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.carteVitale = "L'extention '"+ varExtCarteVitale +"' n'est pas valide."
      
                        setCarteVitale('')
                  }
            }
      }
}, [carteVitale])

let varExtCIN = ''
const [fileNameCIN, setFileNameCIN] = useState(null)
useEffect(() => {

      if(CIN){

            if(CIN.name){
                  let ext = CIN.name.split(".").at(-1).toString().toLowerCase()
                  if(CIN.name.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameCIN(CIN.name.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameCIN(CIN.name)

                  }



                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(CIN.name.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.CIN = ' '
                  }else{
                        varExtCIN = CIN.name.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.CIN = "L'extention '"+ varExtCIN +"' n'est pas valide."
      
                        setCIN('')
                        
                  }
            }else{
                  let ext = CIN.split(".").at(-1).toString().toLowerCase()
                  if(CIN.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameCIN(CIN.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameCIN(CIN)

                  }



                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(CIN.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.CIN = ' '
                  }else{
                        varExtCIN = CIN.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.CIN = "L'extention '"+ varExtCIN +"' n'est pas valide."
      
                        setCIN('')
                  }
            }
      }
}, [CIN])

let varExtTS = ''
const [fileNameTS, setFileNameTS] = useState(null)
useEffect(() => {

      if(TS){
            if(TS.name){
       // console.log("File name : ", carteVitale.name.split(".").at(0).toString().toLowerCase().length)
       let ext = TS.name.split(".").at(-1).toString().toLowerCase()
       if(TS.name.split(".").at(0).toString().toLowerCase().length > 20){

               setFileNameTS(TS.name.substring(0, 19).toString().toLowerCase()+'...'+ext)
        
         } else {
              setFileNameTS(TS.name)

            }




                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(TS.name.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.TS = ' '
                  }else{
                        varExtTS = TS.name.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.TS = "L'extention '"+ varExtTS +"' n'est pas valide."
      
                        setTS('')
                  }
            }else{

                  let ext = TS.split(".").at(-1).toString().toLowerCase()
                  if(TS.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameTS(TS.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameTS(TS)

                  }




                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(TS.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.TS = ' '
                  }else{
                        varExtTS = TS.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.TS = "L'extention '"+ varExtTS +"' n'est pas valide."
      
                        setTS('')
                  }
            }
            
      }
}, [TS])

let varExtCV = ''
const [fileNameCV, setFileNameCV] = useState(null)
useEffect(() => {

      if(CV){
            if(CV.name){
                  // console.log("File name : ", carteVitale.name.split(".").at(0).toString().toLowerCase().length)
                  let ext = CV.name.split(".").at(-1).toString().toLowerCase()
                  if(CV.name.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameCV(CV.name.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameCV(CV.name)

                  }


                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(CV.name.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.CV = ' '
                  }else{
                        varExtCV = CV.name.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.CV = "L'extention '"+ varExtCV +"' n'est pas valide."
      
                        setCV('')
                  }
            }else{
                  let ext = CV.split(".").at(-1).toString().toLowerCase()
                  if(CV.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameCV(CV.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameCV(CV)

                  }


                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(CV.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.CV = ' '
                  }else{
                        varExtCV = CV.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.CV = "L'extention '"+ varExtCV +"' n'est pas valide."
      
                        setCV('')
                  }
            }
           
      }
}, [CV])

let varExtRIB = ''
const [fileNameRIB, setFileNameRIB] = useState(null)

useEffect(() => {

      if(RIB){
            if(RIB.name){
                  let ext = RIB.name.split(".").at(-1).toString().toLowerCase()
                  if(RIB.name.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameRIB(RIB.name.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameRIB(RIB.name)

                  }


                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(RIB.name.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.RIB = ' '
                  }else{
                        varExtRIB = RIB.name.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.RIB = "L'extention '"+ varExtRIB +"' n'est pas valide."
      
                        setRIB('')
                  }
            }else{
                  let ext = RIB.split(".").at(-1).toString().toLowerCase()
                  if(RIB.split(".").at(0).toString().toLowerCase().length > 20){

                        setFileNameRIB(RIB.substring(0, 19).toString().toLowerCase()+'...'+ext)
                        
                  } else {
                        setFileNameRIB(RIB)

                  }


                  if (['pdf', 'png', 'jpeg', 'jpg'].includes(RIB.split(".").at(-1).toString().toLowerCase())){
                        formErrorsFichiers.RIB = ' '
                  }else{
                        varExtRIB = RIB.split(".").at(-1).toString().toLowerCase()
                        formErrorsFichiers.RIB = "L'extention '"+ varExtRIB +"' n'est pas valide."
      
                        setRIB('')
                  }
            }
            
      }
}, [RIB])
const [fileValue1, setFileValue1] = useState();

useEffect(() => {
  if(fileValue1){
    console.log("fileValue1 : ", fileValue1.name)
  }
}, [fileValue1])


const [fileValues, setFileValues] = useState();

useEffect(() => {
  if(fileValues){
    console.log("fileValues : ", fileValues.name)
  }
}, [fileValues])
const [fileValuess, setFileValuess] = useState();

useEffect(() => {
  if(fileValuess){
    console.log("fileValuess : ", fileValuess.name)
  }
}, [fileValuess])

const [fileValue4, setFileValue4] = useState();

useEffect(() => {
  if(fileValue4){
    console.log("fileValue4 : ", fileValue4.name)
  }
}, [fileValue4])

const [fileValue5, setFileValue5] = useState();

useEffect(() => {
  if(fileValue5){
    console.log("fileValue5 : ", fileValue5.name)
  }
}, [fileValue5])


const [choixFiles, setChoixFiles] = useState();

useEffect(() => {
      if(choixFiles){
            if(choixFiles === "CIN"){
                  if(TS){
                        setTS();
                  }
            } else {
                  if(choixFiles === "TS"){
                        if(CIN){
                              setCIN();
                        }
                  }
            }
      }
 
}, [choixFiles])
// ('.scrollContainer').on('scroll', function() {
//       ('.scrollContainer').scrollTop((this).scrollTop());
//     });
    
function afficherNomFichier() {
      var inputFichier = document.getElementById('getFile1');
      var nomFichier = inputFichier.value.split('\\').pop(); // Récupère le nom du fichier sans le chemin
      var nomCourt = nomFichier.substring(0, 5); // Récupère les 5 premiers caractères
    
      document.getElementById('names').textContent = 'Nom du fichier : ' + nomCourt;
    }
    console.log("etablissement" ,affectationValeurs.etablissement) 
    console.log("departement" ,affectationValeurs.departement) 
    console.log("poste" ,affectationValeurs.poste) 


function displayErrorInput(erreur){
      return (
      <>
      
      <div className={Styles.container2}>
      <div className={Styles.zoneInputInput}>
            <span >
            
            </span>
            <span  className={
                        (!CIN && !CV && !RIB && !carteVitale && !TS) || etat === 'affectation'  ? 
                        Styles.textdecorationInput
                        : 
                        Styles.textdecorationInputWithFiles }>

           


            {erreur ? 
            <>{erreur}</>
            :
            <></>
            } </span>
      </div>
</div>
      </>)
      
}

  if(afficher === true){
  return (
    <div>
      <div 
      // className='container'
      >

      {/* <EmbaucheNavBar /> */}

{/* DEBUT FORMULAIRE  */}

{etat === "fichiers" ?    
            <div 
            className={Styles.classCard}
            
            
            >
                  <div className={Styles.formFichiersSection}>

                  

            <div className={Styles.containerBar}>
                  <Menu section = "fichiers" setEtat = {setEtat}/>   

            </div>
            <div className={Styles.captionStyle}>
                        <caption ><h3>Formulaire Fichiers</h3></caption>
            </div>
            <div className={Styles.formContainer}>
                  <form className={Styles.formSection}>

            {/* carte vitale  */}
           

            {carteVitale ? 
            <>
            <div className={Styles.divFileInputStyle}>
                 
                  <span className={Styles.fileNameTxtStyle}>
                  Carte vitale  
                  </span>
                  <span className={Styles.fileInputContainer}> 
                  
                        <span className={Styles.iconContainer}>
                        <label id='getFile1'  className={Styles.btnUploadFile} title='Veuillez télécharger la carte vitale'>
                              
                              <input type="file" id='getFile1'
                              accept="image/png, image/jpeg, image/jpg, application/pdf" 
                              onChange={e => {setCarteVitale(e.target.files[0])}}
                              hidden 
                              />
                                    
                        </label>
                        </span>
                        <span className={Styles.fileNameContainer} >
                              
                                          {
                                    carteVitale ? 
                                   <>{fileNameCarteVitale}</>:
                                    <>Aucun fichier choisi</>  
                                    } 
                        </span> 
                  
                  </span>  
            
              
            </div>
            
            {/* <p id="fichier-choisi" className={Styles.fichierchoisi}>
                                          {!carteVitale.name ? <div className={Styles.divicone}>{String(carteVitale).split('/').slice(-1)[0]} &nbsp;  <a href={carteVitale} target="_blank" rel="noopener noreferrer" className='btn btn-outline-dark'><img src={download} id={Styles.iconDownload}></img></a></div>:
                                    <div>{carteVitale.name}</div>}</p>     */}
            
            </>
            : 
            <div className={Styles.divFileInputStyle} >
                  <span className={Styles.fileNameTxtStyle}>
                  Carte vitale  
                  </span>
                  <span className={Styles.fileInputContainer} > 
                  
                        <span className={Styles.iconContainer}>

                        <label id='getFile1'  className={Styles.btnUploadFile} title='Veuillez télécharger la carte vitale'>
                              
                              <input type="file" id='getFile1' 
                              accept="image/png, image/jpeg, image/jpg, application/pdf" 
                              onChange={e => {setCarteVitale(e.target.files[0])}}
                              hidden
                              />
                                    
                        </label>
                      
                        </span>
                        <span className={Styles.fileNameContainer}>
                              
                                      
                                    <>Aucun fichier choisi</>  
                                    
                        </span> 
                  
                  </span>  
            
              
            </div>
            
      
      
      }
   {/* error carte vitale  */}
{
      formErrorsFichiers.carteVitale !== ' ' && formErrorsFichiers.carteVitale ? 
                                    <>
                                    <div className={Styles.alertContainer} >
                                    <div className={Styles.zoneInput}>
                                                <span >
                                               
                                                </span>
                                          
                                                <div className={Styles.textdecoration1} >
                                               
                                       <span className={Styles.alertIconContainer}> <FontAwesomeIcon icon={faTriangleExclamation} className={Styles.iconError}/></span>
                                       <span className={Styles.alertTextStyle}>{formErrorsFichiers.carteVitale}

<br />                                <span  className={Styles.alertTextStyle} >Liste des extentions valide : PNG, JPEG, JPG, PDF. </span> 

                                                      </span>
                                                      
                                                       </div>
                                    </div>
                                    </div>
                                    



                                   
                                    </>
                                    :
                                    <></>}
            





              
              <br />
              <div className={Styles.divFileInputStyleChoix}>
                <span className={Styles.sectionChoix}>

                {
                  !["CIN", "TS"].includes(choixFiles) ? 
                  <>
                  <div className={Styles.divFirstChoice}>
                      <span className={Styles.fileNameTxtStyle1}>
                        <input type="radio" id="huey" name="drone" value="CIN" onChange={(e) => setChoixFiles(e.target.value)} style={{marginBottom:"8px"}}/>
                        <label for="huey" title='Veuillez télécharger la Carte nationale d identité'>&nbsp; CIN </label>
                      </span>

                      <span className={Styles.fileInputContainerCenterRight}>

                      <span className={Styles.fileInputContainer1}> 
                
                          <span className={Styles.iconContainer}>

                              <label id='getCIN'  className={Styles.btnUploadFile}>
                                    <input type="text" id='getCIN' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setCIN(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                            
                          </span>
                        <span className={Styles.fileNameContainer} 
                            style={{backgroundColor:"lightgray", color:"gray"}}
                        
                        >
                          
                                    {
                                CIN ? 
                                <>{fileNameCIN}</>  :
                                <>Aucun fichier choisi</>  
                              } 
                          </span> 
                      </span> 

                      </span>


                  </div>

                  <div className={Styles.divFirstChoice} style={{marginTop:"5px"}}>
            <span className={Styles.fileNameTxtStyle1}>
              <input type="radio" id="huey" name="drone" value="TS" onChange={(e) => setChoixFiles(e.target.value)} style={{marginBottom:"8px"}}/>
              <label for="huey" title='Veuillez télécharger la carte de séjour'>&nbsp; Carte de séjour</label>
            </span>

            <span className={Styles.fileInputContainerCenterRight}>

            <span className={Styles.fileInputContainer1}> 
      
                <span className={Styles.iconContainer}>
                <label id='getTS'  className={Styles.btnUploadFile}>
                                    <input type="text" id='getTS' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setTS(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                </span>
              <span className={Styles.fileNameContainer}
                            style={{backgroundColor:"lightgray", color:"gray"}}
              
              >
                
                          {
                      TS ? 
                      <>{fileNameTS}</>  :
                      <>Aucun fichier choisi</>  
                    } 
                </span> 
            </span> 

            </span>


        </div>




                  </>

                  :<>
                  {
                        choixFiles === "CIN" ?
                        <>
{/* Carte nationale d'identité */}



{CIN  ? 
            <>
            <div className={Styles.divFirstChoice}>
                      <span className={Styles.fileNameTxtStyle1}>
                        <input type="radio" id="huey" name="drone" value="huey" style={{marginBottom:"8px"}} checked/>
                        <label for="huey" title='Veuillez télécharger la Carte nationale d identité'>&nbsp; CIN</label>
                      </span>

                      <span className={Styles.fileInputContainerCenterRight}>

                      <span className={Styles.fileInputContainer1}> 
                
                          <span className={Styles.iconContainer}>
                          <label id='getCIN'  className={Styles.btnUploadFile}>
                                    <input type="file" id='getCIN' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setCIN(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                          </span>
                        <span className={Styles.fileNameContainer}>
                          
                                    {
                                CIN ? 
                                <>{fileNameCIN}</>  :
                                <>Aucun fichier choisi</>  
                              } 
                          </span> 
                      </span> 
                      </span>


                  </div>
{/*             
            <p id="fichier-choisi" className={Styles.fichierchoisi}>
                                          {!CIN.name ? <div className={Styles.divicone}>{String(CIN).split('/').slice(-1)[0]} &nbsp;  <a href={CIN} target="_blank" rel="noopener noreferrer" className='btn btn-outline-dark'><img src={download} id={Styles.iconDownload}></img></a></div>:
                                    <div>{CIN.name}</div>}</p>     */}
            
            </>
            : 
            <div className={Styles.divFirstChoice}>
                
                        <span className={Styles.fileNameTxtStyle1}>
                              <input type="radio" id="huey" name="drone" value="CIN" onChange={(e) => setChoixFiles(e.target.value)} style={{marginBottom:"8px"}} checked/>
                              <label for="huey" title='Veuillez télécharger la Carte nationale d identité'>&nbsp; CIN</label>
                        </span>
<span className={Styles.fileInputContainerCenterRight}>
<span className={Styles.fileInputContainer1}> 
                      
                
                          <span className={Styles.iconContainer}>
                          <label id='getCIN'  className={Styles.btnUploadFile}>
                                    <input type="file" id='getCIN' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setCIN(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                          </span>
                        <span className={Styles.fileNameContainer}>
                          
                                    {
                                CIN ? 
                                <>{fileNameCIN}</>  :
                                <>Aucun fichier choisi</>  
                              } 
                          </span> 
                      </span></span>
                      {/* 


                        */}


                  </div>
            
      
      
      }

      {/* Carte de séjour */}

      <div className={Styles.divFirstChoice} style={{marginTop:"5px"}}>
            <span className={Styles.fileNameTxtStyle1}>
              <input type="radio" id="huey" name="drone" value="TS" onChange={(e) => setChoixFiles(e.target.value)} style={{marginBottom:"8px"}}/>
              <label for="huey" title='Veuillez télécharger la carte de séjour'>&nbsp; Carte de séjour</label>
            </span>

            <span className={Styles.fileInputContainerCenterRight}>

            <span className={Styles.fileInputContainer1}> 
      
                <span className={Styles.iconContainer}>
                <label id='getTS'  className={Styles.btnUploadFile}>
                                    <input type="text" id='getTS' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setTS(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                </span>
              <span className={Styles.fileNameContainer}
                            style={{backgroundColor:"lightgray", color:"gray"}}
              
              >
                
                          {
                      TS ? 
                      <>{fileNameTS}</>  :
                      <>Aucun fichier choisi</>  
                    } 
                </span> 
            </span> 

            </span>


        </div>


                        </>:
                        <>
      {/* Carte nationale d'identité */}



      <div className={Styles.divFirstChoice}>
                      <span className={Styles.fileNameTxtStyle1}>
                        <input type="radio" id="huey" name="drone" value="CIN" onChange={(e) => setChoixFiles(e.target.value)} style={{marginBottom:"8px"}}/>
                        <label for="huey" title='Veuillez télécharger la Carte nationale d identité'>&nbsp; CIN</label>
                      </span>

                      <span className={Styles.fileInputContainerCenterRight}>

                      <span className={Styles.fileInputContainer1}> 
                
                          <span className={Styles.iconContainer}>
                          <label id='getCIN'  className={Styles.btnUploadFile}>
                                    <input type="text" id='getCIN' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setCIN(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                          </span>
                        <span className={Styles.fileNameContainer} 
                            style={{backgroundColor:"lightgray", color:"gray"}}
                        
                        >
                          
                                    {
                                CIN ? 
                                <>{fileNameTS}</>  :
                                <>Aucun fichier choisi</>  
                              } 
                          </span> 
                      </span> 
                      </span>


                  </div>

      {/* Carte de séjour */}

{TS ? 
            <>
                             <div className={Styles.divFirstChoice} style={{marginTop:"5px"}}>
                      <span className={Styles.fileNameTxtStyle1}>
                        <input checked type="radio" id="huey" name="drone" value="TS" onChange={(e) => setChoixFiles(e.target.value)} style={{marginBottom:"8px"}}/>
                        <label for="huey" title='Veuillez télécharger la carte de séjour'>&nbsp; Carte de séjour</label>
                      </span>

                      <span className={Styles.fileInputContainerCenterRight}>

                      <span className={Styles.fileInputContainer1}> 
                
                          <span className={Styles.iconContainer}>
                          <label id='getTS'  className={Styles.btnUploadFile}>
                                    <input type="file" id='getTS' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setTS(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                          </span>
                        <span className={Styles.fileNameContainer}>
                          
                                    {
                                TS ? 
                                <>{fileNameTS}</>  :
                                <>Aucun fichier choisi</>  
                              } 
                          </span> 
                      </span> 
                      </span>


                  </div>
            
            {/* <p id="fichier-choisi" className={Styles.fichierchoisi}>
                                          {!TS.name ? <div className={Styles.divicone}>{String(TS).split('/').slice(-1)[0]} &nbsp;  <a href={TS} target="_blank" rel="noopener noreferrer" className='btn btn-outline-dark'><img src={download} id={Styles.iconDownload}></img></a></div>:
                                    <div>{TS.name}</div>}</p>     */}
            
            </>
            : 
            <div className={Styles.divFirstChoice} style={{marginTop:"5px"}}>
            <span className={Styles.fileNameTxtStyle1}>
              <input checked type="radio" id="huey" name="drone" value="TS" onChange={(e) => setChoixFiles(e.target.value)} style={{marginBottom:"8px"}}/>
              <label for="huey" title='Veuillez télécharger la carte de séjour'>&nbsp; Carte de séjour</label>
            </span>

            <span className={Styles.fileInputContainerCenterRight}>

            <span className={Styles.fileInputContainer1}> 
      
                <span className={Styles.iconContainer}>
                <label id='getTS'  className={Styles.btnUploadFile}>
                                    <input type="file" id='getTS' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setTS(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                </span>
              <span className={Styles.fileNameContainer}>
                
                          {
                      TS ? 
                      <>{fileNameTS}</>  :
                      <>Aucun fichier choisi</>  
                    } 
                </span> 
            </span> 
            </span>


        </div>
            
      
      
      }                  
                        
                        </>
                  }
                  
                  </>
      
}





                </span>
              </div>


                {/* Curriculum Vitae  */}
                <br />

            {CV ? 
            <>
            <div className={Styles.divFileInputStyle}>
                  <span className={Styles.fileNameTxtStyle}>
                  Curriculum Vitae  
                  </span>
                  <span className={Styles.fileInputContainer}> 
                  
                        <span className={Styles.iconContainer}>
                        <label id='getCV'  className={Styles.btnUploadFile} title='Veuillez télécharger le Curriculum Vitae'>
                                    <input type="file" id='getCV' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setCV(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
          
                        </span>
                        <span className={Styles.fileNameContainer}>
                              
                                          {
                                    CV ? 
                                    <>{fileNameCV}</>  :
                                    <>Aucun fichier choisi</>  
                                    } 
                        </span> 
                  
                  </span>  
            
              
            </div>
            
            {/* <p id="fichier-choisi" className={Styles.fichierchoisi}>
                                          {!CV.name ? <div className={Styles.divicone}>{String(CV).split('/').slice(-1)[0]} &nbsp;  <a href={CV} target="_blank" rel="noopener noreferrer" className='btn btn-outline-dark'><img src={download} id={Styles.iconDownload}></img></a></div>:
                                    <div>{CV.name}</div>}</p>     */}
            
            </>
            : 
            <div className={Styles.divFileInputStyle}>
                  <span className={Styles.fileNameTxtStyle}>
                  Curriculum Vitae 
                  </span>
                  <span className={Styles.fileInputContainer}> 
                  
                        <span className={Styles.iconContainer}>
                        <label id='getCV'  className={Styles.btnUploadFile} title='Veuillez télécharger le Curriculum Vitae'>
                                    <input type="file" id='getCV' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setCV(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
                        </span>
                        <span className={Styles.fileNameContainer}>
                              
                                          {
                                    CV ? 
                                    <>{CV.name}</>  :
                                    <>Aucun fichier choisi</>  
                                    } 
                        </span> 
                  
                  </span>  
            
              
            </div>
            
      
      
      }
      {/* RIB  */}
      <br />

{RIB ? 
<>
<div className={Styles.divFileInputStyle}>
      <span className={Styles.fileNameTxtStyle}>
      Relevé d'Identité Bancaire  
      </span>
      <span className={Styles.fileInputContainer}> 
      
            <span className={Styles.iconContainer}>
            <label id='getRIB'  className={Styles.btnUploadFile} title="Veuillez télécharger le Relevé d'Identité Bancaire">
                                    <input type="file" id='getRIB' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setRIB(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
            
            </span>
            <span className={Styles.fileNameContainer}>
                  
                              {
                        RIB ? 
                        <>{fileNameRIB}</>  :
                        <>Aucun fichier choisi</>  
                        } 
            </span> 
      
      </span>  

  
</div>

</>
: 
<div className={Styles.divFileInputStyle}>
      <span className={Styles.fileNameTxtStyle}>
      Relevé d'Identité Bancaire
      </span>
      <span className={Styles.fileInputContainer}> 
      
            <span className={Styles.iconContainer}>
            <label id='getRIB'  className={Styles.btnUploadFile} title="Veuillez télécharger le Relevé d'Identité Bancaire">
                                    <input type="file" id='getRIB' 
                                    accept="image/png, image/jpeg, image/jpg, application/pdf" 
                                    onChange={e => {setRIB(e.target.files[0])}}
                                    hidden
                                    />
                              </label>
            </span>
            <span className={Styles.fileNameContainer}>
                  
                              {
                        RIB ? 
                        <>{RIB.name}</>  :
                        <>Aucun fichier choisi</>  
                        } 
            </span>      
      </span>  
</div>

}
               <br />
               <div 
              id={Styles.divBtnExtraireContainer}
              >
                    <span 
                      onClick={handleUpload} 
                    className={Styles.btnExtraire}
                  >
                        Extraire
                    </span>

                    
                </div>
                
                
                
            </form>
            </div>
            
      
            <div>
                        <button className={Styles.btnAnnuler} onClick={() => navigate('/embauche')} style={{cursor:"pointer"}}>
                        <span>&nbsp;Annuler</span> 
                        </button> 
                        <button onClick = {btnTestEmbauche}>Test</button>
                        <button className={Styles.btnSubmit2} onClick={btnFichiers}>
                              <span >&nbsp;&nbsp;&nbsp;Embaucher</span> <span > <FontAwesomeIcon icon={faAngleRight} className={Styles.btnNext}/></span>
                              </button>
            </div>
           




            </div>
</div>
:

<div>
{etat === 'donnees'     ?    
            
            
            
            <div className={Styles.classCard}>
<div className={Styles.formFichiersSection}>
            <div className={Styles.containerBar}>

                  <Menu section = "donnees" setEtat = {setEtat}/> 


            </div>

            <div className={Styles.captionStyle}>
                        <caption ><h3>Formulaire Données</h3></caption>
            </div>

            <div className={Styles.formContainerFiles} 
            id={
                        !CIN && !CV && !RIB && !carteVitale && !TS ? 
                              Styles.idTestGridWithoutFiles
                        : 
                              Styles.idTestGridWithFiles


            }
            >

                  <div style={{backgroundColor:"transparent"}} className={Styles.leftSection}>
                  <Input type = "text" title = "Nom" name = "nom" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputNom} value = {donneesSalariesValeurs.nom} handleChange = {handleChangeDonneesSalaries} />

                  {displayErrorInput(formErrors.nom)}
                  <Input type = "text" title = "Prénom" name = "prenom" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputPrenom} value = {donneesSalariesValeurs.prenom} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} />
                  
                  {displayErrorInput(formErrors.prenom)}
                  <Input type = "select" title = "Sexe" name = "sexe" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputSexe} value = {donneesSalariesValeurs.sexe} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} listItem = {listSexe && listSexe.map( resp => <option value={resp.id}>{resp.type}</option> )}/>

                  {displayErrorInput(formErrors.sexe)}
                  <Input type = "date" title = "Date de naissance" name = "dateNaissance" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputDateNaissance} value = {donneesSalariesValeurs.dateNaissance} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} />

                 
                  {displayErrorInput(formErrors.dateNaissance)}
                  <Input type = "select" title = "Pays de naissance" inputId = {inputPaysNaissance} name = "paysNaissance" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS }  value = {donneesSalariesValeurs.paysNaissance} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} listItem =  { listPays &&  listPays.map(  resp => <option key={resp.id} value={resp.id}>{resp.nom}</option>  )}/>

                 
                  {displayErrorInput(formErrors.paysNaissance)}
                  <Input type = "select" title = " Département de naissance" inputId = {inputDepartementNaissance} name = "departementNaissance" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } value = {donneesSalariesValeurs.departementNaissance} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} listItem = {(listVille && listDepartementVille) && listVille.filter(resp => resp.id_pays === parseInt(donneesSalariesValeurs.paysNaissance)).map(resp => listDepartementVille.filter(dept => dept.id_ville === parseInt(resp.id)).map( dept =>  <option value={dept.id}>{dept.nom}</option> ) ) }/>

                  {displayErrorInput(formErrors.departementNaissance)}
                <Input type = "select" title = "Nationalité" inputId = {inputNationalite1} name = "nationalite" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } value = {donneesSalariesValeurs.nationalite} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} listItem ={listNationalite && listNationalite.map( resp => <option value={resp.id}>{resp.nom}</option>) }/>
                  {displayErrorInput(formErrors.nationalite)}


                  <Input type = "text" title = "Numéro SS" name = "numeroSS" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputNumeroSS} value = {donneesSalariesValeurs.numeroSS} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} />

                
                  {displayErrorInput(formErrors.numeroSS)}

                  <Input type = "text" title = "Clé" name = "cle" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputCle} value = {donneesSalariesValeurs.cle} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} />

                 
                  {displayErrorInput(formErrors.cle)}
                  <Input type = "select" title = "Situation familiale" inputId = {inputSituationFamiliale} name = "situationFamiliale" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } value = {donneesSalariesValeurs.situationFamiliale} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} listItem =  {
                                                                              listSituationFamiliale &&
                                                                              listSituationFamiliale.map(
                                                                                    resp => <option value={resp.id}>{resp.nom}</option>
                                                                              )
                                                                         }/>
                


                  {displayErrorInput(formErrors.situationFamiliale)}
                  <Input type = "number" title = "Nombre d'enfants" name = "nombreEnfants" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputNombreEnfants} value = {donneesSalariesValeurs.nombreEnfants} valeurs = {donneesSalariesValeurs} handleChange = {handleChangeDonneesSalaries} />

                 
                  {displayErrorInput(formErrors.nombreEnfants)}


                  {/* old form donnees */}


                  </div>


                  <div className={Styles.rightSection} style={!CIN && !CV && !RIB && !carteVitale && !TS ? 
                              {display:"none"}
                              : 
                              {display:"grid"}}>


                        <div className={Styles.filesTopSection}>
                                    <div className={Styles.radio}>
                                          {
                                                carteVitale ? 
                                                <input label="Carte" type="radio" id="male" name="gender" value="male" onClick={afficherCarteVitale} />
                                                : <></>
                                          }
                                    {
                                          CIN ? 
                                           <input label="CIN" type="radio" id="male" name="gender" value="male"  onClick={afficherCIN} />
                                          :<>
                                          { TS ?
                                                <input label="TS" type="radio" id="femalee" name="gender" value="femalee"  onClick={afficherTS}/> 
                                                : 
                                                <></>
                                          }   
                                          </>
                                    }

                                    {
                                          CV ? 

	                             <input label="CV" type="radio" id="female" name="gender" value="female" onClick={afficherCV}/>
                                          : <></>

                                    }

                                    {
                                          RIB ?
                                          <input label="RIB" type="radio" id="other" name="gender" value="other" onClick={afficherRIB}/>
                                          : <></>
                                    }
      
</div>
                                    
                                    


                
                                    
                        </div>
                        <div className={Styles.filesBottomSection}>
                        
                        {etatAfficherCIN ? <Pdf url={urlCIN} ext={extCIN}/> : <></>}

                        {etatAfficherCarteVitale ? <Pdf url={urlCarteVitale} ext={extCarteVitale}/> : <></>}

                        {etatAfficherTS ? <Pdf url={urlTS} ext={extTS}/> : <></>}{etatAfficherCV ? <Pdf url={urlCV} ext={extCV}/> : <></>}
                   
                        {etatAfficherRIB ? <Pdf url={urlRIB} ext={extRIB}/> : <></>}

                        </div>

                  
                  </div>
        
                  

                  

        </div>



            <div>
                        <button className={Styles.btnAnnuler} onClick={() => navigate('/embauche')} style={{cursor:"pointer"}}>
                        <span>&nbsp;Annuler</span> 
                        </button> 
                        <button className={Styles.btnSubmit2} onClick={btnDonnees}>
                              <span >&nbsp;&nbsp;&nbsp;Affectation</span> <span > <FontAwesomeIcon icon={faAngleRight} className={Styles.btnNext}/></span>
                        </button>
            </div>
</div>
</div>


: 


<div>


{etat === 'affectation'     ? 
   <div className={Styles.classCard}>

<div className={Styles.formFichiersSection}>

            
            <div className={Styles.containerBar}>

                  <Menu section = "affectation" setEtat = {setEtat} /> 

            </div>      
            <div className={Styles.captionStyle}>
                        <caption ><h3>Formulaire Affectation</h3></caption>
            </div>

            <div className={Styles.formContainer} 
             id={
                  !null ? 
                        Styles.idTestGridWithoutFiles
                  : 
                        Styles.idTestGridWithFiles


      }
      
            >

                  <div style={{backgroundColor:"transparent"}} className={Styles.leftSection} >
                  <Input type = "select" title = "Etablissement" inputId = {inputEtablissement} name = "etablissement" value = {affectationValeurs.etablissement}  classZoneInputEtat = { true }  valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} listItem =  { listEtablissement &&  listEtablissement.map( resp =>  <option value={resp.id}>{resp.nom}</option>)}  />

                  {displayErrorInput(formErrorsAffectation.etablissement)}
                  <Input type = "select" title = "Département" inputId = {inputDepartement} name = "departement"  value = {affectationValeurs.departement} classZoneInputEtat = { true }  valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} listItem ={ listDepartement && listDepartement.filter(resp => resp.etablissements.split(',').includes(affectationValeurs.etablissement)).map((departement) => ( <option key={departement.id} value={departement.id}> {departement.nom} </option> ))}/>

                  {displayErrorInput(formErrorsAffectation.departement)}
                  <Input type = "select" title = "Poste" inputId = {inputPoste} name = "poste" value = {affectationValeurs.poste}  classZoneInputEtat = { true }  valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} listItem ={listPoste && listPoste.filter(resp => resp.id_departement.split(',').includes(affectationValeurs.departement)).map((poste) => (<option key={poste.id} value={poste.id}> {poste.nom}</option>)) } />

                  {displayErrorInput(formErrorsAffectation.poste)}

                  <Input type = "select" title = "Type Contrat" inputId = {inputTypeContrat} name = "typeContrat" value = {affectationValeurs.typeContrat}  classZoneInputEtat = { true }  valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} listItem = {  listTypeContrat && listTypeContrat.map(resp => <option value={resp.id}>{resp.nom}</option>  )  }/>
                  {displayErrorInput(formErrorsAffectation.typeContrat)}
                  <Input type = "select" title = "Jour repos 1" inputId = {inputJour1} name = "jourRepos1" value = {affectationValeurs.jourRepos1}  classZoneInputEtat = { true }  valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} listItem = {listJours && listJours.filter(resp => resp.id !== parseInt(affectationValeurs.jourRepos2)).map(resp => <option value={resp.id}>{resp.nom}</option>)} />

                  {displayErrorInput(formErrorsAffectation.jourRepos1)}
                  <Input type = "select" title = " Jour repos 2" inputId = {inputJour2} name = "jourRepos2" value = {affectationValeurs.jourRepos2}  classZoneInputEtat = { true }  valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} listItem =  {
                                                      listJours && 
                                                      listJours.filter(
                                                            resp => resp.id !== parseInt(affectationValeurs.jourRepos1)
                                                      ).map(
                                                            resp => 
                                                            <option value={resp.id}>{resp.nom}</option>
                                                      )
                                                } />

                  {displayErrorInput(formErrorsAffectation.jourRepos2)}
                  <Input type = "date"  title = "Date début" name = "dateDebut" value = {affectationValeurs.dateDebut}  classZoneInputEtat = {true} inputId = {inputDateDebut} valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} />

                  {displayErrorInput(formErrorsAffectation.dateDebut)}

                  <Input 
                  disabled={ 
                        
                        affectationValeurs.typeContrat && affectationValeurs.typeContrat !=="" ?
                        !(parseInt(affectationValeurs.typeContrat) === 1 ||
                        parseInt(affectationValeurs.typeContrat) === 4 ||
                        parseInt(affectationValeurs.typeContrat) === 6 )
                        :
                        true

                      }
                   type = "date"  title = "Date fin" name = "dateFin" value = {affectationValeurs.dateFin}  classZoneInputEtat = {true} inputId = {inputDateFin} valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} />
                  {displayErrorInput(formErrorsAffectation.dateFin)}
                  <InputTemps type = "radio"  title = "Temps" name = "modeTravail" value = {affectationValeurs.modeTravail}  classZoneInputEtat = {true} inputId = {inputModeTravail} valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} listItem= {listTemps}/>

                  {/* <div className={Styles.container1} id={Styles.container4}>
                        
                        <div className={
                        !null ?  
                        Styles.zoneInputInput
                        : 
                        Styles.zoneInputInputWithFiles }>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Temps  
                        </span>
                        <div className={Styles.goldenInput1} id={inputModeTravail}>
                              



                              {affectationValeurs.modeTravail !== "" || !affectationValeurs.modeTravail ? 

                              <>

                             
                                {
                                                                  listTemps && 
                                                                  listTemps.map(
                                                                        resp => 
                                                                        <input label={resp.nom} type="radio" name="modeTravail"  value={resp.id } onChange={handleChangeAffectation}></input>
                                                                  )
                                                            }
                              
                              </>
                                          :
                                          <div>
                                                { (affectationValeurs.modeTravail && listTemps) && 
                                                                  listTemps.map(
                                                                        resp => 
                                                                        {
                                                                              if(resp.id === parseInt(affectationValeurs.modeTravail)){
                                                                                    <input label={resp.nom} type="radio"  name="modeTravail" value={resp.id} onChange={handleChangeAffectation} checked></input>

                                                                              }
                                                                              else {
                                                                                    <input label={resp.nom} type="radio" name="modeTravail" value={resp.id} onChange={handleChangeAffectation}></input>

                                                                              }
                                                                        }
                                                                  )



                                                }
                                                
                                          </div>
  }
                        </div> 
                        </div>
                        
                        
                  </div> */}

                  {displayErrorInput(formErrorsAffectation.modeTravail)}

                  <Input type = "number"  title = "Nbre heures Hebdo" name = "nbrHeuresHebdo"  value = {affectationValeurs.nbrHeuresHebdo} classZoneInputEtat = {true} inputId = {inputNbreHeureHebdo} valeurs = {affectationValeurs} handleChange = {handleChangeAffectation} />

                  {/* <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !null ? 
                        Styles.zoneInputInput
                        : 
                        Styles.zoneInputInputWithFiles }>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Nbre heures Hebdo
                              </span>
                              <input type="number" className={Styles.goldenInput1} min='0' max='120' id={inputNbreHeureHebdo} name='nbrHeuresHebdo' value={affectationValeurs.nbrHeuresHebdo} onChange={handleChangeAffectation}></input>
                        </div>
                  </div>  */}

                  
                 
                  {displayErrorInput(formErrorsAffectation.nbrHeuresHebdo)}



                        </div>
                  <div className={Styles.rightSection} style={!null ?  
                                                {display:"none"}
                                                : 
                                                {display:"grid"}}>


                                          <div className={Styles.filesTopSection}>
                                                      {/* <span onClick={afficherCIN}>CIN</span> */}
                                                      <div className={Styles.radio}>
                                                            {
                                                                  carteVitale ? 
                                                                  <input label="Carte" type="radio" id="male" name="gender" value="male" onClick={afficherCarteVitale} />
                                                                  : <></>
                                                            }
                                                      {
                                                            CIN ? 
                                                      <input label="CIN" type="radio" id="male" name="gender" value="male"  onClick={afficherCIN} />
                                                            :<>
                                                            { TS ?
                                                                  <input label="TS" type="radio" id="femalee" name="gender" value="femalee"  onClick={afficherTS}/> 
                                                                  : 
                                                                  <></>
                                                            }   
                                                            </>
                                                      }

                                                      {
                                                            CV ? 

                        <input label="CV" type="radio" id="female" name="gender" value="female" onClick={afficherCV}/>
                                                            : <></>

                                                      }

                                                      {
                                                            RIB ?
                                                            <input label="RIB" type="radio" id="other" name="gender" value="other" onClick={afficherRIB}/>
                                                            : <></>
                                                      }
                        
                  </div>
                                                      
                                                      


                              
                                                      
                                          </div>
                                          <div className={Styles.filesBottomSection}>
                                          
                                          {etatAfficherCIN ? <Pdf url={urlCIN} ext={extCIN}/> : <></>}

                                          {etatAfficherCarteVitale ? <Pdf url={urlCarteVitale} ext={extCarteVitale}/> : <></>}

                                          {etatAfficherTS ? <Pdf url={urlTS} ext={extTS}/> : <></>}{etatAfficherCV ? <Pdf url={urlCV} ext={extCV}/> : <></>}
                                    
                                          {etatAfficherRIB ? <Pdf url={urlRIB} ext={extRIB}/> : <></>}

                                          </div>

                                    {/* <table 
                                    >  
                                                            
                                                            <tr>
                                                            
                                                                  
                                                                  
                                                                        <tr>
                                                                        {CIN ? <button className={Styles.btnaffichage} id={btnAfficherCIN} onClick={afficherCIN}>CIN</button>:
                                                                  <span></span>}
                                    
                                                                        {carteVitale ? <button className={Styles.btnaffichage} id={btnAfficherCarteVitale}  onClick={afficherCarteVitale}>Carte vitale</button>:
                                                                                                      <span></span>}
                                    
                                                                        {TS ? <button className={Styles.btnaffichage} id={btnAfficherTS}  onClick={afficherTS}>Titre de séjour</button>:
                                                                  <span></span>}
                                                                        {CV ? <button className={Styles.btnaffichage} id={btnAfficherCV}  onClick={afficherCV}>CV</button>:
                                                                  <span></span>}
                                    
                                                                  {RIB ? <button className={Styles.btnaffichage} id={btnAfficherRIB}  onClick={afficherRIB}>RIB</button>:
                                                                  <span></span>}
                                                                  
                                    
                                                                        </tr>
                                                                        <tr>
                                    
                                                                        {etatAfficherCIN ? <Pdf url={urlCIN} ext={extCIN}/> : <span></span>}
                                                            
                                    
                                                                  
                                                                  
                                                                  
                                                                  {etatAfficherCarteVitale ? <Pdf url={urlCarteVitale} ext={extCarteVitale}/> : <span></span>}
                                    
                                                                  
                                                                  
                                                                  {etatAfficherTS ? <Pdf url={urlTS} ext={extTS}/> : <span></span>}
                                    
                                                                  
                                                                  
                                                                  {etatAfficherCV ? <Pdf url={urlCV} ext={extCV}/> : <span></span>}
                                    
                                                                  
                                                                  
                                                                  
                                                                  {etatAfficherRIB ? <Pdf url={urlRIB} ext={extRIB}/> : <span></span>}
                                                                        </tr>
                                                                  
                                                                  
                                                                  
                                                                  
                                    
                                                                  
                                                            </tr>
                                                            
                                                            
                                                            
                                                            
                                                            
                                                            
                                                            
                                                      
                                                            
                                                            
                                                            
                                                            
                        </table> */}

                                          
                                    </div>
                       
             </div>

                  <div>
                                    <button className={Styles.btnAnnuler} onClick={() => navigate('/embauche')} style={{cursor:"pointer"}}>
                                    <span>&nbsp;Annuler</span> 
                                    </button> 
                                    <button className={Styles.btnSubmit2} onClick={btnAffectation}>
                                          <span >&nbsp;&nbsp;&nbsp;Coordonnées</span> <span > <FontAwesomeIcon icon={faAngleRight} className={Styles.btnNext}/></span>
                                    </button>
                   </div>


</div>
                </div>: <div>
                  
                {etat === 'coordonnees'     ?    

            <div className={Styles.classCard}>

            <div className={Styles.formFichiersSection}>

            
                  <div className={Styles.containerBar}>

                        <Menu section = "coordonnees" setEtat = {setEtat} /> 

                  </div> 

                  <div className={Styles.captionStyle}>
                        <caption ><h3>Formulaire Coordonnées</h3></caption>
            </div>

            <div className={Styles.formContainer}
             id={
                  !CIN && !CV && !RIB && !carteVitale && !TS ? 
                        Styles.idTestGridWithoutFiles
                  : 
                        Styles.idTestGridWithFiles


      }
            >
            <div style={{backgroundColor:"transparent"}} className={Styles.leftSection} >
            <Input type = "text"  title = "Numéro" value = {coordonneesValeurs.NRue} name = "NRue" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } inputId = {inputNRue} valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} />
            {displayErrorInput(formErrorsCoordonnees.NRue)}
            <Input type = "text"  title = "Nom de la rue/Avenue"  value = {coordonneesValeurs.NomRue} name = "NomRue" inputId = {inputNomRue} classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} />

                  {displayErrorInput(formErrorsCoordonnees.NomRue)}
                  <Input type = "text"  title = "Chez" name = "chez" value = {coordonneesValeurs.chez} inputId = "inputChez" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} />

                  {displayErrorInput(formErrorsCoordonnees.chez)}
                  <Input type = "select" title = "Pays" inputId = {inputPays} name = "pays" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS  }  valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} listItem = { (listPays && listVille && listCodePostale && coordonneesValeurs.codePostale && coordonneesValeurs.codePostale !== "") ?listCodePostale.filter(code => code.id === parseInt(coordonneesValeurs.codePostale)).map( code => listVille.filter( ville => ville.id === code.id_ville ).map( ville => listPays.filter( pays => pays.id === ville.id_pays ).map( pays => <option name="pays" key={pays.id} value={pays.id} selected>{pays.nom} </option> ) ) ) : <> { (listPays && listVille && coordonneesValeurs.ville && coordonneesValeurs.ville !== "") ? listVille.filter( ville => ville.id === parseInt(coordonneesValeurs.ville) ).map( ville => listPays.filter(pays => pays.id === ville.id_pays ).map(    pays => <option value={pays.id} selected>{pays.nom}</option> ) ) : <> {  (listPays) && listPays.map( pays => <option value={pays.id}>{pays.nom}</option> ) } </> } </>} />

                  {displayErrorInput(formErrorsCoordonnees.pays)}
                  <Input type = "select" title = "Code postale" value = {coordonneesValeurs.codePostale} inputId = {inputCodePostale} name = "codePostale" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS  }  valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} listItem = {(listCodePostale && listVille && listPays && coordonneesValeurs.pays && coordonneesValeurs.pays !== "" && (!coordonneesValeurs.ville || coordonneesValeurs.ville === "")) ?listVille.filter(ville => ville.id_pays === parseInt(coordonneesValeurs.pays)).map( ville => listCodePostale.filter( code => code.id_ville === parseInt(ville.id) ).map( resp => <option value={resp.id}>{resp.nom}</option> ) ): <> { (listCodePostale && listVille && listPays && coordonneesValeurs.ville && coordonneesValeurs.ville !== "") ? listCodePostale.filter( code => code.id_ville === parseInt(coordonneesValeurs.ville)).map( resp => <option value={resp.id}>{resp.nom}</option> ): <> {   listCodePostale  && listCodePostale.map(resp => <option value={resp.id}>{resp.nom}</option> )} </> } </> } />

                  {/* <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CIN && !CV && !RIB && !carteVitale && !TS ? 
                        Styles.zoneInputInput
                        : 
                        Styles.zoneInputInputWithFiles }>                              <span className={Styles.fileNameTxtStyleInput}>
                              Code postale
                              </span>
                              <select className={Styles.goldenInput1} id={inputCodePostale} name='codePostale' value={coordonneesValeurs.codePostale} onChange={handleChangeCoordonnees}>
                                    <option value=""></option>
                                    {
                                          (listCodePostale && listVille && listPays && coordonneesValeurs.pays && coordonneesValeurs.pays !== "" && (!coordonneesValeurs.ville || coordonneesValeurs.ville === "")) ?
                                          listVille.filter(
                                                ville => ville.id_pays === parseInt(coordonneesValeurs.pays)
                                          ).map(
                                                ville => listCodePostale.filter(
                                                            code => code.id_ville === parseInt(ville.id)
                                                      )
                                                      .map(
                                                            resp => 
                                                            <option value={resp.id}>{resp.nom}</option>
                                                      )
                                          )
                                          
                                          :
                                          <>
                                          {
                                                (listCodePostale && listVille && listPays && coordonneesValeurs.ville && coordonneesValeurs.ville !== "") ?
                                                listCodePostale.filter(
                                                                  code => code.id_ville === parseInt(coordonneesValeurs.ville)
                                                            )
                                                            .map(
                                                                  resp => 
                                                                  <option value={resp.id}>{resp.nom}</option>
                                                            )
                                                :
                                                <>

                                             {   listCodePostale  &&
                                                listCodePostale.map(
                                                                  resp => 
                                                                  <option value={resp.id}>{resp.nom}</option>
                                                            )
                                                }
                                                
                                                </>

                                                
                                          }
                                          </>
                                          
                                    }
                              </select>
                        </div>
                  </div>  */}
                  {displayErrorInput(formErrorsCoordonnees.codePostale)}
                  <Input type = "select" title = "Ville" inputId = {inputVille} value = {coordonneesValeurs.ville} name = "ville" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS  }  valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} listItem = 
                  {
                        (listVille && listCodePostale && coordonneesValeurs.codePostale && coordonneesValeurs.codePostale !== "") ?
                        
                        listCodePostale.filter(resp => resp.id === parseInt(coordonneesValeurs.codePostale))
                        .map(
                              resp => listVille.filter(
                                    ville => ville.id === parseInt(resp.id_ville)
                              ).map(
                                    ville => <option value={ville.id} selected>{ville.nom}</option>
                              )
                        ) :
                        
                              <>
                              {
                                    (listVille && listCodePostale && coordonneesValeurs.pays && coordonneesValeurs.pays !== "") ?
                        
                                    listVille.filter(
                                                ville => ville.id_pays === parseInt(coordonneesValeurs.pays)
                                          ).map(
                                                ville => <option value={ville.id}>{ville.nom}</option>
                                          )
                                     :
                                    <>
                                    {
                                          listVille &&
                        
                                          listVille.map(
                                                      ville => <option value={ville.id}>{ville.nom}</option>
                                                )
                                    }
                                    </>
                              }
                              </>
                              
                        

                  }
                          />

                  {/* <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CIN && !CV && !RIB && !carteVitale && !TS ? 
                        Styles.zoneInputInput
                        : 
                        Styles.zoneInputInputWithFiles }>
                        <span className={Styles.fileNameTxtStyleInput}>
                              Ville
                              </span>
                              <select className={Styles.goldenInput1} id={inputVille}
                                                      name="ville" value={coordonneesValeurs.ville}
                                                      onChange={handleChangeCoordonnees}>
                                                                  <option></option>
                                                                  {
                                                                        (listVille && listCodePostale && coordonneesValeurs.codePostale && coordonneesValeurs.codePostale !== "") ?
                                                                        
                                                                        listCodePostale.filter(resp => resp.id === parseInt(coordonneesValeurs.codePostale))
                                                                        .map(
                                                                              resp => listVille.filter(
                                                                                    ville => ville.id === parseInt(resp.id_ville)
                                                                              ).map(
                                                                                    ville => <option value={ville.id} selected>{ville.nom}</option>
                                                                              )
                                                                        ) :
                                                                        
                                                                              <>
                                                                              {
                                                                                    (listVille && listCodePostale && coordonneesValeurs.pays && coordonneesValeurs.pays !== "") ?
                                                                        
                                                                                    listVille.filter(
                                                                                                ville => ville.id_pays === parseInt(coordonneesValeurs.pays)
                                                                                          ).map(
                                                                                                ville => <option value={ville.id}>{ville.nom}</option>
                                                                                          )
                                                                                     :
                                                                                    <>
                                                                                    {
                                                                                          listVille &&
                                                                        
                                                                                          listVille.map(
                                                                                                      ville => <option value={ville.id}>{ville.nom}</option>
                                                                                                )
                                                                                    }
                                                                                    </>
                                                                              }
                                                                              </>
                                                                              
                                                                        

                                                                  }
                              </select> 


                        
                        
                        </div>
                  </div> */}

                  {displayErrorInput(formErrorsCoordonnees.ville)}
                  <Input type = "text"  title = "Téléphone fixe"  value = {coordonneesValeurs.telephoneFixe} name = "telephoneFixe" inputId = "inputTelephoneFixe" classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} />

                  {/* <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CIN && !CV && !RIB && !carteVitale && !TS ? 
                        Styles.zoneInputInput
                        : 
                        Styles.zoneInputInputWithFiles }>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Téléphone fixe
                              </span>
                              <input type="text" className={Styles.goldenInput1} id="inputTelephoneFixe" name='telephoneFixe' value={coordonneesValeurs.telephoneFixe} onChange={handleChangeCoordonnees}></input>
                        </div>
                  </div>  */}

                  
                 
                  {displayErrorInput(formErrorsCoordonnees.telephoneFixe)}

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CIN && !CV && !RIB && !carteVitale && !TS ? 
                        Styles.zoneInputInput
                        : 
                        Styles.zoneInputInputWithFiles }>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Téléphone mobile
                              </span>

                              <div className={Styles.goldenInput1Phone} id={inputTelephoneMobile}>
                                                      < >
                                                      
                                                      {coordonneesValeurs.pays === 'FRANCE'  ?

                                                      < >
                                                      
                                                            <PhoneInput
                                                                  // className={Styles.goldenInput1}
                                                                  country={'fr'}
                                                                  // inputClass={Styles.goldenInput1}               
                                                                  inputProps={{
                                                                  name: 'telephoneMobile',}}
                                                                  value={coordonneesValeurs.telephoneMobile}
                                                                  onChange={handlePhoneChange}
                                                                  
                                                            />
                                                      </>: <>
                                                      {coordonneesValeurs.pays === 'MAROC'  ?
                                                      < >
                                                            <PhoneInput
                                                                  country={'ma'}
                                                                  regions={'africa'}
                                                                  // inputClass={Styles.goldenInput1}               
                                                                  inputProps={{
                                                                  name: 'telephoneMobile',}}
                                                                  value={coordonneesValeurs.telephoneMobile}
                                                                  onChange={handlePhoneChange}
                                                            />
                                                      </>: <>
                                                      <PhoneInput

                                                                  inputStyle={{color:'black', 
                                                                  width:'100%',
                                                                  border: 'none',
                                                                  boxShadow: 'none',
                                                                  borderRadius: '8px'
                                                            }}
                                                                  // containerStyle={{
                                                                  //        padding:"0px",
                                                                  //         }}
                                                                  // buttonStyle={{}}
                                                                  // dropdownStyle={{height:'50px'}}
                                                                  
                                                                  country={''}   
                                                                  // inputClass={Styles.goldenInput1}               
                                                                  inputProps={{
                                                                        
                                                                  name: 'telephoneMobile',}}
                                                                  value={coordonneesValeurs.telephoneMobile}
                                                                  onChange={handlePhoneChange}
                                                                  // inputStyle={{
                                                                  //       height: "25px",
                                                                  //       width: "150px",
                                                                  //       border:"none",
                                                                  //     }}
                                                                  // inputStyle = {Styles.classPhoneInput}
                                                                  // containerClass = {Styles.classPhoneInput}
                                                            /></>}</>}
                                                            
                                                      </>
                              </div>
                        </div>
                  </div> 


                  {displayErrorInput(formErrorsCoordonnees.telephoneMobile)}
                  <Input type = "email"  title = "E-Mail"  value = {coordonneesValeurs.email} name = "email" inputId = {inputEmail} classZoneInputEtat = { !CIN && !CV && !RIB && !carteVitale && !TS } valeurs = {coordonneesValeurs} handleChange = {handleChangeCoordonnees} />

                  {/* <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CIN && !CV && !RIB && !carteVitale && !TS ? 
                        Styles.zoneInputInput
                        : 
                        Styles.zoneInputInputWithFiles }>
                              <span className={Styles.fileNameTxtStyleInput}>
                              E-Mail
                              </span>
                              <input type="email" className={Styles.goldenInput1} id={inputEmail} name='email' value={coordonneesValeurs.email} onChange={handleChangeCoordonnees} ></input>
                        </div>
                  </div>  */}

                  
                 
                  {displayErrorInput(formErrorsCoordonnees.email)}


                  </div>


                  



                  <div className={Styles.rightSection} style={!CIN && !CV && !RIB && !carteVitale && !TS ? 
                                                {display:"none"}
                                                : 
                                                {display:"grid"}}>


                                          <div className={Styles.filesTopSection}>
                                                      {/* <span onClick={afficherCIN}>CIN</span> */}
                                                      <div className={Styles.radio}>
                                                            {
                                                                  carteVitale ? 
                                                                  <input label="Carte" type="radio" id="male" name="gender" value="male" onClick={afficherCarteVitale} />
                                                                  : <></>
                                                            }
                                                      {
                                                            CIN ? 
                                                      <input label="CIN" type="radio" id="male" name="gender" value="male"  onClick={afficherCIN} />
                                                            :<>
                                                            { TS ?
                                                                  <input label="TS" type="radio" id="femalee" name="gender" value="femalee"  onClick={afficherTS}/> 
                                                                  : 
                                                                  <></>
                                                            }   
                                                            </>
                                                      }

                                                      {
                                                            CV ? 

                        <input label="CV" type="radio" id="female" name="gender" value="female" onClick={afficherCV}/>
                                                            : <></>

                                                      }

                                                      {
                                                            RIB ?
                                                            <input label="RIB" type="radio" id="other" name="gender" value="other" onClick={afficherRIB}/>
                                                            : <></>
                                                      }
                        
                  </div>
                                                      
                                                      


                              
                                                      
                                          </div>
                                          <div className={Styles.filesBottomSection}>
                                          
                                          {etatAfficherCIN ? <Pdf url={urlCIN} ext={extCIN}/> : <></>}

                                          {etatAfficherCarteVitale ? <Pdf url={urlCarteVitale} ext={extCarteVitale}/> : <></>}

                                          {etatAfficherTS ? <Pdf url={urlTS} ext={extTS}/> : <></>}{etatAfficherCV ? <Pdf url={urlCV} ext={extCV}/> : <></>}
                                    
                                          {etatAfficherRIB ? <Pdf url={urlRIB} ext={extRIB}/> : <></>}

                                          </div>

                                            
                                    </div>

                  </div>

            
      
      
            {/* <caption ><h2 class="card text-center" align='right'>Formulaire Coordonnées</h2></caption> */}

            

      
      

      <div>
                        <button className={Styles.btnAnnuler} onClick={() => navigate('/embauche')} style={{cursor:"pointer"}}>
                        <span>&nbsp;Annuler</span> 
                        </button> 
                        <button className={Styles.btnSubmit2} onClick={btnCoordonnes}>
                              <span >&nbsp;&nbsp;&nbsp;Compétences</span> <span > <FontAwesomeIcon icon={faAngleRight} className={Styles.btnNext}/></span>
                        </button>
            </div>



</div>
                </div>: <div>


                {etat === 'competences'     ?    


            <div className={Styles.classCard}>

            <div className={Styles.formFichiersSection}>


                  <div className={Styles.containerBar}>

                        <Menu section = "competences" setEtat = {setEtat} /> 

                  </div> 

                  <div className={Styles.captionStyle}>
                        <caption ><h3>Formulaire Compétences</h3></caption>
                  </div>
                  <div className={Styles.formContainer}
                  id={
                         !CV  ? 
                              Styles.idTestGridWithoutFiles
                        : 
                              Styles.idTestGridWithFiles
      
      
            }
                  >

        <div style={{backgroundColor:"transparent"}} className={Styles.leftSection} >
                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                       !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        Formation :
                        <br />
                        </div>
                  </div>
                
                        
                  <div className={Styles.container1} id={Styles.container4}>
                        
                        <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id={inputAnneeFormation} name='anneeFormation1' value={competencesValeurs.anneeFormation1} onChange={handleChangeCompetences} />
                        <input type="text" className={Styles.goldenInput1} id={inputIntituleDiplome} placeholder='Intitulé du diplôme' name='formation1' value={competencesValeurs.formation1} onChange={handleChangeCompetences} /> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterFormation1}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>

                  {displayErrorInput(formErrorsCompetences.formation1)}

                  {etatAjouterFormation1 ? <>

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id='inputAnneeFormation1' name='anneeFormation2' value={competencesValeurs.anneeFormation2} onChange={handleChangeCompetences}/>
                        <input type="text" className={Styles.goldenInput1} id="inputIntituleDiplome1" placeholder='Intitulé du diplôme' name='formation2' value={competencesValeurs.formation2} onChange={handleChangeCompetences}/> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterFormation2}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>
                                                            
                 
                                                            
                                                      </> : <></>

                  }
                  {displayErrorInput("")}


                  {etatAjouterFormation2 ? <>

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id='inputAnneeFormation3' name='anneeFormation3' value={competencesValeurs.anneeFormation3} onChange={handleChangeCompetences}/>
                        <input type="text" className={Styles.goldenInput1} id="inputIntituleDiplome2" placeholder='Intitulé du diplôme' name='formation3' value={competencesValeurs.formation3} onChange={handleChangeCompetences}/> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterFormation3}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>
                                                            

                                                            
                                                      </> : <></>

                  }
                  {displayErrorInput("")}

                  {etatAjouterFormation3 ? <>

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                       !CV  ? 
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id='inputAnneeFormation4' name='anneeFormation4' value={competencesValeurs.anneeFormation4} onChange={handleChangeCompetences}/>
                        <input type="text" className={Styles.goldenInput1} id="inputIntituleDiplome4" placeholder='Intitulé du diplôme' name='formation4' value={competencesValeurs.formation4} onChange={handleChangeCompetences}/> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterFormation3} style={{display:"none"}}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>
                                                            

                                                            
                                                      </> : <></>

                  }

            {displayErrorInput("")}




            <div className={Styles.container1} id={Styles.container4}>
            <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        Expériences :
                        <br />
                        </div>
                  </div>
                
                        
                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id={inputAnneeExperience} name='anneeExperience1' value={competencesValeurs.anneeExperience1} onChange={handleChangeCompetences} />
                        <input type="text" className={Styles.goldenInput1} id={inputExperience} name='experience1' value={competencesValeurs.experience1} onChange={handleChangeCompetences} /> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterExperience1}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>

                  {displayErrorInput(formErrorsCompetences.experience1)}


                  {etatAjouterExperience1 ? <>

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id='inputAnneeExperience1' name='anneeExperience2' value={competencesValeurs.anneeExperience2} onChange={handleChangeCompetences}/>
                        <input type="text" className={Styles.goldenInput1} id="inputExperience1"  name='experience2' value={competencesValeurs.experience2} onChange={handleChangeCompetences}/> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterExperience2}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>
                                                            

                                                            
                                                      </> : <></>

                  }
                  {displayErrorInput("")}


                  {etatAjouterExperience2 ? <>

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id='inputAnneeExperience2' name='anneeExperience3' value={competencesValeurs.anneeExperience3} onChange={handleChangeCompetences}/>
                        <input type="text" className={Styles.goldenInput1} id="inputExperience2"  name='experience3' value={competencesValeurs.experience3} onChange={handleChangeCompetences}/> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterExperience3}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>
                                                            

                                                            
                                                      </> : <></>

                  }
                  {displayErrorInput("")}

                  {etatAjouterExperience3 ? <>

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <input type="month" className={Styles.fileNameTxtStyle7} id='inputAnneeExperience3' name='anneeExperience4' value={competencesValeurs.anneeExperience4} onChange={handleChangeCompetences}/>
                        <input type="text" className={Styles.goldenInput1} id="inputExperience3"  name='experience4' value={competencesValeurs.experience4} onChange={handleChangeCompetences}/> 
                        <button className={Styles.btnAddStyle} onClick={btnAjouterExperience3} style={{display:"none"}}>
                              <FontAwesomeIcon icon={faPlus} />
                        </button>
                        </div>
                  </div>
                                                            

                                                            
                                                      </> : <></>

                  }

                  {displayErrorInput("")}



                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        Langues :
                        <br />
                        </div>
                  </div>
                 

                  <div className={Styles.container1} id={Styles.container4}>
                  <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <select className={Styles.fileNameTxtStyle7} id={inputLangue1} name='langue1' value={competencesValeurs.langue1} onChange={handleChangeCompetences} >
                              <option value=""></option>
                              {listLangue &&
                              listLangue.map(
                                    langue => <option value={langue.id}>{langue.nom}</option>
                              )
                              }
                        </select>
                        <select  className={Styles.goldenInput1} id={inputNiveauLangue1}  name='niveauLangue1' value={competencesValeurs.niveauLangue1} onChange={handleChangeCompetences} >
                              <option value=""></option>
                              {listNiveauLanguesAdministration &&
                              listNiveauLanguesAdministration.map(
                                    resp => <option value={resp.id}>{resp.niveau}</option>
                              )
                              }
                        </select>

                        <button className={Styles.btnAddStyle} onClick={btnAjouterLangue1}>
                              <FontAwesomeIcon icon={faPlus}  />
                        </button>
                        </div>
                  </div>

                  {displayErrorInput(formErrorsCompetences.langue1)}


                  {etatAjouterLangue1 ? 


                  <div className={Styles.container1} id={Styles.container4}>
                         <div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
                        <select className={Styles.fileNameTxtStyle7}  name='langue2' value={competencesValeurs.langue2} onChange={handleChangeCompetences} >
                              <option value=""></option>
                              {listLangue &&
                              listLangue.filter(
                                    langue => ![parseInt(competencesValeurs.langue1)].includes(langue.id)
                              ).map( 
                                    langue => <option value={langue.id}>{langue.nom}</option>
                              )
                              }
                        </select>
                        <select  className={Styles.goldenInput1} name='niveauLangue2' value={competencesValeurs.niveauLangue2} onChange={handleChangeCompetences} >
                              <option value=""></option>
                              {listNiveauLanguesAdministration &&
                              listNiveauLanguesAdministration.map(
                                    resp => <option value={resp.id}>{resp.niveau}</option>
                              )
                              }
                        </select>
                        <button className={Styles.btnAddStyle} onClick={btnAjouterLangue2}>
                              <FontAwesomeIcon icon={faPlus}  />
                        </button></div></div> : <></> }
{displayErrorInput("")}
{etatAjouterLangue2 ? 
<div className={Styles.container1} id={Styles.container4}>
<div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
      <select className={Styles.fileNameTxtStyle7}  name='langue3' value={competencesValeurs.langue3} onChange={handleChangeCompetences} >
            <option value=""></option>
            {listLangue &&
                              listLangue.filter(
                                    langue => ![parseInt(competencesValeurs.langue1), parseInt(competencesValeurs.langue2)].includes(langue.id)
                              ).map(
                                    langue => <option value={langue.id}>{langue.nom}</option>
                              )
                              }
      </select>
      <select  className={Styles.goldenInput1} name='niveauLangue3' value={competencesValeurs.niveauLangue3} onChange={handleChangeCompetences} >
            <option value=""></option>
            {listNiveauLanguesAdministration &&
                              listNiveauLanguesAdministration.map(
                                    resp => <option value={resp.id}>{resp.niveau}</option>
                              )
                              }
      </select>

      <button className={Styles.btnAddStyle} onClick={btnAjouterLangue3}>
            <FontAwesomeIcon icon={faPlus}  />
      </button>
      </div>
</div> : <></>

                                    }
                  {displayErrorInput("")}


{etatAjouterLangue3 ? 


<div className={Styles.container1} id={Styles.container4}>
<div className={
                        !CV  ?  
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>
      <select className={Styles.fileNameTxtStyle7}  name='langue4' value={competencesValeurs.langue4} onChange={handleChangeCompetences} >
            <option value=""></option>
            {listLangue &&
                              listLangue.filter(
                                    langue => ![parseInt(competencesValeurs.langue1), parseInt(competencesValeurs.langue2), parseInt(competencesValeurs.langue3)].includes(langue.id)
                              ).map(
                                    langue => <option value={langue.id}>{langue.nom}</option>
                              )
                              }
      </select>
      <select  className={Styles.goldenInput1} name='niveauLangue4' value={competencesValeurs.niveauLangue4} onChange={handleChangeCompetences} >
            <option value=""></option>
             {listNiveauLanguesAdministration &&
                              listNiveauLanguesAdministration.map(
                                    resp => <option value={resp.id}>{resp.niveau}</option>
                              )
                              }
      </select>

      <button className={Styles.btnAddStyle} style = {{display:"none"}}>
            <FontAwesomeIcon icon={faPlus}  />
      </button>
      </div>
</div> : <></> }
<div className={Styles.container1} id={Styles.container4}>
<div className={
                        !CV  ? 
                        Styles.zoneInputCompetences
                        : 
                        Styles.zoneInputCompetencesWithFiles }>  
                          Compétences :
                        <br />
                        </div>
                  </div>
                  <div className={Styles.container1} id={Styles.container4}>
                        
                        <div className={
                        !CV  ? 
                        Styles.textAreaContainer
                        : 
                        Styles.textAreaContainerWithFiles }>
                        <textarea className={Styles.goldenTextarea}   name='competence' value={competencesValeurs.competence} onChange={handleChangeCompetences}/>
                        </div>
                        </div>

</div>
                        {/* old form competences  */}

                        <div className={Styles.rightSection} style={!CV   ? 
                                                {display:"none"}
                                                : 
                                                {display:"grid"}}>


                                          <div className={Styles.filesTopSection}>
                                                      {/* <span onClick={afficherCIN}>CIN</span> */}
                                                      <div className={Styles.radio}>
                                                            {
                                                                  carteVitale ? 
                                                                  <input style={{display:"none"}} label="Carte" type="radio" id="male" name="gender" value="male" onClick={afficherCarteVitale} />
                                                                  : <></>
                                                            }
                                                      {
                                                            CIN ? 
                                                      <input  style={{display:"none"}} label="CIN" type="radio" id="male" name="gender" value="male"  onClick={afficherCIN} />
                                                            :<>
                                                            { TS ?
                                                                  <input  style={{display:"none"}} label="TS" type="radio" id="femalee" name="gender" value="femalee"  onClick={afficherTS}/> 
                                                                  : 
                                                                  <></>
                                                            }   
                                                            </>
                                                      }

                                                      {
                                                            CV ? 

                        <input label="CV" type="radio" id="female" name="gender" value="female" onClick={afficherCV}/>
                                                            : <></>

                                                      }

                                                      {
                                                            RIB ?
                                                            <input  style={{display:"none"}} label="RIB" type="radio" id="other" name="gender" value="other" onClick={afficherRIB}/>
                                                            : <></>
                                                      }
                        
                  </div> </div>
                                          <div className={Styles.filesBottomSection}>
                                          
                                          {etatAfficherCIN ? <Pdf url={urlCIN} ext={extCIN}/> : <></>}

                                          {etatAfficherCarteVitale ? <Pdf url={urlCarteVitale} ext={extCarteVitale}/> : <></>}

                                          {etatAfficherTS ? <Pdf url={urlTS} ext={extTS}/> : <></>}{etatAfficherCV ? <Pdf url={urlCV} ext={extCV}/> : <></>}
                                    
                                          {etatAfficherRIB ? <Pdf url={urlRIB} ext={extRIB}/> : <></>}

                                          </div>
      </div>
                        </div>
            <div>
                                    <button className={Styles.btnAnnuler} onClick={() => navigate('/embauche')} style={{cursor:"pointer"}}>
                                    <span>&nbsp;Annuler</span> 
                                    </button> 
                                    <button className={Styles.btnSubmit2} onClick={btnCompetences}>
                                          <span >&nbsp;&nbsp;&nbsp;Bancaire</span> <span > <FontAwesomeIcon icon={faAngleRight} className={Styles.btnNext}/></span>
                                    </button>
                        </div>
</div>
                </div>: <div>
                  

                {etat === 'bancaire'     ?   
                 <div className={Styles.classCard}>

                 <div className={Styles.formFichiersSection}>
     
     
                       <div className={Styles.containerBar}>
     
                             <Menu section = "bancaire" setEtat = {setEtat} /> 
     
                       </div> 
     
                       <div className={Styles.captionStyle}>
                             <caption ><h3>Formulaire Compétences</h3></caption>
                       </div>
                       <div className={Styles.formContainer}
                       id={
                        !RIB  ? 
                              Styles.idTestGridWithoutFiles
                        : 
                              Styles.idTestGridWithFiles
      
      
            }
                       >

                       
<div style={{backgroundColor:"transparent"}} className={Styles.leftSection} >
                  
                       <Input type = "select" title = "Banque" value = {idBancaireValeurs.banque} inputId = {inputBanque} name = "banque" classZoneInputEtat = {!RIB }  valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} listItem = { listBanque && listBanque.map( resp =>  <option value={resp.id}>{resp.nom}</option> )}/>
                        {displayErrorInput(formErrorsIdBancaire.banque)}
                        <Input type = "text"  title = "Domiciliation" value = {idBancaireValeurs.domiciliation} name = "domiciliation" inputId = "inputDomiciliation" classZoneInputEtat = { !RIB } valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} />

                        {displayErrorInput(formErrorsIdBancaire.domiciliation)}
                        <Input type = "text"  title = "N° de compte" name = "numeroCompte"  value = {idBancaireValeurs.numeroCompte}inputId = {inputNumeroCompte} classZoneInputEtat = { !RIB } valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} />
                       {displayErrorInput(formErrorsIdBancaire.numeroCompte)}
                       <Input type = "text"  title = "Code Banque" name = "codeBanque" value = {idBancaireValeurs.codeBanque} inputId = {inputCodeBanque} classZoneInputEtat = { !RIB } valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} />

                        {displayErrorInput(formErrorsIdBancaire.codeBanque)}
                        <Input type = "text"  title = "Code Guichet" name = "codeGuichet" value = {idBancaireValeurs.codeGuichet} inputId = {inputCodeGuichet} classZoneInputEtat = { !RIB } valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} />

                        {displayErrorInput(formErrorsIdBancaire.codeGuichet)}
                        <Input type = "text"  title = "Clé RIB" name = "cleRib" value = {idBancaireValeurs.cleRib} inputId = {inputCleRIB} classZoneInputEtat = { !RIB } valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} />

                        {displayErrorInput(formErrorsIdBancaire.cleRib)}

                        <Input type = "text"  title = "IBAN" name = "iban" value = {idBancaireValeurs.iban} inputId = {inputIBAN} classZoneInputEtat = { !RIB } valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} />

                        {displayErrorInput(formErrorsIdBancaire.iban)}
                        <Input type = "text"  title = "BIC" name = "bic" value = {idBancaireValeurs.bic} inputId = {inputBIC} classZoneInputEtat = { !RIB } valeurs = {idBancaireValeurs} handleChange = {handleChangeIdBancaire} />
                        {displayErrorInput(formErrorsIdBancaire.bic)}
                        </div>
                        {/* old form bancaire  */}
                        <div className={Styles.rightSection} style={ !RIB  ? 
                                                {display:"none"}
                                                : 
                                                {display:"grid"}}>


                                          <div className={Styles.filesTopSection}>
                                                      <div className={Styles.radio}>
                                                            {
                                                                  carteVitale ? 
                                                                  <input style={{display:"none"}} label="Carte" type="radio" id="male" name="gender" value="male" onClick={afficherCarteVitale} />
                                                                  : <></>
                                                            }
                                                      {
                                                            CIN ? 
                                                      <input style={{display:"none"}} label="CIN" type="radio" id="male" name="gender" value="male"  onClick={afficherCIN} />
                                                            :<>
                                                            { TS ?
                                                                  <input style={{display:"none"}} label="TS" type="radio" id="femalee" name="gender" value="femalee"  onClick={afficherTS}/> 
                                                                  : 
                                                                  <></>
                                                            }   
                                                            </>
                                                      }

                                                      {
                                                            CV ? 

                        <input style={{display:"none"}} label="CV" type="radio" id="female" name="gender" value="female" onClick={afficherCV}/>
                                                            : <></>
                                                      }
                                                      {
                                                            RIB ?
                                                            <input label="RIB" type="radio" id="other" name="gender" value="other" onClick={afficherRIB}/>
                                                            : <></>
                                                      }
                        
                  </div></div>
                                          <div className={Styles.filesBottomSection}>
                                          
                                          {etatAfficherCIN ? <Pdf url={urlCIN} ext={extCIN}/> : <></>}

                                          {etatAfficherCarteVitale ? <Pdf url={urlCarteVitale} ext={extCarteVitale}/> : <></>}

                                          {etatAfficherTS ? <Pdf url={urlTS} ext={extTS}/> : <></>}{etatAfficherCV ? <Pdf url={urlCV} ext={extCV}/> : <></>}
                                    
                                          {etatAfficherRIB ? <Pdf url={urlRIB} ext={extRIB}/> : <></>}

                                          </div>
                                    </div>
                        </div>
      <div>
                                    <button className={Styles.btnAnnuler} onClick={() => navigate('/embauche')} style={{cursor:"pointer"}}>
                                    <span>&nbsp;Annuler</span> 
                                    </button> 
                                    <button className={Styles.btnSubmit2} onClick={btnBancaire}>
                                          <span >&nbsp;&nbsp;&nbsp;Validation</span> <span > <FontAwesomeIcon icon={faAngleRight} className={Styles.btnNext}/></span>
                                    </button>
                        </div>

</div>
                </div>:<div>

                {etat === 'validation'     ?    <div className={Styles.classCard}>

      <div className={Styles.formValidationSection}>
      <div className={Styles.containerBar}>

            <Menu section = "validation" setEtat = {setEtat} /> 

      </div> 
      <div className={Styles.formContainer}>
            <div className={Styles.validationSections}>
                  <span className={Styles.validationTopSection}>
                  <div className={Styles.containerText}> 
                        <p className={Styles.tesxtStyle}> 
                        Souhaitez-vous valider l’embauche de {donneesSalariesValeurs.sexe === "Homme" ? <span> Monsieur.</span>:<span>{
                        donneesSalariesValeurs.sexe === "Femme" ? <span>Madame.</span>:<span></span>
                        }</span>} <span id={Styles.nomvalidation}>{donneesSalariesValeurs.prenom} {donneesSalariesValeurs.nom} </span> 
                        Pour un {affectationValeurs.typeContrat} du {affectationValeurs.dateDebut}
                              <br />
                              <div className={Styles.containerValidation1} id={Styles.containerValidation3}>
                                    <div className={Styles.zoneInputInputValidation}>
                                    <span className={Styles.fileNameTxtStyleInputValidation}>
                                    Heure de prise de poste 
                                    </span>
                                    <span ><input type="time" className={Styles.goldenInputValidation} id={inputHeurePrisePoste} name='heurePrisePoste' value={validationValeurs.heurePrisePoste} onChange={handleChangeValidation}></input></span>
                                    </div>
                              </div>
                        </p>
                  </div>
                  </span>
                  <span  className={Styles.validationBottomSection}>



                  {etatValidationCarteVitale === false ? 

                  <Alert type = "danger" topMsg = "La carte vitale n’est pas ajoutée, est-ce que vous voulez l’embaucher et compléter ultérieurement ?" bottomMsg = "" btn = {btnCarteVitaleOui}/>



:
<div>
      {etatValidationCIN === false ? 

<Alert type = "danger" topMsg = "La carte nationale d'identité n’est pas ajoutée, est-ce que vous voulez l’embaucher et compléter ultérieurement ?" bottomMsg = "" btn = {btnCINOui}/>

:
<div>
{etatValidationRIB === false ? 

<Alert type = "danger" topMsg = "Relevé d’Identité Bancaire n’est pas ajoutée, est-ce que vous voulez l’embaucher et compléter ultérieurement ?" bottomMsg = "" btn = {btnRIBOui}/>

:
<div>
{etatValidationTS === false ? 

<Alert type = "danger" topMsg = "Titre de séjour n’est pas ajoutée, est-ce que vous voulez l’embaucher et compléter ultérieurement ?" bottomMsg = "" btn = {btnTSOui}/>

:
<div>
{etatValidationCV === false ? 
<Alert type = "danger" topMsg = "CV n’est pas ajoutée, est-ce que vous voulez l’embaucher et compléter ultérieurement ?" bottomMsg = "" btn = {btnCVOui}/>
: <div></div>} </div>} </div>} </div>} </div>}
{
etatValidation === 1 ? 
<>
<Alert type = "success" topMsg = "Salarié embaucher" bottomMsg = "" btn = {""}/>
</>
:
<div>
      {  etatValidation === 2 ? 
          <>
<Alert type = "success" topMsg = "Salarié réembaucher" bottomMsg = "" btn = {""}/>
          </>
          :
            <div>{
            etatValidation === 3 ? 
            <>

<Alert type = "success" topMsg = "Embauche mise en attente" bottomMsg = "" btn = {""}/>
            
            </>
          
          :
            <div></div>
      }
            </div>
      }
</div>
}
 </span>
            </div>  </div>
      <div>
                                    <button className={Styles.btnAnnuler} onClick={() => navigate('/embauche')} style={{cursor:"pointer"}}>
                                    <span>&nbsp;Annuler</span> 
                                    </button> 
                                    <button className={Styles.btnSubmit2} onClick={btnEmbaucher}>
                                          <span >&nbsp;&nbsp;&nbsp;Embaucher</span> <span ></span>
                                    </button>
                        </div>
</div>
                </div>: <div>  {etat === 'competences'     ?    <div id='main'  class='vl'>
            <div >
                  <hr></hr>
                  <caption ><h2 class="card text-center" align='right'>Formulaire Compétences</h2></caption>
     <div class='classTest'>
      <button className='btn' onClick={btnCompetences}> ID. Bancaire </button>
      </div>
</div>
</div>: <div> </div>} </div>} </div>} </div>}
                  </div>}
                  </div>}
</div>
}
      </div>}

{/* FIN FORMULAIRE  */}
</div>
</div>
                                  

  )}
}

export default SaisieSection