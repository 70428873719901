import React from 'react';
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../APIService';
import StylesEM from './EmbaucheSection.module.css';
import EmbaucheNavBar from './../EmbaucheGlobal/EmbaucheNavBar.jsx';
import {faExclamation, faWarning, faXmark} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free/css/all.min.css';
import {faCheck} from '@fortawesome/free-solid-svg-icons';


function EmbaucheSection() {

  var currentDate = new Date().toJSON().slice(0,10);
  const [nomPrenom, setNomPrenom] = useState();
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [numeroSS, setNumeroSS] = useState('')
  const [statut, setStatut] = useState('')
  const [employe, setEmploye] = useState({})
  const [alertReembauche, setAlertReembauche] = useState(false)
  const [employes, setEmployes] = useState([])
  const [employesTemporaires, setEmployesTemporaires] = useState([])
  const [employeRechercher, setEmployerRechercher] = useState([])
  const [employesTemporairesRechercher, setEmployesTemporairesRechercher] = useState([])
  const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente'])
  const [etat, setEtat] = useState(false)
  const [etatVide, setEtatVide] = useState(false)
  const [motif, setMotif] = useState({})
  const [etatMessageEmployeTemporaire, setEtatMessageEmployeTemporaire] = useState(false)
  const [users, setUsers] = useState([])
  const [afficher, SetAfficher] = useState(false)
  const [matriculeTempError, setMatriculeTempError] = useState();
  const [matriculeSalaria,setMatriculeSalaria]=useState();
  const [matriculeBlockage,setMatriculeBlockage]=useState();
  const [listStatutAdministration, setListStatutAdministration] = useState(null)

  const inputStyle = {
    flex: 1,
    margin: '5px',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };


  useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          // navigate('/')
          window.location.href = '/'
          localStorage.clear()
  
      }
    }, [token])
    
    

    useEffect(() => {

      if(token['mytoken']){
          APIService.getStatutAdministration(token)
          .then(resp => setListStatutAdministration(resp))
      }
    
  }, [token['mytoken']])
    useEffect(() => {
        APIService.getUsers(token['mytoken'])
      .then(resp => setUsers(resp))
    },token['mytoken'])


    useEffect(() => {
        if(users.length){
          SetAfficher(true)
        }
          
        
    }, [users])
  
  const btnEmployeTemporaire = () => {
    if(etatMessageEmployeTemporaire === false){
      setEtatMessageEmployeTemporaire(true)
      setAlertReembauche(false)
      setEtatReaction(false)
      setMatriculeSalaria(false)
      setMatriculeBlockage(false)
    }
    
  }


useEffect(() => {

  if(employes.length != 0){
    setEtat(true)
    setEmployerRechercher(
      APIService.getEmployeRechercher(employes, nom, prenom, numeroSS)
    )
    
  }


}, [employes])

useEffect(() => {

  if(employesTemporaires.length != 0){
    setEtat(true)
    setEtatMessageEmployeTemporaire(false)
    setMatriculeTempError(false)
    setAlertReembauche(false)
    setEtatReaction(false)
    setMatriculeSalaria(false)
    setMatriculeBlockage(false)
    setEmployesTemporairesRechercher(
      APIService.getEmployesTemporairesRechercher(employesTemporaires, nom, prenom, numeroSS)
    )}


}, [employesTemporaires])

  const RechercheBtn = () => {

    setToken('id', '')
    setToken('matriculeEmployeAttente', '')
    setEmployes([])
    setEmployerRechercher([])
    setEmployesTemporaires([])
    setEmployesTemporairesRechercher([])
    setEtat(false)
    setAlertReembauche(false)
    setMotif({})
    
    if(numeroSS || nom || prenom){
      setEtatVide(false)

      if(!statut || statut === 'valider'){


     APIService.getEmployes(token)
    .then(resp => setEmployes(resp))
    .catch(error => console.log(error))

      }
      
      if(!statut || statut === 'attente'){
      APIService.getEmployesTemporaires(token)
    .then(resp => setEmployesTemporaires(resp))
    .catch(error => console.log(error))
  }

    }else {
      setEtatVide(true)
      setMatriculeTempError(false)
    }

  }

  const ReembaucheBtn2 = () => {
    if(alertReembauche === false){
      setAlertReembauche(true)   
      setEtatMessageEmployeTemporaire(false)
      setEtatReaction(false)
      setMatriculeTempError(false)
      setMatriculeBlockage(false)
     }
  }

  const btnCompleterEmbauche = (e) => {

    setToken('matriculeEmployeAttente', e.target.value)
    window.location.href = '/saisie'

  }

  const [etatReaction, setEtatReaction] = useState(false)

  const ReembaucheBtn = (e) => {

    setToken('id', e.target.value)
    setEtatReaction(true)
    setAlertReembauche(false)
    setEtatMessageEmployeTemporaire(false)
    setMatriculeTempError(false)
    setMatriculeSalaria(false)
    setMatriculeBlockage(false)
  }

  useEffect(() => {

    
    if(token['id']){


      APIService.getEmploye(token['id'], token).then(resp => setEmploye(resp))
      

    }
  }, [token['id']])

  useEffect(() => {
    if(employe){
      APIService.getMotif(employe.motifSortie_employe, token).then(resp => setMotif(resp))
    }

    
  }, [employe])

  useEffect(() => {


    if(etatReaction === true){
      console.log(etatReaction)
      if(motif && motif.block_motif === true){
        console.log('no')
      }
      else{ if(motif && motif.block_motif === false)
        {window.location.href='/saisie'}
      }
    }
    
  }, [motif])

  const [motValue, setMotValue] = useState();
  const [listBlockMotif, setListBlockMotif] = useState([]);
  const [listMotifs, setListMotifs] = useState([]);


  useEffect(() => {

   if(token){

    APIService.getMotifs(token).then(resp => setListMotifs(resp))
   } 

   
  }, [token])


  useEffect(() => {

    if(listMotifs){
 
          listMotifs.map(mot => {
            if(mot.block_motif){

              if(listBlockMotif.indexOf(mot.id) === -1){
                listBlockMotif.push(mot.id)
              }
            }

          })
    } 


 
    
   }, [listMotifs])


  function etatMotif(v){
      if(motValue){
        return motValue.block_motif;
      } else {
        return null;
      }


  }
  const [showFirstForm, setShowFirstForm] = useState(true);

  const handleSubmitFirstForm = (event) => {
    
    event.preventDefault();
    if(!etatVide){
      setShowFirstForm(false);
    }
  };

  const handleSubmitSecondForm = (event) => {
    event.preventDefault();
  };
  const [showError, setShowError] = useState(true);

  const handleCloseClick = () => {
    if(showError === true)
    setShowError(false);
    else
    setShowError(true)
  };

  const [etatVide_, setEtatVide_] = useState(true);
  const [etatMessageEmployeTemporaire_, setEtatMessageEmployeTemporaire_] = useState(true);
  const [etatMessageBlocage, setEtatMessageBlocage] = useState(true);
  const [etatMessageNonTrouve, setEtatMessageNonTrouve] = useState(true);


  const handleCloseClickVide = () => {
    if(etatVide_ === true)
    setEtatVide_(false);
    else
    setEtatVide_(true)
  };

  const handleCloseClickEmployeTemporaire_ = () => {
    setEtatMessageEmployeTemporaire_(false);
  };
  const handleCloseClickBlocage = () => {
    setEtatMessageBlocage(false);
  };

  if(afficher === true){

  return (
            <div className={StylesEM.classCard}>
                {showFirstForm ? (
              <div className={StylesEM.firstFormContainer} >
                <div className={StylesEM.firstFormCenter}>

                      <form onSubmit={handleSubmitFirstForm} >
                      <div className={StylesEM.classTopSectionForm}>
                      <div className={StylesEM.container1} id={StylesEM.container3}>
                              <div className={StylesEM.zoneInput}>
                                  <span className={StylesEM.fileNameTxtStyle1}>
                                  Nom
                                  </span>
                                  <input type="text" className={StylesEM.goldenInput1} value={nom} onChange={e => setNom(e.target.value)}/>
                              </div>
                          </div>
                      <div className={StylesEM.container1} id={StylesEM.container4 }>
                          <div className={StylesEM.zoneInput}>
                              <span className={StylesEM.fileNameTxtStyle1}>
                              Prénom
                              </span>
                              <input type="text" className={StylesEM.goldenInput1} value={prenom} onChange={e => setPrenom(e.target.value)}/>
                          </div>
                      </div>
                      <div className={StylesEM.container1} id={StylesEM.container4}>
                        <div className={StylesEM.zoneInput}>
                            <span className={StylesEM.fileNameTxtStyle1}>
                            Numéro SS 
                            </span>
                            <input type="text" className={StylesEM.goldenInput1} value={numeroSS} onChange={e => setNumeroSS(e.target.value)}/>
                        </div>
                    </div>
                  <div className={StylesEM.container1} id={StylesEM.container3}>
                  <div className={StylesEM.zoneInput}>
                      <span className={StylesEM.fileNameTxtStyle1}>
                      Statut 
                      </span>
                      <select name="" className={StylesEM.goldenInput2} value={statut} onChange={e => setStatut(e.target.value)}>
                      <option></option>
                                                                   {
                                                                        listStatutAdministration &&
                                                                        listStatutAdministration.map(
                                                                              resp => <option value={resp.id}>{resp.nom}</option>
                                                                        )
                                                                   }
                      </select>
                  </div>
                </div>
              <div className={StylesEM.btnsubmittest}>
              <button type='submit' id={StylesEM.btnRecherchetest} className='bouttonsubmit' onClick={RechercheBtn}>
                      <i class="fa fa-search"></i>
                      <span className={StylesEM.hidden_text}>Rechercher</span>
                      </button>         
                </div>
                <div className={StylesEM.btnsubmitMobile}>
                      <button type='submit' id={StylesEM.btnRecherche} className='bouttonsubmit' onClick={RechercheBtn}>
                              <span className={StylesEM.text}>Rechercher</span>
                              </button>         
                        </div>
              <br />
                      </div>
                      <div className={StylesEM.classBottomSectionForm}>
        {/* MESSAGE ERREUR  */}
              {etatVide === true ?
      <>
      <div className={StylesEM.MessageError}>
      <span className={StylesEM.sectionIcon}>
      <button className={StylesEM.btnWarning}  >
      <FontAwesomeIcon icon={faExclamation } />
      </button>
      </span>
      <span className={StylesEM.sectionMsg}>
      <div className={StylesEM.topMsg}>
      Erreur
      </div>
      <div className={StylesEM.bottomMsg}>
      Tous les champs sont vides
      </div>
      </span>
      <span className={StylesEM.sectionValidation}>
      <div className={StylesEM.topicone}>
      <span className={StylesEM.iconeClose}>
          <button className={StylesEM.close} onClick={() => {setEtatVide(false)}}>
          <FontAwesomeIcon icon={faXmark } />
          </button>
        </span>
      </div>
      <div className={StylesEM.bottomicone}>
        <span className={StylesEM.lesicones}>
          <button className={StylesEM.btOui}  >
            Oui
          </button>
          <button className={StylesEM.btNon}  >
            Non
          </button>
        </span>
      </div>
      </span>
      </div>
      </> : <></>
      }
                      </div>
                      </form>
                </div>
              </div>
                  ) : (
                    <>
              <div className={StylesEM.generalContainer}>
                 <div className={StylesEM.firstContainer}>
                    <form onSubmit={handleSubmitSecondForm}>
                  <div className={StylesEM.containerScrol2}>
                        <div className={StylesEM.container12}>
                          <div className={StylesEM.zoneInput2}>
                            <span className={StylesEM.fileNameTxtStyle12}>
                              Nom
                            </span>
                            <input type="text" className={StylesEM.goldenInput12} value={nom} onChange={e => setNom(e.target.value)}/>
                          </div>
                        </div>

                        <div className={StylesEM.container12}>
                          <div className={StylesEM.zoneInput2}>
                            <span className={StylesEM.fileNameTxtStyle12}>
                              Prénom
                            </span>
                            <input type="text" className={StylesEM.goldenInput12} value={prenom} onChange={e => setPrenom(e.target.value)}/>
                          </div>
                        </div>

                        <div className={StylesEM.container12}>
                          <div className={StylesEM.zoneInput2}>
                            <span className={StylesEM.fileNameTxtStyle12}>
                              Numéro SS 
                            </span>
                            <input type="text" className={StylesEM.goldenInput12} value={numeroSS} onChange={e => setNumeroSS(e.target.value)}/>
                          </div>
                        </div>

                        <div className={StylesEM.container12}>
                          <div className={StylesEM.zoneInput2}>
                            <span className={StylesEM.fileNameTxtStyle12}>
                              Statut 
                            </span>
                            <select name="" className={StylesEM.goldenInput22} value={statut} onChange={e => setStatut(e.target.value)}>
                              <option value="valider">Valider</option>
                              <option value="attente">En attente</option>
                              <option value=""></option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className={StylesEM.btnsubmit2}>
                        <button type='submit' id={StylesEM.btnRecherche2} className='bouttonsubmit' onClick={RechercheBtn}>
                        <span className={StylesEM.text2}>Rechercher</span>
                        </button>         
                      </div>
                      <br />
              </form>
              </div>
                  {/* ***************************table******************************************  */}
                <div className={StylesEM.tableContainer}>
                      <table className={StylesEM.tableauResultat}>   
{
 (etat && employeRechercher.length !== 0) || (etat && employesTemporairesRechercher) && (employesTemporairesRechercher.length !== 0) ?
 <thead>
     <tr>
       <th className={StylesEM.colonnevide}></th>
       <th className={StylesEM.colonne}>Nom</th>
       <th className={StylesEM.colonne}>Prenom</th>
       <th className={StylesEM.colonne}>Etablissement</th>
       <th className={StylesEM.colonne}>Département</th>
       <th className={StylesEM.colonne}>Type de Contrat</th>
       <th className={StylesEM.colonne}>Date de sortie</th>
     </tr>
 </thead>
 :
 <>
 <tr className={StylesEM.alertepage2}>
  {
                    etatVide === true ?
                    <>
                      <div className={StylesEM.MessageErrorVide}>
                                <span className={StylesEM.sectionIcon}>
                                    <button className={StylesEM.btnWarning}  >
                                    <FontAwesomeIcon icon={faExclamation } />
                                    </button>
                                </span>
                                <span className={StylesEM.sectionMsg}>
                                    <div className={StylesEM.topMsg}>
                                    Erreur 
                                    </div>
                                    <div className={StylesEM.bottomMsg}>
                                    Tous les champs sont vides
                                    </div>
                                </span>
                                <span className={StylesEM.sectionValidation}>
                                    <div className={StylesEM.topicone}>
                                        <span className={StylesEM.iconeClose}>
                                            <button className={StylesEM.close} onClick={() => {setEtatVide(false)}}>
                                            <FontAwesomeIcon icon={faXmark } />
                                            </button>
                                          </span>
                                    </div>
                                    <div className={StylesEM.bottomicone}>
                                          <span className={StylesEM.lesicones}>
                                           
                                          </span>
                                    </div>
                                </span>
                            </div>
                      </> 
                          
                      : <></>
                  }
              {etat && employeRechercher.length === 0 && employesTemporairesRechercher.length === 0 ? 
                   <>
                        <div alert="alert" className={StylesEM.MessageEmplNnTrouve}>
                        <span className={StylesEM.sectionIconEmplNnTrouve}>
                            <button className={StylesEM.btnWarningEmplNnTrouve}  >
                            <FontAwesomeIcon icon={faExclamation } />

                            </button>
                        </span>
                        <span className={StylesEM.sectionMsgEmplNnTrouve}>
                            <div className={StylesEM.topMsgEmplNnTrouve}>
                            Employé non trouvé
                            </div>
                            <div className={StylesEM.bottomMsgEmplNnTrouve}>
                            Il s’agit d’un nouveau salarié, est-ce que vous voulez l’embaucher ?
                            </div>
                        </span>
                        <span className={StylesEM.sectionValidationEmplNnTrouve}>
                            <div className={StylesEM.topiconeEmplNnTrouve}>
                                <span className={StylesEM.iconeCloseEmplNnTrouve}>
                                    <button className={StylesEM.closeEmplNnTrouve} onClick={() => {setEtat(false)}}>
                                    <FontAwesomeIcon icon={faXmark } />
                                    </button>
                                  </span>
                            </div>
                            <div className={StylesEM.bottomiconeEmplNnTrouve}>
                                  <span className={StylesEM.lesiconesEmplNnTrouve}>
                                  <a href='/saisie'> <button className={StylesEM.btOuiEmplNnTrouve}>
                                      Oui
                                    </button>
                                    </a>
                                    <button className={StylesEM.btNonEmplNnTrouve} onClick={() => {setEtat(false)}}>
                                      Non
                                    </button>
                                  </span>
                            </div>
                        </span>
                    </div>
                    </>
                          :<div></div>
                          }
 </tr>
 </>
}

<tbody>
{ etatVide === true ? 

<></>
:
<>
{
etat && employeRechercher.length !== 0 ? 

<>
   {(etat && employeRechercher) ? 
                   
     employeRechercher.filter(emp => emp.stateOccur_employe==1).map(emp => 
       <>
           {emp.dateSortie_employe && emp.dateSortie_employe < currentDate ? 
             <>
             {
               listBlockMotif && listBlockMotif.includes(emp.motifSortie_employe) ? 
               
               
               <>
               <tr id={matriculeBlockage===emp.matricule_employe ?
                 StylesEM.blocageZone:null}>
                 <td>
                    <span id={StylesEM.iconeBlocage}> 
                    <FontAwesomeIcon icon={faXmark} />
                    </span>
                 </td>
                 <td>
                   <button value={emp.id} onClick={(e) => {ReembaucheBtn(e); setMatriculeBlockage(emp.matricule_employe)}} id={StylesEM.boutonblocage}>{emp.nom_employe.toUpperCase()}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={(e) => {ReembaucheBtn(e); setMatriculeBlockage(emp.matricule_employe)}} id={StylesEM.boutonblocage}>{emp.prenom_employe.toUpperCase()}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={(e) => {ReembaucheBtn(e); setMatriculeBlockage(emp.matricule_employe)}}  id={StylesEM.boutonblocage}>{emp.etablissement_employe}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={(e) => {ReembaucheBtn(e); setMatriculeBlockage(emp.matricule_employe)}}  id={StylesEM.boutonblocage}>{emp.departement_employe}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={(e) => {ReembaucheBtn(e); setMatriculeBlockage(emp.matricule_employe)}} id={StylesEM.boutonblocage}>{emp.typeContrat_employe}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={(e) => {ReembaucheBtn(e); setMatriculeBlockage(emp.matricule_employe)}} id={StylesEM.boutonblocage}>{emp.dateSortie_employe}</button>
                 </td>
               </tr> 
               </> 
               : 
               <>
               <tr id={StylesEM.succesZone}>
                 <td>
                 <span id={StylesEM.iconeValidation}> <FontAwesomeIcon icon={faCheck} /></span>
                 </td>
                 <td>
                   <button value={emp.id} onClick={ReembaucheBtn} id={StylesEM.boutonsucces}>{emp.nom_employe.toUpperCase()}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={ReembaucheBtn} id={StylesEM.boutonsucces}>{emp.prenom_employe.toUpperCase()}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={ReembaucheBtn}  id={StylesEM.boutonsucces}>{emp.etablissement_employe}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={ReembaucheBtn}  id={StylesEM.boutonsucces}>{emp.departement_employe}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={ReembaucheBtn} id={StylesEM.boutonsucces}>{emp.typeContrat_employe}</button>
                 </td>
                 <td>
                   <button value={emp.id} onClick={ReembaucheBtn} id={StylesEM.boutonsucces}>{emp.dateSortie_employe}</button>
                 </td>
               </tr> 
               </> 
             }
             </>
             :
             <>
             {/* les salaries qui sont encore avec la societe */}
               {!emp.dateSortie_employe || emp.dateSortie_employe > currentDate ? 
                 <>
                   <tr id={matriculeSalaria===emp.matricule_employe?
                    StylesEM.SalarieZone:null}>
                     <td>
                       <span id={StylesEM.informationicone}> 
                          <FontAwesomeIcon icon={faExclamation } />
                       </span>
                     </td>
                     <td>
                       <button value={emp.id} onClick={() => {ReembaucheBtn2(); setMatriculeSalaria(emp.matricule_employe); setNomPrenom(emp.nom_employe.toUpperCase() + " " + emp.prenom_employe)}} id={StylesEM.boutonprimary}>{emp.nom_employe.toUpperCase()}</button>
                     </td>
                     <td>
                       <button value={emp.id} onClick={() => {ReembaucheBtn2();setMatriculeSalaria(emp.matricule_employe); setNomPrenom(emp.nom_employe.toUpperCase() + " " + emp.prenom_employe)}}  id={StylesEM.boutonprimary} >{emp.prenom_employe.toUpperCase()}</button>
                     </td>
                     <td>
                       <button value={emp.id} onClick={() => {ReembaucheBtn2();setMatriculeSalaria(emp.matricule_employe);setNomPrenom(emp.nom_employe.toUpperCase() + " " + emp.prenom_employe)}}  id={StylesEM.boutonprimary}>{emp.etablissement_employe}</button>
                     </td>
                     <td>
                       <button value={emp.id} onClick={() => {ReembaucheBtn2();setMatriculeSalaria(emp.matricule_employe); setNomPrenom(emp.nom_employe.toUpperCase() + " " + emp.prenom_employe)}}  id={StylesEM.boutonprimary}>{emp.departement_employe}</button>
                     </td>
                     <td>
                       <button value={emp.id} onClick={() => {ReembaucheBtn2();setMatriculeSalaria(emp.matricule_employe); setNomPrenom(emp.nom_employe.toUpperCase() + " " + emp.prenom_employe)}} id={StylesEM.boutonprimary}>{emp.typeContrat_employe}</button>
                     </td>
                     <td>
                       <button value={emp.id} onClick={() => {ReembaucheBtn2();setMatriculeSalaria(emp.matricule_employe); setNomPrenom(emp.nom_employe.toUpperCase() + " " + emp.prenom_employe)}} id={StylesEM.boutonprimary1}>{emp.dateSortie_employe}</button>
                     </td>
                   </tr>
                 </>:
                 <>
                         
                 </>
               }
                   
             </>
           }
           
       </>
       ):
       <>
                   
       </>
   }
</> : 
<></>
}
</>
}
                   {/* Affichage de la liste temporaires recherchés */}
{(etat && employesTemporairesRechercher) ? 
employesTemporairesRechercher.map(emp => 
<>                          
<tr id={
  matriculeTempError === emp.matricule_employe ?
  StylesEM.temporaireZone : null
} >
<td >
    <span id={StylesEM.iconeEmpTempr}> 
    <FontAwesomeIcon icon={faWarning} />
    </span>
</td>
<td className={StylesEM.temporaire} >
 <button onClick={() => {btnEmployeTemporaire(); setMatriculeTempError(emp.matricule_employe)}} id={StylesEM.boutoninfo}>
   {emp.nom_employe.toUpperCase()}</button>
</td>
<td  className={StylesEM.temporaire}>
 <button onClick={() => {btnEmployeTemporaire(); setMatriculeTempError(emp.matricule_employe)}}  id={StylesEM.boutoninfo}>{emp.prenom_employe.toUpperCase()}</button>
</td>
<td  className={StylesEM.temporaire}  >
 <button onClick={() => {btnEmployeTemporaire(); setMatriculeTempError(emp.matricule_employe)}}  id={StylesEM.boutoninfo}>{emp.etablissement_employe}</button>
</td>
<td  className={StylesEM.temporaire} >
 <button onClick={() => {btnEmployeTemporaire(); setMatriculeTempError(emp.matricule_employe)}}  id={StylesEM.boutoninfo}>{emp.departement_employe}</button>
</td>
<td  className={StylesEM.temporaire} >
 <button onClick={() => {btnEmployeTemporaire(); setMatriculeTempError(emp.matricule_employe)}}  id={StylesEM.boutoninfo}>{emp.typeContrat_employe}</button>
</td>
<td  className={StylesEM.temporaire}>
 <button onClick={() => {btnEmployeTemporaire(); setMatriculeTempError(emp.matricule_employe)}} id={StylesEM.boutonprimary1}>{emp.dateSortie_employe}</button>
</td>
</tr>
<br />
</>
) : 
<></>
}
</tbody>
                      </table>
                </div>
    <div className={StylesEM.Alertcontainer}>
          
                                {etatMessageEmployeTemporaire && matriculeTempError ? 
                      <>
                          <div  role="alert" className={StylesEM.MessageEnAttente}>
                                  <span className={StylesEM.sectionIconAtt}>
                                      <button className={StylesEM.btnWarningAtt}  >
                                      <FontAwesomeIcon icon={faWarning } />
                                      </button>
                                  </span>
                                  <span className={StylesEM.sectionMsgAtt}>
                                      <div className={StylesEM.topMsgAtt}>
                                      Employé en attente
                                      </div>
                                      <div className={StylesEM.bottomMsgAtt}>
                                      Est-ce que vous voulez compléter son embauche ?
                                      </div>
                                  </span>
                                  <span className={StylesEM.sectionValidationAtt}>
                                      <div className={StylesEM.topiconeAtt}>
                                          <span className={StylesEM.iconeCloseAtt}>
                                              <button className={StylesEM.closeAtt} onClick={() => {setEtatMessageEmployeTemporaire(false); setMatriculeTempError();}}>
                                               <FontAwesomeIcon icon={faXmark } />
                                              </button>
                                          </span>
                                      </div>
                                      <div className={StylesEM.bottomiconeAtt}>
                                            <span className={StylesEM.lesiconesAtt}>
                                              <button value={matriculeTempError} onClick={btnCompleterEmbauche} className={StylesEM.btOuiAtt}  >
                                                Oui
                                              </button>
                                              <button className={StylesEM.btNonAtt} onClick={() => {setEtatMessageEmployeTemporaire(false); setMatriculeTempError();}} >
                                                Non
                                              </button>
                                            </span>
                                      </div>
                                  </span>
                          </div>
                          </> :<span></span>
                      }
                    {/* les salaries */}
                      {alertReembauche ? 
                      <>
                      <div alert="alert" className={StylesEM.MessageSalarie}>
                              <span className={StylesEM.sectionIconSalarie}>
                                  <button className={StylesEM.btnWarningSalarie}  >
                                    <FontAwesomeIcon icon={faExclamation } />
                                  </button>
                              </span>
                              <span className={StylesEM.sectionMsgSalarie}>
                                  <div className={StylesEM.topMsgSalarie}>
                                  Vous ne pouvez pas réembaucher les salariés déja embauché !
                                  </div>
                                  <div className={StylesEM.bottomMsgSalarie}>
                                  {nomPrenom} est déjà embauché .
                                  </div>
                              </span>
                              <span className={StylesEM.sectionValidationSalarie}>
                                  <div className={StylesEM.topiconeSalarie}>
                                      <span className={StylesEM.iconeCloseSalarie}>
                                          <button className={StylesEM.closeSalarie} onClick={() => {setAlertReembauche(false); setMatriculeSalaria();}}>
                                          <FontAwesomeIcon icon={faXmark } />
                                          </button>
                                        </span>
                                  </div>
                                  <div className={StylesEM.bottomiconeSalarie}>
                                        <span className={StylesEM.lesiconesSalarie}>
                                
                                        </span>
                                  </div>
                              </span>
                          </div>
                          </>: <span></span>
                          }
                           {/* BLOKAGE */}
                          { motif && motif.block_motif === true && etatReaction === true ?
                          <>
                          
                                  <div className={StylesEM.MessageBlockage}>
                                        <span className={StylesEM.sectionIconBlockage}>
                                            <button className={StylesEM.btnWarningBlockage}  >
                                            <FontAwesomeIcon icon={faXmark}/>
                                            </button>
                                        </span>
                                        <span className={StylesEM.sectionMsgBlockage}>
                                            <div className={StylesEM.topMsgBlockage}>
                                            {motif.alert_motif} 
                                            </div>
                                            <div className={StylesEM.bottomMsgBlockage}>
                                            {motif.message_motif}
                                            </div>
                                        </span>
                                        <span className={StylesEM.sectionValidationBlockage}>
                                            <div className={StylesEM.topiconeBlockage}>
                                                <span className={StylesEM.iconeCloseBlockage}>
                                                    <button className={StylesEM.closeBlockage} onClick={() => {setEtatReaction(false);setMatriculeBlockage();}}>
                                                    <FontAwesomeIcon icon={faXmark } />
                                                    </button>
                                                  </span>
                                            </div>
                                            <div className={StylesEM.bottomiconeBlockage}>
                                                  <span className={StylesEM.lesiconesBlockage}>
                                                  </span>
                                            </div>
                                        </span>
                                    </div>
                                  
                                    </>
                                  :
                                  <div></div>

                                  }
        </div>

        <div className={StylesEM.legendeContainer}>
          {
            (etat && employeRechercher.length !== 0) &&  (etat && employesTemporairesRechercher) || (employesTemporairesRechercher.length !== 0) ?
            <>

            <span id={StylesEM.footerTable}>
              <span className={StylesEM.legende}>
                <div className={StylesEM.legendItem}>
                  <span className={StylesEM.texte} id={StylesEM.blockageicone}>
                    <FontAwesomeIcon icon={faXmark}/>
                  </span>
                  <span className={StylesEM.texteLegende}>
                      Blockage
                  </span>
                  </div>
              </span>
              <span className={StylesEM.legende}>
              <div className={StylesEM.legendItem}>
                  <span className={StylesEM.texte} id={StylesEM.validationicone}>
                    <FontAwesomeIcon icon={faCheck}/>
                  </span>
                  <span className={StylesEM.texteLegende}>
                      Validation
                  </span>
                  </div>
              </span>
              <span className={StylesEM.legende}>
              <div className={StylesEM.legendItem}>
                  <span className={StylesEM.texte} id={StylesEM.warningeicone}>
                    <FontAwesomeIcon icon={faWarning}/>
                  </span>
                  <span className={StylesEM.texteLegende}>
                     Avertissement
                  </span>
                  </div>
              </span>
              <span className={StylesEM.legende_}>
              <div className={StylesEM.legendItem}>
                  <span className={StylesEM.texte} id={StylesEM.informationicone}>
                    <FontAwesomeIcon icon={faExclamation}/>
                  </span>
                  <span className={StylesEM.texteLegende}>
                      Information
                  </span>
                  </div>
              </span>
            </span>
            {/* *************************************************** */}
            {/* 2 eme cas  */}
            <span id={StylesEM.footerTable2}>
              <span className={StylesEM.legende2}>
                  <span className={StylesEM.texte2} id={StylesEM.blockageicone}>
                    <FontAwesomeIcon icon={faXmark}/>
                  </span>
                  <span className={StylesEM.texteLegende2}>
                      Blockage
                  </span>
              </span>
              <span className={StylesEM.legende2}>
                  <span className={StylesEM.texte2} id={StylesEM.validationicone}>
                    <FontAwesomeIcon icon={faCheck}/>
                  </span>
                  <span className={StylesEM.texteLegende2}>
                      Validation
                  </span>
              </span>
              <span className={StylesEM.legende2}>
                  <span className={StylesEM.texte2} id={StylesEM.warningeicone}>
                    <FontAwesomeIcon icon={faWarning}/>
                  </span>
                  <span className={StylesEM.texteLegende2}>
                      Warning
                  </span>
              </span>
              <span className={StylesEM.legende2}>
                  <span className={StylesEM.texte2} id={StylesEM.informationicone}>
                    &nbsp; 
                      <FontAwesomeIcon icon={faExclamation } />
                  </span>
                  <span className={StylesEM.texteLegende2}>
                      Information
                  </span>
              </span>
            </span>
            </>
            :
            <></>
           }
           </div>
           </div>
              </>  
                  )} 
             </div>
          );  
        };
      };

export default EmbaucheSection