
import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import CodePostaleSection from './CodePostaleSection.jsx';



export const CodePostale = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<CodePostaleSection />} />
    
   
 </div>
)}


export default CodePostale


