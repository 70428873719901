
import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import DepartementVilleSection from './DepartementVilleSection.jsx';



export const DepartementVille = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<DepartementVilleSection />} />
    
   
 </div>
)}


export default DepartementVille


