import React from 'react';
import styles from './LoadingPopup.module.css'



function LoadingPopup(props) {
  return (
    <div>
        <div className={styles.overlay}></div>
        <div className={styles.popup}>
                                    <div className={styles.gifContainer}>
                                         <i class="fas fa-spinner fa-pulse"></i>

                                    </div>


        </div>

    </div>
    
  )
}

export default LoadingPopup