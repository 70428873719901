import React from 'react';
import styles from './SelectNbreLigneTableInput.module.css'

function SelectNbreLigneTableInput(props) {

  return (
    
    <span className={styles.inputgroup}>
                                      <label className={styles.label}>Nombre de lignes </label>
                                      <select value={props.nombrePageFixed} onChange={props.handleOnChange} className={styles.test}>
                                          <option value={0}></option>
                                          <option value={2}>2</option>
                                          <option value={3}>3</option>
                                          <option value={5}>5</option>
                                          <option value={10}>10</option>
                                          <option value={25}>25</option>

                                      </select>
              </span>

  )
}

export default SelectNbreLigneTableInput