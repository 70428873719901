import React from 'react';
// import Styles from './Acceuil.module.css';
import Acceuil from './AcceuilStyle';
// import AccueilTopNavBar from './AccueilTopNavBar';
import NavBarGlobal from "./../NavBar/NavBarGlobal/NavBarGlobal";


function Accueil() {

       
  return (
    
      <div>
        <NavBarGlobal section = {<Acceuil />}/>
      </div>
  )
  
}

export default Accueil;

