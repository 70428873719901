import React from 'react';
import GestionTempsNavBar from './../../GestionDeTempsGlobal/NavBar/GestionTempsNavBar.jsx';
import GestionTempsCard from './../../GestionDeTempsGlobal/Card/GestionTempsCard.jsx';
import DemandeAbsenceSection from './DemandeAbsenceSection.jsx';
// import NavBarGlobal from '../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx';
import NavBarGlobal from "../../../Global/NavBar/NavBarGlobal/NavBarGlobal";


function DemandeAbsence() {

  return (
    <div>
       <NavBarGlobal section = {<DemandeAbsenceSection />}/>
    </div>
  )
}

export default DemandeAbsence