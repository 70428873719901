import react, {useEffect, useState} from "react";
import styles from "./FraisTransportSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";


function FraisTransportSection(){
    const today = new Date("2999-12-31").toISOString().slice(0, 10);
    const navigate = useNavigate();

    const date_today = new Date();
    const firstDay = new Date(date_today.getFullYear(), date_today.getMonth() , 1);
    const lastDay = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const [matriculePaie, setMatriculePaie] = useState();
    const [listFraisTransport, setListFraisTransport] = useState();
    const [listFraisTransportEtat, setListFraisTransportEtat] = useState([]);
    const [typeTransport, setTypeTransport] = useState();
    const [typeFrais, setTypeFrais] = useState();
    const [date, setDate] = useState();
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState();
    const [montant, setMontant] = useState();

    const [typeTransportDelimiter, setTypeTransportDelimiter] = useState();
    const [typeFraisDelimiter, setTypeFraisDelimiter] = useState();
    const [dateDelimiter, setDateDelimiter] = useState();
    const [dateDebutDelimiter, setDateDebutDelimiter] = useState();
    const [dateFinDelimiter, setDateFinDelimiter] = useState();
    const [montantDelimiter, setMontantDelimiter] = useState();



    const [idModifier, setIdModifier] = useState();
    const [idDelimiter, setIdDelimiter] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();
    const [isIconUp, setIsIconUp] = useState(false);

    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])
    
    useEffect(() => {
      if(matriculePaie){
        APIService.getFraisTransportPaie(matriculePaie, token).then(resp => setListFraisTransport(resp));
      }
    }, [matriculePaie, listFraisTransportEtat])

    useEffect(() => {

        if(JSON.stringify(listFraisTransportEtat) !== JSON.stringify(listFraisTransport)){
            setListFraisTransportEtat(listFraisTransport)
        }
    }, [listFraisTransport])
    


    const btnEnregistrer = () => {
        let body = {
            matricule_employe : "",
            matricule_manager : "", 
            typeTransport : "",
            typeFrais : "",
            date : "",
            dateDebut : "",
            dateFin : "",
            montant : ""
         
        }

        let bodyDelimiter = {
            matricule_employe : "",
            matricule_manager : "", 
            typeTransport : "",
            typeFrais : "",
            date : "",
            dateDebut : "",
            dateFin : "",
            montant : ""
        }

        if(((dateDebut && (dateFin || today)) || date) && typeTransport && typeFrais && montant){
            body.matricule_employe = matriculePaie;
            body.matricule_manager = matriculePaie;
            if(typeTransport === "Personnel"){
                body.dateDebut = date;
                body.dateFin = date;
                body.date = date;
            } else {

                body.dateDebut = dateDebut;;
                body.dateFin = dateFin;
                body.date = null;

            }            
            body.typeTransport = typeTransport;
            body.typeFrais = typeFrais;
            body.montant = montant;

            if(idModifier){

                APIService.putFraisTransportPaie(idModifier, body, token);
                setIdModifier();
                

            } else {
                if(idDelimiter){

                    bodyDelimiter.matricule_employe = matriculePaie;
                    bodyDelimiter.matricule_manager = matriculePaie;
                    bodyDelimiter.dateDebut = dateDebutDelimiter;
                    bodyDelimiter.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                    bodyDelimiter.date = date;
                    bodyDelimiter.typeTransport = typeTransport;
                    bodyDelimiter.typeFrais = typeFrais;
                    bodyDelimiter.montant = montant;



                   

                    APIService.putFraisTransportPaie(idDelimiter, bodyDelimiter, token);
                    APIService.postFraisTransportPaie(body, token);

                    setIdDelimiter();

                } else {
                    listFraisTransport.map(
                        resp => {
                            let bodyTemp = {
                                matricule_employe : "",
                                matricule_manager : "", 
                                typeTransport : "",
                                typeFrais : "",
                                date : "",
                                dateDebut : "",
                                dateFin : "",
                                montant : ""
                            }


                            if(new Date(resp.dateFin) > new Date(dateDebut)){
                                bodyTemp.matricule_employe = resp.matricule_employe;
                                bodyTemp.matricule_manager = resp.matricule_manager;
                                bodyTemp.dateDebut = resp.dateDebut;
                                bodyTemp.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                                bodyTemp.date = resp.date;
                                bodyTemp.typeTransport = resp.typeTransport;
                                bodyTemp.typeFrais = resp.typeFrais;
                                bodyTemp.montant = resp.montant;




                                APIService.putFraisTransportPaie(resp.id, bodyTemp, token);

                            }
                        }
                    )
                    console.log("Body : ", body);
                    APIService.postFraisTransportPaie(body, token);

                }
            }

            APIService.getFraisTransportPaie(matriculePaie, token).then(resp => {setListFraisTransport(resp); setListFraisTransportEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deleteFraisTransportPaie(idRem, token);
        APIService.getFraisTransportPaie(matriculePaie, token).then(resp => {setListFraisTransport(resp); setListFraisTransportEtat(resp);});

        setIdModifier();
        setIdDelimiter();
        
    }

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listFraisTransport.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setDateDebut(resp.dateDebut);
            setDateFin(resp.dateFin);
            setDate(resp.date);
            setTypeTransport(resp.typeTransport);
            setTypeFrais(resp.typeFrais);
            setMontant(resp.montant);




            setIdDelimiter();

        })


    }
    const btnDelimiter = (e) => {

        if(!etatAjouter) {
            setEtatAjouter(true);
        }

        listFraisTransport.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdDelimiter(resp.id);
            setDateDebutDelimiter(resp.dateDebut);
            setDateFinDelimiter(resp.dateFin);
            setDateDelimiter(resp.date);
            setTypeTransportDelimiter(resp.typeTransport);
            setTypeFraisDelimiter(resp.typeFrais);
            setMontantDelimiter(resp.montant);



            setDateDebut("");
            setDateFin(resp.dateFin);
            setDate(resp.date);
            setTypeTransport(resp.typeTransport);
            setTypeFrais(resp.typeFrais);
            setMontant(resp.montant);




            setIdModifier();


        })
    }


    const btnAjouter = () => {
        setIsIconUp(prevState => !prevState);    
        if(etatAjouter){
            setEtatAjouter(false);
        } else {
            setDateDebut("")
            setDateFin("")
            setDate("")
            setTypeTransport("")
            setTypeFrais("")
            setMontant("")


            setEtatAjouter(true);
        }

        setIdModifier();
        setIdDelimiter();
    }
    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }

    useEffect(() => {
        if(typeTransport && typeTransport === "Public" 
        ){
            setTypeFrais("Abonnement")
            setDateDebut(moment(firstDay).format("yyyy-MM-DD"))
            setDateFin(today)
        } else {
            if(typeTransport && typeTransport === "Personnel"){
                setDate(moment(lastDay).format("yyyy-MM-DD"))
            }
        }
    }, [typeTransport])
    
    

    return(
        <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart}>
            <TopMenuPaie page="fraistransport"/>

            </div>
            <div className={styles.secondPart}>
                    <div className={styles.tableAndFormSection}>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    {
                                        idDelimiter ?
                                        <div className={styles.modifiableTexte2}>
                                            <i class="fa-solid fa-scissors"></i> &nbsp;
                                            Délimiter</div> :<></>
                                    }
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de transport
                                                </div>
                                                <select className={styles.goldenInput1} value={typeTransport} onChange={e => setTypeTransport(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Public">Public</option>
                                                                            <option value="Personnel">Personnel</option>
                                                                            
                                                                        </select>
                                            </div>
                                    </div>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de frais
                                                </div>
                                                <select className={styles.goldenInput1} value={typeFrais} onChange={e => setTypeFrais(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Abonnement">Abonnement</option>
                                                                            <option value="Carburant">Carburant</option>
                                                                            <option value="Forfait mobilité">Forfait mobilité</option>
                                                                        </select>
                                            </div>
                                    </div>
                                     {
                                typeTransport && typeTransport === "Public" ?
                                <>
                                </>:
                                <>
                                     <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={date} onChange={(e) => setDate(e.target.value)}/>
                                        </div>
                                    </div>
                                </>

                            }
                                    {
                                typeTransport && typeTransport === "Personnel" ?
                                <>
                                </>:
                                <>

                                    <div className={styles.modern_container}>
                                        <div className={styles.modern_inputs}>
                                        <span className={styles.modern_label}>
                                        Période
                                        </span>
                                        <div class={styles.modern_input_wrapper}>
                                            <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                                            <span className={styles.timeSeparator}>-</span>
                                            <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                        </div>
                                        </div>
                                    </div>
                                    
                                </>

                            }
                                     
                                     
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant à rembourser
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Type de transport</th>
                                        <th>Type de frais</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFraisTransport ? 
                                    
                                        listFraisTransport.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
                                                <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
                                                <td>{resp.typeTransport}</td>
                                                <td>{resp.typeFrais}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>


                                                                &nbsp;

                                                                <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
                                                                <i class="fa-solid fa-scissors"></i>
                                                                    </button>

                                                        </>:<></>
                                                        }
                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                            </div>
                    </div>
                    {/* **************** Mobile ******** */}
                    <div className={styles.tableAndFormSectionMobile}>
                        <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Type de transport</th>
                                        <th>Type de frais</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFraisTransport ? 
                                    
                                        listFraisTransport.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
                                                <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
                                                <td>{resp.typeTransport}</td>
                                                <td>{resp.typeFrais}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>

                                                            
                                                                &nbsp;
                                                            
                                                                <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
                                                                <i class="fa-solid fa-scissors"></i>
                                                                    </button>
                                                                
                                                        </>:<></>
                                                        }
                                                    
                                                    


                                                    
                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                        </div>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    {
                                        idDelimiter ?
                                        <div className={styles.modifiableTexte2}>
                                            <i class="fa-solid fa-scissors"></i> &nbsp;
                                            Délimiter</div> :<></>
                                    }
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                     {
                                typeTransport && typeTransport === "Public" ?
                                <>
                                </>:
                                <>
                                     <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={date} onChange={(e) => setDate(e.target.value)}/>
                                        </div>
                                    </div>
                                </>

                            }
                                    {
                                typeTransport && typeTransport === "Personnel" ?
                                <>
                                </>:
                                <>

                                    <div className={styles.modern_container}>
                                        <div className={styles.modern_inputs}>
                                        <span className={styles.modern_label}>
                                        Période
                                        </span>
                                        <div class={styles.modern_input_wrapper}>
                                            <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                                            <span className={styles.timeSeparator}>-</span>
                                            <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                        </div>
                                        </div>
                                    </div>
                                    
                                </>

                            }
                                     
                                     <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de transport
                                                </div>
                                                <select className={styles.goldenInput1} value={typeTransport} onChange={e => setTypeTransport(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Public">Public</option>
                                                                            <option value="Personnel">Personnel</option>
                                                                            
                                                                        </select>
                                            </div>
                                    </div>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de frais
                                                </div>
                                                <select className={styles.goldenInput1} value={typeFrais} onChange={e => setTypeFrais(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Abonnement">Abonnement</option>
                                                                            <option value="Carburant">Carburant</option>
                                                                            <option value="Forfait mobilité">Forfait mobilité</option>
                                                                        </select>
                                            </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant à rembourser
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            
                    </div>
            </div>
        </div>
    </div>
    )
}

export default FraisTransportSection;