import React from 'react';
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../APIService.js';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Women from "./../../img/women.jpg";
import { FaBell } from "react-icons/fa";
import logosolway from "./../../img/logosolway.png";
import styles from './AccueilTopNavBar.module.css';
import moment from 'moment';
import 'moment/locale/fr' 

moment.locale('fr')

function AccueilTopNavBar() {
    const [token, setToken, removeToken] = useCookies(['mytoken', 'username', 'matriculeManager', 'matriculeEmployeAttente', 'matriculeActuel'])
    const [users, setUsers] = useState([])
    const [afficher, SetAfficher] = useState(false)

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          window.location.href = '/'
          localStorage.clear()
  
      }
    }, [token])
    
    
    useEffect(() => {
        APIService.getUsers(token['mytoken'])
      .then(resp => setUsers(resp))
    },token['mytoken'])


    useEffect(() => {

        
        if(users.length){
          SetAfficher(true)
        }
          
        
    }, [users])
   
  
    const LogoutBtn = () => {
      removeToken(['mytoken'])
      localStorage.clear()
  
    }
  


    

    const [allNotifications, setAllNotifications] = useState()



  // vue 

  useEffect(() => {

    if(token){

      APIService.getAllNotifications(token)
      .then(resp => setAllNotifications(resp.filter(ntf => ntf.username === token["username"])))


    }


  },[token])



  
  


const [etatMenu, setEtatMenu] = useState(false)


const btnMenu = () => {
  if(etatMenu === true){

    setEtatMenu(false)

  }else{
    setEtatMenu(true)

  }
 }
    

 const [etatOpen, setEtatOpen] = useState(false)


  // Affichage des notifications 
  const [allEmployes, setAllEmployes] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [employeCible, setEmployeCible] = useState([])

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          // navigate('/')
          window.location.href = '/'
          localStorage.clear()
      }
    }, [token])




    useEffect(() => {
      APIService.getUsers(token['mytoken'])
    .then(resp => setAllUsers(resp))
    },token['mytoken'])

    useEffect(() => {

      APIService.getEmploye(APIService.getEmployeId(allUsers, token), token)
      .then(resp => setEmployeCible(resp))

    },[allUsers])

  const [notificationCount, setNotificationCount] = useState(0)
  const [notifications, setNotifications] = useState([])
  const [allNotificationsBar, setAllNotificationsBar] = useState([])
  const [etatNotification, setEtatNotification] = useState(false)

  useEffect(() => {

    if(employeCible){
      setToken('matriculeActuel', employeCible.matricule_employe)
      setToken('matriculeManager', employeCible.matricule_manager)

    }
    
  }, [employeCible])
  


  useEffect(() => {

    APIService.getAllNotifications(token)
    .then(resp => setAllNotificationsBar(resp))


  },[etatNotification, token])


  const btnNotification = () => {
    if(etatNotification){

      setEtatNotification(false)


    }else{
      allNotificationsBar.map(resp => APIService.ModifierNotification(resp.id, {username:resp.username,matricule_employe:resp.matricule_employe,notification:resp.notification,dateNotification:resp.dateNotification,statutNotification:true}, token))
      setEtatNotification(true)
    }
  }


  const btnNotificationEvent = (e) => {

    allNotificationsBar.filter(nots => nots.id === parseInt(e.target.name)
      ).map(
      
      resp => APIService.ModifierNotification(resp.id, {username:resp.username,matricule_employe:resp.matricule_employe,notification:resp.notification,dateNotification:resp.dateNotification,statutNotification:resp.statutNotification, estVu:true}, token)
    )

    setToken('matriculeEmployeAttente', e.target.value)
    window.location.href = '/saisie'

  }


  return (
    <div>

    <nav id={styles.idNav}>
                
                <table className={styles.tableNotifProfile} align="right">
                  
                    <tr>
                      <td>
                          <img src={logosolway} id={styles.logoSolway}/>
                        
                      </td>
                      <td className={styles.menuNav}>
                        
                        {/* <Popup  trigger=
                                {<button className={styles.btnChoix2}><span className={styles.textBtnChoix}>Administration du personnel</span></button>}
                                {...{contentStyle}}
                                position="bottom"
                                >
                                <div>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Embauche</span></button>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Salarié</span></button>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Gestion de temps</span></button>
                                </div>
                        </Popup> */}
                        {/* <Popup  trigger=
                                {<button className={styles.btnChoix2}><span className={styles.textBtnChoix}>Planification</span></button>}
                                {...{contentStyle}}
                                position="bottom"
                                >
                                <div>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Embauche</span></button>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Salarié</span></button>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Gestion de temps</span></button>
                                </div>
                        </Popup> */}
                        {/* <Popup  trigger=
                                {<button className={styles.btnChoix2}><span className={styles.textBtnChoix}>Gestion des talents</span></button>}
                                {...{contentStyle}}
                                position="bottom"
                                >
                                <div>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Embauche</span></button>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Salarié</span></button>
                                  <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Gestion de temps</span></button>
                                </div>
                        </Popup> */}
                      </td>
                      <td className={styles.widthTD}>
                      <Popup  trigger=
                                {
                                  <button className={styles.tdNotifIcon}>
                                      <FaBell className={styles.iconProfileStyle} />
                                      <sup id={styles.supStyle}>{allNotificationsBar.filter(nots => nots.username === token['username']).length}</sup>
                                  </button>
                                }
                                {...{ textAlign : "center" , fontSize: "1vw", color: "black", width: "6vw"}}
                                position="bottom"
                                >
                                <div className={styles.containerOfNotifications}>
                                
  
                                    
  
                                    {allNotificationsBar.filter(nots => nots.username === token['username']).sort((a, b) => a.dateNotification > b.dateNotification ? -1:1).map(resp => <>
                                    {resp.estVu === false ? 
  
                                    <button className={styles.btnPrincipaleNonVue} 
                                    // id='inputRouge' 
                                    name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                    // class="btn btn-outline-danger text-center"
                                    >
                                      <span className={styles.textBtnNotif}>
                                        <br />
                                          {resp.notification}
                                        
                                        <hr/>
                                        {resp.dateNotification ? <>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</>
                                        : <>XX/XX/XXXX xx:xx:xx</>}
  
  
                                      </span>
                                    </button>
                                    :
                                    <button className={styles.btnPrincipaleVue} name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                    // class="btn btn-outline-secondary text-center"
                                    >
                                      <span className={styles.textBtnNotif}>
                                        <br />
                                      {resp.notification}
                                    <hr/>
                                    {resp.dateNotification ? <span>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</span>
                                    : <span>XX/XX/XXXX xx:xx:xx</span>}
                                    </span>
                                    </button> }
                                    <br />
                                    </>)}
                                </div>
                        </Popup>
                        
                      </td>
                      <td className={styles.widthTD}>
                        <Popup  trigger=
                                {
                                  <button className={styles.btnProfile} onClick={btnMenu}>
                                    <img src={Women} alt="" />
                                  </button>
                                }
                                {...{ textAlign : "center" , fontSize: "1vw", color: "black", width: "2vw !important"}}
                                position="bottom"
                                >
                                <div>
                                  <div id={styles.txtProfile}>
                                    {employeCible.prenom_employe}, {employeCible.nom_employe} 
                                    <hr />
                                    Etablissement : {employeCible.etablissement_employe} 
                                    <hr />
                                    <button className={styles.btnPrincipale} onClick={LogoutBtn}>Se déconnecter</button>
                                    
                                  </div>
                                </div>
                        </Popup>
                      </td>
                    </tr>
                  </table>
               
                
  
  
                  
  
  
                
  
                </nav>



    </div>
  )
}

export default AccueilTopNavBar