import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import CotisationSection from "./CotisationSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function Cotisation(){

    return(
        <div>
            <NavBarGlobal  section = {<CotisationSection />}/>
        </div>
    )
}

export default Cotisation;