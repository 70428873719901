import React from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import NavBarGlobal from "./../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";

import DashboardPaieSection from './DashboardPaieSection.jsx';



export const DashboardPaie = () => {
  return (
  <div>

    <NavBarGlobal section = {<DashboardPaieSection />} />
   
 </div>
)}
export default DashboardPaie