import React from 'react';
import {useCookies} from 'react-cookie';
import styles from './DeclarationSection.module.css';
import GestionTempsNavBar from './../../GestionDeTempsGlobal/NavBar/GestionTempsNavBar.jsx';
import GestionTempsCard from './../../GestionDeTempsGlobal/Card/GestionTempsCard.jsx';
import DeclarationSection from './DeclarationSection.jsx';
// import NavBarGlobal from '../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal';
import NavBarGlobal from "../../../Global/NavBar/NavBarGlobal/NavBarGlobal";




function Declaration() {
  return (
    <div>
        <NavBarGlobal section = {<DeclarationSection />}/>
    </div>
  )
}

export default Declaration