import React, { useState,useEffect } from 'react';
import styles from './FourthSectionTablePageTablet.module.css'; 

function GeneralCardTestTablet({
    component1,component2,component3
}) {
    const [componentsToDisplay,setComponentsToDisplay]=useState([])
      useEffect(() => {
        const Components = [];
        if(component1) {
            Components.push(component1);
        }
        if(component2){ 
            Components.push(component2);
        }
        if(component3){ 
            Components.push(component3);
        }
        setComponentsToDisplay(Components);   
      }, [component1, component2, component3]);

      // console.log("display ",componentsToDisplay)
    return (
        <div className={styles.fullStyle}>
        <div className={styles.fourthSectionStyle}>
          {componentsToDisplay.map((component,index) => (
            <div className={styles.styleComponents} key={index}>
              {component}
            </div>
          ))}
        </div>
      </div>
    );
}
export default GeneralCardTestTablet;
