import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import NoteFraisSection from "./NoteFraisSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function NoteFrais(){

    return(
        <div>
            <NavBarGlobal  section = {<NoteFraisSection />}/>
        </div>
    )
}

export default NoteFrais;