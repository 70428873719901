// Partie ADMINISTRATION DE PERSONNEL
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Login from './components/Global/Login/Login';
import Reset from './components/Global/Reset/Reset';
import Register from './components/Global/Register/Register';
import Profile from './components/Global/Profile/Profile';

import {CookiesProvider} from 'react-cookie';
// Global
// import CardStyle from "./components/Global/CardVide/CardStyle";
import NavBar from "./components/Global/NavBar/NavBar";
import NavBarMobile from "./components/Global/NavBar/Mobile/NavBarMobile";
// import NavBarGlobal from "./components/Global/NavBar/NavBarGlobal/NavBarGlobal";


// Partie ACCEUIL
import Accueil from './components/Global/Accueil/Accueil';
import Admin from './components/Embauche/Admin/Admin';
import Embauche from './components/Embauche/Recherche/Embauche';
import Saisie from './components/Embauche/Saisie/Saisie';
import AdminSection from './components/Embauche/Admin/AdminSection';
import PopupComponent from './components/Embauche/PopupComponent';


// import Services from './components/Services';
import Pdf from './components/Pdf';


// Partie SALARIE 
import Salarie from './components/Salarie/Dashboard/Salarie';
import DonneesPersonnel from './components/Salarie/DonneesPersonnelles/DonneesPersonnel';
import Contrat from './components/Salarie/Contrat/Statut/Contrat';
import Avenant from './components/Salarie/Contrat/Avenant/Avenant';
import Statutformation from './components/Salarie/Formation/Statut/Statutformation';
import Formation from './components/Salarie/Formation/Creation/Formation';


// Partie GESTION DE TEMPS 
import Dashboard from './components/GestionDeTemps/Dashboard/Dashboard';
import Declaration from './components/GestionDeTemps/Activite/Declaration/Declaration';
import HistoriqueActivite from './components/GestionDeTemps/Activite/Historique/HistoriqueActivite';
import DemandeAbsence from './components/GestionDeTemps/Absence/Demande/DemandeAbsence';
import HistoriqueAbsence from './components/GestionDeTemps/Absence/Historique/HistoriqueAbsence';
import PlanningAbsence from './components/GestionDeTemps/PlanningAbsence/PlanningAbsence';
import ListeTaches from './components/GestionDeTemps/ListeTaches/ListeTaches';



// tests 

import SalarieGlobal from './components/Salarie/SalarieGlobal/GlobalComponent/GloabalComponentSalarie';


// Saisie Style 

import CardSaisieGlobal from './components/Embauche/Saisie/CardSaisieGlobal';


// Test composants glob 


import NavBarGlobal from './components/GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal';
import CardStyle from './components/GlobalComponents/CardVide/CardStyle'


// Sous module Paie 

import DashboardPaie from './components/Paie/dashboardPaie/DashboardPaie';
import Remuneration from './components/Paie/Remuneration/Remuneration';
import Cotisation from './components/Paie/Cotisation/Cotisation';
import Absence from './components/Paie/Absence/Absence';
import Repas from './components/Paie/Repas/Repas';
import AcompteSalaire from './components/Paie/AcompteSalaire/AcompteSalaire';
import Primes from './components/Paie/Primes/Primes';
import NoteFrais from './components/Paie/NoteFrais/NoteFrais';
import FraisProfessionnels from './components/Paie/FraisProfessionnels/FraisProfessionnels';
import FraisTransport from './components/Paie/FraisTransport/FraisTransport';
import HeuresSupplementaires from './components/Paie/HeuresSupplementaires/HeuresSupplementaires';




//Administration

import DashboardAdministration from './components/Administration/dashboardAdministration/DashboardAdministration';
import DonneesAdministration from './components/Administration/donneesAdministration/DonneesAdministration';
import LangueAdministrations from './components/Administration/languesAdministration/LangueAdministrations';
import SituationFamilialeAdministration from './components/Administration/situationFamilialeAdministration/SituationFamilialeAdministration';
import Jours from './components/Administration/jours/Jours';
import TypeContrat from './components/Administration/typeContrat/TypeContrat';
import Temps from './components/Administration/temps/Temps';
import Banque from './components/Administration/banque/Banque';
import Etablissement from './components/Administration/etablissement/Etablissement';
import Departement from './components/Administration/departement/Departement'; 
import Poste from './components/Administration/poste/Poste'; 
import Nationalite from './components/Administration/nationalite/Nationalite';
import Pays from './components/Administration/pays/Pays';
import Ville from './components/Administration/ville/Ville';
import DepartementVille from './components/Administration/departementVille/DepartementVille';
import CodePostale from './components/Administration/codePostale/CodePostale';
import StatutAdministration from './components/Administration/statutAdministration/StatutAdministration';
import NombreLignesAdministration from './components/Administration/nombreLignesAdministration/NombreLignesAdministration';
import ActionsAdministration from './components/Administration/actionsAdministration/ActionsAdministration'; 
import DescriptionAdministration from './components/Administration/descriptionAdministration/DescriptionAdministration'; 
import NiveauLanguesAdministration from './components/Administration/niveauLanguesAdministration/NiveauLanguesAdministration'; 
import StatutActiviteAdministration from './components/Administration/statutActiviteAdministration/StatutActiviteAdministration';
import ActiontacheAdministration from './components/Administration/actiontacheAdministration/ActiontacheAdministration';
// import DemandeAbsenceSection from './components/GestionDeTemps/Absence/Demande/DemandeAbsenceSection';
import DemandeAbsenceAdministration from './components/Administration/demandeAbsenceAdministration/DemandeAbsenceAdministration';
import Unite from './components/Administration/unite/Unite';




import TestHooks from './components/Administration/unite/TestHooks';














function Router(){
  return(
    
    <CookiesProvider>
    <BrowserRouter>
    <Routes>
    
    {/* ------- */}
      <Route exact path = '/' element={<Login />}/>
      <Route exact path = '/register' element={<Register />}/>
      <Route exact path = '/profile' element={<Profile />}/>
      <Route exact path = '/accueil' element={<Accueil />}/>
      <Route exact path = '/admin' element={<Admin />}/>
      <Route exact path = '/embauche' element={<Embauche />}/>
      <Route exact path = '/saisie' element={<Saisie />}/>
      <Route exact path = '/dashboard' element={<Dashboard />}/>
      <Route exact path = '/contrat' element={<Contrat />}/>
      <Route exact path = '/statut' element={<Statutformation />}/>
      <Route exact path = '/avenant' element={<Avenant />}/>
      <Route exact path = '/salarie' element={<Salarie />}/>
      <Route exact path = '/declaration' element={<Declaration />}/>
      <Route exact path = '/pdf' element={<Pdf />}/>
      <Route exact path = '/formation' element={<Formation />}/>
      <Route exact path = '/donneesPersonnelles' element={<DonneesPersonnel />}/>
      <Route exact path = '/demandeAbsence' element={<DemandeAbsence />}/>
      <Route exact path = '/historiqueAbsence' element={<HistoriqueAbsence />}/>
      <Route exact path = '/historiqueActivite' element={<HistoriqueActivite />}/>
      <Route exact path = '/listeTaches' element={<ListeTaches />}/>
      <Route exact path = '/planningAbsence' element={<PlanningAbsence />}/>
      <Route exact path = '/navBar' element={<NavBar />}/>
      <Route exact path = '/mobile' element={<NavBarMobile />}/>
      <Route exact path = '/navbarglobal' element={<NavBarGlobal />}/>
      <Route exact path = '/card' element={<CardStyle />}/>
      <Route exact path = '/salarieGlobal' element={<SalarieGlobal />}/>
      <Route exact path = '/cardStyle' element={<CardStyle />}/>
      <Route exact path = '/adminSection' element={<AdminSection />}/>
      <Route exact path = '/navGlob' element={<NavBarGlobal />}/>
      <Route exact path = '/remuneration' element={<Remuneration />}/>
      <Route exact path = '/cotisation' element={<Cotisation />}/>
      <Route exact path = '/absence' element={<Absence />}/>
      <Route exact path = '/repas' element={<Repas />}/>
      <Route exact path = '/acompteSalaire' element={<AcompteSalaire />}/>
      <Route exact path = '/primes' element={<Primes />}/>
      <Route exact path = '/noteFrais' element={<NoteFrais />}/>
      <Route exact path = '/fraisProfessionnels' element={<FraisProfessionnels />}/>
      <Route exact path = '/fraisTransport' element={<FraisTransport />}/>
      <Route exact path = '/fraisTransport' element={<FraisTransport />}/>
      <Route exact path = '/heuresSupplementaires' element={<HeuresSupplementaires />}/>
      <Route exact path = '/popup' element={<PopupComponent />}/>
      <Route exact path = '/paie' element={<DashboardPaie />}/>


      {/* Administration  */}

      <Route exact path = '/administration' element={<DashboardAdministration />}/>
      <Route exact path = '/donnees' element={<DonneesAdministration />}/>
      <Route exact path = '/languesAdministartion' element={<LangueAdministrations />}/>
      <Route exact path = '/situationFamilialeAdministration' element={<SituationFamilialeAdministration />}/>
      <Route exact path = '/TypeContrats' element={< TypeContrat/>}/>
      <Route exact path = '/temps' element={< Temps/>}/>
      <Route exact path = '/banques' element={< Banque/>}/>
      <Route exact path = '/jours' element={< Jours/>}/>
      <Route exact path = '/etablissement' element={< Etablissement/>}/>
      <Route exact path = '/departement' element={< Departement/>}/>
      <Route exact path = '/poste' element={< Poste/>}/>
      <Route exact path = '/nationalite' element={< Nationalite/>}/>
      <Route exact path = '/pays' element={< Pays/>}/>
      <Route exact path = '/ville' element={< Ville/>}/>
      <Route exact path = '/DepartementVille' element={< DepartementVille/>}/>
      <Route exact path = '/CodePostale' element={< CodePostale/>}/> 
      <Route exact path = '/StatutAdministration' element={< StatutAdministration/>}/> 
      <Route exact path = '/NombreLignesAdministration' element={< NombreLignesAdministration/>}/> 
      <Route exact path = '/ActionsAdministration' element={< ActionsAdministration/>}/>
      <Route exact path = '/DescriptionAdministration' element={< DescriptionAdministration/>}/> 
      <Route exact path = '/NiveauLanguesAdministration' element={< NiveauLanguesAdministration/>}/> 
      <Route exact path = '/StatutActiviteAdministration' element={< StatutActiviteAdministration/>}/> 
      <Route exact path = '/ActiontacheAdministration' element={< ActiontacheAdministration/>}/> 
      <Route exact path = '/DemandeAbsenceAdministration' element={< DemandeAbsenceAdministration/>}/> 
      <Route exact path = '/unite' element={< Unite/>}/> 
      <Route exact path = '/hook' element={< TestHooks/>}/> 



      <Route exact path = '/reset/:uid/:tokenReset' element={<Reset />}/>
          <Route exact path = '/reset' element={<Reset />}/>

      
































      


      {/* <Route exact path = '/Saisienew' element={<Saisienew />}/> */}

      {/* <Route exact path = '/absence' element={<Absence />}/> */}

    </Routes>
    </BrowserRouter>
    </CookiesProvider>
  
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
