import React from 'react';
import styles from './CheckboxTableInput.module.css'

function CheckboxTableInput(props) {

  return (
    
    <span className={styles.inputgroup}>
             
              <input type="checkbox" className={styles.inputGlobal8}
              checked={props.afficherChecked} 
              onChange={props.handleChange}
              /> 
              <span className={styles.inputGlobal77} > &nbsp; Tout afficher </span>


            </span> 

  )
}

export default CheckboxTableInput