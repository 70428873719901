import React, { useState, useEffect } from 'react';
import styles from './Input.module.css';

function Input(props) {

  const [disabledValue, setDisabledValue] = useState(false)


  useEffect(() => {
    if(props.disabled === true || props.disabled === false){

      setDisabledValue(props.disabled)
    } 
  }, [props.disabled])

 


  return (
      <div className={styles.container} id={styles.containerId}>
                  <div 
                  className={props.classZoneInputEtat ?
                  styles.zoneInputInput
                  : 
                  styles.zoneInputInputWithFiles
                }

                
                >
                              <span className={styles.fileNameTxtStyleInput}>
                              {props.title}  
                              </span>
                              {
                                props.type === 'select' ?
                             <select  className={styles.goldenInput1} id={props.inputId} name={props.name} value={props.value} onChange={props.handleChange}>
                                  <option></option>
                                  
                                      {
                                        props.listItem
                                      } 
                                    
                                </select>
                                :
                                <input type={props.type} className={styles.goldenInput1} id={props.inputId} name={props.name} value={props.value}
                                                      onChange={props.handleChange}  disabled={disabledValue} />
                              }                       
                        </div>
                  </div> 
  )
}
{
 
}


export default Input