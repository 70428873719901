import React, { useState, useEffect, Children } from "react";
import styles from "./PopupComponentGstTemps.module.css";
import '@fortawesome/fontawesome-free/css/all.min.css';


function PopupComponentGstTemps({show,onClose,width,height,children,popUpHeader}){
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (show) {
      togglePopup();
    }
  }, [show]);

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  
if(show){
  return(
  <>
  <div className={styles.modal_} onClick={onClose}>
      {width && height ? (
        <>
          <div className={styles.popup} style={{ width, height,maxWidth:"800px" ,maxHeight: "600px", overflowY: "hidden"}} onClick={(e) => e.stopPropagation()}>
              <div className={styles.header}>
                <div className={styles.title}>
                  {popUpHeader &&
                  <>
                      {popUpHeader}
                  </>
                  }
                </div>
                <div className={styles.xStyle}>
                    <button className={styles.buttonx} onClick={onClose}>
                        {/* &times; */}
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>
              </div>
              <div id={styles.content}>
                          {children}
              </div>
          </div>
          </>):(
    <>:</>)}
    </div>
  </>
  )
} else {
  return(
    <></>
  )
}
};
export default PopupComponentGstTemps;