import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import JoursSection from './JoursSection.jsx';



export const Jours = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<JoursSection />} />
    {/* jours */}
   
 </div>
)}


export default Jours