import React from "react";
// import Card from "../CardVide/CardStyle.jsx";
import ProfileSection from "./ProfileSection";
import NavBarGlobal from "./../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";

function Profile(){

    return(
<div>
    <NavBarGlobal  section = {<ProfileSection />}  />
    
     </div>
    )
}
export default Profile; 