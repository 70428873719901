import React from 'react';
import styles from "./TopMenuPaieNewStyle.module.css"
import {useState, useEffect, useRef} from 'react';

function TopMenuPaie(props) {

   
  // const [show, setShow] = useState(false);
  // const popupRef = useRef(null);
  // const barButtonRef = useRef(null);

  // const handleClick = (event) => {
  //   if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)) {
  //     setShow(false);
  //   }
  // };

  // const showMenu = () => {
  //   setShow(prevShow => !prevShow);
  // };

  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener('mousedown', handleClick);
  //   } else {
  //     document.removeEventListener('mousedown', handleClick);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClick);
  //   };
  // }, [show]);
  // *************************
  const [show, setShow] = useState(false);
  const popupRef = useRef(null);
  const barButtonRef = useRef(null);

  const showMenu = () => {
    setShow(prevShow => !prevShow);
    if (show) {
      console.log("Show True : ", show)
          popupRef.current.classList.remove(styles.fadeOut);
      setTimeout(() => {
            popupRef.current.style.display = 'none';
            console.log("New ref after .51s : ", popupRef.current.style.display)
        }, 510); 
  }else{
    console.log("Show False : ", show)
    popupRef.current.classList.add(styles.fadeIn);
    popupRef.current.style.display = 'block';
  }
  };

  const handleClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)
  ) {
    showMenu()
    }
  };
  
  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClick);
    // setCaretDirection('up');
    } else {
      document.removeEventListener('mousedown', handleClick);
    // setCaretDirection('down');
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [show]);

  // **********************************


  const [showAdministrationMenu, setShowAdministrationMenu] = useState(false);
  const [showPlannificationMenu, setShowPlannificationMenu] = useState(false);
  const [showGestionMenu, setShowGestionMenu] = useState(false);

  const [styleAdministration, setStyleAdministration] = useState()
  const [stylePlanification, setStylePlanification] = useState({backgroundColor:"transparent"})
  const [styleGestion, setStyleGestion] = useState({backgroundColor:"transparent"})

  function showMenuAdministraion() {
    if(showAdministrationMenu){
      setShowAdministrationMenu(false)
      setStyleAdministration({})


    }else{
      setShowAdministrationMenu(true)
      setStyleAdministration({backgroundColor:"#ddd", color:"black"})

      setShowPlannificationMenu(false)
      setStylePlanification({})

      setShowGestionMenu(false)
      setStyleGestion({})


    }
  }


  function showMenuPlannification() {
    if(showPlannificationMenu){
      setShowPlannificationMenu(false)
      setStylePlanification({})


    }else{
      setShowPlannificationMenu(true)
      setStylePlanification({backgroundColor:"#ddd", color:"black"})

      setShowAdministrationMenu(false)
      setStyleAdministration({})

      setShowGestionMenu(false)
      setStyleGestion({})
      
    }
  }

  function showMenuGestion() {
    if(showGestionMenu){
      setShowGestionMenu(false)
      setStyleGestion({})


    }else{
      setShowGestionMenu(true)
      setStyleGestion({backgroundColor:"#ddd", color:"black"})


      setShowPlannificationMenu(false)
      setStylePlanification({})

      setShowAdministrationMenu(false)
      setStyleAdministration({})
    }
  }

    var styleAbsence = {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};
    var styleAcompteSalaire = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleCotisation = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleFraisProfessionnel = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleFraisTransport = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleHeuresSupplementaires = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleNoteFrais = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var stylePrimes = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleRemuneration = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleRepas = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};



    if(props.page){
      
        if(props.page === "absence"){
          styleAbsence = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};

          // styleAbsence = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
        }
        if(props.page === "AcompteSalaire"){
            //  style for the design
            styleAcompteSalaire = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
        }
        if(props.page === "cotisation"){
            styleCotisation ={borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
        }
        if(props.page === "FraisProfessionnels"){
            styleFraisProfessionnel = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
          }
          if(props.page === "fraistransport"){
            styleFraisTransport = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
          }
          if(props.page === "HeuresSupplementaires"){
            styleHeuresSupplementaires= {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
          }
          if(props.page === "Notefrais"){
            styleNoteFrais = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
          }
          if(props.page === "primes"){
            stylePrimes = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
          }
          if(props.page === "remuneration"){
            styleRemuneration = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
          }
          if(props.page === "repas"){
            styleRepas = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)",height:"24px", marginBottom: "2px"};
          }
      }
  return (
    <div className={styles.sectionStyle}>
      <div className={styles.emptycontainer}>
        <div className={styles.firstPart}></div>
        <div className={styles.container}>
            <div className={styles.topMenuContainer} id={styles.idTopNavBar}>
                      <div className={styles.vide}></div>
                      <div className={styles.column} style={styleAbsence}>
                        <a href="absence" className={styles.txtLinkBtn}>
                          <button className={styles.classBtnCalendrier}  >
                             Absence 
                             </button>
                              </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="AcompteSalaire" style={styleAcompteSalaire}>
                        <a href="AcompteSalaire" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} > Acompte sur salaire</button> </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="cotisation" style={styleCotisation}>
                        <a href="cotisation" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} > Cotisation </button> </a>
                      </div>  
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="FraisProfessionnels" style={styleFraisProfessionnel}>
                        <a href="FraisProfessionnels" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier}>Frais Professionnels</button> </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="fraistransport"  style={styleFraisTransport} >
                        <a href="fraistransport" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier}>Frais Transport</button> </a>
                      </div>
                      <div  className={styles.vide}></div>
                      <div className={styles.column} id="HeuresSupplementaires" style={styleHeuresSupplementaires}>
                        <a href="HeuresSupplementaires" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} >Heures en extra</button> </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="Notefrais" style={styleNoteFrais}>
                        <a href="Notefrais" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier}> Notefrais </button> </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="primes" style={stylePrimes} >
                        <a href="primes" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} >Primes </button> </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="remuneration" style={styleRemuneration}>
                        <a href="remuneration" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} >Rémuneration</button> </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id={styles.lastColumn} style={styleRepas}>
                        <a href="repas" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} > repas </button> </a>
                      </div>
                      <div className={styles.vide}></div>
            </div>
        </div>
        <div className={styles.lastPart}></div>
      </div>
      {/* ****************MOBILE ************** */}
      <div className={styles.emptycontainerMobile}>
        <div className={styles.firstPartMobile}>
             <span className={styles.element} ref={barButtonRef} onClick={() => showMenu()} style={{ backgroundColor: show ? "rgb(218, 165, 32,80%)" : "" }}>
                  <i className="fa fa-bars"></i>
            </span>
            <div id={styles.itemsMenu}  ref={popupRef}   className={show ? `${styles.active}` : `${styles.hidden}`}>
              <div className={styles.containerPopUP}>
                <div className={styles.buttonMobile} style={styleAbsence}>
                  <a href="absence"  className={styles.txtLinkBtn} >
                    <button  className={styles.btnMenu} > 
                      Absence
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleAcompteSalaire}>
                  <a href="AcompteSalaire" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  > 
                      Acompte sur salaire
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleCotisation}>
                  <a href="cotisation" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}    > 
                      Cotisation
                    </button>
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleFraisProfessionnel}> 
                  <a href="FraisProfessionnels" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  >
                      Frais Professionnels
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleFraisTransport}>
                  <a href="fraistransport" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  >
                      Frais Transport
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleHeuresSupplementaires}>
                  <a href="HeuresSupplementaires" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  >
                      Heures Supplementaires
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleNoteFrais}>
                  <a href="Notefrais" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu} > 
                      Notefrais
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={stylePrimes}>
                  <a href="primes" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  >
                      Primes
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile}  style={styleRemuneration}>
                  <a href="remuneration" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  >
                      Rémuneration
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleRepas}>
                  <a href="repas" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  > 
                      Repas
                    </button> 
                  </a>
                </div>
                </div>
            </div>
        </div>
        <div className={styles.secondPartMobile}>
                  {
                    props.page == "absence" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <div className={styles.btnMenu_}  > 
                      Absence
                    </div> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "AcompteSalaire" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_} > 
                    Acompte sur salaire
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "cotisation" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                    Cotisation
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "FraisProfessionnels" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                    Frais Professionnels
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "fraistransport" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                    Frais Transport
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "HeuresSupplementaires" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                    Heures Supplementaires
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "Notefrais" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                      Notefrais
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "primes" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                    Primes
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "remuneration" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                     Rémuneration
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "repas" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_} > 
                    Repas
                    </button> 
                    </div>   
                    </>:<></>
                  }
        </div>
      </div>

    </div>
  )
}

export default TopMenuPaie