import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import TypeContratSection from './TypeContratSection.jsx';



export const TypeContrat = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<TypeContratSection />} />
    
   
 </div>
)}


export default TypeContrat