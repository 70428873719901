import React from 'react';
import styles from './Saisie.module.css';
import NavBarGlobal from "./../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import SaisieSection from "./SaisieSection";
function Saisie() {
  return (
    <div>
        <NavBarGlobal section={<SaisieSection />} />
    </div>
  )
}

export default Saisie

