import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import DashboardAdministrationSection from './DashboardAdministrationSection.jsx';



export const DashboardAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<DashboardAdministrationSection />} />
   
 </div>
)}

export default DashboardAdministration
