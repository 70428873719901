import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import TempsSection from './TempsSection.jsx';



export const Temps = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<TempsSection />} />
    
   
 </div>
)}


export default Temps