import React from 'react';
import styles from './Delimiter.module.css'

function Delimiter(props) {
  return (
    <button id={styles.delimitButton} onClick={() => props.delimiterData()}>
      <i class="fa-solid fa-scissors"></i>
    </button>
  )
}

export default Delimiter