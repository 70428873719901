import React from 'react';
import styles from './SalarieCard.module.css';
import {useCookies} from 'react-cookie';


function SalarieCard(props) {
  var styleSalarie = {backgroundColor:""};
  var styleContrat = {backgroundColor:""};
  var styleFormation = {backgroundColor:""};
  const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'idFichePersonnel', 'matriculeAvenant', 'listMatriculeNomPrenom'])

if(props.page){
  if(props.page === "salarie" || props.page === "donnees"){
    styleSalarie = {backgroundColor:"goldenrod"};
   


  }
  if(props.page === "contrat"){
    styleContrat = {backgroundColor:"goldenrod"};
  }
  if(props.page === "formation"){
    styleFormation = {backgroundColor:"goldenrod"};
  }
}
 
  
  return (
    <div id={styles.cardSalarie}>
      

        <div id={styles.idContainer}>
          <div className="row" id={styles.idTopNavBar}>
                  <div className="col">
                  <a href="salarie" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleSalarie} > Dashboard </button> </a>
                  </div>
                  {
                    props.page !== "salarie" ?
                    <>
                    <div className="col" id="contrat">
                      <a href="contrat" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleContrat} > Contrat </button> </a>
                    </div>
                    <div className="col" id="formation">
                      <a href="statut" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleFormation} > Formation </button> </a>
                    </div>   
                    </>:<></>
                  }
                        
          </div>
          {/* <br /> */}
          {
            props.page !== "salarie" ?
            <div id={styles.styleSalarieCible}>
            <span id={styles.txtStyle}>
            Salarié : {token["listMatriculeNomPrenom"][0]} - {token["listMatriculeNomPrenom"][1]} {token["listMatriculeNomPrenom"][2]}

            </span>
          </div> :
          <hr /> 

          }
          
          <div id={styles.idSection}>
                    {<props.section />}
        </div>
        </div>
    </div>
  )
}

export default SalarieCard