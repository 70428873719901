import React, { useEffect } from 'react';
import Styles from './CardSaisieGlobal.module.css';
import "./../../../App.css";
import { useState } from 'react';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUpload } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@https://www.npmjs.com/package/@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUpload } from '@fortawesome/free-solid-svg-icons';



      
// import '../App.css';
function CardStyle() {

  
    // const [title, SetTitle] = useState('');
    const [fileValue1, setFileValue1] = useState();

    useEffect(() => {
      if(fileValue1){
        console.log("fileValue1 : ", fileValue1.name)
      }
    }, [fileValue1])
    
  
    const [fileValues, setFileValues] = useState();

    useEffect(() => {
      if(fileValues){
        console.log("fileValues : ", fileValues.name)
      }
    }, [fileValues])
    const [fileValuess, setFileValuess] = useState();

    useEffect(() => {
      if(fileValuess){
        console.log("fileValuess : ", fileValuess.name)
      }
    }, [fileValuess])

    const [fileValue4, setFileValue4] = useState();

    useEffect(() => {
      if(fileValue4){
        console.log("fileValue4 : ", fileValue4.name)
      }
    }, [fileValue4])

    const [fileValue5, setFileValue5] = useState();

    useEffect(() => {
      if(fileValue5){
        console.log("fileValue5 : ", fileValue5.name)
      }
    }, [fileValue5])
    
    return (
      <div className='bodback'>


        <div className={Styles.containerB}>
            <div className={Styles.une}>
              {/* <div className={Styles.divGridBar}>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
                    <button> Fichiers </button>
              </div> */}
              <div className={Styles.case}  >

        
        <span className={Styles.buttonT} id={Styles.unique1} > Fichiers </span>
        <span className={Styles.rectangles}></span>
        <span className={Styles.buttonT} id={Styles.unique2} >  Données</span> 
        <span className={Styles.rectangles}></span>
        <span  className={Styles.buttonT} id={Styles.unique3} >Affectation</span> 
        <span className={Styles.rectangles}></span>
        <span  className={Styles.buttonT} id={Styles.unique4}>Coordonnées</span>
        <span  className={Styles.rectangles}></span>
        <span  className={Styles.buttonT} id={Styles.unique5} >Compétences</span>
        <span  className={Styles.rectangles}></span>
        <span  className={Styles.buttonT} id={Styles.unique6} >ID.Bancaire</span> 
        <span  className={Styles.rectangles}></span>
        <span  className={Styles.buttonTn} id={Styles.unique7} >Validation</span>
        </div>
        </div>
      {/* <div className={Styles.test}> rien</div> */}
      <div className={Styles.formContainer}>
      <caption ><h3>Formulaire Fichiers</h3></caption>
      <br />
      <form 
      className={Styles.formSection}
      >
          <div className={Styles.divFileInputStyle}>
            <span className={Styles.fileNameTxtStyle}>
              Carte vitale  
            </span>
                <span className={Styles.fileInputContainer}> 
                
                    <span className={Styles.iconContainer}>
                      <label for="getFile1" className={Styles.btnUploadFile} title='Veuillez télécharger la carte vitale'> </label>
                      <input type="file" id='getFile1' accept="image/png, image/jpeg, image/jpg, application/pdf" 
                      
                      onChange={e => setFileValue1(e.target.files[0])}

                      hidden/>
                    </span>
                   <span className={Styles.fileNameContainer}>
                     
                              {
                          fileValue1 ? 
                          <>{fileValue1.name}</>  :
                           <>Aucun fichier choisi</>  
                        } 
                    </span> 
                </span>  
            
              
              </div>
              
              <br />


              <div className={Styles.divFileInputStyle}>
                <span className={Styles.sectionChoix}>

                  <div className={Styles.divFirstChoice}>
                      <span className={Styles.fileNameTxtStyle1}>
                        <input type="radio" id="huey" name="drone" value="huey" className={Styles.test}/>
                        <label for="huey" >&nbsp; Carte nationale d'identité</label>
                      </span>


                      <span className={Styles.fileInputContainer1}> 
                
                          <span className={Styles.iconContainer}>
                            <label for="getFiles" className={Styles.btnUploadFile} title='Veuillez télécharger la Carte nationale d identité'> </label>
                            <input type="file" id='getFiles' accept="image/png, image/jpeg, image/jpg, application/pdf" 
                            
                            onChange={e => setFileValues(e.target.files[0])}

                            hidden/>
                          </span>
                        <span className={Styles.fileNameContainer}>
                          
                                    {
                                fileValues ? 
                                <>{fileValues.name}</>  :
                                <>Aucun fichier choisi</>  
                              } 
                          </span> 
                      </span> 


                  </div>


                  <div className={Styles.divFirstChoice} style={{marginTop:"5px"}}>
                      <span className={Styles.fileNameTxtStyle1}>
                        <input type="radio" id="huey" name="drone" value="huey" className={Styles.test}/>
                        <label for="huey" >&nbsp; Carte de séjour</label>
                      </span>


                      <span className={Styles.fileInputContainer1}> 
                
                          <span className={Styles.iconContainer}>
                            <label for="getFiless" className={Styles.btnUploadFile} title='Veuillez télécharger la carte de séjour'> </label>
                            <input type="file" id='getFiless' accept="image/png, image/jpeg, image/jpg, application/pdf" 
                            
                            onChange={e => setFileValuess(e.target.files[0])}

                            hidden/>
                          </span>
                        <span className={Styles.fileNameContainer}>
                          
                                    {
                                fileValuess ? 
                                <>{fileValuess.name}</>  :
                                <>Aucun fichier choisi</>  
                              } 
                          </span> 
                      </span> 


                  </div>
                 

                 
            
                </span>
              </div>
              <br />
              <div className={Styles.divFileInputStyle}>
            <span className={Styles.fileNameTxtStyle}>
              Curriculum Vitae
            </span>
                <span className={Styles.fileInputContainer}> 
                
                    <span className={Styles.iconContainer}>
                      <label for="getFile4" className={Styles.btnUploadFile} title='Veuillez télécharger le curriculum Vitae'> </label>
                      <input type="file" id='getFile4' accept="image/png, image/jpeg, image/jpg, application/pdf" 
                      
                      onChange={e => setFileValue4(e.target.files[0])}

                      hidden/>
                    </span>
                   <span className={Styles.fileNameContainer}>
                     
                              {
                          fileValue4 ? 
                          <>{fileValue4.name}</>  :
                           <>Aucun fichier choisi</>  
                        } 
                    </span> 
                </span>  
            
              
              </div>
              
              <br />
              <div className={Styles.divFileInputStyle}>
            <span className={Styles.fileNameTxtStyle}>
              Relevé d'Identité Bancaire
            </span>
                <span className={Styles.fileInputContainer}> 
                
                    <span className={Styles.iconContainer}>
                      <label for="getFile5" className={Styles.btnUploadFile} title='Veuillez télécharger le relevé d identité Bancaire '> </label>
                      <input type="file" id='getFile5' accept="image/png, image/jpeg, image/jpg, application/pdf" 
                      
                      onChange={e => setFileValue5(e.target.files[0])}

                      hidden/>
                    </span>
                   <span className={Styles.fileNameContainer}>
                     
                              {
                          fileValue5 ? 
                          <>{fileValue5.name}</>  :
                           <>Aucun fichier choisi</>  
                        } 
                    </span> 
                </span>  
            
              
              </div>
              
              <br />
              
             

              <div id={Styles.divBtnExtraireContainer}>
                    <button  className={Styles.btnExtraire}>
                          Extraire
                    </button>
                </div>
                
                
                
            </form>
          </div>
          <br />
        <div>
        
       <input type="reset" value="Annuler" className={Styles.btnReset}/>
       <input type="submit" value="Données" className={Styles.btnSubmit}/>
        </div>

        </div>
        </div>
    );   
}

export default CardStyle ;
