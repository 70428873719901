import React, { useState, useEffect } from "react";
import {useCookies} from 'react-cookie';
import APIService from './../../../../APIService.js';
import styles from './ContratSection.module.css';
import moment from 'moment';
import TopMenu from "../../SalarieGlobal/TopMenu/TopMenu";
import TopMenuSalarie from "../../TopMenuSalarie.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import LineFilterTableInput from "../../../GlobalComponents/lineFilterTableInput/LineFilterTableInput.jsx";
import PaginationTable from "../../../GlobalComponents/paginationTable/PaginationTable.jsx";
import GeneralCardTest from "../../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import FourthSectionTablePage from "../../../GlobalComponents/fourthSectionTablePage/FourthSectionTablePage.jsx"
import CheckboxTableInput from "../../../GlobalComponents/checkboxTableInput/CheckboxTableInput.jsx";
import SelectNbreLigneTableInput from "../../../GlobalComponents/selectNbreLigneTableInput/SelectNbreLigneTableInput.jsx";
import SecondSectionTablePage from "../../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import DateComponent from "../../../GlobalComponents/dateComponent/DateComponent.jsx";
import CreateComponent from "../../../GlobalComponents/createComponent/CreateComponent.jsx";
import DescriptionComponent from "../../../GlobalComponents/descriptionComponent/DescriptionComponent.jsx";
import { useNavigate } from "react-router-dom";
import ListColumns from "../../../GlobalComponents/listColumns/ListColumns.jsx";
const ContratSection= () => {
  const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculeAvenant', 'id', 'matriculeEmployeAttente']);
  const [avenants, setAvenants] = useState()
  const [avenantsList, setAvenantsList] = useState()
  const [typeContrat, setTypeContrat] = useState("")
  const [post, setPost] = useState("")
  const [dateDebut, setDateDebut] = useState("")
  const [dateFin, setDateFin] = useState("")

  const [nombrePageArray, setNombrePageArray] = useState([])
  const [selectedNombrePageValeur, setSelectedNombrePageValeur] = useState()
  const [nombrePageValeur, setNombrePageValeur] = useState(0)
  const [defaultNombrePageValeur, setDefaultNombrePageValeur] = useState()

  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()


  var varTypeContratRecherche = ""
  var varPostRecherche = ""
  var varDateDebutRecherche = ""
  var varDateFinRecherche = ""

  // TESTTT FILTRE 
  useEffect(() => {
    let lastData=[]
    if(valeurRechercheGlobale && avenants){
      setAvenantsList(avenants.filter(resp => resp.typeContrat_avenant.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
        || resp.post_avenant.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
        || resp.dateDebut_avenant.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
         || resp.dateFin_avenant.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())))
        
    }else{
      setAvenantsList(avenants)
      lastData.push(avenants)
    }
  }, [valeurRechercheGlobale, avenants])

  useEffect(() => {
    if(typeContrat){
      varTypeContratRecherche = typeContrat.toString().toLowerCase()
    }else{
      varTypeContratRecherche = ""
    }

    if(post){
      varPostRecherche = post.toString().toLowerCase()
    }else{
      varPostRecherche = ""
    }

    if(dateDebut){
      varDateDebutRecherche = dateDebut.toString().toLowerCase()
    }else{
      varDateDebutRecherche = ""
    }

    if(dateFin){
      varDateFinRecherche = dateFin.toString().toLowerCase()
    }else{
      varDateFinRecherche = ""
    }
    if(avenants){
      setAvenantsList(avenants.filter(resp => resp.typeContrat_avenant.toString().toLowerCase().includes(varTypeContratRecherche) && resp.post_avenant.toString().toLowerCase().includes(varPostRecherche) && resp.dateDebut_avenant.toString().toLowerCase().includes(varDateDebutRecherche) && resp.dateFin_avenant.toString().toLowerCase().includes(varDateFinRecherche)))
    }
    
  }, [avenants,typeContrat, post, dateDebut, dateFin])
  
  function range(start, end) {
    return Array(end - start + 1). fill(). map((_, idx) => start + idx)
  }

  useEffect(() => {
    if(avenants){
      setAvenantsList(avenants)
    }
  }, [avenants])

//   useEffect(() => {
//     if(avenantsList){
//       setSelectedNombrePageValeur(avenantsList.length)
//       setNombrePageArray(range(1, 1))
//       setNombrePageValeur(avenantsList.length)
//       setDefaultNombrePageValeur(avenantsList.length)
//     }
//   }, [avenantsList])

//   function setNombrePage(num) {

//     setSelectedNombrePageValeur(parseInt(num))
//     setNombrePageArray(range(1, Math.ceil(parseInt(avenantsList.length)/parseInt(num))))
//     setNombrePageValeur(parseInt(num))
    
//   }
//   const [afficherChecked, setAfficherChecked] = useState(false)
//   function transformNumberToArray(n) {
//     const array = [];
//     for (let i = 1; i <= n; i++) {
//         array.push(i);
//     }
//     return array;
// }
//   const handleChangeNombrePage = (e) => {
//     setAfficherChecked(false)
//     setSelectedNombrePageValeur(parseInt(e.target.value))
//     setNombrePageArray(range(1, Math.ceil(parseInt(avenantsList.length)/parseInt(e.target.value))))
//     setNombrePageValeur(parseInt(e.target.value))
    
//   }

// testttt
// Pagination 
const [avenantsList_, setAvenantsList_] = useState([]);

const [index, setIndex] = useState(0)
const [page, setPage] = useState(null)
const [pageTickets, setPageTickets] = useState([])
const [nombrePageArray_, setNombrePageArray_] = useState([])
const [nombrePageValeurFixed, setNombrePageValeurFixed] = useState(null)
const [afficherChecked, setAfficherChecked] = useState(false)


function transformNumberToArray(n) {
    const array = [];
    for (let i = 1; i <= n; i++) {
        array.push(i);
    }
    return array;
}


useEffect(() => {
  if(avenantsList && parseInt(index) !== 0 && page !== 0 )
  
  { 
    let indexInt = parseInt(index);

    setPageTickets(avenantsList.slice(page*indexInt - indexInt, page*indexInt))
    setNombrePageArray_(transformNumberToArray(Math.ceil(avenantsList.length/parseInt(index))))

  } else {

    if( avenantsList && parseInt(index) === 0){
      setPage(1)
      setPageTickets(avenantsList)
      setNombrePageArray_([])
     


    }

  }
  
}, [avenantsList, index, page])


useEffect(() => {
  if(afficherChecked && avenantsList){
    setNombrePageValeurFixed(0);
    setIndex(0);
    
    
  }
   
 }, [afficherChecked])


 const handleChangeNombrePage = (e) => {
  setAfficherChecked(false)
  setNombrePageValeurFixed(parseInt(e.target.value))
  setIndex(e.target.value)
  setPage(1);
}

useEffect(() => {

  if(pageTickets ){

    setAvenantsList_(pageTickets)
  }
  
}, [pageTickets])
// testtttt pagination
useEffect(() => {
  console.log("nbre page :",nombrePageValeur,"nbre pge array",nombrePageArray,
    "selected nbre valeur ",selectedNombrePageValeur)
}, [nombrePageValeur,selectedNombrePageValeur,nombrePageArray])


  const btnNombrePage = (e) => {
    setNombrePageValeur(parseInt(e.target.value))
  }


  function btnNombrePageFunction(pageNum){
    setNombrePageValeur(parseInt(pageNum))
  }


  
  

  useEffect(() => {

    if(token['mytoken']){
      APIService.getAvenants(token)
    .then(resp => setAvenants(resp.filter(resp => resp.matricule_employe === token['matriculeAvenant'])))
    .catch(error => console.log(error))
    }

  }, [token])
useEffect(() => {
 console.log("avenants list ",avenantsList)
}, [avenantsList])

const deleteButton = (id) => {
  if(id && token){
    console.log("delete button",id)

    // re loading popup 
    // setIsLoading(true)
    APIService.SupprimerAvenants(id, token)
    .then(
      () =>  APIService.getAvenants(token)
              .then(resp => 
              {setAvenantsList(resp.filter(resp => resp.matricule_employe === token['matriculeAvenant']));
              }
                )
                .catch(error => console.log(error))
    
    )
  }
}
const modifyButton = (id) => {
  if(id){
    console.log("modify button",id)
  }
}
const hideButton = (id) => {
  if(id){
    console.log("hide button",id)
  }
}
const sendButton = (id) => {
  if(id){
    console.log("send button",id)
  }
}
const headers={id : "id", action_avenant:"Type des Avenants",dateDebut_avenant:"Date début"
// ,test:"Action"
};
const componentsList={ 1: "Send",2:"Delete",3:"Modify",4:"Hide",5:"Delete"}
const methodsList={ 1: sendButton,2:deleteButton,3:modifyButton,4:hideButton,5:deleteButton}
const navigate = useNavigate();
useEffect(() => {
  if( token['matriculeAvenant']){
    console.log("token", token['matriculeAvenant'])

  }else{
    console.log("no token here ")
    navigate('/salarie');
  }
}, [ token['matriculeAvenant']])
const [headerTest, setHeaderTest] = useState({});
const test={ action_avenant:"Type des Avenants",dateDebut_avenant:"Date début"}
 


  return (

    

//     <div className={styles.classCard}>



//       <div className={styles.SectionTop11}>
//           <TopMenuSalarie page="contrat"/>
//       </div>


//         <div className={styles.SectionTop1}>


//           <div className={styles.LaptopTop}>

//         <table style={{width:"100%", color:"goldenrod", backgroundColor:"rgb(218, 165, 32, 20%)", fontWeight:"bold", height:"100%"}}>
//             <tr>

//                  <td>
//                     <span className="col">
//                         <label for="inputTypeContrat" style={{fontWeight:"bold",marginLeft:"40px"}}>Type de contrat :</label>
//                         <input type="Nom" value={typeContrat} onChange={e => setTypeContrat(e.target.value)} id={styles.inputText} placeholder=" " className={styles.inputGlobal}/>
//                     </span>
//                 </td>

//                 <td>
//                     <span className="col">
//                         <label for="inputTypeContrat" style={{fontWeight:"bold",marginLeft:"40px"}}>Type de contrat :</label>
//                         <input type="Nom" value={typeContrat} onChange={e => setTypeContrat(e.target.value)} id={styles.inputText} placeholder=" " className={styles.inputGlobal}/>
//                     </span>
//                 </td>
              
//                 <td>
//                   <span className="col">
//                       <label for="inputEmail4 " style={{fontWeight:"bold"}}>Poste :</label>
//                       <input type="Nom" value={post} onChange={e => setPost(e.target.value)} id={styles.inputText} placeholder="" className={styles.inputGlobal}/>
                
//                   </span>
//                  </td>

//                  {/* <div className={styles.barrehauthaut} > */}
//                  {/* <span> */}
//                 <td>
//                   <span className="col">
//                       <label for="inputEmail4 " style={{fontWeight:"bold"}}>Date début :</label>
//                       <input placeholder="Select date" value={dateDebut} onChange={e => setDateDebut(e.target.value)} type="date" id={styles.inputText} className={styles.inputGlobal}/>
      
//                   </span>
//                 </td>

//                 <td>
//                   <span className="col">
//                       <label for="inputEmail4 " style={{fontWeight:"bold"}}>Date fin :</label>
//                       <input placeholder="Select date" value={dateFin} onChange={e => setDateFin(e.target.value)} type="date" id={styles.inputText} className={styles.inputGlobal}/>
      
//                   </span>
//                 </td>
//                 {/* </span> */}
//                 {/* </div> */}
//             </tr>
//         </table>
//         </div>

//         <div className={styles.SectionMobileTop}>


//         <table style={{width:"100%", color:"rgb(218, 165, 32)", backgroundColor:"rgb(218, 165, 32, 20%)", marginBottom:"0.2vh", fontWeight:"normal", height:"100%"}}>
//             <tr>

//                 <td>
//                     <span className="col">
//                         <label for="inputTypeContrat" className={styles.SectionMobileTop22}>Type de contrat :</label>
//                         <input type="Nom" value={typeContrat} onChange={e => setTypeContrat(e.target.value)} id={styles.inputText} placeholder=" " className={styles.inputGlobal}/>
//                     </span>
//                 </td>
              
//                 <td>
//                   <span className="col">
//                       <label for="inputEmail4 " className={styles.SectionMobileTop22}>Poste :</label>
//                       <input type="Nom" value={post} onChange={e => setPost(e.target.value)} id={styles.inputText} placeholder="" className={styles.inputGlobal}/>
                
//                   </span>
//                  </td>

//             </tr>

//             </table>




//         </div>


//         </div>

//         <div className={styles.SectionTop2}>
//         <div id={styles.idTestTable}>
    
//     <table class="table">
//     <thead>
//           <tr id={styles.idForStyleTh}>
//             <th>Type des Avenants
//             </th>
//             <th>Date début
//             </th>
//           </tr>
         
//         </thead>
//         <tbody>

//             {
//             avenantsList ? avenantsList.slice(nombrePageValeur-selectedNombrePageValeur, nombrePageValeur).map(avn =>
              
//               <tr>
//                 <td> {avn.action_avenant} </td>
//                 <td> {moment(avn.dateDebut_avenant).format("DD/MM/YYYY")}</td>
//               </tr>
              
//               ) : <span> </span>
//           }

//         </tbody>
//       </table>
//       </div>
//         </div>

//         <div className={styles.SectionTop3}>

//         <div className={styles.barrebas}>

//        <span className={styles.leftBottomBar}>
        
//             <span className={styles.inputGlobal1}>
//               <input type="checkbox" className={styles.inputGlobal2} 
//               checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}}
//               /> <span className={styles.inputGlobal77}>Tout afficher	</span>

//             </span>

//             <span style={{width:"auto", fontWeight:"bold"}}>
//                 <label className={styles.inputGlobal4} style={{fontWeight:"bold"}}>Nombre de lignes : </label>
//                                       <select 
//                                       value={nombrePageValeur} onChange={handleChangeNombrePage}
//                                       className={styles.inputGlobal3}
//                                       >
//                                           <option default></option>
//                                           <option value="2">2</option>
//                                           <option value="3">3</option>
//                                           <option value="5">5</option>
//                                           <option value="10">10</option>
//                                           <option value="25">25</option>

//                                       </select>
//               </span>

//        </span>

       
//        <span className={styles.centerBottomBar}>

//             {/* <span className={styles.Numberpage} >
//               {nombrePageArray ? nombrePageArray.map(nbr => <button style={{fontWeight:"bold" ,border:"none", backgroundColor:"transparent"}}  value={selectedNombrePageValeur*nbr} onClick={btnNombrePage}>{nbr}</button>) : <span></span>}
//             </span> */}


// <span className={styles.pagination} >

// {nombrePageArray && nombrePageArray[0] === selectedPageNumber  ? 

// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}} disabled> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>

// : 
// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}}> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>

// }

// { selectedPageNumber > 2 ? <>...</> :<></>}


// {nombrePageArray ? nombrePageArray.map(nbr => 


//  <>
 
 
//  {  


// Math.abs(selectedPageNumber - nbr) <= 2 ? 

// <button className={
//   selectedPageNumber === nbr ? styles.classSelectedPageNumber : styles.classPageNumber
// } value={selectedNombrePageValeur*nbr} onClick={(e) => {btnNombrePage(e); setSelectedPageNumber(nbr)}}>{nbr}</button> : <></>



// }
 
 
//  </>






// ) : <></>} 




// { nombrePageArray && nombrePageArray.length-selectedPageNumber > 2 ? <>...</> :<></>}


// {nombrePageArray && nombrePageArray[nombrePageArray.length - 1] === selectedPageNumber  ? 

// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}} disabled><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}}/> </button>

// : 

// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}}><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}} /> </button>


// }

// </span>

//        </span>
//        <span className={styles.rightBottomBar}>
//           <span style={{fontWeight:"normal"}}>
//             <a href="http://localhost:3000/Avenant" className={styles.creeravenat}>
//             + Créer Avenant
                
//             </a> 
//           </span>
//        </span>

//     {/* <table>
//         <tr>
//           <td style={{textAlign:"left"}}>
//             <span className="col" style={{ width:"100%"}}>
//             <span className={styles.inputGlobal1}>
//               <input type="checkbox" className={styles.inputGlobal2} 
//               checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}}
//               /> Tout afficher	

//             </span>
//             <span className="col" style={{width:"auto", fontWeight:"bold"}}>
//                 <label className={styles.inputGlobal4} style={{fontWeight:"bold"}}>Nombre de lignes : </label>
//                                       <select 
//                                       value={nombrePageValeur} onChange={handleChangeNombrePage}
//                                       className={styles.inputGlobal3}
//                                       >
//                                           <option default></option>
//                                           <option value="2">2</option>
//                                           <option value="3">3</option>
//                                           <option value="5">5</option>
//                                           <option value="10">10</option>
//                                           <option value="25">25</option>

//                                       </select>
//               </span>
//           </span>
//           </td>

//           <td  style={{textAlign:"left", width:"30%"}}>
//             <span className={styles.Numberpage} >
//             {nombrePageArray ? nombrePageArray.map(nbr => <button className="col" style={{border:"none", backgroundColor:"transparent"}}  value={selectedNombrePageValeur*nbr} onClick={btnNombrePage}>{nbr}</button>) : <span></span>}
//             </span>
//           </td>

//           <td style={{textAlign:"right"}}>
//           <span className="col" style={{fontWeight:"normal"}}>
//             <a href="http://localhost:3000/Avenant" className={styles.creeravenat}>
//             + Créer Avenant
                
//             </a> 
//           </span>
//           </td>
//         </tr>

//       </table>  */}
//         </div>
//         </div>

    
// </div>
<div>
<div className={styles.web}>

<GeneralCardTest 
section1={
<TopMenuSalarie page="contrat" />
} 
section2={
<SecondSectionTablePage 
nbreComponents={5}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={avenantsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />}
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
/>}
section3={
<>
{
  (avenantsList_ && headerTest) && 
    <TableComponent data={avenantsList_} cols={headerTest} 
    addAction={true} 
    contentAction={methodsList}
    componentsList={componentsList}
    clickableRow={false}

/>  
}
</>    
 } 
section4={
          <FourthSectionTablePage 
          component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
          component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>} 
          component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />}
          component4={<CreateComponent text={"Créer Avenant"} link={"http://localhost:3000/Avenant"} />}
          /> 
        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={styles.tablet}>
<GeneralCardTest 
section1={
<TopMenuSalarie page="contrat" />
}  
section2={
<SecondSectionTablePageTablet 
nbreComponents={6}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={avenantsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
/>}
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
    (avenantsList_ && headerTest) && 
    <TableComponent data={avenantsList_} cols={headerTest} 
    addAction={true} 
    contentAction={methodsList}
    componentsList={componentsList}
    clickableRow={false}

  />  
  }
</div>  
                } 
section4={
        <FourthSectionTablePageTablet 
        // component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
        //   } 
          component1={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>} 
          component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />}
          component3={<CreateComponent text={"Créer Avenant"} link={"http://localhost:3000/Avenant"} />}
        /> 

        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={styles.mobile}>
<GeneralCardTest 
 section1={
<TopMenuSalarie page="contrat" />
}  
section2={
<SecondSectionTablePageMobile 
nbreComponents={7}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={avenantsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}

component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
component7={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
/>

}  
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
     (avenantsList_ && headerTest) && 
     <TableComponent data={avenantsList_} cols={headerTest} 
     addAction={true} 
     contentAction={methodsList}
     componentsList={componentsList}
     clickableRow={false}
 
  />  
  }
</div>  
                } 
section4={
        <FourthSectionTablePageMobile 
        component1={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
        component2={<CreateComponent text={"Créer Avenant"} link={"http://localhost:3000/Avenant"} />}

        /> 
        } 
NbreSections={4} 
prctgeSection1={7}
prctgeSection2={6} 
prctgeSection3={81}
prctgeSection4={6}
/>
</div>
<div className={styles.mobileInversePetit}>
<GeneralCardTest 
section1={
  <TopMenuSalarie page="contrat" />
} 
section2={
<SecondSectionTablePageMobileInversePetit 
nbreComponents={7}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={avenantsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}

component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
component7={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
/>

}  
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
     (avenantsList_ && headerTest) && 
     <TableComponent data={avenantsList_} cols={headerTest} 
     addAction={true} 
     contentAction={methodsList}
     componentsList={componentsList}
    clickableRow={false}

  />  
  }
</div>
                } 
section4={
        <FourthSectionTablePageMobileInversePetit 
        component1={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
        component2={<CreateComponent text={"Créer Avenant"} link={"http://localhost:3000/Avenant"} />}
        
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div>
<div className={styles.mobileInverseGrand}>
<GeneralCardTest 
section1={
<TopMenuSalarie page="contrat" />}
section2={
<SecondSectionTablePageMobileInverseGrand 
nbreComponents={6}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={avenantsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
/>

} 
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
   (avenantsList_ && headerTest) && 
   <TableComponent data={avenantsList_} cols={headerTest} 
   addAction={true} 
   contentAction={methodsList}
   componentsList={componentsList}
   clickableRow={false}

  />  
  }
</div>  
                } 
section4={
        <FourthSectionTablePageMobileInverseGrand  
        component1={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>} 
          component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />}
          component3={<CreateComponent text={"Créer Avenant"} link={"http://localhost:3000/Avenant"} />}
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div> 
</div>
    
  );
  
};

export default ContratSection;