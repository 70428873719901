import React from "react";
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from '../../../APIService.js';

import styles from './UniteSection.module.css';
import {useNavigate} from 'react-router-dom';






const HierarchicalDisplay = ({setIdEdit, setEditDetails, data, node, isTopLevel = false }) => {
    const [isOpen, setIsOpen] = useState(isTopLevel);
    
    const hasChildren = node && typeof node === 'object' && Object.keys(node).length > 0;
    const handleEditClick = (unit) => {
        setIdEdit(unit.id);
        setEditDetails(unit);
       
    };
    return (
        <div>
            {hasChildren && (
                <button onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <>&#x2B11;</> : <>&#x2B0E;</>}
                </button>
            )
            
           
        
        }
            {isOpen && hasChildren && (
                <div style={{ marginLeft: '40px' }}>
                    {Object.keys(node).map(key => (
                        <div key={key}>
                           
                            {
                                data && data.filter(resp => resp.id === parseInt(key)).map(resp => 
                                    
                                    <div>
                                        {resp.nom} <button onClick={() => handleEditClick(resp)}>e</button>
                                        
                                    </div> 
                                    )
                            }
                            <HierarchicalDisplay setIdEdit = {setIdEdit} setEditDetails={setEditDetails} data = {data} node={node[key]} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};







function UniteSection() {
    const [token, setToken, removeToken] = useCookies(['mytoken']);
    const [listUnite, setListUnite] = useState(null)
    const [result, setResult] = useState(null)
    const [showAdd, setShowAdd] = useState(false)
    const [nom, setNom] = useState(null)
    const [newNom, setNewNom] = useState(null)
    const [unite, setUnite] = useState(null)
    const [idEdit, setIdEdit] = useState(null);
    const [editDetails, setEditDetails] = useState(null);

    
    let navigate = useNavigate()

   

    useEffect(() => {

        if(token['mytoken']){
            APIService.getUniteAdministration(token)
            .then(resp => setListUnite(resp))
        }
      
    }, [token['mytoken']])


  useEffect(() => {

    if(listUnite){
        let maxNiveau = 0
        var racine = {}
        var racine_1 = {}

        for(let i in listUnite){
            if(listUnite[i]['niveau'] > maxNiveau) maxNiveau = parseInt(listUnite[i]['niveau']);
        }

       
    
   




        for(let i = maxNiveau; i >0; i--){
            let n = i
            let n_1 = i - 1



            if(Object.keys(racine_1).length === 0){

                listUnite.filter(
                    resp => resp.niveau === n
                ).map(
                    resp => racine[resp.id] = {}
                )
        
                listUnite.filter(
                    resp => resp.niveau === n_1
                ).map(
                    resp => racine_1[resp.id] = {}
                )


                
            } 

            
            
            else {
                
                racine = racine_1
                racine_1 = {}

                listUnite.filter(
                    resp => resp.niveau === n_1
                ).map(
                    resp => racine_1[resp.id] = {}
                )

                


            }

       
                for(let v in Object.keys(racine)){

                    for(let v_1 in Object.keys(racine_1)){
        
                    
        
        
        
                        if(parseInt(listUnite.filter(resp => resp.id === parseInt(Object.keys(racine)[v])).map(resp => resp.unite)[0]) === parseInt(Object.keys(racine_1)[v_1])) {
                           
        
                            racine_1[parseInt(Object.keys(racine_1)[v_1])][parseInt(Object.keys(racine)[v])] = racine[parseInt(Object.keys(racine)[v])]

        
        
                        }
    
                        
        
                    }
        
      
            }

            



            
        }


        console.log("racine - 1 : ", racine_1)


        setResult(racine_1)


       


        

        

        




       
       
    }
    
  }, [listUnite])



  
  


   const postData = () => {
    if(token && nom){

        let formData = new FormData();

        formData.append('nom', nom)
        if(unite){
                
                formData.append('unite', parseInt(unite))
                formData.append('niveau', parseInt(listUnite.filter(resp => resp.id === parseInt(unite)).map(resp => resp.niveau)) + 1)
            } else {
                
                formData.append('niveau', 0)

            }



        APIService.postUniteAdministration(formData, token)
        .then(
            () => APIService.getUniteAdministration(token)
                    .then(
                        resp => setListUnite(resp)
                        )

        )
    }
   }



    

const postDataa = () => {
    if (idEdit) {
        let formData = new FormData();
        formData.append('nom', newNom );

        APIService.putUniteAdministration(idEdit, formData, token)
            .then(() => {
                APIService.getUniteAdministration(token)
                    .then(resp => {
                        setListUnite(resp);
                        setIdEdit(null);
                        setEditDetails(null);
                        setNewNom(null); 
                    });
            })
            .catch(error => {
                console.error('Erreur lors de la mise à jour : ', error);
            });
    } else {
        console.error('Token, nom ou idEdit manquant.');
    }
};


    
      

      
     


    
   
    
    
  return (
    <div className={styles.classCard}>


       


        <div className={styles.classTable}>


            
                {
                    listUnite && result && 
                    <HierarchicalDisplay setIdEdit = {setIdEdit} setEditDetails={setEditDetails} data = {listUnite} node={result} isTopLevel={true} />

                }

                


           

            

        </div>

        <div>
            <button onClick={() => setShowAdd(!showAdd)}>Ajouter</button>
                {idEdit ?
                 <>edit : {idEdit}</>
                 : 
                 <></>}
                { editDetails && 
                    <div>
                        Nom : <input type="text" value={newNom || editDetails.nom } onChange={e => setNewNom(e.target.value)} />
                        
                        <button onClick={postDataa}>Submit</button>
                    </div>
                }
            
            {
                showAdd  && 
                <div>
                    Nom : <input type="text" value={nom} onChange={e => setNom(e.target.value)} />
                    Unite : <select value={unite} onChange = {e => {setUnite(e.target.value)}}>
                                <option value=""></option>
                                {
                                    listUnite &&
                                    listUnite.map(
                                        resp => <option value={resp.id}>{resp.nom}</option>
                                    )
                                }
                            </select>

                            <button onClick = {postData}>Submit</button>
                </div>
            }

        </div>
       

    </div>
  )
}


export default UniteSection