import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import FraisProfessionnelsSection from "./FraisProfessionnelsSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function FraisProfessionnels(){

    return(
        <div>
            <NavBarGlobal  section = {<FraisProfessionnelsSection />}/>
        </div>
    )
}

export default FraisProfessionnels;