import React from "react";
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from '../../../APIService.js';

import styles from './TypeContratSection.module.css';
import {useNavigate} from 'react-router-dom';




function TypeContratSection() {
    const [token, setToken, removeToken] = useCookies(['mytoken']);
    const [listTypeContrat, setListTypeContrat] = useState(null)
    const [showAjouter, setShowAjouter] = useState(false)
    const [nom, setNom] = useState(null)
    const [idModif, setIdModif] = useState(null); 
    const [nomModif, setNomModif] = useState(null); 


    let navigate = useNavigate()

    useEffect(() => {

        if(token['mytoken']){
            APIService.getTypeContrat(token)
            .then(resp => setListTypeContrat(resp))
        }
      
    }, [token['mytoken']])


   
    

    const btnShowAjouter = () => {
   
    if (showAjouter) {
        setShowAjouter(false);
        setIdModif(null);  
        setNomModif(null);
      } else {
        setShowAjouter(true);
      }
    };

    function handelAjouterTypeContrat(e){
        e.preventDefault();

        if (nom) {
          let formData = new FormData();
          formData.append('nom', nom);
      
              APIService.postTypeContrat(formData, token).then(() => {
              APIService.getTypeContrat(token).then((resp) => setListTypeContrat(resp));
            })
            .then(

                () =>
                {   
                    setNom('');
                    setIdModif(null);
                    setShowAjouter(false);
                }
            )          
      
          
        } else {
          console.log('Le nom est obligatoire');
        }
      };
      

      
        function handelModifierTypeContrat(e){
            e.preventDefault();

        if (nom) {
            let formData = new FormData();
            formData.append('nom', nom);

            APIService.putTypeContrat(idModif, formData, token)
              .then(() => {
                APIService.getTypeContrat(token).then((resp) => setListTypeContrat(resp));
              })
              .then(

                () =>
                {   
                    setNom('');
                    setIdModif(null);
                    setShowAjouter(false);
                }
            )
            
      }

    }


    
    const handelSupprimerTypeContrat = (id) => {
        if (id) {
          APIService.deleteTypeContrat(id, token).then(() => {
            APIService.getTypeContrat(token).then((resp) => setListTypeContrat(resp));
          });
        }
      };
    
   
    
    
  return (
    <div className={styles.classCard}>


        <div className={styles.classMenu}>

             <button  onClick={() => navigate('/donnees')} style={{cursor:"pointer"}}>Sexe</button>
             <button onClick={() => navigate('/languesAdministartion')} style={{cursor:"pointer"}} >Langue</button>
             <button  onClick={() => navigate('/situationFamilialeAdministration')} style={{cursor:"pointer"}} >sitiationfamiliale</button>
             <button className={styles.classBtnActive}  >TypeContrats</button>
             <button  onClick={() => navigate('/jours')} style={{cursor:"pointer"}}>jours</button>
             <button   onClick={() => navigate('/temps')} style={{cursor:"pointer"}}>temps</button>
             <button  onClick={() => navigate('/banques')} style={{cursor:"pointer"}}>banques</button>
             <button  onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button>
             <button  onClick={() => navigate('/departement')} style={{cursor:"pointer"}}>Departement</button>
             <button  onClick={() => navigate('/poste')} style={{cursor:"pointer"}}>Poste</button>







        </div>


        <div className={styles.classTable}>

            <table className={styles.tableStyle}>
                <thead>
                    <tr>

                        <th>id</th>
                        <th>nom</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {
                        listLangue && 

                        listLangue.map(
                            lg => 

                            <tr>
                                    <td>{lg.id}</td>
                                    <td>{lg.nom}</td>
                                    <td>
                                        <button onClick = {() => handelSupprimerLangue(lg.id)}>Supprimer</button>
                                        <button >Modifier</button>


                                    </td>
                            </tr>


                        )


                    } */}

{listTypeContrat &&
          listTypeContrat.map((lg) => (
            <tr key={lg.id}>
              <td>{lg.id}</td>
              <td>{lg.nom}</td>
              <td>
                <button onClick={() => handelSupprimerTypeContrat(lg.id)}>Supprimer</button>
                <button onClick={() => {setIdModif(lg.id); setNom(lg.nom); setShowAjouter(true)}}>Modifier</button>
              </td>
            </tr>
          ))}
                  
                </tbody>
            </table>

        </div>
        <div className={styles.classAjouter}>
            <div>
                <button onClick = {btnShowAjouter} >Ajouter</button>
            </div>

            {
                showAjouter && 

                <div>

                        <form  onSubmit = {e => {
                        if(idModif){
                            handelModifierTypeContrat(e)
                        } else {handelAjouterTypeContrat(e)}
                                             } }>
                        Nom : <input type="text" value={nom} onChange = {e => setNom(e.target.value)} />
                        </form>
                    <div>
                    
                        <button onClick = { e =>

                            idModif ?

                            handelModifierTypeContrat(e)
                            :
                            handelAjouterTypeContrat(e)
                            
                            
                            }>
                               
                                {
                                    idModif ? <>Modifier</>
                                    : <>Ajouter</>
                                }
                            </button>
                            
                    </div>

               </div>
            }

        </div>

    </div>
  )
}


export default TypeContratSection