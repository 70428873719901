

    // PosteSection.js

import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import APIService from '../../../APIService.js';
import styles from './PosteSection.module.css';
import {useNavigate} from 'react-router-dom';


function PosteSection() {
  const [token] = useCookies(['mytoken']);
  const [listPoste, setListPoste] = useState(null);
  const [listDepartement, setListDepartement] = useState(null);
  const [showAjouter, setShowAjouter] = useState(false);
  const [idModif, setIdModif] = useState(null);
  const [nom, setNom] = useState(null);
  const [departementIds, setDepartementIds] = useState([]);

  let navigate = useNavigate()

  useEffect(() => {

    console.log("departementIds : ", departementIds)


  }, [departementIds])
  useEffect(() => {
    if (token['mytoken']) {
      APIService.getPoste(token)
        .then((resp) => setListPoste(resp))
        .catch((error) => console.error('Erreur lors de la récupération des données :', error));

      APIService.getDepartement(token)
        .then((resp) => setListDepartement(resp))
        .catch((error) => console.error('Erreur lors de la récupération des établissements :', error));
    }
  }, [token['mytoken']]);

  const btnShowAjouter = () => {
    if (showAjouter) {
      setShowAjouter(false);
      setIdModif(null);
    } else {
      setShowAjouter(true);
    }
  };

  const handelAjouterPoste = (e) => {
    e.preventDefault();

    if (nom && departementIds.length > 0) {
      console.log("Bonjour : ", departementIds.join(','))
      let formData = new FormData();
      formData.append('nom', nom);
      formData.append('id_departement', departementIds.join(','))
      // etablissementIds.forEach((id) => formData.append('etablissements', id));
      APIService.postPoste(formData, token)
        .then(() => APIService.getPoste(token))
        .then((resp) => setListPoste(resp))
        .then(() => {
          setNom('');
          setDepartementIds([]);
          setIdModif(null);
          setShowAjouter(false);
        })
        .catch((error) => console.error('Erreur lors de l\'ajout du département :', error));
    } else {
      console.log('Le nom et au moins un établissement sont obligatoires');
    }
  };

  const handelModifierPoste = (e) => {
    e.preventDefault();

    if (nom && departementIds.length > 0) {
      let formData = new FormData();
      formData.append('nom', nom);
      formData.append('id_departement', departementIds.join(','))

      APIService.putPoste(idModif, formData, token)
        .then(() => APIService.getPoste(token))
        .then((resp) => setListPoste(resp))
        .then(() => {
          setNom('');
          setDepartementIds([]);
          setIdModif(null);
          setShowAjouter(false);
        })
        .catch((error) => console.error('Erreur lors de la modification du département :', error));
    }
  };

  const handelSupprimerPoste = (id) => {
    if (id) {
      APIService.deletePoste(id, token)
        .then(() => APIService.getPoste(token))
        .then((resp) => setListPoste(resp))
        .catch((error) => console.error('Erreur lors de la suppression du département :', error));
    }
  };

  return (
    <div className={styles.classCard}>
      
      <div className={styles.classMenu}>

          <button  onClick={() => navigate('/donnees')} style={{cursor:"pointer"}}>Sexe</button>
          <button  onClick={() => navigate('/languesAdministartion')} style={{cursor:"pointer"}} >Langue</button>
          <button  onClick={() => navigate('/situationFamilialeAdministration')} style={{cursor:"pointer"}} >sitiationfamiliale</button>
          <button  onClick={() => navigate('/TypeContrats')} style={{cursor:"pointer"}} >TypeContrats</button>
          <button  onClick={() => navigate('/jours')} style={{cursor:"pointer"}}>jours</button>
          <button  onClick={() => navigate('/temps')} style={{cursor:"pointer"}}>temps</button>
          <button  onClick={() => navigate('/banques')} style={{cursor:"pointer"}}>banques</button>
          <button   onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button>
          {/* <button  onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button> */}
          <button  onClick={() => navigate('/departement')} style={{cursor:"pointer"}}>Departement</button>
          <button className={styles.classBtnActive}>Poste</button>
          <button   onClick={() => navigate('/nationalite')} style={{cursor:"pointer"}} >Nationalite</button>
          <button onClick={() => navigate('/pays')} style={{cursor:"pointer"}} >pays</button>

        </div>
      <div className={styles.classTable}>
        <table className={styles.tableStyle}>
          <thead>
            <tr>
              <th>id</th>
              <th>Nom du Poste</th>
              <th>Departement</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listPoste &&
              listPoste.map((poste) => (
                <tr key={poste.id}>
                  <td>{poste.id}</td>
                  <td>{poste.nom}</td>
                  <td>
                    {
                      listDepartement &&
                      listDepartement.filter(resp => 
                        poste.id_departement.split(',').map(resp => parseInt(resp)).includes(parseInt(resp.id)) === true
                        ).map(
                          resp =>
                          
                          
                            <>
                            - {resp.nom}
                            </>
                            
                          
                          
                        )
                    }

                  </td>
                  <td>
                    <button onClick={() => handelSupprimerPoste(poste.id)}>Supprimer</button>
                    <button onClick={() => {
                      setIdModif(poste.id);
                      setNom(poste.nom);
                      setDepartementIds(poste.id_departement.split(',').map(resp => parseInt(resp)));
                      setShowAjouter(true);
                    }}>Modifier</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className={styles.classAjouter}>
        <div>
          <button onClick={btnShowAjouter}>Ajouter</button>
        </div>

        {showAjouter && (
          <div>
            <form
              onSubmit={(e) => {
                if (idModif) {
                  handelModifierPoste(e);
                } else {
                  handelAjouterPoste(e);
                }
              }}
            >
              Nom : <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} />
              <br />
              Établissements :
              {listDepartement && 
              
              listDepartement.map(
                resp => <>

                <input type="checkbox" onChange={() => {
                  
                  if(departementIds.includes(resp.id)){

                    setDepartementIds(departementIds.filter(i => i !== resp.id))

                  } else {

                    setDepartementIds([...departementIds, resp.id])

                  }
              
              }} 

              checked={departementIds.includes(resp.id)}
              /> {resp.nom} <br />
                </>
              )}
            </form>
            <div>
              <button
                onClick={(e) =>
                  idModif
                    ? handelModifierPoste(e)
                    : handelAjouterPoste(e)
                }
              >
                {idModif ? <>Modifier</> : <>Ajouter</>}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PosteSection;
