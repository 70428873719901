import React from "react";
import SalarieNavBar from '../SalarieGlobal/NavBar/SalarieNavBar.jsx';


// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import NavBarGlobal from "./../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";

import DonneesPersonnellesSection from "./DonneesPersonnellesSection.jsx";
import Styles from './donneesPersonnellesSection.module.css';




const DonneesPersonnel = () => {
  
 
  return (

    <div>

    <NavBarGlobal section = {<DonneesPersonnellesSection />} />
   
    </div>

    
  );
  
};
     
export default DonneesPersonnel;