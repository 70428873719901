import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import UniteSection from './UniteSection.jsx';



export const Unite = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<UniteSection />} />
    
   
 </div>
)}


export default Unite