import React from 'react';
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../../APIService.js';
import styles from './NavBarMobile.module.css';
import 'reactjs-popup/dist/index.css';

import Women from "./../../../img/women.jpg";
import { FaBell } from "react-icons/fa";


// import "./admin.css";
import moment from 'moment';
import 'moment/locale/fr' 

moment.locale('fr')


function NavBarMobile(props) {



    const [token, setToken, removeToken] = useCookies(['mytoken', 'username', 'matriculeManager', 'matriculeEmployeAttente', 'matriculeActuel'])
    const [users, setUsers] = useState([])
    const [afficher, SetAfficher] = useState(false)

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          window.location.href = '/'
          localStorage.clear()
  
      }
    }, [token])
    
    
    useEffect(() => {
        APIService.getUsers(token['mytoken'])
      .then(resp => setUsers(resp))
    },token['mytoken'])


    useEffect(() => {

        
        if(users.length){
          SetAfficher(true)
        }
          
        
    }, [users])


   
  
    const LogoutBtn = () => {
      removeToken(['mytoken'])
      localStorage.clear()
  
    }
  

    
      
     



    
    



  // Affichage des notifications 
  const [allUsers, setAllUsers] = useState([])
  const [employeCible, setEmployeCible] = useState([])

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          // navigate('/')
          window.location.href = '/'
          localStorage.clear()
      }
    }, [token])




    useEffect(() => {
      APIService.getUsers(token['mytoken'])
    .then(resp => setAllUsers(resp))
    },token['mytoken'])

    useEffect(() => {

      APIService.getEmploye(APIService.getEmployeId(allUsers, token), token)
      .then(resp => setEmployeCible(resp))

    },[allUsers])


  const [allNotificationsBar, setAllNotificationsBar] = useState([])
  const [etatNotification, setEtatNotification] = useState(false)


  useEffect(() => {

    APIService.getAllNotifications(token)
    .then(resp => setAllNotificationsBar(resp))


  },[etatNotification, token])


 


  const btnNotificationEvent = (e) => {

    allNotificationsBar.filter(nots => nots.id === parseInt(e.target.name)
      ).map(
      
      resp => APIService.ModifierNotification(resp.id, {username:resp.username,matricule_employe:resp.matricule_employe,notification:resp.notification,dateNotification:resp.dateNotification,statutNotification:resp.statutNotification, estVu:true}, token)
    )

    setToken('matriculeEmployeAttente', e.target.value)
    window.location.href = '/saisie'

  }




  
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false)
  const [showProfile, setShowProfile] = useState(false)


  function showMenu() {
    if(show){
      setShow(false)
      setShowAdministrationMenu(false)

      setHomeStyle({backgroundColor:"#6D6C6A", color:"white"})


    }else{
      setShow(true)


      setShowProfile(false)
      setShowNotification(false)

      setHomeStyle({backgroundColor:"white", color:"goldenrod"})




    }
  }


  function showMenuNotification() {
    if(showNotification){
      setShowNotification(false)

      setHomeStyle({backgroundColor:"#6D6C6A", color:"white"})



    }else{
      setShowNotification(true)

      setHomeStyle({backgroundColor:"white", color:"goldenrod"})


      setShowProfile(false)
      setShow(false)

    }
  }


  function showMenuProfile() {
    if(showProfile){
      setShowProfile(false)

      setHomeStyle({backgroundColor:"#6D6C6A", color:"white"})



    }else{
      setShowProfile(true)
      

      setHomeStyle({backgroundColor:"white", color:"goldenrod"})


      setShowNotification(false)
      setShow(false)



    }
  }

  const [showAdministrationMenu, setShowAdministrationMenu] = useState(false);
  const [showPlannificationMenu, setShowPlannificationMenu] = useState(false);
  const [showGestionMenu, setShowGestionMenu] = useState(false);

  const [styleAdministration, setStyleAdministration] = useState()
  const [stylePlanification, setStylePlanification] = useState({backgroundColor:"transparent"})
  const [styleGestion, setStyleGestion] = useState({backgroundColor:"transparent"})

  function showMenuAdministraion() {
    if(showAdministrationMenu){
      setShowAdministrationMenu(false)
      setStyleAdministration({})


    }else{
      setShowAdministrationMenu(true)
      setStyleAdministration({backgroundColor:"#ddd", color:"black"})

      setShowPlannificationMenu(false)
      setStylePlanification({})

      setShowGestionMenu(false)
      setStyleGestion({})


    }
  }


  function showMenuPlannification() {
    if(showPlannificationMenu){
      setShowPlannificationMenu(false)
      setStylePlanification({})


    }else{
      setShowPlannificationMenu(true)
      setStylePlanification({backgroundColor:"#ddd", color:"black"})

      setShowAdministrationMenu(false)
      setStyleAdministration({})

      setShowGestionMenu(false)
      setStyleGestion({})
      
    }
  }

  function showMenuGestion() {
    if(showGestionMenu){
      setShowGestionMenu(false)
      setStyleGestion({})


    }else{
      setShowGestionMenu(true)
      setStyleGestion({backgroundColor:"#ddd", color:"black"})


      setShowPlannificationMenu(false)
      setStylePlanification({})

      setShowAdministrationMenu(false)
      setStyleAdministration({})
    }
  }



const [homeStyle, setHomeStyle] = useState({backgroundColor:"#6D6C6A", color:"white"});


  return (
    <div >
      <div id={styles.navBar}>
            <span className={styles.element} style={homeStyle}>
              <i class="fa fa-home" aria-hidden="true"></i>
            </span>
            {
              show ?
              <span className={styles.element} onClick={() => showMenu()} style={{backgroundColor:"rgb(218, 165, 32, 20%)"}}>
              <i class="fa fa-bars"></i>
            </span>:
            <span className={styles.element} onClick={() => showMenu()}>
            <i class="fa fa-bars"></i>
          </span>
            }

{
  showProfile ?
          <span className={styles.elementNotif} onClick={() => showMenuProfile()} style={{backgroundColor:"rgb(218, 165, 32, 20%)"}}>
                                      <img src={Women} alt="" style={{width:"20px", height:"20px", borderRadius:"50%"}}/>
          </span>

          : <span className={styles.elementNotif} onClick={() => showMenuProfile()}>
                      <img src={Women} alt="" style={{width:"20px", height:"20px", borderRadius:"50%"}}/>
            </span>
}

          

          {
            showNotification ?

            <span className={styles.elementNotif} onClick={() => showMenuNotification()} style={{backgroundColor:"rgb(218, 165, 32, 20%)"}}>

            <FaBell className={styles.iconProfileStyle} />
            <sup id={styles.supStyle}>{allNotificationsBar.filter(nots => nots.username === token['username']).length}</sup>

            </span> :

          <span className={styles.elementNotif} onClick={() => showMenuNotification()}>

          <FaBell className={styles.iconProfileStyle} />
          <sup id={styles.supStyle}>{allNotificationsBar.filter(nots => nots.username === token['username']).length}</sup>

          </span>
          }
          
          


          

      </div>


<div className={styles.navBarMobileStyle}>
{
  showProfile ? 
  
          <div 
          id={styles.itemsMenu}
          className={styles.gridContainer}
          
          // className="row"
          >
              <span id={styles.colClassProfilePhoto} className={styles.gridItem}>
                <img src={Women} alt="" id={styles.imgProfileStyle}/>
              </span>
              <span id={styles.colClassProfileContenu} className={styles.gridItem}>
              <div style={{textAlign:"center", fontSize:"12px"}}>
                <br />
                {employeCible.prenom_employe}, {employeCible.nom_employe} 
                <br />
                {/* <hr /> */}
                Etablissement : {employeCible.etablissement_employe} 
                <hr />
                <span>
                  <button className={styles.btnDeconnect} onClick={LogoutBtn}>Se déconnecter</button>
                </span>
                                      
              </div>
              </span>
              
          </div>
  
  
  :<></>
}

      


      {
        showNotification ?

        <div id={styles.itemsMenuNotification}>
          <div className={styles.notifCont}>
          <div className={styles.containerOfNotifications} style={{fontSize:"12px"}}>
                                  
    
                                      
    
                                  {allNotificationsBar.filter(nots => nots.username === token['username']).sort((a, b) => a.dateNotification > b.dateNotification ? -1:1).map(resp => <>
                                  {resp.estVu === false ? 

                                  <button className={styles.btnPrincipaleNonVue} 
                                  style={{fontSize:"12px"}}
                                  name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                    >
                                    <span className={styles.textBtnNotif} style={{fontSize:"12px"}}>
                                      <br />
                                        {resp.notification}
                                      
                                      <hr/>
                                      {resp.dateNotification ? <>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</>
                                      : <>XX/XX/XXXX xx:xx:xx</>}


                                    </span>
                                  </button>
                                  :
                                  <button className={styles.btnPrincipaleVue} name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                  style={{fontSize:"12px"}}
                                  >
                                    <span className={styles.textBtnNotif}>
                                      <br />
                                    {resp.notification}
                                  <hr/>
                                  {resp.dateNotification ? <span>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</span>
                                  : <span>XX/XX/XXXX xx:xx:xx</span>}
                                  </span>
                                  </button> }
                                  <br />
                                  </>)}
                               
                              </div> 
          </div>

         

        </div>:
        <></>
      }

      
      {show ? 
            <div id={styles.itemsMenu}>
              <button className={styles.btnMenu} style={styleAdministration} onClick={() => showMenuAdministraion()}>Administration du personnel
              {
                showAdministrationMenu ? <>
              <i class="fa fa-angle-up" aria-hidden="true" style={{float:"right"}}></i>

                </>:<>
              <i class="fa fa-angle-down" aria-hidden="true" style={{float:"right"}}></i>
                
                </>
                }              </button>
              {
                showAdministrationMenu ? <>
                <div className="row" id={styles.classDivItemsMenu}>
                  <a className="col" href="ok">Embauche</a>
                  <a className="col" href="ok">Salarié</a>
                  <a className="col" href="ok">Gestion de temps</a>
                </div>
                
                </>
                :<></>
              }
              
              <button className={styles.btnMenu}  style={stylePlanification} onClick={() => showMenuPlannification()}>Plannification
              {
                showPlannificationMenu ? <>
              <i class="fa fa-angle-up" aria-hidden="true" style={{float:"right"}}></i>

                </>:<>
              <i class="fa fa-angle-down" aria-hidden="true" style={{float:"right"}}></i>
                
                </>
                }

              </button>
              {
                showPlannificationMenu ? <>
                <div className="row" id={styles.classDivItemsMenu}>
                  <a className="col" href="ok">Embauche</a>
                  <a className="col" href="ok">Salarié</a>
                  <a className="col" href="ok">Gestion de temps</a>
                </div>
                
                </>
                :<></>
              }
              <button className={styles.btnMenu}  style={styleGestion} onClick={() => showMenuGestion()}>Gestion de temps

              {
                showGestionMenu ? <>
              <i class="fa fa-angle-up" aria-hidden="true" style={{float:"right"}}></i>

                </>:<>
              <i class="fa fa-angle-down" aria-hidden="true" style={{float:"right"}}></i>
                
                </>
                }
              
              </button>
              {
                showGestionMenu ? <>
                <div className="row" id={styles.classDivItemsMenu}>
                  <a className="col" href="ok">Embauche</a>
                  <a className="col" href="ok">Salarié</a>
                  <a className="col" href="ok">Gestion de temps</a>
                </div>
                
                </>
                :<></>
              }

            </div>
            :<></>
          }

</div>
        

        <div id={styles.idSection} className="bodback">
              {props.section}

        </div>
    </div>
  )
}

export default NavBarMobile