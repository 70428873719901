import React, { useState, useEffect } from "react";
import Styles from './ScrollableButtons.module.css';

function ScrollableButtons({ methods, ids, buttonsNames }) {
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  // console.log(" and methods ",methods)
  // console.log("and lastly ids",ids)
  // console.log("btns nms",buttonsNames)
  const btnILeft = () => {
    // console.log("left arrow clicked");
    setVisibleStartIndex(Math.max(0, visibleStartIndex - 1));
  };

  const btnIRight = () => {
    setVisibleStartIndex(Math.min(Object.keys(buttonsNames).length - 3, visibleStartIndex + 1));
    // console.log("right arrow clicked");

  };

  const handleClick = (index) => {
    // console.log("button clicked", index);
    const newVisibleStartIndex = Math.max(0, Math.min(index - 1, Object.keys(buttonsNames).length - 3));
    setVisibleStartIndex(newVisibleStartIndex);
    const clickedMethod = Object.values(methods)[index];
    if (clickedMethod) {
      clickedMethod();
    }
  };

  // useEffect(() => {
  //   console.log("visible start index", visibleStartIndex);
  // }, [visibleStartIndex]);

  return (
    <div id={Styles.divHeaderBtn}>
      <button onClick={btnILeft} className={Styles.arrowButtonLeft}>
        <i className='fas fa-angle-left'></i>
      </button>
      {buttonsNames && Object.values(buttonsNames).slice(visibleStartIndex, visibleStartIndex + 3).map((name, index) => (
        <div key={index} className={Styles.buttons}>
          <button className={Styles.eachButton} onClick={() => handleClick(index + visibleStartIndex)} id={Object.values(ids)[index + visibleStartIndex]}>
            {name}
          </button>
        </div>
      ))}
      <button onClick={btnIRight} className={Styles.arrowButtonRight}>
        <i className='fas fa-angle-right'></i>
      </button>
    </div>
  );
}

export default ScrollableButtons;

