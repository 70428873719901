import React from "react";
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../APIService';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import StylesM from './DashboardSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopMenu from "../../Salarie/SalarieGlobal/TopMenu/TopMenu";
import GestionTempsCard from "./../GestionDeTempsGlobal/Card/GestionTempsCard";
import LineFilterTableInput from "../../GlobalComponents/lineFilterTableInput/LineFilterTableInput.jsx";
import PaginationTable from "../../GlobalComponents/paginationTable/PaginationTable.jsx";
import GeneralCardTest from "../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import FourthSectionTablePage from "../../GlobalComponents/fourthSectionTablePage/FourthSectionTablePage.jsx"
import CheckboxTableInput from "../../GlobalComponents/checkboxTableInput/CheckboxTableInput.jsx";
import SelectNbreLigneTableInput from "../../GlobalComponents/selectNbreLigneTableInput/SelectNbreLigneTableInput.jsx";
import SecondSectionTablePage from "../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import moment from 'moment';
import { faPlus, faArrowRight, faArrowLeft, faCircle1, faCircle} from '@fortawesome/free-solid-svg-icons';
import TopMenuGestionDuTemps from "../TopMenuGestionDuTemps";
import ListColumns from "../../GlobalComponents/listColumns/ListColumns.jsx";

function DashboardSection() {

    const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()
    const [idMasquer, setIdMasquer] = useState([]);
    const [idSupprimer, setIdSupprimer] = useState([]);
    const [idChangeAffichage, setIdChangeAffichage] = useState();
    const [idSupprimerValeur, setIdSupprimerValeur] = useState();
    const [nombrePageArray, setNombrePageArray] = useState([])
    const [selectedNombrePageValeur, setSelectedNombrePageValeur] = useState()
    const [nombrePageValeur, setNombrePageValeur] = useState(0)
    const [matriculeRecherche, setMatriculeRecherche] = useState()
    const [nomRecherche, setNomRecherche] = useState()
    const [prenomRecherche, setPrenomRecherche] = useState()
    const [etablissementRecherche, setEtablissementRecherche] = useState()
    const [departementRecherche, setDepartementRecherche] = useState()
    const [categorieRecherche, setCategorieRecherche] = useState()
    const [contratRecherche, setContratRecherche] = useState()
    const [horaireRecherche, setHoraireRecherche] = useState()
    const [employesList, setEmployesList] = useState()
    const [defaultNombrePageValeur, setDefaultNombrePageValeur] = useState()
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
    const [employes, setEmployes] = useState([]);
    const [listHistoriqueAbsence, setListHistoriqueAbsence] = useState([]);
    const [listDayDate, setListDayDate] = useState()
    const [listCalculationResult, setListCalculationResult] = useState();
    const [role, setRole] = useState();
    const [dataEmployes, setDataEmployes] = useState([]);
    const [isLoading, setIsLoading] = useState()
    var listCalculation = {}
    var varMatriculeRecherche = ''
    var varNomRecherche = ''
    var varPrenomRecherche = ''
    var varEtablissementRecherche = ''
    var varDepartementRecherche = ''
    var varContratRecherche = ''
    var varHoraireRecherche = ''
    
  
      // const doc = new jsPDF('landscape', '', 'a4');
    const doc = new jsPDF();
  
  
      // var pdfjs = document.getElementById('panelTable');
  
    function createData(emps){
      var data = []
  
      emps.map(emp => data.push([emp.matricule_employe, emp.nom_employe, emp.prenom_employe, emp.etablissement_employe, emp.departement_employe, emp.typeContrat_employe, emp.plageHoraire_employe]))
  
      return data
    }
    
    const btnGeneratePdf = (e) => {
      doc.autoTable({ 
        head: [['Matricule', 'Nom', 'Prénom', 'Etablissement', 'Département', 'Contrat', 'Horaire']],
        body: createData(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false))
      })
  
      doc.save("test.pdf")
    }
    const [listNombreLingnesAdministration, setListNombreLingnesAdministration] = useState(null)

    useEffect(() => {
        if(token['mytoken']){
            APIService.getNombreLignesAdministration(token)
            .then(resp => setListNombreLingnesAdministration(resp))
        }
    }, [token['mytoken']])
    useEffect(() => {
      let lastData=[]
      if(valeurRechercheGlobale && employes){
        setEmployesList(employes.filter(resp => resp.matricule_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.nom_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.prenom_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.etablissement_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.departement_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.typeContrat_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.plageHoraire_employe.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())))
      }else{
        setEmployesList(employes)
        lastData.push(employes)
      }
    }, [valeurRechercheGlobale,employes])

    useEffect(() => {
        var day;
        var dateOffset;
        var myDate;
        var listDate = []
        
    
        
    
          myDate = new Date();
          day = new Date().getUTCDay();
          dateOffset = (24*60*60*1000) * (day-1);
    
          myDate.setTime(myDate.getTime() - dateOffset);
          listDate.push(moment(myDate).format("DD/MM/YYYY"))
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate.push(moment(myDate).format("DD/MM/YYYY"))
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate.push(moment(myDate).format("DD/MM/YYYY"))
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate.push(moment(myDate).format("DD/MM/YYYY"))
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate.push(moment(myDate).format("DD/MM/YYYY"))
    
          // myDate.setTime(myDate.getTime() + (24*60*60*1000));
          // listDate["Samedi"]=moment(myDate).format("DD/MM/YYYY")
    
    
          setListDayDate(listDate)
    
        
      
      }, [])
      
    
      useEffect(() => {
    
    
        if(listHistoriqueAbsence && listDayDate){
    
          
    
          
    
            for(let v in listHistoriqueAbsence){
              if(!getKeysList(listCalculation).includes(listHistoriqueAbsence[v]["matricule_employe"])){
                listCalculation[listHistoriqueAbsence[v]["matricule_employe"]] = [0, 0]
              }
    
    
    
              for(let el in listDayDate){
    
    
    
                      
                     
                      if(new Date(moment(listHistoriqueAbsence[v]["rubriqueDateDebut_demandeAbsence"]).format("YYYY-MM-DD")).getTime() <= new Date(moment(listDayDate[el], "DD/MM/YYYY").format("YYYY-MM-DD")).getTime()
                      && new Date(moment(listHistoriqueAbsence[v]["rubriqueDateFin_demandeAbsence"]).format("YYYY-MM-DD")).getTime() >= new Date(moment(listDayDate[el], "DD/MM/YYYY").format("YYYY-MM-DD")).getTime()){
    
                        
    
                        if(getKeysList(listCalculation).includes(listHistoriqueAbsence[v]["matricule_employe"])){
                              listCalculation[listHistoriqueAbsence[v]["matricule_employe"]][0] += 1
                              if(listHistoriqueAbsence[v]["rubriqueAbsence_demandeAbsence"] === "Demande CP"){
                                listCalculation[listHistoriqueAbsence[v]["matricule_employe"]][1] += 1
                              }
                        }
                        else{
                              listCalculation[listHistoriqueAbsence[v]["matricule_employe"]] = [1]
    
                              if(listHistoriqueAbsence[v]["rubriqueAbsence_demandeAbsence"] === "Demande CP"){
                                listCalculation[listHistoriqueAbsence[v]["matricule_employe"]].push(1)
                              }else{
                                listCalculation[listHistoriqueAbsence[v]["matricule_employe"]].push(0)
                              }
      
                        }
    
    
    
                      }
    
                
                  }
    
    
              }
    
    
    
          
    
              if(
                getKeysList(listCalculation).length > 0){
                setListCalculationResult(listCalculation);
              }
              
    
        }
    
    }, [listHistoriqueAbsence, listDayDate])
    
    
    function getKeysList(list) {
    
      let listKeys = []
    
      for(let v in list){
          listKeys.push(v)
      }
    
      return listKeys
    
    }
    
    
    
    
      useEffect(() => {
    
        if(token['mytoken']){
    
          APIService.getEmployes(token).then(resp => setEmployes(resp.filter(resp => resp.state_employe === true && resp.matricule_manager === token['matriculeManager'])));
    
          APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence === true)));
    
          APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));
    
        }
    
      }, [token])
    
      
    
    
      useEffect(() => {
        if(role){
          if(role !== "Manager"){
            window.location.href = "/";
          }
        }
      }, [role])
      
      
    useEffect(() => {
     if(listCalculationResult){
      console.log("oook :", getKeysList(listCalculationResult))
     }
    }, [listCalculationResult])
    
      // if(role === "Manager"){
    
  
    useEffect(() => {
  
      if(matriculeRecherche){
        varMatriculeRecherche = matriculeRecherche.toString().toLowerCase()
      }else{
        varMatriculeRecherche = ''
      }
  
      if(nomRecherche){
        varNomRecherche = nomRecherche.toString().toLowerCase()
      }else{
        varNomRecherche = ''
      }
  
      if(prenomRecherche){
        varPrenomRecherche = prenomRecherche.toString().toLowerCase()
      }else{
        varPrenomRecherche = ''
      }
  
      if(etablissementRecherche){
        varEtablissementRecherche = etablissementRecherche.toString().toLowerCase()
      }else{
        varEtablissementRecherche = ''
      }
      
      if(departementRecherche){
        varDepartementRecherche = departementRecherche.toString().toLowerCase()
      }else{
        varDepartementRecherche = ''
      }
  
      
  
      if(contratRecherche){
        varContratRecherche = contratRecherche.toString().toLowerCase()
      }else{
        varContratRecherche = ''
      }
  
      if(horaireRecherche){
        varHoraireRecherche = horaireRecherche
      }else{
        varHoraireRecherche = ''
      }
  
      
      setEmployesList(employes.filter(resp => resp.matricule_employe.toLowerCase().includes(varMatriculeRecherche) && resp.nom_employe.toLowerCase().includes(varNomRecherche) && resp.prenom_employe.toLowerCase().includes(varPrenomRecherche) && resp.etablissement_employe.toLowerCase().includes(varEtablissementRecherche) && resp.departement_employe.toLowerCase().includes(varDepartementRecherche) && resp.typeContrat_employe.toLowerCase().includes(varContratRecherche) && resp.plageHoraire_employe.toString().toLowerCase().includes(varHoraireRecherche)))
      
    }, [matriculeRecherche, nomRecherche, prenomRecherche, etablissementRecherche, departementRecherche, contratRecherche, horaireRecherche])
    
  
  
  
  
    useEffect(() => {
     
      APIService.getEmployes(token)
      .then(resp => setEmployes(resp))
      .catch(error => console.log(error))
  
    }, [idChangeAffichage])
    const [initData, setInitData] = useState(null)

    useEffect(() => {
  
      if(token['mytoken']){
        APIService.getEmployes(token)
        .then(resp => 
           setInitData(resp)
      )
      .catch(error => console.log(error))
      }
  
    }, [token])
    useEffect(() => {
      if(initData){
        for(let v in initData){
          if(initData[v]["statut_employe"] !== "actif"){
            initData[v]["statut_employe"] = "Non active"
            console.log("test active",initData[v]["statut_employe"] )
          }else{
            initData[v]["statut_employe"] = "Active"
          }
        }


        setEmployes(initData)
      }
    }, [initData])

      useEffect(() => {
        if (initData) {
          initData.forEach(emp => {
            if (getKeysList(listCalculationResult)===emp.matricule_employe) {
              emp.heureReel = emp.plageHoraire_employe - (listCalculationResult[emp.matricule_employe][0] * 7);
              emp.jourAbsence = listCalculationResult[emp.matricule_employe][0];
              emp.compteCP = listCalculationResult[emp.matricule_employe][1];
              const calculatedObjectif = ((emp.plageHoraire_employe - (listCalculationResult[emp.matricule_employe][0] * 7)) * 100) / emp.plageHoraire_employe;
              emp.Objectif = calculatedObjectif.toLocaleString(undefined, { minimumFractionDigits: 0 }) + '%';
            } else {
              emp.heureReel = emp.plageHoraire_employe;
              emp.jourAbsence = 0;
              emp.compteCP = 0;
              emp.Objectif = "100%";
            }
          });
          setEmployes(initData);
        }
      }, [initData,employes]);

      

      
      

      console.log("init data ",employes)
    useEffect(() => {
  
      if(token['mytoken']){
        APIService.getEmployes(token)
      .then(resp => setEmployes(resp))
      .catch(error => console.log(error))
  
      }
  
    }, [token])
  
  
  
    useEffect(() => {
      if(employes){
          setSelectedNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
          setNombrePageArray(range(1, 1))
          setNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
          setDefaultNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
  
          setEmployesList(employes)
      }
    }, [employes])
  
  
    useEffect(() => {
      if(idMasquer && employesList && selectedNombrePageValeur){
          setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/selectedNombrePageValeur)))
  
      }
    }, [idMasquer, employesList])
    
  
    const btnMasquer = (e) => {
      
      setIdMasquer(idMasquer.concat(parseInt(e.target.value)))
      
    }
  
    const btnRoload = () => {
      if(employes){
        setEmployesList(employes)
        setIdMasquer([])
      }
      
    }
  
    const btnSupprimer = (e) => {
  
      setIdSupprimerValeur(parseInt(e.target.value))
  
      // APIService.SupprimerEmploye(e.target.value, token)  
      
      // setIdSupprimer(idSupprimer.concat(parseInt(e.target.value)))
      
    }
  
    var listData = {}
    const btnSupprimerOui = (e) => {
      listData = employesList.filter(emp => emp.id === parseInt(e.target.value))[0]
      APIService.ModifierEmploye(parseInt(e.target.value),
      {matricule_employe:listData.matricule_employe, dateEntree_employe:listData.dateEntree_employe, dateModification_employe:listData.dateModification_employe, dateFinModification_employe:listData.dateFinModification_employe,dateSortie_employe:listData.dateSortie_employe,
      nom_employe:listData.nom_employe, prenom_employe:listData.prenom_employe, sexe_employe:listData.sexe_employe, numeroSS_employe:listData.numeroSS_employe, cle_employe:listData.cle_employe, dateNaissance_employe:listData.dateNaissance_employe, 
      paysNaissance_employe:listData.paysNaissance_employe, departementNaissance_employe:listData.departementNaissance_employe, nationnalite1_employe:listData.anneeFormation1_employe, nationnalite2_employe:listData.nationnalite2_employe, 
      situationFamiliale_employe:listData.situationFamiliale_employe, nombreEnfant_employe:listData.nombreEnfant_employe, adresse_employe:listData.adresse_employe, pays_employe:listData.pays_employe, 
      codePostale_employe:listData.codePostale_employe, ville_employe:listData.ville_employe, tel_employe:listData.tel_employe, 
      email_employe:listData.email_employe, etablissement_employe:listData.etablissement_employe, departement_employe:listData.departement_employe, 
      poste_employe:listData.poste_employe, typeContrat_employe:listData.typeContrat_employe, plageHoraire_employe:listData.plageHoraire_employe, jourRepos1_employe:listData.jourRepos1_employe, jourRepos2_employe:listData.jourRepos2_employe, nomRue_employe:listData.nomRue_employe, NRue_employe:listData.NRue_employe, complementAdresse_employe:listData.complementAdresse_employe, chez_employe:listData.chez_employe, telephoneFixe_employe:listData.telephoneFixe_employe,formation1_employe:listData.formation1_employe, anneeFormation1_employe:listData.anneeFormation1_employe, formation2_employe:listData.formation2_employe, anneeFormation2_employe:listData.anneeExperience2_employe,formation3_employe:listData.formation3_employe, anneeFormation3_employe:listData.anneeExperience3_employe,formation4_employe:listData.formation4_employe, anneeFormation4_employe:listData.anneeFormation4_employe,
      experience1_employe:listData.experience1_employe, anneeExperience1_employe:listData.anneeExperience1_employe, experience2_employe:listData.experience2_employe, anneeExperience2_employe:listData.anneeExperience2_employe,experience3_employe:listData.experience3_employe, anneeExperience3_employe:listData.anneeExperience3_employe,experience4_employe:listData.experience4_employe, anneeExperience4_employe:listData.anneeExperience4_employe, 
      langue1_employe:listData.langue1_employe, niveauLangue1_employe:listData.niveauLangue1_employe,
      langue2_employe:listData.langue2_employe, niveauLangue2_employe:listData.niveauLangue2_employe,
      langue3_employe:listData.langue3_employe, niveauLangue3_employe:listData.niveauLangue3_employe,
      langue4_employe:listData.langue4_employe, niveauLangue4_employe:listData.niveauLangue4_employe,
      iban_employe:listData.iban_employe, banque_employe:listData.banque_employe,
      domiciliation_employe:listData.domiciliation_employe, numeroCompte_employe:listData.numeroCompte_employe,
      codeBanque_employe:listData.codeBanque_employe, codeGuichet_employe:listData.codeGuichet_employe,
      cleRib_employe:listData.cleRib_employe, state_employe:0, modeTravail_employe : listData.modeTravail_employe, competence_employe: listData.competence_employe,
      heurePrisePoste_employe: listData.heurePrisePoste_employe,            
      motifSortie_employe:3, matricule_manager: token['matriculeManager']},token)
      
      
      
      // APIService.SupprimerEmploye(e.target.value, token)  
      
      setIdSupprimer(idSupprimer.concat(parseInt(e.target.value)))
  
      setIdSupprimerValeur()
  
    }
  
    const btnSupprimerNon = (e) => {
  
      setIdSupprimerValeur()
      
    }
  
    function range(start, end) {
      return Array(end - start + 1). fill(). map((_, idx) => start + idx)
    }
  
  
    function setNombrePage(num) {

      setSelectedNombrePageValeur(parseInt(num))
      setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/parseInt(num))))
      setNombrePageValeur(parseInt(num))
      
    }
  
    const btnNombrePage = (e) => {
      setNombrePageValeur(parseInt(e.target.value))
      
    }

    function btnNombrePageFunction(pageNum){
      setNombrePageValeur(parseInt(pageNum))
    }
  
  
    const btnFichePersonnel = (e) => {
      
  
      setToken('idFichePersonnel', e.target.value)
      console.log(e.target.name)
  
      // window.location.href = '/donneesPersonnelles'
  
    }

    const [show, setShow] = useState(false)
    function showMenu() {
      if(show){
        setShow(false)
      }else{
        setShow(true)

      }
    }
    // ********************************
    

    // Pagination 

  const [index, setIndex] = useState(0)
  const [page, setPage] = useState(null)
  const [pageTickets, setPageTickets] = useState([])
  const [nombrePageArray_, setNombrePageArray_] = useState([])
  const [nombrePageValeurFixed, setNombrePageValeurFixed] = useState(null)
  const [afficherChecked, setAfficherChecked] = useState(false)


  function transformNumberToArray(n) {
      const array = [];
      for (let i = 1; i <= n; i++) {
          array.push(i);
      }
      return array;
  }
 
  
  useEffect(() => {
    if(employesList && parseInt(index) !== 0 && page !== 0 )
    
    { 
      let indexInt = parseInt(index);
  
      setPageTickets(employesList.slice(page*indexInt - indexInt, page*indexInt))
      setNombrePageArray_(transformNumberToArray(Math.ceil(employesList.length/parseInt(index))))

    } else {

      if( employesList && parseInt(index) === 0){
        setPage(1)
        setPageTickets(employesList)
        setNombrePageArray_([])
       


      }

    }
    
  }, [employesList, index, page])
  



  useEffect(() => {
    if(afficherChecked && employesList){
      setNombrePageValeurFixed(0);
      setIndex(0);
    }
   }, [afficherChecked])
   const handleChangeNombrePage = (e) => {
    setAfficherChecked(false)
    setNombrePageValeurFixed(parseInt(e.target.value))
    setIndex(e.target.value)
    setPage(1);
  }

  useEffect(() => {
    console.log("array nbre page ",nombrePageArray,"page",page)
  }, [nombrePageArray,page])
  
    // test table 
    const sampleData = [
      { id: 1, name: 'xxxx', age: 25 },
      { id: 2, name: 'testt', age: 30 },
      { id: 3, name: 'yousra'  , age:24},
      { id: 4, name: 'hamza'  , age:16},

    ];
    console.log("spledata",dataEmployes)
    const colmns = 
      { id:"identifiant", age: "years", name:"prenom"};
     

    const headers={id : "id", matricule_employe:"Matricule",nom_employe:"Nom",prenom_employe:"Prénom",
    etablissement_employe:"Etablissement",typeContrat_employe:"Contrat",plageHoraire_employe:"Hre Planifié",
    heureReel:"Hre Réel",jourAbsence:"Jr Absence",compteCP:"Compte CP",Objectif:"Objectif"
    // ,test:"Action"
  };


    useEffect(() => {

      if(pageTickets){

        setDataEmployes(pageTickets)
      }
      
    }, [pageTickets])
    


    // ******************************
    const deleteButton = (id) => {
      if(id && token){
        // re loading popup 
        // setIsLoading(true)
        APIService.SupprimerEmploye(id, token)
        .then(
          () =>  APIService.getEmployes(token)
                  .then(resp => 
                  {setInitData(resp);
                    // re
                  setIsLoading(false)
                  }
                    )
                    .catch(error => console.log(error))
        
        )
      }
    }
    const modifyButton = (id) => {
      if(id){
        console.log("modify button",id)
      }
    }
    const hideButton = (id) => {
      if(id){
        console.log("hide button",id)
      }
    }
    const sendButton = (id) => {
      if(id){
        console.log("send button",id)
      }
    }
   const componentsList={ 1: "Send",2:"Delete",3:"Modify",4:"Hide",5:"Delete"}
  const methodsList={ 1: sendButton,2:deleteButton,3:modifyButton,4:hideButton,5:deleteButton}
  const [headerTest, setHeaderTest] = useState({});
  const test={matricule_employe:"Matricule",nom_employe:"Nom",prenom_employe:"Prénom"}
   
  return (
//     <div className={StylesM.classCard}>
//         <div className={StylesM.topBar}>
//         <TopMenuGestionDuTemps page="dashboard"/>
//         </div>


//       <div className={StylesM.toolsBar}>

//       <div id={StylesM.classTopNavRechPC} style={{color:"rgb(218, 165, 32)", backgroundColor:"rgb(218, 165, 32, 20%)"}}>
       
         
            
//             <span className={StylesM.inputgroup}>
//               <input type="checkbox" className={StylesM.inputGlobal8} checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} /> 
//               <span className={StylesM.inputGlobal77} >Tou afficher	</span>

//             </span>
//             <span   className={StylesM.inputgroup}>
//                 <label className={StylesM.label}>Nombre de lignes : </label>
//                                       <select value={nombrePageValeur} onChange={handleChangeNombrePage} className={StylesM.test}>
//                                       <option></option>
//                                                                    {
//                                                                         listNombreLingnesAdministration &&
//                                                                         listNombreLingnesAdministration.map(
//                                                                               resp => <option value={resp.id}>{resp.nombre_Lingnes}</option>
//                                                                         )
//                                                                    }

//                                       </select>
//               </span>
          
//             <span className={StylesM.inputgroup}>
//                 <label className={StylesM.label} htmlFor="" >Filtrer les lignes : </label>
//                 <input type="text"  placeholder="Rechercher" value={valeurRechercheGlobale} onChange={e => setValeurRechercheGlobale(e.target.value)} className={StylesM.test}/>
//             </span>
          
//           <span  className={StylesM.inputgroup} style={{textAlign:"right", fontWeight:"bold"}}>
//                   <span className="col">
//                   <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span></button>
//                   </span>
//                   <span className="col">
//                   <button title="Excel" className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span></button>

//                   </span>
//           </span>
        
        

//       </div>


//       <div id={StylesM.classTopNavRechMobile}>

//       <div className={StylesM.barrebas1}>

//       <span  className={StylesM.inputgroupI} >

       
                 
//                           <input type="checkbox"  id={StylesM.test} checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} className={StylesM.inputGlobal3}/>
                  

//                   <span className={StylesM.toutafficher1} id={StylesM.label}> 
//                     Tout afficher	
//                   </span>

        
//         </span>


//       <span  className={StylesM.inputgroupI} >

              

//               <label className={StylesM.label_}>Nombre de lignes : </label>
              
              
//                         <select value={nombrePageValeur} onChange={handleChangeNombrePage} >
//                         <option></option>
//                                                                    {
//                                                                         listNombreLingnesAdministration &&
//                                                                         listNombreLingnesAdministration.map(
//                                                                               resp => <option value={resp.id}>{resp.nombre_Lingnes}</option>
//                                                                         )
//                                                                    }
//                         </select>

              

//       </span>

//       <span className={StylesM.RightTopBar}>

//                             {
//                       show ?
//                       <span 
//                       id={StylesM.btnPlus} style={{backgroundColor:"goldenrod", color:"white"}} 
//                       onClick={() => showMenu()}>
//                       <FontAwesomeIcon icon={faPlus} className={StylesM.iconplus} style={{color: "black",fontSize:"22px"}} />          </span>:
//                       <span 
//                       id={StylesM.btnPlus} style={{fontWeight:"bold", color:"white"}}
//                       onClick={() => showMenu()}>
//                       <FontAwesomeIcon icon={faPlus}style={{color: "black"}} />          </span>
//                       }

//       </span>


//       </div>

// </div>

//       </div>


//       <div className={StylesM.classTable}>


//         <div className={StylesM.divTableOverflow}>
//         <div id={StylesM.idTestTable}>  

// <table class="table">
//   <thead>

//     <tr id={StylesM.idForStyleTh}>
//         <th>Matricule</th>
//         <th>Nom</th>
//         <th>Prénom</th>
//         <th>Etablissement</th>
//         <th>Contrat</th>
//         <th>Hre Plannifié</th>
//         <th>Hre Réel</th>
//         <th>Jr Absence</th>
//         <th>Compte CP</th>
//         <th>Objectif</th>
//     </tr>

// </thead>

// <tbody>

//     {
//       employes ? 
//       employes.map(emp => 
//         <tr>
//           <td>{emp.matricule_employe}</td>
//           <td>{emp.nom_employe}</td>
//           <td>{emp.prenom_employe}</td>
//           <td>{emp.etablissement_employe}</td>
//           <td>{emp.typeContrat_employe}</td>
//           <td>{emp.plageHoraire_employe}</td>
//           {
//             getKeysList(listCalculationResult).includes(emp.matricule_employe)
//             ? 

//             <>
//               <td>{emp.plageHoraire_employe - (listCalculationResult[emp.matricule_employe][0] * 7)}</td>
//               <td>{listCalculationResult[emp.matricule_employe][0]}</td>
//               <td>{listCalculationResult[emp.matricule_employe][1]}</td>
//               <td>{((emp.plageHoraire_employe - (listCalculationResult[emp.matricule_employe][0] * 7))*100)/emp.plageHoraire_employe}%</td>
//             </>
//             :
//             <>
//               <td>{emp.plageHoraire_employe}</td>
//               <td>0</td>
//               <td>0</td>
//               <td>100%</td>                      
//             </>
//           }
          
//       </tr>
//         )
//       :<></>
//     }
// </tbody>

    
// </table>

// </div>


//       </div>      
      
   

//       </div>



//       <div className={StylesM.bottomToolsBar}>

//       <div className={StylesM.barrebas}>
//       <span className={StylesM.inputgroup1} >

//               <span className={StylesM.leftBottomBar}>

              
//               <input type="checkbox" className={StylesM.inputGlobal8}
//               checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}}/> 
//               <span className={StylesM.inputGlobal77} > Tout afficher	</span>

//             </span>


              

//               </span>

//               <span style={{width:"auto", fontWeight:"bold"}} className={StylesM.inputgroup1}  id={StylesM.inputgroupIp2} >
//                 <label className={StylesM.label}>Nombre de lignes: </label>
//                                       <select value={nombrePageValeur} onChange={handleChangeNombrePage} className={StylesM.test}>
//                                       <option></option>
//                                                                    {
//                                                                         listNombreLingnesAdministration &&
//                                                                         listNombreLingnesAdministration.map(
//                                                                               resp => <option value={resp.id}>{resp.nombre_Lingnes}</option>
//                                                                         )
//                                                                    }

//                                       </select>
//               </span>

//               <span className={StylesM.inputgroup2}  >
//               <span className={StylesM.centerBottomBar}>

//                   <span className={StylesM.pagination} >

//                   {nombrePageArray && nombrePageArray[0] === selectedPageNumber  ? 
                  
//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}} disabled> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>
                  
//                   : 
//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}}> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>
                  
//                   }

//               { selectedPageNumber > 2 ? <>...</> :<></>}


//             {nombrePageArray ? nombrePageArray.map(nbr => 


//                    <>
                   
                   
//                    {  


//                   Math.abs(selectedPageNumber - nbr) <= 2 ? 

//                   <button className={
//                     selectedPageNumber === nbr ? StylesM.classSelectedPageNumber : StylesM.classPageNumber
//                   } value={selectedNombrePageValeur*nbr} onClick={(e) => {btnNombrePage(e); setSelectedPageNumber(nbr)}}>{nbr}</button> : <></>



//                   }
                   
                   
//                    </>
//               ) : <></>} 




//                 { nombrePageArray && nombrePageArray.length-selectedPageNumber > 2 ? <>...</> :<></>}


//                 {nombrePageArray && nombrePageArray[nombrePageArray.length - 1] === selectedPageNumber  ? 
                  
//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}} disabled><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}}/> </button>
                  
//                   : 

//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}}><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}} /> </button>

                  
//                   }

//                   </span>


//               </span>
//               </span>
//               <span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
//               <span className={StylesM.rightBottomBar}>

                    
//                  { 
//               idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
//                  }
//                     </span> 

//               </span>
//       </div>
//       </div>

//     </div>
<div>
<div className={StylesM.web}>

<GeneralCardTest 
section1={
<TopMenuGestionDuTemps page="dashboard"/>
} 
section2={
  <SecondSectionTablePage 
        nbreComponents={5}
        component1={<ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
         component5={<CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
        } 
        />
}
section3={
  <>
  {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          btnFichePersonnel(e);
          setToken('matriculeAvenant', row.Matricule);
          setToken('listMatriculeNomPrenom', [row.Matricule, row.Nom, row.Prénom]);
        }
      }
      }
      
    
  />  
  }
</>    
 } 
section4={
  <FourthSectionTablePage 
  component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
} 
component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
  component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
  component4={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
               <span className={StylesM.rightBottomBar}>
                 { 
               idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
                 }
                     </span> 
               </span>}
  /> 
        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={StylesM.tablet}>
        <GeneralCardTest 
        section1={
          <TopMenuGestionDuTemps page="dashboard"/>
      }  
        section2={
        <SecondSectionTablePageTablet 
        nbreComponents={6}
        component1={<ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
        />

      } 
        section3={
          <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
        {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          btnFichePersonnel(e);
          setToken('matriculeAvenant', row.Matricule);
          setToken('listMatriculeNomPrenom', [row.Matricule, row.Nom, row.Prénom]);
        }
      }
      }
      
    
  />  
  }
        </div>  
                        } 
        section4={
                <FourthSectionTablePageTablet 
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
              component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
                component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
                
                /> 
          // <>testttt</>

                } 
        NbreSections={4} 
        prctgeSection1={8}
        prctgeSection2={6} 
        prctgeSection3={80}
        prctgeSection4={6}
        />
</div>
<div className={StylesM.mobile}>
        <GeneralCardTest 
         section1={
          <TopMenuGestionDuTemps page="dashboard"/>
      }  
        section2={
        <SecondSectionTablePageMobile 
        nbreComponents={6}
        component1={<ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
              component6={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 

        />
      // <>testttt</>

      }  
        section3={
          <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
           {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          btnFichePersonnel(e);
          setToken('matriculeAvenant', row.Matricule);
          setToken('listMatriculeNomPrenom', [row.Matricule, row.Nom, row.Prénom]);
        }
      }
      }
      
    
  />  
  }
        </div>  
                        } 
        section4={
                <FourthSectionTablePageMobile 
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
                component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}

                /> 
                } 
        NbreSections={4} 
        prctgeSection1={7}
        prctgeSection2={6} 
        prctgeSection3={81}
        prctgeSection4={6}
        />
</div>
<div className={StylesM.mobileInversePetit}>
        <GeneralCardTest 
        section1={
          <TopMenuGestionDuTemps page="dashboard"/>
      } 
        section2={
        <SecondSectionTablePageMobileInversePetit 
        nbreComponents={6}
        component1={<ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
              component6={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 

        />

      }  
        section3={
          <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
          {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          btnFichePersonnel(e);
          setToken('matriculeAvenant', row.Matricule);
          setToken('listMatriculeNomPrenom', [row.Matricule, row.Nom, row.Prénom]);
        }
      }
      }
      
    
  />  
  }
        </div>
                        } 
        section4={
                <FourthSectionTablePageMobileInversePetit 
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
                component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}

                /> 
                } 
        NbreSections={4} 
        prctgeSection1={10}
            prctgeSection2={9} 
            prctgeSection3={72}
            prctgeSection4={9}
        />
</div>
<div className={StylesM.mobileInverseGrand}>
        <GeneralCardTest 
        section1={
          <TopMenuGestionDuTemps page="dashboard"/>
      }
        section2={
        <SecondSectionTablePageMobileInverseGrand 
        nbreComponents={5}
        component1={<ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
        />

      } 
        section3={
          <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
          {
            (dataEmployes && headerTest) && 
              <TableComponent data={dataEmployes} cols={headerTest} 
              addAction={true} 
              contentAction={methodsList}
              componentsList={componentsList}
              clickableRow={true}
              onClickRow={(e, row) => {
                console.log('Row:', e);
                if (e && e.target) {
                  btnFichePersonnel(e);
                  setToken('matriculeAvenant', row.Matricule);
                  setToken('listMatriculeNomPrenom', [row.Matricule, row.Nom, row.Prénom]);
                }
              }
              }
          />  
          }
        </div>  
                        } 
        section4={
                <FourthSectionTablePageMobileInverseGrand  
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
              component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
                component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
                
                /> 
                } 
        NbreSections={4} 
        prctgeSection1={10}
            prctgeSection2={9} 
            prctgeSection3={72}
            prctgeSection4={9}
        />
</div>

</div> 
  )}
// }

export default DashboardSection