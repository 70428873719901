import React from 'react';
import styles from './CustomSelect.module.css';

function CustomSelect({ label, options, isOpen, disabled, handleOptionClick, selectRef ,selectedOption,toggleDropDown}) {
  const handleOptionSelection = (option) => {
    handleOptionClick(option); 
    toggleDropDown(); 
  };
  return (
    <div className={styles.customSelect} ref={selectRef}>
    <div className={styles.labelStyle}>
      <label className={styles.label}>{label}</label>
    </div>
    <div
      className={`${styles.selectSelected} ${isOpen ? `${styles.selectSelected}` : ''}`}
      onClick={toggleDropDown}
      style={{ cursor: disabled || options.length === 0 ? 'not-allowed' : 'pointer',
    border: disabled || options.length === 0 ? "1px solid rgb(128, 128, 128,10%)":"1px solid #d6d6d6",
    backgroundColor:disabled || options.length === 0? "rgb(128, 128, 128,10%)":""
    }}
    >
      <div className={styles.selectDiv}>
        <div className={styles.selectText}>{selectedOption}</div>
        <div className={styles.selectArrow}>
          <i className="fa-solid fa-caret-down"></i>
        </div>
      </div>
    </div>
    {!disabled && options.length >0 &&(
      <div className={`${styles.selectItems} ${isOpen ? '' : `${styles.selectHide}`}`}>
        {options.map((option, index) => (
          <div key={index} value={option} onClick={() => handleOptionSelection(option)} >
            {option}
          </div>
        ))}
      </div>
    )}
  </div>
  );
}

export default CustomSelect;
