import react, {useEffect, useState} from "react";
import styles from "./FraisProfessionnelsSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";


function FraisProfessionnelsSection(){
    const date_today = new Date();
    const today = new Date("2999-12-31").toISOString().slice(0, 10);
    const navigate = useNavigate();

    const lastDay = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const [matriculePaie, setMatriculePaie] = useState();
    const [listFraisProfessionnels, setListFraisProfessionnels] = useState();
    const [listFraisProfessionnelsEtat, setListFraisProfessionnelsEtat] = useState([]);
    const [typeFraisProfessionnel, setTypeFraisProfessionnel] = useState();
    const [date, setDate] = useState();
    const [vehicule, setVehicule] = useState();
    const [puissanceVehicule, setPuissanceVehicule] = useState();
    const [distanceParcourue, setDistanceParcourue] = useState();
    const [montant, setMontant] = useState();

    const [idModifier, setIdModifier] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();
    const [isIconUp, setIsIconUp] = useState(false);

    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])

    useEffect(() => {

        if(!date && lastDay){
            setDate(moment(lastDay).format("yyyy-MM-DD"))
        }
      
    }, [date])

    
    useEffect(() => {
      if(matriculePaie){
        APIService.getFraisProfessionnelsPaie(matriculePaie, token).then(resp => setListFraisProfessionnels(resp));
      }
    }, [matriculePaie, listFraisProfessionnelsEtat])

    useEffect(() => {

        if(JSON.stringify(listFraisProfessionnelsEtat) !== JSON.stringify(listFraisProfessionnels)){
            setListFraisProfessionnelsEtat(listFraisProfessionnels)
        }
    }, [listFraisProfessionnels])


   
    


    const btnEnregistrer = () => {
        
        if(typeFraisProfessionnel && date && ((vehicule && puissanceVehicule && distanceParcourue) || montant)){
            var formData = new FormData();
            formData.append("matricule_employe", matriculePaie);
            formData.append("matricule_manager", matriculePaie);
            formData.append("typeFraisProfessionnel", typeFraisProfessionnel);
            formData.append("date", date);
            formData.append("vehicule", vehicule);  
            formData.append("puissanceVehicule", puissanceVehicule);  
            formData.append("distanceParcourue", distanceParcourue);
            formData.append("montant", montant);

            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }

            if(idModifier){

                APIService.putFraisProfessionnelsPaie(idModifier, formData, token);
                setIdModifier();
            } else {
                                    


                    APIService.postFraisProfessionnelsPaie(formData, token);

                
            }

            APIService.getFraisProfessionnelsPaie(matriculePaie, token).then(resp => {setListFraisProfessionnels(resp); setListFraisProfessionnelsEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deleteFraisProfessionnelsPaie(idRem, token);
        APIService.getFraisProfessionnelsPaie(matriculePaie, token).then(resp => {setListFraisProfessionnels(resp); setListFraisProfessionnelsEtat(resp);});

        setIdModifier();
        
    }
   

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listFraisProfessionnels.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setTypeFraisProfessionnel(resp.typeFraisProfessionnel);
            setDate(resp.date);
            setVehicule(resp.vehicule);
            setPuissanceVehicule(resp.puissanceVehicule);
            setDistanceParcourue(resp.distanceParcourue);
            setMontant(resp.montant);








        })


    }

    
    

    


    const btnAjouter = () => {
        setIsIconUp(prevState => !prevState);   
        if(etatAjouter){
            setEtatAjouter(false);
        } else {
            setTypeFraisProfessionnel("");
            setDate("");
            setVehicule("");
            setPuissanceVehicule("");
            setDistanceParcourue("");
            setMontant("");

            setEtatAjouter(true);
        }

        setIdModifier();
    }
    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }
    

    return(
        <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart}>
                <TopMenuPaie page="FraisProfessionnels"/>
            </div>
            <div className={styles.secondPart}>
                    <div className={styles.tableAndFormSection}>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    {
                                    }
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de frais professionnel
                                                </div>
                                                <select className={styles.goldenInput1} value={typeFraisProfessionnel} onChange={e => setTypeFraisProfessionnel(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Repas">Repas</option>
                                                                            <option value="Télétravail">Télétravail</option>
                                                                            <option value="Professionnel de transport">Professionnel de transport</option>
                                                                        </select>
                                            </div>
                                            </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={date} onChange={(e) => setDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    {
                                typeFraisProfessionnel && typeFraisProfessionnel !== "Professionnel de transport" ? 
                                <></> : 
                                <>
                                <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Véhicule
                                                </div>
                                                <select className={styles.goldenInput1} value={vehicule} onChange={e => setVehicule(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Abonnement">Abonnement</option>
                                                                            <option value="Carburant">Carburant</option>
                                                                            <option value="Forfait mobilité">Forfait mobilité</option>
                                                                        </select>
                                            </div>
                                            </div>
                                            <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Puissance de véhicule
                                                </div>
                                                <select className={styles.goldenInput1} value={puissanceVehicule} onChange={e => setPuissanceVehicule(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                            <option value="6">6</option>
                                                                        </select>
                                            </div>
                                            </div>
                            <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Distance parcourue en KM
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={distanceParcourue} onChange={(e) => setDistanceParcourue(e.target.value)}/>
                                        </div>
                                    </div>
                                
                                </>

                            }
                             {
                                typeFraisProfessionnel === "Professionnel de transport" ? 
                                <></> : 
                                <>
                                <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant à rembourser
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                <br />
                                
                                </>

                            }

                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type de frais professionnels</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFraisProfessionnels ? 
                                    
                                        listFraisProfessionnels.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.date).format("DD/MM/YYYY")}</td>
                                                <td>{resp.typeFraisProfessionnel}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>


                                                                &nbsp;

                                                        </>:<></>
                                                        }
                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                            </div>
                    </div>
                    {/* **************** Mobile ******** */}
                    <div className={styles.tableAndFormSectionMobile}>
                        <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type de frais professionnels</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFraisProfessionnels ? 
                                    
                                        listFraisProfessionnels.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.date).format("DD/MM/YYYY")}</td>
                                                <td>{resp.typeFraisProfessionnel}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>

                                                            
                                                                &nbsp;
                                                                
                                                        </>:<></>
                                                        }
                                                    
                                                    


                                                    
                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                        </div>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de frais professionnel
                                                </div>
                                                <select className={styles.goldenInput1} value={typeFraisProfessionnel} onChange={e => setTypeFraisProfessionnel(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Repas">Repas</option>
                                                                            <option value="Télétravail">Télétravail</option>
                                                                            <option value="Professionnel de transport">Professionnel de transport</option>
                                                                        </select>
                                            </div>
                                            </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={date} onChange={(e) => setDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    {
                                typeFraisProfessionnel && typeFraisProfessionnel !== "Professionnel de transport" ? 
                                <></> : 
                                <>
                                <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Véhicule
                                                </div>
                                                <select className={styles.goldenInput1} value={vehicule} onChange={e => setVehicule(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Abonnement">Abonnement</option>
                                                                            <option value="Carburant">Carburant</option>
                                                                            <option value="Forfait mobilité">Forfait mobilité</option>
                                                                        </select>
                                            </div>
                                            </div>
                                            <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Puissance de véhicule
                                                </div>
                                                <select className={styles.goldenInput1} value={puissanceVehicule} onChange={e => setPuissanceVehicule(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                            <option value="6">6</option>
                                                                        </select>
                                            </div>
                                            </div>
                            <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Distance parcourue en KM
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={distanceParcourue} onChange={(e) => setDistanceParcourue(e.target.value)}/>
                                        </div>
                                    </div>
                                
                                </>

                            }
                             {
                                typeFraisProfessionnel === "Professionnel de transport" ? 
                                <></> : 
                                <>
                                <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant à rembourser
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                <br />
                                
                                </>

                            }

                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            
                    </div>
            </div>
        </div>
    </div>
    )
}

export default FraisProfessionnelsSection;