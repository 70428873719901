import React, { useEffect, useState } from "react";
import {useCookies} from 'react-cookie';
import Women from "./../../img/women.jpg";
import Equipe from "./../../img/equipe.jpg";

import styles from "./ProfileSection.module.css";
import APIService from '../../../APIService.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPen, faXmark } from '@fortawesome/free-solid-svg-icons';
 function ProfileSection(){

    const [nomUser ,setNomUser]= useState(null);
    const [password ,setPassword]= useState(null);
    const [newPassword ,setNewPassword]= useState(null);
    const [emp ,setEmp]= useState(null);
    const [token, setToken] = useCookies(['mytoken', 'idCurrentEmp']);
    const [etatEditPassword, setEtatEditPassword] = useState(false)
    const [settingLangue, setSettingLangue] = useState(false)

    const [confPassword, setConfPassword] = useState(null)
    const [posteEmp, setPosteEmp] = useState(null)


    useEffect( ()=>{
        if(token['mytoken'] && token['idCurrentEmp']){
            APIService.getEmployes(token)
            .then(resp => setEmp(resp.filter(resp => resp.id === parseInt(token['idCurrentEmp']))[0])
            // .then(resp => console.log("cc" ,(resp.filter(resp => resp.id === parseInt(token['idCurrentEmp']))[0]))

            ) ;
        }
    },[token]);

    useEffect( ()=>{
       
            APIService.getUsers(token['mytoken'])
            .then(resp => setNomUser(resp.filter(resp => resp.username === token['username']).map(resp=> resp))
            // .then(resp => console.log("cc" ,(resp.filter(resp => resp.id === parseInt(token['idCurrentEmp']))[0]))
            // .then(resp => console.log("cc" ,resp.filter(resp => resp.username === token['username']).map(resp=> resp))

            ) ;
        
    },[token]);


    useEffect( ()=>{
        if(token && emp){
            APIService.getPoste(token)
        .then(resp => resp.filter(resp => resp.id === emp.poste_employe).map(resp=>setPosteEmp(resp.nom)))
        
        }
    //    if(emp.poste_employe === posteEmp.id){
        // .then(resp => console.log("cc" ,resp.filter(resp => resp.id === emp.poste_employe).map(resp=> resp.nom))
            // .filter(resp => resp.id === parseInt(token['idCurrentEmp']))[0]))
        // .then(resp => console.log("cc" ,resp.filter(resp => resp.username === token['username']).map(resp=> resp))

        
},[token, emp]);

// useEffect(
//     () => {

//         if(posteEmp){
//             console.log("Emp poste : ", posteEmp)
//         }

//     }
//     ,[posteEmp])
// console.log("CC2 :",posteEmp )

    const [passModifier, setPassModifier] = useState(null)

   const [showAlertChamps, setShowAlertChamps] = useState(false)
   const [showAlertConfirmation, setShowAlertConfirmation] = useState(false)

          
    function  editPassword(){
   


        if(nomUser && password && password === confPassword){
          
          
          let formData = new FormData();
          formData.append("password", password)
          formData.append("role", "MANAGER")
          formData.append("username", nomUser[0].username)
          
    
          console.log("ccccc",nomUser[0].id)

    
    
          APIService.putUsers(nomUser[0].id, formData)
          .then(resp => {
            // setToken('mytoken', resp.token);
            // setPassModifier(resp.is_successful);
            // setShowAlertChamps(false);
            // setShowAlertConfirmation(false);
            console.log("resp : ", resp)
    
          });
          
          
    
    
    
        } else{ 
    
            
            
            if(!password || !confPassword){
            setShowAlertChamps(true)
            setPassModifier(false);
            setShowAlertConfirmation(false);
    
        } else {if(password !== confPassword){
            setShowAlertConfirmation(true)
            setPassModifier(false);
            setShowAlertChamps(false);
    
        }}
    
    
    
        }
    
    
        
      }
      
      function btnSettingLangue() {

        if(settingLangue){
            setSettingLangue(false)
        } else {
            setSettingLangue(true)
        }
        
    }
      function btnEditPassword() {

        if(etatEditPassword){
            setEtatEditPassword(false)
        } else {
            setEtatEditPassword(true)
        }
        
    }

    // function closeNoti = () => {

        
    //         setEtatEditPassword(false)
        
    //         setEtatEditPassword(true)
        
        
    // }

    return(
//         <div  className={styles.classCard}>

//             {
//                 emp && <p>Nom complet : {emp.prenom_employe}, {emp.nom_employe}, {emp.email_employe} , {emp.poste_employe}
//                     </p>
                    
//             }
// <div className={styles.container1} id={styles.container4}>
//                             <button onClick = {() => btnEditPassword()} className={styles.btnEnregistrer}>Modifier le mot passe</button>
//                         </div>

//                           {
//                         etatEditPassword ?
//                         <div className={styles.classDisplayInfosEdit}>
                                               
                                        
//                             <div style={{width:"100%", paddingTop:"20px"}}>
//                             <div className={styles.container1} id={styles.container4}>
//                                             <div className={styles.zoneInputInput}>
//                                                 <span className={styles.fileNameTxtStyleInput}>
//                                                 Mot de passe
//                                                 </span>
//                                                 <input type="password" value = {password} onChange = {e => setPassword(e.target.value)} className={styles.goldenInput1}/>
                                               
//                                             </div>
//                             </div> 
//                             <div className={styles.container1} id={styles.container4}>
//                                             <div className={styles.zoneInputInput}>
//                                                 <span className={styles.fileNameTxtStyleInput}>
//                                                 Confirmer le mot de passe
//                                                 </span>
//                                                 <input type="password" value = {confPassword} onChange = {e => setConfPassword(e.target.value)} className={styles.goldenInput1}/>
                                                
//                                             </div>
//                             </div> 
                       
                       

//                         <div className={styles.container1} id={styles.container4}>
//                             <button onClick={() => editPassword()} className={styles.btnEnregistrer}>Modifier</button>
//                         </div>
                    


//                             </div>
                             
                             
//                         </div>
//                         :<></>
//                     }         
//         </div>
    <div className={styles.classCard}>
        <div className={styles.test}>
        <div className={styles.testPc}>
       
        <div className={styles.zoneSetting}>
        
            <div className={styles.test4}>
           <span> Langue du profile</span>
        <button className={styles.paramButton2} 
        // onClick = {() => btnEditPassword()}
        >
            <FontAwesomeIcon icon={faPen} />
         </button></div>
         <div className={styles.test4} style={{fontSize:"16px",fontFamily:"Georgia",color:"gray"}}>Francais</div>
        </div>  
       
        <br />
        {/* <div className={styles.zonePicture}>
        <div className={styles.imageProfile2}
                 style={{backgroundColor:"yellow"}}
                 > 
                    <img class={styles.logoimage2} src={Equipe} /> 
                  
                </div>
</div> */}
        </div>
        <div className={styles.testMobile}>
        <button  className={styles.paramButton2} onClick = {() => btnSettingLangue()}>
                         <FontAwesomeIcon icon={faGear} />
                      </button>
                      {
                        settingLangue ?
        <div className={styles.zoneSetting}>
        
            <div className={styles.test4}>
           <span> Langue du profile</span>
        <button className={styles.paramButton2} 
        >
            <FontAwesomeIcon icon={faPen} />
         </button></div>
         <div className={styles.test5} style={{fontSize:"16px",fontFamily:"Georgia",color:"gray"}}>Francais</div>
        </div>  
         :<></>
                    }      
        <br />
      
        </div>

        <div className={styles.test2}>
            <div className={styles.zoneProfile}>
                <div className={styles.imageProfile}
                //  style={{backgroundColor:"yellow"}}
                 > 
                    <img class={styles.logoimage} src={Women} /> 
                  
                </div>
                <div className={styles.textprofile} 
                // style={{backgroundColor:"green"}}
                >
                {
                emp && 
                <div className={styles.textprofileCase}>
                   <span style={{fontSize:"16px", fontWeight:"bold", color:"gray",fontFamily:"Georgia"}}> {emp.prenom_employe} {emp.nom_employe} </span>
                       
                    <span style={{fontSize:"14px",marginTop:"4px",fontFamily:"Georgia"}}>   
                        {posteEmp && <>{posteEmp}</>}
                        </span>
                       <div className={styles.divise}>
                    <span  style={{fontSize:"14px", fontWeight:"normal", color:"gray",fontFamily:"system-ui"}}>  {emp.email_employe}</span>  
                    {/* <button onClick = {() => btnEditPassword()} className={styles.btnEnregistrer}>Modifier le mot passe</button> */}
                    <button className={styles.paramButton2} onClick = {() => btnEditPassword()}>
                         <FontAwesomeIcon icon={faPen} />
                      </button>
                    </div>
                  </div> 
            }
           

                              
                </div>
                <div>
                         
                
                {
                        etatEditPassword ?
                        <div className={styles.classDisplayInfosEdit}>

<div className={styles.barNotif}>
                  <div className={styles.popupHeader}>
                      <span className={styles.textBarNotifStyle}>   </span>
                      <button className={styles.paramButton}>
                         {/* <FontAwesomeIcon icon={faPen} /> */}
                      </button>
                    <span> </span>
                      <button className={styles.closeButton} 
                      onClick={() => setEtatEditPassword(false)}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        </button>
                  </div>
                  
                         

              
                  
                  </div>   









                                        
                            <div style={{width:"100%", paddingTop:"20px"}}>
                                            <div className={styles.zoneInputInput}>
                                                <span className={styles.fileNameTxtStyleInput}>
                                                Mot de passe
                                                </span>
                                                <input type="password" value = {password} onChange = {e => setPassword(e.target.value)} className={styles.goldenInput1}/>
                                               
                                            </div>
                            <br />
                                            <div className={styles.zoneInputInput}>
                                                <span className={styles.fileNameTxtStyleInput1}>
                                                Confirmer le mot de passe
                                                </span>
                                                <span className={styles.fileNameTxtStyleInputMobile}>
                                                C M P
                                                </span>
                                                <input type="password" value = {confPassword} onChange = {e => setConfPassword(e.target.value)} className={styles.goldenInput1}/>
                                                
                                            </div>
                            
                       
                       {/* <br /> */}
<div style={{display:"flex", alignItems:"center",justifyContent:"center"}} >
                            <button onClick={() => editPassword()} className={styles.btnEnregistrer} >Envoyer</button>
                            </div>
                        </div>
                             
                             
                                           </div> 
                                                     :<></>
                                                 } 
                                                 </div>  
            </div>
        </div>

        <div className={styles.test3}>
        
        
        
        </div>

        </div>
        </div>
    )
} 
export default ProfileSection; 