import React, { useState, useEffect } from 'react';
import styles from './TableComponent.module.css';

const TableComponent = ({ data, cols, addAction, contentAction, componentsList, onClickRow, clickableRow ,idDelete, idModify,idDelimit}) => {
  const [finalColumns, setFinalColumns] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [compList, setCompList] = useState([])
const [methList, setMethList] = useState([])

  useEffect(() => {
    if(componentsList && contentAction){
    let x=Object.values(componentsList)
    let y=Object.values(contentAction)
    setCompList(x)
    setMethList(y)
  }

}, [componentsList,contentAction])

  useEffect(() => {
    if (data && cols) {
      let result = [];
      let colsKeys = Object.keys(cols);

      for (let v in data) {
        let temp = {};
        for (let i = 0; i < colsKeys.length; i++) {
          temp[cols[colsKeys[i]]] = data[v][colsKeys[i]];
        }
        result.push(temp);
      }
      setResultData(result);
      setFinalColumns(Object.values(cols));
    }
  }, [data, cols]);

  // const requestSort = (key) => {
  //   let direction = 'ascending';
  //   if (sortConfig.key === key && sortConfig.direction === 'ascending') {
  //     direction = 'descending';
  //   }
  //   const sorted = [...resultData].sort((a, b) => {
  //     if (a[key] < b[key]) {
  //       return direction === 'ascending' ? -1 : 1;
  //     }
  //     if (a[key] > b[key]) {
  //       return direction === 'ascending' ? 1 : -1;
  //     }
  //     return 0;
  //   });
  //   setResultData(sorted);
  //   setSortConfig({ key, direction });
  // };
const requestSort = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
  }

  const sorted = [...resultData].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];
      if (typeof valueA === 'string' && typeof valueB === 'string') {
          const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
          const isDateA = dateRegex.test(valueA);
          const isDateB = dateRegex.test(valueB);

          if (isDateA && isDateB) {
              const dateA = new Date(
                  parseInt(valueA.split('/')[2]),
                  parseInt(valueA.split('/')[1]) - 1,
                  parseInt(valueA.split('/')[0])
              );
              const dateB = new Date(
                  parseInt(valueB.split('/')[2]),
                  parseInt(valueB.split('/')[1]) - 1,
                  parseInt(valueB.split('/')[0])
              );
              return direction === 'ascending' ? dateA - dateB : dateB - dateA;
          } else {
              return direction === 'ascending' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          }
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          return direction === 'ascending' ? valueA - valueB : valueB - valueA;
      } else {
          return 0; 
      }
  });

  setResultData(sorted);
  setSortConfig({ key, direction });
};


const convertDateFormatIfNeeded = (value) => {
  const yyyyMMddRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (yyyyMMddRegex.test(value)) {
      const [year, month, day] = value.split('-');
      return `${day}/${month}/${year}`;
  }
  return value;
};





    
    
  
  

  return (
    <div className={styles.tableComponentStyle}>
      <table id={styles.idForStyleTh}>
        <thead>
          <tr>
            {finalColumns.slice(1).map((column, index) => (
              <th key={index} onClick={() => requestSort(column)} className={styles.thStyle}>
                 <div className={styles.sortableHeader}>
                  <span className={styles.headerTable}>{column}</span>
                  {sortConfig.key === column && (
                    <span className={styles.sortIcon}>
                      {sortConfig.direction === 'ascending' ?
                        <i className="fa-solid fa-caret-up"></i>
                        :
                        <i className="fa-solid fa-caret-down"></i>
                      }
                    </span>
                  )}
                  {sortConfig.key !== column && (
                    <>
                      <span className={`${styles.sortIcon} ${styles.light}`}>
                      <i class="fas fa-sort"></i>
                      </span>
                    </>
                  )}
                </div>
                
              </th>
            ))}
            {addAction && (
              <th className={styles.actionHeaderCell}>
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {resultData.map((row, indexRow) => (
            <tr id={styles.hoverRowStyle} key={indexRow} 
            className={(row.id ===idDelete) ? styles.redRow: (row.id ===idModify)? styles.modifyRow:(row.id ===idDelimit)? styles.delimitRow:null}>
              {finalColumns.slice(1).map((columnCol, indexCol) => (
                <td key={indexCol} className={(row.id ===idDelete) || (row.id ===idModify) || (row.id ===idDelimit) ? styles.idTd:null} value={row.id} onClick={clickableRow ? (e) => onClickRow(e, row) : null}>
                  {/* {row[columnCol]} */}
                  {convertDateFormatIfNeeded(row[columnCol])}
                </td>
              ))}
                {addAction && methList && compList && (
                  <td className={styles.actionStyle}>
                    <div className={styles.buttonsStyle}>
                      {compList.map((component, index) => {
                        const methodNameComponent = compList[index].toLowerCase() + 'Data';
                        const methodFunction = methList[index];

                        if (component && methodFunction) {
                          const Component = require(`../../Salarie/Dashboard/${component}`).default;

                          return (
                            <div key={index} style={{ display: "flex", width: "100%", height: "100%", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                              <Component {...{ [methodNameComponent]: () => methodFunction(row.id) }} />
                            </div>
                          );
                        }
                        return null; 
                      })}
                    </div>
                  </td>
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
