
import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import StatutAdministrationSection from './StatutAdministrationSection.jsx';



export const StatutAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<StatutAdministrationSection />} />
    
   
 </div>
)}


export default StatutAdministration


