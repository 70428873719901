import React from 'react'
import styles from "./Menu.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import {faCheck, faMoneyCheck,faDatabase, faFile , faFileCircleCheck, faIdCard, faBrain, faCheckToSlot, faAngleRight, } from '@fortawesome/free-solid-svg-icons'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
function Menu(props) {

  return (
    <div className={styles.case} >
                  {
                    ["fichiers", "donnees", "affectation", "coordonnees", "competences", "bancaire", "validation"].includes(props.section) ?

                    <>
                        <span className={styles.classBtnMenu} style={{backgroundColor:"goldenrod"}}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faFile } className={styles.btnfiles}/></span><span className={styles.textStyleFile} onClick={() => props.setEtat('fichiers')} > Fichiers</span></span>

                        <span className={styles.rectangles} style={{backgroundColor:"goldenrod"}}></span>
                    
                    
                    </>:<>
                    
                        <span className={styles.classBtnMenu} ><span className={styles.classIconContainer}><FontAwesomeIcon icon={faFile } className={styles.btnfiles}/></span><span className={styles.textStyleFile}>Fichiers</span></span>

                        <span className={styles.rectangles} ></span>
                    </>
                  }


{
                    ["donnees", "affectation", "coordonnees", "competences", "bancaire", "validation"].includes(props.section) ?

                    <>
                              <span className={styles.classBtnMenu} style={{backgroundColor:"goldenrod"}}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faDatabase } className={styles.btnfiles}/></span><span className={styles.textStyleFile} onClick={() => props.setEtat('donnees')} >Données</span></span>
                              
                              <span className={styles.rectangles} style={{backgroundColor:"goldenrod"}}></span>
                    
                    </>:<>
                    
                              <span className={styles.classBtnMenu} ><span className={styles.classIconContainer}><FontAwesomeIcon icon={faDatabase } className={styles.btnfiles}/></span><span className={styles.textStyleFile}>Données</span></span>

                              <span className={styles.rectangles}></span>
                    </>
                  }


{
                    ["affectation", "coordonnees", "competences", "bancaire", "validation"].includes(props.section) ?

                    <>
                              <span className={styles.classBtnMenu} style={{backgroundColor:"goldenrod"}}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faFileCircleCheck } className={styles.btnfiles}/></span><span className={styles.textStyleFile} onClick={() => props.setEtat('affectation')} >Affectation</span></span>

                              <span className={styles.rectangles} style={{backgroundColor:"goldenrod"}}></span>
                    </>:<>
                    
                              <span className={styles.classBtnMenu} ><span className={styles.classIconContainer}><FontAwesomeIcon icon={faFileCircleCheck } className={styles.btnfiles}/></span><span className={styles.textStyleFile}>Affectation</span></span>
                              <span className={styles.rectangles}></span>
                    </>
                  }


{
                    ["coordonnees", "competences", "bancaire", "validation"].includes(props.section) ?

                    <>
                            <span className={styles.classBtnMenu} style={{backgroundColor:"goldenrod"}}><span className={styles.classIconContainer} ><FontAwesomeIcon icon={faIdCard } className={styles.btnfiles}/></span><span className={styles.textStyleFile} onClick={() => props.setEtat('coordonnees')} >Coordonnées</span></span>

                              <span className={styles.rectangles} style={{backgroundColor:"goldenrod"}}></span>
                    </>:<>
                    
                    <span className={styles.classBtnMenu}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faIdCard } className={styles.btnfiles}/></span><span className={styles.textStyleFile}>Coordonnées</span></span>
                              <span className={styles.rectangles}></span>
                    </>
                  }
  
{
                    ["competences", "bancaire", "validation"].includes(props.section) ?

                    <>
                         <span className={styles.classBtnMenu} style={{backgroundColor:"goldenrod"}} ><span className={styles.classIconContainer} ><FontAwesomeIcon icon={faBrain } className={styles.btnfiles}/></span><span className={styles.textStyleFile} onClick={() => props.setEtat('competences')} >Compétences</span></span>

                            <span className={styles.rectangles} style={{backgroundColor:"goldenrod"}}></span>
                            </>:<>

                        <span className={styles.classBtnMenu}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faBrain } className={styles.btnfiles}/></span><span className={styles.textStyleFile}>Compétences</span></span>
                            <span className={styles.rectangles}></span>
                    </>
                  }                        
                              
                              
                              {
                    ["bancaire", "validation"].includes(props.section) ?

                    <>
                     
         <span className={styles.classBtnMenu} style={{backgroundColor:"goldenrod"}}><span className={styles.classIconContainer} ><FontAwesomeIcon icon={faMoneyCheck } className={styles.btnfiles}/></span><span className={styles.textStyleFile} onClick={() => props.setEtat('bancaire')} >ID.Bancaire</span></span>

          <span className={styles.rectangles} style={{backgroundColor:"goldenrod"}}></span>
          </>:<>

                <span className={styles.classBtnMenu}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faMoneyCheck } className={styles.btnfiles}/></span><span className={styles.textStyleFile}>ID.Bancaire</span></span>
                       <span className={styles.rectangles}></span>
                    </>
                  }      

                  {
                    ["validation"].includes(props.section) ?

                    <>
                        {/* <span className={styles.classBtnMenu} id={styles.unique7} style={{backgroundColor:"goldenrod"}}><FontAwesomeIcon icon={faCheckToSlot} className={styles.btnfiles}/><span className={styles.textStyleFile}> &nbsp; Validation</span></span>
                        
                         </>:<>
                    
                         <span className={styles.classBtnMenu} id={styles.unique7} ><FontAwesomeIcon icon={faCheckToSlot} className={styles.btnfiles}/><span className={styles.textStyleFile}> &nbsp; Validation</span></span> */}
                     <span className={styles.classBtnMenu} style={{backgroundColor:"goldenrod"}}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faCheckToSlot } className={styles.btnfiles}/></span><span className={styles.textStyleFile} onClick={() => props.setEtat('validation')} >Validation</span></span>

                          </>:<>

                       <span className={styles.classBtnMenu}><span className={styles.classIconContainer}><FontAwesomeIcon icon={faCheckToSlot } className={styles.btnfiles}/></span><span className={styles.textStyleFile}>Validation</span></span>
                    </>
                  }                       
                              
                              
                            
                        
    </div>
  )
}

export default Menu;