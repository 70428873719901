import react, {useEffect, useState} from "react";
import styles from "./RepasSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";



function RepasSection(){
    const today = new Date("2999-12-31").toISOString().slice(0, 10);
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const [matriculePaie, setMatriculePaie] = useState();
    const [listRepas, setListRepas] = useState();
    const [listRepasEtat, setListRepasEtat] = useState([]);
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState(today);
    const [valeurTitreRestaurant, setValeurTitreRestaurant] = useState();
    const [partEmployeur, setPartEmployeur] = useState();
    const [modeGestion, setModeGestion] = useState();
    const [nombreTitre, setNombreTitre] = useState();


    const [dateDebutDelimiter, setDateDebutDelimiter] = useState();
    const [dateFinDelimiter, setDateFinDelimiter] = useState();
    const [valeurTitreRestaurantDelimiter, setValeurTitreRestaurantDelimiter] = useState();
    const [partEmployeurDelimiter, setPartEmployeurDelimiter] = useState();
    const [modeGestionDelimiter, setModeGestionDelimiter] = useState();
    const [nombreTitreDelimiter, setNombreTitreDelimiter] = useState();

    const [idModifier, setIdModifier] = useState();
    const [idDelimiter, setIdDelimiter] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();
    const [isIconUp, setIsIconUp] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])

    useEffect(() => {
      if(matriculePaie){
        APIService.getRepasPaie(matriculePaie, token).then(resp => setListRepas(resp));
      }
    }, [matriculePaie, listRepasEtat])

    useEffect(() => {

        if(JSON.stringify(listRepasEtat) !== JSON.stringify(listRepas)){
            setListRepasEtat(listRepas)
        }
    }, [listRepas])
    


    const btnEnregistrer = () => {
        let body = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            valeurTitreRestaurant : "",
            partEmployeur : "",
            modeGestion : "",
            nombreTitre : ""
        }

        let bodyDelimiter = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            valeurTitreRestaurant : "",
            partEmployeur : "",
            modeGestion : "",
            nombreTitre : ""
        }

        if(dateDebut && (dateFin || today) && valeurTitreRestaurant && partEmployeur && modeGestion && nombreTitre){
            body.matricule_employe = matriculePaie;
            body.matricule_manager = matriculePaie;
            body.dateDebut = dateDebut;
            if(dateFin) {
                body.dateFin = dateFin
            } else {
                body.dateFin = today
            }
            body.valeurTitreRestaurant = valeurTitreRestaurant;
            body.partEmployeur = partEmployeur;
            body.modeGestion = modeGestion;
            body.nombreTitre = nombreTitre;


            if(idModifier){

                APIService.putRepasPaie(idModifier, body, token);
                setIdModifier();

            } else {
                if(idDelimiter){

                    bodyDelimiter.matricule_employe = matriculePaie;
                    bodyDelimiter.matricule_manager = matriculePaie;
                    bodyDelimiter.dateDebut = dateDebutDelimiter;
                    bodyDelimiter.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                    bodyDelimiter.valeurTitreRestaurant = valeurTitreRestaurant;
                    bodyDelimiter.partEmployeur = partEmployeur;
                    bodyDelimiter.modeGestion = modeGestion;
                    bodyDelimiter.nombreTitre = nombreTitre;
                   

                    APIService.putRepasPaie(idDelimiter, bodyDelimiter, token);
                    APIService.postRepasPaie(body, token);

                    setIdDelimiter();

                } else {
                    listRepas.map(
                        resp => {
                            let bodyTemp = {
                                matricule_employe : "",
                                matricule_manager : "", 
                                dateDebut : "",
                                dateFin : "",
                                valeurTitreRestaurant : "",
                                partEmployeur : "",
                                modeGestion : "",
                                nombreTitre : ""
                            }


                            if(new Date(resp.dateFin) > new Date(dateDebut)){
                                bodyTemp.matricule_employe = resp.matricule_employe;
                                bodyTemp.matricule_manager = resp.matricule_manager;
                                bodyTemp.dateDebut = resp.dateDebut;
                                bodyTemp.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                                bodyTemp.valeurTitreRestaurant = resp.valeurTitreRestaurant;
                                bodyTemp.partEmployeur = resp.partEmployeur;
                                bodyTemp.modeGestion = resp.modeGestion;
                                bodyTemp.nombreTitre = resp.nombreTitre;
                   

                                APIService.putRepasPaie(resp.id, bodyTemp, token);

                            }
                        }
                    )
                    APIService.postRepasPaie(body, token);

                }
            }

            APIService.getRepasPaie(matriculePaie, token).then(resp => {setListRepas(resp); setListRepasEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deleteRepasPaie(idRem, token);
        APIService.getRepasPaie(matriculePaie, token).then(resp => {setListRepas(resp); setListRepasEtat(resp);});

        setIdModifier();
        setIdDelimiter();
        
    }

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listRepas.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setDateDebut(resp.dateDebut);
            setDateFin(resp.dateFin);
            setValeurTitreRestaurant(resp.valeurTitreRestaurant);
            setPartEmployeur(resp.partEmployeur);
            setModeGestion(resp.modeGestion);
            setNombreTitre(resp.nombreTitre);

            setIdDelimiter();

        })


    }

    
    

    const btnDelimiter = (e) => {

        if(!etatAjouter) {
            setEtatAjouter(true);
        }

        listRepas.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdDelimiter(resp.id);
            setDateDebutDelimiter(resp.dateDebut);
            setDateFinDelimiter(resp.dateFin);
            setValeurTitreRestaurantDelimiter(resp.valeurTitreRestaurant);
            setPartEmployeurDelimiter(resp.partEmployeur);
            setModeGestionDelimiter(resp.modeGestion);
            setNombreTitreDelimiter(resp.nombreTitre);

            setDateDebut("");
            setDateFin(resp.dateFin);
            setValeurTitreRestaurant(resp.valeurTitreRestaurant);
            setPartEmployeur(resp.partEmployeur);
            setModeGestion(resp.modeGestion);
            setNombreTitre(resp.nombreTitre);

            setIdModifier();


        })
    }


    const btnAjouter = () => {
        setIsIconUp(prevState => !prevState);
        if(etatAjouter){
            setEtatAjouter(false);
        } else {
            setDateDebut("")
            setDateFin("")
            setValeurTitreRestaurant("");
            setPartEmployeur("");
            setModeGestion("");
            setNombreTitre("");
            setEtatAjouter(true);
        }

        setIdModifier();
        setIdDelimiter();
    }
    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }

    return(
        <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart}>
            <TopMenuPaie page="repas"/>
            </div>
            <div className={styles.secondPart}>
                    <div className={styles.tableAndFormSection}>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    {
                                        idDelimiter ?
                                        <div className={styles.modifiableTexte2}>
                                            <i class="fa-solid fa-scissors"></i> &nbsp;
                                            Délimiter</div> :<></>
                                    }
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                     <div className={styles.modern_container}>
                                        <div className={styles.modern_inputs}>
                                        <span className={styles.modern_label}>
                                        Période
                                        </span>
                                        <div class={styles.modern_input_wrapper}>
                                            <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                                            <span className={styles.timeSeparator}>-</span>
                                            <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                        </div>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Valeur du titre restaurant
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={valeurTitreRestaurant} onChange={(e) => setValeurTitreRestaurant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Part payée par l'employeur
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={partEmployeur} onChange={(e) => setPartEmployeur(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Mode de gestion
                                                </div>
                                                <select className={styles.goldenInput1} value={modeGestion} onChange={e => setModeGestion(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Mode 1">Mode 1</option>
                                                                            <option value="Mode 2">Mode 2</option>
                                                                        </select>
                                            </div>
                                            </div>
                                            <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Nombre de titre
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreTitre} onChange={(e) => setNombreTitre(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Valeur du titre</th>
                                        <th>Nombre du titre</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listRepas ? 
                                    
                                        listRepas.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
                                                <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
                                                <td>{resp.valeurTitreRestaurant}</td>
                                                <td>{resp.nombreTitre}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>


                                                                &nbsp;

                                                                <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
                                                                <i class="fa-solid fa-scissors"></i>
                                                                    </button>

                                                        </>:<></>
                                                        }

                                                    </td>
                                                    
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                            </div>
                    </div>
                    {/* **************** Mobile ******** */}
                    <div className={styles.tableAndFormSectionMobile}>
                        <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Valeur du titre</th>
                                        <th>Nombre du titre</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listRepas ? 
                                    
                                        listRepas.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
                                                <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
                                                <td>{resp.valeurTitreRestaurant}</td>
                                                <td>{resp.nombreTitre}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>

                                                            
                                                                &nbsp;
                                                            
                                                                <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
                                                                <i class="fa-solid fa-scissors"></i>
                                                                    </button>
                                                                
                                                        </>:<></>
                                                        }
                                                    
                                                    


                                                    
                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                        </div>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    {
                                        idDelimiter ?
                                        <div className={styles.modifiableTexte2}>
                                            <i class="fa-solid fa-scissors"></i> &nbsp;
                                            Délimiter</div> :<></>
                                    }
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                    <div className={styles.modern_container}>
                                        <div className={styles.modern_inputs}>
                                        <span className={styles.modern_label}>
                                        Période
                                        </span>
                                        <div class={styles.modern_input_wrapper}>
                                            <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                                            <span className={styles.timeSeparator}>-</span>
                                            <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                        </div>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Valeur du titre restaurant
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={valeurTitreRestaurant} onChange={(e) => setValeurTitreRestaurant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Part payée par l'employeur
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={partEmployeur} onChange={(e) => setPartEmployeur(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Mode de gestion
                                                </div>
                                                <select className={styles.goldenInput1} value={modeGestion} onChange={e => setModeGestion(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Mode 1">Mode 1</option>
                                                                            <option value="Mode 2">Mode 2</option>
                                                                        </select>
                                            </div>
                                            </div>
                                            <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Nombre de titre
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={nombreTitre} onChange={(e) => setNombreTitre(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            
                    </div>
            </div>
        </div>
    </div>
       
    )
}

export default RepasSection;