import React from 'react';
import styles from './CardStyle.module.css';

function CardStyle(props) {
  return (
    <div className={styles.classCard}>
        {/* <div className={styles.classCard}>

        <div className={styles.topBar}>

        </div>
        <div className={styles.classSection}>
            {props.section}
        </div>

        </div> */}
          {props.section}
    </div>
    
  )
}

export default CardStyle