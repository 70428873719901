import react, {useEffect, useState} from "react";
import styles from "./NoteFraisSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";


function NoteFraisSection(){
    const today = new Date("2999-12-31").toISOString().slice(0, 10);
    const navigate = useNavigate();

    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const [matriculePaie, setMatriculePaie] = useState();
    const [listNoteFrais, setListNoteFrais] = useState();
    const [listNoteFraisEtat, setListNoteFraisEtat] = useState([]);
    const [nomNoteFrais, setNomNoteFrais] = useState();
    const [typeNote, setTypeNote] = useState();
    const [justificatif, setJustificatif] = useState([]);
    const [date, setDate] = useState();
    const [montant, setMontant] = useState();
    const [tva, setTva] = useState();
    const [dateRemboursement, setDateRemboursement] = useState();
    const [isIconUp, setIsIconUp] = useState(false);
    const [idModifier, setIdModifier] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();

    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])

    useEffect(() => {
      if(matriculePaie){
        APIService.getNoteFraisPaie(matriculePaie, token).then(resp => setListNoteFrais(resp));
      }
    }, [matriculePaie, listNoteFraisEtat])

    useEffect(() => {

        if(JSON.stringify(listNoteFraisEtat) !== JSON.stringify(listNoteFrais)){
            setListNoteFraisEtat(listNoteFrais)
        }
    }, [listNoteFrais])
    


    const btnEnregistrer = () => {

        if(nomNoteFrais && typeNote && justificatif && date && montant && tva && dateRemboursement){
            var formData = new FormData();
            formData.append("matricule_employe", matriculePaie);
            formData.append("matricule_manager", matriculePaie);
            formData.append("nomNoteFrais", nomNoteFrais);
            formData.append("typeNote", typeNote);
            formData.append("justificatif", justificatif);  
            formData.append("montant", montant);
            formData.append("tva", tva);
            formData.append("date", date);
            formData.append("dateRemboursement", dateRemboursement);

            if(idModifier){

                APIService.putNoteFraisPaie(idModifier, formData, token);
                setIdModifier();
            } else {
                                    
                    APIService.postNoteFraisPaie(formData, token);

                
            }

            APIService.getNoteFraisPaie(matriculePaie, token).then(resp => {setListNoteFrais(resp); setListNoteFraisEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deleteNoteFraisPaie(idRem, token);
        APIService.getNoteFraisPaie(matriculePaie, token).then(resp => {setListNoteFrais(resp); setListNoteFraisEtat(resp);});

        setIdModifier();
        
    }

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listNoteFrais.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setNomNoteFrais(resp.nomNoteFrais);
            setTypeNote(resp.typeNote);
            setJustificatif(resp.justificatif);
            setDate(resp.date);
            setMontant(resp.montant);
            setTva(resp.tva);
            setDateRemboursement(resp.dateRemboursement);

        })
    }

    
    

    


    const btnAjouter = () => {
        setIsIconUp(prevState => !prevState);   
        if(etatAjouter){
            setEtatAjouter(false);
        } else {
            setNomNoteFrais("")
            setTypeNote("")
            setJustificatif([])
            setDate("")
            setMontant("")
            setTva("")
            setDateRemboursement("")

            setEtatAjouter(true);
        }

        setIdModifier();
    }
    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }

    return(
        <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart}>
                <TopMenuPaie page="Notefrais"/>
            </div>
            <div className={styles.secondPart}>
                    <div className={styles.tableAndFormSection}>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Nom de la note de frais
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={nomNoteFrais} onChange={(e) => setNomNoteFrais(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Type de note
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={typeNote} onChange={(e) => setTypeNote(e.target.value)}/>
                                        </div>
                                    </div>
                                    
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={date} onChange={(e) => setDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            TVA
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={tva} onChange={(e) => setTva(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date de remboursement
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateRemboursement} onChange={(e) => setDateRemboursement(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div id={styles.idDivCmnt}>
                                       <div className={styles.texte}> Pièce justificative : </div>
                                       <div className={styles.container1_}>
                                          <div className={styles.zoneInput_}>
                                            <label htmlFor="file-input" className={styles.fileInputLabel} title="Veuillez téléverser le fichier : la pièce justificative . ">
                                            <i class="fa-solid fa-arrow-up-from-bracket fa-lg" style={{color: "#ffffff", textAlign:"center",alignItems:"center"}}></i>
                                            </label>
                                            <input className={styles.goldenInput1_} id="file-input"  type="file" onChange={(e) => setJustificatif(e.target.files[0])} />
                                          </div>
                                      </div>
                                    </div>
                                    
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Libellé</th>
                                        <th>Type de note de frais</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listNoteFrais ? 
                                    
                                        listNoteFrais.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.date).format("DD/MM/YYYY")}</td>
                                                <td>{resp.nomNoteFrais}</td>
                                                <td>{resp.typeNote}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>
                                                                &nbsp;
                                                        </>:<></>
                                                        }

                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                            </div>
                    </div>
                    {/* **************** Mobile ******** */}
                    <div className={styles.tableAndFormSectionMobile}>
                        <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Libellé</th>
                                        <th>Type de note de frais</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listNoteFrais ? 
                                    
                                        listNoteFrais.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.date).format("DD/MM/YYYY")}</td>
                                                <td>{resp.nomNoteFrais}</td>
                                                <td>{resp.typeNote}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>

                                                            
                                                                &nbsp;
                                                                
                                                        </>:<></>
                                                        }
                                                    
                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                        </div>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Nom de la note de frais
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={nomNoteFrais} onChange={(e) => setNomNoteFrais(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Type de note
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={typeNote} onChange={(e) => setTypeNote(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={date} onChange={(e) => setDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            TVA
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={tva} onChange={(e) => setTva(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Date de remboursement
                                            </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateRemboursement} onChange={(e) => setDateRemboursement(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div id={styles.idDivCmnt}>
                                       <div className={styles.texte}> Pièce justificative : </div>
                                       <div className={styles.container1_}>
                                          <div className={styles.zoneInput_}>
                                            <label htmlFor="file-input" className={styles.fileInputLabel} title="Veuillez téléverser le fichier : la pièce justificative . ">
                                            <i class="fa-solid fa-arrow-up-from-bracket fa-lg" style={{color: "#ffffff", textAlign:"center",alignItems:"center"}}></i>
                                            </label>
                                            <input className={styles.goldenInput1_} id="file-input"  type="file" onChange={(e) => setJustificatif(e.target.files[0])} />
                                          </div>
                                      </div>
                                    </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            
                    </div>
            </div>
        </div>
    </div>
      
    )
}

export default NoteFraisSection;