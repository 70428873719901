import React, { useState, useEffect } from "react";
import styles from './DescriptionPaieComponent.module.css';
import APIService from "../../../APIService";
import {useCookies} from 'react-cookie';


function DescriptionPaieComponent(props) {
  const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie', 'id', 'matriculeEmployeAttente']);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [matricule, setMatricule] = useState('');


console.log("matricule paie ", token['matriculePaie'])

   useEffect(() => {
      APIService.getEmployes(token)
        .then(data => {
          console.log('Employee Data: tttest', data);
          const matchingEmployee = data.find(employee => employee.matricule_employe === token['matriculePaie']);
          console.log("tessst des",matchingEmployee)

          if (matchingEmployee) {
            setMatricule(matchingEmployee.matricule_employe);
            setNom(matchingEmployee.nom_employe);
            setPrenom(matchingEmployee.prenom_employe);
            
          } 
        })
        .catch(error => {
          console.error('Error fetching employee data:', error);
        });
    }, [token]);  

  return (
    
     <span className={styles.inputgroup}>
       <a href="http://localhost:3000/paie" className={styles.link}>
        {/* <span  className={styles.label} > */}
         {matricule} - {nom} {prenom}
         {/* </span> */}
         </a>
     </span>
  )
}

export default DescriptionPaieComponent