import React from "react";
// import NavBarGlobal from "../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import AvenantSection from "./AvenantSection.jsx";
import NavBarGlobal from "../../../Global/NavBar/NavBarGlobal/NavBarGlobal";



const Avenant = () => {
  return (
    <div>
    <NavBarGlobal section = {<AvenantSection />} />
    </div>
  )
}
export default Avenant;