import React from "react";
import styles from './PaginationTable.module.css';

function PaginationTable(props) {

  return (
    <>
    <span className={styles.inputgroup2}>
    <span className={styles.centerBottomBar}>
        <span className={styles.pagination} >
        { props.nombrePageArray.length >=2 ?
            <span className={styles.inputgroup1}>
              <span className={styles.pagination} >
              {props.nombrePageArray && props.nombrePageArray[0] === props.page  ? 
              <button className={styles.btnPaginationLeftDisabled}  onClick={() => {props.setPage(props.page-1)}} disabled> 
                <i class="fa-solid fa-chevron-left" className={styles.arrowLeftStyle} id={styles.disbaledArrowLeft}></i>
              </button>
              : 
              <button className={styles.btnPaginationLeft}  onClick={() => {props.setPage(props.page-1)}}> 
                <i class="fa-solid fa-chevron-left"  id={styles.arrowLeft}></i>

              </button>
              }
              { props.page > 3 ? 
              <div className={styles.buttonsStyle}>
                ...
                </div>
               :<></>}
              {props.nombrePageArray ? props.nombrePageArray.map(nbr => 
              <>
                      {  
                      Math.abs(props.page - nbr) <= 2 ? 
                      <button className={
                          props.page === nbr ? styles.classSelectedPageNumber : styles.classPageNumber
                      } onClick={() => {props.setPage(nbr)}}>{nbr}</button> : <></>
                      }
              </>
              ) : <></>} 
              { props.nombrePageArray && props.nombrePageArray.length-props.page > 3 ? 
              <div className={styles.buttonsStyle}>

                ...
                </div>
               :<></>}
              {props.nombrePageArray && props.nombrePageArray[props.nombrePageArray.length - 1] === props.page  ? 

              <button className={styles.btnPaginationDisabled}  onClick={() => {props.setPage(props.page+1)}} disabled>
                <i class="fa-solid fa-chevron-right"  id={styles.disbaledArrowRight}></i>
                </button>
              : 
              <button className={styles.btnPagination}  onClick={() => {props.setPage(props.page+1)}}>
                <i class="fa-solid fa-chevron-right" style={{ color:"grey"}} id={styles.arrowRight}></i>
                </button>
              }
              </span>
            </span>
: <span></span>
}       
        </span>
    </span>
    </span>
    <span className={styles.inputgroup2Mobile}>
    <span className={styles.centerBottomBar}>
        <span className={styles.pagination} >
        { props.nombrePageArray.length >=1 ?
            <span className={styles.inputgroup1}>
              <span className={styles.pagination} >
              {props.nombrePageArray && props.nombrePageArray[0] === props.page  ? 
              <button className={styles.btnPaginationLeftDisabled}  onClick={() => {props.setPage(props.page-1)}} disabled> 
                <i class="fa-solid fa-chevron-left" className={styles.arrowLeftStyle} id={styles.disbaledArrowLeft}></i>
              </button>
              : 
              <button className={styles.btnPaginationLeft}  onClick={() => {props.setPage(props.page-1)}}> 
                <i class="fa-solid fa-chevron-left"  id={styles.arrowLeft}></i>

              </button>
              }
              { props.page > 1 ? 
              <div className={styles.buttonsStyle}>
                ...
                </div>
               :<></>}
              {props.nombrePageArray ? props.nombrePageArray.map(nbr => 
              <>
                      {  
                      Math.abs(props.page - nbr) <= 1 ? 
                      <button className={
                          props.page === nbr ? styles.classSelectedPageNumber : styles.classPageNumber
                      } onClick={() => {props.setPage(nbr)}}>{nbr}</button> : <></>
                      }
              </>
              ) : <></>} 
              { props.nombrePageArray && props.nombrePageArray.length-props.page > 0 ? 
              <div className={styles.buttonsStyle}>

                ...
                </div>
               :<></>}
              {props.nombrePageArray && props.nombrePageArray[props.nombrePageArray.length - 1] === props.page  ? 

              <button className={styles.btnPaginationDisabled}  onClick={() => {props.setPage(props.page+1)}} disabled>
                <i class="fa-solid fa-chevron-right"  id={styles.disbaledArrowRight}></i>
                </button>
              : 
              <button className={styles.btnPagination}  onClick={() => {props.setPage(props.page+1)}}>
                <i class="fa-solid fa-chevron-right" style={{ color:"grey"}} id={styles.arrowRight}></i>
                </button>
              }
              </span>
            </span>
: <span></span>
}       
        </span>
    </span>
    </span>
    </>
  )
}
export default PaginationTable