import React from 'react';
import styles from './GloabalComponentSalarie.module.css';
import NavBarGlobal from "./../../../Global/NavBar/NavBarGlobal/NavBarGlobal";
import CardStyle from "./../../../Global/CardVide/CardStyle";



function GloabalComponentSalarie(props) {
  return (
    <div 
    // className={styles.divContainer}
    >

      <NavBarGlobal section={<CardStyle section = {props.section}/>}/>

    </div>
  )
}

export default GloabalComponentSalarie;