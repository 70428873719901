import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import HeuresSupplementairesSection from "./HeuresSupplementairesSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function HeuresSupplemenatires(){

    return(
        <div>
            <NavBarGlobal  section = {<HeuresSupplementairesSection />}/>
        </div>
    )
}

export default HeuresSupplemenatires;