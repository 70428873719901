
import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import PaysSection from './PaysSection.jsx';



export const Pays = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<PaysSection />} />
    
   
 </div>
)}


export default Pays


