import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import SituationFamilialeAdministrationSection from './SituationFamilialeAdministrationSection.jsx';



export const SituationFamilialeAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<SituationFamilialeAdministrationSection />} />
    
    
   
 </div>
)}


export default SituationFamilialeAdministration
