import React, {useState, useEffect} from 'react';
import styles from "./PlanningAbsenceSection.module.css";
import moment from 'moment';
import {useCookies} from 'react-cookie';
import APIService from './../../../APIService';
import NavBarGlobal from '../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal';
import {faExclamation, faWarning, faXmark} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free/css/all.min.css';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import TopMenuGestionDuTemps from '../TopMenuGestionDuTemps';


function PlanningAbsenceSection() {
    const listMois_ = {1: "Janvier", 2: "Février", 3: "Mars", 4: "Avril", 5: "Mai", 6: "Juin", 7: "Juillet", 8: "Août", 9: "Septembre", 10: "Octobre", 11: "Novembre", 12: "Décembre"}  
    var total = {1:0, 2:0, 3:0, 4:0, 5:0, 6:0, 7:0, 8:0, 9:0, 10:0, 11:0, 12:0, 13:0, 14:0, 15:0, 16:0, 17:0, 18:0, 19:0, 20:0, 21:0, 22:0, 23:0, 24:0, 25:0, 26:0, 27:0, 28:0, 29:0, 30:0, 31:0}
    const listJrs = {0:"DI", 1:"LU", 2:"MA", 3:"ME", 4:"JE", 5:"VE", 6:"SA"};

    const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel', 'absenceList', 'idAbsence']);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1)
    const [numberDays, setNumberDays] = useState(new Date(currentYear, currentMonth, 0).getDate())
    const [listHistoriqueAbsence, setListHistoriqueAbsence] = useState([]);
    const [listDebutHist, setListDebutHist] = useState()
    const [listFinHist, setListFinHist] = useState()
    const [listNomPrenomResult, setListNomPrenomResult] = useState()
    const [mapListMatriculeResult, setMapListMatriculeResult] = useState()
    const [rechercheValidation, setRechercheValidation] = useState()
    const [role, setRole] = useState();
    var listEmpHistDateDebut = {}
    var listEmpHistDateFin = {}
    var listNomPrenom = []
    var iter = 0;
    var mapListMatricule = []
    const [currentMonth_, setCurrentMonth_] = useState(new Date().getMonth());
    const [currentYear_, setCurrentYear_] = useState(new Date().getFullYear());
    const [isMonthSelected_, setIsMonthSelected_] = useState(true);

    const handleMonthClick = (monthIndex) => {
      setCurrentMonth_(monthIndex);
      setIsMonthSelected_(true);
    }
  
    const handleYearClick = () => {
      setIsMonthSelected_(false);
    }
  
    const btnPrecedant_ = () => {
        if (isMonthSelected_) {
            if (currentMonth_ === 1) {
              setCurrentYear_(prevYear => prevYear - 1);
              setCurrentMonth_(12);
            } else {
              setCurrentMonth_(prevMonth => prevMonth - 1);
            }
          } else {
            setCurrentYear_(prevYear => prevYear - 1);
          }
      }
    
      const btnSuivant_ = () => {
        if (isMonthSelected_) {
            if (currentMonth_ === 12) {
              setCurrentYear_(prevYear => prevYear + 1);
              setCurrentMonth_(1);
            } else {
              setCurrentMonth_(prevMonth => prevMonth + 1);
            }
          } else {
            setCurrentYear_(prevYear => prevYear + 1);
          }
      }

      useEffect(() => {
        setCurrentYear_(new Date().getFullYear());
        setCurrentMonth_(new Date().getMonth() + 1);
    }, []);
    

    useEffect(() => {
        if(rechercheValidation){
            if(rechercheValidation === "valider"){
                APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence === true)))
            }else{
                if(rechercheValidation === "attente"){
                    APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence === null)))
                }else{
                    if(rechercheValidation === "all"){
                        APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && (resp.action_demandeAbsence === true || resp.action_demandeAbsence === null))))
                    }
                }
            }
        }
      
    }, [rechercheValidation])
    





 
    
    function getId(matricule, dateActuelle) {
        for(let v in listHistoriqueAbsence){
            if(listHistoriqueAbsence[v]["matricule_employe"] === matricule
            && (new Date(listHistoriqueAbsence[v]["rubriqueDateDebut_demandeAbsence"]).getTime() <= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime() 
            && new Date(listHistoriqueAbsence[v]["rubriqueDateFin_demandeAbsence"]).getTime() >= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime())){

                return listHistoriqueAbsence[v]["id"];

            }
        }
    }



    function getVerificationStyle(matricule, dateActuelle){

        for(let v in listHistoriqueAbsence){
            if(listHistoriqueAbsence[v]["matricule_employe"] === matricule
            && (new Date(listHistoriqueAbsence[v]["rubriqueDateDebut_demandeAbsence"]).getTime() <= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime() 
            && new Date(listHistoriqueAbsence[v]["rubriqueDateFin_demandeAbsence"]).getTime() >= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime())){

                return listHistoriqueAbsence[v]["action_demandeAbsence"];

            }


        }

        // listHistoriqueAbsence.filter(resp => resp.matricule_employe === matricule && (new Date(resp.rubriqueDateDebut_demandeAbsence).getTime() <= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime() && new Date(resp.rubriqueDateFin_demandeAbsence).getTime() >= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime())).map(
        //     resp => {return resp.nom_employe;}
        // )

    }

    function getVerification(listDebut, listFin, dateActuelle, matricule, jour){
        let etat = false;

        for (let index = 0; index < listDebut[matricule].length; index++) {
            
            if(new Date(listDebut[matricule][index]).getTime() <= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime() && new Date(listFin[matricule][index]).getTime() >= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime()){
                etat = true;
                total[jour] +=1 
                break; 
            }
            
        }

        return etat;
    }

    function getKeysList(list) {

        let listKeys = []

        for(let v in list){
            listKeys.push(v)
        }

        return listKeys

    }

    useEffect(() => {
            if(listHistoriqueAbsence){

                listHistoriqueAbsence.map(resp => {

                    if(getKeysList(listEmpHistDateDebut).includes(resp.matricule_employe)){
                        listEmpHistDateDebut[resp.matricule_employe].push(resp.rubriqueDateDebut_demandeAbsence)
                        listEmpHistDateFin[resp.matricule_employe].push(resp.rubriqueDateFin_demandeAbsence)
                    }else{
                        
                        listEmpHistDateDebut[resp.matricule_employe] = [resp.rubriqueDateDebut_demandeAbsence]
                        listEmpHistDateFin[resp.matricule_employe] = [resp.rubriqueDateFin_demandeAbsence]

                        listNomPrenom[resp.matricule_employe] = [resp.nom_employe]
                        listNomPrenom[resp.matricule_employe].push(resp.prenom_employe)




                    }
                    
                    
                })

                setListDebutHist(listEmpHistDateDebut)
                setListFinHist(listEmpHistDateFin)

                console.log(listNomPrenom)

                for(let matricule in listNomPrenom){
                    mapListMatricule.push({matricule})
                }

                setMapListMatriculeResult(mapListMatricule)

                setListNomPrenomResult(listNomPrenom)




            }
      
    }, [listHistoriqueAbsence])

    useEffect(() => {

        console.log("mapListMatriculeResult", mapListMatriculeResult)
      
    }, [mapListMatriculeResult])
    
    

    useEffect(() => {
        if(token['mytoken']){
            APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && (resp.action_demandeAbsence === true || resp.action_demandeAbsence === null))));
            APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));

        }
    }, [])


    
  useEffect(() => {

    console.log("ok", token['matriculeActuel'])
    if(role && token['matriculeActuel']){
      if(role === "Salarie"){

        // setMatriculeTargetDeclaration(token["matriculeActuel"]);


      

        if(document.getElementById("listeTaches")){
            let elem = document.getElementById("listeTaches");
            elem.parentNode.removeChild(elem);
          }
        if(document.getElementById("historiqueAbsence")){
            let elem = document.getElementById("historiqueAbsence");
            elem.parentNode.removeChild(elem);
          }
        if(document.getElementById("historiqueActivite")){
          let elem = document.getElementById("historiqueActivite");
          elem.parentNode.removeChild(elem);
        }
        if(document.getElementById("dashboard")){
          let elem = document.getElementById("dashboard");
          elem.parentNode.removeChild(elem);
        }
      }
    }
  }, [role, token])


    
    

    
    const btnSuivantMois = () => {

        if(currentMonth === 12){
            setCurrentMonth(1)
        }else{
            setCurrentMonth(currentMonth + 1)

        }
    }

    const btnPrecedantMois = () => {

        if(currentMonth === 1){
            setCurrentMonth(12)
        }else{
            setCurrentMonth(currentMonth - 1)

        }
    }

    useEffect(() => {

      if(currentMonth_){
        setNumberDays(new Date(currentYear_, currentMonth_, 0).getDate())
      }
    }, [currentMonth_])
    
    

const [nommerEtat, setNommerEtat] = useState(false);
const [colVides, setColVides] = useState([]);




useEffect(() => {

    if(mapListMatriculeResult){

        var arryCol = []

        console.log("lenght : ", mapListMatriculeResult.length)
        for(let i = 5; i>mapListMatriculeResult.length; i--){
            arryCol.push(<tr>
                                                    <td className={styles.nomLigne_}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    <td className={styles.celluleVide}></td>
                                                    {
                                                      numberDays === 31 ?
                                                      <>
                                                        <td className={styles.celluleVide}></td>
                                                        <td className={styles.celluleVide}></td>
                                                        <td className={styles.celluleVide}></td>
                                                      </>:
                                                      <></>
                                                    }

{
                                                      numberDays === 30 ?
                                                      <>
                                                        <td className={styles.celluleVide}></td>
                                                        <td className={styles.celluleVide}></td>
                                                      </>:
                                                      <></>
                                                    }

{
                                                      numberDays === 29 ?
                                                      <>
                                                       
                                                        <td className={styles.celluleVide}></td>
                                                      </>:
                                                      <></>
                                                    }





            </tr>)

            
        }

        if(arryCol){
            setColVides(arryCol)
        } else {
            setColVides(null)
        }
    }
  
}, [mapListMatriculeResult, numberDays])

  return (
    <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart} style={{zIndex: 2}}>
            <TopMenuGestionDuTemps page="planning"/>
            </div>
            <div className={styles.secondPart}>
                <div className={styles.dividePartTwo}>
                    <div className={styles.first}>
                            <div className={styles.dateContainer}>
                                <button className={styles.idBtnIcon} onClick={btnPrecedant_}>
                                    <i class="fa-solid fa-angle-left"></i> 
                                </button>
                                <span className={styles.dateText}>
                                    <span onClick={handleMonthClick.bind(null, currentMonth_)}
                                    className={isMonthSelected_ ? styles.boldText : null}>
                                         {listMois_[currentMonth_]}
                                    </span>
                                    {''}
                                    <span onClick={handleYearClick} className={!isMonthSelected_ ? styles.boldText : null}>
                                         {currentYear_}
                                    </span>
                                </span>
                                <button className={styles.idBtnIcon} onClick={btnSuivant_}>
                                    <i class="fa-solid fa-angle-right"></i>
                                </button>
                            </div>
                    </div>
                    <div className={styles.second}>
                        <div className={styles.containerCheckbox}>
                        <label className={styles.switch} >
                            
                            <input 
                                type="checkbox" 
                                checked={nommerEtat} 
                                onChange={() => setNommerEtat(!nommerEtat)} 
                                style={{visibility: "hidden"}}
                            />
                            <span className={styles.slider} style={{zIndex: 0}}></span>
                        </label>
                        <span className={styles.texteCheckbox}>Les jours</span>
                        </div>
                        <div className={styles.containerCheckboxMobile}>
                        <label className={styles.toggleSwitch}>
                            <input type="checkbox" checked={nommerEtat} onChange={() => setNommerEtat(!nommerEtat)} />
                            <span className={styles.slider}></span>
                            </label>
                            <span className={styles.texteCheckbox}>Les jours</span>
                        </div>
                    </div>
                    <div className={styles.third}>
                        <select className={styles.inputGlobalChoix} onChange={e => setRechercheValidation(e.target.value)}>
                                <option value="all" default>Tous</option>
                                <option value="valider">Valider</option>
                                <option value="attente">En attente</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={styles.thirdPart}>
                <div className={styles.tableContainer}>
                    <table id={styles.tablePlanning}>
                        <thead className={styles.headTable}>
                        <tr>
                            <th id={styles.salarieCellule} className={styles.fixedColumn} >Salarié/jour du mois</th>
                            <th>01 
                                {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-01`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>02
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-02`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>03
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-03`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>04
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-04`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>05
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-05`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>06
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-06`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>07
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-07`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>08
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-08`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>09
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-09`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>10
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-10`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>11
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-11`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>12
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-12`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>13
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-13`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>14
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-14`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>15
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-15`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>16
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-16`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>17
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-17`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>18
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-18`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>19
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-19`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>20
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-20`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>21
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-21`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>22
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-22`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>23
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-23`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>24
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-24`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>25
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-25`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>26
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-26`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>27
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-27`).getDay()]}
                                </>:<></>
                                }
                            </th>
                            <th>28
                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-28`).getDay()]}
                                </>:<></>
                                }
                            </th>


                            {
                                numberDays === 31 ?
                                <>
                                    <th>29
                                    {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-29`).getDay()]}
                                </>:<></>
                                }
                                    </th>
                                    <th>30
                                    {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-30`).getDay()]}
                                </>:<></>
                                }
                                    </th>
                                    <th>31
                                    {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-31`).getDay()]}
                                </>:<></>
                                }
                                    </th>
                                </>:<>

                                {
                                    numberDays === 30 ?
                                    <>
                                        <th>29
                                        {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-29`).getDay()]}
                                </>:<></>
                                }
                                        </th>
                                        <th>30
                                        {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-30`).getDay()]}
                                </>:<></>
                                }
                                        </th>
                                    </>:<>

                                    {
                                        numberDays === 29 ?
                                        <>
                                            <th>29
                                            {
                                    nommerEtat ? 
                                    <>
                                    <br />
                                {listJrs[new Date(`${currentYear_}-${currentMonth_}-29`).getDay()]}
                                </>:<></>
                                }
                                            </th>
                                        </>:<>

                                        

                                        </>

                                    }

                                    

                                    </>

                                }

                                </>

                            }
                            
                        </tr>
                        </thead>
                        <tbody>

                        {
                            mapListMatriculeResult && listDebutHist && listFinHist ? 
                            mapListMatriculeResult.map(
                                mat =>
                                <tr>
                                    <td className={`${styles.nomLigne} ${styles.fixedColumn}`}>{listNomPrenomResult[mat.matricule][0]} {listNomPrenomResult[mat.matricule][1]}</td>
                                    {
                                        new Date(`${currentYear_}-${currentMonth_}-01`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-01`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-01`, mat.matricule, 1) ?
                                                    // new Date(abs.rubriqueDateDebut_demandeAbsence).getTime() <= new Date(moment(new Date(`${currentYear}-${currentMonth_}-01`)).format("YYYY-MM-DD")).getTime() && new Date(abs.rubriqueDateFin_demandeAbsence).getTime() >= new Date(moment(new Date(`${currentYear_}-${currentMonth_}-01`)).format("YYYY-MM-DD")).getTime() ?

                                                    <>
                                                        {
                                                            getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-01`) === true ?
                                                            <td id={styles.idJourAbsenceValider} onClick={
                                                                () => {
                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-01`));
                                                                        window.location.href = '/demandeAbsence'; 
                                                                    }
                                                                }
                                                                }>
                                                                    <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                </td>:<>
                                                                {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-01`) === null ?
                                                                    <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-01`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                             <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                            </td>:<></>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }
                                    {
                                        new Date(`${currentYear_}-${currentMonth_}-02`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-02`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-02`, mat.matricule, 2) ?

                                                    <>
                                                        {
                                                            getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-02`) === true ?
                                                            <td id={styles.idJourAbsenceValider} onClick={
                                                                () => {
                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-02`));
                                                                        window.location.href = '/demandeAbsence'; 
                                                                    }
                                                            }
                                                                }>
                                                                    <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                </td>:<>
                                                                {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-02`) === null ?
                                                                    <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-02`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                             <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<></>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-03`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-03`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-03`, mat.matricule, 3) ?
                                                
                                                    <>
                                                        {
                                                            getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-03`) === true ?
                                                            <td id={styles.idJourAbsenceValider} onClick={
                                                                () => {
                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-03`));
                                                                        window.location.href = '/demandeAbsence'; 
                                                                    } 
                                                            }
                                                                }>
                                                                    <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                </td>:<>
                                                                {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-03`) === null ?
                                                                    <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-03`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                             <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<></>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-04`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-04`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-04`, mat.matricule, 4) ?
                                                
                                                    <>
                                                        {
                                                            getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-04`) === true ?
                                                            <td id={styles.idJourAbsenceValider} onClick={
                                                                () => {
                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-04`));
                                                                        window.location.href = '/demandeAbsence'; 
                                                                    } 
                                                            }
                                                                }>
                                                                    <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                </td>:<>
                                                                {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-04`) === null ?
                                                                    <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-04`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                        }
                                                                        }>
                                                                             <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<></>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-05`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-05`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-05`, mat.matricule, 5) ?
                                                
                                                    <>
                                                        {
                                                            getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-05`) === true ?
                                                            <td id={styles.idJourAbsenceValider} onClick={
                                                                () => {
                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-05`));
                                                                        window.location.href = '/demandeAbsence'; 
                                                                    }
                                                            }
                                                                }>
                                                                    <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                </td>:<>
                                                                {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-05`) === null ?
                                                                    <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-05`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                             <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<></>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-06`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-06`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-06`, mat.matricule, 6) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-06`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-06`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-06`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-06`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-07`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-07`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-07`, mat.matricule, 7) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-07`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-07`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-07`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-07`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                        <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-08`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-08`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-08`, mat.matricule, 8) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-08`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-08`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-08`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-08`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-09`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-09`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-09`, mat.matricule, 9) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-09`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-09`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-09`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-09`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }}
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-10`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-10`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-10`, mat.matricule, 10) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-10`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-10`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-10`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-10`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-11`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-11`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-11`, mat.matricule, 11) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-11`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-11`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-11`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-11`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-12`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-12`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-12`, mat.matricule, 12) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-12`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-12`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-12`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-12`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-13`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-13`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-13`, mat.matricule, 13) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-13`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-13`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-13`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-13`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-14`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-14`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-14`, mat.matricule, 14) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-14`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-14`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-14`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-14`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>
                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-15`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-15`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-15`, mat.matricule, 15) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-15`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-15`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-15`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-15`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-16`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-16`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-16`, mat.matricule, 16) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-16`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-16`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-16`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-16`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-17`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-17`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-17`, mat.matricule, 17) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-17`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-17`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-17`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-17`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }
                                                                
                                                                
                                                                
                                                                >
                                                                     <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-18`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-18`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-18`, mat.matricule, 18) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-18`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-18`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-18`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-18`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-19`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-19`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-19`, mat.matricule, 19) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-19`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-19`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-19`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-19`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-20`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-20`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-20`, mat.matricule, 20) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-20`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-20`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-20`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-20`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-21`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-21`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-21`, mat.matricule, 21) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-21`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-21`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-21`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-21`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                    }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div> 
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-22`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-22`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-22`, mat.matricule, 22) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-22`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-22`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-22`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-22`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-23`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-23`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-23`, mat.matricule, 23) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-23`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-23`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-23`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-23`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-24`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-24`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-24`, mat.matricule, 24) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-24`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-24`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-24`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-24`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-25`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-25`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-25`, mat.matricule, 25) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-25`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-25`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-25`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-25`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-26`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-26`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-26`, mat.matricule, 26) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-26`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-26`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-26`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-26`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-27`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-27`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-27`, mat.matricule, 27) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-27`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-27`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-27`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-27`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        } 
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>
                                    }{
                                        new Date(`${currentYear_}-${currentMonth_}-28`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-28`).getDay() === 6 ?
                                        <td id={styles.idWeekEndTest}>
                                        <div className={styles.celluleWeekend}>
                                           &nbsp;
                                       </div>
                                   </td>:
                                        <>
                                            
                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-28`, mat.matricule, 28) ?
                                                
                                                <>
                                                    {
                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-28`) === true ?
                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                            () => {
                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-28`));
                                                                    window.location.href = '/demandeAbsence'; 
                                                                }
                                                        }
                                                            }>
                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                            </td>:<>
                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-28`) === null ?
                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                    () => {
                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-28`));
                                                                            window.location.href = '/demandeAbsence'; 
                                                                        }
                                                                }
                                                                    }>
                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                    </td>:<></>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <td className={styles.celluleVide}></td>

                                                }
                                            
                                        </>

                                    }
                                    
                                    
                                    {
                                        numberDays === 31 ?
                                        <>
                                            {
                                                    new Date(`${currentYear_}-${currentMonth_}-29`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-29`).getDay() === 6 ?
                                                    <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                                    <>
                                                        
                                                            {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-29`, mat.matricule, 29) ?
                                                            
                                                            <>
                                                                {
                                                                    getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-29`) === true ?
                                                                    <td id={styles.idJourAbsenceValider} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-29`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                            <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<>
                                                                        {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-29`) === null ?
                                                                            <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                                () => {
                                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-29`));
                                                                                        window.location.href = '/demandeAbsence'; 
                                                                                    }
                                                                            }
                                                                                }>
                                                                                     <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                                </td>:<></>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                                :
                                                                <td className={styles.celluleVide}></td>

                                                            }
                                                        
                                                    </>

                                                }{
                                                    new Date(`${currentYear_}-${currentMonth_}-30`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-30`).getDay() === 6 ?
                                                    <td id={styles.idWeekEndTest}>
                                                    <div className={styles.celluleWeekend}>
                                                       &nbsp;
                                                   </div>
                                               </td>:
                                                    <>
                                                        
                                                            {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-30`, mat.matricule, 30) ?
                                                            
                                                            <>
                                                                {
                                                                    getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-30`) === true ?
                                                                    <td id={styles.idJourAbsenceValider} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-30`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                            <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<>
                                                                        {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-30`) === null ?
                                                                            <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                                () => {
                                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-30`));
                                                                                        window.location.href = '/demandeAbsence'; 
                                                                                    }
                                                                            }
                                                                                }>
                                                                                     <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                                </td>:<></>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                                :
                                                                <td className={styles.celluleVide}></td>

                                                            }
                                                        
                                                    </>

                                                }{
                                                    new Date(`${currentYear_}-${currentMonth_}-31`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-31`).getDay() === 6 ?
                                                    <td id={styles.idWeekEndTest}>
                                                    <div className={styles.celluleWeekend}>
                                                       &nbsp;
                                                   </div>
                                               </td>:
                                                    <>
                                                        
                                                            {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-31`, mat.matricule, 31) ?
                                                            
                                                            <>
                                                                {
                                                                    getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-31`) === true ?
                                                                    <td id={styles.idJourAbsenceValider} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-31`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                            <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<>
                                                                        {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-31`) === null ?
                                                                            <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                                () => {
                                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-31`));
                                                                                        window.location.href = '/demandeAbsence'; 
                                                                                    }
                                                                            }
                                                                                }>
                                                                                     <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                                </td>:<></>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                                :
                                                                <td className={styles.celluleVide}></td>

                                                            }
                                                        
                                                    </>

                                                }




                                        </>:<>

                                        {
                                            numberDays === 30 ?
                                            <>
                                                {
                                                    new Date(`${currentYear_}-${currentMonth_}-29`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-29`).getDay() === 6 ?
                                                    <td id={styles.idWeekEndTest}>
                                                    <div className={styles.celluleWeekend}>
                                                       &nbsp;
                                                   </div>
                                               </td>:
                                                    <>
                                                        
                                                            {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-29`, mat.matricule, 29) ?
                                                            
                                                            <>
                                                                {
                                                                    getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-29`) === true ?
                                                                    <td id={styles.idJourAbsenceValider} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-29`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                            <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<>
                                                                        {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-29`) === null ?
                                                                            <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                                () => {
                                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-29`));
                                                                                        window.location.href = '/demandeAbsence'; 
                                                                                    }
                                                                            }
                                                                                }>
                                                                                     <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                                </td>:<></>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                                :
                                                                <td className={styles.celluleVide}></td>

                                                            }
                                                        
                                                    </>

                                                }{
                                                    new Date(`${currentYear_}-${currentMonth_}-30`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-30`).getDay() === 6 ?
                                                    <td id={styles.idWeekEndTest}>
                                                    <div className={styles.celluleWeekend}>
                                                       &nbsp;
                                                   </div>
                                               </td>:
                                                    <>
                                                        
                                                            {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-30`, mat.matricule, 30) ?
                                                            
                                                            <>
                                                                {
                                                                    getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-30`) === true ?
                                                                    <td id={styles.idJourAbsenceValider} onClick={
                                                                        () => {
                                                                            if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-30`));
                                                                                window.location.href = '/demandeAbsence'; 
                                                                            }
                                                                    }
                                                                        }>
                                                                            <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                        </td>:<>
                                                                        {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-30`) === null ?
                                                                            <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                                () => {
                                                                                    if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                        setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-30`));
                                                                                        window.location.href = '/demandeAbsence'; 
                                                                                    }
                                                                            }
                                                                                }>
                                                                                     <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                                </td>:<></>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                                :
                                                                <td className={styles.celluleVide}></td>

                                                            }
                                                        
                                                    </>

                                                }
                                            </>:<>

                                            {
                                                numberDays === 29 ?
                                                <>
                                                    {
                                                        new Date(`${currentYear_}-${currentMonth_}-29`).getDay() === 0 || new Date(`${currentYear_}-${currentMonth_}-29`).getDay() === 6 ?
                                                        <td id={styles.idWeekEndTest}>
                                             <div className={styles.celluleWeekend}>
                                                &nbsp;
                                            </div>
                                        </td>:
                                                        <>
                                                            
                                                                {   getVerification(listDebutHist, listFinHist, `${currentYear_}-${currentMonth_}-29`, mat.matricule, 29) ?
                                                                
                                                                <>
                                                                    {
                                                                        getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-29`) === true ?
                                                                        <td id={styles.idJourAbsenceValider} onClick={
                                                                            () => {
                                                                                if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                    setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-29`));
                                                                                    window.location.href = '/demandeAbsence'; 
                                                                                }
                                                                        }
                                                                            }>
                                                                                <div className={styles.celluleValider}>
                                                                            &nbsp;
                                                                        </div>
                                                                            </td>:<>
                                                                            {   getVerificationStyle(mat.matricule, `${currentYear_}-${currentMonth_}-29`) === null ?
                                                                                <td id={styles.idJourAbsenceNullTest2} onClick={
                                                                                    () => {
                                                                                        if((mat.matricule === token['matriculeActuel'] && role === "Salarie") || role === "Manager"){
                                                                                            setToken("idAbsence", getId(mat.matricule, `${currentYear_}-${currentMonth_}-29`));
                                                                                            window.location.href = '/demandeAbsence'; 
                                                                                        }
                                                                                }
                                                                                    }>
                                                                                         <div className={styles.testCellule2}>
                                                                            &nbsp;
                                                                        </div>
                                                                                    </td>:<></>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                                    :
                                                                    <td className={styles.celluleVide}></td>

                                                                }
                                                            
                                                        </>

                                                    }
                                                </>:<>

                                                

                                                </>

                                            }

                                            

                                            </>

                                        }

                                        </>

                                    }
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                
                                </tr>
                            )
                            :<></>
                        }

                        {
                            colVides ? 

                            <>{colVides}</>:
                            <></>
                        }
                        </tbody>
                        <tfoot className={styles.totalZone}>
                            {
                                mapListMatriculeResult && listDebutHist && listFinHist ?
                                <tr>
                                <th className={styles.fixedColumn}>Total</th>
                                <th>
                                    {
                                    total[1] !== 0 ?
                                    <>{total[1]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[2] !== 0 ?
                                    <>{total[2]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[3] !== 0 ?
                                    <>{total[3]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[4] !== 0 ?
                                    <>{total[4]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[5] !== 0 ?
                                    <>{total[5]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[6] !== 0 ?
                                    <>{total[6]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[7] !== 0 ?
                                    <>{total[7]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[8] !== 0 ?
                                    <>{total[8]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[9] !== 0 ?
                                    <>{total[9]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[10] !== 0 ?
                                    <>{total[10]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[11] !== 0 ?
                                    <>{total[11]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[12] !== 0 ?
                                    <>{total[12]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[13] !== 0 ?
                                    <>{total[13]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[14] !== 0 ?
                                    <>{total[14]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[15] !== 0 ?
                                    <>{total[15]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[16] !== 0 ?
                                    <>{total[16]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[17] !== 0 ?
                                    <>{total[17]}</>:<></>
                                    }
                                </th><th>
                                    {
                                    total[18] !== 0 ?
                                    <>{total[18]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[19] !== 0 ?
                                    <>{total[19]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[20] !== 0 ?
                                    <>{total[20]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[21] !== 0 ?
                                    <>{total[21]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[22] !== 0 ?
                                    <>{total[22]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[23] !== 0 ?
                                    <>{total[23]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[24] !== 0 ?
                                    <>{total[24]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[25] !== 0 ?
                                    <>{total[25]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[26] !== 0 ?
                                    <>{total[26]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[27] !== 0 ?
                                    <>{total[27]}</>:<></>
                                    }
                                </th>
                                <th>
                                    {
                                    total[28] !== 0 ?
                                    <>{total[28]}</>:<></>
                                    }
                                </th>


                                {
                                    numberDays === 31 ?
                                    <>
                                        <th>
                                            {
                                            total[29] !== 0 ?
                                            <>{total[29]}</>:<></>
                                            }
                                        </th>
                                        <th>
                                            {
                                            total[30] !== 0 ?
                                            <>{total[30]}</>:<></>
                                            }
                                        </th>
                                        <th>
                                            {
                                            total[31] !== 0 ?
                                            <>{total[31]}</>:<></>
                                            }
                                        </th>
                                    </>:<>

                                    {
                                        numberDays === 30 ?
                                        <>
                                            <th>
                                                {
                                                total[29] !== 0 ?
                                                <>{total[29]}</>:<></>
                                                }
                                            </th>
                                            <th>
                                                {
                                                total[30] !== 0 ?
                                                <>{total[30]}</>:<></>
                                                }
                                            </th>
                                        </>:<>

                                        {
                                            numberDays === 29 ?
                                            <>
                                                <th>
                                                    {
                                                    total[29] !== 0 ?
                                                    <>{total[29]}</>:<></>
                                                    }
                                                </th>
                                            </>:<>

                                            

                                            </>

                                        }

                                        

                                        </>

                                    }

                                    </>

                                }
                                
                            </tr>:<></>
                            }
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}


export default PlanningAbsenceSection