import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import AcompteSalaireSection from "./AcompteSalaireSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function AcompteSalaire(){

    return(
        <div>
            <NavBarGlobal  section = {<AcompteSalaireSection />}/>
        </div>
    )
}

export default AcompteSalaire;