import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import DonneesAdministrationSection from './DonneesAdministrationSection.jsx';



export const DonneesAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<DonneesAdministrationSection />} />
   
 </div>
)}


export default DonneesAdministration