import React from "react";
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from '../../../../APIService';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import StylesM from './HistoriqueAbsenceSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import GestionTempsCard from "../../GestionDeTempsGlobal/Card/GestionTempsCard";
import TopMenuGestionDuTemps from "../../TopMenuGestionDuTemps";
import GeneralCardTest from "../../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import SecondSectionTablePage from "../../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import DateComponent from "../../../GlobalComponents/dateComponent/DateComponent.jsx";
import CreateComponent from "../../../GlobalComponents/createComponent/CreateComponent.jsx";
import DescriptionComponent from "../../../GlobalComponents/descriptionComponent/DescriptionComponent.jsx";
import moment from 'moment';
import FourthSectionTablePage from  "../../../GlobalComponents/fourthSectionTablePage/FourthSectionTablePage";
import CheckboxTableInput from "../../../GlobalComponents/checkboxTableInput/CheckboxTableInput.jsx";
import TopMenu from "../../../Salarie/SalarieGlobal/TopMenu/TopMenu";
import LineFilterTableInput from "../../../GlobalComponents/lineFilterTableInput/LineFilterTableInput.jsx";
import PaginationTable from "../../../GlobalComponents/paginationTable/PaginationTable.jsx";
import SelectNbreLigneTableInput from "../../../GlobalComponents/selectNbreLigneTableInput/SelectNbreLigneTableInput.jsx";
import PopupComponentGstTemps from "../../../GlobalComponents/popupComponentGstTemps/PopupComponentGstTemps.jsx";
import ListColumns from "../../../GlobalComponents/listColumns/ListColumns.jsx";



function HistoriqueAbsenceSection() {

    const [employes, setEmployes] = useState([]);
    const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()
    const [idMasquer, setIdMasquer] = useState([]);
    const [idSupprimer, setIdSupprimer] = useState([]);
    const [idChangeAffichage, setIdChangeAffichage] = useState();
    const [idSupprimerValeur, setIdSupprimerValeur] = useState();
    const [nombrePageArray, setNombrePageArray] = useState([])
    const [selectedNombrePageValeur, setSelectedNombrePageValeur] = useState()
    const [nombrePageValeur, setNombrePageValeur] = useState(0)
    const [matriculeRecherche, setMatriculeRecherche] = useState()
    const [nomRecherche, setNomRecherche] = useState()
    const [prenomRecherche, setPrenomRecherche] = useState()
    const [etablissementRecherche, setEtablissementRecherche] = useState()
    const [departementRecherche, setDepartementRecherche] = useState()
    const [categorieRecherche, setCategorieRecherche] = useState()
    const [contratRecherche, setContratRecherche] = useState()
    const [horaireRecherche, setHoraireRecherche] = useState()
    const [employesList, setEmployesList] = useState()
    const [defaultNombrePageValeur, setDefaultNombrePageValeur] = useState()
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);

    const [show, setShow] = useState(false);

    const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
    const [listHistoriqueAbsence, setListHistoriqueAbsence] = useState([]);
    const [listHistoriqueAbsenceResult, setListHistoriqueAbsenceResult] = useState([]);
    const [idWorkflow, setIdWorkflow] = useState();
    const [inputMatricule, setInputMatricule] = useState();
    const [inputNomPrenom, setInputNomPrenom] = useState();
    const [role, setRole] = useState();
    const [dataEmployes, setDataEmployes] = useState([]);
  
    var varMatriculeRecherche = ''
    var varNomRecherche = ''
    var varPrenomRecherche = ''
    var varEtablissementRecherche = ''
    var varDepartementRecherche = ''
    var varContratRecherche = ''
    var varHoraireRecherche = ''
    
  
      // const doc = new jsPDF('landscape', '', 'a4');
    const doc = new jsPDF();
  
  
      // var pdfjs = document.getElementById('panelTable');
  
    function createData(emps){
      var data = []
  
      emps.map(emp => data.push([emp.matricule_employe, emp.nom_employe, emp.prenom_employe, emp.etablissement_employe, emp.departement_employe, emp.typeContrat_employe, emp.plageHoraire_employe]))
  
      return data
    }
    
    const btnGeneratePdf = (e) => {
      // doc.text("MY DATA", 10, 10)
      // doc.table(20, 20, employesList)
      // if(employesList){
      //   employesList.map(emp =>
      //     doc.text(emp.nom_employe, 10, 20)
      //     )
      // }
      // doc.text(pdfjs.innerText, 10, 20)
      doc.autoTable({ 
        head: [['Matricule', 'Nom', 'Prénom', 'Etablissement', 'Département', 'Contrat', 'Horaire']],
        body: createData(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false))
      })
  
      doc.save("test.pdf")
    }
  
    useEffect(() => {
      if(valeurRechercheGlobale){
        setEmployesList(employes.filter(resp => resp.matricule_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.nom_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.prenom_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.etablissement_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.departement_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.typeContrat_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.plageHoraire_employe.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())))
      }else{
        setEmployesList(employes)
      }
    }, [valeurRechercheGlobale])
    
  
    useEffect(() => {
  
      if(matriculeRecherche){
        varMatriculeRecherche = matriculeRecherche.toString().toLowerCase()
      }else{
        varMatriculeRecherche = ''
      }
  
      if(nomRecherche){
        varNomRecherche = nomRecherche.toString().toLowerCase()
      }else{
        varNomRecherche = ''
      }
  
      if(prenomRecherche){
        varPrenomRecherche = prenomRecherche.toString().toLowerCase()
      }else{
        varPrenomRecherche = ''
      }
  
      if(etablissementRecherche){
        varEtablissementRecherche = etablissementRecherche.toString().toLowerCase()
      }else{
        varEtablissementRecherche = ''
      }
      
      if(departementRecherche){
        varDepartementRecherche = departementRecherche.toString().toLowerCase()
      }else{
        varDepartementRecherche = ''
      }
  
      
  
      if(contratRecherche){
        varContratRecherche = contratRecherche.toString().toLowerCase()
      }else{
        varContratRecherche = ''
      }
  
      if(horaireRecherche){
        varHoraireRecherche = horaireRecherche
      }else{
        varHoraireRecherche = ''
      }
  
      
      setEmployesList(employes.filter(resp => resp.matricule_employe.toLowerCase().includes(varMatriculeRecherche) && resp.nom_employe.toLowerCase().includes(varNomRecherche) && resp.prenom_employe.toLowerCase().includes(varPrenomRecherche) && resp.etablissement_employe.toLowerCase().includes(varEtablissementRecherche) && resp.departement_employe.toLowerCase().includes(varDepartementRecherche) && resp.typeContrat_employe.toLowerCase().includes(varContratRecherche) && resp.plageHoraire_employe.toString().toLowerCase().includes(varHoraireRecherche)))
      
    }, [matriculeRecherche, nomRecherche, prenomRecherche, etablissementRecherche, departementRecherche, contratRecherche, horaireRecherche])
    
  
  
  
  
    useEffect(() => {
     
      APIService.getEmployes(token)
      .then(resp => setEmployes(resp))
      .catch(error => console.log(error))
  
    }, [idChangeAffichage])
  
    useEffect(() => {
  
      if(token['mytoken']){
        APIService.getEmployes(token)
      .then(resp => setEmployes(resp))
      .catch(error => console.log(error))
  
      }
  
    }, [token])
  
  
  
    useEffect(() => {
      if(employes){
          setSelectedNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
          setNombrePageArray(range(1, 1))
          setNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
          setDefaultNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
  
          setEmployesList(employes)
      }
    }, [employes])
  
  
    useEffect(() => {
      if(idMasquer && employesList && selectedNombrePageValeur){
          setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/selectedNombrePageValeur)))
  
      }
    }, [idMasquer, employesList])
    
  
    const btnMasquer = (e) => {
      
      setIdMasquer(idMasquer.concat(parseInt(e.target.value)))
      
    }
  
    const btnRoload = () => {
      if(employes){
        setEmployesList(employes)
        setIdMasquer([])
      }
      
    }
  
    const btnSupprimer = (e) => {
  
      setIdSupprimerValeur(parseInt(e.target.value))
  
      // APIService.SupprimerEmploye(e.target.value, token)  
      
      // setIdSupprimer(idSupprimer.concat(parseInt(e.target.value)))
      
    }
  
    var listData = {}
    const btnSupprimerOui = (e) => {
      listData = employesList.filter(emp => emp.id === parseInt(e.target.value))[0]
      // listData.state_employe = 0
      // delete listData.id;
      // console.log(listData)
  
      // APIService.ModifierEmploye(parseInt(e.target.value), listData, token) 
      
      APIService.ModifierEmploye(parseInt(e.target.value),
      {matricule_employe:listData.matricule_employe, dateEntree_employe:listData.dateEntree_employe, dateModification_employe:listData.dateModification_employe, dateFinModification_employe:listData.dateFinModification_employe,dateSortie_employe:listData.dateSortie_employe,
      nom_employe:listData.nom_employe, prenom_employe:listData.prenom_employe, sexe_employe:listData.sexe_employe, numeroSS_employe:listData.numeroSS_employe, cle_employe:listData.cle_employe, dateNaissance_employe:listData.dateNaissance_employe, 
      paysNaissance_employe:listData.paysNaissance_employe, departementNaissance_employe:listData.departementNaissance_employe, nationnalite1_employe:listData.anneeFormation1_employe, nationnalite2_employe:listData.nationnalite2_employe, 
      situationFamiliale_employe:listData.situationFamiliale_employe, nombreEnfant_employe:listData.nombreEnfant_employe, adresse_employe:listData.adresse_employe, pays_employe:listData.pays_employe, 
      codePostale_employe:listData.codePostale_employe, ville_employe:listData.ville_employe, tel_employe:listData.tel_employe, 
      email_employe:listData.email_employe, etablissement_employe:listData.etablissement_employe, departement_employe:listData.departement_employe, 
      poste_employe:listData.poste_employe, typeContrat_employe:listData.typeContrat_employe, plageHoraire_employe:listData.plageHoraire_employe, jourRepos1_employe:listData.jourRepos1_employe, jourRepos2_employe:listData.jourRepos2_employe, nomRue_employe:listData.nomRue_employe, NRue_employe:listData.NRue_employe, complementAdresse_employe:listData.complementAdresse_employe, chez_employe:listData.chez_employe, telephoneFixe_employe:listData.telephoneFixe_employe,formation1_employe:listData.formation1_employe, anneeFormation1_employe:listData.anneeFormation1_employe, formation2_employe:listData.formation2_employe, anneeFormation2_employe:listData.anneeExperience2_employe,formation3_employe:listData.formation3_employe, anneeFormation3_employe:listData.anneeExperience3_employe,formation4_employe:listData.formation4_employe, anneeFormation4_employe:listData.anneeFormation4_employe,
      experience1_employe:listData.experience1_employe, anneeExperience1_employe:listData.anneeExperience1_employe, experience2_employe:listData.experience2_employe, anneeExperience2_employe:listData.anneeExperience2_employe,experience3_employe:listData.experience3_employe, anneeExperience3_employe:listData.anneeExperience3_employe,experience4_employe:listData.experience4_employe, anneeExperience4_employe:listData.anneeExperience4_employe, 
      langue1_employe:listData.langue1_employe, niveauLangue1_employe:listData.niveauLangue1_employe,
      langue2_employe:listData.langue2_employe, niveauLangue2_employe:listData.niveauLangue2_employe,
      langue3_employe:listData.langue3_employe, niveauLangue3_employe:listData.niveauLangue3_employe,
      langue4_employe:listData.langue4_employe, niveauLangue4_employe:listData.niveauLangue4_employe,
      iban_employe:listData.iban_employe, banque_employe:listData.banque_employe,
      domiciliation_employe:listData.domiciliation_employe, numeroCompte_employe:listData.numeroCompte_employe,
      codeBanque_employe:listData.codeBanque_employe, codeGuichet_employe:listData.codeGuichet_employe,
      cleRib_employe:listData.cleRib_employe, state_employe:0, modeTravail_employe : listData.modeTravail_employe, competence_employe: listData.competence_employe,
      heurePrisePoste_employe: listData.heurePrisePoste_employe,            
      motifSortie_employe:3, matricule_manager: token['matriculeManager']},token)
      
      
      
      // APIService.SupprimerEmploye(e.target.value, token)  
      
      setIdSupprimer(idSupprimer.concat(parseInt(e.target.value)))
  
      setIdSupprimerValeur()
  
    }
  
    const btnSupprimerNon = (e) => {
  
      setIdSupprimerValeur()
      
    }
  
    function range(start, end) {
      return Array(end - start + 1). fill(). map((_, idx) => start + idx)
    }
  
  
    function setNombrePage(num) {

      setSelectedNombrePageValeur(parseInt(num))
      setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/parseInt(num))))
      setNombrePageValeur(parseInt(num))
      
    }

    // useEffect(() => {
    //   if(nombrePageValeur && selectedNombrePageValeur){
    //     console.log("nombrePageValeur :", nombrePageValeur)
    //     console.log("selectedNombrePageValeur :", selectedNombrePageValeur)

    //   }
      
    // }, [nombrePageValeur, selectedNombrePageValeur])
    
    // const handleChangeNombrePage = (e) => {
    //   setAfficherChecked(false)
    //   setSelectedNombrePageValeur(parseInt(e.target.value))
    //   setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/parseInt(e.target.value))))
    //   setNombrePageValeur(parseInt(e.target.value))
      
  
    // }
  
    const btnNombrePage = (e) => {
      setNombrePageValeur(parseInt(e.target.value))
      
    }

    function btnNombrePageFunction(pageNum){
      setNombrePageValeur(parseInt(pageNum))
    }
  
  
    const btnFichePersonnel = (e) => {
      
  
      setToken('idFichePersonnel', e.target.value)
      console.log(e.target.name)
  
      window.location.href = '/donneesPersonnelles'
  
    }

    // const [afficherChecked, setAfficherChecked] = useState(false)
    
    function showMenu() {
      if(show){
        setShow(false)
      }else{
        setShow(true)

      }
    }
    useEffect(() => {
        if(inputMatricule || inputNomPrenom){
            let matricule = "", nomPrenom = "";
            if(inputMatricule){
                matricule = inputMatricule;
            }
            if(inputNomPrenom){
                nomPrenom = inputNomPrenom;
            }


            setListHistoriqueAbsenceResult(listHistoriqueAbsence.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence !== null && resp.matricule_manager.toLowerCase().includes(matricule.toLowerCase()) && (resp.nom_demandeAbsence.toLowerCase().includes(nomPrenom.toLowerCase()) || resp.prenom_demandeAbsence.toLowerCase().includes(nomPrenom.toLowerCase()))))


        }else{

            setListHistoriqueAbsenceResult(listHistoriqueAbsence)



        }
    }, [inputMatricule, inputNomPrenom])
    
    useEffect(() => {
        if(token['mytoken']){
            APIService.getDemandeAbsence(token).then(resp => 
                {
                    setListHistoriqueAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence !== null))
                    setListHistoriqueAbsenceResult(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence !== null))
                }
                
                )

            APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));
            
        }
    }, [])

   

    useEffect(() => {
        if(role){
          if(role !== "Manager"){
            window.location.href = "/";
          }
        }
      }, [role])


       // testttttttt
    const [initData, setInitData] = useState(null)
    const [isLoading, setIsLoading] = useState()

    
    const [data, setData] = useState(null)

    useEffect(() => {
  
      if(token['mytoken']){
        APIService.getDemandeAbsence(token).then(resp => 
           setData(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence !== null))
      )
      .catch(error => console.log(error))
      }
  
    }, [token])

    useEffect(() => {

      if(data){
        setInitData(data)
      }
      
    }, [data])

          useEffect(() => {
            if (initData) {
              initData.map(dmd => {
                  dmd.rubriqueAbsence_demandeAbs = dmd.rubriqueAbsence_demandeAbsence;
                  dmd.periode_demandeAbs = 'Du '+ moment(dmd.rubriqueDateDebut_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY") +' Au ' +moment(dmd.rubriqueDateFin_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY") ;
                  dmd.dateDemande_demandeAbs = 'Par ' + dmd.prenom_demandeAbsence + ' ' + dmd.nom_demandeAbsence + ' le : ' + moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY");
                  if(dmd.action_demandeAbsence === true){
                    dmd.dateValidation_demandeAbs = (
                      <div className={StylesM.dateValidationStyle}>
                        <b className={StylesM.boldStyle}>Validé</b> Par {dmd.prenomValidation_demandeAbsence} {dmd.nomValidation_demandeAbsence} le : {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </div>
                  );
                  } else{
                    dmd.dateValidation_demandeAbs = (
                      <div className={StylesM.dateValidationStyle}>
                        <b className={StylesM.boldStyle}>Refusé</b> Par {dmd.prenomValidation_demandeAbsence} {dmd.nomValidation_demandeAbsence} le : {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </div>
                    );
                  }
              });
              setListHistoriqueAbsenceResult(initData);
            }
          }, [initData,listHistoriqueAbsenceResult]);

           
          useEffect(() => {
            if(valeurRechercheGlobale){
              setInitData(initData.filter(dmd => dmd.rubriqueAbsence_demandeAbs.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
              || dmd.periode_demandeAbs.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
              || dmd.dateDemande_demandeAbs.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
              ));
            }
            else{
              setInitData(data)
            }
          }, [valeurRechercheGlobale])

          // TESTTT
 

    // Pagination 

    const [index, setIndex] = useState(0)
    const [page, setPage] = useState(null)
    const [pageTickets, setPageTickets] = useState([])
    const [nombrePageArray_, setNombrePageArray_] = useState([])
    const [nombrePageValeurFixed, setNombrePageValeurFixed] = useState(null)
    const [afficherChecked, setAfficherChecked] = useState(false)
  
  // const [first, setfirst] = useState(second)
    function transformNumberToArray(n) {
        const array = [];
        for (let i = 1; i <= n; i++) {
            array.push(i);
        }
        return array;
    }
   
    
    useEffect(() => {
      if(employesList && parseInt(index) !== 0 && page !== 0 )
      
      { 
        let indexInt = parseInt(index);
    
        setPageTickets(listHistoriqueAbsenceResult.slice(page*indexInt - indexInt, page*indexInt))
        setNombrePageArray_(transformNumberToArray(Math.ceil(listHistoriqueAbsenceResult.length/parseInt(index))))
  
      } else {
  
        if( listHistoriqueAbsenceResult&& parseInt(index) === 0){
          setPage(1)
          setPageTickets(listHistoriqueAbsenceResult)
          setNombrePageArray_([])
         
  
  
        }
  
      }
      
    }, [listHistoriqueAbsenceResult, index, page])
    
  
  
  
    useEffect(() => {
      if(afficherChecked && listHistoriqueAbsenceResult){
        setNombrePageValeurFixed(0);
        setIndex(0);
      }
     }, [afficherChecked])
     const handleChangeNombrePage = (e) => {
      setAfficherChecked(false)
      setNombrePageValeurFixed(parseInt(e.target.value))
      setIndex(e.target.value)
      setPage(1);
    }
  
    useEffect(() => {
      console.log("array nbre page ",nombrePageArray,"page",page)
    }, [nombrePageArray,page])

    useEffect(() => {

      if(pageTickets){

        setDataEmployes(pageTickets)
      }
      
    }, [pageTickets])


          // 

          const deleteButton = (id) => {
            if(id && token){
              APIService.SupprimerDemandeActivite(id, token)
              .then(
                () =>  APIService.getDemandeAbsence(token)
                        .then(resp => 
                        {setInitData(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence !== null));
                          // re
                        setIsLoading(false)
                        }
                          )
                          .catch(error => console.log(error))
              
              )
            }
          }
          const modifyButton = (id) => {
            if(id){
              console.log("modify button",id)
            }
          }
          const hideButton = (id) => {
            if(id){
              console.log("hide button",id)
            }
          }
          const sendButton = (id) => {
            if(id){
              console.log("send button",id)
            }
          }
          const headers={id : "id", rubriqueAbsence_demandeAbs:"Rubrique des absences",periode_demandeAbs:"Période",
          dateDemande_demandeAbs:"Date demande",dateValidation_demandeAbs:"Date de validation"
        };
          const componentsList={ 1: "Send",2:"Delete",3:"Modify",4:"Hide",5:"Delete"}
          const methodsList={ 1: sendButton,2:deleteButton,3:modifyButton,4:hideButton,5:deleteButton}
          const [headerTest, setHeaderTest] = useState({});
          const test={rubriqueAbsence_demandeAbs:"Rubrique des absences",periode_demandeAbs:"Période"}
    
  return (
//     <div className={StylesM.classCard}>


//         <div className={StylesM.topBar}>

//              {/* <TopMenu page="salarie" /> */}
//              {/* <GestionTempsCard page="historiqueAbsence"/> */}
//              <TopMenuGestionDuTemps page="historiqueAbsence"/>


//         </div>


//       <div className={StylesM.toolsBar}>

//       <div id={StylesM.classTopNavRechPC} style={{color:"rgb(218, 165, 32)", backgroundColor:"rgb(218, 165, 32, 20%)"}}>
       
         
            
//             <span className={StylesM.inputgroup}>
//               <input type="checkbox" className={StylesM.inputGlobal8} checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} /> 
//               <span className={StylesM.inputGlobal77} style={{fontSize:"12px"}} >Tout afficher	</span>

//             </span>
//             <span   className={StylesM.inputgroup}>
//                 <label >Nombre de lignes : </label><span style={{padding:"14px"}}> </span>
//                                       <select value={nombrePageValeur} onChange={handleChangeNombrePage}  className={StylesM.btnNumber}>
//                                           <option ></option>
//                                           <option value="2">2</option>
//                                           <option value="3">3</option>
//                                           <option value="5">5</option>
//                                           <option value="10">10</option>
//                                           <option value="25">25</option>

//                                       </select>
//               </span>
          
//             <span className={StylesM.inputgroup}>
//                 <label className={StylesM.label} htmlFor="" >Filtrer les lignes : </label>
//                 <input type="text"  placeholder="Rechercher" value={valeurRechercheGlobale} onChange={e => setValeurRechercheGlobale(e.target.value)} className={StylesM.test}/>
//             </span>
          
//           <span  className={StylesM.inputgroup} style={{textAlign:"right", fontWeight:"bold"}}>
//                   {/* <span className="col">
//                     <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span></button>
//                   </span>
//                   <span className="col">
//                   <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span></button>

//                   </span> */}
                  
//                   <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span></button>
                  
                  
//                   <button title="Excel" className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span></button>

                  
//           </span>
        
        

//       </div>


//       <div id={StylesM.classTopNavRechMobile}>

//       <div className={StylesM.barrebas1}>

//       <span  className={StylesM.inputgroupI} >

       
                 
//                           <input type="checkbox"  id={StylesM.test} checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} className={StylesM.inputGlobal3}/>
                  

//                   <span className={StylesM.toutafficher1} id={StylesM.label} style={{fontSize:"12px"}}> 
//                     Tout afficher	
//                   </span>

        
//         </span>


//       <span  className={StylesM.inputgroupI} >

              

//               <label>Nombre de lignes : </label><span style={{padding:"10px"}}> </span>
              
              
//                         <select value={nombrePageValeur} onChange={handleChangeNombrePage}  className={StylesM.btnNumber} >
//                             <option ></option>
//                             <option value="2">2</option>
//                             <option value="3">3</option>
//                             <option value="5">5</option>
//                             <option value="10">10</option>
//                             <option value="25">25</option>

//                         </select>

              

//       </span>

//       <span className={StylesM.RightTopBar}>

//                             {
//                       show ?
//                       <span 
//                       id={StylesM.btnPlus} style={{backgroundColor:"goldenrod", color:"white"}} 
//                       onClick={() => showMenu()}>
//                       <FontAwesomeIcon icon={faPlus} className={StylesM.iconplus} style={{color: "black",fontSize:"22px"}} />          </span>:
//                       <span 
//                       id={StylesM.btnPlus} style={{fontWeight:"bold", color:"white"}}
//                       onClick={() => showMenu()}>
//                       <FontAwesomeIcon icon={faPlus}style={{color: "black"}} />          </span>
//                       }

//       </span>


//       </div>

// </div>

//       </div>


//       <div className={StylesM.classTable}>


//         <div className={StylesM.divTableOverflow}>
//         <div id={StylesM.idTestTable}>

//         <table class="table">
//             <thead>
//                 <tr id={StylesM.idForStyleTh}>
//                     <th>Rubrique des absences</th>
//                     <th>Période</th>
//                     <th>Date demande</th>
//                     <th>Date Validation</th>
//                 </tr>
//             </thead>
//             <tbody>
//             {
//                 listHistoriqueAbsenceResult ? 
//                 listHistoriqueAbsenceResult.map(dmd => 
//                     <tr onClick={() => {setIdWorkflow(dmd.id); setShow(true);}} style={{cursor: "pointer"}}>
//                         <td>{dmd.rubriqueAbsence_demandeAbsence}</td>
//                         <td>DU {moment(dmd.rubriqueDateDebut_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")} AU {moment(dmd.rubriqueDateFin_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
//                         <td>Par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} le : {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
//                         <td>
//                                 {dmd.action_demandeAbsence === true ?
//                                 <b>Validé</b>:<b>Refusé</b>} par {dmd.prenomValidation_demandeAbsence} {dmd.nomValidation_demandeAbsence} le : {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")
//                                 }
//                         </td>
//                     </tr>
                    
//                     )
//                 :<span></span>
//             }
//             </tbody>
//         </table>

        

                    
//         </div>


//       </div>      
      
   

//       </div>



//       <div className={StylesM.bottomToolsBar}>

//       <div className={StylesM.barrebas}>
//       <span className={StylesM.inputgroup1} >

//               <span className={StylesM.leftBottomBar}>

              
//               <input type="checkbox" className={StylesM.inputGlobal8}
//               checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}}/> 
//               <span className={StylesM.inputGlobal77} style={{fontSize:"12px"}} > Tout afficher	</span>

//             </span>


              

//               </span>

//               <span  className={StylesM.inputgroup1}  id={StylesM.inputgroupIp2} >
//                 <label >Nombre de lignes: </label><span style={{padding:"14px"}}> </span>
//                                       <select value={nombrePageValeur} onChange={handleChangeNombrePage}  className={StylesM.btnNumber}>
//                                           <option ></option>
//                                           <option value="2">2</option>
//                                           <option value="3">3</option>
//                                           <option value="5">5</option>
//                                           <option value="10">10</option>
//                                           <option value="25">25</option>

//                                       </select>
//               </span>

//               <span className={StylesM.inputgroup2}  >
//               <span className={StylesM.centerBottomBar}>

//                   <span className={StylesM.pagination} >

//                   {nombrePageArray && nombrePageArray[0] === selectedPageNumber  ? 
                  
//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}} disabled> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>
                  
//                   : 
//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}}> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>
                  
//                   }

//               { selectedPageNumber > 2 ? <>...</> :<></>}


//             {nombrePageArray ? nombrePageArray.map(nbr => 


//                    <>
                   
                   
//                    {  


//                   Math.abs(selectedPageNumber - nbr) <= 2 ? 

//                   <button className={
//                     selectedPageNumber === nbr ? StylesM.classSelectedPageNumber : StylesM.classPageNumber
//                   } value={selectedNombrePageValeur*nbr} onClick={(e) => {btnNombrePage(e); setSelectedPageNumber(nbr)}}>{nbr}</button> : <></>



//                   }
                   
                   
//                    </>
            
            
            
              
              
              
//               ) : <></>} 




//                 { nombrePageArray && nombrePageArray.length-selectedPageNumber > 2 ? <>...</> :<></>}


//                 {nombrePageArray && nombrePageArray[nombrePageArray.length - 1] === selectedPageNumber  ? 
                  
//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}} disabled><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}}/> </button>
                  
//                   : 

//                   <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}}><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}} /> </button>

                  
//                   }

//                   </span>


//               </span>
//               </span>
//               {/* <span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
//               <span className={StylesM.rightBottomBar}>

                    
//                  { 
//               idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
//                  }
//                     </span> 

//               </span> */}



//       </div>



// {/* 
//             <table id={StylesM.idBarBottomPC} style={{width:"100%", color:"rgb(218, 165, 32)", backgroundColor:"rgb(218, 165, 32, 20%)"}}>
//         <tr>
//           <td style={{textAlign:"left"}} id={StylesM.tableRepeter}>

//             <span className="col" style={{ width:"100%",fontWeight:"bold"}}>
//             <span className="col">
//               <input type="checkbox" checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} className={StylesM.inputGlobal3}/> 
//               <span className={StylesM.toutafficher2} > Tout afficher	</span>

//             </span>

//             <span className="col" style={{width:"auto", color:"red"}}>
//                 <label className={StylesM.nombredelignes2}>Nombre de lignes : </label>
//                                       <select value={nombrePageValeur} onChange={handleChangeNombrePage} className={StylesM.inputGlobal2}>
//                                           <option ></option>
//                                           <option value="2">2</option>
//                                           <option value="3">3</option>
//                                           <option value="5">5</option>
//                                           <option value="10">10</option>
//                                           <option value="25">25</option>

//                                       </select>
//               </span>


//           </span>

//           </td>
//           <td  style={{textAlign:"left", width:"30%"}}>
//             <span >
//             {nombrePageArray ? nombrePageArray.map(nbr => <button className="col" style={{border:"none", backgroundColor:"transparent"}}  value={selectedNombrePageValeur*nbr} onClick={btnNombrePage}>{nbr}</button>) : <span></span>}
//             </span>
//           </td>
//           <td style={{textAlign:"right"}}>
//           <span style={{fontWeight:"bold"}}>
//           {
//               idMasquer.length>0 ? <span className={StylesM.nombredelignes3}>Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.nombredelignes3}>Salariés masqués : 0 </span>
//             }
//           </span>
//           </td>
//         </tr>

//             </table>   */}
      
//           {/* <div id={StylesM.classBottomNavRechMobile}> */}

//         {/* <span id={StylesM.idPagination}>
//           {nombrePageArray ? nombrePageArray.map(nbr => <button className="col" style={{border:"none", backgroundColor:"transparent", padding:"0",fontWeight:"bold"}}  value={selectedNombrePageValeur*nbr} onClick={btnNombrePage}>{nbr}</button>) : <span></span>}
//         </span> */}

//         {/* <span  id={StylesM.idMasquer}>
//           {
//               idMasquer.length>0 ? <span className={StylesM.nombredelignes3}>Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.nombredelignes3}>Salariés masqués : 0 </span>
//             }
//         </span> */}

//           {/* </div> */}

//       </div>
      
//       <Modal title="My Modal" onClose={() => setShow(false)} show={show}>

//       {
//             listHistoriqueAbsence.filter(resp => resp.id === idWorkflow).map(dmd => 
//                 <>
//                 <div className="row">
//                     <table id={StylesM.idTableCmnts}>
//                       <tr>
//                         <td id={StylesM.idBorderLeft}>
//                         Demande effectuée par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} le {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")} 
//                         </td>
//                         <td>
//                           <span id={StylesM.idFleche}>&rarr;</span>
//                         </td>
//                         {dmd.action_demandeAbsence === true ?
//                         <td className="col" id={StylesM.idValider}>
//                             Demande Validée par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
//                         </td>:
//                         <td className="col" id={StylesM.idRefuser}>
//                             Demande Validée par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
//                         </td>
//                         }
//                       </tr>
//                     </table>

                
                    

                
//                 </div>

//                 <hr style={{width:"90%", marginLeft:"auto", marginRight:"auto"}} />
//                 <div id={StylesM.commentaire}>Commentaire : &nbsp;
                
//                 {dmd.action_demandeAbsence === true ? 
//                 <span style={{color:"green", fontWeight:"bold"}}>{dmd.commentaireValidation_demandeAbsence}</span> :
//                 <span style={{color:"red", fontWeight:"bold"}}>{dmd.commentaireValidation_demandeAbsence}</span>}
//                 </div>
//                 </>

//                 )
//         }

// </Modal>

//     </div>
<div>
<div className={StylesM.web}>

<GeneralCardTest 
section1={
<TopMenuGestionDuTemps page="historiqueAbsence"/>
} 
section2={
<SecondSectionTablePage 
        nbreComponents={5}
        component1={ <ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
         component5={<CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
        } 
        />
}
section3={
  <>
 {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          setIdWorkflow(row.id);
          setShow(true);
        }
      }
      }
  />  
  }
      <PopupComponentGstTemps onClose={() => setShow(false)} show={show} width="350px" height="280px">
{
       listHistoriqueAbsence.filter(resp => resp.id === idWorkflow).map(dmd => 
          <>
          <div  className={StylesM.insidePopup}>
            <div className={StylesM.firstSection}>
              <div style={dmd.action_demandeAbsence === true ? {boxShadow:"inset 0.6vw 0 0 0 green, 0 0 8px rgba(0, 0, 0, 0.1)"}: 
             {boxShadow:"inset 0.6vw 0 0 0 red, 0 0 8px rgba(0, 0, 0, 0.1)"}} className={StylesM.divideFirstSection}>
                <div className={StylesM.first}>
                Demande effectuée par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} le {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")} 
                </div>
                <div className={StylesM.second}>
                <span id={StylesM.idFleche}>
                  <i class="fa-solid fa-right-long"></i>
                  </span>
                </div >
                {dmd.action_demandeAbsence === true ?
                <div className={StylesM.third}>
                    Demande <span className={StylesM.textColored}  style={{display:"contents",color:"green"}}>Validée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </div>
                :
                <div  className={StylesM.third}>
                      Demande <span className={StylesM.textColored}  style={{display:"contents",color:"red"}}>Refusée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      {/* testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt */}

                </div>
                
                }
              </div>
            </div>
            <div className={StylesM.secondSection}>
              <div id={StylesM.idDivCmnt}>
                  <div className={StylesM.texte}> Commentaires :</div>
                  {dmd.action_demandeAbsence === true ?
                  <textarea className={StylesM.inputGlobal}style={{color:"green", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled >
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>: 
                  <textarea className={StylesM.inputGlobal} style={{color:"red", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled>
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>
                          }
              </div>
              </div>
          </div>
          </>

          )
  }


              </PopupComponentGstTemps>
</>    
 } 
section4={
  <FourthSectionTablePage 
  component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
} 
component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
  component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
  component4={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
               <span className={StylesM.rightBottomBar}>
                 { 
               idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
                 }
                     </span> 
               </span>}
  /> 
        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={StylesM.tablet}>
        <GeneralCardTest 
        section1={
          <TopMenuGestionDuTemps page="historiqueAbsence"/>
      }  
        section2={
        <SecondSectionTablePageTablet 
        nbreComponents={5}
        component1={ <ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
        />
      } 
        section3={
            <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
              {
                (dataEmployes && headerTest) && 
                  <TableComponent data={dataEmployes} cols={headerTest} 
                  addAction={true} 
                  contentAction={methodsList}
                  componentsList={componentsList}
                  clickableRow={true}
                  onClickRow={(e, row) => {
                    console.log('Row:', e);
                    if (e && e.target) {
                      setIdWorkflow(row.id);
                      setShow(true);
                    }
                  }
                  }
              />  
              }
    <PopupComponentGstTemps onClose={() => setShow(false)} show={show} width="350px" height="280px">
{
       listHistoriqueAbsence.filter(resp => resp.id === idWorkflow).map(dmd => 
          <>
          <div  className={StylesM.insidePopup}>
            <div className={StylesM.firstSection}>
              <div style={dmd.action_demandeAbsence === true ? {boxShadow:"inset 0.6vw 0 0 0 green, 0 0 8px rgba(0, 0, 0, 0.1)"}: 
             {boxShadow:"inset 0.6vw 0 0 0 red, 0 0 8px rgba(0, 0, 0, 0.1)"}} className={StylesM.divideFirstSection}>
                <div className={StylesM.first}>
                Demande effectuée par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} le {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")} 
                </div>
                <div className={StylesM.second}>
                <span id={StylesM.idFleche}>
                  <i class="fa-solid fa-right-long"></i>
                  </span>
                </div >
                {dmd.action_demandeAbsence === true ?
                <div className={StylesM.third}>
                    Demande <span className={StylesM.textColored}  style={{display:"contents",color:"green"}}>Validée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </div>
                :
                <div  className={StylesM.third}>
                      Demande <span className={StylesM.textColored}  style={{display:"contents",color:"red"}}>Refusée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      {/* testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt */}

                </div>
                
                }
              </div>
            </div>
            <div className={StylesM.secondSection}>
              <div id={StylesM.idDivCmnt}>
                  <div className={StylesM.texte}> Commentaires :</div>
                  {dmd.action_demandeAbsence === true ?
                  <textarea className={StylesM.inputGlobal}style={{color:"green", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled >
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>: 
                  <textarea className={StylesM.inputGlobal} style={{color:"red", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled>
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>
                          }
              </div>
              </div>
          </div>
          </>

          )
  }


              </PopupComponentGstTemps>
              </div>    
                        } 
        section4={
                <FourthSectionTablePageTablet 
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
              component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
                component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
                
                /> 

                } 
        NbreSections={4} 
        prctgeSection1={8}
        prctgeSection2={6} 
        prctgeSection3={80}
        prctgeSection4={6}
        />
</div>
<div className={StylesM.mobile}>
        <GeneralCardTest 
         section1={
          <TopMenuGestionDuTemps page="historiqueAbsence"/>
      }  
        section2={
        <SecondSectionTablePageMobile 
        nbreComponents={6}
        component1={ <ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
              component6={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 

        />
      // <>testttt</>

      }  
        section3={
          <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
          {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          setIdWorkflow(row.id);
          setShow(true);
        }
      }
      }
      
    
  />  
  }
    <PopupComponentGstTemps onClose={() => setShow(false)} show={show} width="250px" height="200px">
{
       listHistoriqueAbsence.filter(resp => resp.id === idWorkflow).map(dmd => 
          <>
          <div  className={StylesM.insidePopup}>
            <div className={StylesM.firstSection}>
              <div style={dmd.action_demandeAbsence === true ? {boxShadow:"inset 0.6vw 0 0 0 green, 0 0 8px rgba(0, 0, 0, 0.1)"}: 
             {boxShadow:"inset 0.6vw 0 0 0 red, 0 0 8px rgba(0, 0, 0, 0.1)"}} className={StylesM.divideFirstSection}>
                <div className={StylesM.first}>
                Demande effectuée par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} le {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")} 
                </div>
                <div className={StylesM.second}>
                <span id={StylesM.idFleche}>
                  <i class="fa-solid fa-right-long"></i>
                  </span>
                </div >
                {dmd.action_demandeAbsence === true ?
                <div className={StylesM.third}>
                    Demande <span className={StylesM.textColored}  style={{display:"contents",color:"green"}}>Validée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </div>
                :
                <div  className={StylesM.third}>
                      Demande <span className={StylesM.textColored}  style={{display:"contents",color:"red"}}>Refusée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      {/* testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt */}

                </div>
                
                }
              </div>
            </div>
            <div className={StylesM.secondSection}>
              <div id={StylesM.idDivCmnt}>
                  <div className={StylesM.texte}> Commentaires :</div>
                  {dmd.action_demandeAbsence=== true ?
                  <textarea className={StylesM.inputGlobal}style={{color:"green", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled >
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>: 
                  <textarea className={StylesM.inputGlobal} style={{color:"red", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled>
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>
                          }
              </div>
              </div>
          </div>
          </>

          )
  }


              </PopupComponentGstTemps>
        </div>  
                        } 
        section4={
                <FourthSectionTablePageMobile 
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
                component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}

                /> 
              // <>TESTTT</>
                } 
        NbreSections={4} 
        prctgeSection1={7}
        prctgeSection2={6} 
        prctgeSection3={81}
        prctgeSection4={6}
        />
</div>
<div className={StylesM.mobileInversePetit}>
        <GeneralCardTest 
        section1={
          <TopMenuGestionDuTemps page="historiqueAbsence"/>
      } 
        section2={
        <SecondSectionTablePageMobileInversePetit 
        nbreComponents={6}
        component1={ <ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
              component6={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 

        />
      // <>TESTTT</>

      }  
        section3={
          <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
          {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          setIdWorkflow(row.id);
          setShow(true);
        }
      }
      }
      
    
  />  
  }
    <PopupComponentGstTemps onClose={() => setShow(false)} show={show} width="250px" height="200px">
{
       listHistoriqueAbsence.filter(resp => resp.id === idWorkflow).map(dmd => 
          <>
          <div  className={StylesM.insidePopup}>
            <div className={StylesM.firstSection}>
              <div style={dmd.action_demandeAbsence === true ? {boxShadow:"inset 0.6vw 0 0 0 green, 0 0 8px rgba(0, 0, 0, 0.1)"}: 
             {boxShadow:"inset 0.6vw 0 0 0 red, 0 0 8px rgba(0, 0, 0, 0.1)"}} className={StylesM.divideFirstSection}>
                <div className={StylesM.first}>
                Demande effectuée par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} le {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")} 
                </div>
                <div className={StylesM.second}>
                <span id={StylesM.idFleche}>
                  <i class="fa-solid fa-right-long"></i>
                  </span>
                </div >
                {dmd.action_demandeAbsence === true ?
                <div className={StylesM.third}>
                    Demande <span className={StylesM.textColored}  style={{display:"contents",color:"green"}}>Validée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </div>
                :
                <div  className={StylesM.third}>
                      Demande <span className={StylesM.textColored}  style={{display:"contents",color:"red"}}>Refusée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      {/* testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt */}

                </div>
                
                }
              </div>
            </div>
            <div className={StylesM.secondSection}>
              <div id={StylesM.idDivCmnt}>
                  <div className={StylesM.texte}> Commentaires :</div>
                  {dmd.action_demandeAbsence === true ?
                  <textarea className={StylesM.inputGlobal}style={{color:"green", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled >
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>: 
                  <textarea className={StylesM.inputGlobal} style={{color:"red", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled>
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>
                          }
              </div>
              </div>
          </div>
          </>

          )
  }


              </PopupComponentGstTemps>
        </div>
                        } 
        section4={
                <FourthSectionTablePageMobileInversePetit 
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
                component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}

                /> 
              // <>TESTTT</>
                } 
        NbreSections={4} 
        prctgeSection1={10}
            prctgeSection2={9} 
            prctgeSection3={72}
            prctgeSection4={9}
        />
</div>
<div className={StylesM.mobileInverseGrand}>
        <GeneralCardTest 
        section1={
          <TopMenuGestionDuTemps page="historiqueAbsence"/>
      }
        section2={
        <SecondSectionTablePageMobileInverseGrand 
        nbreComponents={5}
        component1={ <ListColumns listColumn={initData}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />
      } 
        component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />
      }
        component3={<LineFilterTableInput valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)} />} 
        component4={<div  className={StylesM.testIcons}>
        <div className={StylesM.sectionIcons}>
        <div className={StylesM.buttonSection}>
          <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}>
            <span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span>
          </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span>
        </button>
        </div>
        <div  className={StylesM.buttonSection}>
        <button title="Excel" className={StylesM.idBtnIcon}>
          <span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span>
        </button>
        </div>
        </div>
        </div>}
        component5={<span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
        <span className={StylesM.rightBottomBar}>
          { 
        idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
          }
              </span> 
        </span>}
        />
      // <>TESTTT</>

      } 
        section3={
          <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
         {
    (dataEmployes && headerTest) && 
      <TableComponent data={dataEmployes} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={true}
      onClickRow={(e, row) => {
        console.log('Row:', e);
        if (e && e.target) {
          setIdWorkflow(row.id);
          setShow(true);
        }
      }
      }
      
    
  />  
  }
    <PopupComponentGstTemps onClose={() => setShow(false)} show={show} width="300px" height="200px">
{
       listHistoriqueAbsence.filter(resp => resp.id === idWorkflow).map(dmd => 
          <>
          <div  className={StylesM.insidePopup}>
            <div className={StylesM.firstSection}>
              <div style={dmd.action_demandeAbsence === true ? {boxShadow:"inset 0.6vw 0 0 0 green, 0 0 8px rgba(0, 0, 0, 0.1)"}: 
             {boxShadow:"inset 0.6vw 0 0 0 red, 0 0 8px rgba(0, 0, 0, 0.1)"}} className={StylesM.divideFirstSection}>
                <div className={StylesM.first}>
                Demande effectuée par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} le {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")} 
                </div>
                <div className={StylesM.second}>
                <span id={StylesM.idFleche}>
                  <i class="fa-solid fa-right-long"></i>
                  </span>
                </div >
                {dmd.action_demandeAbsence === true ?
                <div className={StylesM.third}>
                    Demande <span className={StylesM.textColored}  style={{display:"contents",color:"green"}}>Validée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                </div>
                :
                <div  className={StylesM.third}>
                      Demande <span className={StylesM.textColored}  style={{display:"contents",color:"red"}}>Refusée</span> par {dmd.nomValidation_demandeAbsence} {dmd.prenomValidation_demandeAbsence} Le {moment(dmd.dateValidation_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      {/* testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt
                      testtttttttttttttttttttttttttttttttttttttt */}

                </div>
                
                }
              </div>
            </div>
            <div className={StylesM.secondSection}>
              <div id={StylesM.idDivCmnt}>
                  <div className={StylesM.texte}> Commentaires :</div>
                  {dmd.action_demandeAbsence === true ?
                  <textarea className={StylesM.inputGlobal}style={{color:"green", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled >
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>: 
                  <textarea className={StylesM.inputGlobal} style={{color:"red", width:"96%",marginLeft:"2%",marginRight:"2%",resize:"none"}} disabled>
                  {dmd.commentaireValidation_demandeAbsence}
                  </textarea>
                          }
              </div>
              </div>
          </div>
          </>

          )
  }


              </PopupComponentGstTemps>
        </div>  
                        } 
        section4={
                <FourthSectionTablePageMobileInverseGrand  
                component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange = {() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />
              } 
              component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
                component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
                
                /> 
              // <>TESTTT</>
                } 
        NbreSections={4} 
        prctgeSection1={10}
            prctgeSection2={9} 
            prctgeSection3={72}
            prctgeSection4={9}
        />
</div>

</div> 
  )
}

export default HistoriqueAbsenceSection;