import React, { useState, useEffect } from 'react';
import APIService from '../../../APIService';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import logosolway from "./../../img/logosolway.png";
import Styles from './Register.module.css';
import faviconsolway from '../../img/faviconsolway.png';
import solwayGroup2 from '../../img/solwayGroup_2.png';

import "./../../../App.css";
// import Alert from '../../../components/alert/Alert';
// import Statut from "./../../img/statut.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import LoadingPopup from './../../GlobalComponents/loadingPopup/LoadingPopup';

import solwayGroup from '../../img/solwayGroup.png';
import Alert from '../../GlobalComponents/alert/Alert';


// import '../App.css'



function Register() {
    let navigate = useNavigate();
    
    const [token, setToken] = useCookies(['mytoken', 'username'])
    const [nom, setNom] = useState(null)
    const [prenom, setPrenom] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [confPassword, setConfPassword] = useState(null)
    const [errorInput, setErrorInput] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(null)
    const [isHovered, setIsHovered] = useState(false);
    const [click, setClick] = useState(false)
    const [isLoading, setIsLoading] = useState(false)




    async function registerBtn(e){

        e.preventDefault();
        console.log('prenom : ',prenom)
        console.log('nom : ',nom)
        console.log('password : ',password)


        if(prenom && nom && email && password && password === confPassword){
            // setIsLoading(true)
            setClick(true)
            let body = {"first_name": prenom, "last_name": nom, "username": email, 
            'email': email, "role": "user", 'resetPasswortToken' : null, 'activation_token': null, 
            'reset_token': null, 'enabled': false, 'isVerified': true,  "password": password, 'isValidByAdmin': true}

            const response = await APIService.postUser(body)
            // console.log('Response : ',response)
            if (response?.status === 'success') {
                // console.log('Response : ',response)
                setIsSuccessful(response?.detail)
                setIsLoading(false)
            }else if(response?.status === 'error'){
                // console.log('Inside err ')
                // console.log('Err',response?.detail)
                setIsSuccessful(false)
                setIsLoading(false)
            }


            // APIService.postUtilisateur(body)
            // .then(resp => {
            //     setIsSuccessful(resp.is_successful);
            //     setIsLoading(false)
            //     // console.log("success :",resp.is_successful);
            //     // console.log("success 2:",resp);



            // })

        } else {
            setErrorInput(true)
        }

        


    }





  

  return (
    <>
    {/* PC */}

    <div className={Styles.classDivGlobal}  id={Styles.web}>

    <div className={Styles.leftDiv}>
    <div className={Styles.leftRectangle}>
    <div className={Styles.logoDiv}>
    <div id={Styles.logoSolwayHrContainer}>
                            <img className={Styles.solwayGroupLogo} src={faviconsolway} /> 
                        </div>
                        <span id={Styles.textTitleCard}>SOLWAY HR - Une gestion complète et efficace de votre écosystème RH</span>
    </div>

    <div className={Styles.discDiv}>
    <span className={Styles.discSpan} style={{fontFamily: "Cambria"}}>
                        <p style={{fontFamily: "Cambria"}}>SOLWAY HR est un système de gestion des ressources humaines avancé, qui exploite la puissance de l'intelligence artificielle pour simplifier les tâches et optimiser les processus RH. Notre outil offre une gestion complète et efficace de votre écosystème RH, incluant :</p>
                        <ul style={{fontFamily: "Cambria"}}>
                            <li style={{fontFamily: "Cambria"}}>Le processus de recrutement</li>
                            <li style={{fontFamily: "Cambria"}}>L'embauche</li>
                            <li style={{fontFamily: "Cambria"}}>La gestion des employés</li>
                            <li style={{fontFamily: "Cambria"}}>Le suivi du temps de travail</li>
                            <li style={{fontFamily: "Cambria"}}>La paie</li>
                            <li style={{fontFamily: "Cambria"}}>Les entretiens annuels</li>
                            <li style={{fontFamily: "Cambria"}}>Et bien plus encore.</li>
                        </ul>
                        <p style={{fontFamily: "Cambria"}}>Avec SOLWAY HR, bénéficiez d'une gestion RH optimale et concentrez-vous sur l'essentiel : le développement de votre entreprise.</p>

                         </span>
    </div>
    </div>

    </div>

    <div className={Styles.rightDiv}>
    <div className={Styles.heaerDiv}>
      <spam className={Styles.headerLogin}>
      <img id={Styles.logoConnect} src={solwayGroup2} alt="" />

<br />
{/* Bienvenue <br /> */}
<span id={Styles.idTextConnect}>Connectez-vous</span>
      </spam>
    </div>

    <div className={Styles.formContainer}>
    <form className={Styles.formStyle}  onSubmit = {e => registerBtn(e)}>
    <div className={Styles.inputsDiv}>
        <div class={Styles.inputContainer}>
            <i class="fa-regular fa-user"></i>
            <input type="text" placeholder="Prénom" value={prenom} onChange = {e => {setPrenom(e.target.value); setErrorInput(false); setIsSuccessful(null)}}/>
        </div>
        <div class={Styles.inputContainer}>
            <i class="fa-regular fa-user"></i>
            <input type="text" placeholder="Nom" value={nom} onChange = {e => {setNom(e.target.value); setErrorInput(false); setIsSuccessful(null)}}/>
        </div>
        <div class={Styles.inputContainer}>
            <i class="fa-solid fa-at"></i>
            <input type="email" placeholder="Email" value={email} onChange = {e => {setEmail(e.target.value); setErrorInput(false); setIsSuccessful(null)}} />
        </div>

        <div class={Styles.inputContainer}>
            <i class="fa-solid fa-lock"></i>
            <input type='password'
                  placeholder="Mot de passe" value={password} onChange = {e => {setPassword(e.target.value); setErrorInput(false); setIsSuccessful(null)}} />
        </div>
        <div class={Styles.inputContainer}>
            <i class="fa-solid fa-lock"></i>
            <input type='password'
                  placeholder="Confirmer le mot de passe" value={confPassword} onChange = {e => {setConfPassword(e.target.value); setErrorInput(false); setIsSuccessful(null)}}/>
        </div>
    </div>

    <div className={Styles.alertsDiv}>

    {
        confPassword && confPassword !== password && !errorInput ?
        // <div className = {Styles.classError}>Tous les champs sont obligatoires ! </div>
        <Alert type = "danger" text = "Les mots de passe doivent être pareils." bottomMsg = "" handleCloseClick = {() => setErrorInput(false)} />

        :
        <></>
    }

    {/* {
        confPassword && confPassword !== password && !errorInput ?
        <div className = {Styles.classError}>Les mots de passe doivent etre les memes ! </div>:
        <></>
    } */}
    {
        errorInput ?
        // <div className = {Styles.classError}>Tous les champs sont obligatoires ! </div>
        <Alert type = "danger" text = " Veuillez remplir tous les champs obligatoires." bottomMsg = "" handleCloseClick = {() => setErrorInput(false)}/>

        :
        <></>
    }
    {
        isSuccessful ?
        <Alert type = "success" text = "Votre compte a été créé et un email de validation vous a été envoyé." bottomMsg = "" handleCloseClick = {() => {setIsSuccessful(null); setClick(false)}}/>

        :
        <>
        {
            isSuccessful === false && click ?
            <Alert type = "danger" text = " Un compte est déjà associé à cette adresse e-mail." bottomMsg = "" handleCloseClick = {() => {setIsSuccessful(null); setClick(false)}}/>

            :
            <></>

        }
        </>
    }


    {
            isLoading
            &&
            <LoadingPopup />
    }

    </div>



    <div className={Styles.loginButtonDiv}>
      <button type="submit" onSubmit = {e => registerBtn(e)}>
          S'inscrire
      </button>
    </div>

    </form>
    </div>

    <div className={Styles.signUpDiv}>
      <span>Vous avez déjà un compte ? <a onClick={() => navigate('/')}>S'identifier</a></span>
    </div>

    </div>

    </div>

    {/* Mobile */}

    <div className={Styles.classDivGlobalMobile}  id={Styles.mobile}>

    <div className={Styles.topDiv}>
    <div className={Styles.logoMobileDiv}>
    <img className={Styles.solwayGroupLogoMobile}  src={solwayGroup}/>
    </div>
    </div>

    <div className={Styles.bottomDiv}>
    <div className={Styles.descDivMobile}>
    <spam className={Styles.discSpamMobile}>
    SOLWAY HR : système RH avancé utilisant l'IA pour simplifier les tâches et optimiser les processus. Gestion complète de l'embauche, des employés, du temps de travail, de la paie et des entretiens annuels.

    </spam>
    </div>

    <div className={Styles.formMobileDiv}>
    <div className={Styles.heaerDivMobile}>
    <spam className={Styles.headerLoginMobile}>
    <img id={Styles.logoConnectMobile} src={faviconsolway} />
    

<br />
<span id={Styles.idTextConnectMobile}> Création de compte</span> 
       
    </spam>
    </div>

    <div className={Styles.formContainerMobile}>
    <form className={Styles.formStyleMobile}  onSubmit = {e => registerBtn(e)}>
    <div className={Styles.inputsDivMobile}>
        <div class={Styles.inputContainerMobile}>
            <i class="fa-regular fa-user"></i>
            <input type="text" placeholder="Prénom" value={prenom} onChange = {e => {setPrenom(e.target.value); setErrorInput(false); setIsSuccessful(null)}}/>
        </div>
        <div class={Styles.inputContainerMobile}>
            <i class="fa-regular fa-user"></i>
            <input type="text" placeholder="Nom" value={nom} onChange = {e => {setNom(e.target.value); setErrorInput(false); setIsSuccessful(null)}}/>
        </div>
        <div class={Styles.inputContainerMobile}>
            <i class="fa-solid fa-at"></i>
            <input type="email" placeholder="Email" value={email} onChange = {e => {setEmail(e.target.value); setErrorInput(false); setIsSuccessful(null)}} />
        </div>

        <div class={Styles.inputContainerMobile}>
            <i class="fa-solid fa-lock"></i>
            <input type='password'
                  placeholder="Mot de passe" value={password} onChange = {e => {setPassword(e.target.value); setErrorInput(false); setIsSuccessful(null)}} />
        </div>
        <div class={Styles.inputContainerMobile}>
            <i class="fa-solid fa-lock"></i>
            <input type='password'
                  placeholder="Confirmer le mot de passe" value={confPassword} onChange = {e => {setConfPassword(e.target.value); setErrorInput(false); setIsSuccessful(null)}}/>
        </div>
    </div>

    <div className={Styles.alertsDivMobile}>

    {
        confPassword && confPassword !== password && !errorInput ?
        // <div className = {Styles.classError}>Tous les champs sont obligatoires ! </div>
        <Alert type = "danger" text = "Les mots de passe doivent être pareils." bottomMsg = "" handleCloseClick = {() => setErrorInput(false)} />

        :
        <></>
    }

    {/* {
        confPassword && confPassword !== password && !errorInput ?
        <div className = {Styles.classError}>Les mots de passe doivent etre les memes ! </div>:
        <></>
    } */}
    {
        errorInput ?
        // <div className = {Styles.classError}>Tous les champs sont obligatoires ! </div>
        <Alert type = "danger" text = " Veuillez remplir tous les champs obligatoires." bottomMsg = "" handleCloseClick = {() => setErrorInput(false)}/>

        :
        <></>
    }
    {
        isSuccessful ?
        <Alert type = "success" text = "Votre compte a été créé et un email de validation vous a été envoyé." bottomMsg = "" handleCloseClick = {() => {setIsSuccessful(null); setClick(false)}}/>

        :
        <>
        {
            isSuccessful === false && click ?
            <Alert type = "danger" text = " Un compte est déjà associé à cette adresse e-mail." bottomMsg = "" handleCloseClick = {() => {setIsSuccessful(null); setClick(false)}}/>

            :
            <></>

        }
        </>
    }


    {
            isLoading
            &&
            <LoadingPopup />
    }

    </div>


    <div className={Styles.loginButtonDivMobile}>
    <button type="submit" onSubmit = {e => registerBtn(e)}>
        S'inscrire
    </button>
    </div>

    </form>
    </div>

    <div className={Styles.signUpDivMobile}>
    <span>Vous avez déjà un compte ? <a onClick={() => navigate('/')}>S'identifier</a></span>
    </div>
    </div>



    </div>
    </div>



        
    </>
  )

}

export default Register