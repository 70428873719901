import React from 'react'
import styles from './Alert.module.css';

import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";

function Alert(props) {
    return (
        // <div>
        <>
            {props.type === "success" && 
            <div className={styles.alertContainerSuccess}>
            <div className={styles.iconDivSuccess}>
                <FaRegCheckCircle className={styles.icon}/>
            </div>

            <div className={styles.textDivSuccess}>
                <span>{props.text}</span>
            </div>

            <div className={styles.closeDivSuccess}>
                <button onClick={props.handleCloseClick}>
                    <IoCloseSharp className={styles.icon}/>
                </button>
            </div>
            </div>
            }

            {props.type === "danger" &&
            <div className={styles.alertContainerErr}>
            <div className={styles.iconDivErr}>
                <RiCloseCircleLine className={styles.icon}/>
            </div>

            <div className={styles.textDivErr}>
                <span>{props.text}</span>
            </div>

            <div className={styles.closeDivErr}>
                <button onClick={props.handleCloseClick}>
                    <IoCloseSharp className={styles.icon}/>
                </button>
            </div>
            </div>
            }

        </>
        // </div>
    )
}

export default Alert