import React from 'react'
import { jsPDF } from "jspdf";
import PdfReview from './pdfReview';


function Pdf(props) {
    const doc = new jsPDF();
    var ext = ''

    function getUrl(url, doc) {

        ext = url.split(".")
        ext = ext[ext.length - 1]
        ext = ext.toLocaleLowerCase() 
        var img = new Image()

        

        if(ext === "pdf" || props.ext === "pdf"){
            return url
        }
        else{
            
            img.src = url
            // doc.addImage(img, 10, 10, img.width, img.height)
            doc.addImage(img, 10, 10, 190, 280)

            return URL.createObjectURL(doc.output("blob"))
        }
        
    }

  return (
    <div>
        <PdfReview url={getUrl(props.url, doc)}/>
    </div>
  )
}

export default Pdf