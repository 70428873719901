import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import BanqueSection from './BanqueSection.jsx';



export const Banque = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<BanqueSection />} />
   
 </div>
)}


export default Banque