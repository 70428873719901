import react, {useEffect, useState} from "react";
import styles from "./AcompteSalaireSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";
import LineFilterTableInput from "../../GlobalComponents/lineFilterTableInput/LineFilterTableInput";
import PaginationTable from "../../GlobalComponents/paginationTable/PaginationTable";
import GeneralCardTest from "../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import FourthSectionTablePage from "../../GlobalComponents/fourthSectionTablePage/FourthSectionTablePage.jsx"
import CheckboxTableInput from "../../GlobalComponents/checkboxTableInput/CheckboxTableInput.jsx";
import SelectNbreLigneTableInput from "../../GlobalComponents/selectNbreLigneTableInput/SelectNbreLigneTableInput.jsx";
import SecondSectionTablePage from "../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import ListColumns from "../../GlobalComponents/listColumns/ListColumns.jsx";
import DescriptionPaieComponent from "../../GlobalComponents/descriptionPaieComponent/DescriptionPaieComponent.jsx";
import PopupComponentGstTemps from "../../GlobalComponents/popupComponentGstTemps/PopupComponentGstTemps.jsx";
import ButtonValider from "../../GlobalComponents/buttonValider/ButtonValider.jsx";


function AcompteSalaireSection(){
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const navigate = useNavigate();

    const [matriculePaie, setMatriculePaie] = useState();
    const [listAcompteSalaire, setListAcompteSalaire] = useState();
    const [listAcompteSalaireEtat, setListAcompteSalaireEtat] = useState([]);
    const [dateDebut, setDateDebut] = useState();
    const [montant, setMontant] = useState();
    const [remarque, setRemarque] = useState();

    const [dateDebutDelimiter, setDateDebutDelimiter] = useState();
    const [montantDelimiter, setMontantDelimiter] = useState();
    const [remarqueDelimiter, setRemarqueDelimiter] = useState();


    const [idModifier, setIdModifier] = useState();
    const [idDelimiter, setIdDelimiter] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();
    const [isIconUp, setIsIconUp] = useState(false);
    
    useEffect(() => {
      if(matriculePaie){
        APIService.getAcompteSalairePaie(matriculePaie, token).then(resp => setListAcompteSalaire(resp));
      }
    }, [matriculePaie, listAcompteSalaireEtat])

    useEffect(() => {

        if(JSON.stringify(listAcompteSalaireEtat) !== JSON.stringify(listAcompteSalaire)){
            setListAcompteSalaireEtat(listAcompteSalaire)
        }
    }, [listAcompteSalaire])

    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])
    


    const btnEnregistrer = () => {
        let body = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            montant : "",
            remarque : ""
        }

        let bodyDelimiter = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            montant : "",
            remarque : ""
        }

        if(dateDebut && montant){
            body.matricule_employe = matriculePaie;
            body.matricule_manager = matriculePaie;
            body.dateDebut = dateDebut;
            body.montant = montant;
            body.remarque = remarque;

            if(idModifier){

                APIService.putAcompteSalairePaie(idModifier, body, token);
                setIdModifier();
                setShow(false)
                setDateDebut("")
                setMontant("")
                setRemarque("")
             
            } else {
                if(idDelimiter){

                    bodyDelimiter.matricule_employe = matriculePaie;
                    bodyDelimiter.matricule_manager = matriculePaie;
                    bodyDelimiter.dateDebut = dateDebutDelimiter;
                    bodyDelimiter.montant = montantDelimiter;
                    bodyDelimiter.remarque = remarqueDelimiter;
                   

                    APIService.putAcompteSalairePaie(idDelimiter, bodyDelimiter, token);
                    APIService.postAcompteSalairePaie(body, token);

                    setIdDelimiter();
                    setShow(false)
                    setDateDebut("")
                    setMontant("")
                    setRemarque("")

                } else {
                    listAcompteSalaire.map(
                        resp => {
                            let bodyTemp = {
                                matricule_employe : "",
                                matricule_manager : "", 
                                dateDebut : "",
                                montant : "",
                                remarque : ""
                            }
                            setDateDebut("")
                            setMontant("")
                            setRemarque("")
                                bodyTemp.matricule_employe = resp.matricule_employe;
                                bodyTemp.matricule_manager = resp.matricule_manager;
                                bodyTemp.dateDebut = resp.dateDebut;
                                bodyTemp.montant = resp.montant;
                                bodyTemp.remarque = resp.remarque;

                                APIService.putAcompteSalairePaie(resp.id, bodyTemp, token);
                        }
                    )
                    APIService.postAcompteSalairePaie(body, token);

                }
            }

            APIService.getAcompteSalairePaie(matriculePaie, token).then(resp => {setListAcompteSalaire(resp); setListAcompteSalaireEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deleteAcompteSalairePaie(idRem, token);
        APIService.getAcompteSalairePaie(matriculePaie, token).then(resp => {setListAcompteSalaire(resp); setListAcompteSalaireEtat(resp);});

        setIdModifier();
        setIdDelimiter();
        
    }

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listAcompteSalaire.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setDateDebut(resp.dateDebut);
            setMontant(resp.montant);
            setRemarque(resp.remarque);

            setIdDelimiter();

        })


    }

    
    

    const btnDelimiter = (e) => {

        if(!etatAjouter) {
            setEtatAjouter(true);
        }

        listAcompteSalaire.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdDelimiter(resp.id);
            setDateDebutDelimiter(resp.dateDebut);
            setMontantDelimiter(resp.montant);
            setRemarqueDelimiter(resp.remarque);

            setDateDebut("");
            setMontant(resp.montant);
            setRemarque(resp.remarque);


            setIdModifier();


        })
    }


    const [show, setShow] = useState(false)

    const btnAjouter = () => {  
        if(etatAjouter){
             setEtatAjouter(prevState => !prevState);
          setIsIconUp(prevState => !prevState);
          setShow(prevState => !prevState);

        } else {
            setDateDebut("")
            setMontant("")
            setRemarque("")
            setEtatAjouter(prevState => !prevState);
            setIsIconUp(prevState => !prevState);
            setShow(prevState => !prevState);
        }

        setIdModifier();
        setIdDelimiter();
        setDateDebut("")
        setMontant("")
        setRemarque("")
    }
    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }

       // *****************************************************
const [listAcompteSalaireResult, setListAcompteSalaireResult] = useState(null)
const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()

useEffect(() => {

  if(matriculePaie){
    APIService.getAcompteSalairePaie(matriculePaie, token).then(
        resp => setListAcompteSalaire(resp));
  }
}, [matriculePaie,listAcompteSalaireEtat])





useEffect(() => {
  if(valeurRechercheGlobale && listAcompteSalaire){
    setListAcompteSalaireResult(
      listAcompteSalaire.filter(resp =>  
         
            (Object.keys(headerTest).includes("dateDebut") && resp.dateDebut.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))
             ||
            (Object.keys(headerTest).includes("matricule_employe") && resp.matricule_employe.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("matricule_manager") && resp.matricule_manager.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("montant") && resp.montant.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))||
            (Object.keys(headerTest).includes("remarque") && resp.remarque.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))


             )

            )
  }else{
    if(listAcompteSalaire){
      setListAcompteSalaireResult(listAcompteSalaire)
    }
  }
}, [valeurRechercheGlobale,listAcompteSalaire])

// Pagination

const [index, setIndex] = useState(0)
const [page, setPage] = useState(null)
const [pageTickets, setPageTickets] = useState([])
const [nombrePageArray_, setNombrePageArray_] = useState([])
const [nombrePageValeurFixed, setNombrePageValeurFixed] = useState(null)
const [afficherChecked, setAfficherChecked] = useState(false)


function transformNumberToArray(n) {
   const array = [];
   for (let i = 1; i <= n; i++) {
       array.push(i);
   }
   return array;
}


useEffect(() => {
 if(listAcompteSalaireResult && parseInt(index) !== 0 && page !== 0 )

 {
   let indexInt = parseInt(index);

   setPageTickets(listAcompteSalaireResult.slice(page*indexInt - indexInt, page*indexInt))
   setNombrePageArray_(transformNumberToArray(Math.ceil(listAcompteSalaireResult.length/parseInt(index))))

 } else {

   if( listAcompteSalaireResult && parseInt(index) === 0){
     setPage(1)
     setPageTickets(listAcompteSalaireResult)
     setNombrePageArray_([])
   


   }

 }

}, [listAcompteSalaireResult, index, page])




useEffect(() => {
 if(afficherChecked && listAcompteSalaireResult){
   setNombrePageValeurFixed(0);
   setIndex(0);
 }
}, [afficherChecked, listAcompteSalaireResult])

const handleChangeNombrePage = (e) => {
 setAfficherChecked(false)
 setNombrePageValeurFixed(parseInt(e.target.value))
 setIndex(e.target.value)
 setPage(1);
}

const [dataUsed, setDataUsed] = useState(null)

useEffect(() => {

 if(pageTickets){

   setDataUsed(pageTickets)
 }

}, [pageTickets])


// *********************************************************



// ****************************************************
const [showTest, setShowTest] = useState(false)
const [idTest, setIdTest] = useState()
const [errorMessage, setErrorMessage] = useState('');
// const [errorMessageDelimiter, setErrorMessageDelimiter] = useState('');

    //    const handleDateDebutChange = (e) => {
    //     const startDate = e.target.value;
    //     if (startDate > dateFin) {
    //         setErrorMessage("date debut doit etre inferieure a date fin ! ");
    //     } else {
    //         setErrorMessage("");
    //     }
    //     setDateDebut(startDate);
    // };
  //   const handleDateDebutChangeDelimiter = (e) => {
  //     const startDate = e.target.value;
  //     if (startDate > dateFin) {
  //         setErrorMessageDelimiter("date debut doit etre inferieure a date fin ! ");
  //     } else {
  //         setErrorMessageDelimiter("");
  //     }
  //     setDateDebut(startDate);
  // };


      const deleteButton = (id) => {
        if(id && token){
          console.log("delete button",id)
          setShowTest(true); 
          setIdTest(id)
        }
      }
      const deleteButtonOui=()=>{
         APIService.deleteAcompteSalairePaie(idTest, token);
          APIService.getAcompteSalairePaie(matriculePaie, token).then(resp => {setListAcompteSalaire(resp); setListAcompteSalaireEtat(resp);});
          setIdModifier();
          setIdDelimiter(); 
          setShowTest(false)

      }
      const deleteButtonNon=()=>{
        setIdTest();
        setShowTest(false)

     }
      const modifyButton = (id) => {
        if(id){
          console.log("modify button",id)
          if(!etatAjouter){
            setEtatAjouter(true);
        }
        if(!show){
          setShow(true)
        }else{
          setShow(!show)
        }
          setErrorMessage("");
        const selectedResp = listAcompteSalaire.find(resp => resp.id === parseInt(id));
         console.log("modify button", selectedResp)
        if (selectedResp) {
            setIdModifier(selectedResp.id);
            setDateDebut(selectedResp.dateDebut);
            setMontant(selectedResp.montant);
            setRemarque(selectedResp.remarque);
            setIdDelimiter();

        }
        }
        
      }
      const hideButton = (id) => {
        if(id){
          console.log("hide button",id)
        }
      }
      const sendButton = (id) => {
        if(id){
          console.log("send button",id)
        }
      }
      const delimiterButton = (id) => {
        if(id){
          console.log("modify button",id)
          if(!etatAjouter){
            setEtatAjouter(true);
        }
        if(!show){
          setShow(true)
        }else{
          setShow(!show)
        }
        setErrorMessage("");
        const selectedResp = listAcompteSalaire.find(resp => resp.id === parseInt(id));
         console.log("Delimited button", selectedResp)
        if (selectedResp) {
          setIdDelimiter(selectedResp.id);
            setDateDebutDelimiter(selectedResp.dateDebut);
            setMontantDelimiter(selectedResp.montant);
            setRemarqueDelimiter(selectedResp.remarque);
            setDateDebut("");
            setMontant(selectedResp.montant);
            setRemarque(selectedResp.remarque);
            setIdModifier();
        }
        }
      }



      const [headerTest, setHeaderTest] = useState({dateDebut:"Date de Début",montant:"Montant"
      // ,salaireBase:"Valeur"
    });
    //   const test={ dateDebut:"Date de Début",dateFin:"Date de Fin"
    //   ,salaireBase:"Valeur"
    // }
    
       const componentsList={ 1: "Send",2:"Delete",3:"Modify",4:"Hide",5:"Delete",6:"Delimiter"}
       const methodsList={ 1: sendButton,2:deleteButton,3:modifyButton,4:hideButton,5:deleteButton,6:delimiterButton}
       
    
    

    return(
    //     <div className={styles.classCard}>
    //     <div className={styles.generalContainer}>
    //         <div className={styles.firstPart}>
    //         <TopMenuPaie page="AcompteSalaire"  />
            
    //         </div>
    //         <div className={styles.secondPart}>
    //                 <div className={styles.tableAndFormSection}>
    //                     <div className={styles.buttonAndFormSection}>
    //                         <div className={styles.buttonSection_}>
    //                             <button className={styles.btnAjouter} onClick = {btnAjouter}>
    //                                                     Ajouter une nouvelle ligne &nbsp; {' '}
    //                                                     {isIconUp ? (
    //                                                         <i className="fa-solid fa-caret-up"></i>
    //                                                     ) : (
    //                                                         <i className="fa-solid fa-caret-down"></i>
    //                                                     )}
    //                             </button>
    //                         </div>
    //                         <div className={styles.formSection}>
    //                             <br />
    //                             {idModifier ? 
    //                                 <div className={styles.modifiableTexte2}>
    //                                     <i class="fa-solid fa-pencil"></i> &nbsp;
    //                                     Modifier</div>

    //                                 :
    //                                 <>
    //                                 {
    //                                     idDelimiter ?
    //                                     <div className={styles.modifiableTexte2}>
    //                                         <i class="fa-solid fa-scissors"></i> &nbsp;
    //                                         Délimiter</div> :<></>
    //                                 }
                                    
    //                                 </>
    //                             }
                                
    //                             {
    //                                 etatAjouter ? 
    //                                 <>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                          Date
    //                                         </div>
    //                                         <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                         Montant
    //                                         </div>
    //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                  <div id={styles.idDivCmnt}>
    //                                    <div className={styles.texte}> Remarque </div>
    //                                     <textarea className={styles.inputGlobal} rows="2" style={{color:"black", width:"96%", minHeight: "30px",resize: "vertical",marginLeft:"2%",marginRight:"2%"}} value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" />
    //                                 </div>
    //                                 <div className={styles.btnSave}>
    //                                     <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
    //                                 </div>
                                     
    //                                  </>:<></>
    //                                 }
                                    

    //                         </div>

    //                     </div>
    //                         <div className={styles.tableSection}>
    //                             <div className={styles.tableZone} >
    //                             <table className={styles.classTable}>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Date</th>
    //                                     <th>Montant</th>
    //                                     <th>Action</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {
    //                                     listAcompteSalaire ? 
                                    
    //                                     listAcompteSalaire.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
    //                                         <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
    //                                             <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
    //                                             <td>{resp.montant}</td>
    //                                             <td>
    //                                                 <button id={styles.trashButton} value={resp.id} onClick={ () => 
    //                                                     handleRowClick(resp.id)
    //                                                     }>
    //                                                         <i class="fa-solid fa-trash-can"></i>
    //                                                         </button>
    //                                                         {idSupprimerAlert && idSupprimerAlert===resp.id ? (
    //                                                             <>
    //                                                             <div className={styles.overlay}></div>
    //                                                             <div className={styles.popup}>
    //                                                                 <div id={styles.supprimerTexte}>Supprimer</div>
    //                                                                 <br />
    //                                                                 <div className={styles.buttonsPopup}>
    //                                                                 <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
    //                                                                 <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
    //                                                                 </div>
    //                                                             </div>
    //                                                             </>
    //                                                             ):<></>
    //                                                         }


    //                                                     {
    //                                                     moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
    //                                                     <>
    //                                                             &nbsp;
    //                                                             <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
    //                                                              <i class="fa-solid fa-pencil"></i>
    //                                                                 </button>


    //                                                             &nbsp;

    //                                                             <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
    //                                                             <i class="fa-solid fa-scissors"></i>
    //                                                                 </button>

    //                                                     </>:<></>
    //                                                     }






    //                                                 </td>
    //                                         </tr>
    //                                     )
                                    
                                        
    //                                     :<></>

    //                                 }
                                    
    //                             </tbody>
    //                             </table>
    //                             </div>
    //                         </div>
    //                 </div>
    //                 {/* **************** Mobile ******** */}
    //                 <div className={styles.tableAndFormSectionMobile}>
    //                     <div className={styles.tableSection}>
    //                             <div className={styles.tableZone} >
    //                             <table className={styles.classTable}>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Date</th>
    //                                     <th>Montant</th>
    //                                     <th>Action</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {
    //                                     listAcompteSalaire ? 
                                    
    //                                     listAcompteSalaire.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
    //                                         <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
    //                                             <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
    //                                             <td>{resp.montant}</td>
    //                                             <td>
    //                                                 <button id={styles.trashButton} value={resp.id} onClick={ () => 
    //                                                     handleRowClick(resp.id)
    //                                                     }>
    //                                                         <i class="fa-solid fa-trash-can"></i>
    //                                                         </button>
    //                                                         {idSupprimerAlert && idSupprimerAlert===resp.id ? (
    //                                                             <>
    //                                                             <div className={styles.overlay}></div>
    //                                                             <div className={styles.popup}>
    //                                                                 <div id={styles.supprimerTexte}>Supprimer</div>
    //                                                                 <br />
    //                                                                 <div className={styles.buttonsPopup}>
    //                                                                 <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
    //                                                                 <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
    //                                                                 </div>
    //                                                             </div>
    //                                                             </>
    //                                                             ):<></>
    //                                                         }


    //                                                     {
    //                                                     moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
    //                                                     <>
    //                                                             &nbsp;
    //                                                             <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
    //                                                              <i class="fa-solid fa-pencil"></i>
    //                                                                 </button>

                                                            
    //                                                             &nbsp;
                                                            
    //                                                             <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
    //                                                             <i class="fa-solid fa-scissors"></i>
    //                                                                 </button>
                                                                
    //                                                     </>:<></>
    //                                                     }
                                                    
                                                    


                                                    
    //                                                 </td>
    //                                         </tr>
    //                                     )
                                    
                                        
    //                                     :<></>

    //                                 }
                                    
    //                             </tbody>
    //                             </table>
    //                             </div>
    //                     </div>
    //                     <div className={styles.buttonAndFormSection}>
    //                         <div className={styles.buttonSection_}>
    //                             <button className={styles.btnAjouter} onClick = {btnAjouter}>
    //                                                     Ajouter une nouvelle ligne &nbsp; {' '}
    //                                                     {isIconUp ? (
    //                                                         <i className="fa-solid fa-caret-up"></i>
    //                                                     ) : (
    //                                                         <i className="fa-solid fa-caret-down"></i>
    //                                                     )}
    //                             </button>
    //                         </div>
    //                         <div className={styles.formSection}>
    //                             <br />
    //                             {idModifier ? 
    //                                 <div className={styles.modifiableTexte2}>
    //                                     <i class="fa-solid fa-pencil"></i> &nbsp;
    //                                     Modifier</div>

    //                                 :
    //                                 <>
    //                                 {
    //                                     idDelimiter ?
    //                                     <div className={styles.modifiableTexte2}>
    //                                         <i class="fa-solid fa-scissors"></i> &nbsp;
    //                                         Délimiter</div> :<></>
    //                                 }
                                    
    //                                 </>
    //                             }
                                
    //                             {
    //                                 etatAjouter ? 
    //                                 <>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                          Date
    //                                         </div>
    //                                         <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                 <div className={styles.container1}>
    //                                     <div className={styles.zoneInput}>
    //                                         <div className={styles.fileNameTxtStyle1}>
    //                                         Montant
    //                                         </div>
    //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
    //                                     </div>
    //                                 </div>
    //                                 <div id={styles.idDivCmnt}>
    //                                    <div className={styles.texte}> Remarque </div>
    //                                     <textarea className={styles.inputGlobal} rows="2" style={{color:"black", width:"96%", minHeight: "30px",resize: "vertical",marginLeft:"2%",marginRight:"2%"}} value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" />
    //                                 </div>
    //                                 <div className={styles.btnSave}>
    //                                     <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
    //                                 </div>
                                     
    //                                  </>:<></>
    //                                 }
                                    

    //                         </div>

    //                     </div>
                            
    //                 </div>
    //         </div>
    //     </div>
    // </div>
     <div>
     <div className={styles.web} >
 
     <GeneralCardTest 
     section1={
     <TopMenuPaie page="AcompteSalaire"/>
     } 
     section2={
     <SecondSectionTablePage 
     nbreComponents={4}
     component1={<DescriptionPaieComponent/>
     } 
     component2={ <ListColumns listColumn={listAcompteSalaireResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
     component3={<>
     <div className={styles.inputgroup}>
             <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
                 <div className={styles.zoneComponent}>
                     <div className={styles.text}>
                         <span className={styles.creeravenat_}>
                         Ajouter une nouvelle ligne 
                         </span> 
                     </div>
                     <div className={styles.iconeRight}>
                     {isIconUp ? (
                         <i className="fa-solid fa-caret-up"></i>
                         ) : (
                         <i className="fa-solid fa-caret-down"></i>
                         )}
 
                     </div>
                 </div>
             </div>
     </div>
     <PopupComponentGstTemps onClose={() => {
         setShow(false);
         setIsIconUp(false);
         setIdModifier();
         setIdDelimiter();
     }} show={show} width="400px" height="250px"
     popUpHeader={
       idModifier ? (
           <>
               <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
           </>
       ) : idDelimiter ? (
           <>
               <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
           </>
       ) : (
           <></>
       )
     }
                                                     
                                                     >
     <div className={styles.formSection}>
                                <div className={styles.container1}>
                                       <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                             Date
                                          </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)}/>
                                       </div>
                                     </div>
                                              <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                Montant
                                                </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={montant} onChange={(e) => setMontant(e.target.value)} disabled/>
                                                       </>:<>
                                                       <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                                       </>
                                                     }
                                                </div>
                                             </div>
                                            <div id={styles.idDivCmnt}>
                                                <div className={styles.texte}> Remarque </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                            <textarea className={styles.inputGlobalDisabled}  value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" disabled/>
                                                       </>:<>
                                                            <textarea className={styles.inputGlobal} value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" />
                                                       </>
                                                     }
                                            </div>
                                            
                                             <div  className={styles.divBtnSubmit}>
                                                   {(!dateDebut || !montant || !remarque) ? 
                                                   <>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                   :<>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                 }
                                             </div>
                                             
 
                                     </div>
     </PopupComponentGstTemps>
 
     {showTest && (
                     <PopupComponentGstTemps
                         onClose={() => {setShowTest(false);setIdTest()}}
                         show={showTest}
                         width="400px"
                         height="250px"
                         popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                     >
                         <div className={styles.popUpDeleteContent}>
                           <div className={styles.firstPartDelete}>
                             <div className={styles.textDelete}>
                             Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                             </div>
                           </div>
                           <div className={styles.thirdPartDelete}>
                             <ButtonValider textButton={"Annuler"}
                               buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                             <div></div>
                             <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                           </div>
                         </div>
 
 
 
                   </PopupComponentGstTemps>
                 )}
     </>}  
     component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
     }
     />}
     section3={
     <>
     {
       ( dataUsed && headerTest) && 
         <TableComponent data={dataUsed} cols={headerTest} 
         addAction={true} 
         contentAction={methodsList}
         componentsList={componentsList}
         clickableRow={false}
         idDelete={idTest}
         idModify={idModifier}
         idDelimit={idDelimiter}
 
     />  
     }
     </>    
     } 
     section4={
               <FourthSectionTablePage 
               component1={ 
               <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
               } 
               component2={
               <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
             } 
               component3={
               <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
             }
               /> 
             } 
     NbreSections={4} 
     prctgeSection1={8}
     prctgeSection2={6} 
     prctgeSection3={80}
     prctgeSection4={6}
     />
     </div>
     <div className={styles.tablet}>
 <GeneralCardTest 
 section1={
 <TopMenuPaie page="AcompteSalaire"/>
 }  
 section2={
   <SecondSectionTablePageTablet 
   nbreComponents={4}
   component1={<DescriptionPaieComponent/>
   } 
   component2={
      <ListColumns listColumn={listAcompteSalaireResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
   component3={<>
   <div className={styles.inputgroup}  >
           <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
               <div className={styles.zoneComponent}>
                   <div className={styles.text}>
                       <span className={styles.creeravenat_}>
                       Ajouter une nouvelle ligne 
                       </span> 
                   </div>
                   <div className={styles.iconeRight}>
                   {isIconUp ? (
                       <i className="fa-solid fa-caret-up"></i>
                       ) : (
                       <i className="fa-solid fa-caret-down"></i>
                       )}
 
                   </div>
               </div>
           </div>
   </div>
   <PopupComponentGstTemps onClose={() => {
       setShow(false);
       setIsIconUp(false);
       setIdModifier();
       setIdDelimiter();
   }} show={show} width="400px" height="280px"
   popUpHeader={
     idModifier ? (
         <>
             <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
         </>
     ) : idDelimiter ? (
         <>
             <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
         </>
     ) : (
         <></>
     )
   }
                                                   
                                                   >
   <div className={styles.formSection}>
                                <div className={styles.container1}>
                                       <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                             Date
                                          </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)}/>
                                       </div>
                                     </div>
                                              <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                Montant
                                                </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={montant} onChange={(e) => setMontant(e.target.value)} disabled/>
                                                       </>:<>
                                                       <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                                       </>
                                                     }
                                                </div>
                                             </div>
                                            <div id={styles.idDivCmnt}>
                                                <div className={styles.texte}> Remarque </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                            <textarea className={styles.inputGlobalDisabled}  value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" disabled/>
                                                       </>:<>
                                                            <textarea className={styles.inputGlobal} value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" />
                                                       </>
                                                     }
                                            </div>
                                            
                                             <div  className={styles.divBtnSubmit}>
                                                   {(!dateDebut || !montant || !remarque) ? 
                                                   <>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                   :<>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                 }
                                             </div>
                                             
 
                                     </div>
     </PopupComponentGstTemps>
 
   {showTest && (
                   <PopupComponentGstTemps
                       onClose={() => {setShowTest(false);setIdTest()}}
                       show={showTest}
                       width="400px"
                       height="250px"
                       popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                   >
                       <div className={styles.popUpDeleteContent}>
                         <div className={styles.firstPartDelete}>
                           <div className={styles.textDelete}>
                           Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                           </div>
                         </div>
                         <div className={styles.thirdPartDelete}>
                           <ButtonValider textButton={"Annuler"}
                             buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                           <div></div>
                           <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                         </div>
                       </div>
 
 
 
                 </PopupComponentGstTemps>
               )}
   </>}  
   component4={
   <LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
   }
   />}
 section3={
   <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
             {
               ( dataUsed && headerTest) && 
                 <TableComponent data={dataUsed} cols={headerTest} 
                 addAction={true} 
                 contentAction={methodsList}
                 componentsList={componentsList}
                 clickableRow={false}
                 idDelete={idTest}
                 idModify={idModifier}
                 idDelimit={idDelimiter}
                 //  condition={showTest}
 
             />  
             }
             </div>   
                 } 
 section4={
         <FourthSectionTablePageTablet 
         component1={ 
           <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
           } 
           component2={
           <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
         } 
           component3={
           <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
         }/>
 
         } 
 NbreSections={4} 
 prctgeSection1={8}
 prctgeSection2={6} 
 prctgeSection3={80}
 prctgeSection4={6}
 />
 </div>
 <div className={styles.mobile}>
 <GeneralCardTest 
  section1={
 <TopMenuPaie page="AcompteSalaire"/>
 }  
 section2={
   <SecondSectionTablePageMobile
   nbreComponents={5}
   component1={<DescriptionPaieComponent/>
   } 
   component2={
     <>
 <div className={styles.inputgroup}  >
           <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
               <div className={styles.zoneComponent}>
                   <div className={styles.text}>
                       <span className={styles.creeravenat_}>
                       Ajouter une nouvelle ligne 
                       </span> 
                   </div>
                   <div className={styles.iconeRight}>
                   {isIconUp ? (
                       <i className="fa-solid fa-caret-up"></i>
                       ) : (
                       <i className="fa-solid fa-caret-down"></i>
                       )}
 
                   </div>
               </div>
           </div>
   </div>
   <PopupComponentGstTemps onClose={() => {
       setShow(false);
       setIsIconUp(false);
       setIdModifier();
       setIdDelimiter();
   }} show={show} width="250px" height="200px"
   popUpHeader={
     idModifier ? (
         <>
             <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
         </>
     ) : idDelimiter ? (
         <>
             <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
         </>
     ) : (
         <></>
     )
   }
                                                   
                                                   >
    <div className={styles.formSection}>
                                <div className={styles.container1}>
                                       <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                             Date
                                          </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)}/>
                                       </div>
                                     </div>
                                              <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                Montant
                                                </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={montant} onChange={(e) => setMontant(e.target.value)} disabled/>
                                                       </>:<>
                                                       <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                                       </>
                                                     }
                                                </div>
                                             </div>
                                            <div id={styles.idDivCmnt}>
                                                <div className={styles.texte}> Remarque </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                            <textarea className={styles.inputGlobalDisabled}  value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" disabled/>
                                                       </>:<>
                                                            <textarea className={styles.inputGlobal} value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" />
                                                       </>
                                                     }
                                            </div>
                                            
                                             <div  className={styles.divBtnSubmit}>
                                                   {(!dateDebut || !montant || !remarque) ? 
                                                   <>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                   :<>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                 }
                                             </div>
                                             
 
                                     </div>
     </PopupComponentGstTemps>
 
   {showTest && (
                   <PopupComponentGstTemps
                       onClose={() => {setShowTest(false);setIdTest()}}
                       show={showTest}
                       width="250px"
                       height="200px"
                       popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                   >
                       <div className={styles.popUpDeleteContent}>
                         <div className={styles.firstPartDelete}>
                           <div className={styles.textDelete}>
                           Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                           </div>
                         </div>
                         <div className={styles.thirdPartDelete}>
                           <ButtonValider textButton={"Annuler"}
                             buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                           <div></div>
                           <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                         </div>
                       </div>
 
 
 
                 </PopupComponentGstTemps>
               )}
               </>
 
    }
   component3={
      <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
 
   }  
   component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
   }
   component5={ 
     <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
     } 
   />}
 section3={
   <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
   {
     ( dataUsed && headerTest) && 
       <TableComponent data={dataUsed} cols={headerTest} 
       addAction={true} 
       contentAction={methodsList}
       componentsList={componentsList}
       clickableRow={false}
       idDelete={idTest}
       idModify={idModifier}
       idDelimit={idDelimiter}
       //  condition={showTest}
 
   />  
   }
   </div>   
                 } 
 section4={
         <FourthSectionTablePageMobile 
           component1={
             <ListColumns listColumn={listAcompteSalaireResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />
         } 
           component2={
           <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
         }
         />
         } 
 NbreSections={4} 
 prctgeSection1={7}
 prctgeSection2={6} 
 prctgeSection3={81}
 prctgeSection4={6}
 />
 </div>
 <div className={styles.mobileInversePetit}>
 <GeneralCardTest 
 section1={
     <TopMenuPaie page="AcompteSalaire"/>
 } 
 section2={
 <SecondSectionTablePageMobileInversePetit 
 nbreComponents={5}
 component1={<DescriptionPaieComponent/>
 } 
 component2={
   <>
 <div className={styles.inputgroup}  >
           <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
               <div className={styles.zoneComponent}>
                   <div className={styles.text}>
                       <span className={styles.creeravenat_}>
                       Ajouter une nouvelle ligne 
                       </span> 
                   </div>
                   <div className={styles.iconeRight}>
                   {isIconUp ? (
                       <i className="fa-solid fa-caret-up"></i>
                       ) : (
                       <i className="fa-solid fa-caret-down"></i>
                       )}
 
                   </div>
               </div>
           </div>
   </div>
   <PopupComponentGstTemps onClose={() => {
       setShow(false);
       setIsIconUp(false);
       setIdModifier();
       setIdDelimiter();
   }} show={show} width="300px" height="180px"
   popUpHeader={
     idModifier ? (
         <>
             <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
         </>
     ) : idDelimiter ? (
         <>
             <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
         </>
     ) : (
         <></>
     )
   }
                                                   
                                                   >
  <div className={styles.formSection}>
                                <div className={styles.container1}>
                                       <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                             Date
                                          </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)}/>
                                       </div>
                                     </div>
                                              <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                Montant
                                                </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={montant} onChange={(e) => setMontant(e.target.value)} disabled/>
                                                       </>:<>
                                                       <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                                       </>
                                                     }
                                                </div>
                                             </div>
                                            <div id={styles.idDivCmnt}>
                                                <div className={styles.texte}> Remarque </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                            <textarea className={styles.inputGlobalDisabled}  value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" disabled/>
                                                       </>:<>
                                                            <textarea className={styles.inputGlobal} value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" />
                                                       </>
                                                     }
                                            </div>
                                            
                                             <div  className={styles.divBtnSubmit}>
                                                   {(!dateDebut || !montant || !remarque) ? 
                                                   <>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                   :<>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                 }
                                             </div>
                                             
 
                                     </div>
     </PopupComponentGstTemps>
 
   {showTest && (
                   <PopupComponentGstTemps
                       onClose={() => {setShowTest(false);setIdTest()}}
                       show={showTest}
                       width="300px"
                       height="180px"
                       popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                   >
                       <div className={styles.popUpDeleteContent}>
                         <div className={styles.firstPartDelete}>
                           <div className={styles.textDelete}>
                           Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                           </div>
                         </div>
                         <div className={styles.thirdPartDelete}>
                           <ButtonValider textButton={"Annuler"}
                             buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                           <div></div>
                           <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                         </div>
                       </div>
 
 
 
                 </PopupComponentGstTemps>
               )}
               </>
 }
 
 component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
 }
 component4={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
 component5={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
 />
 
 }  
 section3={
   <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
     ( dataUsed && headerTest) && 
       <TableComponent data={dataUsed} cols={headerTest} 
       addAction={true} 
       contentAction={methodsList}
       componentsList={componentsList}
       clickableRow={false}
       idDelete={idTest}
       idModify={idModifier}
       idDelimit={idDelimiter}
       //  condition={showTest}
 
   />  
   }
 </div>
                 } 
 section4={
         <FourthSectionTablePageMobileInversePetit 
         component1={ 
            <ListColumns listColumn={listAcompteSalaireResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
 
           component2={
           <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
         }
         
         /> 
         } 
 NbreSections={4} 
 prctgeSection1={10}
     prctgeSection2={9} 
     prctgeSection3={72}
     prctgeSection4={9}
 />
 </div>
 <div className={styles.mobileInverseGrand}>
 <GeneralCardTest 
 section1={
 <TopMenuPaie page="AcompteSalaire"/>
 }
 section2={
 <SecondSectionTablePageMobileInverseGrand 
 nbreComponents={4}
 component1={<DescriptionPaieComponent/>
 } 
 component2={
   <ListColumns listColumn={listAcompteSalaireResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
 component3={
   <>
   <div className={styles.inputgroup}  >
           <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
               <div className={styles.zoneComponent}>
                   <div className={styles.text}>
                       <span className={styles.creeravenat_}>
                       Ajouter une nouvelle ligne 
                       </span> 
                   </div>
                   <div className={styles.iconeRight}>
                   {isIconUp ? (
                       <i className="fa-solid fa-caret-up"></i>
                       ) : (
                       <i className="fa-solid fa-caret-down"></i>
                       )}
 
                   </div>
               </div>
           </div>
   </div>
   <PopupComponentGstTemps onClose={() => {
       setShow(false);
       setIsIconUp(false);
       setIdModifier();
       setIdDelimiter();
   }} show={show} width="350px" height="200px"
   popUpHeader={
     idModifier ? (
         <>
             <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
         </>
     ) : idDelimiter ? (
         <>
             <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
         </>
     ) : (
         <></>
     )
   }
                                                   
                                                   >
    <div className={styles.formSection}>
                                <div className={styles.container1}>
                                       <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                             Date
                                          </div>
                                            <input type="date" id={styles.inputNombreheure} className={styles.goldenInput1} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)}/>
                                       </div>
                                     </div>
                                              <div className={styles.container1}>
                                                <div className={styles.zoneInput}>
                                                    <div className={styles.fileNameTxtStyle1}>
                                                Montant
                                                </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={montant} onChange={(e) => setMontant(e.target.value)} disabled/>
                                                       </>:<>
                                                       <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                                       </>
                                                     }
                                                </div>
                                             </div>
                                            <div id={styles.idDivCmnt}>
                                                <div className={styles.texte}> Remarque </div>
                                                {
                                                       idDelimiter ?
                                                       <>
                                                            <textarea className={styles.inputGlobalDisabled}  value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" disabled/>
                                                       </>:<>
                                                            <textarea className={styles.inputGlobal} value={remarque} onChange={(e) => setRemarque(e.target.value)} id="comnt" />
                                                       </>
                                                     }
                                            </div>
                                            
                                             <div  className={styles.divBtnSubmit}>
                                                   {(!dateDebut || !montant || !remarque) ? 
                                                   <>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                   :<>
                                                   <div className={styles.sectionButtonEnregistrer}> 
                                                     <div></div>
                                                     <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                     </div>
                                                   </>
                                                 }
                                             </div>
                                             
 
                                     </div>
     </PopupComponentGstTemps>
 
   {showTest && (
                   <PopupComponentGstTemps
                       onClose={() => {setShowTest(false);setIdTest()}}
                       show={showTest}
                       width="350px"
                       height="200px"
                       popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                   >
                       <div className={styles.popUpDeleteContent}>
                         <div className={styles.firstPartDelete}>
                           <div className={styles.textDelete}>
                           Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                           </div>
                         </div>
                         <div className={styles.thirdPartDelete}>
                           <ButtonValider textButton={"Annuler"}
                             buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                           <div></div>
                           <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                         </div>
                       </div>
 
 
 
                 </PopupComponentGstTemps>
               )}
   </>
 }
 component4={ 
   <LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
 } 
 />
 
 } 
 section3={
   <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
   {
     ( dataUsed && headerTest) && 
       <TableComponent data={dataUsed} cols={headerTest} 
       addAction={true} 
       contentAction={methodsList}
       componentsList={componentsList}
       clickableRow={false}
       idDelete={idTest}
       idModify={idModifier}
       idDelimit={idDelimiter}
       //  condition={showTest}
 
   />  
   }
 </div>  
                 } 
 section4={
         <FourthSectionTablePageMobileInverseGrand  
         component1={ 
           <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
           } 
           component2={
           <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
         } 
           component3={
           <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
         }
         /> 
         } 
 NbreSections={4} 
 prctgeSection1={10}
     prctgeSection2={9} 
     prctgeSection3={72}
     prctgeSection4={9}
 />
 </div> 
 
 </div>
    )
}

export default AcompteSalaireSection;