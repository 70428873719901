import React from "react";
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from '../../../APIService.js';

import styles from './DonneesAdministrationSection.module.css';
import {useNavigate} from 'react-router-dom';




function DonneesAdministrationSection() {
    const [token, setToken, removeToken] = useCookies(['mytoken']);
    const [listSexe, setListSexe] = useState(null)
    const [showAjouter, setShowAjouter] = useState(false)
    const [type, setType] = useState(null)
    const [idModif, setIdModif] = useState(null); 
    const [nomModif, setNomModif] = useState(null); 

    let navigate = useNavigate()

    useEffect(() => {

        if(token['mytoken']){
            APIService.getSexeAdministration(token)
            .then(resp => setListSexe(resp))
        }
      
    }, [token['mytoken']])


    // useEffect(() => {

    //     if(type){
    //         console.log("Type : ", type)
    //     }
      
    // }, [type])
    

    const btnShowAjouter = () => {
   
        if (showAjouter) {
            setShowAjouter(false);
            setIdModif(null);  
            setNomModif(null);
          } else {
            setShowAjouter(true);
          }
        };

    // const handelAjouterSexe = () => {
    //     if(type){
    //         let formData = new FormData();
    //         formData.append('type', type)

    //         APIService.postSexeAdministration(formData, token)
    //         .then(
    //             () =>  APIService.getSexeAdministration(token)
    //             .then(resp => setListSexe(resp))
    //         )
    //     } else {
    //         console.log('Le type est obligatoire')

    //     }
    // }
    


    // const handelSupprimerSexe = (id) => {

    //     if(id){
    //         APIService.deleteSexeAdministration(id, token)
    //         .then(
    //             () =>  APIService.getSexeAdministration(token)
    //             .then(resp => setListSexe(resp))
    //         )
    //     }
    // }

    function handelAjouterSexe(e){
        e.preventDefault();

        if (type) {
          let formData = new FormData();
          formData.append('type', type);
      
              APIService.postSexeAdministration(formData, token).then(() => {
              APIService.getSexeAdministration(token).then((resp) => setListSexe(resp));
            })
            .then(

                () =>
                {   
                    setType('');
                    setIdModif(null);
                    setShowAjouter(false);
                }
            )          
      
          
        } else {
          console.log('Le type est obligatoire');
        }
      };
      

      
        function handelModifierSexe(e){
            e.preventDefault();

        if (type) {
            let formData = new FormData();
            formData.append('type', type);

            APIService.putSexeAdministration(idModif, formData, token)
              .then(() => {
                APIService.getSexeAdministration(token).then((resp) => setListSexe(resp));
              })
              .then(

                () =>
                {   
                    setType('');
                    setIdModif(null);
                    setShowAjouter(false);
                }
            )
            
      }

    }


    
    const handelSupprimerSexe = (id) => {
        if (id) {
          APIService.deleteSexeAdministration(id, token).then(() => {
            APIService.getSexeAdministration(token).then((resp) => setListSexe(resp));
          });
        }
      };
    
    
  return (
    <div className={styles.classCard}>


        <div className={styles.classMenu}>

             <button className={styles.classBtnActive}>Sexe</button>
             <button onClick={() => navigate('/languesAdministartion')} style={{cursor:"pointer"}} >Langue</button>
             <button  onClick={() => navigate('/situationFamilialeAdministration')} style={{cursor:"pointer"}} >sitiationfamiliale</button>
             <button  onClick={() => navigate('/TypeContrats')} style={{cursor:"pointer"}} >TypeContrats</button>
             <button  onClick={() => navigate('/jours')} style={{cursor:"pointer"}}>jours</button>
             <button  onClick={() => navigate('/temps')} style={{cursor:"pointer"}}>temps</button>
             <button  onClick={() => navigate('/banques')} style={{cursor:"pointer"}}>banques</button>
             <button  onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button>
             <button  onClick={() => navigate('/departement')} style={{cursor:"pointer"}}>Departement</button>
             <button  onClick={() => navigate('/poste')} style={{cursor:"pointer"}}>Poste</button>


        </div>


        <div className={styles.classTable}>

            <table className={styles.tableStyle}>
                <thead>
                    <tr>

                        <th>id</th>
                        <th>type</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {
                        listSexe && 

                        listSexe.map(
                            sx => 

                            <tr>
                                    <td>{sx.id}</td>
                                    <td>{sx.type}</td>
                                    <td>
                                        <button onClick = {() => handelSupprimerSexe(sx.id)}>Supprimer</button>
                                        <button >Modifier</button>

                                    </td>
                            </tr>


                        )


                    } */}

{listSexe &&
          listSexe.map((lg) => (
            <tr key={lg.id}>
              <td>{lg.id}</td>
              <td>{lg.type}</td>
              <td>
                <button onClick={() => handelSupprimerSexe(lg.id)}>Supprimer</button>
                <button onClick={() => {setIdModif(lg.id); setType(lg.type); setShowAjouter(true)}}>Modifier</button>
              </td>
            </tr>
          ))}
                  
                </tbody>
            </table>

        </div>
        <div className={styles.classAjouter}>
            <div>
                <button onClick = {btnShowAjouter} >Ajouter</button>
            </div>

            {
                showAjouter && 

            //     <div>
            //         <div>
            //             Type : <input type="text" value={type} onChange = {e => setType(e.target.value)} />
            //         </div>
            //         <div>
            //             <button onClick = {handelAjouterSexe}>Enregistrer</button>
            //         </div>

            //    </div>
            <div>

            <form  onSubmit = {e => {
            if(idModif){
                handelModifierSexe(e)
            } else {handelAjouterSexe(e)}
                                 } }>
            Type : <input type="text" value={type} onChange = {e => setType(e.target.value)} />
            </form>
        <div>
        
            <button onClick = { e =>

                idModif ?

                handelModifierSexe(e)
                :
                handelAjouterSexe(e)
                
                
                }>
                   
                    {
                        idModif ? <>Modifier</>
                        : <>Ajouter</>
                    }
                </button>
                
        </div>

   </div>
            }

        </div>

    </div>
  )
}


export default DonneesAdministrationSection