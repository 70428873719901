import React, { useState ,useEffect,useRef ,useLayoutEffect} from 'react';
import styles from './ListColumns.module.css';
import CustomSelect from '../customSelect/CustomSelect.jsx';

function ListColumns({listColumn, setHeader,addDefaultColumns,listDefaultColumns}) {
  const [columnsRendered, setColumnsRendered] = useState([])

// extrcation data et suppression id and remove defaut clms
  useEffect(() => {
    const firstObjectKeys = [];
    if(listColumn){
      for (let key in listColumn[0]) {
        firstObjectKeys.push(key);
        if (key === "id") {
          const index = firstObjectKeys.indexOf("id");
          firstObjectKeys.splice(index, 1); 
      }
      }
      console.log("TESTTTTT",firstObjectKeys);
      setColumnsRendered(firstObjectKeys)
      if (addDefaultColumns) {
        const defaultColumnKeys = Object.keys(listDefaultColumns);
      // console.log("deft clmm keys",defaultColumnKeys)
      const filteredColumns = firstObjectKeys.filter(column => !defaultColumnKeys.includes(column));
      console.log('Filtered Columns:', filteredColumns);
      setColumnsRendered(filteredColumns);
    }
      console.log("clm rendered",columnsRendered)
    }
}, [listColumn])

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [check, setCheck] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [optionSelected, setOptionSelected] = useState(true);
  const [isOpen, setIsOpen] = useState(false);


  const handleChange = (event) => {
    console.log("test " ,event);
    const selectedLabel = event;
    console.log("label",selectedLabel)
    if(selectedLabel!=="..."){
    if (!selectedOptions.includes(selectedLabel)) {
      setSelectedOptions(prevOptions => [...prevOptions, selectedLabel]);
      setOptionSelected(false);
      setColumnsRendered(columnsRendered.filter(column => column !== selectedLabel))
      setCheck(true);
    }}
    setInputValue('');
  };

  
  console.log("selected opts",selectedOptions)
  const [inputs, setInputs] = useState([]);

  const handleValiderClick = () => {
    if(inputValue!=='')
    {
      setInputs(prevInputs => [...prevInputs, inputValue]); 
      setInputValue('');
      setCheck(false)
      setOptionSelected(true)
    }

    
  };

useLayoutEffect(() => {
  const newHeader = {id:"id"}; 
  if(addDefaultColumns){
    Object.assign(newHeader, listDefaultColumns);
  }
  selectedOptions.map((option, index) => {
    if (inputs[index]) {
      newHeader[option] = inputs[index];
    }
  });
  setHeader(newHeader); 
console.log("header", newHeader);

}, [inputs, selectedOptions]); 

const [show, setShow] = useState(false);
const popupRef = useRef(null);
const barButtonRef = useRef(null);
  
const showPopUP = () => {
  setShow(prevShow => !prevShow);
  if (show) {
    console.log("Show True : ", show)
        popupRef.current.classList.remove(styles.fadeOut);
    setTimeout(() => {
          popupRef.current.style.display = 'none';
          console.log("New ref after .51s : ", popupRef.current.style.display)
      }, 510); 
}else{
  console.log("Show False : ", show)
  popupRef.current.classList.add(styles.fadeIn);
  popupRef.current.style.display = 'grid';
}
};
const handleClick = (event) => {
  if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)
) {
  showPopUP()
  }
};

useEffect(() => {
  if (show) {
    document.addEventListener('mousedown', handleClick);
  } else {
    document.removeEventListener('mousedown', handleClick);
  }
  return () => {
    document.removeEventListener('mousedown', handleClick);
  };
}, [show]);

const dropDownRef = useRef(null);
const [isDropdownOpen, setDropdownOpen] = useState(false);
const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
};
const handleClickDropDown = (event) => {
  if (dropDownRef.current && !dropDownRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)
) {
  toggleDropdown()
  }
};

useEffect(() => {
  if (isDropdownOpen) {
    document.addEventListener('mousedown', handleClickDropDown);
  } else {
    document.removeEventListener('mousedown', handleClickDropDown);
  }
  return () => {
    document.removeEventListener('mousedown', handleClickDropDown);
  };
}, [isDropdownOpen]);

  

  return (
    <div className={styles.styleDiv}>
      <div className={styles.inputgroup}  >
          <div className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} ref={barButtonRef} onClick={() => showPopUP()}>
              <div className={styles.zoneComponent}>
                  <div className={styles.icone}>
                      <i class="fa-solid fa-plus"></i>
                  </div>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                          ajouter des colonnes
                      </span> 
                  </div>
              </div>
          </div>
      </div>
          <div id={styles.popupStyle} className={show ? `${styles.active}` : `${styles.hidden}`} ref={popupRef}  style={{minHeight: "42px"}}>
                <div className={styles.header}>
                <div className={styles.title}>
                </div>
                <div className={styles.xStyle}>
                    <button className={styles.buttonx}  onClick={() => showPopUP()}>
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                </div>
              {check ?
              <>
                <div className={styles.styleAble}>
                  <div className={styles.dividedPart}>

                    <span className={styles.inputgroupPopUp} >
                       <CustomSelect 
                       label={"Sélectionner une colonne"}
                       options={columnsRendered}
                       isOpen={isDropdownOpen}
                       disabled={true} 
                       toggleDropDown={toggleDropdown}
                       handleOptionClick={handleChange}
                       selectRef={dropDownRef}
                      />
                    
                    </span>

                    <span className={styles.inputgroupPopUp}>
                      <label className={styles.labelPopUp}>
                        <div className={styles.divideLabel}>
                          <span className={styles.first}> Colonne choisie :  </span>
                          <span className={styles.second}> {selectedOptions[selectedOptions.length - 1]}</span>
                        </div>
                      </label>
                    </span>

                    <span className={styles.inputgroupPopUp}>
                      <label className={styles.labelPopUp}>Intitulé</label>
                      <input type="text" className={styles.testPopUp} value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
                    </span>

                    <div className={styles.btnsubmit2}>
                        <button type='submit' id={styles.btnRecherche2} className='bouttonsubmit' onClick={handleValiderClick}>
                        <span className={styles.text2}>Valider</span>
                        </button>         
                    </div>
                  </div>
                </div>
              </>
              :<>
                <div className={styles.styleDisable}>
                  <div  className={styles.dividedPartDis}>
                    <span className={styles.inputgroupPopUp} >
                       <CustomSelect 
                       label={"Sélectionner une colonne"}
                       options={columnsRendered}
                       isOpen={isDropdownOpen}
                       disabled={false} 
                       toggleDropDown={toggleDropdown}
                       handleOptionClick={handleChange}
                       selectRef={dropDownRef}
                      />
                    </span>
                    <span className={styles.inputgroupPopUp}>
                      <label className={styles.labelPopUp}>Intitulé</label>
                      <input type="text" className={styles.testPopUpDisable} value={inputValue} onChange={(e) => setInputValue(e.target.value)} disabled/>
                    </span>
                  </div>
                </div>
              </>}
          </div>
    </div>
  );
}
export default ListColumns;
