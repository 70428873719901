import React from 'react';
import styles from './NavBarGlobal.module.css';
import NavBar from './../NavBar';
import NavBarMobile from './../Mobile/NavBarMobile';


import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../../APIService.js';


import moment from 'moment';
import 'moment/locale/fr' 

moment.locale('fr')

function NavBarGlobal(props) {

  const [token, setToken, removeToken] = useCookies(['mytoken', 'username', 'matriculeManager', 'matriculeEmployeAttente', 'matriculeActuel'])
  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [employeCible, setEmployeCible] = useState([])



  useEffect(() => {
    APIService.getUsers(token['mytoken'])
      .then(resp => setUsers(resp))
    },token['mytoken'])

  useEffect(() => {
    APIService.getUsers(token['mytoken'])
  .then(resp => setAllUsers(resp))
  },token['mytoken'])


 


    useEffect(() => {

      APIService.getEmploye(APIService.getEmployeId(allUsers, token), token)
      .then(resp => setEmployeCible(resp))

    },[allUsers])
  
    useEffect(() => {

      if(employeCible){
        setToken('matriculeActuel', employeCible.matricule_employe)
        setToken('matriculeManager', employeCible.matricule_manager)
  
      }
      
    }, [employeCible])
  
    
    
  return (
    <div>
        <div id={styles.navPC}>
            <NavBar section = {props.section}/>
        </div>
        
        <div id={styles.navMobile}>
            <NavBarMobile section = {props.section}/>
        </div>
    </div>
  )
}

export default NavBarGlobal;