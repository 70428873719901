import React, { useState, useEffect } from 'react';
import styles from './InputTemps.module.css';

function Input(props) {

  const [disabledValue, setDisabledValue] = useState(false)


  useEffect(() => {
    if(props.disabled === true || props.disabled === false){

      setDisabledValue(props.disabled)
    } 
  }, [props.disabled])


  useEffect(() => {
    if(props.value){

      console.log("Value : ", props.value)
    } 
  }, [props.value])

 


  return (
      <div className={styles.container} id={styles.containerId}>
                  <div 
                  className={props.classZoneInputEtat ?
                  styles.zoneInputInput
                  : 
                  styles.zoneInputInputWithFiles
                }

                
                >
                              <span className={styles.fileNameTxtStyleInput}>
                              {props.title}  
                              </span>
                             




            <div className={styles.goldenInput1} id={props.inputId} >
                              



                              {props.value === "" || !props.value ? 

                              <>

                             
                                {
                                                                  props.listItem && 
                                                                  props.listItem.map(
                                                                        resp => 
                                                                        <input label={resp.nom} type={props.type} name={props.name} value={resp.id}  onChange={props.handleChange}></input>
                                                                  )
                                                            }
                              
                              </>
                                          :
                                          <div>
                                                { (props.value && props.listItem) ?

                                                <>
                                                {
                                                                props.listItem.map(
                                                                    resp => 
                                                                    <>
                                                                    {
                                                                            resp.id === parseInt(props.value) ?
                                                                                    <input label={resp.nom} type={props.type}  name={props.name} value={resp.id} onChange={props.handleChange} checked></input>

                                                                            :
                                                                            <input label={resp.nom} type={props.type}  name={props.name} value={resp.id} onChange={props.handleChange}></input>


                                                                    }
                                                                    
                                                                    
                                                                    </>
                                                                    // {
                                                                    //     if(resp.id === parseInt(props.value)){
                                                                    //         <>yes</>

                                                                    //     }
                                                                    //     else {
                                                                    //         <>no</>

                                                                    //             // <input label={resp.nom} type={props.type} name={props.name} value={resp.id} onChange={props.handleChange}></input>

                                                                    //     }
                                                                    // }
                                                                )
                                                }</>
                                               
                                                                 

                                                                  :<></>



                                                }
                                                
                                          </div>
  }
                        </div> 
                        </div>
                  </div> 
  )
}
{
 
}


export default Input