import React from 'react';
import styles from './GeneralCard.module.css'; 

function GeneralCardTest({
  // props
  NbreSections,prctgeSection1,prctgeSection2,prctgeSection3,prctgeSection4,
  section1,section2,section3,section4
}) {
    let sectionStyles = '';

    if (NbreSections === 1) {
        sectionStyles = styles.generalsection;
    } 

    const percentage1 = parseFloat(prctgeSection1);
    // console.log("pectg 1",percentage1,"no chnage ",prctgeSection1)
    // console.log("type of percentage1:", typeof percentage1);

    return (
        <div className={styles.classCard}>
            {NbreSections === 1 ? (
                <>
                <div className={sectionStyles} style={{width:"100%",height:"100%",display:"flex"}}>
                    {section1}
                </div>
                </>):<></>
            }

            {NbreSections === 2 ? (
                <>
                <div style={{width:"100%",height:"100%",display:"grid",gridTemplateRows: `${prctgeSection1}% ${prctgeSection2}%`}}>
                    <div style={{ width:"100%",height:"100%",display:"flex" }}>
                        {section1}
                    </div>
                    <div style={{width:"100%",height:"100%",display:"flex"}}>
                        {section2}
                    </div>
                </div>
            </>):<></>
        }

            {NbreSections === 3 ? (
                <>
                <div style={{width:"100%",height:"100%",display:"grid",gridTemplateRows: `${prctgeSection1}% ${prctgeSection2}% ${prctgeSection3}%`}}>
                    <div style={{width:"100%",height:"100%",display:"flex" }}>
                        {section1}
                    </div>
                    <div style={{width:"100%",height:"100%",display:"flex" }}>
                        {section2}
                    </div>
                    <div style={{width:"100%",height:"100%",display:"flex" }}>
                        {section3}
                    </div>
                </div>
          </>):<></>
        }

            {NbreSections === 4 ? (
                <>
                <div style={{width:"100%",height:"100%",display:"grid",gridTemplateRows: `${prctgeSection1}% ${prctgeSection2}% ${prctgeSection3}% ${prctgeSection4}%`}}>
                    <div style={{ width:"100%",height:"100%",display:"flex"}}>
                        {section1}
                    </div>
                    <div style={{width:"100%",height:"100%",display:"flex" }}>
                        {section2}
                    </div>
                    <div style={{width:"100%",height:"100%",display:"flex" }}>
                        {section3}
                    </div>
                    <div style={{width:"100%",height:"100%",display:"flex" }}>
                        {section4}
                    </div>
                </div>
                </>):<></>
            }
        </div>
    );
}
export default GeneralCardTest;
