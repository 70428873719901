import React, { useState, useEffect } from "react";
import {useCookies} from 'react-cookie';
import APIService from './../../../../APIService.js';
import Styles from './FormationSection.module.css';
import moment from 'moment';
import TopMenuSalarie from "../../TopMenuSalarie.jsx";
import GeneralCardTest from "../../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import SecondSectionTablePage from "../../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import DateComponent from "../../../GlobalComponents/dateComponent/DateComponent.jsx";
import CreateComponent from "../../../GlobalComponents/createComponent/CreateComponent.jsx";
import DescriptionComponent from "../../../GlobalComponents/descriptionComponent/DescriptionComponent.jsx";
import { useNavigate } from 'react-router-dom';


function FormationSection() {
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculeAvenant', 'id', 'matriculeEmployeAttente']);
    const [dateDebut, setDateDebut] = useState("")
    const [description, setDescription] = useState("")
  
    const [inputLieuFormation, setInputLieuFormation] = useState('')
    const [inputFormateur, setInputFormateur] = useState('')
    const [inputDateDebut, setInputDateDebut] = useState('')
    const [inputDateFin, setInputDateFin] = useState('')
    const [inputDescription, setInputDescription] = useState('');
  
  
  
  //   const [avenantsList, setAvenantsList] = useState()
  
    const [formErrorsFormation, setFormErrorsFormation] = useState({})
    const [formationValeurs, setFormationValeurs] = useState({})
  
    function inputId(valeur, erreur, nomId){
      if(!valeur && !erreur)
            {return nomId }
            else {
                  if(valeur && !erreur){       
                        return "inputVert";
                  }else{
                        if(erreur){
                              return "inputRouge";
                        }
                  }
            }
      }  
      const [listDescriptionAdministration, setListDescriptionAdministration] = useState(null)

      useEffect(() => {
  
          if(token['mytoken']){
              APIService.getDescriptionAdministration(token)
              .then(resp => setListDescriptionAdministration(resp))
          }
        
      }, [token['mytoken']])
  
      useEffect(() => {      
        setInputLieuFormation(inputId(formationValeurs.lieuFormation, formErrorsFormation.lieuFormation, "inputLieuFormation"))
      }, [formationValeurs.lieuFormation, formErrorsFormation.lieuFormation])
  
      useEffect(() => {      
        setInputFormateur(inputId(formationValeurs.formateur, formErrorsFormation.formateur, "inputFormateur"))
      }, [formationValeurs.formateur, formErrorsFormation.formateur])
  
      useEffect(() => {      
        setInputDateDebut(inputId(formationValeurs.dateDebut, formErrorsFormation.dateDebut, "inputDateDebut"))
      }, [formationValeurs.dateDebut, formErrorsFormation.dateDebut])
  
      useEffect(() => {      
        setInputDateFin(inputId(formationValeurs.dateFin, formErrorsFormation.dateFin, "inputDateFin"))
      }, [formationValeurs.dateFin, formErrorsFormation.dateFin])
  
      useEffect(() => {      
        setInputDescription(inputId(formationValeurs.description, formErrorsFormation.description, "inputDescription"))
      }, [formationValeurs.description, formErrorsFormation.description])
  
      const handleChangeFormation = (e) => {
  
        // e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, "");
        // e.target.value = e.clipboardData.getData('Text').trim();*
        e.target.value = e.target.value.replace(/^\s+/g, "");
    
        e.target.value = e.target.value.replace(/ +(?= )/g, "");
        
    
        if(e.target.name === 'lieuFormation' || e.target.name === 'formateur'){
    
             
              // e.target.value = e.target.value.replace(/[^A-Za-z]/g,"");
              e.target.value = e.target.value.replace(/[^a-zA-Z\u00C0-\u017F-\s]/g,"");
              e.target.value = e.target.value.toUpperCase() 
              
        }
        
    
        
        const {name, value} = e.target;
        
        setFormationValeurs({...formationValeurs, [name]: value});
        
    }
  
    const validateFormation = (valeurs) => {
  
      const errors = {};
    
      if(!valeurs.lieuFormation){
            errors.lieuFormation = "Le lieu de formation est obligatoire !";
    
      }
      if(!valeurs.formateur){
        errors.formateur = "Le formateur est obligatoire !";
      }
    
      if(!valeurs.dateDebut){
        errors.dateDebut = "La date de début est obligatoire !";
      }
      if(!valeurs.dateFin){
        errors.dateFin = "La date de fin est obligatoire !";
      }
    
      if(!valeurs.description){
        errors.description = "La description est obligatoire !";
      }
    
    
      return errors;
    
    }; 
  
    var body = {}
  
  
  
    const btnFormation = () => {
      setFormErrorsFormation(validateFormation(formationValeurs));
    
      if(formationValeurs.lieuFormation && formationValeurs.formateur && formationValeurs.dateDebut && formationValeurs.description){
    
        if(formationValeurs.dateFin){
          body = {matricule_employe: token['matriculeAvenant'], lieuFormation_formation:formationValeurs.lieuFormation, formateur_formation:formationValeurs.formateur, dateDebut_formation:formationValeurs.dateDebut, dateFin_formation:formationValeurs.dateFin, description_formation:formationValeurs.description }
          }else{
          body = {matricule_employe: token['matriculeAvenant'], lieuFormation_formation:formationValeurs.lieuFormation, formateur_formation:formationValeurs.formateur, dateDebut_formation:formationValeurs.dateDebut, dateFin_formation:"9999-01-01", description_formation:formationValeurs.description }
    
          }
    
    
          APIService.AjouterFormation(body, token)
    
          window.location.href = '/Statut'
  
      }
    }
  
    useEffect(() => {
  
      setDescription(formationValeurs.description)
      
      if((typeof formationValeurs.description === 'undefined' ||formationValeurs.description === "") && Object.keys(formErrorsFormation).length === 0){
                  console.log('')
                  
      }else{
            if(!formationValeurs.description) {
              setFormErrorsFormation({...formErrorsFormation, description:"La description est obligatoire !"})
                  // formErrorsAvenant.poste = "Le poste est obligatoire !";
            }else{
                  // formErrorsAvenant.poste = "";
              setFormErrorsFormation({...formErrorsFormation, description:""})
    
            }  
      } 
    }, 
    [formationValeurs.description])
  
    useEffect(() => {
  
      setDateDebut(formationValeurs.dateDebut)
      
      if((typeof formationValeurs.dateDebut === 'undefined' ||formationValeurs.dateDebut === "") && Object.keys(formErrorsFormation).length === 0){
                  console.log('')
                  
      }else{
            if(!formationValeurs.dateDebut) {
              setFormErrorsFormation({...formErrorsFormation, dateDebut:"La date de début est obligatoire !"})
                  // formErrorsAvenant.poste = "Le poste est obligatoire !";
            }else{
                  // formErrorsAvenant.poste = "";
              setFormErrorsFormation({...formErrorsFormation, dateDebut:""})
    
            }  
      } 
    }, 
    [formationValeurs.dateDebut])

    useEffect(() => {
  
      setDateDebut(formationValeurs.dateFin)
      
      if((typeof formationValeurs.dateFin === 'undefined' ||formationValeurs.dateFin === "") && Object.keys(formErrorsFormation).length === 0){
                  console.log('')
                  
      }else{
            if(!formationValeurs.dateFin) {
              setFormErrorsFormation({...formErrorsFormation, dateFin:"La date de fin est obligatoire !"})
                  // formErrorsAvenant.poste = "Le poste est obligatoire !";
            }else{
                  // formErrorsAvenant.poste = "";
              setFormErrorsFormation({...formErrorsFormation, dateFin:""})
    
            }  
      } 
    }, 
    [formationValeurs.dateFin])
  
    useEffect(() => {
  
      
      if((typeof formationValeurs.lieuFormation === 'undefined' ||formationValeurs.lieuFormation === "") && Object.keys(formErrorsFormation).length === 0){
                  console.log('')
                  
      }else{
            if(!formationValeurs.lieuFormation) {
              setFormErrorsFormation({...formErrorsFormation, lieuFormation:"Le lieu de formation est obligatoire !"})
                  // formErrorsAvenant.poste = "Le poste est obligatoire !";
            }else{
                  // formErrorsAvenant.poste = "";
              setFormErrorsFormation({...formErrorsFormation, lieuFormation:""})
    
            }  
      } 
    }, 
    [formationValeurs.lieuFormation])
  
    useEffect(() => {
  
      
      if((typeof formationValeurs.formateur === 'undefined' ||formationValeurs.formateur === "") && Object.keys(formErrorsFormation).length === 0){
                  console.log('')
                  
      }else{
            if(!formationValeurs.formateur) {
              setFormErrorsFormation({...formErrorsFormation, formateur:"Le formateur est obligatoire !"})
                  // formErrorsAvenant.poste = "Le poste est obligatoire !";
            }else{
                  // formErrorsAvenant.poste = "";
              setFormErrorsFormation({...formErrorsFormation, formateur:""})
    
            }  
      } 
    }, 
    [formationValeurs.formateur])
  
  
function displayErrorInput(erreur){
      return (
      <>
      
      <div className={Styles.container2}>
      <div className={Styles.zoneInputInput}>
            <span >
            
            </span>
            <span className={Styles.textdecorationInput}>
            {erreur ? 
            <>{erreur}</>
            :
            <></>
            } </span>
      </div>
</div>
      </>)
      
}
const navigate = useNavigate();
useEffect(() => {
      if( token['matriculeAvenant']){
        console.log("token", token['matriculeAvenant'])
    
      }else{
        console.log("no token here ")
      //   window.location.href = 'http://localhost:3000/salarie';
      navigate('/salarie');

      }
    }, [ token['matriculeAvenant']])
  return (


//     <div className={Styles.classCard}>


//           <div className={Styles.BarSection}>

//           <TopMenuSalarie page="formation" />

//           </div>


//           <div className={Styles.firstsetcion}>


//           <div id={Styles.divFormulaire}>

//           <div className={Styles.lecentredepage} >

//           <div className={Styles.container1} id={Styles.container4}>
//                         <div className={Styles.zoneInputInput}>
//                               <span className={Styles.fileNameTxtStyleInput}>
//                               Lieu formation
//                               </span>
//                               <input type="text" name="lieuFormation" value={formationValeurs.lieuFormation} onChange={handleChangeFormation} id={inputLieuFormation} placeholder=" " className={Styles.goldenInput1} />  
//                         </div>
//          </div> 

//          <span className={Styles.txtAlert}>{displayErrorInput(formErrorsFormation.lieuFormation) }</span>


//          <div className={Styles.container1} id={Styles.container4}>
//                         <div className={Styles.zoneInputInput}>
//                               <span className={Styles.fileNameTxtStyleInput}>
//                               Formateur
//                               </span>
//                               <input type="Nom" name="formateur" value={formationValeurs.formateur} onChange={handleChangeFormation} id={inputFormateur} placeholder="" className={Styles.goldenInput1}/>                        
//                         </div>
//          </div> 

//          <span className={Styles.txtAlert}>{displayErrorInput(formErrorsFormation.formateur)}</span>
  

//          <div className={Styles.container1} id={Styles.container4}>
//                         <div className={Styles.zoneInputInput}>
//                               <span className={Styles.fileNameTxtStyleInput}>
//                               Date début
//                               </span>
//                               <input placeholder="" name="dateDebut" value={formationValeurs.dateDebut} onChange={handleChangeFormation} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>

//                         </div>
//          </div> 

//          <span className={Styles.txtAlert}>{displayErrorInput(formErrorsFormation.dateDebut)}</span>

//          <div className={Styles.container1} id={Styles.container4}>
//                         <div className={Styles.zoneInputInput}>
//                               <span className={Styles.fileNameTxtStyleInput}>
//                               Date fin
//                               </span>
//                               <input placeholder="Select date" name="dateFin" value={formationValeurs.dateFin} onChange={handleChangeFormation} type="date" id={inputDateFin} className={Styles.goldenInput1}/>

//                         </div>
//          </div> 

//          <span className={Styles.txtAlert}>{displayErrorInput(formErrorsFormation.dateFin)}</span>

//          <div className={Styles.container1} id={Styles.container4}>
//                         <div className={Styles.zoneInputInput}>
//                               <span className={Styles.fileNameTxtStyleInput}>
//                               Description
//                               </span>
//                               <select name="description" aria-label="Default select example" value={formationValeurs.description} onChange={handleChangeFormation} id={inputDescription} className={Styles.goldenInput1}>
//                                      <option></option>
//                                      {
//                                                                         listDescriptionAdministration &&
//                                                                         listDescriptionAdministration.map(
//                                                                               resp => <option value={resp.id}>{resp.description}</option>
//                                                                         )
//                                                                    }
//                               </select>
//                         </div>
//          </div> 

//          <span className={Styles.txtAlert}>{displayErrorInput(formErrorsFormation.description)}</span>
// </div>
// </div>


//           </div>

//           <div className={Styles.secondsection}>

//           <div id={Styles.idTestTable}>
//         <table class="table table-light">
//             <thead >
//                 <tr id={Styles.idForStyleTh}>

//                 <th scope="col">Description</th>
//                 <th scope="col">Date début</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {
//                 description || dateDebut ? 
                    
//                     <tr>

//                     <td> {description} </td>
//                     <td> {moment(dateDebut).format("DD/MM/YYYY")}</td>
//                     </tr>
                    
//                     : <span></span>
//                 }
                
//             </tbody>
            
//             </table>
//         </div>


//           </div>
//           <div className={Styles.thirdsection}>
//                 <div className={Styles.divValider}>
//                         <button type="button" id={Styles.idBtnValider} onClick={btnFormation}>   Valider   </button>
//                 </div>
//           </div>
//     </div>

<div>
  <div className={Styles.web}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="formation" />
  } 
  section2={
      <SecondSectionTablePage
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
        <div id={Styles.divFormulaire}>

          <div className={Styles.lecentredepage} >

          <div className={Styles.container1} id={Styles.container4}>
                        <div className={Styles.zoneInputInput}>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Lieu formation
                              </span>
                              <input type="text" name="lieuFormation" value={formationValeurs.lieuFormation} onChange={handleChangeFormation} id={inputLieuFormation} placeholder=" " className={Styles.goldenInput1} />  
                        </div>
         </div> 
          {displayErrorInput(formErrorsFormation.lieuFormation) }
         <div className={Styles.container1} id={Styles.container4}>
                        <div className={Styles.zoneInputInput}>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Formateur
                              </span>
                              <input type="Nom" name="formateur" value={formationValeurs.formateur} onChange={handleChangeFormation} id={inputFormateur} placeholder="" className={Styles.goldenInput1}/>                        
                        </div>
         </div> 
          {displayErrorInput(formErrorsFormation.formateur)}
         <div className={Styles.container1} id={Styles.container4}>
                        <div className={Styles.zoneInputInput}>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Date début
                              </span>
                              <input placeholder="" name="dateDebut" value={formationValeurs.dateDebut} onChange={handleChangeFormation} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>

                        </div>
         </div> 
          {displayErrorInput(formErrorsFormation.dateDebut)}
         <div className={Styles.container1} id={Styles.container4}>
                        <div className={Styles.zoneInputInput}>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Date fin
                              </span>
                              <input placeholder="" name="dateFin" value={formationValeurs.dateFin} onChange={handleChangeFormation} type="date" id={inputDateFin} className={Styles.goldenInput1}/>

                        </div>
         </div> 
          {displayErrorInput(formErrorsFormation.dateFin)}
         <div className={Styles.container1} id={Styles.container4}>
                        <div className={Styles.zoneInputInput}>
                              <span className={Styles.fileNameTxtStyleInput}>
                              Description
                              </span>
                              <select name="description" aria-label="Default select example" value={formationValeurs.description} onChange={handleChangeFormation} id={inputDescription} className={Styles.goldenInput1}>
                                     <option></option>
                                     {
                                                                        listDescriptionAdministration &&
                                                                        listDescriptionAdministration.map(
                                                                              resp => <option value={resp.id}>{resp.description}</option>
                                                                        )
                                                                   }
                              </select>
                        </div>
         </div> 
          {displayErrorInput(formErrorsFormation.description)}
</div>
</div>


  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnFormation}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={8}
  prctgeSection2={6}
  prctgeSection3={80} 
  prctgeSection4={6}
  />
  </div>
  <div className={Styles.tablet}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="formation" />
  } 
  section2={
      <SecondSectionTablePageTablet
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
    <div id={Styles.divFormulaire}>

    <div className={Styles.lecentredepage} >

    <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Lieu formation
                        </span>
                        <input type="text" name="lieuFormation" value={formationValeurs.lieuFormation} onChange={handleChangeFormation} id={inputLieuFormation} placeholder=" " className={Styles.goldenInput1} />  
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.lieuFormation) }
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Formateur
                        </span>
                        <input type="Nom" name="formateur" value={formationValeurs.formateur} onChange={handleChangeFormation} id={inputFormateur} placeholder="" className={Styles.goldenInput1}/>                        
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.formateur)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date début
                        </span>
                        <input placeholder="" name="dateDebut" value={formationValeurs.dateDebut} onChange={handleChangeFormation} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateDebut)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date fin
                        </span>
                        <input placeholder="" name="dateFin" value={formationValeurs.dateFin} onChange={handleChangeFormation} type="date" id={inputDateFin} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateFin)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Description
                        </span>
                        <select name="description" aria-label="Default select example" value={formationValeurs.description} onChange={handleChangeFormation} id={inputDescription} className={Styles.goldenInput1}>
                               <option></option>
                               {
                                                                  listDescriptionAdministration &&
                                                                  listDescriptionAdministration.map(
                                                                        resp => <option value={resp.id}>{resp.description}</option>
                                                                  )
                                                             }
                        </select>
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.description)}
</div>
</div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnFormation}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={8}
  prctgeSection2={6} 
  prctgeSection3={80} 
  prctgeSection4={6}
  />
  </div>
  <div className={Styles.mobile}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="formation" />
  } 
  section2={
      <SecondSectionTablePageMobile
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
    <div id={Styles.divFormulaire}>

    <div className={Styles.lecentredepage} >

    <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Lieu formation
                        </span>
                        <input type="text" name="lieuFormation" value={formationValeurs.lieuFormation} onChange={handleChangeFormation} id={inputLieuFormation} placeholder=" " className={Styles.goldenInput1} />  
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.lieuFormation) }
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Formateur
                        </span>
                        <input type="Nom" name="formateur" value={formationValeurs.formateur} onChange={handleChangeFormation} id={inputFormateur} placeholder="" className={Styles.goldenInput1}/>                        
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.formateur)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date début
                        </span>
                        <input placeholder="" name="dateDebut" value={formationValeurs.dateDebut} onChange={handleChangeFormation} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateDebut)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date fin
                        </span>
                        <input placeholder="" name="dateFin" value={formationValeurs.dateFin} onChange={handleChangeFormation} type="date" id={inputDateFin} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateFin)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Description
                        </span>
                        <select name="description" aria-label="Default select example" value={formationValeurs.description} onChange={handleChangeFormation} id={inputDescription} className={Styles.goldenInput1}>
                               <option></option>
                               {
                                                                  listDescriptionAdministration &&
                                                                  listDescriptionAdministration.map(
                                                                        resp => <option value={resp.id}>{resp.description}</option>
                                                                  )
                                                             }
                        </select>
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.description)}
</div>
</div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnFormation}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={7}
  prctgeSection2={6} 
  prctgeSection3={81} 
  prctgeSection4={6}
  />
  </div>
  <div className={Styles.mobileInversePetit}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="formation" />
  } 
  section2={
      <SecondSectionTablePageMobileInversePetit
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
    <div id={Styles.divFormulaire}>

    <div className={Styles.lecentredepage} >

    <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Lieu formation
                        </span>
                        <input type="text" name="lieuFormation" value={formationValeurs.lieuFormation} onChange={handleChangeFormation} id={inputLieuFormation} placeholder=" " className={Styles.goldenInput1} />  
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.lieuFormation) }
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Formateur
                        </span>
                        <input type="Nom" name="formateur" value={formationValeurs.formateur} onChange={handleChangeFormation} id={inputFormateur} placeholder="" className={Styles.goldenInput1}/>                        
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.formateur)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date début
                        </span>
                        <input placeholder="" name="dateDebut" value={formationValeurs.dateDebut} onChange={handleChangeFormation} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateDebut)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date fin
                        </span>
                        <input placeholder="" name="dateFin" value={formationValeurs.dateFin} onChange={handleChangeFormation} type="date" id={inputDateFin} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateFin)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Description
                        </span>
                        <select name="description" aria-label="Default select example" value={formationValeurs.description} onChange={handleChangeFormation} id={inputDescription} className={Styles.goldenInput1}>
                               <option></option>
                               {
                                                                  listDescriptionAdministration &&
                                                                  listDescriptionAdministration.map(
                                                                        resp => <option value={resp.id}>{resp.description}</option>
                                                                  )
                                                             }
                        </select>
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.description)}
</div>
</div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnFormation}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={10}
  prctgeSection2={9} 
  prctgeSection3={72} 
  prctgeSection4={9}
  />
  </div>
  <div className={Styles.mobileInverseGrand}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="formation" />
  } 
  section2={
      <SecondSectionTablePageMobileInverseGrand
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
    <div id={Styles.divFormulaire}>

    <div className={Styles.lecentredepage} >

    <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Lieu formation
                        </span>
                        <input type="text" name="lieuFormation" value={formationValeurs.lieuFormation} onChange={handleChangeFormation} id={inputLieuFormation} placeholder=" " className={Styles.goldenInput1} />  
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.lieuFormation) }
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Formateur
                        </span>
                        <input type="Nom" name="formateur" value={formationValeurs.formateur} onChange={handleChangeFormation} id={inputFormateur} placeholder="" className={Styles.goldenInput1}/>                        
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.formateur)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date début
                        </span>
                        <input placeholder="" name="dateDebut" value={formationValeurs.dateDebut} onChange={handleChangeFormation} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateDebut)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Date fin
                        </span>
                        <input placeholder="" name="dateFin" value={formationValeurs.dateFin} onChange={handleChangeFormation} type="date" id={inputDateFin} className={Styles.goldenInput1}/>

                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.dateFin)}
   <div className={Styles.container1} id={Styles.container4}>
                  <div className={Styles.zoneInputInput}>
                        <span className={Styles.fileNameTxtStyleInput}>
                        Description
                        </span>
                        <select name="description" aria-label="Default select example" value={formationValeurs.description} onChange={handleChangeFormation} id={inputDescription} className={Styles.goldenInput1}>
                               <option></option>
                               {
                                                                  listDescriptionAdministration &&
                                                                  listDescriptionAdministration.map(
                                                                        resp => <option value={resp.id}>{resp.description}</option>
                                                                  )
                                                             }
                        </select>
                  </div>
   </div> 
    {displayErrorInput(formErrorsFormation.description)}
</div>
</div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnFormation}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={10}
  prctgeSection2={9}
  prctgeSection3={72} 
  prctgeSection4={9}
  />
  </div> 
</div>
  )
}

export default FormationSection