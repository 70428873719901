import { useState } from 'react';
import {useCookies} from 'react-cookie';
import axios from "axios";

export default class APIService {
  // Django Server 
//   static address = 'http://178.18.247.44:8000'; 
    static address = 'https://api.solwayhr.com'; 

  // Django Local 
    // static address = 'http://127.0.0.1:8000';

    static ModifierArticle(article_id, body, token){
        return fetch(`${this.address}/embauche/articles/${article_id}/`, {
            'method':'PUT',
            headers:{
              'Content-type':'application/json',
              'Authorization':`Token ${token}`
            },
            body:JSON.stringify(body)
          }).then(resp => resp.json())
    }


    static SupprimerEmploye(id_employe, token){
      return fetch(`${this.address}/embauche/employes/${id_employe}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }
    static ModifierEmploye(employe_id, body, token){
      return fetch(`${this.address}/embauche/employes/${employe_id}/`, {
        'method': 'PUT',
        headers:{
          'Content-type':'multipart/form-data',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }

    static getAvenants(token){
      return fetch(`${this.address}/embauche/avenants/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      }).then(resp => resp.json())
    }
    static SupprimerAvenants(id_employe, token){
      return fetch(`${this.address}/embauche/avenants/${id_employe}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }
    static getFormations(token){
      return fetch(`${this.address}/embauche/formations/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      }).then(resp => resp.json())
    }
    static SupprimerFormations(id_employe, token){
      return fetch(`${this.address}/embauche/formations/${id_employe}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }

    static getRoleEmploye(token){
      return APIService.getEmployes(token).then(resp => resp.filter(ftr => ftr.matricule_employe === token['matriculeActuel'] && ftr.state_employe === true));
    }

    static getEmployes(token){
      return fetch(`${this.address}/embauche/employes/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      }).then(resp => resp.json())
    }

    static getEmployesByToken(token){
      return fetch(`${this.address}/embauche/employes/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token}`
        }
      }).then(resp => resp.json())
    }

    static getEmployesTemporaires(token){
      return fetch(`${this.address}/embauche/employesTemporaires/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      }).then(resp => resp.json())
    }



    static InsertArticle(body, token){
        return fetch(`${this.address}/embauche/articles/`, {
            'method':'POST',
            headers:{
                'Content-type':'application/json',
                'Authorization':`Token ${token['mytoken']}`
              },
              body:JSON.stringify(body)

        }).then(resp => resp.json())
    }
    // static AjouterEmploye2(body, token){
      
    //   return axios({
    //     method: "POST",
    //     url: "http://127.0.0.1:8000/embauche/employes/",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Token ${token['mytoken']}`
    //     },
    //     body: body
    //   })
    //     .then((res) => console.log(res))
    //     .catch((err) => console.log(err));
    // }

    static getFichieTemporaire(matricule_fichier, token){
      return axios({
        method: "GET",
        url: `${this.address}/embauche/fichiersTemporaires/${matricule_fichier}/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token['mytoken']}`
        }
      })

    }

    static getFichier(id_fichier, token){
      return axios({
        method: "GET",
        url: `${this.address}/embauche/fichiers/${id_fichier}/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token['mytoken']}`
        }
      })
        
    }
    static getFichiers(token){
      return fetch(`${this.address}/embauche/fichiers/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
        }).then(resp => resp.json())
      
    }

    static getFichiersByToken(token){
      return fetch(`${this.address}/embauche/fichiers/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
        }).then(resp => resp.json())
      
    }
    static InsererFichier(data, token){
      return axios({
        method: "POST",
        url: `${this.address}/embauche/fichiers/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token['mytoken']}`
        },
        data
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    static InsererFichierTemporaire(data, token){
      return axios({
        method: "POST",
        url: `${this.address}/embauche/fichiersTemporaires/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token['mytoken']}`
        },
        data
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    static InsererNotification(body, token){
      return fetch(`${this.address}/embauche/notifications/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }


    static InsererDeclarationActivite(body, token){
      return fetch(`${this.address}/embauche/declarationActivite/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }

    static ModifierDeclarationActivite(activite_id, body, token){
      return fetch(`${this.address}/embauche/declarationActivite/${activite_id}/`, {
          'method':'PUT',
          headers:{
            'Content-type':'application/json',
            'Authorization':`Token ${token['mytoken']}`
          },
          body:JSON.stringify(body)
        }).then(resp => resp.json())
  }

  //   static ModifierDemandeAbsence(activite_id, body, token){
  //     return fetch(`http://127.0.0.1:8000/embauche/demandeAbsence/${activite_id}/`, {
  //         'method':'PUT',
  //         headers:{
  //           'Content-type':'multipart/form-data',
  //           'Authorization':`Token ${token['mytoken']}`
  //         },
  //         body
  //       }).then(resp => resp.json())
  // }


      static ModifierDemandeAbsence(absence_id, body, token){
        return fetch(`${this.address}/embauche/demandeAbsence/${absence_id}/`, {
            'method':'PUT',
            headers:{
              'Content-type':'application/json',
              'Authorization':`Token ${token['mytoken']}`
            },
            body:JSON.stringify(body)
          }).then(resp => resp.json())
    }

    static getDemandeAbsenceFichiers(token){
      return fetch(`${this.address}/embauche/demandeAbsenceFichiers/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
        }).then(resp => resp.json())
      
    }

    static getDemandeAbsenceById(token){
      return fetch(`${this.address}/embauche/demandeAbsence/${token['idAbsence']}/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
        }).then(resp => resp.json())
      
    }

    



    static InsererDemandeAbsence(body, token){
      return fetch(`${this.address}/embauche/demandeAbsence/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }

    static InsererDemandeAbsenceFichiers(data, token){
      return axios({
        method: "POST",
        url: `${this.address}/embauche/demandeAbsenceFichiers/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Token ${token['mytoken']}`
        },
        data
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }


    static ModifierDemandeAbsenceFichiers(idFichier, data, token){
      return axios({
        method: "PUT",
        url: `${this.address}/embauche/demandeAbsenceFichiers/${idFichier}/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Token ${token['mytoken']}`
        },
        data
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    static getDeclarationActivite(token){
      return fetch(`${this.address}/embauche/declarationActivite/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
        }).then(resp => resp.json())
      
    }
    static SupprimerDeclarationActivite(id_employe, token){
      return fetch(`${this.address}/embauche/declarationActivite/${id_employe}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }

    static getDemandeAbsence(token){
      return fetch(`${this.address}/embauche/demandeAbsence/`, {
        'method':'GET',
        headers:{
          'Content-type':'multipart/form-data',
          'Authorization':`Token ${token['mytoken']}`
        }
        }).then(resp => resp.json())
      
    }
    static SupprimerDemandeActivite(id_employe, token){
      return fetch(`${this.address}/embauche/demandeAbsence/${id_employe}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }

    static AjouterAvenant(body, token){
      return fetch(`${this.address}/embauche/avenants/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }

    static AjouterFormation(body, token){
      return fetch(`${this.address}/embauche/formations/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }
    
    static AjouterEmploye(body, token){
      return fetch(`${this.address}/embauche/employes/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }

    static postEmploye(body, token){
      return fetch(`${this.address}/embauche/employes/`, {
        'method': 'POST',
        headers:{
          'Authorization':`Token ${token['mytoken']}`
        },
        body:body
      }).then(resp => resp.json())
    }

    static putEmploye(id_emp, body, token){
      return fetch(`${this.address}/embauche/employes/${id_emp}/`, {
        'method': 'PUT',
        headers:{
          'Authorization':`Token ${token['mytoken']}`
        },
        body:body
      }).then(resp => resp.json())
    }


   

    static AjouterEmployeTemporaire(body, token){
      return fetch(`${this.address}/embauche/employesTemporaires/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }

    static SupprimerFichierTemporaire(matricule_employe, token){
      return fetch(`${this.address}/embauche/fichiersTemporaires/${matricule_employe}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }

    static SupprimerEmployeTemporaire(matricule_employe, token){
      return fetch(`${this.address}/embauche/employesTemporaires/${matricule_employe}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }

    static SupprimerNotifications(matricule_employe, token){
      return APIService.getAllNotifications(token)
      .then(resp => resp.filter(nots => nots.matricule_employe === matricule_employe).map( notif =>
        // console.log(notif.notification)
        fetch(`${this.address}/embauche/notifications/${notif.id}/`, {
          'method': 'DELETE',
          headers:{
            'Content-type':'application/json',
            'Authorization':`Token ${token['mytoken']}`
          }
        })
      ))
      
    }

    static SupprimerArticle(article_id, token){
        return fetch(`${this.address}/embauche/articles/${article_id}/`, {
            'method':'DELETE',
            headers:{
              'Content-type':'application/json',
              'Authorization':`Token ${token}`
            }
          })
    }
    // static tokenExist(){

    // }
    static LoginUser(body){
        return fetch(`${this.address}/auth/`, {
            'method':'POST',
            headers:{
                'Content-type':'application/json',
              },
              body:JSON.stringify(body)

        }).then(resp => resp.json())
    }

    static getUsers(token){
      return fetch(`${this.address}/embauche/users/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token}`
        }
        }).then(resp => resp.json())
    }
    static putUsers(id_utilisateur, formdata){
      return fetch(`${this.address}/embauche/users/${id_utilisateur}/`, 
    
      {
        method: 'PUT',
        body: formdata,
        redirect: 'follow'
      }
      
      
      ).then(resp => resp.json())
        
    }  

    static ModifierNotification(id_notification, body, token){
      return fetch(`${this.address}/embauche/notifications/${id_notification}/`, {
        'method': 'PUT',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      })
    }

    static getAllNotifications(token){
      return fetch(`${this.address}/embauche/notifications/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
        }).then(resp => resp.json())
    }

    // static getNotifications(allNotifications, token){
    //   const notifications = allNotifications.filter(nots => nots.username === token['username'])
    //   return notifications;
    // }
    
    static getEmployeId(users, token){
      
      const id_employe = users.filter(user => user.username === token['username']).map(user => user.id_employe)
      return id_employe;
      

    }

    static getMotif(id_motif, token){
      return fetch(`${this.address}/embauche/motifs/${id_motif}/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
      }).then(resp => resp.json())

    }

    static getMotifs(token){
      return fetch(`${this.address}/embauche/motifs/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
      }).then(resp => resp.json())

    }

    

    static getEmploye(id_employe, token){
      return fetch(`${this.address}/embauche/employes/${id_employe}/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
      }).then(resp => resp.json())

    }

    static getEmployeTemporaire(matricule_employe, token){
      return fetch(`${this.address}/embauche/employesTemporaires/${matricule_employe}/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
      }).then(resp => resp.json())

    }

    // getEmployesTemporairesRechercher

    static getEmployesTemporairesRechercher(employes, nom, prenom, numeroSS){
      if(numeroSS && nom && prenom){
        return employes.filter(emp => emp.numeroSS_employe === numeroSS && emp.nom_employe.toLowerCase()  === nom.toLowerCase() && emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
      }
      else {
        if(numeroSS && nom && !prenom){
          return employes.filter(emp => emp.numeroSS_employe === numeroSS && emp.nom_employe.toLowerCase()  === nom.toLowerCase())
        }else{
          if(numeroSS && !nom && prenom){
            return employes.filter(emp => emp.numeroSS_employe === numeroSS && emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
          }else {
            if(!numeroSS && nom && prenom){
              return employes.filter(emp => emp.nom_employe.toLowerCase()  === nom.toLowerCase() && emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
            }else {
              if(numeroSS && !nom && !prenom){
                return employes.filter(emp => emp.numeroSS_employe === numeroSS)
              }else{
                if(!numeroSS && nom && !prenom){
                  return employes.filter(emp => emp.nom_employe.toLowerCase()  === nom.toLowerCase())
                }else{
                  if(!numeroSS && !nom && prenom){
                    return employes.filter(emp => emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
                  }
                }
              }

            }

          }

        }
      }

    }

    
    static getEmployeRechercher(employes, nom, prenom, numeroSS){
      if(numeroSS && nom && prenom){
        return employes.filter(emp => emp.numeroSS_employe === numeroSS && emp.nom_employe.toLowerCase()  === nom.toLowerCase() && emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
      }
      else {
        if(numeroSS && nom && !prenom){
          return employes.filter(emp => emp.numeroSS_employe === numeroSS && emp.nom_employe.toLowerCase()  === nom.toLowerCase())
        }else{
          if(numeroSS && !nom && prenom){
            return employes.filter(emp => emp.numeroSS_employe === numeroSS && emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
          }else {
            if(!numeroSS && nom && prenom){
              return employes.filter(emp => emp.nom_employe.toLowerCase()  === nom.toLowerCase() && emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
            }else {
              if(numeroSS && !nom && !prenom){
                return employes.filter(emp => emp.numeroSS_employe === numeroSS)
              }else{
                if(!numeroSS && nom && !prenom){
                  return employes.filter(emp => emp.nom_employe.toLowerCase()  === nom.toLowerCase())
                }else{
                  if(!numeroSS && !nom && prenom){
                    return employes.filter(emp => emp.prenom_employe.toLowerCase() === prenom.toLowerCase())
                  }
                }
              }

            }

          }

        }
      }

    }




    // Paie 


    // Rémuneration


    static getRemunerationPaie(matricule_employe, token){
      return fetch(`${this.address}/embauche/remunerationPaie/`, {
        'method':'GET',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
      }).then(resp => resp.json())
      .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
     

    }


    static postRemunerationPaie(body, token){
      return fetch(`${this.address}/embauche/remunerationPaie/`, {
        'method': 'POST',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
    }

    static deleteRemunerationPaie(id_remuneration, token){
      return fetch(`${this.address}/embauche/remunerationPaie/${id_remuneration}/`, {
        'method': 'DELETE',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        }
      })
    }


    static putRemunerationPaie(id_remuneration, body, token){
      return fetch(`${this.address}/embauche/remunerationPaie/${id_remuneration}/`, {
          'method':'PUT',
          headers:{
            'Content-type':'application/json',
            'Authorization':`Token ${token['mytoken']}`
          },
          body:JSON.stringify(body)
        }).then(resp => resp.json())
  }



  // Cotisation

  static getCotisationPaie(matricule_employe, token){
    return fetch(`${this.address}/embauche/cotisationPaie/`, {
      'method':'GET',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
    }).then(resp => resp.json())
    .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
   
  }

  static postCotisationPaie(body, token){
    return fetch(`${this.address}/embauche/cotisationPaie/`, {
      'method': 'POST',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
  }


  static deleteCotisationPaie(id_cotisation, token){
    return fetch(`${this.address}/embauche/cotisationPaie/${id_cotisation}/`, {
      'method': 'DELETE',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      }
    })
  }

  static putCotisationPaie(id_cotisation, body, token){
    return fetch(`${this.address}/embauche/cotisationPaie/${id_cotisation}/`, {
        'method':'PUT',
        headers:{
          'Content-type':'application/json',
          'Authorization':`Token ${token['mytoken']}`
        },
        body:JSON.stringify(body)
      }).then(resp => resp.json())
}



//  Absence 

static getAbsencePaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/absencePaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postAbsencePaie(body, token){
  return fetch(`${this.address}/embauche/absencePaie/`, {
    'method': 'POST',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
    body:JSON.stringify(body)
  }).then(resp => resp.json())
}


static deleteAbsencePaie(id_absence, token){
  return fetch(`${this.address}/embauche/absencePaie/${id_absence}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putAbsencePaie(id_absence, body, token){
  return fetch(`${this.address}/embauche/absencePaie/${id_absence}/`, {
      'method':'PUT',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
}


//  Repas 

static getRepasPaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/repasPaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postRepasPaie(body, token){
  return fetch(`${this.address}/embauche/repasPaie/`, {
    'method': 'POST',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
    body:JSON.stringify(body)
  }).then(resp => resp.json())
}


static deleteRepasPaie(id_repas, token){
  return fetch(`${this.address}/embauche/repasPaie/${id_repas}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putRepasPaie(id_repas, body, token){
  return fetch(`${this.address}/embauche/repasPaie/${id_repas}/`, {
      'method':'PUT',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
}


//  Acompte sur salaire 

static getAcompteSalairePaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/acompteSalairePaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postAcompteSalairePaie(body, token){
  return fetch(`${this.address}/embauche/acompteSalairePaie/`, {
    'method': 'POST',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
    body:JSON.stringify(body)
  }).then(resp => resp.json())
}


static deleteAcompteSalairePaie(id_acompteSalaire, token){
  return fetch(`${this.address}/embauche/acompteSalairePaie/${id_acompteSalaire}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putAcompteSalairePaie(id_acompteSalaire, body, token){
  return fetch(`${this.address}/embauche/acompteSalairePaie/${id_acompteSalaire}/`, {
      'method':'PUT',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
}


//  Primes 

static getPrimesPaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/primesPaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postPrimesPaie(body, token){
  return fetch(`${this.address}/embauche/primesPaie/`, {
    'method': 'POST',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
    body:JSON.stringify(body)
  }).then(resp => resp.json())
}


static deletePrimesPaie(id_primes, token){
  return fetch(`${this.address}/embauche/primesPaie/${id_primes}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putPrimesPaie(id_primes, body, token){
  return fetch(`${this.address}/embauche/primesPaie/${id_primes}/`, {
      'method':'PUT',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
}


//  Note de frais 

static getNoteFraisPaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/noteFraisPaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'multipart/form-data',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postNoteFraisPaie(body, token){
  return fetch(`${this.address}/embauche/noteFraisPaie/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteNoteFraisPaie(id_noteFrais, token){
  return fetch(`${this.address}/embauche/noteFraisPaie/${id_noteFrais}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'multipart/form-data',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putNoteFraisPaie(id_noteFrais, body, token){
  return fetch(`${this.address}/embauche/noteFraisPaie/${id_noteFrais}/`, {
      'method':'PUT',
      headers:{
        'Content-type':'multipart/form-data',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
}



//  Frais professionnels 

static getFraisProfessionnelsPaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/fraisProfessionnelsPaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'multipart/form-data',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postFraisProfessionnelsPaie(body, token){
  return fetch(`${this.address}/embauche/fraisProfessionnelsPaie/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteFraisProfessionnelsPaie(id_fraisProfessionnels, token){
  return fetch(`${this.address}/embauche/fraisProfessionnelsPaie/${id_fraisProfessionnels}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'multipart/form-data',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putFraisProfessionnelsPaie(id_fraisProfessionnels, body, token){
  return fetch(`${this.address}/embauche/fraisProfessionnelsPaie/${id_fraisProfessionnels}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}



//  Frais de transport 

static getFraisTransportPaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/fraisTransportPaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postFraisTransportPaie(body, token){
  return fetch(`${this.address}/embauche/fraisTransportPaie/`, {
    'method': 'POST',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
    body:JSON.stringify(body)
  }).then(resp => resp.json())
}


static deleteFraisTransportPaie(id_fraisTransport, token){
  return fetch(`${this.address}/embauche/fraisTransportPaie/${id_fraisTransport}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putFraisTransportPaie(id_fraisTransport, body, token){
  return fetch(`${this.address}/embauche/fraisTransportPaie/${id_fraisTransport}/`, {
      'method':'PUT',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
}    



//  Heures supplémentaires 

static getHeuresSupplementairesPaie(matricule_employe, token){
  return fetch(`${this.address}/embauche/heuresSupplementairesPaie/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
  .then(resp => resp.filter(rem => rem.matricule_employe === matricule_employe))
 
}

static postHeuresSupplementairesPaie(body, token){
  return fetch(`${this.address}/embauche/heuresSupplementairesPaie/`, {
    'method': 'POST',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
    body:JSON.stringify(body)
  }).then(resp => resp.json())
}


static deleteHeuresSupplementairesPaie(id_heuresSupplementaires, token){
  return fetch(`${this.address}/embauche/heuresSupplementairesPaie/${id_heuresSupplementaires}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}

static putHeuresSupplementairesPaie(id_heuresSupplementaires, body, token){
  return fetch(`${this.address}/embauche/heuresSupplementairesPaie/${id_heuresSupplementaires}/`, {
      'method':'PUT',
      headers:{
        'Content-type':'application/json',
        'Authorization':`Token ${token['mytoken']}`
      },
      body:JSON.stringify(body)
    }).then(resp => resp.json())
}    



// Administration 

static getSexeAdministration(token){
  return fetch(`${this.address}/embauche/sexeAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postSexeAdministration(body, token){
  return fetch(`${this.address}/embauche/sexeAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteSexeAdministration(id_sexe, token){
  return fetch(`${this.address}/embauche/sexeAdministration/${id_sexe}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putSexeAdministration(id_sexe, body, token){
  return fetch(`${this.address}/embauche/sexeAdministration/${id_sexe}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

// langue administration :

static getLangueAdministration(token){
  return fetch(`${this.address}/embauche/langueAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postLangueAdministration(body, token){
  return fetch(`${this.address}/embauche/langueAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteLangueAdministration(id_langue, token){
  return fetch(`${this.address}/embauche/langueAdministration/${id_langue}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}



static putLangueAdministration(id_langue, body, token){
  return fetch(`${this.address}/embauche/langueAdministration/${id_langue}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}



// situation familiale administration

static getSituationFamilialeAdministration(token){
  return fetch(`${this.address}/embauche/situationFamilialeAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postSituationFamilialeAdministration(body, token){
  return fetch(`${this.address}/embauche/situationFamilialeAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteSituationFamilialeAdministration(id_situationfam, token){
  return fetch(`${this.address}/embauche/situationFamilialeAdministration/${id_situationfam}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putSituationFamilialeAdministration(id_situationfam, body, token){
  return fetch(`${this.address}/embauche/situationFamilialeAdministration/${id_situationfam}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getJours(token){
  return fetch(`${this.address}/embauche/jour/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postJours(body, token){
  return fetch(`${this.address}/embauche/jour/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteJours(id_jours, token){
  return fetch(`${this.address}/embauche/jour/${id_jours}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putJours(id_jours, body, token){
  return fetch(`${this.address}/embauche/jour/${id_jours}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getTypeContrat(token){
  return fetch(`${this.address}/embauche/typeContrat/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postTypeContrat(body, token){
  return fetch(`${this.address}/embauche/typeContrat/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteTypeContrat(id_typeContrat, token){
  return fetch(`${this.address}/embauche/typeContrat/${id_typeContrat}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putTypeContrat(id_typeContrat, body, token){
  return fetch(`${this.address}/embauche/typeContrat/${id_typeContrat}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getTemps(token){
  return fetch(`${this.address}/embauche/temps/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}
static postTemps(body, token){
  return fetch(`${this.address}/embauche/temps/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteTemps(id_temps, token){
  return fetch(`${this.address}/embauche/temps/${id_temps}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putTemps(id_temps, body, token){
  return fetch(`${this.address}/embauche/temps/${id_temps}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


static getBanque(token){
  return fetch(`${this.address}/embauche/banque/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postBanque(body, token){
  return fetch(`${this.address}/embauche/banque/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteBanque(id_banque, token){
  return fetch(`${this.address}/embauche/banque/${id_banque}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putBanque(id_banque, body, token){
  return fetch(`${this.address}/embauche/banque/${id_banque}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


static getEtablissement(token){
  return fetch(`${this.address}/embauche/etablissement/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postEtablissement(body, token){
  return fetch(`${this.address}/embauche/etablissement/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deleteEtablissement(id_etablissement, token){
  return fetch(`${this.address}/embauche/etablissement/${id_etablissement}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putEtablissement(id_etablissement, body, token){
  return fetch(`${this.address}/embauche/etablissement/${id_etablissement}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}



static getDepartement(token){
  return fetch(`${this.address}/embauche/departement/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postDepartement(body, token){
  return fetch(`${this.address}/embauche/departement/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteDepartement(id_departement, token){
  return fetch(`${this.address}/embauche/departement/${id_departement}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putDepartement(id_departement, body, token){
  return fetch(`${this.address}/embauche/departement/${id_departement}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


static getPoste(token){
  return fetch(`${this.address}/embauche/poste/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postPoste(body, token){
  return fetch(`${this.address}/embauche/poste/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}

static deletePoste(id_poste, token){
  return fetch(`${this.address}/embauche/poste/${id_poste}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putPoste(id_poste, body, token){
  return fetch(`${this.address}/embauche/poste/${id_poste}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}
    

static getNationalite(token){
  return fetch(`${this.address}/embauche/nationalite/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postNationalite(body, token){
  return fetch(`${this.address}/embauche/nationalite/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteNationalite(id_nationalite, token){
  return fetch(`${this.address}/embauche/nationalite/${id_nationalite}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putNationalite(id_nationalite, body, token){
  return fetch(`${this.address}/embauche/nationalite/${id_nationalite}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getPays(token){
  return fetch(`${this.address}/embauche/pays/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postPays(body, token){
  return fetch(`${this.address}/embauche/pays/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deletePays(id_pays, token){
  return fetch(`${this.address}/embauche/pays/${id_pays}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putPays(id_pays, body, token){
  return fetch(`${this.address}/embauche/pays/${id_pays}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getVille(token){
  return fetch(`${this.address}/embauche/ville/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postVille(body, token){
  return fetch(`${this.address}/embauche/ville/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteVille(id_ville, token){
  return fetch(`${this.address}/embauche/ville/${id_ville}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putVille(id_ville, body, token){
  return fetch(`${this.address}/embauche/ville/${id_ville}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getDepartementville(token){
  return fetch(`${this.address}/embauche/departementVille/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postDepartementville(body, token){
  return fetch(`${this.address}/embauche/departementVille/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteDepartementville(id_departementville, token){
  return fetch(`${this.address}/embauche/departementVille/${id_departementville}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putDepartementville(id_departementville, body, token){
  return fetch(`${this.address}/embauche/departementVille/${id_departementville}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


static getCodePostale(token){
  return fetch(`${this.address}/embauche/codePostale/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postCodePostale(body, token){
  return fetch(`${this.address}/embauche/codePostale/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteCodePostale(id_codePostale, token){
  return fetch(`${this.address}/embauche/codePostale/${id_codePostale}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putCodePostale(id_codePostale, body, token){
  return fetch(`${this.address}/embauche/codePostale/${id_codePostale}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getStatutAdministration(token){
  return fetch(`${this.address}/embauche/statutAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postStatutAdministration(body, token){
  return fetch(`${this.address}/embauche/statutAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteStatutAdministration(id_statutAdministration, token){
  return fetch(`${this.address}/embauche/statutAdministration/${id_statutAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putStatutAdministration(id_statutAdministration, body, token){
  return fetch(`${this.address}/embauche/statutAdministration/${id_statutAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


static getNombreLignesAdministration(token){
  return fetch(`${this.address}/embauche/nombreLignesAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postNombreLignesAdministration(body, token){
  return fetch(`${this.address}/embauche/nombreLignesAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteNombreLignesAdministration(id_nombreLignesAdministration, token){
  return fetch(`${this.address}/embauche/nombreLignesAdministration/${id_nombreLignesAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putNombreLignesAdministration(id_nombreLignesAdministration, body, token){
  return fetch(`${this.address}/embauche/nombreLignesAdministration/${id_nombreLignesAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getActionsAdministration(token){
  return fetch(`${this.address}/embauche/actionsAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postActionsAdministration(body, token){
  return fetch(`${this.address}/embauche/actionsAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteActionsAdministration(id_actionsAdministration, token){
  return fetch(`${this.address}/embauche/actionsAdministration/${id_actionsAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putActionsAdministration(id_actionsAdministration, body, token){
  return fetch(`${this.address}/embauche/actionsAdministration/${id_actionsAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


static getDescriptionAdministration(token){
  return fetch(`${this.address}/embauche/descriptionAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postDescriptionAdministration(body, token){
  return fetch(`${this.address}/embauche/descriptionAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteDescriptionAdministration(id_descriptionAdministration, token){
  return fetch(`${this.address}/embauche/descriptionAdministration/${id_descriptionAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putDescriptionAdministration(id_descriptionAdministration, body, token){
  return fetch(`${this.address}/embauche/descriptionAdministration/${id_descriptionAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getNiveauLanguesAdministration(token){
  return fetch(`${this.address}/embauche/niveauLanguesAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postNiveauLanguesAdministration(body, token){
  return fetch(`${this.address}/embauche/niveauLanguesAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteNiveauLanguesAdministration(id_niveauLanguesAdministration, token){
  return fetch(`${this.address}/embauche/niveauLanguesAdministration/${id_niveauLanguesAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putNiveauLanguesAdministration(id_niveauLanguesAdministration, body, token){
  return fetch(`${this.address}/embauche/niveauLanguesAdministration/${id_niveauLanguesAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getStatutActiviteAdministration(token){
  return fetch(`${this.address}/embauche/statutActiviteAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postStatutActiviteAdministration(body, token){
  return fetch(`${this.address}/embauche/statutActiviteAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteStatutActiviteAdministration(id_statutActiviteAdministration, token){
  return fetch(`${this.address}/embauche/statutActiviteAdministration/${id_statutActiviteAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putStatutActiviteAdministration(id_statutActiviteAdministration, body, token){
  return fetch(`${this.address}/embauche/statutActiviteAdministration/${id_statutActiviteAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


static getActiontacheAdministration(token){
  return fetch(`${this.address}/embauche/actiontacheAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postActiontacheAdministration(body, token){
  return fetch(`${this.address}/embauche/actiontacheAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteActiontacheAdministration(id_actiontacheAdministration, token){
  return fetch(`${this.address}/embauche/actiontacheAdministration/${id_actiontacheAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putActiontacheAdministration(id_actiontacheAdministration, body, token){
  return fetch(`${this.address}/embauche/actiontacheAdministration/${id_actiontacheAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

static getDemandeAbsenceAdministration(token){
  return fetch(`${this.address}/embauche/demandeAbsenceAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postDemandeAbsenceAdministration(body, token){
  return fetch(`${this.address}/embauche/demandeAbsenceAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteDemandeAbsenceAdministration(id_demandeAbsenceAdministration, token){
  return fetch(`${this.address}/embauche/demandeAbsenceAdministration/${id_demandeAbsenceAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putDemandeAbsenceAdministration(id_demandeAbsenceAdministration, body, token){
  return fetch(`${this.address}/embauche/demandeAbsenceAdministration/${id_demandeAbsenceAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}


// Unite 

static getUniteAdministration(token){
  return fetch(`${this.address}/embauche/uniteAdministration/`, {
    'method':'GET',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    },
  }).then(resp => resp.json())
}

static postUniteAdministration(body, token){
  return fetch(`${this.address}/embauche/uniteAdministration/`, {
    'method': 'POST',
    headers:{
      'Authorization':`Token ${token['mytoken']}`
    },
    body
  }).then(resp => resp.json())
}


static deleteUniteAdministration(id_uniteAdministration, token){
  return fetch(`${this.address}/embauche/uniteAdministration/${id_uniteAdministration}/`, {
    'method': 'DELETE',
    headers:{
      'Content-type':'application/json',
      'Authorization':`Token ${token['mytoken']}`
    }
  })
}
static putUniteAdministration(id_uniteAdministration, body, token){
  return fetch(`${this.address}/embauche/uniteAdministration/${id_uniteAdministration}/`, {
      'method':'PUT',
      headers:{
        'Authorization':`Token ${token['mytoken']}`
      },
      body:body
    }).then(resp => resp.json())
}

}

    