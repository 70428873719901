import React, {useEffect, useState} from "react";
import styles from "./HeuresSupplementairesSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";

function HeuresSupplementairesSection(){
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const navigate = useNavigate();

    const [matriculePaie, setMatriculePaie] = useState();
    const [listHeuresSupplementaires, setListHeuresSupplementaires] = useState();
    const [listHeuresSupplementairesEtat, setListHeuresSupplementairesEtat] = useState([]);
    const [typeHeuresSupplementaires, setTypeHeuresSupplementaires] = useState(null);
    const [typeHeuresSupplementaires_2, setTypeHeuresSupplementaires_2] = useState(null);
    const [idModifier, setIdModifier] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();
     const [isIconUp, setIsIconUp] = useState(false);
    const [additionalRows, setAdditionalRows] = useState([]);
    const [additionalRows_2, setAdditionalRows_2] = useState([]);
    const toggleRow = (rowIndex) => {
        console.log('Clicked row index:', rowIndex);
    
        setAdditionalRows(prevRows => {
        const updatedRows = [...prevRows];
        const existingRow = updatedRows.find(row => row === rowIndex);
    
        if (existingRow !== undefined) {
            updatedRows.splice(updatedRows.indexOf(existingRow), 1);
        } else {
            updatedRows.push(rowIndex);
        }
    
        console.log('Updated rows:', updatedRows);
        console.log('Existing row:', existingRow);
    
        return updatedRows;
        });
    };
  
    const toggleRow_2 = (rowIndex) => {
        console.log('Clicked row index:', rowIndex);
        setAdditionalRows_2(prevRows => {
        const updatedRows = [...prevRows];
        const existingRow = updatedRows.find(row => row === rowIndex);

        if (existingRow !== undefined) {
            updatedRows.splice(updatedRows.indexOf(existingRow), 1);
        } else {
            updatedRows.push(rowIndex);
        }
        console.log('Updated rows:', updatedRows);
        console.log('Existing row:', existingRow);

    return updatedRows;
    });
    }
      const [rowCount, setRowCount] = useState(1);


    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])

    useEffect(() => {
      if(matriculePaie){
        APIService.getHeuresSupplementairesPaie(matriculePaie, token).then(resp => setListHeuresSupplementaires(resp));
      }
    }, [matriculePaie, listHeuresSupplementairesEtat])

    useEffect(() => {

        if(JSON.stringify(listHeuresSupplementairesEtat) !== JSON.stringify(listHeuresSupplementaires)){
            setListHeuresSupplementairesEtat(listHeuresSupplementaires)
        }
    }, [listHeuresSupplementaires])
    


    const btnEnregistrer = () => {
       
        let body = {
            matricule_employe : null,
            matricule_manager : null, 
            typeHeuresSupplementaires : null,
            dateLundi : null,
            heureDebutLundi : null,
            heureFinLundi : null,
            heureCalculatedLundi : null,
            dateMardi : null,
            heureDebutMardi : null,
            heureFinMardi : null,
            heureCalculatedMardi : null,
            dateMercredi : null,
            heureDebutMercredi : null,
            heureFinMercredi : null,
            heureCalculatedMercredi : null,
            dateJeudi : null,
            heureDebutJeudi : null,
            heureFinJeudi : null,
            heureCalculatedJeudi : null,
            dateVendredi : null,
            heureDebutVendredi : null,
            heureFinVendredi : null,
            heureCalculatedVendredi : null,
            dateSamedi : null,
            heureDebutSamedi : null,
            heureFinSamedi : null,
            heureCalculatedSamedi : null,
            dateDimanche : null,
            heureDebutDimanche : null,
            heureFinDimanche : null,
            heureCalculatedDimanche : null,
            typeHeuresSupplementaires_2 : null,
            dateLundi_2 : null,
            heureDebutLundi_2 : null,
            heureFinLundi_2 : null,
            heureCalculatedLundi_2 : null,
            dateMardi_2 : null,
            heureDebutMardi_2 : null,
            heureFinMardi_2 : null,
            heureCalculatedMardi_2 : null,
            dateMercredi_2 : null,
            heureDebutMercredi_2 : null,
            heureFinMercredi_2 : null,
            heureCalculatedMercredi_2 : null,
            dateJeudi_2 : null,
            heureDebutJeudi_2 : null,
            heureFinJeudi_2 : null,
            heureCalculatedJeudi_2 : null,
            dateVendredi_2 : null,
            heureDebutVendredi_2 : null,
            heureFinVendredi_2 : null,
            heureCalculatedVendredi_2 : null,
            dateSamedi_2 : null,
            heureDebutSamedi_2 : null,
            heureFinSamedi_2 : null,
            heureCalculatedSamedi_2 : null,
            dateDimanche_2 : null,
            heureDebutDimanche_2 : null,
            heureFinDimanche_2 : null,
            heureCalculatedDimanche_2 : null,


        }


        if(listDayDate && typeHeuresSupplementaires 
            && (inputHeureCalculatedLundi || inputHeureCalculatedMardi || inputHeureCalculatedMercredi || inputHeureCalculatedJeudi || inputHeureCalculatedVendredi || inputHeureCalculatedSamedi || inputHeureCalculatedDimanche)){
            



            body.matricule_employe = matriculePaie;
            body.matricule_manager = matriculePaie; 
            body.typeHeuresSupplementaires = typeHeuresSupplementaires;
            body.dateLundi = listDayDate["Lundi"];
            body.heureDebutLundi = inputHeureDebutLundi;
            body.heureFinLundi = inputHeureFinLundi;
            body.heureCalculatedLundi = inputHeureCalculatedLundi;
            body.dateMardi = listDayDate["Mardi"];
            body.heureDebutMardi = inputHeureDebutMardi;
            body.heureFinMardi = inputHeureFinMardi;
            body.heureCalculatedMardi = inputHeureCalculatedMardi;
            body.dateMercredi = listDayDate["Mercredi"];
            body.heureDebutMercredi = inputHeureDebutMercredi;
            body.heureFinMercredi = inputHeureFinMercredi;
            body.heureCalculatedMercredi = inputHeureCalculatedMercredi;
            body.dateJeudi = listDayDate["Jeudi"];
            body.heureDebutJeudi = inputHeureDebutJeudi;
            body.heureFinJeudi = inputHeureFinJeudi;
            body.heureCalculatedJeudi = inputHeureCalculatedJeudi;
            body.dateVendredi = listDayDate["Vendredi"];
            body.heureDebutVendredi = inputHeureDebutVendredi;
            body.heureFinVendredi = inputHeureFinVendredi;
            body.heureCalculatedVendredi = inputHeureCalculatedVendredi;
            body.dateSamedi = listDayDate["Samedi"];
            body.heureDebutSamedi = inputHeureDebutSamedi;
            body.heureFinSamedi = inputHeureFinSamedi;
            body.heureCalculatedSamedi = inputHeureCalculatedSamedi;
            body.dateDimanche = listDayDate["Dimanche"];
            body.heureDebutDimanche = inputHeureDebutDimanche;
            body.heureFinDimanche = inputHeureFinDimanche;
            body.heureCalculatedDimanche = inputHeureCalculatedDimanche;
            body.typeHeuresSupplementaires_2 = typeHeuresSupplementaires_2;
            body.heureDebutLundi_2 = inputHeureDebutLundi_2;
            body.heureFinLundi_2 = inputHeureFinLundi_2;
            body.heureCalculatedLundi_2 = inputHeureCalculatedLundi_2;
            body.heureDebutMardi_2 = inputHeureDebutMardi_2;
            body.heureFinMardi_2 = inputHeureFinMardi_2;
            body.heureCalculatedMardi_2 = inputHeureCalculatedMardi_2;
            body.heureDebutMercredi_2 = inputHeureDebutMercredi_2;
            body.heureFinMercredi_2 = inputHeureFinMercredi_2;
            body.heureCalculatedMercredi_2 = inputHeureCalculatedMercredi_2;
            body.heureDebutJeudi_2 = inputHeureDebutJeudi_2;
            body.heureFinJeudi_2 = inputHeureFinJeudi_2;
            body.heureCalculatedJeudi_2 = inputHeureCalculatedJeudi_2;
            body.heureDebutVendredi_2 = inputHeureDebutVendredi_2;
            body.heureFinVendredi_2 = inputHeureFinVendredi_2;
            body.heureCalculatedVendredi_2 = inputHeureCalculatedVendredi_2;
            body.heureDebutSamedi_2 = inputHeureDebutSamedi_2;
            body.heureFinSamedi_2 = inputHeureFinSamedi_2;
            body.heureCalculatedSamedi_2 = inputHeureCalculatedSamedi_2;
            body.heureDebutDimanche_2 = inputHeureDebutDimanche_2;
            body.heureFinDimanche_2 = inputHeureFinDimanche_2;
            body.heureCalculatedDimanche_2 = inputHeureCalculatedDimanche_2;


            if(idModifier){

                APIService.putHeuresSupplementairesPaie(idModifier, body, token);
                setIdModifier();
             
            } else {

                console.log("Body : ", body)

                APIService.postHeuresSupplementairesPaie(body, token);

            }

            APIService.getHeuresSupplementairesPaie(matriculePaie, token).then(resp => {setListHeuresSupplementaires(resp); setListHeuresSupplementairesEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deleteHeuresSupplementairesPaie(idRem, token);
        APIService.getHeuresSupplementairesPaie(matriculePaie, token).then(resp => {setListHeuresSupplementaires(resp); setListHeuresSupplementairesEtat(resp);});

        setIdModifier();
        
    }
   

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listHeuresSupplementaires.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {

            var listDate = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":"", "Dimanche":""}

            
            setIdModifier(resp.id);
            setTypeHeuresSupplementaires(resp.typeHeuresSupplementaires)

            listDate["Lundi"] = resp.dateLundi;
            listDate["Mardi"] = resp.dateMardi;
            listDate["Mercredi"] = resp.dateMercredi;
            listDate["Jeudi"] = resp.dateJeudi;
            listDate["Vendredi"] = resp.dateVendredi;
            listDate["Samedi"] = resp.dateSamedi;
            listDate["Dimanche"] = resp.dateDimanche;

            setInputDate(resp.dateLundi);

            setInputHeureDebutLundi(resp.heureDebutLundi)
            setInputHeureFinLundi(resp.heureFinLundi)
            setInputHeureCalculatedLundi(resp.heureCalculatedLundi)
            setInputHeureDebutMardi(resp.heureDebutMardi)
            setInputHeureFinMardi(resp.heureFinMardi)
            setInputHeureCalculatedMardi(resp.heureCalculatedMardi)
            setInputHeureDebutMercredi(resp.heureDebutMercredi)
            setInputHeureFinMercredi(resp.heureFinMercredi)
            setInputHeureCalculatedMercredi(resp.heureCalculatedMercredi)
            setInputHeureDebutJeudi(resp.heureDebutJeudi)
            setInputHeureFinJeudi(resp.heureFinJeudi)
            setInputHeureCalculatedJeudi(resp.heureCalculatedJeudi)
            setInputHeureDebutVendredi(resp.heureDebutVendredi)
            setInputHeureFinVendredi(resp.heureFinVendredi)
            setInputHeureCalculatedVendredi(resp.heureCalculatedVendredi)
            setInputHeureDebutSamedi(resp.heureDebutSamedi)
            setInputHeureFinSamedi(resp.heureFinSamedi)
            setInputHeureCalculatedSamedi(resp.heureCalculatedSamedi)
            setInputHeureDebutDimanche(resp.heureDebutDimanche)
            setInputHeureFinDimanche(resp.heureFinDimanche)
            setInputHeureCalculatedDimanche(resp.heureCalculatedDimanche)

            setTypeHeuresSupplementaires_2(resp.typeHeuresSupplementaires_2)
            setInputHeureDebutLundi_2(resp.heureDebutLundi_2)
            setInputHeureFinLundi_2(resp.heureFinLundi_2)
            setInputHeureCalculatedLundi_2(resp.heureCalculatedLundi_2)
            setInputHeureDebutMardi_2(resp.heureDebutMardi_2)
            setInputHeureFinMardi_2(resp.heureFinMardi_2)
            setInputHeureCalculatedMardi_2(resp.heureCalculatedMardi_2)
            setInputHeureDebutMercredi_2(resp.heureDebutMercredi_2)
            setInputHeureFinMercredi_2(resp.heureFinMercredi_2)
            setInputHeureCalculatedMercredi_2(resp.heureCalculatedMercredi_2)
            setInputHeureDebutJeudi_2(resp.heureDebutJeudi_2)
            setInputHeureFinJeudi_2(resp.heureFinJeudi_2)
            setInputHeureCalculatedJeudi_2(resp.heureCalculatedJeudi_2)
            setInputHeureDebutVendredi_2(resp.heureDebutVendredi_2)
            setInputHeureFinVendredi_2(resp.heureFinVendredi_2)
            setInputHeureCalculatedVendredi_2(resp.heureCalculatedVendredi_2)
            setInputHeureDebutSamedi_2(resp.heureDebutSamedi_2)
            setInputHeureFinSamedi_2(resp.heureFinSamedi_2)
            setInputHeureCalculatedSamedi_2(resp.heureCalculatedSamedi_2)
            setInputHeureDebutDimanche_2(resp.heureDebutDimanche_2)
            setInputHeureFinDimanche_2(resp.heureFinDimanche_2)
            setInputHeureCalculatedDimanche_2(resp.heureCalculatedDimanche_2)
            


            setListDayDate(listDate)


        })


    }



    function btnModifierFunction(value){

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listHeuresSupplementaires.filter(
            resp => resp.id === parseInt(value)

        ).map(resp => {

            var listDate = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":"", "Dimanche":""}

            
            setIdModifier(resp.id);
            setTypeHeuresSupplementaires(resp.typeHeuresSupplementaires)

            listDate["Lundi"] = resp.dateLundi;
            listDate["Mardi"] = resp.dateMardi;
            listDate["Mercredi"] = resp.dateMercredi;
            listDate["Jeudi"] = resp.dateJeudi;
            listDate["Vendredi"] = resp.dateVendredi;
            listDate["Samedi"] = resp.dateSamedi;
            listDate["Dimanche"] = resp.dateDimanche;

            setInputDate(resp.dateLundi);

            setInputHeureDebutLundi(resp.heureDebutLundi)
            setInputHeureFinLundi(resp.heureFinLundi)
            setInputHeureCalculatedLundi(resp.heureCalculatedLundi)
            setInputHeureDebutMardi(resp.heureDebutMardi)
            setInputHeureFinMardi(resp.heureFinMardi)
            setInputHeureCalculatedMardi(resp.heureCalculatedMardi)
            setInputHeureDebutMercredi(resp.heureDebutMercredi)
            setInputHeureFinMercredi(resp.heureFinMercredi)
            setInputHeureCalculatedMercredi(resp.heureCalculatedMercredi)
            setInputHeureDebutJeudi(resp.heureDebutJeudi)
            setInputHeureFinJeudi(resp.heureFinJeudi)
            setInputHeureCalculatedJeudi(resp.heureCalculatedJeudi)
            setInputHeureDebutVendredi(resp.heureDebutVendredi)
            setInputHeureFinVendredi(resp.heureFinVendredi)
            setInputHeureCalculatedVendredi(resp.heureCalculatedVendredi)
            setInputHeureDebutSamedi(resp.heureDebutSamedi)
            setInputHeureFinSamedi(resp.heureFinSamedi)
            setInputHeureCalculatedSamedi(resp.heureCalculatedSamedi)
            setInputHeureDebutDimanche(resp.heureDebutDimanche)
            setInputHeureFinDimanche(resp.heureFinDimanche)
            setInputHeureCalculatedDimanche(resp.heureCalculatedDimanche)

            setTypeHeuresSupplementaires_2(resp.typeHeuresSupplementaires_2)
            setInputHeureDebutLundi_2(resp.heureDebutLundi_2)
            setInputHeureFinLundi_2(resp.heureFinLundi_2)
            setInputHeureCalculatedLundi_2(resp.heureCalculatedLundi_2)
            setInputHeureDebutMardi_2(resp.heureDebutMardi_2)
            setInputHeureFinMardi_2(resp.heureFinMardi_2)
            setInputHeureCalculatedMardi_2(resp.heureCalculatedMardi_2)
            setInputHeureDebutMercredi_2(resp.heureDebutMercredi_2)
            setInputHeureFinMercredi_2(resp.heureFinMercredi_2)
            setInputHeureCalculatedMercredi_2(resp.heureCalculatedMercredi_2)
            setInputHeureDebutJeudi_2(resp.heureDebutJeudi_2)
            setInputHeureFinJeudi_2(resp.heureFinJeudi_2)
            setInputHeureCalculatedJeudi_2(resp.heureCalculatedJeudi_2)
            setInputHeureDebutVendredi_2(resp.heureDebutVendredi_2)
            setInputHeureFinVendredi_2(resp.heureFinVendredi_2)
            setInputHeureCalculatedVendredi_2(resp.heureCalculatedVendredi_2)
            setInputHeureDebutSamedi_2(resp.heureDebutSamedi_2)
            setInputHeureFinSamedi_2(resp.heureFinSamedi_2)
            setInputHeureCalculatedSamedi_2(resp.heureCalculatedSamedi_2)
            setInputHeureDebutDimanche_2(resp.heureDebutDimanche_2)
            setInputHeureFinDimanche_2(resp.heureFinDimanche_2)
            setInputHeureCalculatedDimanche_2(resp.heureCalculatedDimanche_2)
            


            setListDayDate(listDate)


        })


    }


    
    

    

    const btnAjouter = () => {
        setIsIconUp(prevState => !prevState); 
        if(etatAjouter){
            setEtatAjouter(false);
        } else {
            

            var listDate = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":"", "Dimanche":""}

            
            setTypeHeuresSupplementaires(null)

            listDate["Lundi"] = null;
            listDate["Mardi"] = null;
            listDate["Mercredi"] = null;
            listDate["Jeudi"] = null;
            listDate["Vendredi"] = null;
            listDate["Samedi"] = null;
            listDate["Dimanche"] = null;

            setInputHeureDebutLundi(null)
            setInputHeureFinLundi(null)
            setInputHeureCalculatedLundi(null)
            setInputHeureDebutMardi(null)
            setInputHeureFinMardi(null)
            setInputHeureCalculatedMardi(null)
            setInputHeureDebutMercredi(null)
            setInputHeureFinMercredi(null)
            setInputHeureCalculatedMercredi(null)
            setInputHeureDebutJeudi(null)
            setInputHeureFinJeudi(null)
            setInputHeureCalculatedJeudi(null)
            setInputHeureDebutVendredi(null)
            setInputHeureFinVendredi(null)
            setInputHeureCalculatedVendredi(null)
            setInputHeureDebutSamedi(null)
            setInputHeureFinSamedi(null)
            setInputHeureCalculatedSamedi(null)
            setInputHeureDebutDimanche(null)
            setInputHeureFinDimanche(null)
            setInputHeureCalculatedDimanche(null)

           
            setInputHeureDebutLundi_2(null)
            setInputHeureFinLundi_2(null)
            setInputHeureCalculatedLundi_2(null)
            setInputHeureDebutMardi_2(null)
            setInputHeureFinMardi_2(null)
            setInputHeureCalculatedMardi_2(null)
            setInputHeureDebutMercredi_2(null)
            setInputHeureFinMercredi_2(null)
            setInputHeureCalculatedMercredi_2(null)
            setInputHeureDebutJeudi_2(null)
            setInputHeureFinJeudi_2(null)
            setInputHeureCalculatedJeudi_2(null)
            setInputHeureDebutVendredi_2(null)
            setInputHeureFinVendredi_2(null)
            setInputHeureCalculatedVendredi_2(null)
            setInputHeureDebutSamedi_2(null)
            setInputHeureFinSamedi_2(null)
            setInputHeureCalculatedSamedi_2(null)
            setInputHeureDebutDimanche_2(null)
            setInputHeureFinDimanche_2(null)
            setInputHeureCalculatedDimanche_2(null)
            


            setListDayDate(listDate)


            setEtatAjouter(true);
        }

        setIdModifier();
    }
    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
       // **********
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {

        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }


    const [inputDate, setInputDate] = useState();
    const [listDayDate, setListDayDate] = useState()


    useEffect(() => {
        var day;
        var dateOffset;
        var myDate;
        var listDate = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":"", "Dimanche":""}
        
    
        if(inputDate){
            var etatInputDate = false;
          
          listHeuresSupplementaires.filter(
            resp => [resp.dateLundi, resp.dateMardi].includes(inputDate)
          ).map(
            resp => {btnModifierFunction(resp.id); etatInputDate = true}
          )

          if(etatInputDate === false){

            let listDate = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":"", "Dimanche":""}

            
            setTypeHeuresSupplementaires("")


            listDate["Lundi"] = null;
            listDate["Mardi"] = null;
            listDate["Mercredi"] = null;
            listDate["Jeudi"] = null;
            listDate["Vendredi"] = null;
            listDate["Samedi"] = null;
            listDate["Dimanche"] = null;



            setInputHeureDebutLundi(null)
            setInputHeureFinLundi(null)
            setInputHeureCalculatedLundi(null)
            setInputHeureDebutMardi(null)
            setInputHeureFinMardi(null)
            setInputHeureCalculatedMardi(null)
            setInputHeureDebutMercredi(null)
            setInputHeureFinMercredi(null)
            setInputHeureCalculatedMercredi(null)
            setInputHeureDebutJeudi(null)
            setInputHeureFinJeudi(null)
            setInputHeureCalculatedJeudi(null)
            setInputHeureDebutVendredi(null)
            setInputHeureFinVendredi(null)
            setInputHeureCalculatedVendredi(null)
            setInputHeureDebutSamedi(null)
            setInputHeureFinSamedi(null)
            setInputHeureCalculatedSamedi(null)
            setInputHeureDebutDimanche(null)
            setInputHeureFinDimanche(null)
            setInputHeureCalculatedDimanche(null)

           
            setInputHeureDebutLundi_2("")
            setInputHeureFinLundi_2(null)
            setInputHeureCalculatedLundi_2(null)
            setInputHeureDebutMardi_2(null)
            setInputHeureFinMardi_2(null)
            setInputHeureCalculatedMardi_2(null)
            setInputHeureDebutMercredi_2(null)
            setInputHeureFinMercredi_2(null)
            setInputHeureCalculatedMercredi_2(null)
            setInputHeureDebutJeudi_2(null)
            setInputHeureFinJeudi_2(null)
            setInputHeureCalculatedJeudi_2(null)
            setInputHeureDebutVendredi_2(null)
            setInputHeureFinVendredi_2(null)
            setInputHeureCalculatedVendredi_2(null)
            setInputHeureDebutSamedi_2(null)
            setInputHeureFinSamedi_2(null)
            setInputHeureCalculatedSamedi_2(null)
            setInputHeureDebutDimanche_2(null)
            setInputHeureFinDimanche_2(null)
            setInputHeureCalculatedDimanche_2(null)
            


            setListDayDate(listDate)
          }

          if(!Object.values(listDate).includes(inputDate)){ 


          myDate = new Date(inputDate);
          day = new Date(inputDate).getUTCDay();
          dateOffset = (24*60*60*1000) * (day-1);
    
          myDate.setTime(myDate.getTime() - dateOffset);
          listDate["Lundi"]=moment(myDate).format("YYYY-MM-DD")
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate["Mardi"]=moment(myDate).format("YYYY-MM-DD")
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate["Mercredi"]=moment(myDate).format("YYYY-MM-DD")
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate["Jeudi"]=moment(myDate).format("YYYY-MM-DD")
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate["Vendredi"]=moment(myDate).format("YYYY-MM-DD")
    
          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate["Samedi"]=moment(myDate).format("YYYY-MM-DD")

          myDate.setTime(myDate.getTime() + (24*60*60*1000));
          listDate["Dimanche"]=moment(myDate).format("YYYY-MM-DD")


    
    
          setListDayDate(listDate)

          }
    
        
    
        }
      
      }, [inputDate])

      function diffTime(debut, fin){

        var xDebut = debut.split(":")
        var xD = parseInt(xDebut[1]) + parseInt(xDebut[0]*60)
        var xFin = fin.split(":")
        var xF = parseInt(xFin[1]) + parseInt(xFin[0]*60)
        var d = Math.abs(xF - xD)
        var h = parseInt(d/60)
        var m = parseInt((parseInt(d%60) * 100)/60)
        var t = `${h},${m}`


        return  t
      }
    //   Ligne 1 

      const [etatHeureLundi, setEtatHeureLundi] = useState(false)
      const [inputHeureDebutLundi, setInputHeureDebutLundi] = useState(null)
      const [inputHeureFinLundi, setInputHeureFinLundi] = useState(null)
      const [inputHeureCalculatedLundi, setInputHeureCalculatedLundi] = useState(null)
      const [inputHeureLundiBtn, setInputHeureLundiBtn] = useState(null)
      
      const [etatHeureMardi, setEtatHeureMardi] = useState(false)
      const [inputHeureDebutMardi, setInputHeureDebutMardi] = useState(null)
      const [inputHeureFinMardi, setInputHeureFinMardi] = useState(null)
      const [inputHeureCalculatedMardi, setInputHeureCalculatedMardi] = useState(null)
      const [inputHeureMardiBtn, setInputHeureMardiBtn] = useState()

      const [etatHeureMercredi, setEtatHeureMercredi] = useState(false)
      const [inputHeureDebutMercredi, setInputHeureDebutMercredi] = useState(null)
      const [inputHeureFinMercredi, setInputHeureFinMercredi] = useState(null)
      const [inputHeureCalculatedMercredi, setInputHeureCalculatedMercredi] = useState(null)
      const [inputHeureMercrediBtn, setInputHeureMercrediBtn] = useState(null)

      const [etatHeureJeudi, setEtatHeureJeudi] = useState(false)
      const [inputHeureDebutJeudi, setInputHeureDebutJeudi] = useState(null)
      const [inputHeureFinJeudi, setInputHeureFinJeudi] = useState(null)
      const [inputHeureCalculatedJeudi, setInputHeureCalculatedJeudi] = useState(null)
      const [inputHeureJeudiBtn, setInputHeureJeudiBtn] = useState(null)

      const [etatHeureVendredi, setEtatHeureVendredi] = useState(false)
      const [inputHeureDebutVendredi, setInputHeureDebutVendredi] = useState(null)
      const [inputHeureFinVendredi, setInputHeureFinVendredi] = useState(null)
      const [inputHeureCalculatedVendredi, setInputHeureCalculatedVendredi] = useState(null)
      const [inputHeureVendrediBtn, setInputHeureVendrediBtn] = useState(null)

      const [etatHeureSamedi, setEtatHeureSamedi] = useState(false)
      const [inputHeureDebutSamedi, setInputHeureDebutSamedi] = useState(null)
      const [inputHeureFinSamedi, setInputHeureFinSamedi] = useState(null)
      const [inputHeureCalculatedSamedi, setInputHeureCalculatedSamedi] = useState(null)
      const [inputHeureSamediBtn, setInputHeureSamediBtn] = useState(null)

      const [etatHeureDimanche, setEtatHeureDimanche] = useState(false)
      const [inputHeureDebutDimanche, setInputHeureDebutDimanche] = useState(null)
      const [inputHeureFinDimanche, setInputHeureFinDimanche] = useState(null)
      const [inputHeureCalculatedDimanche, setInputHeureCalculatedDimanche] = useState(null)
      const [inputHeureDimancheBtn, setInputHeureDimancheBtn] = useState(null)


            //   Ligne 1

    function btnEditHeure(jour) {
        switch (jour) {
            case "Lundi":
                if(etatHeureLundi){
                    setEtatHeureLundi(false)
                } else {
                    setEtatHeureLundi(true)
                };
                break;
            case "Mardi":
                    if(etatHeureMardi){
                        setEtatHeureMardi(false)
                    } else {
                        setEtatHeureMardi(true)
                    };
                    break;
            case "Mercredi":
                        if(etatHeureMercredi){
                            setEtatHeureMercredi(false)
                        } else {
                            setEtatHeureMercredi(true)
                        };
                        break;
            case "Jeudi":
                            if(etatHeureJeudi){
                                setEtatHeureJeudi(false)
                            } else {
                                setEtatHeureJeudi(true)
                            };
                            break;
            case "Vendredi":
                            if(etatHeureVendredi){
                                    setEtatHeureVendredi(false)
                            } else {
                                    setEtatHeureVendredi(true)
                                };
                            break;    
            case "Samedi":
                            if(etatHeureSamedi){
                                    setEtatHeureSamedi(false)
                                } else {
                                    setEtatHeureSamedi(true)
                                };
                            break; 
            case "Dimanche":
                            if(etatHeureDimanche){
                                    setEtatHeureDimanche(false)
                                } else {
                                    setEtatHeureDimanche(true)
                                };
                            break;  
            default:
                break;
        }
    }



    

      useEffect(() => {
        if(inputHeureDebutLundi && inputHeureFinLundi){
          setInputHeureCalculatedLundi( diffTime(inputHeureDebutLundi, inputHeureFinLundi) )
        }
        
      }, [inputHeureDebutLundi, inputHeureFinLundi])

      useEffect(() => {
        if(inputHeureDebutMardi && inputHeureFinMardi){
          setInputHeureCalculatedMardi( diffTime(inputHeureDebutMardi, inputHeureFinMardi) )
        }
        
      }, [inputHeureDebutMardi, inputHeureFinMardi])

      useEffect(() => {
        if(inputHeureDebutMercredi && inputHeureFinMercredi){
          setInputHeureCalculatedMercredi( diffTime(inputHeureDebutMercredi, inputHeureFinMercredi) )
        }
        
      }, [inputHeureDebutMercredi, inputHeureFinMercredi])

      useEffect(() => {
        if(inputHeureDebutJeudi && inputHeureFinJeudi){
          setInputHeureCalculatedJeudi( diffTime(inputHeureDebutJeudi, inputHeureFinJeudi) )
        }
        
      }, [inputHeureDebutJeudi, inputHeureFinJeudi])

      useEffect(() => {
        if(inputHeureDebutVendredi && inputHeureFinVendredi){
          setInputHeureCalculatedVendredi( diffTime(inputHeureDebutVendredi, inputHeureFinVendredi) )
        }
        
      }, [inputHeureDebutVendredi, inputHeureFinVendredi])

      useEffect(() => {
        if(inputHeureDebutSamedi && inputHeureFinSamedi){
          setInputHeureCalculatedSamedi( diffTime(inputHeureDebutSamedi, inputHeureFinSamedi) )
        }
        
      }, [inputHeureDebutSamedi, inputHeureFinSamedi])

      useEffect(() => {
        if(inputHeureDebutDimanche && inputHeureFinDimanche){
          setInputHeureCalculatedDimanche( diffTime(inputHeureDebutDimanche, inputHeureFinDimanche) )
        }
        
      }, [inputHeureDebutDimanche, inputHeureFinDimanche])

      //   Ligne 2

      const [etatHeureLundi_2, setEtatHeureLundi_2] = useState(false)
      const [inputHeureDebutLundi_2, setInputHeureDebutLundi_2] = useState(null)
      const [inputHeureFinLundi_2, setInputHeureFinLundi_2] = useState(null)
      const [inputHeureCalculatedLundi_2, setInputHeureCalculatedLundi_2] = useState(null)
      const [inputHeureLundiBtn_2, setInputHeureLundiBtn_2] = useState(null)
      
      const [etatHeureMardi_2, setEtatHeureMardi_2] = useState(false)
      const [inputHeureDebutMardi_2, setInputHeureDebutMardi_2] = useState(null)
      const [inputHeureFinMardi_2, setInputHeureFinMardi_2] = useState(null)
      const [inputHeureCalculatedMardi_2, setInputHeureCalculatedMardi_2] = useState(null)
      const [inputHeureMardiBtn_2, setInputHeureMardiBtn_2] = useState(null)

      const [etatHeureMercredi_2, setEtatHeureMercredi_2] = useState(false)
      const [inputHeureDebutMercredi_2, setInputHeureDebutMercredi_2] = useState(null)
      const [inputHeureFinMercredi_2, setInputHeureFinMercredi_2] = useState(null)
      const [inputHeureCalculatedMercredi_2, setInputHeureCalculatedMercredi_2] = useState(null)
      const [inputHeureMercrediBtn_2, setInputHeureMercrediBtn_2] = useState(null)

      const [etatHeureJeudi_2, setEtatHeureJeudi_2] = useState(false)
      const [inputHeureDebutJeudi_2, setInputHeureDebutJeudi_2] = useState(null)
      const [inputHeureFinJeudi_2, setInputHeureFinJeudi_2] = useState(null)
      const [inputHeureCalculatedJeudi_2, setInputHeureCalculatedJeudi_2] = useState(null)
      const [inputHeureJeudiBtn_2, setInputHeureJeudiBtn_2] = useState(null)

      const [etatHeureVendredi_2, setEtatHeureVendredi_2] = useState(false)
      const [inputHeureDebutVendredi_2, setInputHeureDebutVendredi_2] = useState(null)
      const [inputHeureFinVendredi_2, setInputHeureFinVendredi_2] = useState(null)
      const [inputHeureCalculatedVendredi_2, setInputHeureCalculatedVendredi_2] = useState(null)
      const [inputHeureVendrediBtn_2, setInputHeureVendrediBtn_2] = useState(null)

      const [etatHeureSamedi_2, setEtatHeureSamedi_2] = useState(false)
      const [inputHeureDebutSamedi_2, setInputHeureDebutSamedi_2] = useState(null)
      const [inputHeureFinSamedi_2, setInputHeureFinSamedi_2] = useState(null)
      const [inputHeureCalculatedSamedi_2, setInputHeureCalculatedSamedi_2] = useState(null)
      const [inputHeureSamediBtn_2, setInputHeureSamediBtn_2] = useState(null)

      const [etatHeureDimanche_2, setEtatHeureDimanche_2] = useState(false)
      const [inputHeureDebutDimanche_2, setInputHeureDebutDimanche_2] = useState(null)
      const [inputHeureFinDimanche_2, setInputHeureFinDimanche_2] = useState(null)
      const [inputHeureCalculatedDimanche_2, setInputHeureCalculatedDimanche_2] = useState(null)
      const [inputHeureDimancheBtn_2, setInputHeureDimancheBtn_2] = useState(null)


            //   Ligne 2

    function btnEditHeure_2(jour) {
        switch (jour) {
            case "Lundi":
                if(etatHeureLundi_2){
                    setEtatHeureLundi_2(false)
                } else {
                    setEtatHeureLundi_2(true)
                };
                break;
            case "Mardi":
                    if(etatHeureMardi_2){
                        setEtatHeureMardi_2(false)
                    } else {
                        setEtatHeureMardi_2(true)
                    };
                    break;
            case "Mercredi":
                        if(etatHeureMercredi_2){
                            setEtatHeureMercredi_2(false)
                        } else {
                            setEtatHeureMercredi_2(true)
                        };
                        break;
            case "Jeudi":
                            if(etatHeureJeudi_2){
                                setEtatHeureJeudi_2(false)
                            } else {
                                setEtatHeureJeudi_2(true)
                            };
                            break;
            case "Vendredi":
                            if(etatHeureVendredi_2){
                                    setEtatHeureVendredi_2(false)
                            } else {
                                    setEtatHeureVendredi_2(true)
                                };
                            break;    
            case "Samedi":
                            if(etatHeureSamedi_2){
                                    setEtatHeureSamedi_2(false)
                                } else {
                                    setEtatHeureSamedi_2(true)
                                };
                            break; 
            case "Dimanche":
                            if(etatHeureDimanche_2){
                                    setEtatHeureDimanche_2(false)
                                } else {
                                    setEtatHeureDimanche_2(true)
                                };
                            break;  
            default:
                break;
        }
    }



    

      useEffect(() => {
        if(inputHeureDebutLundi_2 && inputHeureFinLundi_2){
          setInputHeureCalculatedLundi_2( diffTime(inputHeureDebutLundi_2, inputHeureFinLundi_2) )
        }
        
      }, [inputHeureDebutLundi_2, inputHeureFinLundi_2])

      useEffect(() => {
        if(inputHeureDebutMardi_2 && inputHeureFinMardi_2){
          setInputHeureCalculatedMardi_2( diffTime(inputHeureDebutMardi_2, inputHeureFinMardi_2) )
        }
        
      }, [inputHeureDebutMardi_2, inputHeureFinMardi_2])

      useEffect(() => {
        if(inputHeureDebutMercredi_2 && inputHeureFinMercredi_2){
          setInputHeureCalculatedMercredi_2( diffTime(inputHeureDebutMercredi_2, inputHeureFinMercredi_2) )
        }
        
      }, [inputHeureDebutMercredi_2, inputHeureFinMercredi_2])

      useEffect(() => {
        if(inputHeureDebutJeudi_2 && inputHeureFinJeudi_2){
          setInputHeureCalculatedJeudi_2( diffTime(inputHeureDebutJeudi_2, inputHeureFinJeudi_2) )
        }
        
      }, [inputHeureDebutJeudi_2, inputHeureFinJeudi_2])

      useEffect(() => {
        if(inputHeureDebutVendredi_2 && inputHeureFinVendredi_2){
          setInputHeureCalculatedVendredi_2( diffTime(inputHeureDebutVendredi_2, inputHeureFinVendredi_2) )
        }
        
      }, [inputHeureDebutVendredi_2, inputHeureFinVendredi_2])

      useEffect(() => {
        if(inputHeureDebutSamedi_2 && inputHeureFinSamedi_2){
          setInputHeureCalculatedSamedi_2( diffTime(inputHeureDebutSamedi_2, inputHeureFinSamedi_2) )
        }
        
      }, [inputHeureDebutSamedi_2, inputHeureFinSamedi_2])

      useEffect(() => {
        if(inputHeureDebutDimanche_2 && inputHeureFinDimanche_2){
          setInputHeureCalculatedDimanche_2( diffTime(inputHeureDebutDimanche_2, inputHeureFinDimanche_2) )
        }
        
      }, [inputHeureDebutDimanche_2, inputHeureFinDimanche_2])

      function returnTDContent(numLigne, day, listDayDate, setInputDate, btnEditHeure, inputHeureBtn, etatHeure, inputHeureDebut, setInputHeureDebut, inputHeureFin, setInputHeureFin, inputHeureCalculated,
        setInputHeureCalculated, setInputHeureBtn) {
        return <>
        {
            numLigne === 1 ?
            <>{listDayDate &&  listDayDate[day] ? 
                <>
                    <input className={styles.inputStyle} type="date" value={listDayDate[day]} onChange = {e => setInputDate(e.target.value)}/>
                </>
                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                }</> : 
                <></>
            
        }
        <div>
             <button className={styles.buttonStyle} onClick={() => btnEditHeure(day)}>

                 {
                    inputHeureBtn ?
                      <><span>{inputHeureBtn}</span></>
                      : <>
                             {
                              inputHeureCalculated ?
                               <>{inputHeureCalculated}</>
                                :
                                <>
                                <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>
                                     </>
                              }
                                                        
                        </>
                  }
            </button>
        </div>
                                                {
                                                    etatHeure ? 
                                                    <>
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                            <div className={styles.form__group}>
                                                            </div>
                                                            <div className={styles.form__group}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebut} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebut(e.target.value)} required="" />
                                                                <label className={styles.form__label} for="matricule">Heure début</label>
                                                            </div>
                                                            <div className={styles.form__group}>
                                                            </div>
                                                            < div className={styles.form__group}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFin} onChange = {e => setInputHeureFin(e.target.value)} required="" />
                                                                <label for="name" className={styles.form__label}>Heure Fin</label>
                                                            </div>
                                                            <div className={styles.form__group}>
                                                            </div>
                                                            < div className={styles.form__group}>
                                                                <input type="text" className={styles.form__input} id={styles.name} placeholder="Nombre heure" value = {inputHeureCalculated} onChange = {e => setInputHeureCalculated(e.target.value)} required=""/>
                                                                <label for="name" className={styles.form__label}>Nombre heure</label>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure(day); if(inputHeureCalculated){
                                                            setInputHeureBtn(inputHeureCalculated)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </>:
                                                    <></>
                                                }
        
        
        </>;
      }



      const [actifLigne_2, setActifLigne_2] = useState(false)

      const btnActifLigne_2 = () => {
        setActifLigne_2(true)
      }
      const btnDeleteLigne = () => {


        setTypeHeuresSupplementaires(typeHeuresSupplementaires_2)

        setEtatHeureLundi(etatHeureLundi_2)
        setInputHeureDebutLundi(inputHeureDebutLundi_2)
        setInputHeureFinLundi(inputHeureFinLundi_2)
        setInputHeureCalculatedLundi(inputHeureCalculatedLundi_2)
        setInputHeureLundiBtn(inputHeureLundiBtn_2)
        
        setEtatHeureMardi(etatHeureMardi_2)
        setInputHeureDebutMardi(inputHeureDebutMardi_2)
        setInputHeureFinMardi(inputHeureFinMardi_2)
        setInputHeureCalculatedMardi(inputHeureCalculatedMardi_2)
        setInputHeureMardiBtn(inputHeureMardiBtn_2)

        setEtatHeureMercredi(etatHeureMercredi_2)
        setInputHeureDebutMercredi(inputHeureDebutMercredi_2)
        setInputHeureFinMercredi(inputHeureFinMercredi_2)
        setInputHeureCalculatedMercredi(inputHeureCalculatedMercredi_2)
        setInputHeureMercrediBtn(inputHeureMercrediBtn_2)

        setEtatHeureJeudi(etatHeureJeudi_2)
        setInputHeureDebutJeudi(inputHeureDebutJeudi_2)
        setInputHeureFinJeudi(inputHeureFinJeudi_2)
        setInputHeureCalculatedJeudi(inputHeureCalculatedJeudi_2)
        setInputHeureJeudiBtn(inputHeureJeudiBtn_2)

        setEtatHeureVendredi(etatHeureVendredi_2)
        setInputHeureDebutVendredi(inputHeureDebutVendredi_2)
        setInputHeureFinVendredi(inputHeureFinVendredi_2)
        setInputHeureCalculatedVendredi(inputHeureCalculatedVendredi_2)
        setInputHeureVendrediBtn(inputHeureVendrediBtn_2)

        setEtatHeureSamedi(etatHeureSamedi_2)
        setInputHeureDebutSamedi(inputHeureDebutSamedi_2)
        setInputHeureFinSamedi(inputHeureFinSamedi_2)
        setInputHeureCalculatedSamedi(inputHeureCalculatedSamedi_2)
        setInputHeureSamediBtn(inputHeureSamediBtn_2)

        setEtatHeureDimanche(etatHeureDimanche_2)
        setInputHeureDebutDimanche(inputHeureDebutDimanche_2)
        setInputHeureFinDimanche(inputHeureFinDimanche_2)
        setInputHeureCalculatedDimanche(inputHeureCalculatedDimanche_2)
        setInputHeureDimancheBtn(inputHeureDimancheBtn_2)




        setActifLigne_2(false)

      }

      
      
      const btnDeleteLigne_2 = () => {
        setActifLigne_2(false)
      }




      useEffect(() => {

        if(!actifLigne_2){
            setTypeHeuresSupplementaires_2(null)

            setEtatHeureLundi_2(false)
            setInputHeureDebutLundi_2(null)
            setInputHeureFinLundi_2(null)
            setInputHeureCalculatedLundi_2(null)
            setInputHeureLundiBtn_2(null)
            
            setEtatHeureMardi_2(false)
            setInputHeureDebutMardi_2(null)
            setInputHeureFinMardi_2(null)
            setInputHeureCalculatedMardi_2(null)
            setInputHeureMardiBtn_2(null)
    
            setEtatHeureMercredi_2(false)
            setInputHeureDebutMercredi_2(null)
            setInputHeureFinMercredi_2(null)
            setInputHeureCalculatedMercredi_2(null)
            setInputHeureMercrediBtn_2(null)
    
            setEtatHeureJeudi_2(false)
            setInputHeureDebutJeudi_2(null)
            setInputHeureFinJeudi_2(null)
            setInputHeureCalculatedJeudi_2(null)
            setInputHeureJeudiBtn_2(null)
    
            setEtatHeureVendredi_2(false)
            setInputHeureDebutVendredi_2(null)
            setInputHeureFinVendredi_2(null)
            setInputHeureCalculatedVendredi_2(null)
            setInputHeureVendrediBtn_2(null)
    
            setEtatHeureSamedi_2(false)
            setInputHeureDebutSamedi_2(null)
            setInputHeureFinSamedi_2(null)
            setInputHeureCalculatedSamedi_2(null)
            setInputHeureSamediBtn_2(null)
    
            setEtatHeureDimanche_2(false)
            setInputHeureDebutDimanche_2(null)
            setInputHeureFinDimanche_2(null)
            setInputHeureCalculatedDimanche_2(null)
            setInputHeureDimancheBtn_2(null)
        }
        
      }, [actifLigne_2])



      function getValueCalc(value) {

        if(value){
            return parseFloat(value);
        } else {
            return 0;
        }
        
      }
   

      
    return(
        <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart}>
                <TopMenuPaie page="HeuresSupplementaires"/>
            </div>
            <div className={styles.secondPart}>
                    <div className={styles.tableAndFormSection}>
                    <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type heures supplémentaires</th>
                                        <th>Heures supplémentaires</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listHeuresSupplementaires ? 
                                    
                                        listHeuresSupplementaires.sort((a, b) => a.dateLundi > b.dateLundi ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>Semaine : {moment(resp.dateLundi).format('Do MMMM')} à {moment(resp.dateDimanche).format('Do MMMM YYYY')}</td>
                                                <td> {
                                                    !resp.typeHeuresSupplementaires_2  ?
                                                    <>{resp.typeHeuresSupplementaires}</>:
                                                    <>{ resp.typeHeuresSupplementaires === resp.typeHeuresSupplementaires_2 ?

                                                        <>{resp.typeHeuresSupplementaires}</>:
                                                                    <>{resp.typeHeuresSupplementaires} et {resp.typeHeuresSupplementaires_2}</>
                                                        
                                                        
                                                        
                                                    }</>

                                                }</td>
                                                <td>{
                                                    getValueCalc(resp.heureCalculatedLundi) + 
                                                    getValueCalc(resp.heureCalculatedMardi) + 
                                                    getValueCalc(resp.heureCalculatedMercredi) + 
                                                    getValueCalc(resp.heureCalculatedJeudi) + 
                                                    getValueCalc(resp.heureCalculatedVendredi) + 
                                                    getValueCalc(resp.heureCalculatedSamedi) + 
                                                    getValueCalc(resp.heureCalculatedDimanche) + 
                                                    getValueCalc(resp.heureCalculatedLundi_2) + 
                                                    getValueCalc(resp.heureCalculatedMardi_2) + 
                                                    getValueCalc(resp.heureCalculatedMercredi_2) + 
                                                    getValueCalc(resp.heureCalculatedJeudi_2) + 
                                                    getValueCalc(resp.heureCalculatedVendredi_2) + 
                                                    getValueCalc(resp.heureCalculatedSamedi_2) + 
                                                    getValueCalc(resp.heureCalculatedDimanche_2) 

                                                }</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }

                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>


                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                            </div>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                <div className={styles.tableZone_} >
                                <table className={styles.tableTest}>
                                    <thead>
                                        <tr>
                                                <th>Date</th>
                                                <th>Lun.</th>
                                                <th>Mar.</th>
                                                <th>Mer.</th>
                                                <th>Jeu.</th>
                                                <th>Ven.</th>
                                                <th>Sam.</th>
                                                <th>Dim.</th>
                                                <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {[...Array(rowCount)].map((_, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            <tr>
                                            <td>
                                                 <span className={styles.texteType}>Type h. Supp. </span>
                                                    <select className={styles.selectOption} name="" id="" value={typeHeuresSupplementaires} onChange = {e => setTypeHeuresSupplementaires(e.target.value)}>
                                                        <option value=""></option>
                                                        <option value="type 1">type 1</option>
                                                        <option value="type 2">type 2</option>
                                                    </select>
                                                </td>
                                                <td>
                                            {listDayDate &&  listDayDate["Lundi"] ? 
                                                <>
                                                    <input className={styles.inputStyle} type="date" value={listDayDate["Lundi"]} onChange = {e => setInputDate(e.target.value)}/>
                                                </>
                                                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                                                }
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure("Lundi");toggleRow(rowIndex);}}>
                                                {
                                                        inputHeureLundiBtn ?
                                                        <><span>{inputHeureLundiBtn}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedLundi ?
                                                            <>{inputHeureCalculatedLundi}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                            {listDayDate &&  listDayDate["Mardi"] ? 
                                                <>
                                                    <input className={styles.inputStyle} type="date" value={listDayDate["Mardi"]} onChange = {e => setInputDate(e.target.value)}/>
                                                </>
                                                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                                                }
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure("Mardi");toggleRow(rowIndex);}}>
                                                {
                                                        inputHeureMardiBtn ?
                                                        <><span>{inputHeureMardiBtn}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedMardi ?
                                                            <>{inputHeureCalculatedMardi}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            
                                            <td>
                                            {listDayDate &&  listDayDate["Mercredi"] ? 
                                                <>
                                                    <input className={styles.inputStyle} type="date" value={listDayDate["Mercredi"]} onChange = {e => setInputDate(e.target.value)}/>
                                                </>
                                                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                                                }
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure("Mercredi");toggleRow(rowIndex);}}>
                                                {
                                                        inputHeureMercrediBtn ?
                                                        <><span>{inputHeureMercrediBtn}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedMercredi ?
                                                            <>{inputHeureCalculatedMercredi}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                            {listDayDate &&  listDayDate["Jeudi"] ? 
                                                <>
                                                    <input className={styles.inputStyle} type="date" value={listDayDate["Jeudi"]} onChange = {e => setInputDate(e.target.value)}/>
                                                </>
                                                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                                                }
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure("Jeudi");toggleRow(rowIndex);}}>
                                                {
                                                        inputHeureJeudiBtn ?
                                                        <><span>{inputHeureJeudiBtn}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedJeudi ?
                                                            <>{inputHeureCalculatedJeudi}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                            {listDayDate &&  listDayDate["Vendredi"] ? 
                                                <>
                                                    <input className={styles.inputStyle} type="date" value={listDayDate["Vendredi"]} onChange = {e => setInputDate(e.target.value)}/>
                                                </>
                                                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                                                }
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure("Vendredi");toggleRow(rowIndex);}}>
                                                {
                                                        inputHeureVendrediBtn ?
                                                        <><span>{inputHeureVendrediBtn}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedVendredi ?
                                                            <>{inputHeureCalculatedVendredi}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                            {listDayDate &&  listDayDate["Samedi"] ? 
                                                <>
                                                    <input className={styles.inputStyle} type="date" value={listDayDate["Samedi"]} onChange = {e => setInputDate(e.target.value)}/>
                                                </>
                                                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                                                }
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure("Samedi");toggleRow(rowIndex);}}>
                                                {
                                                        inputHeureSamediBtn ?
                                                        <><span>{inputHeureSamediBtn}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedSamedi ?
                                                            <>{inputHeureCalculatedSamedi}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                            {listDayDate &&  listDayDate["Dimanche"] ? 
                                                <>
                                                    <input className={styles.inputStyle} type="date" value={listDayDate["Dimanche"]} onChange = {e => setInputDate(e.target.value)}/>
                                                </>
                                                    :                     <input className={styles.inputStyle} type="date" onChange = {e => setInputDate(e.target.value)}/>

                                                }
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure("Dimanche");toggleRow(rowIndex);}}>
                                                {
                                                        inputHeureDimancheBtn ?
                                                        <><span>{inputHeureDimancheBtn}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedDimanche ?
                                                            <>{inputHeureCalculatedDimanche}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                    {inputDate && typeHeuresSupplementaires ? 
                                                    <button id={styles.plusButton} onClick={btnActifLigne_2}>
                                                      
                                                       <i class="fa-solid fa-plus"></i>
                                                        </button>
                                                        : <button id={styles.plusButtonDisabled} disabled>
                                                            
                                                          <i class="fa-solid fa-plus"></i>
                                                            </button>
                                                    }
                                                    {inputDate && typeHeuresSupplementaires && actifLigne_2 ? 
                                                    <button id={styles.trashButton_}  onClick={btnDeleteLigne}>

                                                         <i class="fa-solid fa-trash-can"></i>
                                                    </button>
                                                        : <button id={styles.trashButtonDisabled} disabled>

                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                    }
                                                </td>
                                                        
                                            </tr>
                                            
                                            {additionalRows.includes(rowIndex) ?
                                            <>
                                                <tr>
                                                        <td></td>
                                                        <td> 
                                                        <div className={styles.popup2}> 
                                                            <div className={styles.container}>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure début</span>
                                                                </div>
                                                                <div className={styles.form__group_}>
                                                                    <input className={styles.form__input} id={styles.name} value = {inputHeureDebutLundi} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutLundi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure Fin</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinLundi} onChange = {e => setInputHeureFinLundi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Nombre heure</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedLundi} onChange = {e => setInputHeureCalculatedLundi(e.target.value)} required=""/>
                                                                </div>
                                                              </div>
                                                              <div className={styles.btnSave} onClick = {() => {btnEditHeure("Lundi"); if(inputHeureCalculatedLundi){
                                                                setInputHeureLundiBtn(inputHeureCalculatedLundi)
                                                            }}}>
                                                                            <button className={styles.btnAjouter2}>Enregistrer</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                        <div className={styles.popup2}> 
                                                              <div className={styles.container}>
                                                              <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure début</span>
                                                                </div>
                                                                <div className={styles.form__group_}>
                                                                    <input className={styles.form__input} id={styles.name} value = {inputHeureDebutMardi} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutMardi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure Fin</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinMardi} onChange = {e => setInputHeureFinMardi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Nombre heure</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedMardi} onChange = {e => setInputHeureCalculatedMardi(e.target.value)} required=""/>
                                                                </div>
                                                              </div>
                                                              <div className={styles.btnSave} onClick = {() => {btnEditHeure("Mardi"); if(inputHeureCalculatedMardi){
                                                                setInputHeureMardiBtn(inputHeureCalculatedMardi)
                                                            }}}>
                                                                            <button className={styles.btnAjouter2}>Enregistrer</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                        <div className={styles.popup2}> 
                                                              <div className={styles.container}>
                                                              <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure début</span>
                                                                </div>
                                                                <div className={styles.form__group_}>
                                                                    <input className={styles.form__input} id={styles.name} value = {inputHeureDebutMercredi} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutMercredi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure Fin</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinMercredi} onChange = {e => setInputHeureFinMercredi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Nombre heure</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedMercredi} onChange = {e => setInputHeureCalculatedMercredi(e.target.value)} required=""/>
                                                                </div>
                                                              </div>
                                                              <div className={styles.btnSave} onClick = {() => {btnEditHeure("Mercredi"); if(inputHeureCalculatedMercredi){
                                                                setInputHeureMercrediBtn(inputHeureCalculatedMercredi)
                                                            }}}>
                                                                            <button className={styles.btnAjouter2}>Enregistrer</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                        <div className={styles.popup2}> 
                                                              <div className={styles.container}>
                                                              <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure début</span>
                                                                </div>
                                                                <div className={styles.form__group_}>
                                                                    <input className={styles.form__input} id={styles.name} value = {inputHeureDebutJeudi} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutJeudi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure Fin</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinJeudi} onChange = {e => setInputHeureFinJeudi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Nombre heure</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedJeudi} onChange = {e => setInputHeureCalculatedJeudi(e.target.value)} required=""/>
                                                                </div>
                                                              </div>
                                                              <div className={styles.btnSave} onClick = {() => {btnEditHeure("Jeudi"); if(inputHeureCalculatedJeudi){
                                                                setInputHeureJeudiBtn(inputHeureCalculatedJeudi)
                                                            }}}>
                                                                            <button className={styles.btnAjouter2}>Enregistrer</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                        <div className={styles.popup2}> 
                                                              <div className={styles.container}>
                                                              <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure début</span>
                                                                </div>
                                                                <div className={styles.form__group_}>
                                                                    <input className={styles.form__input} id={styles.name} value = {inputHeureDebutVendredi} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutVendredi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure Fin</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinVendredi} onChange = {e => setInputHeureFinVendredi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Nombre Heure</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedVendredi} onChange = {e => setInputHeureCalculatedVendredi(e.target.value)} required=""/>
                                                                </div>
                                                              </div>
                                                              <div className={styles.btnSave} onClick = {() => {btnEditHeure("Vendredi"); if(inputHeureCalculatedVendredi){
                                                                setInputHeureVendrediBtn(inputHeureCalculatedVendredi)
                                                            }}}>
                                                                            <button className={styles.btnAjouter2}>Enregistrer</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                        <div className={styles.popup2}> 
                                                              <div className={styles.container}>
                                                              <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure début</span>
                                                                </div>
                                                                <div className={styles.form__group_}>
                                                                    <input className={styles.form__input} id={styles.name} value = {inputHeureDebutSamedi} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutSamedi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure Fin</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinSamedi} onChange = {e => setInputHeureFinSamedi(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Nombre heure</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedSamedi} onChange = {e => setInputHeureCalculatedSamedi(e.target.value)} required=""/>
                                                                </div>
                                                              </div>
                                                              <div className={styles.btnSave} onClick = {() => {btnEditHeure("Samedi"); if(inputHeureCalculatedSamedi){
                                                                setInputHeureSamediBtn(inputHeureCalculatedSamedi)
                                                            }}}>
                                                                            <button className={styles.btnAjouter2}>Enregistrer</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                        <div className={styles.popup2}> 
                                                              <div className={styles.container}>
                                                              <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure début</span>
                                                                </div>
                                                                <div className={styles.form__group_}>
                                                                    <input className={styles.form__input} id={styles.name} value = {inputHeureDebutDimanche} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutDimanche(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Heure Fin</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinDimanche} onChange = {e => setInputHeureFinDimanche(e.target.value)} required="" />
                                                                </div>
                                                                <div className={styles.labelForm}>
                                                                    <span className={styles.textePopUP}>Nombre heure</span>
                                                                </div>
                                                                < div className={styles.form__group_}>
                                                                    <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedDimanche} onChange = {e => setInputHeureCalculatedDimanche(e.target.value)} required=""/>
                                                                </div>
                                                              </div>
                                                              <div className={styles.btnSave} onClick = {() => {btnEditHeure("Dimanche"); if(inputHeureCalculatedDimanche){
                                                                setInputHeureDimancheBtn(inputHeureCalculatedDimanche)
                                                            }}}>
                                                                            <button className={styles.btnAjouter2}>Enregistrer</button>
                                                                </div>
                                                            </div>
                                                        </td>
    
                                                       
    
                                                        
                                                </tr>
                                                </>
                                            :
                                            <></>
                                            
                                            }
                                            {actifLigne_2 ?
                                        <> 
                                        <tr>
                                        <td>
                                             <span className={styles.texteType}>Type h. Supp. </span>
                                                    <select className={styles.selectOption} name="" id="" value={typeHeuresSupplementaires_2} onChange = {e => setTypeHeuresSupplementaires_2(e.target.value)}>
                                                        <option value=""></option>
                                                        <option value="type 1">type 1</option>
                                                        <option value="type 2">type 2</option>
                                                    </select>
                                                </td>
                                                <td>   
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure_2("Lundi");toggleRow_2(rowIndex);}}>
                                                {
                                                        inputHeureLundiBtn_2 ?
                                                        <><span>{inputHeureLundiBtn_2}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedLundi_2 ?
                                                            <>{inputHeureCalculatedLundi_2}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure_2("Mardi");toggleRow_2(rowIndex);}}>
                                                {
                                                        inputHeureMardiBtn_2 ?
                                                        <><span>{inputHeureMardiBtn_2}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedMardi_2 ?
                                                            <>{inputHeureCalculatedMardi_2}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            
                                            <td>
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure_2("Mercredi");toggleRow_2(rowIndex);}}>
                                                {
                                                        inputHeureMercrediBtn_2 ?
                                                        <><span>{inputHeureMercrediBtn_2}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedMercredi_2 ?
                                                            <>{inputHeureCalculatedMercredi_2}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure_2("Jeudi");toggleRow_2(rowIndex);}}>
                                                {
                                                        inputHeureJeudiBtn_2 ?
                                                        <><span>{inputHeureJeudiBtn_2}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedJeudi_2 ?
                                                            <>{inputHeureCalculatedJeudi_2}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure_2("Vendredi");toggleRow_2(rowIndex);}}>
                                                {
                                                        inputHeureVendrediBtn_2 ?
                                                        <><span>{inputHeureVendrediBtn_2}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedVendredi_2 ?
                                                            <>{inputHeureCalculatedVendredi_2}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure_2("Samedi");toggleRow_2(rowIndex);}}>
                                                {
                                                        inputHeureSamediBtn_2 ?
                                                        <><span>{inputHeureSamediBtn_2}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedSamedi_2 ?
                                                            <>{inputHeureCalculatedSamedi_2}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                <button className={styles.buttonStyle}  onClick={() => {btnEditHeure_2("Dimanche");toggleRow_2(rowIndex);}}>
                                                {
                                                        inputHeureDimancheBtn_2 ?
                                                        <><span>{inputHeureDimancheBtn_2}</span></>
                                                        : <>

                                                        {
                                                            inputHeureCalculatedDimanche_2 ?
                                                            <>{inputHeureCalculatedDimanche_2}</>
                                                            :
                                                            <>
                                                            <span  className={styles.clockIcon}><i class="fa-solid fa-clock"></i></span>

                                                            
                                                            </>
                                                        }
                                                        
                                                        </>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                    {inputDate && typeHeuresSupplementaires_2 ? 
                                                    <button id={styles.trashButton_}  onClick={btnDeleteLigne_2}>

                                                         <i class="fa-solid fa-trash-can"></i>
                                                    </button>
                                                        : <button id={styles.trashButtonDisabled} disabled>

                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                    }
                                                </td>
                                        </tr>
                                         {additionalRows_2.includes(rowIndex) && (
                                            <tr>
                                                    <td></td>
                                                    <td> 
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                          <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure début</span>
                                                            </div>
                                                            <div className={styles.form__group_}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebutLundi_2} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutLundi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure Fin</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinLundi_2} onChange = {e => setInputHeureFinLundi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Nombre Heure</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedLundi_2} onChange = {e => setInputHeureCalculatedLundi_2(e.target.value)} required=""/>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure_2("Lundi"); if(inputHeureCalculatedLundi_2){
                                                            setInputHeureLundiBtn_2(inputHeureCalculatedLundi_2)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>  
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                          <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure début</span>
                                                            </div>
                                                            <div className={styles.form__group_}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebutMardi_2} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutMardi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure Fin</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinMardi_2} onChange = {e => setInputHeureFinMardi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Nombre heure</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedMardi_2} onChange = {e => setInputHeureCalculatedMardi_2(e.target.value)} required=""/>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure_2("Mardi"); if(inputHeureCalculatedMardi_2){
                                                            setInputHeureMardiBtn_2(inputHeureCalculatedMardi_2)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>  
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                          <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure début</span>
                                                            </div>
                                                            <div className={styles.form__group_}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebutMercredi_2} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutMercredi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure Fin</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinMercredi_2} onChange = {e => setInputHeureFinMercredi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Nombre heure</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedMercredi_2} onChange = {e => setInputHeureCalculatedMercredi_2(e.target.value)} required=""/>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure_2("Mercredi"); if(inputHeureCalculatedMercredi_2){
                                                            setInputHeureMercrediBtn_2(inputHeureCalculatedMercredi_2)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>  
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                          <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure début</span>
                                                            </div>
                                                            <div className={styles.form__group_}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebutJeudi_2} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutJeudi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure Fin</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinJeudi_2} onChange = {e => setInputHeureFinJeudi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Nombre heure</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="text" className={styles.form__input} id={styles.name} value = {inputHeureCalculatedJeudi_2} onChange = {e => setInputHeureCalculatedJeudi_2(e.target.value)} required=""/>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure_2("Jeudi"); if(inputHeureCalculatedJeudi_2){
                                                            setInputHeureJeudiBtn_2(inputHeureCalculatedJeudi_2)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>  
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                          <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure début</span>
                                                            </div>
                                                            <div className={styles.form__group_}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebutVendredi_2} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutVendredi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure Fin</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinVendredi_2} onChange = {e => setInputHeureFinVendredi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Nombre heure</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedVendredi_2} onChange = {e => setInputHeureCalculatedVendredi_2(e.target.value)} required=""/>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure_2("Vendredi"); if(inputHeureCalculatedVendredi_2){
                                                            setInputHeureVendrediBtn_2(inputHeureCalculatedVendredi_2)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>  
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                          <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure début</span>
                                                            </div>
                                                            <div className={styles.form__group_}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebutSamedi_2} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutSamedi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure Fin</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinSamedi_2} onChange = {e => setInputHeureFinSamedi_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Nombre heure</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedSamedi_2} onChange = {e => setInputHeureCalculatedSamedi_2(e.target.value)} required=""/>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure_2("Samedi"); if(inputHeureCalculatedSamedi_2){
                                                            setInputHeureSamediBtn_2(inputHeureCalculatedSamedi_2)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>  
                                                    <div className={styles.popup2}> 
                                                          <div className={styles.container}>
                                                          <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure début</span>
                                                            </div>
                                                            <div className={styles.form__group_}>
                                                                <input className={styles.form__input} id={styles.name} value = {inputHeureDebutDimanche_2} type="time" placeholder="Heure debut" onChange = {e => setInputHeureDebutDimanche_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Heure Fin</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="time" className={styles.form__input} id={styles.name} placeholder="Heure Fin" value = {inputHeureFinDimanche_2} onChange = {e => setInputHeureFinDimanche_2(e.target.value)} required="" />
                                                            </div>
                                                            <div className={styles.labelForm}>
                                                                <span className={styles.textePopUP}>Nombre heure</span>
                                                            </div>
                                                            < div className={styles.form__group_}>
                                                                <input type="text" className={styles.form__input} id={styles.name}  value = {inputHeureCalculatedDimanche_2} onChange = {e => setInputHeureCalculatedDimanche_2(e.target.value)} required=""/>
                                                            </div>
                                                          </div>
                                                          <div className={styles.btnSave} onClick = {() => {btnEditHeure_2("Dimanche"); if(inputHeureCalculatedDimanche_2){
                                                            setInputHeureDimancheBtn_2(inputHeureCalculatedDimanche_2)
                                                        }}}>
                                                                        <button className={styles.btnAjouter2}>Enregistrer</button>
                                                            </div>
                                                        </div>
                                                    </td>

                                                   

                                                    
                                            </tr>
                                            )}
                                        
                                        
                                        
                                        
                                         </>:
                                        <>  </>
                                    }
                                            
                                            
                                        </React.Fragment>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            
                    </div>
            </div>
        </div>
    </div>
    )
}

export default HeuresSupplementairesSection;