import React from 'react';
import styles from "./Alert.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faMoneyCheck,faDatabase, faFile , faFileCircleCheck, faIdCard, faBrain, faCheckToSlot, faAngleRight, } from '@fortawesome/free-solid-svg-icons'
import {faXmark} from '@fortawesome/free-solid-svg-icons'

import { useState } from 'react'

function Alert(props) {
  return (
    <div>
        {
            props.type === "danger" ?
            <>
            <div className={styles.MessageError}>

<span className={styles.sectionIcon}>

    <button className={styles.btnWarning}  >



    !



    </button>

</span>

<span className={styles.sectionMsg}>


    <div className={styles.topMsg}>

    {props.topMsg}
 

    </div>


    <div className={styles.bottomMsg}>

    {props.bottomMsg}

    </div>

</span>

<span className={styles.sectionValidation}>

     <div className={styles.topicone}>

        <span className={styles.iconeClose}>

            <button className={styles.close} 
            // onClick={handleCloseClick}
            >



            <FontAwesomeIcon icon={faXmark } />

           



            </button>

          </span>

     </div>

    <div className={styles.bottomicone}>

          <span className={styles.lesicones}>

            <button className={styles.btOui} onClick={props.btn} >

               Oui

            </button>

            <button className={styles.btNon}  >

              Non

            </button>

          </span>

    </div>

 </span>

</div>
            
            
            
            
            
            
            
            </>:<>
            
            {
            props.type === "success" ?
            <>
            
            <div className={styles.MessageValider}>

<span className={styles.sectionIcon2}>

    <button className={styles.btnChek}  >


    <FontAwesomeIcon icon={faCheck } />


    </button>

</span>

<span className={styles.sectionMsg2}>

    <div className={styles.topMsg2}>

    {props.topMsg}

    </div>

    <div className={styles.bottomMsg2}>

    {props.bottomMsg}


    </div>

</span>

<span className={styles.sectionValidation2}>

    <div className={styles.topicone2}>

        <span className={styles.iconeClose2}>

            <button className={styles.close2} 
            // onClick={handleCloseClick}
            >



            <FontAwesomeIcon icon={faXmark } />

            </button>

        </span>

    </div>

    <div className={styles.bottomicone2}>

        <span className={styles.lesicones2}>

            <button className={styles.btOui2} onClick={props.btn} >

            Oui

            </button>

            <button className={styles.btNon2}  >

            Non

            </button>

        </span>

    </div>

</span>

</div>
            
            
            </>:<>
            
            
            
            
            </>
        }
            
            
            </>
        }
    


        



    </div>
  )
}

export default Alert