import React, { useState,useEffect,useRef } from 'react';
import styles from './SecondSectionTablePageTablet.module.css'; 

function GeneralCardTestTablet(props) {
    const [componentsToDisplay,setComponentsToDisplay]=useState([])
    useEffect(() => {
        const Components = [];
        for (let i = 1; i <= props.nbreComponents; i++) {
            const propName = `component${i}`;
            if (props[propName]) {
                Components.push(props[propName]);
            }
        }
        setComponentsToDisplay(Components);
    }, [props]);

      // console.log("display ",componentsToDisplay)

      const [show, setShow] = useState(false);
      const [caretDirection, setCaretDirection] = useState('up');
      const popupRef = useRef(null);
      const barButtonRef = useRef(null);
      const [isMoving, setIsMoving] = useState(false);

     
      const showComponents = () => {
        setShow(prevShow => !prevShow);
        setCaretDirection(show ? 'up' : 'down');
        setIsMoving(true);
        if (show) {
          console.log("Show True : ", show)
              popupRef.current.classList.remove(styles.fadeOut);
          setTimeout(() => {
                popupRef.current.style.display = 'none';
                console.log("New ref after .51s : ", popupRef.current.style.display)
            }, 510); 
      }else{
        console.log("Show False : ", show)
        popupRef.current.classList.add(styles.fadeIn);
        popupRef.current.style.display = 'block';
      }
      };

      const handleClick = (event) => {
       
        if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)
      ) {
        showComponents()
        }
      };
      
      useEffect(() => {
        if (show) {
          document.addEventListener('mousedown', handleClick);
        setCaretDirection('up');
        } else {
          document.removeEventListener('mousedown', handleClick);
        setCaretDirection('down');
        }
        return () => {
          document.removeEventListener('mousedown', handleClick);
        };
      }, [show]);
    return (
        <div className={styles.fullStyle}>
          { props.nbreComponents<4 ? (
            <>
            <div className={styles.fourthSectionStyle}>
                {componentsToDisplay.map((component,index) => (
                    <div className={styles.firstPartOfComponenets} key={index}>
                    {component}
                    </div>
                ))}
            </div>
            </>):
            <>
            <div className={styles.moreComponenetsSection}>
                <div className={styles.fourthSectionStyle}>
                     <span className={`${styles.addMoreStyle} ${styles.bubble}`} ref={barButtonRef} onClick={() => showComponents()} style={{ 
                     backgroundImage: show ? "linear-gradient(180deg, rgb(218, 165, 32, 60%), goldenrod)":"" ,
                     boxShadow: show? "0 1px 1px #0000002e":"",
                     textShadow: show? "0 1px 0px #79485f7a" :""}}>
                            <i className={`fa-solid fa-angles-${caretDirection}`} style={{ color: show ? "white" : "" }}></i>
                    </span>
                        {componentsToDisplay.slice(0, 3).map((component,index) => (
                        <div className={styles.firstPartOfComponenets} key={index}>
                        {component}
                        </div>
                        ))}
                </div>
                <div className={styles.sectionPopup}>
                    <div id={styles.popupStyle} className={show ? `${styles.active}` : `${styles.hidden}`} ref={popupRef}  style={{minHeight: "42px",resize: "vertical"}}>
                        <div className={styles.fourthSectionStyle}>
                            {componentsToDisplay.slice(3,props.nbreComponents).map((component,index) => (
                                <div className={styles.styleEachComponent} key={index}>
                                {component}
                                </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
      </div>
    );
}
export default GeneralCardTestTablet;
