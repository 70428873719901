import React from 'react';
import styles from './Hide.module.css';

function Hide(props) {
  return (
    <button id={styles.hideButton} onClick={() => props.hideData()}>
      <i class="fa-solid fa-eye-slash"></i>
    </button>
  );
}

export default Hide;