import React, { useState, useEffect } from "react";
import {useCookies} from 'react-cookie';
import APIService from './../../../../APIService.js';
import Styles from './AvenantSection.module.css';
import moment from 'moment';
import TopMenuSalarie from "../../TopMenuSalarie.jsx";
import GeneralCardTest from "../../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import SecondSectionTablePage from "../../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import DateComponent from "../../../GlobalComponents/dateComponent/DateComponent.jsx";
import CreateComponent from "../../../GlobalComponents/createComponent/CreateComponent.jsx";
import DescriptionComponent from "../../../GlobalComponents/descriptionComponent/DescriptionComponent.jsx";
import { useNavigate } from "react-router-dom";

const AvenantSection = () => {
  const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculeAvenant', 'id', 'matriculeEmployeAttente']);
  const [dateDebut, setDateDebut] = useState("")
  const [action, setAction] = useState("")
  const [formErrorsAvenant, setFormErrorsAvenant] = useState({})
  const [avenantValeurs, setAvenantValeurs] = useState({})

  function inputId(valeur, erreur, nomId){
    if(!valeur && !erreur)
          {return nomId }
          else {
                if(valeur && !erreur){       
                      return "inputVert";
                }else{
                      if(erreur){
                            return "inputRouge";
                      }
                }
          }
}  
const [inputPoste, setInputPoste] = useState('')
const [inputTypeContrat, setInputTypeContrat] = useState('')
const [inputDateDebut, setInputDateDebut] = useState('')
const [inputDateFin, setInputDateFin] = useState('')
const [inputAction, setInputAction] = useState('')


var body = {}
const [listTypeContrat, setListTypeContrat] = useState(null)

useEffect(() => {

    if(token['mytoken']){
        APIService.getTypeContrat(token)
        .then(resp => setListTypeContrat(resp))
    }
  
}, [token['mytoken']])

const [listActionsAdministration, setListActionsAdministration] = useState(null)

useEffect(() => {

    if(token['mytoken']){
        APIService.getActionsAdministration(token)
        .then(resp => setListActionsAdministration(resp))
    }
  
}, [token['mytoken']])
useEffect(() => {      
  setInputPoste(inputId(avenantValeurs.poste, formErrorsAvenant.poste, "inputPoste"))
}, [avenantValeurs.poste, formErrorsAvenant.poste])

useEffect(() => {      
  setInputTypeContrat(inputId(avenantValeurs.typeContrat, formErrorsAvenant.typeContrat, "inputTypeContrat"))
}, [avenantValeurs.typeContrat, formErrorsAvenant.typeContrat])

useEffect(() => {      
  setInputDateDebut(inputId(avenantValeurs.dateDebut, formErrorsAvenant.dateDebut, "inputDateDebut"))
}, [avenantValeurs.dateDebut, formErrorsAvenant.dateDebut])

useEffect(() => {      
  setInputDateFin(inputId(avenantValeurs.dateFin, formErrorsAvenant.dateFin, "inputDateFin"))
}, [avenantValeurs.dateFin, formErrorsAvenant.dateFin])

useEffect(() => {      
  setInputAction(inputId(avenantValeurs.action, formErrorsAvenant.action, "inputAction"))
}, [avenantValeurs.action, formErrorsAvenant.action])


  const handleChangeAvenant = (e) => {

    // e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, "");
    // e.target.value = e.clipboardData.getData('Text').trim();*
    e.target.value = e.target.value.replace(/^\s+/g, "");

    e.target.value = e.target.value.replace(/ +(?= )/g, "");
    

    if(e.target.name === 'poste'){

         
          // e.target.value = e.target.value.replace(/[^A-Za-z]/g,"");
          e.target.value = e.target.value.replace(/[^a-zA-Z\u00C0-\u017F-\s]/g,"");
          e.target.value = e.target.value.toUpperCase() 
          

    }
    

    
    const {name, value} = e.target;
    
    setAvenantValeurs({...avenantValeurs, [name]: value});
    
}

const validateAvenant = (valeurs) => {

  const errors = {};

  if(!valeurs.poste){
        errors.poste = "Le poste est obligatoire !";

  }
  if(!valeurs.typeContrat){
    errors.typeContrat = "Le type de contrat est obligatoire !";
  }else{
    if(valeurs.typeContrat === 'CDD'){
      if(!valeurs.dateFin){
        errors.dateFin = "La date de fin est obligatoire !";
      }
    }
  }

  if(!valeurs.dateDebut){
    errors.dateDebut = "La date de début est obligatoire !";
  }

  if(!valeurs.action){
    errors.action = "L'action est obligatoire !";
  }


  return errors;

};   

const [isSubmitAvenant, setIsSubmitAvenant] = useState(false)

const btnAvenant = () => {
  setFormErrorsAvenant(validateAvenant(avenantValeurs));

  if(avenantValeurs.typeContrat && avenantValeurs.poste && avenantValeurs.dateDebut && avenantValeurs.action){

    if(avenantValeurs.typeContrat === "CDD"){
    body = {matricule_employe: token['matriculeAvenant'], typeContrat_avenant:avenantValeurs.typeContrat, post_avenant:avenantValeurs.poste, dateDebut_avenant:avenantValeurs.dateDebut, dateFin_avenant:avenantValeurs.dateFin, action_avenant:avenantValeurs.action }
    }else{
    body = {matricule_employe: token['matriculeAvenant'], typeContrat_avenant:avenantValeurs.typeContrat, post_avenant:avenantValeurs.poste, dateDebut_avenant:avenantValeurs.dateDebut, dateFin_avenant:"2099-01-01", action_avenant:avenantValeurs.action }
    }
    
    APIService.AjouterAvenant(body, token)

    window.location.href = '/contrat'
  }
}

useEffect(() => {

  setAction(avenantValeurs.action)
  
  if((typeof avenantValeurs.action === 'undefined' || avenantValeurs.action === "") && Object.keys(formErrorsAvenant).length === 0){
              console.log('')
              
  }else{
        if(!avenantValeurs.action) {
          setFormErrorsAvenant({...formErrorsAvenant, action:"L'action est obligatoire !"})
              // formErrorsAvenant.poste = "Le poste est obligatoire !";
        }else{
              // formErrorsAvenant.poste = "";
          setFormErrorsAvenant({...formErrorsAvenant, action:""})

        }  
  } 
}, 
[avenantValeurs.action])

useEffect(() => {
  if(avenantValeurs.dateFin === 'CDD')
  {
    if((typeof avenantValeurs.dateFin === 'undefined' || avenantValeurs.dateFin === "") && Object.keys(formErrorsAvenant).length === 0){
      console.log('')
      
    }else{
    if(!avenantValeurs.dateFin) {
      setFormErrorsAvenant({...formErrorsAvenant, dateFin:"La date de fin est obligatoire !"})
          // formErrorsAvenant.poste = "Le poste est obligatoire !";
    }else{
          // formErrorsAvenant.poste = "";
      setFormErrorsAvenant({...formErrorsAvenant, dateFin:""})

    }  
    } 
  }
  }, 
  [avenantValeurs.dateFin])

useEffect(() => {

  setDateDebut(avenantValeurs.dateDebut)
  
  if((typeof avenantValeurs.dateDebut === 'undefined' || avenantValeurs.dateDebut === "") && Object.keys(formErrorsAvenant).length === 0){
              console.log('')
              
  }else{
        if(!avenantValeurs.dateDebut) {
          setFormErrorsAvenant({...formErrorsAvenant, dateDebut:"La date de début est obligatoire !"})
              // formErrorsAvenant.poste = "Le poste est obligatoire !";
        }else{
              // formErrorsAvenant.poste = "";
          setFormErrorsAvenant({...formErrorsAvenant, dateDebut:""})

        }  
  } 
}, 
[avenantValeurs.dateDebut])

useEffect(() => {
  
  if((typeof avenantValeurs.poste === 'undefined' || avenantValeurs.poste === "") && Object.keys(formErrorsAvenant).length === 0){
              console.log('')
              
  }else{
        if(!avenantValeurs.poste) {
          setFormErrorsAvenant({...formErrorsAvenant, poste:"Le poste est obligatoire !"})
              // formErrorsAvenant.poste = "Le poste est obligatoire !";
        }else{
              // formErrorsAvenant.poste = "";
          setFormErrorsAvenant({...formErrorsAvenant, poste:""})

        }  
  } 
}, 
[avenantValeurs.poste])

useEffect(() => {
  
  if((typeof avenantValeurs.typeContrat === 'undefined' || avenantValeurs.typeContrat === "") && Object.keys(formErrorsAvenant).length === 0){
              console.log('')
              
  }else{
        if(!avenantValeurs.typeContrat) {
          setFormErrorsAvenant({...formErrorsAvenant, typeContrat:"Le type de contrat est obligatoire !"})
              // formErrorsAvenant.poste = "Le poste est obligatoire !";
        }else{
              // formErrorsAvenant.poste = "";
          setFormErrorsAvenant({...formErrorsAvenant, typeContrat:""})

        }  
  } 
}, 
[avenantValeurs.typeContrat])


function displayErrorInput(erreur){
  return (
  <>
  
  <div className={Styles.container2}>
  <div className={Styles.zoneInputInput}>
        <span >
        
        </span>
        <span className={Styles.textdecorationInput}>
        {erreur ? 
        <>{erreur}</>
        :
        <></>
        } </span>
  </div>
</div>
  </>)
  
}
const navigate = useNavigate();
useEffect(() => {
      if( token['matriculeAvenant']){
        console.log("token", token['matriculeAvenant'])
    
      }else{
        console.log("no token here ")
      navigate('/salarie');

      }
    }, [ token['matriculeAvenant']])

  return (
//  <div className={Styles.classCard}>
//     <div className={Styles.BarSection}>
//     <TopMenuSalarie page="contrat"/>
//     </div>
//     <div className={Styles.firstsetcion}>
//         <div id={Styles.divFormulaire}>
//           <div className={Styles.lecentredepage} >
//                         <div className={Styles.container1} id={Styles.container4}>
//                               <div className={Styles.zoneInputInput}>
//                                     <span className={Styles.fileNameTxtStyleInput}>
//                                     Type de contrat
//                                     </span>
//                                     <select name="typeContrat" value={avenantValeurs.typeContrat} onChange={handleChangeAvenant} className={Styles.goldenInput1}>
//                                     <option></option>
//                                                                         {
//                                                                               listTypeContrat &&
//                                                                               listTypeContrat.map(
//                                                                                     resp => <option value={resp.id}>{resp.nom}</option>
//                                                                               )
//                                                                         }
//                                     </select>
//                               </div>
//                         </div> 
//                                 {displayErrorInput (formErrorsAvenant.typeContrat)}
//                         <div className={Styles.container1} id={Styles.container4}>
//                               <div className={Styles.zoneInputInput}>
//                                     <span className={Styles.fileNameTxtStyleInput}>
//                                     Poste
//                                     </span>
//                                     <input type="Nom" name="poste" value={avenantValeurs.poste} onChange={handleChangeAvenant} id={inputPoste} placeholder="" className={Styles.goldenInput1}/>
//                               </div>
//                         </div> 
//                                         {displayErrorInput (formErrorsAvenant.poste)}
//                         <div className={Styles.container1} id={Styles.container4}>
//                               <div className={Styles.zoneInputInput}>
//                                     <span className={Styles.fileNameTxtStyleInput}>
//                                     Date début
//                                     </span>
//                                     <input placeholder="Select date" name="dateDebut" value={avenantValeurs.dateDebut} onChange={handleChangeAvenant} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>
//                               </div>
//                         </div> 
//                                         {displayErrorInput (formErrorsAvenant.dateDebut)}
//                         {
//                         avenantValeurs.typeContrat === 'CDD' ? 
//                         <>
//                         <div className={Styles.container1} id={Styles.container4}>
//                               <div className={Styles.zoneInputInput}>
//                                     <span className={Styles.fileNameTxtStyleInput}>
//                         Date fin
//                         </span>
//                         <input placeholder="Select date" name="dateFin" value={avenantValeurs.dateFin} onChange={handleChangeAvenant} type="date" id={inputDateFin} className={Styles.goldenInput1}/>
//                         </div>
//                         </div>
//                               {displayErrorInput (formErrorsAvenant.dateFin)}
//                         </> 
//                         : 
//                         <>
//                         <div className={Styles.container1} id={Styles.container4}>
//                               <div className={Styles.zoneInputInput}>
//                                     <span className={Styles.fileNameTxtStyleInput}>
//                                       Actions
//                                     </span>
//                         <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
//                             <option></option>
//                                                                         {
//                                                                               listActionsAdministration &&
//                                                                               listActionsAdministration.map(
//                                                                                     resp => <option value={resp.id}>{resp.titre}</option>
//                                                                               )
//                                                                         }
//                         </select>
//                         </div>
//                         </div>
//                             {displayErrorInput (formErrorsAvenant.action)}
//                         </>
//                     }
//                 <div>
//                 </div>
//                 {
//                         avenantValeurs.typeContrat === 'CDD' ? 
//                         <>
//                               <div className={Styles.container1} id={Styles.container4}>
//                               <div className={Styles.zoneInputInput}>
//                                     <span className={Styles.fileNameTxtStyleInput}>
//                                   Actions</span>
//                                 <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
//                                     <option></option>
//                                     <option value="Changement de contrat">Changement de contrat</option>
//                                     <option value="En cours">En cours</option>
//                                     <option value="Non commencé">Non commencé</option>
//                                 </select>
//                             </div>

//                         </div>

//                         {displayErrorInput (formErrorsAvenant.action)}
//                         </>: <>
//                         </>
//                     }
//             </div>
//         </div>
//     </div>
//     <div className={Styles.secondsection}>
//         <div id={Styles.idTestTable}>
//   <table >
//   <thead>
//     <tr id={Styles.idForStyleTh}>

//       <th scope="col">Type d'avenant</th>
//       <th scope="col">Date début</th>
//     </tr>
//   </thead>
//   <tbody>
//     {
//       action || dateDebut ? 
//         <tr>
//           <td> {action} </td>
//           <td> {moment(dateDebut).format("DD/MM/YYYY")}</td>
//         </tr>
        
//         : <span></span>
//     }
//   </tbody>
//   </table>
//         </div>
//     </div>
//     <div className={Styles.thirdsection}>
//         <div className={Styles.divValider}>
//         <button type="button" id={Styles.idBtnValider} onClick={btnAvenant}>   Valider   </button>
//         </div>
//     </div>
//  </div>
<div>
  <div className={Styles.web}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="contrat" />
  } 
  section2={
      <SecondSectionTablePage
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
          <div id={Styles.divFormulaire}>
            <div className={Styles.lecentredepage} >
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Type de contrat
                                      </span>
                                      <select class="form-select" name="typeContrat" value={avenantValeurs.typeContrat} onChange={handleChangeAvenant} id={inputTypeContrat} className={Styles.goldenInput1}>
                                      <option></option>
                                                                          {
                                                                                listTypeContrat &&
                                                                                listTypeContrat.map(
                                                                                      resp => <option value={resp.id}>{resp.nom}</option>
                                                                                )
                                                                          }
                                      </select>
                                </div>
                          </div> 
                                  {displayErrorInput (formErrorsAvenant.typeContrat)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Poste
                                      </span>
                                      <input type="Nom" name="poste" value={avenantValeurs.poste} onChange={handleChangeAvenant} id={inputPoste} placeholder="" className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.poste)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Date début
                                      </span>
                                      <input placeholder="Select date" name="dateDebut" value={avenantValeurs.dateDebut} onChange={handleChangeAvenant} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.dateDebut)}
                          {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                          Date fin
                          </span>
                          <input placeholder="Select date" name="dateFin" value={avenantValeurs.dateFin} onChange={handleChangeAvenant} type="date" id={inputDateFin} className={Styles.goldenInput1}/>
                          </div>
                          </div>
                                {displayErrorInput (formErrorsAvenant.dateFin)}
                          </> 
                          : 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                        Actions
                                      </span>
                          <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                              <option></option>
                                                                          {
                                                                                listActionsAdministration &&
                                                                                listActionsAdministration.map(
                                                                                      resp => <option value={resp.id}>{resp.titre}</option>
                                                                                )
                                                                          }
                          </select>
                          </div>
                          </div>
                              {displayErrorInput (formErrorsAvenant.action)}
                          </>
                      }
                  <div>
                  </div>
                  {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                                <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                    Actions</span>
                                  <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                                      <option></option>
                                      <option value="Changement de contrat">Changement de contrat</option>
                                      <option value="En cours">En cours</option>
                                      <option value="Non commencé">Non commencé</option>
                                  </select>
                              </div>

                          </div>

                          {displayErrorInput (formErrorsAvenant.action)}
                          </>: <>
                          </>
                      }
              </div>
          </div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnAvenant}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={8}
  prctgeSection2={6}
  prctgeSection3={80} 
  prctgeSection4={6}
  />
  </div>
  <div className={Styles.tablet}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="contrat" />
  } 
  section2={
      <SecondSectionTablePageTablet
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
          <div id={Styles.divFormulaire}>
            <div className={Styles.lecentredepage} >
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Type de contrat
                                      </span>
                                      <select class="form-select" name="typeContrat" value={avenantValeurs.typeContrat} onChange={handleChangeAvenant} id={inputTypeContrat} className={Styles.goldenInput1}>
                                      <option></option>
                                                                          {
                                                                                listTypeContrat &&
                                                                                listTypeContrat.map(
                                                                                      resp => <option value={resp.id}>{resp.nom}</option>
                                                                                )
                                                                          }
                                      </select>
                                </div>
                          </div> 
                                  {displayErrorInput (formErrorsAvenant.typeContrat)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Poste
                                      </span>
                                      <input type="Nom" name="poste" value={avenantValeurs.poste} onChange={handleChangeAvenant} id={inputPoste} placeholder="" className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.poste)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Date début
                                      </span>
                                      <input placeholder="Select date" name="dateDebut" value={avenantValeurs.dateDebut} onChange={handleChangeAvenant} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.dateDebut)}
                          {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                          Date fin
                          </span>
                          <input placeholder="Select date" name="dateFin" value={avenantValeurs.dateFin} onChange={handleChangeAvenant} type="date" id={inputDateFin} className={Styles.goldenInput1}/>
                          </div>
                          </div>
                                {displayErrorInput (formErrorsAvenant.dateFin)}
                          </> 
                          : 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                        Actions
                                      </span>
                          <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                              <option></option>
                                                                          {
                                                                                listActionsAdministration &&
                                                                                listActionsAdministration.map(
                                                                                      resp => <option value={resp.id}>{resp.titre}</option>
                                                                                )
                                                                          }
                          </select>
                          </div>
                          </div>
                              {displayErrorInput (formErrorsAvenant.action)}
                          </>
                      }
                  <div>
                  </div>
                  {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                                <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                    Actions</span>
                                  <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                                      <option></option>
                                      <option value="Changement de contrat">Changement de contrat</option>
                                      <option value="En cours">En cours</option>
                                      <option value="Non commencé">Non commencé</option>
                                  </select>
                              </div>

                          </div>

                          {displayErrorInput (formErrorsAvenant.action)}
                          </>: <>
                          </>
                      }
              </div>
          </div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnAvenant}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={8}
  prctgeSection2={6} 
  prctgeSection3={80} 
  prctgeSection4={6}
  />
  </div>
  <div className={Styles.mobile}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="contrat" />
  } 
  section2={
      <SecondSectionTablePageMobile
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
          <div id={Styles.divFormulaire}>
            <div className={Styles.lecentredepage} >
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Type de contrat
                                      </span>
                                      <select class="form-select" name="typeContrat" value={avenantValeurs.typeContrat} onChange={handleChangeAvenant} id={inputTypeContrat} className={Styles.goldenInput1}>
                                      <option></option>
                                                                          {
                                                                                listTypeContrat &&
                                                                                listTypeContrat.map(
                                                                                      resp => <option value={resp.id}>{resp.nom}</option>
                                                                                )
                                                                          }
                                      </select>
                                </div>
                          </div> 
                                  {displayErrorInput (formErrorsAvenant.typeContrat)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Poste
                                      </span>
                                      <input type="Nom" name="poste" value={avenantValeurs.poste} onChange={handleChangeAvenant} id={inputPoste} placeholder="" className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.poste)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Date début
                                      </span>
                                      <input placeholder="Select date" name="dateDebut" value={avenantValeurs.dateDebut} onChange={handleChangeAvenant} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.dateDebut)}
                          {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                          Date fin
                          </span>
                          <input placeholder="Select date" name="dateFin" value={avenantValeurs.dateFin} onChange={handleChangeAvenant} type="date" id={inputDateFin} className={Styles.goldenInput1}/>
                          </div>
                          </div>
                                {displayErrorInput (formErrorsAvenant.dateFin)}
                          </> 
                          : 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                        Actions
                                      </span>
                          <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                              <option></option>
                                                                          {
                                                                                listActionsAdministration &&
                                                                                listActionsAdministration.map(
                                                                                      resp => <option value={resp.id}>{resp.titre}</option>
                                                                                )
                                                                          }
                          </select>
                          </div>
                          </div>
                              {displayErrorInput (formErrorsAvenant.action)}
                          </>
                      }
                  <div>
                  </div>
                  {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                                <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                    Actions</span>
                                  <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                                      <option></option>
                                      <option value="Changement de contrat">Changement de contrat</option>
                                      <option value="En cours">En cours</option>
                                      <option value="Non commencé">Non commencé</option>
                                  </select>
                              </div>

                          </div>

                          {displayErrorInput (formErrorsAvenant.action)}
                          </>: <>
                          </>
                      }
              </div>
          </div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnAvenant}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={7}
  prctgeSection2={6} 
  prctgeSection3={81} 
  prctgeSection4={6}
  />
  </div>
  <div className={Styles.mobileInversePetit}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="contrat" />
  } 
  section2={
      <SecondSectionTablePageMobileInversePetit
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
          <div id={Styles.divFormulaire}>
            <div className={Styles.lecentredepage} >
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Type de contrat
                                      </span>
                                      <select class="form-select" name="typeContrat" value={avenantValeurs.typeContrat} onChange={handleChangeAvenant} id={inputTypeContrat} className={Styles.goldenInput1}>
                                      <option></option>
                                                                          {
                                                                                listTypeContrat &&
                                                                                listTypeContrat.map(
                                                                                      resp => <option value={resp.id}>{resp.nom}</option>
                                                                                )
                                                                          }
                                      </select>
                                </div>
                          </div> 
                                  {displayErrorInput (formErrorsAvenant.typeContrat)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Poste
                                      </span>
                                      <input type="Nom" name="poste" value={avenantValeurs.poste} onChange={handleChangeAvenant} id={inputPoste} placeholder="" className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.poste)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Date début
                                      </span>
                                      <input placeholder="Select date" name="dateDebut" value={avenantValeurs.dateDebut} onChange={handleChangeAvenant} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.dateDebut)}
                          {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                          Date fin
                          </span>
                          <input placeholder="Select date" name="dateFin" value={avenantValeurs.dateFin} onChange={handleChangeAvenant} type="date" id={inputDateFin} className={Styles.goldenInput1}/>
                          </div>
                          </div>
                                {displayErrorInput (formErrorsAvenant.dateFin)}
                          </> 
                          : 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                        Actions
                                      </span>
                          <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                              <option></option>
                                                                          {
                                                                                listActionsAdministration &&
                                                                                listActionsAdministration.map(
                                                                                      resp => <option value={resp.id}>{resp.titre}</option>
                                                                                )
                                                                          }
                          </select>
                          </div>
                          </div>
                              {displayErrorInput (formErrorsAvenant.action)}
                          </>
                      }
                  <div>
                  </div>
                  {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                                <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                    Actions</span>
                                  <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                                      <option></option>
                                      <option value="Changement de contrat">Changement de contrat</option>
                                      <option value="En cours">En cours</option>
                                      <option value="Non commencé">Non commencé</option>
                                  </select>
                              </div>

                          </div>

                          {displayErrorInput (formErrorsAvenant.action)}
                          </>: <>
                          </>
                      }
              </div>
          </div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnAvenant}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={10}
  prctgeSection2={9} 
  prctgeSection3={72} 
  prctgeSection4={9}
  />
  </div>
  <div className={Styles.mobileInverseGrand}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="contrat" />
  } 
  section2={
      <SecondSectionTablePageMobileInverseGrand
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
          <div id={Styles.divFormulaire}>
            <div className={Styles.lecentredepage} >
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Type de contrat
                                      </span>
                                      <select class="form-select" id={inputTypeContrat} name="typeContrat" value={avenantValeurs.typeContrat} onChange={handleChangeAvenant} className={Styles.goldenInput1}>
                                      <option></option>
                                                                          {
                                                                                listTypeContrat &&
                                                                                listTypeContrat.map(
                                                                                      resp => <option value={resp.id}>{resp.nom}</option>
                                                                                )
                                                                          }
                                      </select>
                                </div>
                          </div> 
                                  {displayErrorInput (formErrorsAvenant.typeContrat)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Poste
                                      </span>
                                      <input type="Nom" name="poste" value={avenantValeurs.poste} onChange={handleChangeAvenant} id={inputPoste} placeholder="" className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.poste)}
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                      Date début
                                      </span>
                                      <input placeholder="Select date" name="dateDebut" value={avenantValeurs.dateDebut} onChange={handleChangeAvenant} type="date" id={inputDateDebut} className={Styles.goldenInput1}/>
                                </div>
                          </div> 
                                          {displayErrorInput (formErrorsAvenant.dateDebut)}
                          {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                          Date fin
                          </span>
                          <input placeholder="Select date" name="dateFin" value={avenantValeurs.dateFin} onChange={handleChangeAvenant} type="date" id={inputDateFin} className={Styles.goldenInput1}/>
                          </div>
                          </div>
                                {displayErrorInput (formErrorsAvenant.dateFin)}
                          </> 
                          : 
                          <>
                          <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                        Actions
                                      </span>
                          <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                              <option></option>
                                                                          {
                                                                                listActionsAdministration &&
                                                                                listActionsAdministration.map(
                                                                                      resp => <option value={resp.id}>{resp.titre}</option>
                                                                                )
                                                                          }
                          </select>
                          </div>
                          </div>
                              {displayErrorInput (formErrorsAvenant.action)}
                          </>
                      }
                  <div>
                  </div>
                  {
                          avenantValeurs.typeContrat === 'CDD' ? 
                          <>
                                <div className={Styles.container1} id={Styles.container4}>
                                <div className={Styles.zoneInputInput}>
                                      <span className={Styles.fileNameTxtStyleInput}>
                                    Actions</span>
                                  <select class="form-select" name="action" value={avenantValeurs.action} onChange={handleChangeAvenant} id={inputAction} className={Styles.goldenInput1}>
                                      <option></option>
                                      <option value="Changement de contrat">Changement de contrat</option>
                                      <option value="En cours">En cours</option>
                                      <option value="Non commencé">Non commencé</option>
                                  </select>
                              </div>

                          </div>

                          {displayErrorInput (formErrorsAvenant.action)}
                          </>: <>
                          </>
                      }
              </div>
          </div>
  }
  section4={
          <div className={Styles.divValider}>
          <button type="button" id={Styles.idBtnValider} onClick={btnAvenant}>   Valider   </button>
          </div>
  }  
  NbreSections={4} 
  prctgeSection1={10}
  prctgeSection2={9}
  prctgeSection3={72} 
  prctgeSection4={9}
  />
  </div> 
</div>
  );
   
};
     
export default AvenantSection;