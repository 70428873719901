import React, { useState, useEffect } from 'react';
import APIService from '../../../APIService';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import logosolway from "./../../img/logosolway.png";
import Styles from './Reset.module.css';
import "./../../../App.css";
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import TestAlert from '../../../components/testalert/TestAlert';
// import Alert from '../../../components/alert/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import Alert from '../../GlobalComponents/alert/Alert';
import solwayGroup from '../../img/solwayGroup.png';
import solwayGroup2 from '../../img/solwayGroup_2.png';

import faviconsolway from '../../img/faviconsolway.png';

// import '../App.css'



function Reset() {
    
    
    const [token, setToken] = useCookies(['mytoken', 'username'])
    const [user, setUser] = useState(null)

    const [email, setEmail] = useState(null)
    const [afficherMsg, setAfficherMsg] = useState(false)

    const [password, setPassword] = useState(null)
    const [confPassword, setConfPassword] = useState(null)

    const [errorInputEmail, setErrorInputEmail] = useState(false)
    const [errorInput, setErrorInput] = useState(false)
    const [etatValidationWithoudCarteVitale, setEtatValidationWithoudCarteVitale] = useState(false)

    const [isHovered, setIsHovered] = useState(false);
    const [passModifier, setPassModifier] = useState(null)
    const [showAlertChamps, setShowAlertChamps] = useState(false)
    const [showAlertConfirmation, setShowAlertConfirmation] = useState(false)



    const { uid, tokenReset } = useParams();

    console.log("uid : ", uid)

    useEffect(() => {
        // const { uid, token } = props.match.params;

        console.log("Uid : ", uid)
        if(uid && tokenReset){
            console.log("uid : ", uid)
            console.log("tokenReset : ", tokenReset)

            axios.get(`http://127.0.0.1:8000/reset/${uid}/${tokenReset}/`)
            .then(resp => {
                console.log('resp.data : ',resp.data)
                setUser(resp.data) 
            })
            
        }

        
    }, [uid, tokenReset])

    useEffect(() => {
        console.log("User : ", user)
    }, [user])







    let navigate = useNavigate()



    async function registerBtn(e){
        e.preventDefault();
        console.log('token : ',token)

        if(email){

        setAfficherMsg(true)

        let body = {
            "to_email": email
        }

        const response = await APIService.postResetPassword(body, token);
        console.log('response : ',response)


        } else {
            setErrorInputEmail(true)
        }


        // if(prenom && nom && email && password && password === confPassword){
        //     let body = {"first_name": prenom, "last_name": nom, "username": email, 
        //     'email': email, "roles": "user", 'resetPasswortToken' : null, 'activation_token': null, 
        //     'reset_token': null, 'enabled': false, 'isVerified': false,  "password": password, 'isValidByAdmin': false}


        //     APIService.postUtilisateur(body)

        // }

        


    }


   


    async function  editPassword(e){
        e.preventDefault();

    
        if(user && password && password === confPassword){
            console.log('user id : ',user.id)
          

          let formData = new FormData();
          formData.append("password", password)




        //    APIService.putUtilisateur(user.id, formData)
        //    APIService.putUtilisateur(user.id, formData)
        //    .then(resp => {
        //     setPassModifier(resp.is_successful);
        //     setShowAlertChamps(false);
        //     setShowAlertConfirmation(false);
        //   })

        const resp = await APIService.putUtilisateur(user.id, formData)
            setPassModifier(resp.is_successful);
            setShowAlertChamps(false);
            setShowAlertConfirmation(false);
          
          
    
    
    
        } else {
            // setErrorInput(true)
            if(!password || !confPassword){
                setShowAlertChamps(true)
                setPassModifier(false);
                setShowAlertConfirmation(false);
        
            } else {if(password !== confPassword){
                setShowAlertConfirmation(true)
                setPassModifier(false);
                setShowAlertChamps(false);
        
            }}
        }
    
      //  APIService.getCategories(token).then(resp => setListCategories(resp))
    
        
      }
    
      const btnCarteVitaleOui = () => {
      
        setEtatValidationWithoudCarteVitale(true)
       
    }




  

  return (
    <>
        {/* PC */}
        
        <div className={Styles.classDivGlobal}  id={Styles.web}>

        <div className={Styles.leftDiv}>
        <div className={Styles.leftRectangle}>
        <div className={Styles.logoDiv}>
        <div id={Styles.logoSolwayHrContainer}>
                            <img className={Styles.solwayGroupLogo} src={faviconsolway} /> 
                        </div>
                        <span id={Styles.textTitleCard}>SOLWAY HR - Une gestion complète et efficace de votre écosystème RH</span>

        </div>

        <div className={Styles.discDiv}>
        <span className={Styles.discSpan} style={{fontFamily: "Cambria"}}>
                        <p style={{fontFamily: "Cambria"}}>SOLWAY HR est un système de gestion des ressources humaines avancé, qui exploite la puissance de l'intelligence artificielle pour simplifier les tâches et optimiser les processus RH. Notre outil offre une gestion complète et efficace de votre écosystème RH, incluant :</p>
                        <ul style={{fontFamily: "Cambria"}}>
                            <li style={{fontFamily: "Cambria"}}>Le processus de recrutement</li>
                            <li style={{fontFamily: "Cambria"}}>L'embauche</li>
                            <li style={{fontFamily: "Cambria"}}>La gestion des employés</li>
                            <li style={{fontFamily: "Cambria"}}>Le suivi du temps de travail</li>
                            <li style={{fontFamily: "Cambria"}}>La paie</li>
                            <li style={{fontFamily: "Cambria"}}>Les entretiens annuels</li>
                            <li style={{fontFamily: "Cambria"}}>Et bien plus encore.</li>
                        </ul>
                        <p style={{fontFamily: "Cambria"}}>Avec SOLWAY HR, bénéficiez d'une gestion RH optimale et concentrez-vous sur l'essentiel : le développement de votre entreprise.</p>

                         </span>
        </div>
        </div>

        </div>

        <div className={Styles.rightDiv}>
       


    {
        user ?

            <div className={Styles.formRestContainer}>
            <form className={Styles.formStyle} onSubmit = {e => editPassword(e)}>
            <div className={Styles.inputsDiv}>

                <div class={Styles.inputContainer}>
                    <i class="fa-solid fa-lock"></i>
                    <input type='password'
                        placeholder="Mot de passe" value = {password} onChange = {e => {setPassword(e.target.value); setErrorInput(false)}}/>
                </div>
                <div class={Styles.inputContainer}>
                    <i class="fa-solid fa-lock"></i>
                    <input type='password'
                        placeholder="Confirmer le mot de passe" value = {confPassword} onChange = {e => {setConfPassword(e.target.value); setErrorInput(false)}}/>
                </div>
            </div>

            <>
            {
                                                showAlertChamps ?
                                                <div className={Styles.alertsDiv}>
                                                    <Alert type="danger" text="Saisissez les champs obligatoires." 
                                                    handleCloseClick={() => setShowAlertChamps(false)}
                                                    />
                                                </div> : 
                                                <></>
                    }
                     {
                                                showAlertConfirmation ?
                                                <div className={Styles.alertsDiv}>
                                                    <Alert type="danger" text="Les mots de passe ne sont pas les memes." 
                                                    handleCloseClick={() => setShowAlertConfirmation(false)}
                                                    />
                                                </div> : 
                                                <></>
                    }
                    { passModifier
                                                 ?
                                                <div className={Styles.alertsDiv}>
                                                    <Alert type="success" text="La modification a réussi." 
                                                    handleCloseClick={() => setPassModifier(false)}
                                                    />
                                                </div> : 
                                                <></>
                    }
            </>



            <div className={Styles.resetButtonDiv}>
            <button type="submit" onClick={(e) => editPassword(e)}>
                Modifier
            </button>
            </div>

            </form>
            </div>
            :
            <>
            {
                            afficherMsg && email ?
                            <div className={Styles.alertsDiv} style={{flexDirection:'column'}}>
                         <Alert type = "success" text = {`Si un compte est associé à cette adresse e-mail: ${email}, 
                         vous recevrez un e-mail pour réinitialiser votre mot de passe.`}  onClick={() => setAfficherMsg(false)} handleCloseClick = {() => setAfficherMsg(false)}/>
<br />
                                {/* S'il existe ok un compte avec ce mail : {email},  vous recevez un email pour reinstaller le mot de passe,  */}
                                                            <span style={{cursor:"pointer"}} className={Styles.textColor} onClick={() => setAfficherMsg(false)}> réssayer à nouveau
                                                            </span>
                                 
                            </div>:
                            <>
                             <span className={Styles.headerLogin}>
                        {/* <img id={Styles.logoConnect} src={faviconsolway} alt="" /> */}
                        <img id={Styles.logoConnect} src={solwayGroup2} alt="" />

                        <br />
                        {/* Bienvenue <br /> */}
                        <span id={Styles.idTextConnect}>Réinitialisation de mot de passe</span>
                        
                    </span>

                            <div className={Styles.formContainer}>
                            <form className={Styles.formStyle} onSubmit = {e => registerBtn(e)}>
                            <div className={Styles.inputsDiv}>
                                <div class={Styles.inputContainer}>
                                    <i class="fa-solid fa-at"></i>
                                    <input type="email" placeholder="Email" value={email} onChange = {e => {setEmail(e.target.value); setErrorInputEmail(false)}}/>
                                </div>
                            </div>

                            {
                            errorInputEmail ?
                            // <div className = {Styles.classError}>L'adresse mail est obligatoire ! </div>
                            // <TestAlert type = "danger" topMsg = "La modification a réussi " bottomMsg = "" btn = {""}/>
                        // <TestAlert type = "danger" topMsg = " L'adresse mail est obligatoire !" bottomMsg = "" btn = {btnCarteVitaleOui}/>
                        <div className={Styles.alertsDiv}>
                        <Alert type = "danger" text = " Une adresse e-mail est requise." bottomMsg = ""  onClick={() => setErrorInputEmail(false)} handleCloseClick = {() => setErrorInputEmail(false)}/>
                        </div>


                            :
                            <></>
                        }

                            <div className={Styles.loginButtonDiv}>
                            <button type="submit" onClick={e => registerBtn(e)}>
                                Envoyer
                            </button>
                            </div>

                            </form>
                            </div>
                            </>


                        }

            </>

    }
    <div className={Styles.signUpDiv}>
      <span>Vous n’avez pas de compte ? <a onClick={() => navigate('/')}>S'identifier</a></span>
    </div>

    </div>
        </div>


        {/* Mobile */}

        <div className={Styles.classDivGlobalMobile} id={Styles.mobile}>

        <div className={Styles.topDiv}>
        <div className={Styles.logoMobileDiv}>
        <img className={Styles.solwayGroupLogoMobile}  src={solwayGroup}/>
        </div>
        </div>

        <div className={Styles.bottomDiv}>
        <div className={Styles.descDivMobile}>
        <spam className={Styles.discSpamMobile}>
        SOLWAY HR : système RH avancé utilisant l'IA pour simplifier les tâches et optimiser les processus. Gestion complète de l'embauche, des employés, du temps de travail, de la paie et des entretiens annuels.

        </spam>
        </div>

        <div className={Styles.formMobileDiv}>

        {
        user ?

            <div className={Styles.formContainerMobile} style={{height:'70%'}}>
            <form className={Styles.formStyleMobile} onSubmit = {e => editPassword(e)}>
            <div className={Styles.inputsDivMobile}>

                <div class={Styles.inputContainerMobile}>
                    <i class="fa-solid fa-lock"></i>
                    <input type='password'
                        placeholder="Mot de passe" value = {password} onChange = {e => {setPassword(e.target.value); setErrorInput(false)}}/>
                </div>
                <div class={Styles.inputContainerMobile}>
                    <i class="fa-solid fa-lock"></i>
                    <input type='password'
                        placeholder="Confirmer le mot de passe" value = {confPassword} onChange = {e => {setConfPassword(e.target.value); setErrorInput(false)}}/>
                </div>
            </div>

            <>
            {
                                                showAlertChamps ?
                                                <div className={Styles.alertsDiv}>
                                                    <Alert type="danger" text="Saisissez les champs obligatoires." 
                                                    handleCloseClick={() => setShowAlertChamps(false)}
                                                    />
                                                </div> : 
                                                <></>
                    }
                     {
                                                showAlertConfirmation ?
                                                <div className={Styles.alertsDiv}>
                                                    <Alert type="danger" text="Les mots de passe ne sont pas les memes." 
                                                    handleCloseClick={() => setShowAlertConfirmation(false)}
                                                    />
                                                </div> : 
                                                <></>
                    }
                    { passModifier
                                                 ?
                                                <div className={Styles.alertsDiv}>
                                                    <Alert type="success" text="La modification a réussi." 
                                                    handleCloseClick={() => setPassModifier(false)}
                                                    />
                                                </div> : 
                                                <></>
                    }
            </>



            <div className={Styles.resetButtonDiv}>
            <button type="submit" onClick={(e) => editPassword(e)}>
                Modifier
            </button>
            </div>

            </form>
            </div>
            :
            <>
            {
                            afficherMsg && email ?
                            <div className={Styles.alertsDiv} style={{flexDirection:'column',height:'60%'}}>
                         <Alert type = "success" text = {`Si un compte est associé à cette adresse e-mail: ${email}, 
                         vous recevrez un e-mail pour réinitialiser votre mot de passe.`}  onClick={() => setAfficherMsg(false)} handleCloseClick = {() => setAfficherMsg(false)}/>
<br />
                                {/* S'il existe ok un compte avec ce mail : {email},  vous recevez un email pour reinstaller le mot de passe,  */}
                                                            <span style={{cursor:"pointer"}} className={Styles.textColor} onClick={() => setAfficherMsg(false)}> réssayer à nouveau
                                                            </span>
                                 
                            </div>:
                            <>
                            <div className={Styles.heaerDivMobile}>
                            <spam className={Styles.headerLoginMobile}>
                            <img id={Styles.logoConnectMobile} src={faviconsolway} />
    

    <br />
    <span id={Styles.idTextConnectMobile}>  Réinitialisation de mot de passe</span>
                           
                            </spam>
                            </div>

                            <div className={Styles.formContainerMobile}>
                            <form className={Styles.formStyleMobile} onSubmit = {e => registerBtn(e)}>
                            <div className={Styles.inputsDivMobile}>
                                <div class={Styles.inputContainerMobile}>
                                    <i class="fa-solid fa-at"></i>
                                    <input type="email" placeholder="Email" value={email} onChange = {e => {setEmail(e.target.value); setErrorInputEmail(false)}}/>
                                </div>
                            </div>

                            {
                            errorInputEmail ?
                            // <div className = {Styles.classError}>L'adresse mail est obligatoire ! </div>
                            // <TestAlert type = "danger" topMsg = "La modification a réussi " bottomMsg = "" btn = {""}/>
                        // <TestAlert type = "danger" topMsg = " L'adresse mail est obligatoire !" bottomMsg = "" btn = {btnCarteVitaleOui}/>
                        <div className={Styles.alertsDiv}>
                        <Alert type = "danger" text = " Une adresse e-mail est requise." bottomMsg = ""  onClick={() => setErrorInputEmail(false)} handleCloseClick = {() => setErrorInputEmail(false)}/>
                        </div>


                            :
                            <></>
                        }

                            <div className={Styles.loginButtonDivMobile}>
                            <button type="submit" onClick={e => registerBtn(e)}>
                                Envoyer
                            </button>
                            </div>

                            </form>
                            </div>
                            </>


                        }

            </>

    }
    <div className={Styles.signUpDivMobile}>
        <span>Retourner à la page de connexion ? <a onClick={() => navigate('/')}>Connexion</a></span>
    </div>





        </div>
        </div>
        </div>

    </>
  )
}


export default Reset