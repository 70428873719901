
import React from 'react';
import {useCookies} from 'react-cookie';
import GestionTempsNavBar from './../GestionDeTempsGlobal/NavBar/GestionTempsNavBar.jsx';
import GestionTempsCard from './../GestionDeTempsGlobal/Card/GestionTempsCard.jsx';
import PlanningAbsenceSection from './PlanningAbsenceSection.jsx';
// import NavBarGlobal from '../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx';
import NavBarGlobal from "./../../Global/NavBar/NavBarGlobal/NavBarGlobal";


function PlanningAbsence() {
    const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
  return (
    <div>
       <NavBarGlobal section={<PlanningAbsenceSection/>}/>
    </div>
  )
}

export default PlanningAbsence