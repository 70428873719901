import React from 'react';
import styles from './NavBarGlobal.module.css';
import NavBar from './../NavBar';
import NavBarMobile from './../Mobile/NavBarMobile';

function NavBarGlobal(props) {
  return (
    <div>
        <div id={styles.navPC}>
            <NavBar section = {props.section}/>
        </div>
        
        <div id={styles.navMobile}>
            <NavBarMobile section = {props.section}/>
        </div>
    </div>
  )
}

export default NavBarGlobal;