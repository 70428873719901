import React, {useEffect, useState} from 'react';
import styles from "./DemandeAbsenceSection.module.css";
import moment from 'moment';
import {useCookies} from 'react-cookie';
import APIService from './../../../../APIService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavBarGlobal from '../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx';
import TopMenuGestionDuTemps from '../../TopMenuGestionDuTemps.jsx';

function DemandeAbsenceSection() {
    const [show, setShow] = useState(false);
    const [etatValider, setEtatValider] = useState(false)
    const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel', 'idAbsence']);
    const [inputMatriculeRecherche, setInputMatriculeRecherche] = useState("")
    const [inputNomPrenomRecherche, setInputNomPrenomRecherche] = useState("")
    const [inputPrenomRecherche, setInputPrenomRecherche] = useState("")
    const [inputNomRecherche, setInputNomRecherche] = useState("")

    const [etatMatriculeRecherche, setEtatMatriculeRecherche] = useState(false)
    const [etatNomPrenomRecherche, setEtatNomPrenomRecherche] = useState(false)
    const [etatPrenomRecherche, setEtatPrenomRecherche] = useState(false)
    const [etatNomRecherche, setEtatNomRecherche] = useState(false)
    const [employes, setEmployes] = useState([]);
    const [employesList, setEmployesList] = useState([]);
    const [employesListResult, setEmployesListResult] = useState([]);
    const [nomDeclaration, setNomDeclaration] = useState();
    const [prenomDeclaration, setPrenomDeclaration] = useState();
    const [matriculeTargetDeclaration, setMatriculeTargetDeclaration] = useState();
    const [rubriqueAbsence, setRubriqueAbsence] = useState();
    const [rubriqueDateDebut, setRubriqueDateDebut] = useState();
    const [rubriqueDateFin, setRubriqueDateFin] = useState();
    const [choixJourneeDebut, setChoixJourneeDebut] = useState();
    const [choixJourneeFin, setChoixJourneeFin] = useState();
    const [nombreJour, setNombreJour] = useState();
    const [commentaire, setCommentaire] = useState();
    const [pieceJustificative, setPieceJustificative] = useState();
    const [nomEmploye, setNomEmploye] = useState()
    const [prenomEmploye, setPrenomEmploye] = useState()
    const [demandeAbsence, setDemandeAbsence] = useState()
    const [demandeAbsenceFichiers, setDemandeAbsenceFichiers] = useState()
    const [pieceJustificativeName, setPieceJustificativeName] = useState();
    const [idDemande, setIdDemande] = useState();
    const [idFichier, setIdFichier] = useState();
    const [modifierEtat, setModifierEtat] = useState(false); 
    const [role, setRole] = useState("manager");
    const [listDemandeAbsenceAdministration, setListDemandeAbsenceAdministration] = useState(null)





    // Calendrier


    const listMois = {1: "Janvier", 2: "Février", 3: "Mars", 4: "Avril", 5: "Mai", 6: "Juin", 7: "Juillet", 8: "Août", 9: "Septembre", 10: "Octobre", 11: "Novembre", 12: "Décembre"}  
    var numberDaysMois = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0}  
    var listHeader = []
    var listJours = {1:"Lun", 2:"Mar", 3:"Mer", 4:"Jeu", 5:"Ven", 6:"Sam", 0:"Dim"}
    var listBody = {1:[], 2:[], 3:[], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10:[], 11:[], 12:[], 13:[], 14:[], 15:[], 16:[], 17:[], 18:[], 19:[], 20:[], 21:[], 22:[], 23:[], 24:[], 25:[], 26:[], 27:[], 28:[], 29:[], 30:[], 31:[]}
    var listBodyTable = []
    var listDateDebutValider = []
    var listDateFinValider = []
    var listDateDebutAttente = []
    var listDateFinAttente = []

    


    
    const [listHistoriqueAbsence, setListHistoriqueAbsence] = useState([]);


    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [listDateDebutValiderResult, setListDateDebutValiderResult] = useState()
    const [listDateFinValiderResult, setListDateFinValiderResult] = useState()
    const [listDateDebutAttenteResult, setListDateDebutAttenteResult] = useState()
    const [listDateFinAttenteResult, setListDateFinAttenteResult] = useState()
    const [rechercheValidation, setRechercheValidation] = useState()
    const [nbrCP, setNbrCP] = useState(0)
    const [nbrRTT, setNbrRTT] = useState(0)
    const [nbrMaladie, setNbrMaladie] = useState(0)
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
      console.log('Event:', e);
      if (e.target) {
        console.log('Target:', e.target);
        if (e.target.files) {
          console.log('Files:', e.target.files);
          const file = e.target.files[0];
          setSelectedFile(file);
          setPieceJustificative(file);
          setPieceJustificativeName();
        }
      }
    };

  const handleCommentaireChange = (e) => {
    setCommentaire(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  };

  useEffect(() => {

    if(token['mytoken']){
        APIService.getDemandeAbsenceAdministration(token)
        .then(resp => setListDemandeAbsenceAdministration(resp))
    }
  
}, [token['mytoken']])


    useEffect(() => {
        if(listHistoriqueAbsence){


            listHistoriqueAbsence.filter(resp => resp.action_demandeAbsence === true).map(
                abs => { 
                    listDateDebutValider.push(abs.rubriqueDateDebut_demandeAbsence);
                    listDateFinValider.push(abs.rubriqueDateFin_demandeAbsence);
                    if((abs.rubriqueDateDebut_demandeAbsence).toString().split("-").includes((currentYear).toString())){

                        if(abs.rubriqueAbsence_demandeAbsence === "Demande CP"){
                            setNbrCP(nbrCP + parseInt(abs.nombreJour_demandeAbsence))
                        }else{
    
                            if(abs.rubriqueAbsence_demandeAbsence === "Demande RTT"){
                                setNbrRTT(nbrRTT + parseInt(abs.nombreJour_demandeAbsence))
                            }else{
    
                                if(abs.rubriqueAbsence_demandeAbsence === "Absence Maladie"){
                                    setNbrMaladie(nbrMaladie + parseInt(abs.nombreJour_demandeAbsence))
                                }
        
                            }
    
                        }

                    }

                    




                    //             <option value="Absence Maladie">Absence Maladie</option>
                    //             <option value="Congé maternité">Congé maternité</option>
                    //             <option value="Congé paternité">Congé paternité</option>
                    //             <option value="Congé mariage">Congé mariage</option>
                }
            )

            listHistoriqueAbsence.filter(resp => resp.action_demandeAbsence === null).map(
                abs => { 
                    listDateDebutAttente.push(abs.rubriqueDateDebut_demandeAbsence);
                    listDateFinAttente.push(abs.rubriqueDateFin_demandeAbsence);
                }
            )

            setListDateDebutValiderResult(listDateDebutValider)
            setListDateFinValiderResult(listDateFinValider)
            setListDateDebutAttenteResult(listDateDebutAttente)
            setListDateFinAttenteResult(listDateFinAttente)

        }
    }, [listHistoriqueAbsence, currentYear])

    function getVerification(listDebut, listFin, dateActuelle){
        let etat = false;

        for (let index = 0; index < listDebut.length; index++) {

            
            if(new Date(listDebut[index]).getTime() <= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime() && new Date(listFin[index]).getTime() >= new Date(moment(new Date(dateActuelle)).format("YYYY-MM-DD")).getTime()){
                etat = true;
                break; 
            }
            
        }

        return etat;
    }

    
    useEffect(() => {
        if(token['mytoken'] && matriculeTargetDeclaration){
            APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_employe === matriculeTargetDeclaration && (resp.action_demandeAbsence === true || resp.action_demandeAbsence === null))))



            setNbrCP(0)
            setNbrRTT(0)
            setNbrMaladie(0)


        
        
        
        }
    }, [matriculeTargetDeclaration])



    useEffect(() => {
        if(rechercheValidation && matriculeTargetDeclaration){
            setNbrCP(0)
            setNbrRTT(0)
            setNbrMaladie(0)

            if(rechercheValidation === "valider"){
                APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_employe === matriculeTargetDeclaration && resp.action_demandeAbsence === true)))
            }else{
                if(rechercheValidation === "attente"){
                    APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_employe === matriculeTargetDeclaration && resp.action_demandeAbsence === null)))
                }else{
                    if(rechercheValidation === "all"){
                        APIService.getDemandeAbsence(token).then(resp => setListHistoriqueAbsence(resp.filter(resp => resp.matricule_employe === matriculeTargetDeclaration && (resp.action_demandeAbsence === true || resp.action_demandeAbsence === null))))
                    }
                }
            }
        }
      
    }, [rechercheValidation])


    
    


    
    for(let i=1; i<=31; i++){
        listBodyTable.push(<tr>{listBody[i]}</tr>)
    }
    
    for(let i=1; i<=12; i++){

        listHeader.push(<th>{listMois[i]}</th>)

        numberDaysMois[i] = new Date(currentYear, i, 0).getDate()
    }
   
    
   

    for(let i=1; i<=31; i++){
        for(let j=1; j<=12; j++){

            if(i <=  numberDaysMois[j]){
                
                if(new Date(`${currentYear}-${j}-${i}`).getDay() === 0 || new Date(`${currentYear}-${j}-${i}`).getDay() === 6){
                    listBody[i].push(<td id={styles.idWeekEnd}>{i} {listJours[new Date(`${currentYear}-${j}-${i}`).getDay()]}</td>)
                }else{

                    if(listDateDebutValiderResult && listDateFinValiderResult && listDateDebutAttenteResult && listDateFinAttenteResult){
                        if(getVerification(listDateDebutValiderResult, listDateFinValiderResult, `${currentYear}-${j}-${i}`)){
                                                listBody[i].push(<td id={styles.idJourAbsenceValider}>{i} {listJours[new Date(`${currentYear}-${j}-${i}`).getDay()]}</td>)
                           }else{

                                    if(getVerification(listDateDebutAttenteResult, listDateFinAttenteResult, `${currentYear}-${j}-${i}`)){
                                            listBody[i].push(<td id={styles.idJourAbsenceNull}>{i} {listJours[new Date(`${currentYear}-${j}-${i}`).getDay()]}</td>)
                                        }else{
                                            listBody[i].push(<td id={("id"+new Date(`${currentYear}-${j}-${i}`)).toString()} 

                                            onMouseEnter={() => {
                                                                    let tdBalise = document.getElementById(("id"+new Date(`${currentYear}-${j}-${i}`)).toString());
                                                                    tdBalise.style.backgroundColor="rgb(0, 128, 0,30%)";
                                                                    tdBalise.style.color="white";

                                                                    }} 
                                            onMouseLeave={() => {
                                                                    let tdBalise = document.getElementById(("id"+new Date(`${currentYear}-${j}-${i}`)).toString());
                                                                    tdBalise.style.backgroundColor="";
                                                                    tdBalise.style.color="grey";
                                            }} 

                                            onClick={() => {setEtatValider(true)}}>{i} {listJours[new Date(`${currentYear}-${j}-${i}`).getDay()]}</td>)

                                        }

                           }
                    }
                    
                    
                }
                
            }else{
                listBody[i].push(<td></td>)
            }

        }
    }



    // Fin calendrier



    
   
    

    

    useEffect(() => {
        
        if(demandeAbsenceFichiers && demandeAbsence){

            for(let v in demandeAbsenceFichiers){
                
                if(demandeAbsenceFichiers[v]["matricule_employe"] === demandeAbsence.matricule_employe &&
                demandeAbsenceFichiers[v]["matricule_manager"] === demandeAbsence.matricule_manager &&
                demandeAbsenceFichiers[v]["rubriqueAbsence_demandeAbsence"] === demandeAbsence.rubriqueAbsence_demandeAbsence &&
                demandeAbsenceFichiers[v]["rubriqueDateDebut_demandeAbsence"] === demandeAbsence.rubriqueDateDebut_demandeAbsence &&
                demandeAbsenceFichiers[v]["rubriqueDateFin_demandeAbsence"] === demandeAbsence.rubriqueDateFin_demandeAbsence ){

                            setIdFichier(demandeAbsenceFichiers[v]["id"])
                            setPieceJustificativeName(String(demandeAbsenceFichiers[v]["pieceJustificative_demandeAbsence"]).split('/').slice(-1)[0])
                            setPieceJustificative(String(demandeAbsenceFichiers[v]["pieceJustificative_demandeAbsence"]))

                            
                        }

            }

        }
      
    }, [demandeAbsenceFichiers])


    

    useEffect(() => {
        if(demandeAbsence){
            
            APIService.getDemandeAbsenceFichiers(token).then(resp => setDemandeAbsenceFichiers(resp))
        }
    }, [demandeAbsence])

    useEffect(() => {
      if(token["idAbsence"]){
        setEtatValider(true)
        APIService.getDemandeAbsenceById(token).then(resp => setDemandeAbsence(resp))

        
      }
    }, [])
    


    useEffect(() => {
        if(matriculeTargetDeclaration && employes){

            employes.filter(resp => resp.matricule_employe === matriculeTargetDeclaration && resp.state_employe === true).map(resp => {
                setNomEmploye(resp.nom_employe);
                setPrenomEmploye(resp.prenom_employe);
            })
            

        }
      
    }, [matriculeTargetDeclaration])
    

    

    useEffect(() => {
        var diffTime;
        var diffDays;
        if(rubriqueDateDebut && rubriqueDateFin){

            diffTime = Math.abs(new Date(rubriqueDateFin) - new Date(rubriqueDateDebut));
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

            setNombreJour(diffDays + 1);
        }
      
    }, [rubriqueDateDebut, rubriqueDateFin])
    
    
    useEffect(() => {

        if(token['mytoken']){
        APIService.getEmployes(token)
        .then(resp => setEmployes(resp))
        .catch(error => console.log(error))

        APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));
        
        }

    }, [token])



    useEffect(() => {
        if(role && token['matriculeActuel']){
          if(role === "Salarie"){
            
    
            setMatriculeTargetDeclaration(token["matriculeActuel"]);

            if(document.getElementById("rechercheBar")){
                let elem = document.getElementById("rechercheBar");
                elem.parentNode.removeChild(elem);
              }
    
            if(document.getElementById("listeTaches")){
                let elem = document.getElementById("listeTaches");
                elem.parentNode.removeChild(elem);
              }
            if(document.getElementById("historiqueAbsence")){
                let elem = document.getElementById("historiqueAbsence");
                elem.parentNode.removeChild(elem);
              }
            if(document.getElementById("historiqueActivite")){
              let elem = document.getElementById("historiqueActivite");
              elem.parentNode.removeChild(elem);
            }
            if(document.getElementById("dashboard")){
              let elem = document.getElementById("dashboard");
              elem.parentNode.removeChild(elem);
            }
          }
        }
      }, [role, token])

    useEffect(() => {
        if(employes){

          // console.log("emp : ", employes)
          setEmployesList(employes.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.stateOccur_employe === true))
        }
      }, [employes])


    useEffect(() => {
        if(employes && role){
          if(role === "Manager"){
            employes.filter(resp => resp.matricule_employe === token['matriculeManager'] && resp.state_employe === true).map(resp => setNomDeclaration(resp.nom_employe))
            employes.filter(resp => resp.matricule_employe === token['matriculeManager'] && resp.state_employe === true).map(resp => setPrenomDeclaration(resp.prenom_employe))
          } else {
    
            if(role === "Salarie"){
              employes.filter(resp => resp.matricule_employe === token['matriculeActuel'] && resp.state_employe === true).map(resp => setNomDeclaration(resp.nom_employe))
              employes.filter(resp => resp.matricule_employe === token['matriculeActuel'] && resp.state_employe === true).map(resp => setPrenomDeclaration(resp.prenom_employe))
            } 
    
          }
        }
      }, [employes, role])

    useEffect(() => {

        if(inputMatriculeRecherche || inputPrenomRecherche || inputNomRecherche){

          // console.log("Ok ok : ", inputMatriculeRecherche)

          // console.log("employesList : ", employesList)
          
          setEmployesListResult(employesList.filter(
            resp => 
            resp.matricule_employe.toLowerCase().includes(inputMatriculeRecherche.toLowerCase()) 
            && resp.nom_employe.toLowerCase().includes(inputPrenomRecherche.toLowerCase()) 
            && resp.prenom_employe.toLowerCase().includes(inputNomRecherche.toLowerCase())

          
          ))
        }
        
      }, [inputMatriculeRecherche, inputPrenomRecherche, inputNomRecherche])

      useEffect(() => {
        if(inputMatriculeRecherche){
          setEtatMatriculeRecherche(true)
          setEtatPrenomRecherche(false)
          setEtatNomRecherche(false)
        }
        
      }, [inputMatriculeRecherche])
    
      useEffect(() => {
        if(inputPrenomRecherche){
          setEtatPrenomRecherche(true)
          setEtatMatriculeRecherche(false)
          setEtatNomRecherche(false)

        }
        
      }, [inputPrenomRecherche])
      useEffect(() => {
        if(inputNomRecherche){
          setEtatNomRecherche(true)
          setEtatMatriculeRecherche(false)
          setEtatPrenomRecherche(false)
    
        }
        
      }, [inputNomRecherche])

    const btnValider = () => {
        var data = {matricule_employe:"", nom_employe:"", prenom_employe:"", rubriqueAbsence_demandeAbsence:"", rubriqueDateDebut_demandeAbsence:"", rubriqueDateFin_demandeAbsence:"", nombreJour_demandeAbsence:""
                , commentaire_demandeAbsence:"", choixJourneeDebut_demandeAbsence:"", choixJourneeFin_demandeAbsence:"",
                nom_demandeAbsence:"", prenom_demandeAbsence:"", matricule_manager:"",
                dateDemande_demandeAbsence:"",
                action_demandeAbsence:null, 
                commentaireValidation_demandeAbsence:null, nomValidation_demandeAbsence:null, prenomValidation_demandeAbsence:null, 
                dateValidation_demandeAbsence:null}
        var dataFichiers = {matricule_employe:"", matricule_manager:"" , rubriqueAbsence_demandeAbsence:"", rubriqueDateDebut_demandeAbsence:"", rubriqueDateFin_demandeAbsence:"", 
                pieceJustificative_demandeAbsence:""
                }
        if(rubriqueAbsence && rubriqueDateDebut && rubriqueDateFin && nombreJour && commentaire && choixJourneeDebut && choixJourneeFin && pieceJustificative){
            

            if(matriculeTargetDeclaration){
                data["matricule_employe"]=matriculeTargetDeclaration;
                dataFichiers["matricule_employe"]=matriculeTargetDeclaration;

              }else{
                data["matricule_employe"] = token['matriculeActuel'];
                dataFichiers["matricule_employe"] = token['matriculeActuel'];

              }

            data["nom_employe"] = nomEmploye
            data["prenom_employe"] = prenomEmploye
            data["rubriqueAbsence_demandeAbsence"] = rubriqueAbsence
            data["rubriqueDateDebut_demandeAbsence"] = rubriqueDateDebut
            data["rubriqueDateFin_demandeAbsence"] = rubriqueDateFin
            data["nombreJour_demandeAbsence"] = nombreJour
            data["commentaire_demandeAbsence"] = commentaire
            data["choixJourneeDebut_demandeAbsence"] = choixJourneeDebut
            data["choixJourneeFin_demandeAbsence"] = choixJourneeFin
            data["nom_demandeAbsence"] = nomDeclaration;
            data["prenom_demandeAbsence"] = prenomDeclaration;
            data["dateDemande_demandeAbsence"] =moment().format("YYYY-MM-DD");
            data["matricule_manager"] = token['matriculeManager']; //Cas 1: En tant que manager.

            dataFichiers["rubriqueAbsence_demandeAbsence"] = rubriqueAbsence
            dataFichiers["rubriqueDateDebut_demandeAbsence"] = rubriqueDateDebut
            dataFichiers["rubriqueDateFin_demandeAbsence"] = rubriqueDateFin
            dataFichiers["matricule_manager"] = token['matriculeManager']; //Cas 1: En tant que manager.
            dataFichiers["pieceJustificative_demandeAbsence"] = pieceJustificative
            
            if(modifierEtat === true){
                console.log("modifier", typeof(pieceJustificative))


                APIService.ModifierDemandeAbsence(idDemande, data, token);
                if(typeof(pieceJustificative) === "object"){
                    APIService.ModifierDemandeAbsenceFichiers(idFichier, dataFichiers, token);
                }

            } else {

                
    
    
    
                APIService.InsererDemandeAbsence(data, token);
                APIService.InsererDemandeAbsenceFichiers(dataFichiers, token);
    
            }

        }else{
            console.log("Tous les champs sont obligatoires !");

        }
    }


    const [showPopUp, setShowPopUp] = useState(true);
    const [showPopUpPrenom, setShowPopUpPrenom] = useState(true);
    const [showPopUpNom, setShowPopUpNom] = useState(true)



  return (
    <div className={styles.classCard} onClick={() => setShowPopUp(false)}>
         <div className={styles.generalContainer}>
             <div className={styles.firstPart}>
             <TopMenuGestionDuTemps page="demandeAbsence"/>
             </div>
             <div className={styles.secondPart}>
             <div className={styles.diviser}>
             {
                role ? 
                <div className={styles.container}>
                  <div id="idMatriculeRecherche" className={styles.inputgroup}>
                    <span htmlFor="" className={styles.label}>Matricule</span>
                    <input className={styles.test} type="text" placeholder="Rechercher" value={inputMatriculeRecherche} onChange={e => {setInputMatriculeRecherche(e.target.value); setShowPopUp(true)}}/>
                  </div>
                  <div id="idNomPrenomRecherche" className={styles.inputgroup}>
                    <span htmlFor="" className={styles.label}>Prénom</span>
                    <input className={styles.test} type="text" placeholder="Rechercher" value={inputPrenomRecherche} onChange={e => {setInputPrenomRecherche(e.target.value); setShowPopUp(true)}}/>
                  </div>
                  <div id="idNomPrenomRecherche" className={styles.inputgroup}>
                    <span htmlFor="" className={styles.label}>Nom</span>
                    <input className={styles.test} type="text" placeholder="Rechercher" value={inputNomRecherche} onChange={e => {setInputNomRecherche(e.target.value); setShowPopUp(true)}}/>
                  </div>
                  <div className={styles.boutton}> 
                    <span htmlFor="" className={styles.label}>
                    <button className={styles.idBtnIcon} onClick={() => {setToken("idAbsence", ""); setModifierEtat(false); window.location.href = '/demandeAbsence'; }}>
                     <i class="fas fa-sync-alt"></i>
                    </button>
                    </span>
                  </div>
                  {/* ********************Mobile  ********************************************* */}
                  <div className={styles.group_}>
                  <div className={styles.labelMobile_}>Matricule</div>
                  <input className={styles.inputMobile_} type="text" value={inputMatriculeRecherche} onChange={e => {setInputMatriculeRecherche(e.target.value);setShowPopUp(true)}}  required/>
                  <span className={styles.highlight_}></span>
                  <span className={styles.bar_}></span>
                </div>
                <div className={styles.group_}>
                  <div className={styles.labelMobile_}>Prénom</div>
                  <input className={styles.inputMobile_} type="text"  value={inputPrenomRecherche} onChange={e => {setInputPrenomRecherche(e.target.value);setShowPopUp(true)}} required/>
                  <span className={styles.highlight_}></span>
                  <span className={styles.bar_}></span>
                </div>
                <div className={styles.group_}>
                  <div className={styles.labelMobile_}>Nom</div>
                  <input className={styles.inputMobile_} type="text" value={inputNomRecherche} onChange={e => {setInputNomRecherche(e.target.value);setShowPopUp(true)}} required/>
                  <span className={styles.highlight_}></span>
                  <span className={styles.bar_}></span>
                </div>
                <div className={styles.group_}>
                  <div className={styles.labelMobile_}>&nbsp;</div>
                    <button className={styles.idBtnIcon} onClick={() => {setToken("idAbsence", ""); setModifierEtat(false); window.location.href = '/demandeAbsence'; }}>
                    <i class="fas fa-sync-alt"></i>
                    </button>
                    <span className={styles.highlight_}></span>
                  <span className={styles.bar_}></span>
                </div>
                </div>
                
            :<></>
            }
            <div className={styles.containerData}>
                    <div className={styles.matriculelist}>
                    {employesListResult && inputMatriculeRecherche && etatMatriculeRecherche && showPopUp   ? 
                      <div id={styles.cardRechercheMatriculePopUp}>
                        
                          {employesListResult.map(emp => 
                              <span>
                            <div onClick={ () => {setMatriculeTargetDeclaration(emp.matricule_employe); setShow(true);}}  id={styles.rechercheBtn}>{emp.matricule_employe} : {emp.nom_employe}, {emp.prenom_employe}</div>
                              </span>
                              )}
                      </div>
                      :<span></span>}
                      </div>
                      <div className={styles.Prenomlist}>
                      {employesListResult && inputPrenomRecherche && etatPrenomRecherche && showPopUp ? 
              
                        <div id={styles.cardRechercheMatriculePopUp}>
                            {employesListResult.map(emp => 
                              <span>
                      <div onClick={ () => { setMatriculeTargetDeclaration(emp.matricule_employe); setShow(true);}} id={styles.rechercheBtn}>{emp.matricule_employe} : {emp.nom_employe}, {emp.prenom_employe}</div>
                              </span>
                              )}
                        </div>
                        :<span></span>}
                      </div>
                      <div className={styles.Nomlist}>
                      {employesListResult && inputNomRecherche && etatNomRecherche && showPopUp ? 
              
                        <div id={styles.cardRechercheMatriculePopUp}>
                            {employesListResult.map(emp => 
                              <span>
                      <div onClick={ () => { setMatriculeTargetDeclaration(emp.matricule_employe); setShow(true);}} id={styles.rechercheBtn}>{emp.matricule_employe} : {emp.nom_employe}, {emp.prenom_employe}</div>
                              </span>
                              )}
                        </div>
                        :<span></span>}
                      </div>
                      <div></div>
                      <div>
                      </div>
                </div>
                </div>
             </div>
             <div className={styles.thirdPart}>
        {etatValider === true ? 
            <>
            {/* la troisieme partie de la 2 eme page qui contient le
             formulaire d'abscence */}
            <div className={styles.formContainer}>
                <div className={styles.formContainerFirst}>
                    <div className={styles.titleFirstSecondPage}>
                    Rubrique des demandes
                    </div>
                    <div className={styles.titleSecondSecondPage}>
                    Info. Supplémentaire
                    </div>
                </div>
                <div className={styles.formContainerSecond}>
                    <div className={styles.firstSecondPage}>
          <div className={styles.container1}>
          <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Type d'absence
            </div>
            <select className={styles.goldenInput1} value={rubriqueAbsence} onChange={e => setRubriqueAbsence(e.target.value)} id="choix">
                                        <option></option>
                                        {/* <option value="Demande CP">Demande CP</option>
                                        <option value="Demande RTT">Demande RTT</option>
                                        <option value="Absence Maladie">Absence Maladie</option>
                                        <option value="Congé maternité">Congé maternité</option>
                                        <option value="Congé paternité">Congé paternité</option>
                                        <option value="Congé mariage">Congé mariage</option> */}
                                         {listDemandeAbsenceAdministration &&
                                        listDemandeAbsenceAdministration.map(
                                          demandeAbsenceAdministration => <option value={demandeAbsenceAdministration.id}>{demandeAbsenceAdministration.nom}</option>
                                        )
                                        }
                                    </select>
          </div>
        </div>
                    <div className={styles.container1}>
          <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Salarié Ciblé
            </div>
                    <div className={styles.goldenInput1}>
              <span id={styles.inputNombreheure}>
                {employesList && matriculeTargetDeclaration ? (
                  employesList
                    .filter(resp => resp.matricule_employe === matriculeTargetDeclaration)
                    .map(emp => (
                      <span id={styles.textesalarie}>
                        {emp.matricule_employe} - {emp.nom_employe} {emp.prenom_employe}
                      </span>
                    ))
                ): (
                  <span> --- </span>
                )}
              </span>
            </div>
          </div>
        </div>
          <div className={styles.modern_container}>
            <div className={styles.modern_inputs}>
            <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Du
            </div>
            <input type="date"  className={styles.goldenInput1} value={rubriqueDateDebut} onChange={e => setRubriqueDateDebut(e.target.value)}/>
            </div>
                   <div className={styles.radio}>
	<input label="Début de journée" className={styles.left} type="radio" id="jourDebut" name="OK" value="male" onChange={() => setChoixJourneeDebut("Début de journée")} />
	<input label="Mi-journée" className={styles.right} type="radio" d="jourFin" name="OK" value="female" onChange={() => setChoixJourneeDebut("Mi-journée")}/>
</div>
            </div>
          </div>
          <div className={styles.modern_container}>
            <div className={styles.modern_inputs}>
            <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
           Au
            </div>
            <input type="date"  className={styles.goldenInput1} value={rubriqueDateFin} onChange={e => setRubriqueDateFin(e.target.value)}/>
            </div>
                  <div className={styles.radio}>
	<input label="Mi-journée" className={styles.left} type="radio" id="jourDebut2" name="Ko" onChange={() => setChoixJourneeFin("Mi-journée")}/>
	<input label="Fin de journée" className={styles.right} type="radio" id="jourFin2" name="Ko" onChange={() => setChoixJourneeFin("Fin de journée")} />
</div>
            </div>
          </div>
          <div className={styles.container1}>
          <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Nombre de jours
            </div>
            <input type="text" className={styles.goldenInput1}  value={nombreJour} disabled/>
          </div>
        </div>
      </div>

                    <div className={styles.secondSecondPage}>
                         
                                    <div id={styles.idDivCmnt}>
                                       <div className={styles.texte}> Pièce justificatif : </div>
                                       <div className={styles.container1_}>
                                          <div className={styles.zoneInput_}>
                                            <label htmlFor="file-input" className={styles.fileInputLabel} title="Veuillez téléverser le fichier : la pièce justificative . ">
                                            <i class="fa-solid fa-arrow-up-from-bracket fa-lg" style={{color: "#ffffff", textAlign:"center",alignItems:"center"}}></i>
                                            </label>
                                            <input className={styles.goldenInput1_} id="file-input"  type="file" accept="image/png, image/jpeg, image/jpg, application/pdf" onChange={e => {setPieceJustificative(e.target.files[0]); setPieceJustificativeName();}} />
                                            {
                                            pieceJustificativeName && pieceJustificative ?
                                            <div  id={styles.idFileLink}><a href={pieceJustificative} target="_blank" rel="noopener noreferrer" >{pieceJustificative.substring(pieceJustificative.lastIndexOf('/')+1)}</a></div>:
                                            <></>
                                        }  
                                          </div>
                                      </div>
                                    </div>
                                    <div id={styles.idDivCmnt}>
                                       <div className={styles.texte}> Commentaires : </div>
                                        <textarea className={styles.inputGlobal} rows="2" style={{color:"black", width:"96%", minHeight: "50px",resize: "vertical",marginLeft:"2%",marginRight:"2%"}} value={commentaire} onChange={handleCommentaireChange} id="comnt" />
                                    </div>

                                    <div id={styles.idDivBtnValider}>
                                        <button className={styles.BtnValider} onClick={btnValider} id="btnValider">Valider</button>
                                    </div>
                        
                    </div>
                </div>
            </div>


            </>
             :
            <>
                { matriculeTargetDeclaration ?
                    <> 
         <div className={styles.formSecondContainer}>
                <div className={styles.formSecondContainerFirst}>
                    <div className={styles.titleFirstSecondPage_}>
                        <div>

                            <span>
                                <span style={{textAlign:"right"}}>
                                    <button className={styles.idBtnIcon} onClick={() => {setCurrentYear(currentYear - 1);
                                    setNbrCP(0);
                                    setNbrRTT(0);
                                    setNbrMaladie(0);
                                    }}  style={{padding:"0vw", lineHeight: "100%"}}>
                                        <i id={styles.arrows} className={styles.arrowLeft_}  class="fa fa-arrow-circle-left" ></i>

                                    </button>

                                </span>
                                <span style={{padding:"0vw"}}>
                                    <b className={styles.texteAnnee} >{currentYear ? <>1/{currentYear} - 12/{currentYear}</>:<></>}</b>
                                </span>
                                <span style={{textAlign:"left"}}>
                                    <button className={`${styles.idBtnIcon} ${styles.additionalClass}`} onClick={() => {setCurrentYear(currentYear + 1);setNbrCP(0);setNbrRTT(0);setNbrMaladie(0);}}
                                    style={{padding:"0vw", lineHeight: "100%"}}>
                                        <i id={styles.arrows} class="fa fa-arrow-circle-right"></i>
                                    </button>
                                </span>
                            </span>
                            
                        </div>

                    </div>
                    <div className={styles.titleSecondSecondPage_}>
                      <div style={{textAlign:"left"}}>
                        
                        <select className={styles.inputGlobal_} onChange={e => setRechercheValidation(e.target.value)}>
                            <option value="all" default>Tous</option>
                            <option value="valider">Valider</option>
                            <option value="attente">En attente</option>
                        </select>
                
                     </div>
                    </div>
                </div>
                <div className={styles.formSecondContainerSecond}>
                    <div className={styles.firstSecondPage_}>
                         <div id={styles.idDivTableCal}>
                    <div id={styles.idTestTable}>
                
                        <table className={styles.table}>
                        <thead>
                            <tr id={styles.idForStyleTh}>
                            {listHeader}
                            </tr>
                            </thead>

                            <tbody>
                            {listBodyTable}
                            </tbody>
                            
                            
                        </table>
                    </div>
                    </div>
                    </div>
                    <div className={styles.secondSecondPage_}>
                    <div id={styles.idDivTableStat}>
                    <div id={styles.idSalarieCible}>
              Salarié cible : 

        {employesList && matriculeTargetDeclaration ?
        employesList.filter(resp => resp.matricule_employe === matriculeTargetDeclaration).map(
          emp => 
              <span id={styles.textesalarie_} style={{color:"rgb(69, 69, 69)", fontWeight:"normal"}}> {emp.matricule_employe} - {emp.nom_employe} {emp.prenom_employe}</span>
              
        )

        : <span  style={{color:"rgb(69, 69, 69)", fontWeight:"normal"}}> --- </span>

        }
        </div>
                    <div className={styles.AnneeStyle_} style={{textAlign:"center", backgroundColor:"rgb(177, 177, 177)", color:"rgb(69, 69, 69)"}}>Année - {currentYear}</div>

                    <table className={styles.table} id={styles.idTableStat}>
                                        <tr>
                                            <th>Compte prévisionnel</th>
                                            <th>Nbre de jour</th>
                                        </tr>
                                        <tr>
                                            <td>CP</td>
                                            <td>{18 - nbrCP}</td>
                                        </tr>
                                        <tr>
                                            <td>RTT</td>
                                            <td>{11 - nbrRTT}</td>
                                        </tr>

                                        <tr id={styles.idTitleTable}>
                                            <th>Compte actuel </th>
                                        </tr>
                                        <tr>
                                            <td>CP</td>
                                            <td>{nbrCP}</td>
                                        </tr>
                                        <tr>
                                            <td>RTT</td>
                                            <td>{nbrRTT}</td>
                                        </tr>
                                        <tr>
                                            <td>Maladie</td>
                                            <td>{nbrMaladie}</td>
                                        </tr>
                                    </table>
                                    </div>
                    </div>
                </div>
         </div>
                     </>
                    :<></>}
            </>
        }
             </div>
             <div className={styles.thirdPartMobile}>
        {etatValider === true ? 
            <>
            {/* la troisieme partie de la 2 eme page qui contient le
             formulaire d'abscence */}
            <div className={styles.secondPageMobile}>
              <div className={styles.firstPartSecondMobile}>
              Rubrique des demandes
              </div>
              <div className={styles.secondPartSecondMobile}>
              <div className={styles.firstSecondPage}>
          <div className={styles.container1}>
          <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Type d'absence
            </div>
            <select className={styles.goldenInput1} value={rubriqueAbsence} onChange={e => setRubriqueAbsence(e.target.value)} id="choix">
                                        <option></option>
                                        {/* <option value="Demande CP">Demande CP</option>
                                        <option value="Demande RTT">Demande RTT</option>
                                        <option value="Absence Maladie">Absence Maladie</option>
                                        <option value="Congé maternité">Congé maternité</option>
                                        <option value="Congé paternité">Congé paternité</option>
                                        <option value="Congé mariage">Congé mariage</option> */}
                                          {listDemandeAbsenceAdministration &&
                                        listDemandeAbsenceAdministration.map(
                                          demandeAbsenceAdministration => <option value={demandeAbsenceAdministration.id}>{demandeAbsenceAdministration.nom}</option>
                                        )
                                        }
                                    </select>
          </div>
        </div>
                    <div className={styles.container1}>
          <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Salarié Ciblé
            </div>
                    <div className={styles.goldenInput1}>
              <span id={styles.inputNombreheure}>
                {employesList && matriculeTargetDeclaration ? (
                  employesList
                    .filter(resp => resp.matricule_employe === matriculeTargetDeclaration)
                    .map(emp => (
                      <span id={styles.textesalarie}>
                        {emp.matricule_employe} - {emp.nom_employe} {emp.prenom_employe}
                      </span>
                    ))
                ): (
                  <span> --- </span>
                )}
              </span>
            </div>
          </div>
        </div>
          <div className={styles.modern_container}>
            <div className={styles.modern_inputs}>
            <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Du
            </div>
            <input type="date"  className={styles.goldenInput1} value={rubriqueDateDebut} onChange={e => setRubriqueDateDebut(e.target.value)}/>
            </div>
                   <div className={styles.radio}>
	<input label="Début de journée" className={styles.left} type="radio" id="jourDebut" name="OK" value="male" onChange={() => setChoixJourneeDebut("Début de journée")} />
	<input label="Mi-journée" className={styles.right} type="radio" d="jourFin" name="OK" value="female" onChange={() => setChoixJourneeDebut("Mi-journée")}/>
</div>
            </div>
          </div>
          <div className={styles.modern_container}>
            <div className={styles.modern_inputs}>
            <div className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
           Au
            </div>
            <input type="date"  className={styles.goldenInput1} value={rubriqueDateFin} onChange={e => setRubriqueDateFin(e.target.value)}/>
            </div>
                  <div className={styles.radio}>
	<input label="Mi-journée" className={styles.left} type="radio" id="jourDebut2" name="Ko" onChange={() => setChoixJourneeFin("Mi-journée")}/>
	<input label="Fin de journée" className={styles.right} type="radio" id="jourFin2" name="Ko" onChange={() => setChoixJourneeFin("Fin de journée")} />
</div>
            </div>
          </div>
          <div className={styles.container1}>
          <div id={styles.nbreJourMargin}  className={styles.zoneInput}>
            <div className={styles.fileNameTxtStyle1}>
              Nombre de jours
            </div>
            <input  type="text" className={styles.goldenInput1}  value={nombreJour} disabled/>
          </div>
        </div>
      </div>
              </div>
              <div className={styles.thirdPartSecondMobile}>
              Info. Supplémentaire
              </div>
              <div className={styles.lastPartSecondMobile}>
              <div id={styles.idDivCmnt}>
                                       <div className={styles.texte}> Pièce justificatif : </div>
                                       <div className={styles.container1_}>
                                          <div className={styles.zoneInput_}>
                                            <label htmlFor="file-input" className={styles.fileInputLabel} title="Veuillez téléverser le fichier : la pièce justificative . ">
                                            <i class="fa-solid fa-arrow-up-from-bracket fa-lg" style={{color: "#ffffff", textAlign:"center",alignItems:"center"}}></i>
                                            </label>
                                            <input className={styles.goldenInput1_} id="file-input"  type="file" accept="image/png, image/jpeg, image/jpg, application/pdf" onChange={e => {setPieceJustificative(e.target.files[0]); setPieceJustificativeName();}} />
                                            {
                                            pieceJustificativeName && pieceJustificative ?
                                            <div  id={styles.idFileLink}><a href={pieceJustificative} target="_blank" rel="noopener noreferrer" >{pieceJustificative.substring(pieceJustificative.lastIndexOf('/')+1)}</a></div>:
                                            <></>
                                        }  
                                          </div>
                                      </div>
                                    </div>
                         <div id={styles.idDivCmnt}>
                                       <div className={styles.texte}> Commentaires : </div>
                                        <textarea className={styles.inputGlobal} rows="2" style={{color:"black", width:"96%",minHeight: "50px",resize: "vertical",marginLeft:"2%",marginRight:"2%"}} value={commentaire} onChange={handleCommentaireChange} id="comnt" />
                          </div>
                          
                                    <div id={styles.idDivBtnValider}>
                                        <button className={styles.BtnValider} onClick={btnValider} id="btnValider">Valider</button>
                                    </div>
              </div>

            </div>


            </>
             :
            <>
                { matriculeTargetDeclaration ?
                    <> 
                        <div className={styles.firstPageMobile}>
                          <div className={styles.firstPartMobile}>
                            <div className={styles.barre}>
                              <div className={styles.firstBarre}>
                                  <span >
                                      <button className={styles.idBtnIcon} onClick={() => {setCurrentYear(currentYear - 1);
                                      setNbrCP(0);
                                      setNbrRTT(0);
                                      setNbrMaladie(0);
                                      }} >
                                          <i id={styles.arrows} className={styles.arrowLeft} class="fa fa-arrow-circle-left" ></i>

                                      </button>

                                  </span>
                                  <span style={{padding:"0vw"}}>
                                      <b className={styles.texteAnnee} >{currentYear ? <>1/{currentYear} - 12/{currentYear}</>:<></>}</b>
                                  </span>
                                  <span >
                                      <button className={styles.idBtnIcon} onClick={() => {setCurrentYear(currentYear + 1);setNbrCP(0);setNbrRTT(0);setNbrMaladie(0);}}
                                      >
                                          <i id={styles.arrows} class="fa fa-arrow-circle-right"></i>
                                      </button>
                                  </span>
                                </div>
                                <div className={styles.secondBarre}>
                                    <select className={styles.inputGlobal_} onChange={e => setRechercheValidation(e.target.value)}>
                                        <option value="all" default>Tous</option>
                                        <option value="valider">Valider</option>
                                        <option value="attente">En attente</option>
                                    </select>
                                  </div>
                              </div>
                            
                          

                          </div>
                          {/* second part */}
                          <div className={styles.secondPartMobile}>
                                  <div className={styles.firstSecondPage_}>
                                <div id={styles.idDivTableCal}>
                            <div id={styles.idTestTable}>
                        
                                <table className={styles.table}>
                                <thead>
                                    <tr id={styles.idForStyleTh}>
                                    {listHeader}
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {listBodyTable}
                                    </tbody>
                                    
                                    
                                </table>
                            </div>
                            </div>
                            </div>
                          </div>
                          <div className={styles.thirdPartMobilePage}>
                          </div>
                          {/* last part */}
                          <div className={styles.lastPartMobile}>
                          <div id={styles.idDivTableStat}>
                                <div id={styles.idSalarieCible}>
                          Salarié cible : 

                    {employesList && matriculeTargetDeclaration ?
                    employesList.filter(resp => resp.matricule_employe === matriculeTargetDeclaration).map(
                      emp => 
                          <span id={styles.salarieTexte} style={{color:"rgb(69, 69, 69)", fontWeight:"normal"}}> {emp.matricule_employe} - {emp.nom_employe} {emp.prenom_employe}</span>
                          
                    )

                    : <span  style={{color:"rgb(69, 69, 69)", fontWeight:"normal"}}> --- </span>

                    }
                    </div>
                            <div id={styles.AnneeStyle} style={{textAlign: "center", backgroundColor: "rgb(177, 177, 177)", color: "rgb(69, 69, 69)"}}>
                                Année - {currentYear}
                            </div>

                              <div className={styles.wrapper}>
                                <table className={styles.table} id={styles.idTableStat}>
                                  <thead>
                                    <tr>
                                      <th>Compte prévisionnel</th>
                                      <th>Nbre de jour</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className={styles.tableauGris}>CP</td>
                                      <td className={styles.tableauGris_}>{18 - nbrCP}</td>
                                    </tr>
                                    <tr>
                                      <td className={styles.tableauGris}>RTT</td>
                                      <td className={styles.tableauGris_}>{11 - nbrRTT}</td>
                                    </tr>
                                  </tbody>
                                </table>

                                <table className={styles.table} id={styles.idTableStat}>
                                  <thead>
                                    <tr>
                                      <th>Compte actuel</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className={styles.tableauGris}>CP</td>
                                      <td className={styles.tableauGris_}>{nbrCP}</td>
                                    </tr>
                                    <tr>
                                      <td className={styles.tableauGris}>RTT</td>
                                      <td className={styles.tableauGris_}>{nbrRTT}</td>
                                    </tr>
                                    <tr>
                                      <td className={styles.tableauGris}>Maladie</td>
                                      <td className={styles.tableauGris_}>{nbrMaladie}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                                    </div>
                          </div>

                          
                        </div>
                     </>
                    :<></>}
            </>
        }
             </div>
        </div>
    </div>
  )
}

export default DemandeAbsenceSection