import React from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import NavBarGlobal from "./../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";

import SalarieSection from './SalarieSection.jsx';



export const Salarie = () => {
  return (
  <div>

    <NavBarGlobal section = {<SalarieSection />} />
   
 </div>
)}
export default Salarie