import React from 'react';
import styles from './Send.module.css';

function Send(props) {
  return (
    <button style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}} id={styles.sendButton} onClick={() => props.sendData()}>
      <i class="fa-solid fa-paper-plane"></i>
    </button>
  )
}

export default Send