import react, {useEffect, useState} from "react";
import styles from "./PrimesSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";


function PrimesSection(){
    const today = new Date("2999-12-31").toISOString().slice(0, 10);
    const navigate = useNavigate();

    const date_today = new Date();
    const firstDay = new Date(date_today.getFullYear(), date_today.getMonth() , 1);
    const lastDay = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const [matriculePaie, setMatriculePaie] = useState();
    const [listPrimes, setListPrimes] = useState();
    const [listPrimesEtat, setListPrimesEtat] = useState([]);
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState(today);
    const [typePrime, setTypePrime] = useState();
    const [prime, setPrime] = useState();
    const [montant, setMontant] = useState();
    const [dateDebutDelimiter, setDateDebutDelimiter] = useState();
    const [dateFinDelimiter, setDateFinDelimiter] = useState();
    const [typePrimeDelimiter, setTypePrimeDelimiter] = useState();
    const [primeDelimiter, setPrimeDelimiter] = useState();
    const [montantDelimiter, setMontantDelimiter] = useState();
    const [idModifier, setIdModifier] = useState();
    const [idDelimiter, setIdDelimiter] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();
    const [isIconUp, setIsIconUp] = useState(false);

    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        } else {
            navigate('/paie')
        }
    }, [token['matriculePaie']])


    useEffect(() => {
        if(typePrime){
            if(typePrime === "Ponctuelle"){
                setDateDebut(moment(firstDay).format("yyyy-MM-DD"))
                setDateFin(moment(lastDay).format("yyyy-MM-DD"))
            } else {

                if(typePrime === "Permanente"){
                    setDateDebut(moment(firstDay).format("yyyy-MM-DD"))
                    setDateFin(today)
                } 

            }
        }
      
    }, [typePrime])
    

    
    useEffect(() => {
      if(matriculePaie){
        
        APIService.getPrimesPaie(matriculePaie, token).then(resp => setListPrimes(resp));
      }
    }, [matriculePaie, listPrimesEtat])



    useEffect(() => {

        if(JSON.stringify(listPrimesEtat) !== JSON.stringify(listPrimes)){
            setListPrimesEtat(listPrimes)
        }
    }, [listPrimes])
    


    const btnEnregistrer = () => {
        let body = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            typePrime : "",
            prime : "",
            montant : ""
        }

        let bodyDelimiter = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            typePrime : "",
            prime : "",
            montant : ""
        }

        if(dateDebut && (dateFin || today) && typePrime && prime && montant){
            body.matricule_employe = matriculePaie;
            body.matricule_manager = matriculePaie;
            body.dateDebut = dateDebut;
            if(dateFin) {
                body.dateFin = dateFin
            } else {
                body.dateFin = today
            }
            body.typePrime = typePrime;
            body.prime = prime;
            body.montant = montant;


            if(idModifier){

                APIService.putPrimesPaie(idModifier, body, token);
                setIdModifier();
              
            } else {
                if(idDelimiter){

                    bodyDelimiter.matricule_employe = matriculePaie;
                    bodyDelimiter.matricule_manager = matriculePaie;
                    bodyDelimiter.dateDebut = dateDebutDelimiter;
                    bodyDelimiter.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                    bodyDelimiter.typePrime = typePrimeDelimiter;
                    bodyDelimiter.prime = primeDelimiter;
                    bodyDelimiter.montant = montantDelimiter;

                   

                    APIService.putPrimesPaie(idDelimiter, bodyDelimiter, token);
                    APIService.postPrimesPaie(body, token);

                    setIdDelimiter();

                } else {
                    listPrimes.map(
                        resp => {
                            let bodyTemp = {
                                matricule_employe : "",
                                matricule_manager : "", 
                                dateDebut : "",
                                dateFin : "",
                                typePrime : "",
                                prime : "",
                                montant : ""
                            }


                            if(new Date(resp.dateFin) > new Date(dateDebut)){
                                bodyTemp.matricule_employe = resp.matricule_employe;
                                bodyTemp.matricule_manager = resp.matricule_manager;
                                bodyTemp.dateDebut = resp.dateDebut;
                                bodyTemp.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                                bodyTemp.typePrime = resp.typePrime;
                                bodyTemp.prime = resp.prime;
                                bodyTemp.montant = resp.montant;

                                APIService.putPrimesPaie(resp.id, bodyTemp, token);

                            }
                        }
                    )

                    APIService.postPrimesPaie(body, token);

                }
            }

            APIService.getPrimesPaie(matriculePaie, token).then(resp => {setListPrimes(resp); setListPrimesEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {


        APIService.deletePrimesPaie(idRem, token);
        APIService.getPrimesPaie(matriculePaie, token).then(resp => {setListPrimes(resp); setListPrimesEtat(resp);});

        setIdModifier();
        setIdDelimiter();
        
    }
    

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listPrimes.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setDateDebut(resp.dateDebut);
            setDateFin(resp.dateFin);
            setTypePrime(resp.typePrime);
            setPrime(resp.prime);
            setMontant(resp.montant);


            setIdDelimiter();

        })


    }

    
    

    const btnDelimiter = (e) => {

        if(!etatAjouter) {
            setEtatAjouter(true);
        }

        listPrimes.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdDelimiter(resp.id);
            setDateDebutDelimiter(resp.dateDebut);
            setDateFinDelimiter(resp.dateFin);
            setTypePrimeDelimiter(resp.typePrime);
            setPrimeDelimiter(resp.prime);
            setMontantDelimiter(resp.montant);


            setDateDebut("");
            setDateFin(resp.dateFin);
            setTypePrime(resp.typePrime);
            setPrime(resp.prime);
            setMontant(resp.montant);

            setIdModifier();


        })
    }


    const btnAjouter = () => {
        setIsIconUp(prevState => !prevState); 
        if(etatAjouter){
            setEtatAjouter(false);
        } else {
            setDateDebut("")
            setDateFin("")
            setTypePrime("")
            setPrime("")
            setMontant("")

            setEtatAjouter(true);
        }

        setIdModifier();
        setIdDelimiter();
    }
    const handleDeleteClick = (id) => {
        setIdSupprimerAlert(id);
      }
      
      const handleCancelClick = () => {
        setIdSupprimerAlert(null);
        setClickedRow(null);
      }
      
      const handleConfirmClick = () => {
        if (idSupprimerAlert !== null) {
          btnSupprimer(idSupprimerAlert);
          setIdSupprimerAlert(null);
        }
      }
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }

    return(
        <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart}>
            <TopMenuPaie page="primes"/>
            </div>
            <div className={styles.secondPart}>
                    <div className={styles.tableAndFormSection}>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    {
                                        idDelimiter ?
                                        <div className={styles.modifiableTexte2}>
                                            <i class="fa-solid fa-scissors"></i> &nbsp;
                                            Délimiter</div> :<></>
                                    }
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                     <div className={styles.modern_container}>
                                        <div className={styles.modern_inputs}>
                                        <span className={styles.modern_label}>
                                        Période
                                        </span>
                                        <div class={styles.modern_input_wrapper}>
                                            <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                                            <span className={styles.timeSeparator}>-</span>
                                            <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                        </div>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de prime
                                                </div>
                                                <select className={styles.goldenInput1} value={typePrime} onChange={e => setTypePrime(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Permanente">Permanente</option>
                                                                            <option value="Ponctuelle">Ponctuelle</option>
                                                                        </select>
                                            </div>
                                            </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Prime
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={prime} onChange={(e) => setPrime(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Prime</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listPrimes ? 
                                    
                                        listPrimes.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
                                                <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
                                                <td>{resp.prime}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>


                                                                &nbsp;

                                                                <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
                                                                <i class="fa-solid fa-scissors"></i>
                                                                    </button>

                                                        </>:<></>
                                                        }
                                                    </td>
                                            </tr>
                                        )
                                    
                                        :<></>
                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                            </div>
                    </div>
                    {/* **************** Mobile ******** */}
                    <div className={styles.tableAndFormSectionMobile}>
                        <div className={styles.tableSection}>
                                <div className={styles.tableZone} >
                                <table className={styles.classTable}>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Prime</th>
                                        <th>Montant</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listPrimes ? 
                                    
                                        listPrimes.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                        
                                            <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
                                                <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
                                                <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
                                                <td>{resp.prime}</td>
                                                <td>{resp.montant}</td>
                                                <td>
                                                    <button id={styles.trashButton} value={resp.id} onClick={ () => 
                                                        handleRowClick(resp.id)
                                                        }>
                                                            <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                            {idSupprimerAlert && idSupprimerAlert===resp.id ? (
                                                                <>
                                                                <div className={styles.overlay}></div>
                                                                <div className={styles.popup}>
                                                                    <div id={styles.supprimerTexte}>Supprimer</div>
                                                                    <br />
                                                                    <div className={styles.buttonsPopup}>
                                                                    <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
                                                                    <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                </>
                                                                ):<></>
                                                            }


                                                        {
                                                        moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
                                                        <>
                                                                &nbsp;
                                                                <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
                                                                 <i class="fa-solid fa-pencil"></i>
                                                                    </button>

                                                            
                                                                &nbsp;
                                                            
                                                                <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
                                                                <i class="fa-solid fa-scissors"></i>
                                                                    </button>
                                                                
                                                        </>:<></>
                                                        }
                                                    
                                                    


                                                    
                                                    </td>
                                            </tr>
                                        )
                                    
                                        
                                        :<></>

                                    }
                                    
                                </tbody>
                                </table>
                                </div>
                        </div>
                        <div className={styles.buttonAndFormSection}>
                            <div className={styles.buttonSection_}>
                                <button className={styles.btnAjouter} onClick = {btnAjouter}>
                                                        Ajouter une nouvelle ligne &nbsp; {' '}
                                                        {isIconUp ? (
                                                            <i className="fa-solid fa-caret-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down"></i>
                                                        )}
                                </button>
                            </div>
                            <div className={styles.formSection}>
                                <br />
                                {idModifier ? 
                                    <div className={styles.modifiableTexte2}>
                                        <i class="fa-solid fa-pencil"></i> &nbsp;
                                        Modifier</div>

                                    :
                                    <>
                                    {
                                        idDelimiter ?
                                        <div className={styles.modifiableTexte2}>
                                            <i class="fa-solid fa-scissors"></i> &nbsp;
                                            Délimiter</div> :<></>
                                    }
                                    
                                    </>
                                }
                                
                                {
                                    etatAjouter ? 
                                    <>
                                     <div className={styles.modern_container}>
                                        <div className={styles.modern_inputs}>
                                        <span className={styles.modern_label}>
                                        Période
                                        </span>
                                        <div class={styles.modern_input_wrapper}>
                                            <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
                                            <span className={styles.timeSeparator}>-</span>
                                            <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                        </div>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                            <div className={styles.zoneInput}>
                                                <div className={styles.fileNameTxtStyle1}>
                                                Type de prime
                                                </div>
                                                <select className={styles.goldenInput1} value={typePrime} onChange={e => setTypePrime(e.target.value)} id="choix">
                                                                            <option></option>
                                                                            <option value="Permanente">Permanente</option>
                                                                            <option value="Ponctuelle">Ponctuelle</option>
                                                                        </select>
                                            </div>
                                            </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Prime
                                            </div>
                                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1} value={prime} onChange={(e) => setPrime(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.container1}>
                                        <div className={styles.zoneInput}>
                                            <div className={styles.fileNameTxtStyle1}>
                                            Montant
                                            </div>
                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={montant} onChange={(e) => setMontant(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className={styles.btnSave}>
                                        <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
                                    </div>
                                     
                                     </>:<></>
                                    }
                                    

                            </div>

                        </div>
                            
                    </div>
            </div>
        </div>
    </div>
    )
}

export default PrimesSection;