import React from "react";
// import NavBarGlobal from "../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import NavBarGlobal from "../../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";

import StatutFormationSection from "./StatutFormationSection.jsx";

const Navbar = () => {
  return (
    <div>
    <NavBarGlobal section = {<StatutFormationSection />} />
    </div>
  );
};
export default Navbar;