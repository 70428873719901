import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import RepasSection from "./RepasSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function Repas(){

    return(
        <div>
            <NavBarGlobal  section = {<RepasSection />}/>
        </div>
    )
}

export default Repas;