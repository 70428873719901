// DepartementSection.js

import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import APIService from '../../../APIService.js';
import styles from './DepartementSection.module.css';
import {useNavigate} from 'react-router-dom';

function DepartementSection() {
  const [token] = useCookies(['mytoken']);
  const [listDepartement, setListDepartement] = useState(null);
  const [listEtablissement, setListEtablissement] = useState(null);
  const [showAjouter, setShowAjouter] = useState(false);
  const [idModif, setIdModif] = useState(null);
  const [nom, setNom] = useState(null);
  const [etablissementIds, setEtablissementIds] = useState([]);

  let navigate = useNavigate()

  useEffect(() => {

    console.log("etablissementIds : ", etablissementIds)


  }, [etablissementIds])
  useEffect(() => {
    if (token['mytoken']) {
      APIService.getDepartement(token)
        .then((resp) => setListDepartement(resp))
        .catch((error) => console.error('Erreur lors de la récupération des données :', error));

      APIService.getEtablissement(token)
        .then((resp) => setListEtablissement(resp))
        .catch((error) => console.error('Erreur lors de la récupération des établissements :', error));
    }
  }, [token['mytoken']]);

  const btnShowAjouter = () => {
    if (showAjouter) {
      setShowAjouter(false);
      setIdModif(null);
    } else {
      setShowAjouter(true);
    }
  };

  const handelAjouterDepartement = (e) => {
    e.preventDefault();

    if (nom && etablissementIds.length > 0) {
      console.log("Bonjour : ", etablissementIds.join(','))
      let formData = new FormData();
      formData.append('nom', nom);
      formData.append('etablissements', etablissementIds.join(','))
      // etablissementIds.forEach((id) => formData.append('etablissements', id));
      APIService.postDepartement(formData, token)
        .then(() => APIService.getDepartement(token))
        .then((resp) => setListDepartement(resp))
        .then(() => {
          setNom('');
          setEtablissementIds([]);
          setIdModif(null);
          setShowAjouter(false);
        })
        .catch((error) => console.error('Erreur lors de l\'ajout du département :', error));
    } else {
      console.log('Le nom et au moins un établissement sont obligatoires');
    }
  };

  const handelModifierDepartement = (e) => {
    e.preventDefault();

    if (nom && etablissementIds.length > 0) {
      let formData = new FormData();
      formData.append('nom', nom);
      formData.append('etablissements', etablissementIds.join(','))

      APIService.putDepartement(idModif, formData, token)
        .then(() => APIService.getDepartement(token))
        .then((resp) => setListDepartement(resp))
        .then(() => {
          setNom('');
          setEtablissementIds([]);
          setIdModif(null);
          setShowAjouter(false);
        })
        .catch((error) => console.error('Erreur lors de la modification du département :', error));
    }
  };

  const handelSupprimerDepartement = (id) => {
    if (id) {
      APIService.deleteDepartement(id, token)
        .then(() => APIService.getDepartement(token))
        .then((resp) => setListDepartement(resp))
        .catch((error) => console.error('Erreur lors de la suppression du département :', error));
    }
  };

  return (
    <div className={styles.classCard}>
       <div className={styles.classMenu}>

            <button  onClick={() => navigate('/donnees')} style={{cursor:"pointer"}}>Sexe</button>
            <button  onClick={() => navigate('/languesAdministartion')} style={{cursor:"pointer"}} >Langue</button>
            <button  onClick={() => navigate('/situationFamilialeAdministration')} style={{cursor:"pointer"}} >sitiationfamiliale</button>
            <button  onClick={() => navigate('/TypeContrats')} style={{cursor:"pointer"}} >TypeContrats</button>
            <button  onClick={() => navigate('/jours')} style={{cursor:"pointer"}}>jours</button>
            <button  onClick={() => navigate('/temps')} style={{cursor:"pointer"}}>temps</button>
            <button  onClick={() => navigate('/banques')} style={{cursor:"pointer"}}>banques</button>
            <button   onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button>
            {/* <button  onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button> */}
            <button className={styles.classBtnActive} >Departement</button>
            <button  onClick={() => navigate('/poste')} style={{cursor:"pointer"}}>Poste</button>
            <button  onClick={() => navigate('/nationalite')} style={{cursor:"pointer"}}>Nationalite</button>
            <button onClick={() => navigate('/pays')} style={{cursor:"pointer"}} >pays</button>
        </div>
      <div className={styles.classTable}>
        <table className={styles.tableStyle}>
          <thead>
            <tr>
              <th>id</th>
              <th>Nom du Département</th>
              <th>Établissements</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listDepartement &&
              listDepartement.map((departement) => (
                <tr key={departement.id}>
                  <td>{departement.id}</td>
                  <td>{departement.nom}</td>
                  <td>
                    {
                      listEtablissement &&
                      listEtablissement.filter(resp => 
                        departement.etablissements.split(',').map(resp => parseInt(resp)).includes(parseInt(resp.id)) === true
                        ).map(
                          resp =>
                          
                          
                            <>
                            - {resp.nom}
                            </>
                            
                          
                          
                        )
                    }

                  </td>
                  <td>
                    <button onClick={() => handelSupprimerDepartement(departement.id)}>Supprimer</button>
                    <button onClick={() => {
                      setIdModif(departement.id);
                      setNom(departement.nom);
                      setEtablissementIds(departement.etablissements.split(',').map(resp => parseInt(resp)));
                      setShowAjouter(true);
                    }}>Modifier</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className={styles.classAjouter}>
        <div>
          <button onClick={btnShowAjouter}>Ajouter</button>
        </div>

        {showAjouter && (
          <div>
            <form
              onSubmit={(e) => {
                if (idModif) {
                  handelModifierDepartement(e);
                } else {
                  handelAjouterDepartement(e);
                }
              }}
            >
              Nom : <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} />
              <br />
              Établissements :
              {listEtablissement && 
              
              listEtablissement.map(
                resp => <>

                <input type="checkbox" onChange={() => {
                  
                  if(etablissementIds.includes(resp.id)){

                    setEtablissementIds(etablissementIds.filter(i => i !== resp.id))

                  } else {

                    setEtablissementIds([...etablissementIds, resp.id])

                  }
              
              }} 

              checked={etablissementIds.includes(resp.id)}
              /> {resp.nom} <br />
                </>
              )}
            </form>
            <div>
              <button
                onClick={(e) =>
                  idModif
                    ? handelModifierDepartement(e)
                    : handelAjouterDepartement(e)
                }
              >
                {idModif ? <>Modifier</> : <>Ajouter</>}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DepartementSection;
