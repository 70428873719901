import React from "react";
import styles from './CreateComponent.module.css'

function CreateComponent(props) {

  return (
    <div className={styles.inputgroup}  >
        <a href={props.link} className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}}>
            <div className={styles.zoneComponent}>
                <div className={styles.icone}>
                    <i class="fa-solid fa-plus"></i>
                </div>
                <div className={styles.text}>
                    <span className={styles.creeravenat_}>
                        {props.text}
                    </span> 
                </div>
                <div className={styles.iconeRight}>
                <i class="fa-solid fa-arrow-right"></i>
                </div>
            </div>
        </a>
</div>
  )
}

export default CreateComponent