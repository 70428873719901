//CodePostaleSection.js

import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import APIService from '../../../APIService.js';
import styles from './CodePostaleSection.module.css';
import {useNavigate} from 'react-router-dom';

function CodePostaleSection() {
  const [token] = useCookies(['mytoken']);
  const [listVille, setListVille] = useState(null);
  const [listCodePostale, setListCodePostale] = useState(null);
  const [showAjouter, setShowAjouter] = useState(false);
  const [idModif, setIdModif] = useState(null);
  const [nom, setNom] = useState(null);
  const [ville, setVille] = useState(null);
  const [villeId, setVilleId] = useState([]);

  let navigate = useNavigate()
  useEffect(() => {

    if(token['mytoken']){
        APIService.getCodePostale(token)
        .then(resp => setListCodePostale(resp))
        .catch((error) => console.error('Erreur lors de la récupération des données :', error));

        APIService.getVille(token)
          .then((resp) => setListVille(resp))
          .catch((error) => console.error('Erreur lors de la récupération des établissements :', error));
      }
    }, [token['mytoken']]);

    const btnShowAjouter = () => {
   
      if (showAjouter) {
        setShowAjouter(false);
        setIdModif(null);
        } else {
          setShowAjouter(true);
        }
      };
const handelAjouterCodePostale = (e) => {
        e.preventDefault();
    

      if (nom && ville) {
        let formData = new FormData();
        formData.append('nom', nom);
        formData.append('id_ville', ville)
        APIService.postCodePostale(formData, token)
          .then(() => APIService.getCodePostale(token))
          .then((resp) => setListCodePostale(resp))
          .then(() => {
            setNom('');
            setVilleId([]);
            setIdModif(null);
            setShowAjouter(false);
          })
          .catch((error) => console.error('Erreur lors de l\'ajout du CodePostale :', error));
      } else {
        console.log('Le nom et un ville sont obligatoires');
      }
    };
  
    const handelModifierCodePostale = (e) => {
      e.preventDefault();
  
      if (nom && ville) {
        let formData = new FormData();
        formData.append('nom', nom);
        formData.append('id_ville',ville)
  
        APIService.putCodePostale(idModif, formData, token)
          .then(() => APIService.getCodePostale(token))
          .then((resp) => setListCodePostale(resp))
          .then(() => {
            setNom('');
            setVilleId([]);
            setIdModif(null);
            setShowAjouter(false);
          })
          .catch((error) => console.error('Erreur lors de la modification du CodePostale :', error));
      }
    };
  
    const handelSupprimerCodePostale = (id) => {
      if (id) {
        APIService.deleteCodePostale(id, token)
          .then(() => APIService.getCodePostale(token))
          .then((resp) => setListCodePostale(resp))
          .catch((error) => console.error('Erreur lors de la suppression du CodePostale :', error));
      }
    };
    return (
      <div className={styles.classCard}>
         <div className={styles.classMenu}>
  
              <button  onClick={() => navigate('/donnees')} style={{cursor:"pointer"}}>Sexe</button>
              <button  onClick={() => navigate('/languesAdministartion')} style={{cursor:"pointer"}} >Langue</button>
              <button  onClick={() => navigate('/situationFamilialeAdministration')} style={{cursor:"pointer"}} >sitiationfamiliale</button>
              <button  onClick={() => navigate('/TypeContrats')} style={{cursor:"pointer"}} >TypeContrats</button>
              <button  onClick={() => navigate('/jours')} style={{cursor:"pointer"}}>jours</button>
              <button  onClick={() => navigate('/temps')} style={{cursor:"pointer"}}>temps</button>
              <button  onClick={() => navigate('/banques')} style={{cursor:"pointer"}}>banques</button>
              <button   onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button>
              <button className={styles.classBtnActive} >Departement</button>
              <button  onClick={() => navigate('/poste')} style={{cursor:"pointer"}}>Poste</button>
              <button  onClick={() => navigate('/nationalite')} style={{cursor:"pointer"}}>Nationalite</button>
              <button onClick={() => navigate('/pays')} style={{cursor:"pointer"}} >pays</button>
          </div>
        <div className={styles.classTable}>
          <table className={styles.tableStyle}>
            <thead>
              <tr>
                <th>id</th>
                <th>N° du CodePostale</th>
                <th>ville</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {listCodePostale &&
                listCodePostale.map((codePostale) => (
                  <tr key={codePostale.id}>
                    <td>{codePostale.id}</td>
                    <td>{codePostale.nom}</td>   
                    <td>
                      {
                        listVille &&
                        listVille.filter(resp => 
                          resp.id === parseInt(codePostale.id_ville)
                          ).map(
                            resp =>
                            
                            
                              <>
                              - {resp.nom}
                              </>
                              
                            
                            
                          )
                      }
  
                    </td>
                    <td>
                      <button onClick={() => handelSupprimerCodePostale(codePostale.id)}>Supprimer</button>
                      <button onClick={() => {
                        setIdModif(codePostale.id);
                        setNom(codePostale.nom);
                        setVille(codePostale.id_ville)
                        setShowAjouter(true);
                      }}>Modifier</button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
  
        <div className={styles.classAjouter}>
          <div>
            <button onClick={btnShowAjouter}>Ajouter</button>
          </div>
  
          {showAjouter && (
            <div>
              <form
                onSubmit={(e) => {
                  if (idModif) {
                    handelModifierCodePostale(e);
                  } else {
                    handelAjouterCodePostale(e);
                  }
                }}
              >
                    
                Nom : <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} />
                <br />
                ville :
                <select value={ville} onChange={(e) => setVille(e.target.value)}>
                <option value=""></option>
                {listVille && 
                
                listVille.map(
                  resp => <>
  
                  <option value={resp.id}>  {resp.nom} </option> 
                  </>
                )}</select> 
              </form>
              <div>
                <button
                  onClick={(e) =>
                    idModif
                      ? handelModifierCodePostale(e)
                      : handelAjouterCodePostale(e)
                  }
                >
                  {idModif ? <>Modifier</> : <>Ajouter</>}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  
  export default CodePostaleSection;
  
  








  



