import React from 'react';
import styles from './ButtonValider.module.css';
import classNames from 'classnames';

function ButtonValider({onClickButton,textButton,buttonColor,buttonTextColor,hoverShadowColor,cursorChoice}) {
    const buttonClasses = classNames(styles.button, {
      [styles['buttonHasHover']]: hoverShadowColor 
    });
  
    const buttonStyle = {
      backgroundColor: buttonColor,
      color: buttonTextColor,
      '--hoverShadowColor': hoverShadowColor 
    };
   
  return (
    <div className={styles.divButton}>
    <button  className={buttonClasses} style={{...buttonStyle,cursor:cursorChoice}} onClick= {onClickButton}>
      {textButton}
    </button>
  </div>
    
  )
}

export default ButtonValider