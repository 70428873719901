import React, {useState, useEffect,useRef} from "react";
import {useCookies} from 'react-cookie';
import APIService from './../../../APIService.js';
import Pdf from './../../Pdf';
import moment from 'moment';
import Styles from './donneesPersonnellesSection.module.css';
import TopMenu from "../SalarieGlobal/TopMenu/TopMenu.jsx";
import TopMenuSalarie from "../TopMenuSalarie.jsx";
import GeneralCardTest from "../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import SecondSectionTablePage from "../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import DateComponent from "../../GlobalComponents/dateComponent/DateComponent.jsx";
import CreateComponent from "../../GlobalComponents/createComponent/CreateComponent.jsx";
import DescriptionComponent from "../../GlobalComponents/descriptionComponent/DescriptionComponent.jsx";
import ScrollableButtons from "../../GlobalComponents/scrollableButtons/ScrollableButtons.jsx";
import ScrollableButtonsTwo from "../../GlobalComponents/scrollableButtonsTwo/ScrollableButtonsTwo.jsx";
import { useNavigate } from 'react-router-dom';


function DonneesPersonnellesSection() {

  const [employe, setEmploye] = useState([])
  const [fichier, setFichier] = useState([])
  const [employeList, setEmployeList] = useState([])
  const [fichierList, setFichierList] = useState([])
  

  const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'idFichePersonnel', 'matriculeAvenant', 'listMatriculeNomPrenom'])
  
  const [activateDonnees, setActivateDonnees] = useState(false)
  const [activateAffectation, setActivateAffectation] = useState(false)
  const [activateCoordonnees, setActivateCoordonnees] = useState(false)
  const [activateCompetences, setActivateCompetences] = useState(false)
  const [activateIdBancaire, setActivateIdBancaire] = useState(false)
  const [activateFichiers, setActivateFichiers] = useState(false)




  const [inputActivateFichiers, setInputActivateFichiers] = useState(Styles.idBtnGlobalDisa)
  const [inputActivateDonnees, setInputActivateDonnees] = useState(Styles.idBtnGlobalDisa)
  const [inputActivateAffectation, setInputActivateAffectation] = useState(Styles.idBtnGlobalDisa)
  const [inputActivateCoordonnees, setInputActivateCoordonnees] = useState(Styles.idBtnGlobalDisa)
  const [inputActivateCompetences, setInputActivateCompetences] = useState(Styles.idBtnGlobalDisa)
  const [inputActivateIdBancaire, setInputActivateIdBancaire] = useState(Styles.idBtnGlobalDisa)
  


  const [afficherDonnees, setAfficherDonnees] = useState(false)
  const [afficherAffectation, setAfficherAffectation] = useState(false)
  const [afficherCoordonnees, setAfficherCoordonnees] = useState(false)
  const [afficherCompetences, setAfficherCompetences] = useState(false)
  const [afficherIdBancaire, setAfficherIdBancaire] = useState(false)
  const [afficherFichiers, setAfficherFichiers] = useState(false)

  const [firstDateModification, setFirstDateModification] = useState()

  const [initialeDonnees, setInitialeDonnees] = useState(true)
  const [initialeIdBancaire, setInitialeIdBancaire] = useState(true)
  const [initialeAffectation, setInitialeAffectation] = useState(true)
  const [initialeCoordonnees, setInitialeCoordonnees] = useState(true)
  const [initialeCompetences, setInitialeCompetences] = useState(true)
  const [initialeFichiers, setInitialeFichiers] = useState(true)








  const [dateModification, setDateModification] = useState()



  

  useEffect(() => {
    if(employeList.length){
      setFirstDateModification(employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1)[0].dateModification_employe)
    }
  }, [employeList])
  
  


  const btnOccurenceFichiers = (e) => {
    setInitialeFichiers(false)

    if(afficherFichiers){
      setAfficherFichiers(false)
      setDateModification('')
    }else{
      setAfficherFichiers(true)
      setDateModification(e.target.value)
    }
  }

  const btnActivateFichiers = () => {
    if(activateFichiers){
      setActivateFichiers(false)
      setInputActivateFichiers(Styles.idBtnGlobalDisa)
    }else{
      setActivateFichiers(true)
      setActivateIdBancaire(false)
      setActivateCompetences(false)
      setActivateCoordonnees(false)
      setActivateDonnees(false)
      setActivateAffectation(false)

      setInputActivateFichiers(Styles.idBtnGlobalEna)
      setInputActivateIdBancaire(Styles.idBtnGlobalDisa)
      setInputActivateCompetences(Styles.idBtnGlobalDisa)
      setInputActivateCoordonnees(Styles.idBtnGlobalDisa)
      setInputActivateDonnees(Styles.idBtnGlobalDisa)
      setInputActivateAffectation(Styles.idBtnGlobalDisa)
    }
  }
  


  const btnOccurenceIdBancaire = (e) => {
    setInitialeIdBancaire(false)
    if(afficherIdBancaire){
      setAfficherIdBancaire(false)
      setDateModification('')
    }else{
      setAfficherIdBancaire(true)
      setDateModification(e.target.value)
    }
  }


  const btnActivateIdBancaire = () => {
    if(activateIdBancaire){
      setActivateIdBancaire(false)
      setInputActivateIdBancaire(Styles.idBtnGlobalDisa)
    }else{
      setActivateIdBancaire(true)
      setActivateCompetences(false)
      setActivateCoordonnees(false)
      setActivateDonnees(false)
      setActivateAffectation(false)
      setActivateFichiers(false)

      setInputActivateIdBancaire(Styles.idBtnGlobalEna)
      setInputActivateCompetences(Styles.idBtnGlobalDisa)
      setInputActivateCoordonnees(Styles.idBtnGlobalDisa)
      setInputActivateDonnees(Styles.idBtnGlobalDisa)
      setInputActivateAffectation(Styles.idBtnGlobalDisa)
      setInputActivateFichiers(Styles.idBtnGlobalDisa)

    }
  }

  const btnOccurenceCompetences = (e) => {
    setInitialeCompetences(false)

    if(afficherCompetences){
      setAfficherCompetences(false)
      setDateModification('')
    }else{
      setAfficherCompetences(true)
      setDateModification(e.target.value)
    }
  }

  const btnActivateCompetences = () => {
    if(activateCompetences){
      setActivateCompetences(false)
      setInputActivateCompetences(Styles.idBtnGlobalDisa)
    }else{
      setActivateCompetences(true)
      setActivateCoordonnees(false)
      setActivateDonnees(false)
      setActivateAffectation(false)
      setActivateIdBancaire(false)
      setActivateFichiers(false)

      setInputActivateCompetences(Styles.idBtnGlobalEna)
      setInputActivateCoordonnees(Styles.idBtnGlobalDisa)
      setInputActivateDonnees(Styles.idBtnGlobalDisa)
      setInputActivateAffectation(Styles.idBtnGlobalDisa)
      setInputActivateIdBancaire(Styles.idBtnGlobalDisa)
      setInputActivateFichiers(Styles.idBtnGlobalDisa)

    }
  }


  const btnOccurenceCoordonnees = (e) => {
    setInitialeCoordonnees(false)

      if(afficherCoordonnees){
        setAfficherCoordonnees(false)
        setDateModification('')
      }else{
        setAfficherCoordonnees(true)
        setDateModification(e.target.value)
      }
    }


    const btnActivateCoordonnees = () => {
    if(activateCoordonnees){
      setActivateCoordonnees(false)
      setInputActivateCoordonnees(Styles.idBtnGlobalDisa)
    }else{
      setActivateCoordonnees(true)
      setActivateDonnees(false)
      setActivateAffectation(false)
      setActivateCompetences(false)
      setActivateIdBancaire(false)
      setActivateFichiers(false)


      setInputActivateCoordonnees(Styles.idBtnGlobalEna)
      setInputActivateDonnees(Styles.idBtnGlobalDisa)
      setInputActivateAffectation(Styles.idBtnGlobalDisa)
      setInputActivateCompetences(Styles.idBtnGlobalDisa)
      setInputActivateIdBancaire(Styles.idBtnGlobalDisa)
      setInputActivateFichiers(Styles.idBtnGlobalDisa)

    }
  }

  const btnOccurenceDonnees = (e) => {
    setInitialeDonnees(false)

    if(afficherDonnees){
      setAfficherDonnees(false)
      setDateModification('')
    }else{
      setAfficherDonnees(true)
      setDateModification(e.target.value)
    }
  }


  const btnActivateDonnees = () => {
    if(activateDonnees){
      setActivateDonnees(false)
      setInputActivateDonnees(Styles.idBtnGlobalDisa)
    }else{
      setActivateDonnees(true)
      setActivateAffectation(false)
      setActivateCoordonnees(false)
      setActivateCompetences(false)
      setActivateIdBancaire(false)
      setActivateFichiers(false)

      setInputActivateDonnees(Styles.idBtnGlobalEna)
      setInputActivateAffectation(Styles.idBtnGlobalDisa)
      setInputActivateCoordonnees(Styles.idBtnGlobalDisa)
      setInputActivateCompetences(Styles.idBtnGlobalDisa)
      setInputActivateIdBancaire(Styles.idBtnGlobalDisa)
      setInputActivateFichiers(Styles.idBtnGlobalDisa)




    }
  }


  const btnOccurenceAffectation = (e) => {
    setInitialeAffectation(false)

    if(afficherAffectation){
      setAfficherAffectation(false)
      setDateModification('')
    }else{
      setAfficherAffectation(true)
      setDateModification(e.target.value)
    }
  }

  const btnActivateAffectation = () => {
    if(activateAffectation){
      setActivateAffectation(false)
      setInputActivateAffectation(Styles.idBtnGlobalDisa)

    }else{
      setActivateAffectation(true)
      setActivateDonnees(false)
      setActivateCoordonnees(false)
      setActivateCompetences(false)
      setActivateIdBancaire(false)
      setActivateFichiers(false)


      setInputActivateAffectation(Styles.idBtnGlobalEna)
      setInputActivateDonnees(Styles.idBtnGlobalDisa)
      setInputActivateCoordonnees(Styles.idBtnGlobalDisa)
      setInputActivateCompetences(Styles.idBtnGlobalDisa)
      setInputActivateIdBancaire(Styles.idBtnGlobalDisa)
      setInputActivateFichiers(Styles.idBtnGlobalDisa);




    }
  }
  


  useEffect(() => {
    if(token['matriculeAvenant']){
      APIService.getEmployes(token)
      .then(resp => setEmploye(resp))
      .catch(error => console.log(error))

      APIService.getFichiers(token)
      .then(resp => setFichier(resp))
    }
  }, [token])


  useEffect(() => {
    if(employe){
    setEmployeList(employe.filter(resp => resp.matricule_employe === token['matriculeAvenant']))
    }
  }, [employe])


  useEffect(() => {
    if(fichier){
      setFichierList(fichier.filter(resp => resp.matricule_employe === token['matriculeAvenant']))
    }
  }, [fichier])

  useEffect(() => {
    if(fichierList){
      console.log(employeList)
      console.log(fichierList)
        }
  }, [fichierList])
  

  useEffect(() => {
    if(employeList){
      console.log(employeList)
    }
  }, [employeList])

  const [afficherCarteVitale, setAfficherCarteVitale] = useState(false)

  const btnCarteVitaleAfficher = () => {
    if(afficherCarteVitale){
      setAfficherCarteVitale(false)

      document.getElementById(Styles.idBtnCarteVitale).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCarteVitale).style.color="goldenrod";

    }else{
      setAfficherCarteVitale(true)
      setAfficherCIN(false)
      setAfficherTS(false)
      setAfficherCV(false)
      setAfficherRIB(false)

      document.getElementById(Styles.idBtnCarteVitale).style.backgroundColor="goldenrod";
      document.getElementById(Styles.idBtnCarteVitale).style.color="white";
      document.getElementById(Styles.idBtnCarteVitale).style.borderBottomLeftRadius="5px";


      document.getElementById(Styles.idBtnCIN).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCIN).style.color="goldenrod";

      document.getElementById(Styles.idBtnTS).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnTS).style.color="goldenrod";

      document.getElementById(Styles.idBtnCV).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCV).style.color="goldenrod";

      document.getElementById(Styles.idBtnRIB).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnRIB).style.color="goldenrod";
    }
  }


  const [afficherCIN, setAfficherCIN] = useState(false)

  const btnCINAfficher = () => {
    if(afficherCIN){
      setAfficherCIN(false)
      document.getElementById(Styles.idBtnCIN).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCIN).style.color="goldenrod";
    }else{
      setAfficherCIN(true)
      setAfficherCarteVitale(false)
      setAfficherTS(false)
      setAfficherCV(false)
      setAfficherRIB(false)

      document.getElementById(Styles.idBtnCIN).style.backgroundColor="goldenrod";
      document.getElementById(Styles.idBtnCIN).style.color="white";

      document.getElementById(Styles.idBtnCarteVitale).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCarteVitale).style.color="goldenrod";

      document.getElementById(Styles.idBtnTS).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnTS).style.color="goldenrod";

      document.getElementById(Styles.idBtnCV).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCV).style.color="goldenrod";

      document.getElementById(Styles.idBtnRIB).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnRIB).style.color="goldenrod";
    }
  }


  const [afficherTS, setAfficherTS] = useState(false)

  const btnTSAfficher = () => {
    if(afficherTS){
      setAfficherTS(false)
      document.getElementById(Styles.idBtnTS).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnTS).style.color="goldenrod";
    }else{
      setAfficherTS(true)
      setAfficherCarteVitale(false)
      setAfficherCIN(false)
      setAfficherCV(false)
      setAfficherRIB(false)

      document.getElementById(Styles.idBtnTS).style.backgroundColor="goldenrod";
      document.getElementById(Styles.idBtnTS).style.color="white";

      document.getElementById(Styles.idBtnCarteVitale).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCarteVitale).style.color="goldenrod";

      document.getElementById(Styles.idBtnCIN).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCIN).style.color="goldenrod";

      document.getElementById(Styles.idBtnCV).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCV).style.color="goldenrod";

      document.getElementById(Styles.idBtnRIB).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnRIB).style.color="goldenrod";

    }
  }

  const [afficherCV, setAfficherCV] = useState(false)

  const btnCVAfficher = () => {
    if(afficherCV){
      setAfficherCV(false)
      document.getElementById(Styles.idBtnCV).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCV).style.color="goldenrod";

    }else{
      setAfficherCV(true)
      setAfficherCarteVitale(false)
      setAfficherCIN(false)
      setAfficherTS(false)
      setAfficherRIB(false)

      document.getElementById(Styles.idBtnCV).style.backgroundColor="goldenrod";
      document.getElementById(Styles.idBtnCV).style.color="white";

      document.getElementById(Styles.idBtnCarteVitale).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCarteVitale).style.color="goldenrod";

      document.getElementById(Styles.idBtnCIN).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCIN).style.color="goldenrod";

      document.getElementById(Styles.idBtnTS).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnTS).style.color="goldenrod";

      document.getElementById(Styles.idBtnRIB).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnRIB).style.color="goldenrod";
    }
  }
  
  const [afficherRIB, setAfficherRIB] = useState(false)

  const btnRIBAfficher = () => {
    if(afficherRIB){
      setAfficherRIB(false)
      document.getElementById(Styles.idBtnRIB).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnRIB).style.color="goldenrod";

    }else{

      setAfficherRIB(true)
      setAfficherCarteVitale(false)
      setAfficherCIN(false)
      setAfficherTS(false)
      setAfficherCV(false)
      console.log("testtt")
      document.getElementById(Styles.idBtnRIB).style.backgroundColor="goldenrod";
      document.getElementById(Styles.idBtnRIB).style.color="white";
      document.getElementById(Styles.idBtnRIB).style.borderBottomRightRadius="5px";


      document.getElementById(Styles.idBtnCarteVitale).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCarteVitale).style.color="goldenrod";

      document.getElementById(Styles.idBtnCIN).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCIN).style.color="goldenrod";

      document.getElementById(Styles.idBtnTS).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnTS).style.color="goldenrod";

      document.getElementById(Styles.idBtnCV).style.backgroundColor="transparent";
      document.getElementById(Styles.idBtnCV).style.color="goldenrod";
    }
  }
// testttt
const buttonsList={ 1: "Fichiers",2:"Données",3:"Affectation",4:"Coordonnées",5:"Compétences",6:"ID.Bancaire"}
const methodsList={ 1:btnActivateFichiers,2:btnActivateDonnees,3:btnActivateAffectation,4:btnActivateCoordonnees,5:btnActivateCompetences,6:btnActivateIdBancaire};
const idList={ 1:inputActivateFichiers,2:inputActivateDonnees,3:inputActivateAffectation,4:inputActivateCoordonnees,5:inputActivateCompetences,6:inputActivateIdBancaire}

const navigate = useNavigate();
useEffect(() => {
  if( token['matriculeAvenant']){
    console.log("token", token['matriculeAvenant'])

  }else{
    console.log("no token here ")
    navigate('/salarie');
  }
}, [ token['matriculeAvenant']])



  return (

//     <div className={Styles.classCard}>

//               <div className={Styles.classone}>
//                 <TopMenuSalarie page="donnees" />
//               </div>

//               <div className={Styles.classtwo}>
      
//               </div>


//             <div className={Styles.classthree}>

//             <div id={Styles.divHeaderBtn}>

//             <div>
//                 <button onClick={btnActivateFichiers} id={inputActivateFichiers}>Fichiers</button>
//             </div>

//             <div >
//                 <button onClick={btnActivateDonnees} id={inputActivateDonnees}>Données</button>
//             </div>

//             <div >
//                 <button onClick={btnActivateAffectation} id={inputActivateAffectation}>Affectation</button>
//             </div>

//             <div >
//                 <button onClick={btnActivateCoordonnees} id={inputActivateCoordonnees}>Coordonnées</button>
//             </div>

//             <div >
//                 <button onClick={btnActivateCompetences} id={inputActivateCompetences}>Compétences</button>
//             </div>

//             <div>
//                 <button onClick={btnActivateIdBancaire} id={inputActivateIdBancaire}>ID.Bancaire</button>
//             </div>

//             </div>

//             </div>


//             <div className={Styles.classfour}>

//             <div id={Styles.idSection}>
            

// <div className={Styles.classCenterSection}>
//   <div className={Styles.classCenterSection2}>
//              {
//             activateFichiers && employeList && fichierList ? fichierList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(
//               fcr => employeList.filter(emp => emp.matricule_employe === fcr.matricule_employe && emp.dateModification_employe === fcr.dateModification_employe).map(
  
//                 emp => 
  
//             <div>

//             {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
            
//             <div className={Styles.classBtnCenter}>
//             <button  className={Styles.classBtnDonnees} style={{backgroundColor:"rgb(109, 108, 106)", color:"white"}} value={emp.dateModification_employe} onClick={btnOccurenceFichiers}>Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//             &nbsp;
//             &nbsp;
//             &nbsp;
//             <i className='fas fa-angle-down'></i>
//             </button>
//             </div>:

//             <div className={Styles.classBtnCenter}>
//             <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceFichiers}>Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//                 &nbsp;
//                 &nbsp;
//                 &nbsp;
//                 <i className='fas fa-angle-up'></i>
//             </button>
//             </div>

//             }
// {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
            
//   <div >

//                 <br />

//             <div className={Styles.classAffichageBtns}>

//               <div  className="row" >
//                 <button className="col" id={Styles.idBtnCarteVitale} onClick={btnCarteVitaleAfficher}>Carte vitale</button>
//                 {fcr.cin_employe ? 
//                 <button className="col" id={Styles.idBtnCIN} onClick={btnCINAfficher}>CIN</button>
//                 : 
//                 <>
//                 {
//                   fcr.ts_employe ? 
//                   <button className="col" id={Styles.idBtnTS} onClick={btnTSAfficher}>Titre de séjeur</button>
//                   :<></>
//                 }
//                 </>
//               }
//                 <button className="col" id={Styles.idBtnCV} onClick={btnCVAfficher}>CV</button>
//                 <button className="col" id={Styles.idBtnRIB} onClick={btnRIBAfficher}>RIB</button>
//                </div>
//             </div>

//             <br />
            
//             <div className={Styles.classAffichageFichiers} > 
//               {afficherCarteVitale ? <span  style={{margin:"auto"}}><Pdf url={fcr.carteVitale_employe} /></span>: <span></span>}
//               {afficherCIN ? <Pdf url={fcr.cin_employe} /> : <span></span>}
//               {afficherTS ? <Pdf url={fcr.ts_employe} /> : <span></span>}
//               {afficherCV ? <Pdf url={fcr.cv_employe} /> : <span></span>}
//               {afficherRIB ? <Pdf url={fcr.rib_employe} /> : <span></span>}
//             </div>
      
//                </div> : <span></span>}
//                <div class={Styles.centeredline}>
//                   <hr />
//                </div>
            
//             </div>
  
//               )
  
//             )
            
//             : <span>
              
//             </span>
//           }

// </div>
// </div>



// <div className={Styles.classCenterSection}>
  
//   <div className={Styles.classCenterSection2}>

//   {
//             activateDonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
//             <div>
//             {(afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
            
//             <div className={Styles.classBtnCenter}>

//               <button className={Styles.classBtnDonnees} style={{backgroundColor:"rgb(109, 108, 106)", color:"white"}} value={emp.dateModification_employe} onClick={btnOccurenceDonnees}>Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//               &nbsp;
//               &nbsp;
//               &nbsp;
//               <i className='fas fa-angle-down'></i></button>

//             </div>:

//             <div className={Styles.classBtnCenter}>

//             <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceDonnees}>
              
//                     Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")} 
//                     &nbsp;
//                     &nbsp;
//                     &nbsp;
             
//                     <i className='fas fa-angle-up'></i>
                
//             </button>

//             </div>
            
//             }

//             {
            
//             (afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
//             <div className={Styles.affichagetaille}>
//               <br />
              

//               {/* <table className={Styles.tableAffichageDonneesPersonnel}>

//                   <tr>
//                     <td><span className={Styles.classStyleTxt}> Nationalité 1 &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:</span> {emp.nationnalite1_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>- Nationalité 2 :</span> {emp.nationnalite2_employe}</td>
//                   </tr>
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}> Situation familiale&nbsp;&nbsp;:</span> {emp.situationFamiliale_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>- Numero SS :</span> {emp.numeroSS_employe}</td>
//                   </tr>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}> Clé &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:</span> {emp.cle_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>- Nombre d'enfants :</span> {emp.nombreEnfant_employe}</td>
//                   </tr>

//               </table> */}


// <div className={Styles.sectionmobile}>
// <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                              Nom 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                              {emp.nom_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Prénom
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.prenom_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Sexe 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.sexe_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Date naissance 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.departementNaissance_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Pays de naissance 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.paysNaissance_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Département de naissance 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.departementNaissance_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Nationalité 1 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.nationnalite1_employe}
//                 </div>
//               </div>


//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Nationalité 2
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.nationnalite2_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Situation familiale
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.situationFamiliale_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Numero SS
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.numeroSS_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Clé
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.cle_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Nombre d'enfants
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.nombreEnfant_employe}
//                 </div>
//               </div>



// </div>



              

//               <div className={Styles.classcentretotal}>


//                 <div className={Styles.classdroite}>

//                 <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                              Nom 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                              {emp.nom_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Prénom
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.prenom_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Sexe 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.sexe_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Date naissance 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.departementNaissance_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Pays de naissance 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.paysNaissance_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Département de naissance 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.departementNaissance_employe}
//                 </div>
//               </div>

//                 </div>

//                 <div></div>

//                 <div className={Styles.classgauche} >


//                 <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Nationalité 1 
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.nationnalite1_employe}
//                 </div>
//               </div>


//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Nationalité 2
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.nationnalite2_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Situation familiale
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.situationFamiliale_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Numero SS
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.numeroSS_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Clé
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.cle_employe}
//                 </div>
//               </div>

//               <div className={Styles.classtotal}>
//                 <div className={Styles.classpremier}>
//                 Nombre d'enfants
//                 </div>
//                 <div className={Styles.classdeuxieme}>
//                 {emp.nombreEnfant_employe}
//                 </div>
//               </div>

//                 </div>
               

//               </div>


             

              


//                </div> : <span></span>}

//                <div class={Styles.centeredline}>
//                   <hr />
//                </div>
            
//             </div>): <span></span>
//           }

//   </div>
// </div>


// <div className={Styles.classCenterSection}>
//   <div className={Styles.classCenterSection2}>
//           {
//             activateAffectation && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
//             <div>
//             {(afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
            

//             <div className={Styles.classBtnCenter}>
//               <button className={Styles.classBtnDonnees} style={{backgroundColor:"rgb(109, 108, 106)", color:"white"}} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//               &nbsp;
//               &nbsp;
//               &nbsp;
//               <i className='fas fa-angle-down'></i>
//               </button>
            
//               </div>:

//             <div className={Styles.classBtnCenter}>
//               <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//                     &nbsp;
//                     &nbsp;
//                     &nbsp;
//                     <i className='fas fa-angle-up'></i>
//                 </button> 


//             </div>

//             }

//             {


//             (afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
            
//             <div className={Styles.affichagetaille}>
//             <br />
              

              
//               {/* <table className={Styles.tableAffichageDonneesPersonnel2}>

//                 <thead>
//                 <tr>
//                 <th> <span className={Styles.classStyleTxt}>Etablissement </span></th>
//                 <th><span className={Styles.classStyleTxt}>Département </span> </th>
//                 <th><span className={Styles.classStyleTxt}>Poste </span> </th>
//                 <th> <span className={Styles.classStyleTxt}>Type de contrat </span></th>
//                 <th> <span className={Styles.classStyleTxt}>Jour de repos 1 </span></th>
//                 <th><span className={Styles.classStyleTxt}>Jour de repos 2 </span> </th>
//                 </tr> 
//                 </thead>

//                 <tbody>
//                   <tr>

//                     <td>{emp.etablissement_employe}</td>
//                     <td>{emp.departement_employe}</td>
//                     <td>{emp.poste_employe}</td>
//                     <td>{emp.typeContrat_employe}</td>
//                     <td>{emp.jourRepos1_employe}</td>
//                     <td>{emp.jourRepos2_employe} </td>

//                     </tr>
//                 </tbody>
//                 <thead>
//                     <th><span className={Styles.classStyleTxt}>Nationalité 1 </span> </th>
//                     <th> <span className={Styles.classStyleTxt}>Nationalité 2 </span></th>
//                     <th> <span className={Styles.classStyleTxt}>Nbre heures hebdo </span></th>
//                     <th> <span className={Styles.classStyleTxt}>Date de début </span></th>
//                     <th> <span className={Styles.classStyleTxt}>Date de fin </span></th>
//                     <th> <span className={Styles.classStyleTxt}>Mode de travail </span></th>
//                   </thead>

//                   <tbody>
//                     <td>{emp.nationnalite1_employe} </td>
//                     <td>{emp.nationnalite2_employe} </td>
//                     <td> {emp.plageHoraire_employe}</td>
//                     <td> {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}</td>
//                     <td> {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}</td>
//                     <td>{emp.modeTravail_employe} </td>

//                   </tbody>
//                 </table> */}

//                     {/* <td><span className={Styles.classStyleTxt}>Etablissement </span>: {emp.etablissement_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Département </span>: {emp.departement_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Poste </span>: {emp.poste_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Type de contrat </span>: {emp.typeContrat_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Jour de repos 1 </span>: {emp.jourRepos1_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Jour de repos 2 </span>: {emp.jourRepos2_employe} </td> */}
               
//                     {/* <td><span className={Styles.classStyleTxt}>Nationalité 1 </span>: {emp.nationnalite1_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Nationalité 2 </span>: {emp.nationnalite2_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Nbre heures hebdo </span>: {emp.plageHoraire_employe}</td>
                   
//                     <td><span className={Styles.classStyleTxt}>Date de début </span>: {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}</td>
//                     <td><span className={Styles.classStyleTxt}>Date de fin </span>: {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}</td>
//                     <td><span className={Styles.classStyleTxt}>Mode de travail </span>: {emp.modeTravail_employe}</td> */}
            

//               {/* <table className={Styles.tableAffichageDonneesPersonnel2}>
//               <td><span className={Styles.classStyleTxt}>Nationalité 1 </span>: {emp.nationnalite1_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Nationalité 2 </span>: {emp.nationnalite2_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Nbre heures hebdo </span>: {emp.plageHoraire_employe}</td>
                   
//                     <td><span className={Styles.classStyleTxt}>Date de début </span>: {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}</td>
//                     <td><span className={Styles.classStyleTxt}>Date de fin </span>: {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}</td>
//                     <td><span className={Styles.classStyleTxt}>Mode de travail </span>: {emp.modeTravail_employe}</td>
//                     </table> */}



// <div className={Styles.sectionmobile}>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Etablissement 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.etablissement_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Poste
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.poste_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Type de contrat
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.departementNaissance_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Jour de repos 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.jourRepos1_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Jour de repos 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.jourRepos2_employe}
// </div>
// </div>
// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Nbre heures hebdo
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.plageHoraire_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Date de début
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Date de fin
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Mode de travail
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.modeTravail_employe}
// </div>
// </div>

// </div>


// <div className={Styles.classcentretotal}>


// <div className={Styles.classdroite}>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Etablissement 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.etablissement_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Poste
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.poste_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Type de contrat
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.departementNaissance_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Jour de repos 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.jourRepos1_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Jour de repos 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.jourRepos2_employe}
// </div>
// </div>

// </div>

// <div></div>

// <div className={Styles.classgauche} >

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Département
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.departement_employe}
// </div>
// </div>




// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Nbre heures hebdo
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.plageHoraire_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Date de début
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Date de fin
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Mode de travail
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.modeTravail_employe}
// </div>
// </div>

// </div>


// </div>





//                </div> : <span></span>}
//                <div class={Styles.centeredline}>
//                   <hr />
//                </div>
            
//             </div>): <span></span>
//           }
//   </div>
// </div>



// <div className={Styles.classCenterSection}>
//   <div className={Styles.classCenterSection2}>

// {
//             activateCoordonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
//             <div>
//             {(afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
            
//             <div className={Styles.classBtnCenter}>
//               <button  className={Styles.classBtnDonnees} style={{backgroundColor:"rgb(109, 108, 106)", color:"white"}} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//               &nbsp;
//               &nbsp;
//               &nbsp;
//               <i className='fas fa-angle-down'></i>
//               </button>

//               </div>:
              
//             <div className={Styles.classBtnCenter}>

//             <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                
//                 &nbsp;
//                 &nbsp;
//                 &nbsp;
         
//                 <i className='fas fa-angle-up'></i>
//             </button>
//             </div>

// }
// {
// (afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
            
// <div className={Styles.affichagetaille}>



//               <br />
//               {/* <table className={Styles.tableAffichageDonneesPersonnel}>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Nom rue/avenue </span>: {emp.nomRue_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>N° rue/avenue </span>: {emp.NRue_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Complément d'adresse </span>: {emp.complementAdresse_employe}</td>
//                   </tr>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Chez </span>: {emp.chez_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Pays </span>: {emp.pays_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Code postale </span>: {emp.codePostale_employe} </td>
//                   </tr>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Ville </span>: {emp.ville_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Télephone fixe </span>: {emp.telephoneFixe_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Télephone mobile </span>: {emp.tel_employe}</td>
//                   </tr>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>E-Mail </span>: {emp.email_employe}</td>
//                   </tr>
//               </table> */}



// <div className={Styles.sectionmobile}>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Nom rue/avenue
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.nomRue_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// N° rue/avenue
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.NRue_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Complément d'adresse 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.complementAdresse_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Chez
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.pays_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Code postale
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.codePostale_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Ville
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.ville_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Télephone fixe
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.telephoneFixe_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Télephone mobile
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.tel_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// E-Mail
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.email_employe}
// </div>
// </div>

// </div>


// <div className={Styles.classcentretotal}>


// <div className={Styles.classdroite}>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Nom rue/avenue
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.nomRue_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// N° rue/avenue
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.NRue_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Complément d'adresse 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.complementAdresse_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Chez
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.pays_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Code postale
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.codePostale_employe}
// </div>
// </div>

// </div>

// <div></div>

// <div className={Styles.classgauche} >


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Ville
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.ville_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Télephone fixe
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.telephoneFixe_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Télephone mobile
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.tel_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// E-Mail
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.email_employe}
// </div>
// </div>

// </div>


// </div>
              


//                </div> : <span></span>}
//                <div class={Styles.centeredline}>
//                   <hr />
//                </div>
            
//             </div>): <span></span>
//           }

// </div>
// </div>


// <div className={Styles.classCenterSection}>
// <div className={Styles.classCenterSection2}>


// {

//             activateCompetences && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
//             <div>
//             {
//             (afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
            
//             <div className={Styles.classBtnCenter}>
//               <button className={Styles.classBtnDonnees} style={{backgroundColor:"rgb(109, 108, 106)", color:"white"}}value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//               &nbsp;
//               &nbsp;
//               &nbsp;
//               <i className='fas fa-angle-down'></i>
//               </button>

//               </div>:
              
//               <div className={Styles.classBtnCenter}>
//               <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//                     &nbsp;
//                     &nbsp;
//                     &nbsp;
//                     <i className='fas fa-angle-up'></i>
//               </button>
//               </div>
              
        
//             }
//             {(afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
            
//             <div className={Styles.affichagetaille}>
//               <br />
              
//               {/* <table className={Styles.tableAffichageDonneesPersonnel}>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Formation 1 </span>: {emp.formation1_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Année formation 1 </span>: {moment(emp.anneeFormation1_employe).format("MM/YYYY")}</td>
//                   </tr>
//                   {emp.formation2_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Formation 2 </span>: {emp.formation2_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Année formation 2 </span>: {moment(emp.anneeFormation2_employe).format("MM/YYYY")}</td>
//                 </tr>: <span></span>
//                 }
//                 {emp.formation3_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Formation 3 </span>: {emp.formation3_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Année formation 3 </span>: {moment(emp.anneeFormation3_employe).format("MM/YYYY")}</td>
//                 </tr>: <span></span>
//                 }
//                 {emp.formation4_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Formation 4 </span>: {emp.formation4_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Année formation 4 </span>: {moment(emp.anneeFormation4_employe).format("MM/YYYY")}</td>
//                 </tr>: <span></span>
//                 }
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Expérience 1 </span>: {emp.experience1_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Année expérience 1 </span>: {moment(emp.anneeExperience1_employe).format("MM/YYYY")}</td>
//                   </tr>
//                   {emp.experience2_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Expérience 2 </span>: {emp.experience2_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Année expérience 2 </span>: {moment(emp.anneeExperience2_employe).format("MM/YYYY")}</td>
//                 </tr>: <span></span>
//                 }
//                 {emp.experience3_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Expérience 3 </span>: {emp.experience3_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Année expérience 3 </span>: {moment(emp.anneeExperience3_employe).format("MM/YYYY")}</td>
//                 </tr>: <span></span>
//                 }
//                 {emp.experience4_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Expérience 4 </span>: {emp.experience4_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Année expérience 4 </span>: {moment(emp.anneeExperience4_employe).format("MM/YYYY")}</td>
//                 </tr>: <span></span>
//                 }
  
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Langue 1 </span>: {emp.langue1_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Niveau de langue 1 </span>: {emp.niveauLangue1_employe}</td>
//                   </tr>
//                   {emp.langue2_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Langue 2 </span>: {emp.langue2_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Niveau de langue 2 </span>: {emp.niveauLangue2_employe}</td>
//                 </tr>: <span></span>
//                 }
//                 {emp.langue3_employe ? 
//                   <tr>
//                   <td><span className={Styles.classStyleTxt}>Langue 3 </span>: {emp.langue3_employe}</td>
//                   <td><span className={Styles.classStyleTxt}>Niveau de langue 3 </span>: {emp.niveauLangue3_employe}</td>
//                 </tr>: <span></span>
//                 }
//                 {emp.langue4_employe ? 
//                  <tr>
//                  <td><span className={Styles.classStyleTxt}>Langue 4 </span>: {emp.langue4_employe}</td>
//                  <td><span className={Styles.classStyleTxt}>Niveau de langue 4 </span>: {emp.niveauLangue4_employe}</td>
//                </tr>: <span></span>
//                 }
//                <tr>
//                  <td><span className={Styles.classStyleTxt}>Compétence </span>: {emp.competence_employe}</td>
//                </tr>
  
//               </table> */}

//                                                      <div className={Styles.sectionmobile}>

//                                                      <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation1_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 3
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 4
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation4_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience1_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 3
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 4
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience4_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 1 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue1_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 3 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 4 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue4_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Compétence
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.competence_employe}
// </div>
// </div>



// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 1
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 2
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 3
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 4
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 1
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 2
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 3
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 4
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
// </div>
// </div>



// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue1_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 3
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 4
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue4_employe}
// </div>
// </div>




//                                                      </div>



// <div className={Styles.classcentretotal}>


// <div className={Styles.classdroite}>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation1_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 3
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Formation 4
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.formation4_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience1_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 3
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Expérience 4
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.experience4_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 1 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue1_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 3 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Langue 4 
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.langue4_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Compétence
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.competence_employe}
// </div>
// </div>





// </div>

// <div></div>

// <div className={Styles.classgauche} >


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 1
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 2
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 3
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année formation 4
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 1
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 2
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 3
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Année expérience 4
// </div>
// <div className={Styles.classdeuxieme}>
// {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
// </div>
// </div>



// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 1
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue1_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 2
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue2_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 3
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue3_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Niveau de langue 4
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.niveauLangue4_employe}
// </div>
// </div>

// </div>


// </div>



              
//                </div> : <span></span>}
//                <div class={Styles.centeredline}>
//                   <hr />
//                </div>
            
//             </div>): <span></span>
//           }

// </div>
// </div>


// <div className={Styles.classCenterSection}>
// <div className={Styles.classCenterSection2}>

// {
//             activateIdBancaire && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
//             <div>
//             {(afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 

//             <div className={Styles.classBtnCenter}>
//             <button className={Styles.classBtnDonnees}style={{backgroundColor:"rgb(109, 108, 106)", color:"white"}} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//               &nbsp;
//               &nbsp;
//               &nbsp;
//               <i className='fas fa-angle-down'></i>
//             </button>
//             </div> :

//             <div className={Styles.classBtnCenter}>
//             <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
//                     &nbsp;
//                     &nbsp;
//                     &nbsp;
             
//                     <i className='fas fa-angle-up'></i>
//             </button>
//             </div>
// }

// {

// (afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
//               <div className={Styles.affichagetaille}>
//               <br />
              
//               {/* <table className={Styles.tableAffichageDonneesPersonnel}>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Banque </span>: {emp.banque_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Domiciliation </span>: {emp.domiciliation_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Numéro de compte </span>: {emp.numeroCompte_employe}</td>
//                   </tr>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>Code du banque </span>: {emp.codeBanque_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Code du guichet </span>: {emp.codeGuichet_employe}</td>
//                     <td><span className={Styles.classStyleTxt}>Clé RIB </span>: {emp.cleRib_employe} </td>
//                   </tr>
//                   <tr>
//                     <td><span className={Styles.classStyleTxt}>IBAN </span>: {emp.iban_employe}</td>
//                   </tr>
//               </table> */}

//               <div className={Styles.sectionmobile}>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Banque
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.banque_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Domiciliation
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.domiciliation_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Numéro de compte
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.numeroCompte_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Code du banque
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.codeBanque_employe}
// </div>
// </div>
// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Code du guichet
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.codeGuichet_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Clé RIB
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.cleRib_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// IBAN
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.iban_employe}
// </div>
// </div>


                

                

//               </div>





// <div className={Styles.classcentretotal}>


// <div className={Styles.classdroite}>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Banque
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.banque_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Domiciliation
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.domiciliation_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Numéro de compte
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.numeroCompte_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Code du banque
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.codeBanque_employe}
// </div>
// </div>

// </div>

// <div></div>


// <div className={Styles.classgauche} >


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Code du guichet
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.codeGuichet_employe}
// </div>
// </div>


// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// Clé RIB
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.cleRib_employe}
// </div>
// </div>

// <div className={Styles.classtotal}>
// <div className={Styles.classpremier}>
// IBAN
// </div>
// <div className={Styles.classdeuxieme}>
// {emp.iban_employe}
// </div>
// </div>


// </div>

// </div>
              
//                </div> : <span></span>}
//                <div class={Styles.centeredline}>
//                   <hr />
//                </div>
            
//             </div>): <span></span>
//           }
// </div>
// </div>













//             </div>
//             </div>
// </div>
<div>
  <div className={Styles.web}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="donnees" />
  } 
  section2={
      <SecondSectionTablePage
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
      <div id={Styles.divHeaderBtn}>

                <div className={Styles.buttons}>
                    <button className={Styles.eachButton} onClick={btnActivateFichiers} id={inputActivateFichiers}>Fichiers</button>
                </div>
    
                <div className={Styles.buttons}>
                    <button className={Styles.eachButton} onClick={btnActivateDonnees} id={inputActivateDonnees}>Données</button>
                </div>
    
                <div className={Styles.buttons}>
                    <button className={Styles.eachButton} onClick={btnActivateAffectation} id={inputActivateAffectation}>Affectation</button>
                </div>
    
                <div className={Styles.buttons}>
                    <button className={Styles.eachButton} onClick={btnActivateCoordonnees} id={inputActivateCoordonnees}>Coordonnées</button>
                </div>
    
                <div className={Styles.buttons}>
                    <button className={Styles.eachButton} onClick={btnActivateCompetences} id={inputActivateCompetences}>Compétences</button>
                </div>
    
                <div className={Styles.buttons}>
                    <button className={Styles.eachButton} onClick={btnActivateIdBancaire} id={inputActivateIdBancaire}>ID.Bancaire</button>
                </div>
    
      </div>
    
  }
  section4={
                     <div id={Styles.idSection}>
            

<div className={Styles.classCenterSection}>
  <div className={Styles.classCenterSection2}>
             {
            activateFichiers && employeList && fichierList ? fichierList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(
              fcr => employeList.filter(emp => emp.matricule_employe === fcr.matricule_employe && emp.dateModification_employe === fcr.dateModification_employe).map(
  
                emp => 
  
            <div>

            {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
            
            <div className={Styles.classBtnCenter}>
              <div className={Styles.titleStyle}>
                <div className={Styles.titleZone}>
                <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceFichiers}>
                  Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                </button>
                </div>
                <div className={Styles.angleZone}>
                  <button className={Styles.icone} onClick={btnOccurenceFichiers} >
                    <i className='fas fa-angle-down'></i>
                    </button>
                </div>
            </div>
            </div>:

            <div className={Styles.classBtnCenter}>
               <div className={Styles.titleStyle}>
                <div className={Styles.titleZone}>
                  <button onClick={btnOccurenceFichiers} className={Styles.classBtnDonnees} value={emp.dateModification_employe}>
                    Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                  </button>
                </div>
                <div className={Styles.angleZone}>
                  <button className={Styles.iconeUp} onClick={btnOccurenceFichiers}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                  </button>
                </div>
            </div>
            </div>

            }
{(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
            
  <div className={Styles.aboveFichier}>

            <div className={Styles.classAffichageBtns}>

              <div  className={Styles.fichierZone} >
                <button className={Styles.buttonFichier} id={Styles.idBtnCarteVitale} onClick={btnCarteVitaleAfficher}>Carte vitale</button>
                {fcr.cin_employe ? 
                <button className={Styles.buttonFichier} id={Styles.idBtnCIN} onClick={btnCINAfficher}>CIN</button>
                : 
                <>
                {
                  fcr.ts_employe ? 
                  <button className={Styles.buttonFichier} id={Styles.idBtnTS} onClick={btnTSAfficher}>Titre de séjeur</button>
                  :<></>
                }
                </>
              }
                <button className={Styles.buttonFichier} id={Styles.idBtnCV} onClick={btnCVAfficher}>CV</button>
                <button className={Styles.buttonFichier} id={Styles.idBtnRIB} onClick={btnRIBAfficher}>RIB</button>
               </div>
            </div>
            
            <div className={Styles.classAffichageFichiers} > 
              {afficherCarteVitale ? <span  style={{margin:"auto"}}><Pdf url={fcr.carteVitale_employe} /></span>: <span></span>}
              {afficherCIN ? <Pdf url={fcr.cin_employe} /> : <span></span>}
              {afficherTS ? <Pdf url={fcr.ts_employe} /> : <span></span>}
              {afficherCV ? <Pdf url={fcr.cv_employe} /> : <span></span>}
              {afficherRIB ? <Pdf url={fcr.rib_employe} /> : <span></span>}
            </div>
      
               </div> 
               : <span></span>}
               <div class={Styles.centeredline}>
                  {/* <hr /> */}
                  <br />
               </div>
            
            </div>
  
              )
  
            )
            
            : <span>
              
            </span>
          }

</div>
</div>



<div className={Styles.classCenterSection}>
  
  <div className={Styles.classCenterSection2}>

  {
            activateDonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
            <div className={Styles.listZone}>
            {(afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
            
            <div className={Styles.classBtnCenter}>
               <div className={Styles.titleStyle} >
                <div className={Styles.titleZone} >
                  <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe}  onClick={btnOccurenceDonnees}>
                    Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                </button>
                </div>
                <div className={Styles.angleZone} >
                  <button className={Styles.icone} onClick={btnOccurenceDonnees} >
                        <i className='fas fa-angle-down'></i>
                  </button>
                  </div>
             </div>


            </div>:

            <div className={Styles.classBtnCenter} >
               <div className={Styles.titleStyle}>
                <div className={Styles.titleZone} >
                 <button className={Styles.classBtnDonnees} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe} >
                    Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")} 
                 </button>
                 </div>
                <div className={Styles.angleZone} >
               <button className={Styles.iconeUp} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe}>
                <i className='fas fa-angle-up'></i>
                </button>
                </div>
            </div>
            </div>
            
            }

            {
            
            (afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
           
           <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
              {/* <br /> */}
            <div className={Styles.sectionmobile}>
                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                                        Nom 
                            </div>
                            <div className={Styles.classdeuxieme}>
                              <span className={Styles.testGoldenWrap}>
                                          {emp.nom_employe}
                              </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Prénom
                            </div>
                            <div className={Styles.classdeuxieme}>
                              <span className={Styles.testGoldenWrap}>
                                {emp.prenom_employe}
                              </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Sexe 
                            </div>
                            <div className={Styles.classdeuxieme}>
                              <span className={Styles.testGoldenWrap}>
                              {emp.sexe_employe}
                              </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Date naissance 
                            </div>
                            <span className={Styles.testGoldenWrap}>
                                  {emp.departementNaissance_employe}
                            </span>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Pays de naissance 
                            </div>
                            <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.paysNaissance_employe}
                                  </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Département de naissance 
                            </div>
                            <div className={Styles.classdeuxieme}>
                            <span className={Styles.testGoldenWrap}>
                                 {emp.departementNaissance_employe}
                                </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Nationalité 1 
                            </div>
                            <div className={Styles.classdeuxieme}>
                            <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite1_employe}
                                </span>
                            </div>
                          </div>


                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Nationalité 2
                            </div>
                            <div className={Styles.classdeuxieme}>
                              <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite2_employe}
                                  </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Situation familiale
                            </div>
                            <div className={Styles.classdeuxieme}>
                            <span className={Styles.testGoldenWrap}>
                                  {emp.situationFamiliale_employe}
                                </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Numero SS
                            </div>
                            <div className={Styles.classdeuxieme}>
                            <span className={Styles.testGoldenWrap}>
                                 {emp.numeroSS_employe}
                                </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Clé
                            </div>
                            <div className={Styles.classdeuxieme}>
                            <span className={Styles.testGoldenWrap}>
                                 {emp.cle_employe}
                                </span>
                            </div>
                          </div>

                          <div className={Styles.classtotal}>
                            <div className={Styles.classpremier}>
                            Nombre d'enfants
                            </div>
                            <div className={Styles.classdeuxieme}>
                            <span className={Styles.testGoldenWrap}>
                                  {emp.nombreEnfant_employe}
                                </span>
                            </div>
                          </div>
            </div>
              <div className={Styles.classcentretotal}>
                <div className={Styles.classdroite}>

                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                             Nom 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                                 {emp.nom_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Prénom
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                      {emp.prenom_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Sexe 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.sexe_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date naissance 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Pays de naissance 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.paysNaissance_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Département de naissance 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                </div>
              </div>

                </div>

                <div></div>

                <div className={Styles.classgauche} >


                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nationalité 1 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite1_employe}
                    </span>
                </div>
              </div>


              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nationalité 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite2_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Situation familiale
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.situationFamiliale_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Numero SS
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.numeroSS_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Clé
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.cle_employe}
                    </span>
                </div>
              </div>

              <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nombre d'enfants
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.nombreEnfant_employe}
                    </span>
                </div>
              </div>

                </div>
              </div>
               {/* test */}
               </div>
               </div>

                : <span></span>}

               <div class={Styles.centeredline}>
                  {/* <hr /> */}
                  <br />
               </div>
            
            </div>): <span></span>
          }

  </div>
</div>


<div className={Styles.classCenterSection}>
  <div className={Styles.classCenterSection2}>
          {
            activateAffectation && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
            <div>
            {(afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
            

            <div className={Styles.classBtnCenter}>
              <div className={Styles.titleStyle} >
                <div className={Styles.titleZone} >
                  <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                    Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                  </button>
                  </div>
                <div className={Styles.angleZone} >
               <button className={Styles.icone} onClick={btnOccurenceAffectation} >
                    <i className='fas fa-angle-down'></i>
                    </button>
                  </div>
             </div>
            
              </div>:

            <div className={Styles.classBtnCenter}>
              <div className={Styles.titleStyle}>
                <div className={Styles.titleZone} >
                  <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                    Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button> 
                    </div>
                <div className={Styles.angleZone} >
               <button className={Styles.iconeUp} onClick={btnOccurenceAffectation}  value={emp.dateModification_employe}>
                <i className='fas fa-angle-up'></i>
                </button>
                </div>
            </div>

            </div>

            }

            {


            (afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
            
            <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
            {/* <br /> */}
              <div className={Styles.sectionmobile}>
              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Etablissement 
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Poste
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Type de contrat
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Jour de repos 1
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Jour de repos 2
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
              </div>
              </div>
              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Nbre heures hebdo
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Date de début
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Date de fin
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Mode de travail
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
              </div>
              </div>

              </div>
              <div className={Styles.classcentretotal}>


              <div className={Styles.classdroite}>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Etablissement 
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Poste
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
              </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Type de contrat
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Jour de repos 1
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Jour de repos 2
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
              </div>
              </div>

              </div>

              <div></div>

              <div className={Styles.classgauche} >

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Département
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.departement_employe}
                </span>
              </div>
              </div>
              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Nbre heures hebdo
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Date de début
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Date de fin
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Mode de travail
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
              </div>
              </div>

              </div>
              </div>
               </div>
               </div>
               
               : <span></span>}
               <div class={Styles.centeredline}>
                  {/* <hr /> */}
                  <br />
               </div>
            
            </div>): <span></span>
          }
  </div>
</div>
<div className={Styles.classCenterSection}>
  <div className={Styles.classCenterSection2}>
{
            activateCoordonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
            <div>
            {(afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
            
            <div className={Styles.classBtnCenter}>
              <div className={Styles.titleStyle}>
                <div className={Styles.titleZone} >
                  <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                    Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                  </button>
                  </div>
                <div className={Styles.angleZone} >
               <button className={Styles.icone} onClick={btnOccurenceCoordonnees} >
                    <i className='fas fa-angle-down'></i>
                    </button>
                  </div>
             </div>
              </div>:
              
            <div className={Styles.classBtnCenter}>
               <div className={Styles.titleStyle}>
                <div className={Styles.titleZone} >
                  <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                    Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                  </button>
                  </div>
                <div className={Styles.angleZone} >
               <button className={Styles.iconeUp} onClick={btnOccurenceCoordonnees}  value={emp.dateModification_employe}>
                <i className='fas fa-angle-up'></i>
                </button>
                </div>
            </div>
            </div>
}
{
    (afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
    <div className={Styles.affichagetaille}>
     <div className={Styles.affichageZone}>

                  {/* <br /> */}
    <div className={Styles.sectionmobile}>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Nom rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    N° rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Complément d'adresse
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.complementAdresse_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Chez
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code postale
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Ville
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone fixe
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone mobile
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    E-Mail
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
    </div>
    </div>

    </div>
    <div className={Styles.classcentretotal}>
    <div className={Styles.classdroite}>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Nom rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    N° rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Complément d'adresse
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
               {emp.complementAdresse_employe}
              </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Chez
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code postale
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
    </div>
    </div>

    </div>

    <div></div>

    <div className={Styles.classgauche} >


    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Ville
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone fixe
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone mobile
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
    </div>
    </div>

    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    E-Mail
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
    </div>
    </div>

    </div>
    </div>
    </div>
               </div> 
               : <span></span>}
               <div class={Styles.centeredline}>
                  {/* <hr /> */}
                  <br />
               </div>
            
            </div>): <span></span>
          }

</div>
</div>
<div className={Styles.classCenterSection}>
<div className={Styles.classCenterSection2}>
{

            activateCompetences && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
            <div>
            {
            (afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
            
            <div className={Styles.classBtnCenter}>
              <div className={Styles.titleStyle}>
                <div className={Styles.titleZone} >
                  <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>
                    Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                  </button>
              </div>
                <div className={Styles.angleZone} >
               <button className={Styles.icone} onClick={btnOccurenceCompetences} >
                    <i className='fas fa-angle-down'></i>
                    </button>
                  </div>
             </div>

              </div>:
              
              <div className={Styles.classBtnCenter}>
                 <div className={Styles.titleStyle}>
                <div className={Styles.titleZone} >
                  <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                  </button>
                  </div>
                <div className={Styles.angleZone} >
               <button className={Styles.iconeUp} onClick={btnOccurenceCompetences}  value={emp.dateModification_employe}>
                <i className='fas fa-angle-up'></i>
                </button>
                </div>
            </div>
              </div>
            }
            {(afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
            
            <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
              {/* <br /> */}
            <div className={Styles.sectionmobile}>

             <div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Formation 1
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.formation1_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Formation 2
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.formation2_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Formation 3
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.formation3_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Formation 4
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.formation4_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Expérience 1
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.experience1_employe}
    </span>
</div>
</div>
<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Expérience 2
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.experience2_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Expérience 3
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.experience3_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Expérience 4
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.experience4_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Langue 1 
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.langue1_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Langue 2
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.langue2_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Langue 3 
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.langue3_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Langue 4 
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.langue4_employe}
    </span>
</div>
</div>
<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Compétence
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.competence_employe}
    </span>
</div>
</div>
<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année formation 1
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
    </span></div>
</div>
<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année formation 2
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
    </span></div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année formation 3
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année formation 4
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année expérience 1
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année expérience 2
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année expérience 3
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Année expérience 4
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
    </span>
</div>
</div>



<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Niveau de langue 1
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.niveauLangue1_employe}
    </span>
</div>
</div>


<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Niveau de langue 2
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.niveauLangue2_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Niveau de langue 3
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.niveauLangue3_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Niveau de langue 4
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.niveauLangue4_employe}
    </span>
</div>
</div>
            </div>
            <div className={Styles.classcentretotal}>
            <div className={Styles.classdroite}>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation1_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation2_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation3_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation4_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience1_employe}
                </span>
            </div>
            </div>


            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience2_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience3_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience4_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 1 
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue1_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue2_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 3 
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue3_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 4 
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue4_employe}
                </span>
            </div>
            </div>


            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Compétence
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.competence_employe}
                </span>
            </div>
            </div>
            </div>
            <div></div>
            <div className={Styles.classgauche} >
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>



            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue1_employe}
                </span>
            </div>
            </div>


            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue2_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue3_employe}
                </span>
            </div>
            </div>

            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue4_employe}
                </span>
            </div>
            </div>

            </div>


            </div>
              </div>
               </div>
                : <span></span>}
               <div class={Styles.centeredline}>
                  {/* <hr /> */}
                  <br />
               </div>
            
            </div>): <span></span>
          }

</div>
</div>
<div className={Styles.classCenterSection}>
<div className={Styles.classCenterSection2}>

{
            activateIdBancaire && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
            <div>
            {(afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 

            <div className={Styles.classBtnCenter}>
              <div className={Styles.titleStyle} >
                <div className={Styles.titleZone} >
                  <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                    ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                  </button>
                  </div>
                <div className={Styles.angleZone} >
               <button className={Styles.icone} onClick={btnOccurenceIdBancaire} >
                    <i className='fas fa-angle-down'></i>
                    </button>
                  </div>
             </div>
            </div> :

            <div className={Styles.classBtnCenter}>
                <div className={Styles.titleStyle}>
                <div className={Styles.titleZone} >
            <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
              ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
            </button>
            </div>
                <div className={Styles.angleZone} >
               <button className={Styles.iconeUp} onClick={btnOccurenceIdBancaire}  value={emp.dateModification_employe}>
                <i className='fas fa-angle-up'></i>
                </button>
                </div>
            </div>
            </div>
}
{
(afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
              <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
              {/* <br /> */}
              <div className={Styles.sectionmobile}>
<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Banque
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.banque_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Domiciliation
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.domiciliation_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Numéro de compte
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.numeroCompte_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Code du banque
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.codeBanque_employe}
    </span>
</div>
</div>
<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Code du guichet
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.codeGuichet_employe}
    </span>
</div>
</div>


<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
Clé RIB
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.cleRib_employe}
    </span>
</div>
</div>

<div className={Styles.classtotal}>
<div className={Styles.classpremier}>
IBAN
</div>
<div className={Styles.classdeuxieme}>
<span className={Styles.testGoldenWrap}>
    {emp.iban_employe}
    </span>
</div>
</div>
              </div>
              <div className={Styles.classcentretotal}>
              <div className={Styles.classdroite}>
              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Banque
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                  {emp.banque_employe}
                  </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Domiciliation
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                  {emp.domiciliation_employe}
                  </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Numéro de compte
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                  {emp.numeroCompte_employe}
                  </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Code du banque
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                  {emp.codeBanque_employe}
                  </span>
              </div>
              </div>

              </div>

              <div></div>
              <div className={Styles.classgauche} >
              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Code du guichet
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                  {emp.codeGuichet_employe}
                  </span>
              </div>
              </div>
              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              Clé RIB
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                  {emp.cleRib_employe}
                  </span>
              </div>
              </div>

              <div className={Styles.classtotal}>
              <div className={Styles.classpremier}>
              IBAN
              </div>
              <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
                  {emp.iban_employe}
                  </span>
              </div>
              </div>
              </div>

              </div>
               </div>
               </div>
                : <span></span>}
               <div class={Styles.centeredline}>
                  {/* <hr /> */}
                  <br />
               </div>
            
            </div>): <span></span>
          }
</div>
</div>
</div>
  }  
  NbreSections={4} 
  prctgeSection1={8}
  prctgeSection2={6}
  prctgeSection3={6} 
  prctgeSection4={80}
  />
  </div>
  <div className={Styles.tablet}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="donnees" />
  } 
  section2={
      <SecondSectionTablePageTablet
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
<ScrollableButtons 
buttonsNames={buttonsList}
methods={methodsList}
ids={idList}
/>
  }
  section4={
    <div id={Styles.idSection}>
            

    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
                 {
                activateFichiers && employeList && fichierList ? fichierList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(
                  fcr => employeList.filter(emp => emp.matricule_employe === fcr.matricule_employe && emp.dateModification_employe === fcr.dateModification_employe).map(
      
                    emp => 
      
                <div>
    
                {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                    <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceFichiers}>
                      Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.icone} onClick={btnOccurenceFichiers} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                    </div>
                </div>
                </div>:
    
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                      <button onClick={btnOccurenceFichiers} className={Styles.classBtnDonnees} value={emp.dateModification_employe}>
                        Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.iconeUp} onClick={btnOccurenceFichiers}  value={emp.dateModification_employe}>
                        <i className='fas fa-angle-up'></i>
                      </button>
                    </div>
                </div>
                </div>
    
                }
    {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
      <div className={Styles.aboveFichier}>
    
                <div className={Styles.classAffichageBtns}>
    
                  <div  className={Styles.fichierZone} >
                    <button className={Styles.buttonFichier} id={Styles.idBtnCarteVitale} onClick={btnCarteVitaleAfficher}>Carte vitale</button>
                    {fcr.cin_employe ? 
                    <button className={Styles.buttonFichier} id={Styles.idBtnCIN} onClick={btnCINAfficher}>CIN</button>
                    : 
                    <>
                    {
                      fcr.ts_employe ? 
                      <button className={Styles.buttonFichier} id={Styles.idBtnTS} onClick={btnTSAfficher}>Titre de séjeur</button>
                      :<></>
                    }
                    </>
                  }
                    <button className={Styles.buttonFichier} id={Styles.idBtnCV} onClick={btnCVAfficher}>CV</button>
                    <button className={Styles.buttonFichier} id={Styles.idBtnRIB} onClick={btnRIBAfficher}>RIB</button>
                   </div>
                </div>
                
                <div className={Styles.classAffichageFichiers} > 
                  {afficherCarteVitale ? <span  style={{margin:"auto"}}><Pdf url={fcr.carteVitale_employe} /></span>: <span></span>}
                  {afficherCIN ? <Pdf url={fcr.cin_employe} /> : <span></span>}
                  {afficherTS ? <Pdf url={fcr.ts_employe} /> : <span></span>}
                  {afficherCV ? <Pdf url={fcr.cv_employe} /> : <span></span>}
                  {afficherRIB ? <Pdf url={fcr.rib_employe} /> : <span></span>}
                </div>
          
                   </div> 
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>
      
                  )
      
                )
                
                : <span>
                  
                </span>
              }
    
    </div>
    </div>
    
    
    
    <div className={Styles.classCenterSection}>
      
      <div className={Styles.classCenterSection2}>
    
      {
                activateDonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div className={Styles.listZone}>
                {(afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe}  onClick={btnOccurenceDonnees}>
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone} >
                      <button className={Styles.icone} onClick={btnOccurenceDonnees} >
                            <i className='fas fa-angle-down'></i>
                      </button>
                      </div>
                 </div>
    
    
                </div>:
    
                <div className={Styles.classBtnCenter} >
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                     <button className={Styles.classBtnDonnees} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe} >
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")} 
                     </button>
                     </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
                
                }
    
                {
                
                (afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
               
               <div className={Styles.affichagetaille}>
                <div className={Styles.affichageZone}>
                  {/* <br /> */}
                <div className={Styles.sectionmobile}>
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                            Nom 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                    {emp.nom_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Prénom
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.prenom_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Sexe 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.sexe_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Date naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.departementNaissance_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Pays de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.paysNaissance_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Département de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.departementNaissance_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 1 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite1_employe}
                                </span>
                                </div>
                              </div>
    
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 2
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite2_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Situation familiale
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.situationFamiliale_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Numero SS
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.numeroSS_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Clé
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.cle_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nombre d'enfants
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nombreEnfant_employe}
                                </span>
                                </div>
                              </div>
                </div>
                  <div className={Styles.classcentretotal}>
                    <div className={Styles.classdroite}>
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                                 Nom 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                                 {emp.nom_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Prénom
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                      {emp.prenom_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Sexe 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.sexe_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Date naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Pays de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.paysNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Département de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                    </div>
    
                    <div></div>
    
                    <div className={Styles.classgauche} >
    
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 1 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite1_employe}
                    </span>
                    </div>
                  </div>
    
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 2
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite2_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Situation familiale
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.situationFamiliale_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Numero SS
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.numeroSS_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Clé
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.cle_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nombre d'enfants
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nombreEnfant_employe}
                    </span>
                    </div>
                  </div>
    
                    </div>
                  </div>
                   {/* test */}
                   </div>
                   </div>
    
                    : <span></span>}
    
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
      </div>
    </div>
    
    
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
              {
                activateAffectation && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceAffectation} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                
                  </div>:
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                        </button> 
                        </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceAffectation}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
    
                </div>
    
                }
    
                {
    
    
                (afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
                <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                {/* <br /> */}
                <div className={Styles.sectionmobile}>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Etablissement 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Poste
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Type de contrat
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nbre heures hebdo
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de début
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de fin
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Mode de travail
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
                </div>
                </div>
                
                </div>
                <div className={Styles.classcentretotal}>
                
                
                <div className={Styles.classdroite}>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Etablissement 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Poste
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Type de contrat
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
                </div>
                </div>
                
                </div>
                
                <div></div>
                
                <div className={Styles.classgauche} >
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Département
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departement_employe}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nbre heures hebdo
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de début
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de fin
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Mode de travail
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
                </div>
                </div>
                
                </div>
                </div>
                   </div> 
                   </div>
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
      </div>
    </div>
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
    {
                activateCoordonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCoordonnees} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                  </div>:
                  
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCoordonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
    (afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
    <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                          {/* <br /> */}
            <div className={Styles.sectionmobile}>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Nom rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            N° rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Complément d'adresse
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.complementAdresse_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Chez
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Code postale
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Ville
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone fixe
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone mobile
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            E-Mail
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
            </div>
            </div>
            
            </div>
            <div className={Styles.classcentretotal}>
            <div className={Styles.classdroite}>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Nom rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            N° rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Complément d'adresse
            </div>
            <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
               {emp.complementAdresse_employe}
              </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Chez
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Code postale
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
            </div>
            </div>
            
            </div>
            
            <div></div>
            
            <div className={Styles.classgauche} >
            
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Ville
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone fixe
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone mobile
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            E-Mail
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
            </div>
            </div>
            
            </div>
            </div>
                   </div>
                   </div>
                    : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    {
    
                activateCompetences && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {
                (afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>
                        Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                  </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCompetences} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
    
                  </div>:
                  
                  <div className={Styles.classBtnCenter}>
                     <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCompetences}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                  </div>
                }
                {(afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                  {/* <br /> */}
               <div className={Styles.sectionmobile}>
    
                 <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation1_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation4_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience1_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience4_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 1 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue1_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 3 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 4 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue4_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Compétence
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.competence_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue1_employe}
    </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue4_employe}
    </span>
    </div>
    </div>
                </div>
                <div className={Styles.classcentretotal}>
                <div className={Styles.classdroite}>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation4_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience1_employe}
                </span>
                </div>
                </div>
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience4_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 1 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 3 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 4 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue4_employe}
                </span>
                </div>
                </div>
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Compétence
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.competence_employe}
                </span>
                </div>
                </div>
                </div>
                <div></div>
                <div className={Styles.classgauche} >
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue1_employe}
                </span>
                </div>
                </div>
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue4_employe}
                </span>
                </div>
                </div>
                
                </div>
                
                
                </div>
                </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    
    {
                activateIdBancaire && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                        ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceIdBancaire} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                </div> :
    
                <div className={Styles.classBtnCenter}>
                    <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                  ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                </button>
                </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceIdBancaire}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
    (afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
                  <div className={Styles.affichagetaille}>
                  <div className={Styles.affichageZone}>
                  {/* <br /> */}
                  <div className={Styles.sectionmobile}>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.banque_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Domiciliation
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.domiciliation_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Numéro de compte
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.numeroCompte_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.codeBanque_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du guichet
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.codeGuichet_employe}
    </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Clé RIB
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.cleRib_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    IBAN
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.iban_employe}
    </span>
    </div>
    </div>
                  </div>
                  <div className={Styles.classcentretotal}>
                  <div className={Styles.classdroite}>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.banque_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Domiciliation
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.domiciliation_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Numéro de compte
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.numeroCompte_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.codeBanque_employe}
                  </span>
                  </div>
                  </div>
                  
                  </div>
                  
                  <div></div>
                  <div className={Styles.classgauche} >
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du guichet
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.codeGuichet_employe}
                  </span>
                  </div>
                  </div>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Clé RIB
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.cleRib_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  IBAN
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.iban_employe}
                  </span>
                  </div>
                  </div>
                  </div>
                  
                  </div>
                  </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    </div>
    </div>
    </div>
  }  
  NbreSections={4} 
  prctgeSection1={8}
  prctgeSection2={6} 
  prctgeSection3={6} 
  prctgeSection4={80}
  />
  </div>
  <div className={Styles.mobile}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="donnees" />
  } 
  section2={
      <SecondSectionTablePageMobile
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
<ScrollableButtonsTwo 
buttonsNames={buttonsList}
methods={methodsList}
ids={idList}
/>
  }
  section4={
    <div id={Styles.idSection}>
            

    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
                 {
                activateFichiers && employeList && fichierList ? fichierList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(
                  fcr => employeList.filter(emp => emp.matricule_employe === fcr.matricule_employe && emp.dateModification_employe === fcr.dateModification_employe).map(
      
                    emp => 
      
                <div>
    
                {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                    <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceFichiers}>
                      Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.icone} onClick={btnOccurenceFichiers} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                    </div>
                </div>
                </div>:
    
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                      <button onClick={btnOccurenceFichiers} className={Styles.classBtnDonnees} value={emp.dateModification_employe}>
                        Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.iconeUp} onClick={btnOccurenceFichiers}  value={emp.dateModification_employe}>
                        <i className='fas fa-angle-up'></i>
                      </button>
                    </div>
                </div>
                </div>
    
                }
    {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
      <div className={Styles.aboveFichier}>
    
                <div className={Styles.classAffichageBtns}>
    
                  <div  className={Styles.fichierZone} >
                    <button className={Styles.buttonFichier} id={Styles.idBtnCarteVitale} onClick={btnCarteVitaleAfficher}>Carte vitale</button>
                    {fcr.cin_employe ? 
                    <button className={Styles.buttonFichier} id={Styles.idBtnCIN} onClick={btnCINAfficher}>CIN</button>
                    : 
                    <>
                    {
                      fcr.ts_employe ? 
                      <button className={Styles.buttonFichier} id={Styles.idBtnTS} onClick={btnTSAfficher}>Titre de séjeur</button>
                      :<></>
                    }
                    </>
                  }
                    <button className={Styles.buttonFichier} id={Styles.idBtnCV} onClick={btnCVAfficher}>CV</button>
                    <button className={Styles.buttonFichier} id={Styles.idBtnRIB} onClick={btnRIBAfficher}>RIB</button>
                   </div>
                </div>
                
                <div className={Styles.classAffichageFichiers} > 
                  {afficherCarteVitale ? <span  style={{margin:"auto"}}><Pdf url={fcr.carteVitale_employe} /></span>: <span></span>}
                  {afficherCIN ? <Pdf url={fcr.cin_employe} /> : <span></span>}
                  {afficherTS ? <Pdf url={fcr.ts_employe} /> : <span></span>}
                  {afficherCV ? <Pdf url={fcr.cv_employe} /> : <span></span>}
                  {afficherRIB ? <Pdf url={fcr.rib_employe} /> : <span></span>}
                </div>
          
                   </div> 
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>
      
                  )
      
                )
                
                : <span>
                  
                </span>
              }
    
    </div>
    </div>
    
    
    
    <div className={Styles.classCenterSection}>
      
      <div className={Styles.classCenterSection2}>
    
      {
                activateDonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div className={Styles.listZone}>
                {(afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe}  onClick={btnOccurenceDonnees}>
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone} >
                      <button className={Styles.icone} onClick={btnOccurenceDonnees} >
                            <i className='fas fa-angle-down'></i>
                      </button>
                      </div>
                 </div>
    
    
                </div>:
    
                <div className={Styles.classBtnCenter} >
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                     <button className={Styles.classBtnDonnees} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe} >
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")} 
                     </button>
                     </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
                
                }
    
                {
                
                (afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
               
               <div className={Styles.affichagetaille}>
                <div className={Styles.affichageZone}>
                  {/* <br /> */}
                <div className={Styles.sectionmobile}>
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                            Nom 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                    {emp.nom_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Prénom
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.prenom_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Sexe 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.sexe_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Date naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.departementNaissance_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Pays de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.paysNaissance_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Département de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.departementNaissance_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 1 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite1_employe}
                                </span>
                                </div>
                              </div>
    
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 2
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite2_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Situation familiale
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.situationFamiliale_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Numero SS
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.numeroSS_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Clé
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.cle_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nombre d'enfants
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nombreEnfant_employe}
                                </span>
                                </div>
                              </div>
                </div>
                <div className={Styles.classcentretotal}>
                    <div className={Styles.classdroite}>
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                                 Nom 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                                 {emp.nom_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Prénom
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                      {emp.prenom_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Sexe 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.sexe_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Date naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Pays de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.paysNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Département de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                    </div>
    
                    <div></div>
    
                    <div className={Styles.classgauche} >
    
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 1 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite1_employe}
                    </span>
                    </div>
                  </div>
    
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 2
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite2_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Situation familiale
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.situationFamiliale_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Numero SS
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.numeroSS_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Clé
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.cle_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nombre d'enfants
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nombreEnfant_employe}
                    </span>
                    </div>
                  </div>
    
                    </div>
                  </div>
                   {/* test */}
                   </div>
                   </div>
    
                    : <span></span>}
    
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
      </div>
    </div>
    
    
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
              {
                activateAffectation && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceAffectation} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                
                  </div>:
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                        </button> 
                        </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceAffectation}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
    
                </div>
    
                }
    
                {
    
    
                (afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
                <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                {/* <br /> */}
                <div className={Styles.sectionmobile}>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Etablissement 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Poste
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Type de contrat
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nbre heures hebdo
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de début
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
                 </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de fin
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>
               </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Mode de travail
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
                </div>
                </div>
                
                </div>
                <div className={Styles.classcentretotal}>
                
                
                <div className={Styles.classdroite}>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Etablissement 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Poste
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Type de contrat
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
                </div>
                </div>
                
                </div>
                
                <div></div>
                
                <div className={Styles.classgauche} >
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Département
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departement_employe}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nbre heures hebdo
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de début
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de fin
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Mode de travail
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
                </div>
                </div>
                
                </div>
                </div>
                </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
      </div>
    </div>
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
    {
                activateCoordonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCoordonnees} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                  </div>:
                  
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCoordonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
    (afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
    <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                  {/* <br /> */}
    <div className={Styles.sectionmobile}>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Nom rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    N° rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Complément d'adresse 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.complementAdresse_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Chez
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code postale
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Ville
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone fixe
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone mobile
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    E-Mail
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
    </div>
    </div>
    
    </div>
    <div className={Styles.classcentretotal}>
    <div className={Styles.classdroite}>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Nom rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    N° rue/avenue
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Complément d'adresse 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
               {emp.complementAdresse_employe}
              </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Chez
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code postale
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
    </div>
    </div>
    
    </div>
    
    <div></div>
    
    <div className={Styles.classgauche} >
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Ville
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone fixe
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Télephone mobile
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    E-Mail
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
    </div>
    </div>
    
    </div>
    </div>
    </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    {
    
                activateCompetences && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {
                (afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>
                        Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                  </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCompetences} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
    
                  </div>:
                  
                  <div className={Styles.classBtnCenter}>
                     <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCompetences}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                  </div>
                }
                {(afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                  {/* <br /> */}
             <div className={Styles.sectionmobile}>
    
              <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation1_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation4_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience1_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience4_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 1 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue1_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 3 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 4 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue4_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Compétence
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.competence_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue1_employe}
    </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue4_employe}
    </span>
    </div>
    </div>
              </div>
            <div className={Styles.classcentretotal}>
            <div className={Styles.classdroite}>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation1_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation2_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation3_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Formation 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.formation4_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience1_employe}
                </span>
            </div>
            </div>
            
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience2_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience3_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Expérience 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.experience4_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 1 
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue1_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue2_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 3 
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue3_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Langue 4 
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.langue4_employe}
                </span>
            </div>
            </div>
            
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Compétence
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.competence_employe}
                </span>
            </div>
            </div>
            </div>
            <div></div>
            <div className={Styles.classgauche} >
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année formation 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Année expérience 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
                </span>
            </div>
            </div>
            
            
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 1
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue1_employe}
                </span>
            </div>
            </div>
            
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 2
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue2_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 3
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue3_employe}
                </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Niveau de langue 4
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue4_employe}
                </span>
            </div>
            </div>
            
            </div>
            
            
            </div>
            </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    
    {
                activateIdBancaire && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                        ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceIdBancaire} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                </div> :
    
                <div className={Styles.classBtnCenter}>
                    <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                  ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                </button>
                </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceIdBancaire}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
    (afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
                  <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                  {/* <br /> */}
                  <div className={Styles.sectionmobile}>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.banque_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Domiciliation
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.domiciliation_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Numéro de compte
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.numeroCompte_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.codeBanque_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du guichet
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.codeGuichet_employe}
    </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Clé RIB
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.cleRib_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    IBAN
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.iban_employe}
    </span>
    </div>
    </div>
                  </div>
                  <div className={Styles.classcentretotal}>
                  <div className={Styles.classdroite}>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.banque_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Domiciliation
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.domiciliation_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Numéro de compte
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.numeroCompte_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.codeBanque_employe}
                  </span>
                  </div>
                  </div>
                  
                  </div>
                  
                  <div></div>
                  <div className={Styles.classgauche} >
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du guichet
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.codeGuichet_employe}
                  </span>
                  </div>
                  </div>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Clé RIB
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.cleRib_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  IBAN
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.iban_employe}
                  </span>
                  </div>
                  </div>
                  </div>
                  
                  </div>
                  </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    </div>
    </div>
    </div>
  }  
  NbreSections={4} 
  prctgeSection1={7}
  prctgeSection2={6} 
  prctgeSection3={6} 
  prctgeSection4={81}
  />
  </div>
  <div className={Styles.mobileInversePetit}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="donnees" />
  } 
  section2={
      <SecondSectionTablePageMobileInversePetit
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
<ScrollableButtons 
buttonsNames={buttonsList}
methods={methodsList}
ids={idList}
/>
  }
  section4={
    <div id={Styles.idSection}>
            

    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
                 {
                activateFichiers && employeList && fichierList ? fichierList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(
                  fcr => employeList.filter(emp => emp.matricule_employe === fcr.matricule_employe && emp.dateModification_employe === fcr.dateModification_employe).map(
      
                    emp => 
      
                <div>
    
                {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                    <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceFichiers}>
                      Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.icone} onClick={btnOccurenceFichiers} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                    </div>
                </div>
                </div>:
    
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                      <button onClick={btnOccurenceFichiers} className={Styles.classBtnDonnees} value={emp.dateModification_employe}>
                        Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.iconeUp} onClick={btnOccurenceFichiers}  value={emp.dateModification_employe}>
                        <i className='fas fa-angle-up'></i>
                      </button>
                    </div>
                </div>
                </div>
    
                }
    {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
      <div className={Styles.aboveFichier}>
    
                <div className={Styles.classAffichageBtns}>
    
                  <div  className={Styles.fichierZone} >
                    <button className={Styles.buttonFichier} id={Styles.idBtnCarteVitale} onClick={btnCarteVitaleAfficher}>Carte vitale</button>
                    {fcr.cin_employe ? 
                    <button className={Styles.buttonFichier} id={Styles.idBtnCIN} onClick={btnCINAfficher}>CIN</button>
                    : 
                    <>
                    {
                      fcr.ts_employe ? 
                      <button className={Styles.buttonFichier} id={Styles.idBtnTS} onClick={btnTSAfficher}>Titre de séjeur</button>
                      :<></>
                    }
                    </>
                  }
                    <button className={Styles.buttonFichier} id={Styles.idBtnCV} onClick={btnCVAfficher}>CV</button>
                    <button className={Styles.buttonFichier} id={Styles.idBtnRIB} onClick={btnRIBAfficher}>RIB</button>
                   </div>
                </div>
                
                <div className={Styles.classAffichageFichiers} > 
                  {afficherCarteVitale ? <span  style={{margin:"auto"}}><Pdf url={fcr.carteVitale_employe} /></span>: <span></span>}
                  {afficherCIN ? <Pdf url={fcr.cin_employe} /> : <span></span>}
                  {afficherTS ? <Pdf url={fcr.ts_employe} /> : <span></span>}
                  {afficherCV ? <Pdf url={fcr.cv_employe} /> : <span></span>}
                  {afficherRIB ? <Pdf url={fcr.rib_employe} /> : <span></span>}
                </div>
          
                   </div> 
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>
      
                  )
      
                )
                
                : <span>
                  
                </span>
              }
    
    </div>
    </div>
    
    
    
    <div className={Styles.classCenterSection}>
      
      <div className={Styles.classCenterSection2}>
    
      {
                activateDonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div className={Styles.listZone}>
                {(afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe}  onClick={btnOccurenceDonnees}>
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone} >
                      <button className={Styles.icone} onClick={btnOccurenceDonnees} >
                            <i className='fas fa-angle-down'></i>
                      </button>
                      </div>
                 </div>
    
    
                </div>:
    
                <div className={Styles.classBtnCenter} >
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                     <button className={Styles.classBtnDonnees} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe} >
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")} 
                     </button>
                     </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
                
                }
    
                {
                
                (afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
               
               <div className={Styles.affichagetaille}>
                <div className={Styles.affichageZone}>
                  {/* <br /> */}
                <div className={Styles.sectionmobile}>
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                            Nom 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                    {emp.nom_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Prénom
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.prenom_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Sexe 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.sexe_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Date naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.departementNaissance_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Pays de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.paysNaissance_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Département de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.departementNaissance_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 1 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite1_employe}
                                </span>
                                </div>
                              </div>
    
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 2
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nationnalite2_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Situation familiale
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.situationFamiliale_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Numero SS
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.numeroSS_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Clé
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                 {emp.cle_employe}
                                </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nombre d'enfants
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                  {emp.nombreEnfant_employe}
                                </span>
                                </div>
                              </div>
                </div>
                  <div className={Styles.classcentretotal}>
                    <div className={Styles.classdroite}>
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                                 Nom 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                                 {emp.nom_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Prénom
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                      {emp.prenom_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Sexe 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.sexe_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Date naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Pays de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.paysNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Département de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                    </div>
                  </div>
    
                    </div>
    
                    <div></div>
    
                    <div className={Styles.classgauche} >
    
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 1 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite1_employe}
                    </span>
                    </div>
                  </div>
    
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 2
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nationnalite2_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Situation familiale
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.situationFamiliale_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Numero SS
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.numeroSS_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Clé
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.cle_employe}
                    </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nombre d'enfants
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                    {emp.nombreEnfant_employe}
                    </span>
                    </div>
                  </div>
    
                    </div>
                  </div>
                   {/* test */}
                   </div>
                   </div>
    
                    : <span></span>}
    
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
      </div>
    </div>
    
    
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
              {
                activateAffectation && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceAffectation} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                
                  </div>:
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                        </button> 
                        </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceAffectation}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
    
                </div>
    
                }
    
                {
    
    
                (afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
                <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                {/* <br /> */}
                <div className={Styles.sectionmobile}>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Etablissement 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Poste
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Type de contrat
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nbre heures hebdo
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de début
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de fin
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Mode de travail
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
                </div>
                </div>
                
                </div>
                <div className={Styles.classcentretotal}>
                
                
                <div className={Styles.classdroite}>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Etablissement 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.etablissement_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Poste
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.poste_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Type de contrat
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departementNaissance_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Jour de repos 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.jourRepos2_employe}
                </span>
                </div>
                </div>
                
                </div>
                
                <div></div>
                
                <div className={Styles.classgauche} >
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Département
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.departement_employe}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Nbre heures hebdo
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.plageHoraire_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de début
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Date de fin
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Mode de travail
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.modeTravail_employe}
                </span>
                </div>
                </div>
                
                </div>
                </div>
                   </div> 
                   </div>
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
      </div>
    </div>
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
    {
                activateCoordonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCoordonnees} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                  </div>:
                  
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCoordonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
    (afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
    <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                          {/* <br /> */}
            <div className={Styles.sectionmobile}>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Nom rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            N° rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Complément d'adresse
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.complementAdresse_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Chez
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Code postale
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Ville
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone fixe
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone mobile
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            E-Mail
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
            </div>
            </div>
            
            </div>
            <div className={Styles.classcentretotal}>
            <div className={Styles.classdroite}>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Nom rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.nomRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            N° rue/avenue
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.NRue_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Complément d'adresse
            </div>
            <div className={Styles.classdeuxieme}>
              <span className={Styles.testGoldenWrap}>
               {emp.complementAdresse_employe}
              </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Chez
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.pays_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Code postale
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.codePostale_employe}
            </span>
            </div>
            </div>
            
            </div>
            
            <div></div>
            
            <div className={Styles.classgauche} >
            
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Ville
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.ville_employe}
            </span>
            </div>
            </div>
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone fixe
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.telephoneFixe_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            Télephone mobile
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.tel_employe}
            </span>
            </div>
            </div>
            
            <div className={Styles.classtotal}>
            <div className={Styles.classpremier}>
            E-Mail
            </div>
            <div className={Styles.classdeuxieme}>
            <span className={Styles.testGoldenWrap}>
            {emp.email_employe}
            </span>
            </div>
            </div>
            
            </div>
            </div>
                   </div>
                   </div>
                    : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    {
    
                activateCompetences && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {
                (afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>
                        Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                  </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCompetences} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
    
                  </div>:
                  
                  <div className={Styles.classBtnCenter}>
                     <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCompetences}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                  </div>
                }
                {(afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.affichagetaille}>
            <div className={Styles.affichageZone}>
                  {/* <br /> */}
               <div className={Styles.sectionmobile}>
    
                 <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation1_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.formation4_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience1_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.experience4_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 1 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue1_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 3 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 4 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.langue4_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Compétence
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.competence_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
    </span>
    </div>
    </div>
    
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue1_employe}
    </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue2_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue3_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.niveauLangue4_employe}
    </span>
    </div>
    </div>
                </div>
                <div className={Styles.classcentretotal}>
                <div className={Styles.classdroite}>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.formation4_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience1_employe}
                </span>
                </div>
                </div>
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.experience4_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 1 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue1_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 3 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 4 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.langue4_employe}
                </span>
                </div>
                </div>
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Compétence
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.competence_employe}
                </span>
                </div>
                </div>
                </div>
                <div></div>
                <div className={Styles.classgauche} >
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
                </span>
                </div>
                </div>
                
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue1_employe}
                </span>
                </div>
                </div>
                
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue2_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue3_employe}
                </span>
                </div>
                </div>
                
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                {emp.niveauLangue4_employe}
                </span>
                </div>
                </div>
                
                </div>
                
                
                </div>
                </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    
    {
                activateIdBancaire && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                        ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceIdBancaire} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                </div> :
    
                <div className={Styles.classBtnCenter}>
                    <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                  ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                </button>
                </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceIdBancaire}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
    (afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
                  <div className={Styles.affichagetaille}>
                  <div className={Styles.affichageZone}>
                  {/* <br /> */}
                  <div className={Styles.sectionmobile}>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.banque_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Domiciliation
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.domiciliation_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Numéro de compte
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.numeroCompte_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.codeBanque_employe}
    </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du guichet
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.codeGuichet_employe}
    </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Clé RIB
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.cleRib_employe}
    </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    IBAN
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
    {emp.iban_employe}
    </span>
    </div>
    </div>
                  </div>
                  <div className={Styles.classcentretotal}>
                  <div className={Styles.classdroite}>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.banque_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Domiciliation
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.domiciliation_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Numéro de compte
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.numeroCompte_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.codeBanque_employe}
                  </span>
                  </div>
                  </div>
                  
                  </div>
                  
                  <div></div>
                  <div className={Styles.classgauche} >
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du guichet
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.codeGuichet_employe}
                  </span>
                  </div>
                  </div>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Clé RIB
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.cleRib_employe}
                  </span>
                  </div>
                  </div>
                  
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  IBAN
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                  {emp.iban_employe}
                  </span>
                  </div>
                  </div>
                  </div>
                  
                  </div>
                  </div>
                   </div> : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    </div>
    </div>
    </div>
  }  
  NbreSections={4} 
  prctgeSection1={10}
  prctgeSection2={9} 
  prctgeSection3={9} 
  prctgeSection4={72}
  />
  </div>
  <div className={Styles.mobileInverseGrand}>

  <GeneralCardTest 
  section1={
  <TopMenuSalarie page="donnees" />
  } 
  section2={
      <SecondSectionTablePageMobileInverseGrand
      nbreComponents={1}
      component1={<DescriptionComponent/>
      }/>
  }
  section3={
    <div id={Styles.divHeaderBtn}>

    <div className={Styles.buttons}>
        <button className={Styles.eachButton} onClick={btnActivateFichiers} id={inputActivateFichiers}>Fichiers</button>
    </div>

    <div className={Styles.buttons}>
        <button className={Styles.eachButton} onClick={btnActivateDonnees} id={inputActivateDonnees}>Données</button>
    </div>

    <div className={Styles.buttons}>
        <button className={Styles.eachButton} onClick={btnActivateAffectation} id={inputActivateAffectation}>Affectation</button>
    </div>

    <div className={Styles.buttons}>
        <button className={Styles.eachButton} onClick={btnActivateCoordonnees} id={inputActivateCoordonnees}>Coordonnées</button>
    </div>

    <div className={Styles.buttons}>
        <button className={Styles.eachButton} onClick={btnActivateCompetences} id={inputActivateCompetences}>Compétences</button>
    </div>

    <div className={Styles.buttons}>
        <button className={Styles.eachButton} onClick={btnActivateIdBancaire} id={inputActivateIdBancaire}>ID.Bancaire</button>
    </div>

</div>
  }
  section4={
    <div id={Styles.idSection}>
            

    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
                 {
                activateFichiers && employeList && fichierList ? fichierList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(
                  fcr => employeList.filter(emp => emp.matricule_employe === fcr.matricule_employe && emp.dateModification_employe === fcr.dateModification_employe).map(
      
                    emp => 
      
                <div>
    
                {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                    <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceFichiers}>
                      Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.icone} onClick={btnOccurenceFichiers} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                    </div>
                </div>
                </div>:
    
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone}>
                      <button onClick={btnOccurenceFichiers} className={Styles.classBtnDonnees} value={emp.dateModification_employe}>
                        Fichiers {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                    </div>
                    <div className={Styles.angleZone}>
                      <button className={Styles.iconeUp} onClick={btnOccurenceFichiers}  value={emp.dateModification_employe}>
                        <i className='fas fa-angle-up'></i>
                      </button>
                    </div>
                </div>
                </div>
    
                }
    {(afficherFichiers && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeFichiers === true) ? 
                
      <div className={Styles.aboveFichier}>
    
                <div className={Styles.classAffichageBtns}>
    
                  <div  className={Styles.fichierZone} >
                    <button className={Styles.buttonFichier} id={Styles.idBtnCarteVitale} onClick={btnCarteVitaleAfficher}>Carte vitale</button>
                    {fcr.cin_employe ? 
                    <button className={Styles.buttonFichier} id={Styles.idBtnCIN} onClick={btnCINAfficher}>CIN</button>
                    : 
                    <>
                    {
                      fcr.ts_employe ? 
                      <button className={Styles.buttonFichier} id={Styles.idBtnTS} onClick={btnTSAfficher}>Titre de séjeur</button>
                      :<></>
                    }
                    </>
                  }
                    <button className={Styles.buttonFichier} id={Styles.idBtnCV} onClick={btnCVAfficher}>CV</button>
                    <button className={Styles.buttonFichier} id={Styles.idBtnRIB} onClick={btnRIBAfficher}>RIB</button>
                   </div>
                </div>
                
                <div className={Styles.classAffichageFichiers} > 
                  {afficherCarteVitale ? <span  style={{margin:"auto"}}><Pdf url={fcr.carteVitale_employe} /></span>: <span></span>}
                  {afficherCIN ? <Pdf url={fcr.cin_employe} /> : <span></span>}
                  {afficherTS ? <Pdf url={fcr.ts_employe} /> : <span></span>}
                  {afficherCV ? <Pdf url={fcr.cv_employe} /> : <span></span>}
                  {afficherRIB ? <Pdf url={fcr.rib_employe} /> : <span></span>}
                </div>
          
                   </div> 
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>
      
                  )
      
                )
                
                : <span>
                  
                </span>
              }
    
    </div>
    </div>
    
    
    
    <div className={Styles.classCenterSection}>
      
      <div className={Styles.classCenterSection2}>
    
      {
                activateDonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div className={Styles.listZone}>
                {(afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe}  onClick={btnOccurenceDonnees}>
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                    </button>
                    </div>
                    <div className={Styles.angleZone} >
                      <button className={Styles.icone} onClick={btnOccurenceDonnees} >
                            <i className='fas fa-angle-down'></i>
                      </button>
                      </div>
                 </div>
    
    
                </div>:
    
                <div className={Styles.classBtnCenter} >
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                     <button className={Styles.classBtnDonnees} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe} >
                        Données {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")} 
                     </button>
                     </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceDonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
                
                }
    
                {
                
                (afficherDonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeDonnees === true) ? 
               
               <div className={Styles.affichagetaille}>
                <div className={Styles.affichageZone}>
                  {/* <br /> */}
                <div className={Styles.sectionmobile}>
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                            Nom 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                  <span className={Styles.testGoldenWrap}>
                                              {emp.nom_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Prénom
                                </div>
                                <div className={Styles.classdeuxieme}>
                                  <span className={Styles.testGoldenWrap}>
                                    {emp.prenom_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Sexe 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                  <span className={Styles.testGoldenWrap}>
                                  {emp.sexe_employe}
                                  </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Date naissance 
                                </div>
                                <span className={Styles.testGoldenWrap}>
                                      {emp.departementNaissance_employe}
                                </span>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Pays de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                    <span className={Styles.testGoldenWrap}>
                                      {emp.paysNaissance_employe}
                                      </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Département de naissance 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                     {emp.departementNaissance_employe}
                                    </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 1 
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                      {emp.nationnalite1_employe}
                                    </span>
                                </div>
                              </div>
    
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nationalité 2
                                </div>
                                <div className={Styles.classdeuxieme}>
                                  <span className={Styles.testGoldenWrap}>
                                      {emp.nationnalite2_employe}
                                      </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Situation familiale
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                      {emp.situationFamiliale_employe}
                                    </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Numero SS
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                     {emp.numeroSS_employe}
                                    </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Clé
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                     {emp.cle_employe}
                                    </span>
                                </div>
                              </div>
    
                              <div className={Styles.classtotal}>
                                <div className={Styles.classpremier}>
                                Nombre d'enfants
                                </div>
                                <div className={Styles.classdeuxieme}>
                                <span className={Styles.testGoldenWrap}>
                                      {emp.nombreEnfant_employe}
                                    </span>
                                </div>
                              </div>
                </div>
                  <div className={Styles.classcentretotal}>
                    <div className={Styles.classdroite}>
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                                 Nom 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                                     {emp.nom_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Prénom
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                          {emp.prenom_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Sexe 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.sexe_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Date naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.departementNaissance_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Pays de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.paysNaissance_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Département de naissance 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.departementNaissance_employe}
                        </span>
                    </div>
                  </div>
    
                    </div>
    
                    <div></div>
    
                    <div className={Styles.classgauche} >
    
    
                    <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 1 
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.nationnalite1_employe}
                        </span>
                    </div>
                  </div>
    
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nationalité 2
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.nationnalite2_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Situation familiale
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.situationFamiliale_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Numero SS
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.numeroSS_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Clé
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.cle_employe}
                        </span>
                    </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                    <div className={Styles.classpremier}>
                    Nombre d'enfants
                    </div>
                    <div className={Styles.classdeuxieme}>
                    <span className={Styles.testGoldenWrap}>
                        {emp.nombreEnfant_employe}
                        </span>
                    </div>
                  </div>
    
                    </div>
                  </div>
                   {/* test */}
                   </div>
                   </div>
    
                    : <span></span>}
    
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
      </div>
    </div>
    
    
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
              {
                activateAffectation && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceAffectation} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                
                  </div>:
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceAffectation}>
                        Affectation {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                        </button> 
                        </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceAffectation}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
    
                </div>
    
                }
    
                {
    
    
                (afficherAffectation && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeAffectation === true) ? 
                
                <div className={Styles.affichagetaille}>
                <div className={Styles.affichageZone}>
                {/* <br /> */}
                  <div className={Styles.sectionmobile}>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Etablissement 
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.etablissement_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Poste
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.poste_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Type de contrat
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Jour de repos 1
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.jourRepos1_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Jour de repos 2
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.jourRepos2_employe}
                    </span>
                  </div>
                  </div>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Nbre heures hebdo
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.plageHoraire_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Date de début
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Date de fin
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                    </span>              </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Mode de travail
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.modeTravail_employe}
                    </span>
                  </div>
                  </div>
    
                  </div>
                  <div className={Styles.classcentretotal}>
    
    
                  <div className={Styles.classdroite}>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Etablissement 
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.etablissement_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Poste
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.poste_employe}
                  </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Type de contrat
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.departementNaissance_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Jour de repos 1
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.jourRepos1_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Jour de repos 2
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.jourRepos2_employe}
                    </span>
                  </div>
                  </div>
    
                  </div>
    
                  <div></div>
    
                  <div className={Styles.classgauche} >
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Département
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.departement_employe}
                    </span>
                  </div>
                  </div>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Nbre heures hebdo
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.plageHoraire_employe}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Date de début
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {moment(emp.dateEntree_employe).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Date de fin
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {moment(emp.dateSortie_employe).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Mode de travail
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                    {emp.modeTravail_employe}
                    </span>
                  </div>
                  </div>
    
                  </div>
                  </div>
                   </div>
                   </div>
                   
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
      </div>
    </div>
    <div className={Styles.classCenterSection}>
      <div className={Styles.classCenterSection2}>
    {
                activateCoordonnees && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button  className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCoordonnees} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                  </div>:
                  
                <div className={Styles.classBtnCenter}>
                   <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCoordonnees}>
                        Coordonnées {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCoordonnees}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
        (afficherCoordonnees && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCoordonnees === true) ? 
        <div className={Styles.affichagetaille}>
         <div className={Styles.affichageZone}>
    
                      {/* <br /> */}
        <div className={Styles.sectionmobile}>
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Nom rue/avenue
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.nomRue_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        N° rue/avenue
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.NRue_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Complément d'adresse
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.complementAdresse_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Chez
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.pays_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Code postale
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.codePostale_employe}
                </span>
        </div>
        </div>
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Ville
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.ville_employe}
                </span>
        </div>
        </div>
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Télephone fixe
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.telephoneFixe_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Télephone mobile
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.tel_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        E-Mail
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.email_employe}
                </span>
        </div>
        </div>
    
        </div>
        <div className={Styles.classcentretotal}>
        <div className={Styles.classdroite}>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Nom rue/avenue
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.nomRue_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        N° rue/avenue
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.NRue_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Complément d'adresse
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                   {emp.complementAdresse_employe}
                  </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Chez
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.pays_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Code postale
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.codePostale_employe}
                </span>
        </div>
        </div>
    
        </div>
    
        <div></div>
    
        <div className={Styles.classgauche} >
    
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Ville
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.ville_employe}
                </span>
        </div>
        </div>
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Télephone fixe
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.telephoneFixe_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        Télephone mobile
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.tel_employe}
                </span>
        </div>
        </div>
    
        <div className={Styles.classtotal}>
        <div className={Styles.classpremier}>
        E-Mail
        </div>
        <div className={Styles.classdeuxieme}>
        <span className={Styles.testGoldenWrap}>
                {emp.email_employe}
                </span>
        </div>
        </div>
    
        </div>
        </div>
        </div>
                   </div> 
                   : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    {
    
                activateCompetences && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {
                (afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>
                        Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                  </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceCompetences} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
    
                  </div>:
                  
                  <div className={Styles.classBtnCenter}>
                     <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceCompetences}>Compétences {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceCompetences}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                  </div>
                }
                {(afficherCompetences && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeCompetences === true) ? 
                
                <div className={Styles.affichagetaille}>
                <div className={Styles.affichageZone}>
                  {/* <br /> */}
                <div className={Styles.sectionmobile}>
    
                 <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.formation1_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.formation2_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.formation3_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.formation4_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.experience1_employe}
        </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.experience2_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.experience3_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.experience4_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 1 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.langue1_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.langue2_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 3 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.langue3_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Langue 4 
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.langue4_employe}
        </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Compétence
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.competence_employe}
        </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
        </span></div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
        </span></div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année formation 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Année expérience 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
        </span>
    </div>
    </div>
    
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 1
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.niveauLangue1_employe}
        </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 2
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.niveauLangue2_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 3
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.niveauLangue3_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Niveau de langue 4
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.niveauLangue4_employe}
        </span>
    </div>
    </div>
                </div>
                <div className={Styles.classcentretotal}>
                <div className={Styles.classdroite}>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.formation1_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.formation2_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.formation3_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Formation 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.formation4_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.experience1_employe}
                    </span>
                </div>
                </div>
    
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.experience2_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.experience3_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Expérience 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.experience4_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 1 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.langue1_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.langue2_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 3 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.langue3_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Langue 4 
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.langue4_employe}
                    </span>
                </div>
                </div>
    
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Compétence
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.competence_employe}
                    </span>
                </div>
                </div>
                </div>
                <div></div>
                <div className={Styles.classgauche} >
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeFormation1_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeFormation2_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeFormation3_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année formation 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeFormation4_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeExperience1_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeExperience2_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeExperience3_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Année expérience 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {moment(emp.anneeExperience4_employe).format("MM/YYYY")}
                    </span>
                </div>
                </div>
    
    
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 1
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.niveauLangue1_employe}
                    </span>
                </div>
                </div>
    
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 2
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.niveauLangue2_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 3
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.niveauLangue3_employe}
                    </span>
                </div>
                </div>
    
                <div className={Styles.classtotal}>
                <div className={Styles.classpremier}>
                Niveau de langue 4
                </div>
                <div className={Styles.classdeuxieme}>
                <span className={Styles.testGoldenWrap}>
                    {emp.niveauLangue4_employe}
                    </span>
                </div>
                </div>
    
                </div>
    
    
                </div>
                  </div>
                   </div>
                    : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    
    </div>
    </div>
    <div className={Styles.classCenterSection}>
    <div className={Styles.classCenterSection2}>
    
    {
                activateIdBancaire && employeList ? employeList.sort((a, b) => a.dateModification_employe > b.dateModification_employe ? -1:1).map(emp => 
                <div>
                {(afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
    
                <div className={Styles.classBtnCenter}>
                  <div className={Styles.titleStyle} >
                    <div className={Styles.titleZone} >
                      <button className={Styles.classBtnDonneesDown} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                        ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                      </button>
                      </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.icone} onClick={btnOccurenceIdBancaire} >
                        <i className='fas fa-angle-down'></i>
                        </button>
                      </div>
                 </div>
                </div> :
    
                <div className={Styles.classBtnCenter}>
                    <div className={Styles.titleStyle}>
                    <div className={Styles.titleZone} >
                <button className={Styles.classBtnDonnees} value={emp.dateModification_employe} onClick={btnOccurenceIdBancaire}>
                  ID. Bancaire {moment(emp.dateModification_employe).format("DD/MM/YYYY")} - {moment(emp.dateFinModification_employe).format("DD/MM/YYYY")}
                </button>
                </div>
                    <div className={Styles.angleZone} >
                   <button className={Styles.iconeUp} onClick={btnOccurenceIdBancaire}  value={emp.dateModification_employe}>
                    <i className='fas fa-angle-up'></i>
                    </button>
                    </div>
                </div>
                </div>
    }
    {
    (afficherIdBancaire && emp.dateModification_employe === dateModification) || (emp.dateModification_employe === firstDateModification && initialeIdBancaire === true) ? 
                  <div className={Styles.affichagetaille}>
                <div className={Styles.affichageZone}>
                  {/* <br /> */}
                  <div className={Styles.sectionmobile}>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.banque_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Domiciliation
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.domiciliation_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Numéro de compte
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.numeroCompte_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du banque
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.codeBanque_employe}
        </span>
    </div>
    </div>
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Code du guichet
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.codeGuichet_employe}
        </span>
    </div>
    </div>
    
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    Clé RIB
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.cleRib_employe}
        </span>
    </div>
    </div>
    
    <div className={Styles.classtotal}>
    <div className={Styles.classpremier}>
    IBAN
    </div>
    <div className={Styles.classdeuxieme}>
    <span className={Styles.testGoldenWrap}>
        {emp.iban_employe}
        </span>
    </div>
    </div>
                  </div>
                  <div className={Styles.classcentretotal}>
                  <div className={Styles.classdroite}>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                      {emp.banque_employe}
                      </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Domiciliation
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                      {emp.domiciliation_employe}
                      </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Numéro de compte
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                      {emp.numeroCompte_employe}
                      </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du banque
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                      {emp.codeBanque_employe}
                      </span>
                  </div>
                  </div>
    
                  </div>
    
                  <div></div>
                  <div className={Styles.classgauche} >
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Code du guichet
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                      {emp.codeGuichet_employe}
                      </span>
                  </div>
                  </div>
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  Clé RIB
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                      {emp.cleRib_employe}
                      </span>
                  </div>
                  </div>
    
                  <div className={Styles.classtotal}>
                  <div className={Styles.classpremier}>
                  IBAN
                  </div>
                  <div className={Styles.classdeuxieme}>
                  <span className={Styles.testGoldenWrap}>
                      {emp.iban_employe}
                      </span>
                  </div>
                  </div>
                  </div>
    
                  </div>
                   </div>
                   </div>
                    : <span></span>}
                   <div class={Styles.centeredline}>
                      {/* <hr /> */}
                      <br />
                   </div>
                
                </div>): <span></span>
              }
    </div>
    </div>
    </div>
  }  
  NbreSections={4} 
  prctgeSection1={10}
  prctgeSection2={9}
  prctgeSection3={9} 
  prctgeSection4={72}
  />
  </div> 
</div>
  )
}

export default DonneesPersonnellesSection;