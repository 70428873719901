
import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import NationaliteSection from './NationaliteSection.jsx';



export const Nationalite = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<NationaliteSection />} />
    
   
 </div>
)}


export default Nationalite


