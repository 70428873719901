import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import RemunerationSection from "./RemunerationSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function Remuneration(){

    return(
        <div>
            <NavBarGlobal  section = {<RemunerationSection />}/>
        </div>
    )
}

export default Remuneration;