import React from "react";
import SalarieNavBar from '../../SalarieGlobal/NavBar/SalarieNavBar.jsx';
import SalarieCard from '../../SalarieGlobal/Card/SalarieCard.jsx';

// import NavBarGlobal from "../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import FormationSection from "./FormationSection.jsx"
import styles from "./FormationSection.module.css"
import NavBarGlobal from "../../../Global/NavBar/NavBarGlobal/NavBarGlobal";


const Formation = () => {

  return (


    <div>
                 <NavBarGlobal section = {<FormationSection />} />
    </div>
    
  );
  
};
     
export default Formation;