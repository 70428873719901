import React, { useState, useEffect } from "react";
import {useCookies} from 'react-cookie';
import APIService from './../../../../APIService.js';
import styles from "./StatutFormationSection.module.css";
import moment from 'moment';
import TopMenu from "../../SalarieGlobal/TopMenu/TopMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import TopMenuSalarie from "../../TopMenuSalarie.jsx";
import LineFilterTableInput from "../../../GlobalComponents/lineFilterTableInput/LineFilterTableInput.jsx";
import PaginationTable from "../../../GlobalComponents/paginationTable/PaginationTable.jsx";
import GeneralCardTest from "../../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import FourthSectionTablePage from "../../../GlobalComponents/fourthSectionTablePage/FourthSectionTablePage.jsx"
import CheckboxTableInput from "../../../GlobalComponents/checkboxTableInput/CheckboxTableInput.jsx";
import SelectNbreLigneTableInput from "../../../GlobalComponents/selectNbreLigneTableInput/SelectNbreLigneTableInput.jsx";
import SecondSectionTablePage from "../../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import DateComponent from "../../../GlobalComponents/dateComponent/DateComponent.jsx";
import CreateComponent from "../../../GlobalComponents/createComponent/CreateComponent.jsx";
import DescriptionComponent from "../../../GlobalComponents/descriptionComponent/DescriptionComponent.jsx";
import { useNavigate } from "react-router-dom";
import ListColumns from "../../../GlobalComponents/listColumns/ListColumns.jsx";

const StatutFormationSection = () => {
  const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculeAvenant', 'id', 'matriculeEmployeAttente']);
  const [formations, setFormations] = useState()
  const [formationsList, setFormationsList] = useState()
  const [lieuFormation, setLieuFormation] = useState("")
  const [formateur, setFormateur] = useState("")
  const [dateDebut, setDateDebut] = useState("")
  const [dateFin, setDateFin] = useState("")

  const [nombrePageArray, setNombrePageArray] = useState([])
  const [selectedNombrePageValeur, setSelectedNombrePageValeur] = useState()
  const [nombrePageValeur, setNombrePageValeur] = useState(0)
  const [defaultNombrePageValeur, setDefaultNombrePageValeur] = useState()
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()


  var varLieuFormationRecherche = ""
  var varFormateurRecherche = ""
  var varDateDebutRecherche = ""
  var varDateFinRecherche = ""

  // TESTTT FILTRE 
  useEffect(() => {
    let lastData=[]
    if(valeurRechercheGlobale && formations){
      setFormationsList(formations.filter(resp => resp.lieuFormation_formation.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
        || resp.formateur_formation.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
        || resp.dateDebut_formation.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())
         || resp.dateFin_formation.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())))
    }else{
      setFormationsList(formations)
      lastData.push(formations)
    }
  }, [valeurRechercheGlobale, formations])

  useEffect(() => {
    if(lieuFormation){
      varLieuFormationRecherche = lieuFormation.toString().toLowerCase()
    }else{
      varLieuFormationRecherche = ""
    }

    if(formateur){
      varFormateurRecherche = formateur.toString().toLowerCase()
    }else{
      varFormateurRecherche = ""
    }

    if(dateDebut){
      varDateDebutRecherche = dateDebut.toString().toLowerCase()
    }else{
      varDateDebutRecherche = ""
    }

    if(dateFin){
      varDateFinRecherche = dateFin.toString().toLowerCase()
    }else{
      varDateFinRecherche = ""
    }
    if(formations){
      setFormationsList(formations.filter(resp => resp.lieuFormation_formation.toString().toLowerCase().includes(varLieuFormationRecherche) && resp.formateur_formation.toString().toLowerCase().includes(varFormateurRecherche) && resp.dateDebut_formation.toString().toLowerCase().includes(varDateDebutRecherche) && resp.dateFin_formation.toString().toLowerCase().includes(varDateFinRecherche)))
    }
    
  }, [formations,lieuFormation, formateur, dateDebut, dateFin])
  

//   useEffect(() => {
//     if(formations){
//       setFormationsList(formations)
//     }
//   }, [formations])
  
// console.log("formations data ",formations)
//   useEffect(() => {

//     if(token['mytoken']){
//       APIService.getFormations(token)
//     .then(resp => setFormations(resp.filter(resp => resp.matricule_employe === token['matriculeAvenant'])))
//     .catch(error => console.log(error))
//     }

//   }, [token])
  

//   function range(start, end) {
//     return Array(end - start + 1). fill(). map((_, idx) => start + idx)
//   }

//   function setNombrePage(num) {

//     setSelectedNombrePageValeur(parseInt(num))
//     setNombrePageArray(range(1, Math.ceil(parseInt(formationsList.length)/parseInt(num))))
//     setNombrePageValeur(parseInt(num))
    
//   }
//   const [afficherChecked, setAfficherChecked] = useState(false)

//   const handleChangeNombrePage = (e) => {
//     setAfficherChecked(false)
//     setSelectedNombrePageValeur(parseInt(e.target.value))
//     setNombrePageArray(range(1, Math.ceil(parseInt(formationsList.length)/parseInt(e.target.value))))
//     setNombrePageValeur(parseInt(e.target.value))

//   }

//   const btnNombrePage = (e) => {
//     setNombrePageValeur(parseInt(e.target.value))
//   }

//   function btnNombrePageFunction(pageNum){
//     setNombrePageValeur(parseInt(pageNum))
//   }



//   useEffect(() => {
//     if(formationsList){
//       setSelectedNombrePageValeur(formationsList.length)
//       setNombrePageArray(range(1, 1))
//       setNombrePageValeur(formationsList.length)
//       setDefaultNombrePageValeur(formationsList.length)
//     }
//   }, [formationsList])

// testttt
// Pagination 
const [formationsList_, setFormationsList_] = useState([]);

const [index, setIndex] = useState(0)
const [page, setPage] = useState(null)
const [pageTickets, setPageTickets] = useState([])
const [nombrePageArray_, setNombrePageArray_] = useState([])
const [nombrePageValeurFixed, setNombrePageValeurFixed] = useState(null)
const [afficherChecked, setAfficherChecked] = useState(false)


function transformNumberToArray(n) {
    const array = [];
    for (let i = 1; i <= n; i++) {
        array.push(i);
    }
    return array;
}


useEffect(() => {
  if(formationsList && parseInt(index) !== 0 && page !== 0 )
  
  { 
    let indexInt = parseInt(index);

    setPageTickets(formationsList.slice(page*indexInt - indexInt, page*indexInt))
    setNombrePageArray_(transformNumberToArray(Math.ceil(formationsList.length/parseInt(index))))

  } else {

    if( formationsList && parseInt(index) === 0){
      setPage(1)
      setPageTickets(formationsList)
      setNombrePageArray_([])
     


    }

  }
  
}, [formationsList, index, page])


useEffect(() => {
  if(afficherChecked && formationsList){
    setNombrePageValeurFixed(0);
    setIndex(0);
    
    
  }
   
 }, [afficherChecked])


 const handleChangeNombrePage = (e) => {
  setAfficherChecked(false)
  setNombrePageValeurFixed(parseInt(e.target.value))
  setIndex(e.target.value)
  setPage(1);
}

useEffect(() => {

  if(pageTickets ){

    setFormationsList_(pageTickets)
  }
  
}, [pageTickets])
// testtttt pagination
useEffect(() => {
  console.log("nbre page :",nombrePageValeur,"nbre pge array",nombrePageArray,
    "selected nbre valeur ",selectedNombrePageValeur)
}, [nombrePageValeur,selectedNombrePageValeur,nombrePageArray])


  const btnNombrePage = (e) => {
    setNombrePageValeur(parseInt(e.target.value))
  }


  function btnNombrePageFunction(pageNum){
    setNombrePageValeur(parseInt(pageNum))
  }


  
  

  useEffect(() => {

    if(token['mytoken']){
      APIService.getFormations(token)
    .then(resp => setFormations(resp.filter(resp => resp.matricule_employe === token['matriculeAvenant'])))
    .catch(error => console.log(error))
    }

  }, [token])

const deleteButton = (id) => {
  if(id && token){
    console.log("delete button",id)

    // re loading popup 
    // setIsLoading(true)
    APIService.SupprimerFormations(id, token)
    .then(
      () =>  APIService.getFormations(token)
              .then(resp => 
              {setFormationsList(resp.filter(resp => resp.matricule_employe === token['matriculeAvenant']));
                // re
              // setIsLoading(false)
              }
                )
                .catch(error => console.log(error))
    
    )
  }
}
const modifyButton = (id) => {
  if(id){
    console.log("modify button",id)
  }
}
const hideButton = (id) => {
  if(id){
    console.log("hide button",id)
  }
}
const sendButton = (id) => {
  if(id){
    console.log("send button",id)
  }
}
const headers={id : "id"
, description_formation:"Description",dateDebut_formation:"Date début"
};
const componentsList={ 1: "Send",2:"Delete",3:"Modify",4:"Hide",5:"Delete"}
const methodsList={ 1: sendButton,2:deleteButton,3:modifyButton,4:hideButton,5:deleteButton}

const navigate = useNavigate();
useEffect(() => {
  if( token['matriculeAvenant']){
    console.log("token", token['matriculeAvenant'])

  }else{
    console.log("no token here ")
    navigate('/salarie');
  }
}, [ token['matriculeAvenant']])

const [headerTest, setHeaderTest] = useState({});
const test={ description_formation:"Description",dateDebut_formation:"Date début"}
 
  return (
//    <div className={styles.classCard}>


//     <div className={styles.SectionTop11}>
//     <TopMenuSalarie page="formation"/>

//     </div>

//   <div className={styles.SectionTop1}>

//     <div className={styles.LaptopTop}>

//     <table style={{width:"100%", color:"goldenrod", backgroundColor:"rgb(218, 165, 32, 20%)", fontWeight:"bold", height:"100%"}}>
//             <tr>
//                 <td>
//                     <span >
//                         <label for="inputTypeContrat" style={{fontWeight:"bold",marginLeft:"40px"}}>Lieu Formation :</label>
//                         <input type="Nom" value={lieuFormation} onChange={e => setLieuFormation(e.target.value)} id={styles.inputText} placeholder=" " className={styles.inputGlobal}/>
//                     </span>
//                 </td>
//                 <td>
//                   <span >
//                       <label for="inputEmail4 " style={{fontWeight:"bold"}}>Formateur :</label>
//                       <input type="Nom" value={formateur} onChange={e => setFormateur(e.target.value)} id={styles.inputText} placeholder="" className={styles.inputGlobal}/>
                
//                   </span>
//                  </td>
//                 <td>
//                   <span>
//                       <label for="inputEmail4 " style={{fontWeight:"bold"}}>Date début :</label>
//                       <input placeholder="Select date" value={dateDebut} onChange={e => setDateDebut(e.target.value)} type="date" id={styles.inputText} className={styles.inputGlobal}/>
      
//                   </span>
//                 </td>
//                 <td>
//                   <span >
//                       <label for="inputEmail4 " style={{fontWeight:"bold"}}>Date fin :</label>
//                       <input placeholder="Select date" value={dateFin} onChange={e => setDateFin(e.target.value)} type="date" id={styles.inputText} className={styles.inputGlobal}/>

//                   </span>
//                 </td>
//             </tr>
// </table>
//     </div>

//     <div className={styles.SectionMobileTop}>


//     <div className={styles.barrebas1}>

//                     <span className={styles.leftTopBar}>
//                         <label className={styles.SectionMobileTop22} for="inputTypeContrat">Lieu Formation :</label>
//                         <input type="Nom" value={lieuFormation} onChange={e => setLieuFormation(e.target.value)} id={styles.inputText} placeholder=" " className={styles.inputGlobal}/>
//                     </span>
                
//                   <span className={styles.rightTopBar} >
//                       <label className={styles.SectionMobileTop22} for="inputEmail4 ">Formateur :</label>
//                       <input type="Nom" value={formateur} onChange={e => setFormateur(e.target.value)} id={styles.inputText} placeholder="" className={styles.inputGlobal}/>
//                   </span>
//     </div>
//     </div>

//   </div>

//     <div className={styles.SectionTop2}>
//     <div id={styles.idTestTable}>
//       <table class="table">
//       <thead>
//             <tr id={styles.idForStyleTh}>
//               <th>Description
//               </th>
//               <th>Date début
//               </th>
//             </tr>
           
//           </thead>
//           <tbody>
//                 {
//                     formationsList ? formationsList.slice(nombrePageValeur-selectedNombrePageValeur, nombrePageValeur).map(frm =>
                    
//                     <tr>
//                     <td> {frm.description_formation} </td>
//                     <td>{moment(frm.dateDebut_formation).format("DD/MM/YYYY")}</td>
//                     </tr>
                    
//                     ) : <span> </span>
//                 }


//           </tbody>
//         </table>
// </div>
//     </div>

//     <div className={styles.SectionTop3}>

//       <div className={styles.barrebas}>

  
//        <span className={styles.leftBottomBar}>

//             <span className={styles.inputGlobal1}>
//                <input type="checkbox" className={styles.inputGlobal2} 
//                 checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}}
//                /><span className={styles.inputGlobal77}>Tout afficher</span>

//            </span>

//             <span style={{width:"auto", fontWeight:"bold"}}>
//                 <label className={styles.inputGlobal4} style={{fontWeight:"bold"}}>Nombre de lignes : </label>
//                                       <select 
//                                       value={nombrePageValeur} onChange={handleChangeNombrePage}
//                                       className={styles.inputGlobal3}
//                                       >
//                                           <option default></option>
//                                           <option value="2">2</option>
//                                           <option value="3">3</option>
//                                           <option value="5">5</option>
//                                           <option value="10">10</option>
//                                           <option value="25">25</option>

//                                       </select>
//             </span>

//        </span>

//        <span className={styles.centerBottomBar}>
//        <span className={styles.pagination} >

// {nombrePageArray && nombrePageArray[0] === selectedPageNumber  ? 

// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}} disabled> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>

// : 
// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}}> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>

// }

// { selectedPageNumber > 2 ? <>...</> :<></>}


// {nombrePageArray ? nombrePageArray.map(nbr => 


//  <>
 
 
//  {  


// Math.abs(selectedPageNumber - nbr) <= 2 ? 

// <button className={
//   selectedPageNumber === nbr ? styles.classSelectedPageNumber : styles.classPageNumber
// } value={selectedNombrePageValeur*nbr} onClick={(e) => {btnNombrePage(e); setSelectedPageNumber(nbr)}}>{nbr}</button> : <></>



// }
 
 
//  </>






// ) : <></>} 




// { nombrePageArray && nombrePageArray.length-selectedPageNumber > 2 ? <>...</> :<></>}


// {nombrePageArray && nombrePageArray[nombrePageArray.length - 1] === selectedPageNumber  ? 

// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}} disabled><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}}/> </button>

// : 

// <button className={styles.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}}><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}} /> </button>


// }

// </span>
//        </span>

//        <span className={styles.rightBottomBar}>
//                <span>
//                <a href="formation" className={styles.creeravenat}>
//                + Créer Formation
//                </a> 
//                </span>
//        </span>

//       </div>
      
//     </div>

//     </div>
<div>
<div className={styles.web}>

<GeneralCardTest 
section1={
<TopMenuSalarie page="formation" />
} 
section2={
<SecondSectionTablePage 
nbreComponents={5}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={formationsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 

component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />}

component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}

/> 
}
section3={
<>
{
  (formationsList_ && headerTest) && 
    <TableComponent data={formationsList_} cols={headerTest} 
    addAction={true} 
    contentAction={methodsList}
    componentsList={componentsList}
    clickableRow={false}
/>  
}
</>    
 } 
section4={
          <FourthSectionTablePage 
          component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
          component2={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>} 
          component3={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />}
          component4={<CreateComponent text={"Créer Formation"} link={"formation"} />}
          /> 
        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={styles.tablet}>
<GeneralCardTest 
section1={
<TopMenuSalarie page="formation" />
}  
section2={
<SecondSectionTablePageTablet 
nbreComponents={6}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={formationsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
 component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
/> 
}
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
{
  (formationsList_ && headerTest) && 
    <TableComponent data={formationsList_} cols={headerTest} 
    addAction={true} 
    contentAction={methodsList}
    componentsList={componentsList}
    clickableRow={false}

/>  
}
</div>  
                } 
section4={
        <FourthSectionTablePageTablet 
        // component1={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
        //   } 
          component1={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>} 
          component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />}
          component3={<CreateComponent text={"Créer Formation"} link={"formation"} />}
        /> 

        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={styles.mobile}>
<GeneralCardTest 
 section1={
<TopMenuSalarie page="formation" />
}  
section2={
<SecondSectionTablePageMobile 
nbreComponents={7}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={formationsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
component7={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
/>

}  
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
{
  (formationsList_ && headerTest) && 
    <TableComponent data={formationsList_} cols={headerTest} 
    addAction={true} 
    contentAction={methodsList}
    componentsList={componentsList}
    clickableRow={false}

/>  
}
</div>  
                } 
section4={
        <FourthSectionTablePageMobile 
        component1={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
        component2={<CreateComponent text={"Créer Formation"} link={"formation"} />}

        /> 
        } 
NbreSections={4} 
prctgeSection1={7}
prctgeSection2={6} 
prctgeSection3={81}
prctgeSection4={6}
/>
</div>
<div className={styles.mobileInversePetit}>
<GeneralCardTest 
section1={
  <TopMenuSalarie page="formation" />
} 
section2={
<SecondSectionTablePageMobileInversePetit 
nbreComponents={7}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={formationsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 
component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
component7={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
/>

}  
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
{
  (formationsList_ && headerTest) && 
    <TableComponent data={formationsList_} cols={headerTest} 
    addAction={true} 
    contentAction={methodsList}
    componentsList={componentsList}
    clickableRow={false}

/>  
}
</div>
                } 
section4={
        <FourthSectionTablePageMobileInversePetit 
        component1={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_} />}
        component2={<CreateComponent text={"Créer Formation"} link={"formation"} />}
        
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div>
<div className={styles.mobileInverseGrand}>
<GeneralCardTest 
section1={
<TopMenuSalarie page="formation" />}
section2={
<SecondSectionTablePageMobileInverseGrand 
nbreComponents={6}
component1={<DescriptionComponent/>
} 
component2={<ListColumns listColumn={formationsList_}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={test} />}
component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />}
component4={<DateComponent text={"Date début"} date={dateDebut} onChange={e => setDateDebut(e.target.value)}/>} 

component5={<DateComponent text={"Date fin"} date={dateFin} onChange={e => setDateFin(e.target.value)}/>}
 component6={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
/>

} 
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
{
  (formationsList_ && headerTest) && 
    <TableComponent data={formationsList_} cols={headerTest} 
    addAction={true} 
    contentAction={methodsList}
    componentsList={componentsList}
    clickableRow={false}

/>  
}
</div>  
                } 
section4={
        <FourthSectionTablePageMobileInverseGrand  
        component1={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>} 
          component2={<PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />}
          component3={<CreateComponent text={"Créer Formation"} link={"formation"} />}
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div> 
</div>
    
  );
  
};
     
export default StatutFormationSection;