import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import FraisTransportSection from "./FraisTransportSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function FraisTransport(){

    return(
        <div>
            <NavBarGlobal  section = {<FraisTransportSection />}/>
        </div>
    )
}

export default FraisTransport;