import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import PosteSection from './PosteSection.jsx';



export const Poste = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<PosteSection />} />
    
   
 </div>
)}


export default Poste