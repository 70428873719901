import React from 'react';
import styles from "./TopMenu.module.css"

function TopMenu(props) {

    var styleSalarie = {backgroundColor:""};
    var styleContrat = {backgroundColor:""};
    var styleFormation = {backgroundColor:""};

    if(props.page){
        if(props.page === "salarie" || props.page === "donnees"){
          styleSalarie = {backgroundColor:"goldenrod"};
         
      
      
        }
        if(props.page === "contrat"){
          styleContrat = {backgroundColor:"goldenrod"};
        }
        if(props.page === "formation"){
          styleFormation = {backgroundColor:"goldenrod"};
        }
      }
  return (
    <div className={styles.sectionStyle}>

<div className="row" id={styles.idTopNavBar}>
                  <div className="col">
                  <a href="salarie" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleSalarie} > Dashboard </button> </a>
                  </div>
                  {
                    props.page !== "salarie" ?
                    <>
                    <div className="col" id="contrat">
                      <a href="contrat" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleContrat} > Contrat </button> </a>
                    </div>
                    <div className="col" id="formation">
                      <a href="statut" className={styles.txtLinkBtn}><button className={styles.classBtnCalendrier} style={styleFormation} > Formation </button> </a>
                    </div>   
                    </>:<></>
                  }
                        
          </div>


    </div>
  )
}

export default TopMenu