import React from 'react';
import styles from './Modify.module.css';

function Modify(props) {
  return (
    <button id={styles.modifyButton} onClick={() => props.modifyData()}>
      <i class="fa-solid fa-pencil"></i>
    </button>
  )
}

export default Modify