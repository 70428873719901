import React from 'react';
import styles from './NavBar.module.css';

import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../../APIService.js';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Women from "./../../../img/women.jpg";
import { FaBell } from "react-icons/fa";
import Salary from "./../../../img/salary.png";
import Embauche from "./../../../img/embauche.png";
import Gargent from "./../../../img/gestion-de-largent.png";
import Gtemps from "./../../../img/gestionnaire-de-temps.png";
import Gprojet from "./../../../img/gestion-de-projet.png";





// import "./admin.css";
import moment from 'moment';
import 'moment/locale/fr' 

moment.locale('fr')

function NavBar(props) {
    const contentStyle = { textAlign : "center" , fontSize: "1vw", color: "black"};

    const [token, setToken, removeToken] = useCookies(['mytoken', 'username', 'matriculeManager', 'matriculeEmployeAttente', 'matriculeActuel'])
    const [users, setUsers] = useState([])
    const [afficher, SetAfficher] = useState(false)

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          window.location.href = '/'
          localStorage.clear()
  
      }
    }, [token])
    
    
    useEffect(() => {
        APIService.getUsers(token['mytoken'])
      .then(resp => setUsers(resp))
    },token['mytoken'])


    useEffect(() => {

        
        if(users.length){
          SetAfficher(true)
        }
          
        
    }, [users])


   
  
    const LogoutBtn = () => {
      removeToken(['mytoken'])
      localStorage.clear()
  
    }
  

    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
      }
      
      /* Set the width of the side navigation to 0 */
    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }

    const [etatMenu, setEtatMenu] = useState(false)


    const btnMenu = () => {
    if(etatMenu === true){

        setEtatMenu(false)

        let doc = document.getElementById("contenuProfile");
        doc.style.display = "none";

        

    }else{
        setEtatMenu(true)

        let doc = document.getElementById("contenuProfile");
        doc.style.display = "block";
        
        let docNotif = document.getElementById("contenuNotif");
        docNotif.style.display = "none";


    }
    }
    

    const [etatOpen, setEtatOpen] = useState(false)
    const [styleIdSpanBtnSideBar, setStyleIdSpanBtnSideBar] = useState(styles.classSpanBtnSideBar)


  const openSideNavBar = () => {

    if(!etatOpen){
      document.getElementById(styles.idSideNav).style.width = "9vw";
      document.getElementById(styles.idSideNav).style.transition = ".5s";
      document.getElementById(styles.idTxtSideBarEmbauche).style.display = "block";
      document.getElementById(styles.idTxtSideBarSalarie).style.display = "block";
      document.getElementById(styles.idTxtSideBarGestionTemps).style.display = "block";
      document.getElementById(styles.idTxtSideBarPaie).style.display = "block";
      document.getElementById(styles.idTxtSideBarGestionTaches).style.display = "block";

      setStyleIdSpanBtnSideBar(styles.classSpanBtnSideBarOpen)

      setEtatOpen(true)
    }else{

      document.getElementById(styles.idSideNav).style.width = "4vw";
      document.getElementById(styles.idSideNav).style.transition = ".5s";
      document.getElementById(styles.idTxtSideBarEmbauche).style.display = "none";
      document.getElementById(styles.idTxtSideBarSalarie).style.display = "none";
      document.getElementById(styles.idTxtSideBarGestionTemps).style.display = "none";
      document.getElementById(styles.idTxtSideBarPaie).style.display = "none";
      document.getElementById(styles.idTxtSideBarGestionTaches).style.display = "none";

      setStyleIdSpanBtnSideBar(styles.classSpanBtnSideBar)

      setEtatOpen(false)

    }
    

  }

  // Affichage des notifications 
  const [allUsers, setAllUsers] = useState([])
  const [employeCible, setEmployeCible] = useState([])

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          // navigate('/')
          window.location.href = '/'
          localStorage.clear()
      }
    }, [token])




    useEffect(() => {
      APIService.getUsers(token['mytoken'])
    .then(resp => setAllUsers(resp))
    },token['mytoken'])

    useEffect(() => {

      APIService.getEmploye(APIService.getEmployeId(allUsers, token), token)
      .then(resp => setEmployeCible(resp))

    },[allUsers])


  const [allNotificationsBar, setAllNotificationsBar] = useState([])
  const [etatNotification, setEtatNotification] = useState(false)


  useEffect(() => {

    APIService.getAllNotifications(token)
    .then(resp => setAllNotificationsBar(resp))


  },[etatNotification, token])


  const [etatNotif, setEtatNotif] = useState(false)
  const openNotif = () => {
    if(etatNotif){

      let doc = document.getElementById("contenuNotif");
      doc.style.display = "none";

      setEtatNotif(false)


    } else {

      let doc = document.getElementById("contenuNotif");
      doc.style.display = "block";


      let docProfile = document.getElementById("contenuProfile");
      docProfile.style.display = "none";

      setEtatNotif(true)

    }

  }

  const btnNotificationEvent = (e) => {

    allNotificationsBar.filter(nots => nots.id === parseInt(e.target.name)
      ).map(
      
      resp => APIService.ModifierNotification(resp.id, {username:resp.username,matricule_employe:resp.matricule_employe,notification:resp.notification,dateNotification:resp.dateNotification,statutNotification:resp.statutNotification, estVu:true}, token)
    )

    setToken('matriculeEmployeAttente', e.target.value)
    window.location.href = '/saisie'

  }

  if(afficher){

    return (
        <div >
          <div id={styles.idSideNav}>
            
    
    <nav>
        <a href="/accueil">
          <div align="center" className={styles.classHomeLogo}> 
            <i className={`fas fa-home-alt nav-logo-icon`} title="Accueil"></i>
          </div>
        </a>
      <table align="center" className={styles.tableSidebar}>
        
        
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <a href="/embauche">
                <img className={styles.logoSideBarAll} src={Embauche} title="Embauche"></img>
                <h3 id={styles.idTxtSideBarEmbauche}>Embauche</h3>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <a href="/salarie">
                <img className={styles.logoSideBarAll} src={Salary} title="Salarié"></img>
                <h3 id={styles.idTxtSideBarSalarie}>Salarié</h3>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <a href="/dashboard">
                <img className={styles.logoSideBarAll} src={Gtemps} title="Gestion de temps"></img>
                <h3 id={styles.idTxtSideBarGestionTemps}>Gestion de temps</h3>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
            <a href="/paie">

              <img className={styles.logoSideBarAll} src={Gargent} title="Paie"></img>
              <h3 id={styles.idTxtSideBarPaie}>Paie</h3>
              </a>
            
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <img className={styles.logoSideBarAll} src={Gprojet} title="Calendrier et gestion des tâches"></img>
              <h3 id={styles.idTxtSideBarGestionTaches}>Calendrier et tâches</h3>
            </div>
          </td>
        </tr>
        <tr>
          <td>
          </td>
        </tr>
        
      </table>
      <button className={styles.classbtnLogOutSideBar} onClick={LogoutBtn}>
            <div className={styles.classLogoutLogo}> 
                <i className={`fas fa-sign-out nav-link-icon`} title="Se déconnecter"></i>
            </div>
      </button>
    
      
       
    </nav>
    
    </div>
          <nav id={styles.idTopBarNav}>
            <button 
            className={styleIdSpanBtnSideBar}
            onClick={openSideNavBar}>
                <i className="fas fa-bars" ></i>
            </button>
            

            
            <span  id={styles.idSpanMenu}>

              

              
              
              <span className={styles.classMenuItemGlob} id={styles.idDropdownAdministration}>
                <button className={styles.dropBtn}>
                  Global
                </button>
                <div className={styles.content}>
                  <a href="#">Utilisateur</a>
                  <a href="#">Date/Heure</a>
                  <a href="#">Style</a>
                  <a href="#">Langue</a>
                  <a href="/donnees">Données</a>


                </div>
              </span >
              <span className={styles.classMenuItemGlob} id={styles.idDropdownAdministration}>
                <button className={styles.dropBtn}>
                  Administration du personnel
                </button>
                <div className={styles.content}>
                  <a href="#">Embauche</a>
                  <a href="#">Salarié</a>
                  <a href="#">Gestion de temps</a>
                  <a href="#">Paie</a>
                  <a href="#">Calendrie et gestion des taches</a>
                </div>
              </span >
              <span className={styles.classMenuItemGlob} id={styles.idDropdownAdministration}>
                <button className={styles.dropBtn}>
                Planification
                </button>
                <div className={styles.content}>
                  <a href="#">Link 1</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
                </div>
              </span >
              <span className={styles.classMenuItemGlob} id={styles.idDropdownAdministration}>
                <button className={styles.dropBtn}>
                Gestion des talents
                </button>
                <div className={styles.content}>
                  <a href="#">Link 1</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
                </div>
              </span >
            </span>

            
            <span id={styles.idSpanNotifProfil}>
              <span id={styles.idSpanNotif}>
                <button className={styles.tdNotifIcon} onClick={() => openNotif()}>
                    <FaBell className={styles.iconProfileStyle} />
                    <sup id={styles.supStyle}>{allNotificationsBar.filter(nots => nots.username === token['username']).length}</sup>
                </button>
                <div id="contenuNotif" className={styles.contentNotif}>
                <div className={styles.containerOfNotifications}>
                                  
    
                                      
    
                                  {allNotificationsBar.filter(nots => nots.username === token['username']).sort((a, b) => a.dateNotification > b.dateNotification ? -1:1).map(resp => <>
                                  {resp.estVu === false ? 

                                  <button className={styles.btnPrincipaleNonVue} 
                                  
                                  name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                  
                                  >
                                    <span className={styles.textBtnNotif}>
                                      <br />
                                        {resp.notification}
                                      
                                      <hr/>
                                      {resp.dateNotification ? <>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</>
                                      : <>XX/XX/XXXX xx:xx:xx</>}


                                    </span>
                                  </button>
                                  :
                                  <button className={styles.btnPrincipaleVue} name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                  
                                  >
                                    <span className={styles.textBtnNotif}>
                                      <br />
                                    {resp.notification}
                                  <hr/>
                                  {resp.dateNotification ? <span>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</span>
                                  : <span>XX/XX/XXXX xx:xx:xx</span>}
                                  </span>
                                  </button> }
                                  <br />
                                  </>)}
                               
                              </div>
                </div>
              </span>
              <span>
                <button className={styles.btnProfile} onClick={btnMenu}>
                    <img src={Women} alt="" />
                </button>
                <div id="contenuProfile" className={styles.contentProfile}>
                  <div className={styles.profileTopSection}>
                      <span>
                      <img src={Women} alt="" id={styles.imageProfile} />
                      </span>
                      <span id={styles.infosSection}>
                        <div id={styles.infosId}>
                          {employeCible.prenom_employe}, {employeCible.nom_employe} 
                          <br />
                          Etablissement : {employeCible.etablissement_employe} 
                          <hr />

                        </div>
                        <div id={styles.decId}>
                              <button className={styles.btnDeconnect} onClick={LogoutBtn}>Se déconnecter</button>

                        </div>
                      
                      
                      </span>
                      
                  </div>
                  {/* <div className={styles.profileBottomSection}>
                  <button className={styles.btnDeconnect} onClick={LogoutBtn}>Se déconnecter</button>
                  </div> */}
                  {/* <img src={Women} alt="" id={styles.imageProfile} />
                  <span id={styles.txtProfile}> 
                    Ayoub ANSAIR <br />
                    Etablissement : Etab 01
                  </span> */}
                </div>
              </span>
             
            </span>

            

          </nav>
            
            <div id={styles.idSection} className="bodback">

              {props.section}

              {/* ok */}

            </div>
        </div>
      )
  }
 
}


export default NavBar