import React, {useState, useEffect} from 'react';
import styles from "./ListeTachesSection.module.css";
import moment from 'moment';
import {useCookies} from 'react-cookie';
import APIService from './../../../APIService';
import NavBarGlobal from '../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal';
import TopMenuGestionDuTemps from '../TopMenuGestionDuTemps';



function ListeTachesSection() {
    const [employes, setEmployes] = useState([]);
    const [partieActive, setPartieActive] = useState();
    const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel', 'absenceList']);
    const [listDeclarationActivite, setListDeclarationActivite] = useState([]);
    const [listDemandeAbsence, setListDemandeAbsence] = useState([]);
    const [idsValider, setIdsValider] = useState([])
    const [tempPartieActive, setTempPartieActive] = useState()
    const [nomValidaton, setNomValidation] = useState()
    const [prenomValidaton, setPrenomValidation] = useState()
    const [role, setRole] = useState("manager");
    const [styleBtnDeclaration, setStyleBtnDeclaration] = useState();
    const [styleBtnDemande, setStyleBtnDemande] = useState();
    const [listActiontacheAdministration, setListActiontacheAdministration] = useState(null)



    useEffect(() => {

        if(partieActive === "activite"){
            setStyleBtnDeclaration(styles.BackColorBtn);
            setStyleBtnDemande("");
        }else{
            if(partieActive === "absence"){
                setStyleBtnDeclaration("");
                setStyleBtnDemande(styles.BackColorBtn);
            }
        }
     
    }, [partieActive])
    
    useEffect(() => {

        if(token['mytoken']){
            APIService.getActiontacheAdministration(token)
            .then(resp => setListActiontacheAdministration(resp))
        }
      
    }, [token['mytoken']])



    var listAction = [];
    var listCommentaire = [];

    useEffect(() => {

        if(token['mytoken']){
          APIService.getEmployes(token)
        .then(resp => setEmployes(resp))
        .catch(error => console.log(error))
        }

    
      }, [token])

      useEffect(() => {
        if(employes){
          employes.filter(resp => resp.matricule_employe === token['matriculeManager'] && resp.state_employe === true).map(resp => setNomValidation(resp.nom_employe))
          employes.filter(resp => resp.matricule_employe === token['matriculeManager'] && resp.state_employe === true).map(resp => setPrenomValidation(resp.prenom_employe))
        }
      }, [employes])
    
    
    const btnValider = () => {
     if(partieActive === "activite"){

                if(listAction && !listCommentaire){
                    let ob;
                    for(ob in listAction){

                        if(listAction[ob]["action"] === "Valider"){
                            listAction[ob]["declaration"]["action_declarationActivite"] = true
                        }else{
                            if(listAction[ob]["action"] === "Refuser"){
                                listAction[ob]["declaration"]["action_declarationActivite"] = false
                            }
                        }
                        // dateValidation_declarationActivite
                        // nomValidation_declarationActivite
                        // prenomValidation_declarationActivite
                        listAction[ob]["declaration"]["nomValidation_declarationActivite"] = nomValidaton;
                        listAction[ob]["declaration"]["prenomValidation_declarationActivite"] = prenomValidaton;
                        listAction[ob]["declaration"]["dateValidation_declarationActivite"] = moment().format("YYYY-MM-DD");


                        
                        
                        setIdsValider([...idsValider, listAction[ob]['id']])

                        APIService.ModifierDeclarationActivite(listAction[ob]['id'], listAction[ob]["declaration"], token)
                    }
                }


                if(listCommentaire && listAction){
                    let ob;
                    let ob2;
                    for(ob in listCommentaire){
                        for(ob2 in listAction){
                            if(listAction[ob2]["id"] === listCommentaire[ob]["id"]){
                                if(listAction[ob2]["action"] === "Valider"){
                                    listAction[ob2]["declaration"]["action_declarationActivite"] = true
                                }else{
                                    if(listAction[ob2]["action"] === "Refuser"){
                                        listAction[ob2]["declaration"]["action_declarationActivite"] = false
                                    }
                                }


                                listAction[ob2]["declaration"]["nomValidation_declarationActivite"] = nomValidaton;
                                listAction[ob2]["declaration"]["prenomValidation_declarationActivite"] = prenomValidaton;
                                listAction[ob2]["declaration"]["dateValidation_declarationActivite"] = moment().format("YYYY-MM-DD");
                                listCommentaire[ob]["declaration"]["commentaireValidation_declarationActivite"] = listCommentaire[ob]["commentaire"]

                                setIdsValider([...idsValider, listCommentaire[ob]['id']])

                                APIService.ModifierDeclarationActivite(listAction[ob2]['id'], listAction[ob2]["declaration"], token)


                            }
                        }

                        
                    }
                }

                APIService.getDeclarationActivite(token).then(resp => setListDeclarationActivite(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_declarationActivite === null)))

                setTempPartieActive(partieActive)
                setPartieActive("")

     } else {
        if(partieActive === "absence"){

            
            if(listAction && (!listCommentaire || listCommentaire) ){
                
                let ob;
                for(ob in listAction){

                    if(listAction[ob]["action"] === "Valider"){
                        listAction[ob]["absence"]["action_demandeAbsence"] = true
                    }else{
                        if(listAction[ob]["action"] === "Refuser"){
                            listAction[ob]["absence"]["action_demandeAbsence"] = false
                        }
                    }

                    listAction[ob]["absence"]["nomValidation_demandeAbsence"] = nomValidaton;
                    listAction[ob]["absence"]["prenomValidation_demandeAbsence"] = prenomValidaton;
                    listAction[ob]["absence"]["dateValidation_demandeAbsence"] = moment().format("YYYY-MM-DD");


                    
                    
                    setIdsValider([...idsValider, listAction[ob]['id']])


                    APIService.ModifierDemandeAbsence(listAction[ob]['id'], listAction[ob]["absence"], token)
                }
            }


            if(listCommentaire && listAction){
                let ob;
                let ob2;
                for(ob in listCommentaire){
                    for(ob2 in listAction){
                        if(listAction[ob2]["id"] === listCommentaire[ob]["id"]){
                            if(listAction[ob2]["action"] === "Valider"){
                                listAction[ob2]["absence"]["action_demandeAbsence"] = true
                            }else{
                                if(listAction[ob2]["action"] === "Refuser"){
                                    listAction[ob2]["absence"]["action_demandeAbsence"] = false
                                }
                            }


                            listAction[ob2]["absence"]["nomValidation_demandeAbsence"] = nomValidaton;
                            listAction[ob2]["absence"]["prenomValidation_demandeAbsence"] = prenomValidaton;
                            listAction[ob2]["absence"]["dateValidation_demandeAbsence"] = moment().format("YYYY-MM-DD");
                            listAction[ob2]["absence"]["commentaireValidation_demandeAbsence"] = listCommentaire[ob]["commentaire"]

                            setIdsValider([...idsValider, listCommentaire[ob]['id']])

                            APIService.ModifierDemandeAbsence(listAction[ob2]['id'], listAction[ob2]["absence"], token)


                        }
                    }

                    
                }
            }

            APIService.getDemandeAbsence(token).then(resp => setListDemandeAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence === null)))

            setTempPartieActive(partieActive)
            setPartieActive("")



        }
     }

        


    
    }

    useEffect(() => {
        if(tempPartieActive){
            console.log("setPartieActive :",tempPartieActive)
            setPartieActive(tempPartieActive)
            setTempPartieActive()
        }
      
    }, [tempPartieActive])
    

    



    function AddObject(ob, tab){
        var tab_x = tab
        var verif = true


        for (var o in tab_x){
            if(o !== null){
                if(tab_x[o]["id"] === ob["id"]){
                    verif = false
                    // delete tab_x[o];
                    tab_x.splice(o,1);
                    tab_x.push(ob);
        
                    // tab_x = tab_x.filter(resp => resp !== null);
                }
            }
        }
    
        if(verif){
            tab_x.push(ob);
        }
    
        // tab_x = tab_x.filter(resp => resp !== null);

        
        return tab_x;
            
    }

    useEffect(() => {
        if(token['mytoken']){
            APIService.getDeclarationActivite(token).then(resp => setListDeclarationActivite(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_declarationActivite === null)))
        }
      
    }, [])


    useEffect(() => {
        if(token['mytoken']){
            APIService.getDemandeAbsence(token).then(resp => setListDemandeAbsence(resp.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.action_demandeAbsence === null)))
        }
      
    }, [])

    // useEffect(() => {
    //   if(token['mytoken']){
    //     APIService.getRoleEmploye(token).then(resp => setRole(resp));
    //   }
    // }, [token])
    useEffect(() => {

        if(token["mytoken"]){
          APIService.getRoleEmploye(token)
          .then(resp => 
            // console.log("hi", resp)
            setRole(resp)
        )
  
        
        }
        
      }, [token["mytoken"]])


    useEffect(() => {
        if(role){
          if(role !== "Manager"){
            window.location.href = "/";
          }
        }
      }, [role])


      if(role === "Manager"){



        
  return (
    <div className={styles.classCard}>
        <div className={styles.generalContainer}>
            <div className={styles.firstPart}>
               <TopMenuGestionDuTemps page="taches"/>
            </div>
            <div className={styles.secondPart}>
                <div className={styles.dividePartTwo_}>
                <div className={styles.radio}>
                    <input label="Déclarations d’activité" className={styles.left} type="radio"  name="OK" onClick={() => {setPartieActive("activite"); listAction = null; listCommentaire = null;}}/>
                    <input label="Demandes d’absence" className={styles.right} type="radio" name="OK"  onClick={() => {setPartieActive("absence"); listAction = null; listCommentaire = null;}}/>
                </div>
                </div>
            </div>
            <div className={styles.thirdPart}>
            {
        partieActive === "activite" ? 
                <>
                <div className={styles.thirdPartDivided}>
                    <div className={styles.tableSection}>
                        {/* for all but not for mobile */}
                        <div id={styles.idTestTable}>
                            <table className={`${styles.table} ${styles.custom_table}`}>
                                <thead>
                                    <tr  id={styles.idForStyleTh}>
                                        <th>Rubrique des déclarations</th>
                                        <th >Demandé par</th>
                                        <th>Quantité</th>
                                        <th>Action</th>
                                        <th>Commentaire</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                    {
                                            listDemandeAbsence ? 
                                            listDeclarationActivite.filter(resp => idsValider.includes(resp.id) === false).map(dec => 
                                                <tr id={dec.id}>
                                                    <td className={styles.orangecellule} id={styles.orangeZone}>{dec.tache_declarationActivite}</td>
                                                    <td className={styles.orangecellule_} id={styles.orangeZone}>Par : {dec.prenom_declarationActivite} {dec.nom_declarationActivite} <br /><i class="fa-solid fa-calendar-days"></i> {moment(dec.dateDeclaration_declarationActivite, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                                    <td>{dec.nombreHeure_declarationActivite} (Heure)</td>
                                                    <td>
                                                        <select className={styles.inputGlobalChoix} onChange={(e) => {listAction = AddObject({"id": dec.id, "action": e.target.value, "declaration": dec}, listAction);}}>
                                                            <option></option>
                                                            {/* <option className="Valider">Valider</option>
                                                            <option className="Refuser">Refuser</option> */}
                                                            {listActiontacheAdministration &&
                                                                listActiontacheAdministration.map(
                                                                    actiontacheAdministration => <option value={actiontacheAdministration.id}>{actiontacheAdministration.nom}</option>
                                                                )
                                                                }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <textarea type="text" className={styles.inputGlobal} onChange={(e) => {listCommentaire = AddObject({"id": dec.id, "commentaire": e.target.value, "declaration": dec}, listCommentaire);}}/>
                                                    </td>
                                                </tr>
                                                
                                                )
                                            :<span></span>
                                    }
                                    </tbody>
                                    

                                    
                                        
                                    
                                </table>
                        </div> 
                    </div>
                    <div className={styles.tableSectionMobile}> 
                        {/* for mobile */}
                        <div id={styles.idTestTableMobile}>
                            <div className={styles.tableContainer}>
                                <table className={styles.table}>
                                    <thead className={styles.fixedHeader}>
                                        <tr id={styles.idForStyleTh}>
                                            <th className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>Rubrique des déclarations</th>
                                            <th className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>Demandé par</th>
                                            <th>Quantité</th>
                                            <th>Action</th>
                                            <th>Commentaire</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listDemandeAbsence ? 
                                            listDeclarationActivite.filter(resp => idsValider.includes(resp.id) === false).map(dec => (
                                                <tr id={dec.id}>
                                                    <td className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>{dec.tache_declarationActivite}</td>
                                                    <td className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>
                                                        Par : {dec.prenom_declarationActivite} {dec.nom_declarationActivite} <br />
                                                        <i className="fa-solid fa-calendar-days"></i> {moment(dec.dateDeclaration_declarationActivite, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>{dec.nombreHeure_declarationActivite} (Heure)</td>
                                                    <td>
                                                        <select className={styles.inputGlobalChoix} onChange={(e) => {listAction = AddObject({"id": dec.id, "action": e.target.value, "declaration": dec}, listAction);}}>
                                                            <option></option>
                                                            {/* <option className="Valider">Valider</option>
                                                            <option className="Refuser">Refuser</option> */}
                                                              {listActiontacheAdministration &&
                                                                listActiontacheAdministration.map(
                                                                    actiontacheAdministration => <option value={actiontacheAdministration.id}>{actiontacheAdministration.nom}</option>
                                                                )
                                                                }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <textarea type="text" className={styles.inputGlobal} onChange={(e) => {listCommentaire = AddObject({"id": dec.id, "commentaire": e.target.value, "declaration": dec}, listCommentaire);}}/>
                                                    </td>
                                                </tr>
                                            )) : <span></span>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttonSectionOK}>
                      <button onClick={btnValider} className={styles.BtnOk}>Ok</button>
                    </div>
                </div>
                </>:
                <></>

     }
      {
            partieActive === "absence" ? 
                        <>
                <div className={styles.thirdPartDivided}>
                <div className={styles.tableSection}>
                        <div id={styles.idTestTable}>
                            <table className={styles.table}>
                                <thead>
                                    <tr id={styles.idForStyleTh}>
                                        <th>Demande d’absence</th>
                                        <th>Demandé par</th>
                                        <th>Quantité</th>
                                        <th>Action</th>
                                        <th>Commentaire</th>
                                    </tr>
                                </thead>
                                
                                <tbody>

                                {
                                        listDemandeAbsence ? 
                                        listDemandeAbsence.filter(resp => idsValider.includes(resp.id) === false).map(dmd => 
                                            <tr id={dmd.id}>
                                                <td>{dmd.rubriqueAbsence_demandeAbsence}</td>
                                                <td>Par {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} <br /><i class="fa-solid fa-calendar-days"></i> {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                                <td>{dmd.nombreJour_demandeAbsence} (Jour)</td>
                                                <td>
                                                    <select className={styles.inputGlobalChoix} onChange={(e) => {listAction = AddObject({"id": dmd.id, "action": e.target.value, "absence": dmd}, listAction);}}>
                                                        <option></option>
                                                        {/* <option className="Valider">Valider</option>
                                                        <option className="Refuser">Refuser</option> */}
                                                          {listActiontacheAdministration &&
                                                                listActiontacheAdministration.map(
                                                                    actiontacheAdministration => <option value={actiontacheAdministration.id}>{actiontacheAdministration.nom}</option>
                                                                )
                                                                }
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea className={styles.inputGlobal} type="text" onChange={(e) => {listCommentaire = AddObject({"id": dmd.id, "commentaire": e.target.value, "absence": dmd}, listCommentaire);}}/>
                                                </td>
                                            </tr>
                                            
                                            )
                                        :<span></span>
                                }
                                </tbody>
                            </table>
                        </div>
                </div>
                <div className={styles.tableSectionMobile}> 
                        <div id={styles.idTestTableMobile}>
                          <div className={styles.tableContainer}>
                                <table className={`${styles.table} ${styles.custom_table}`}>
                                    <thead className={styles.fixedHeader}>
                                        <tr id={styles.idForStyleTh}>
                                            <th className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>Demande d’absence</th>
                                            <th className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>Demandé par</th>
                                            <th>Quantité</th>
                                            <th>Action</th>
                                            <th>Commentaire</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listDemandeAbsence ? 
                                            listDemandeAbsence.filter(resp => idsValider.includes(resp.id) === false).map(dmd => (
                                                <tr id={dmd.id}>
                                                    <td className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>{dmd.rubriqueAbsence_demandeAbsence}</td>
                                                    <td className={styles.fixedHeaderCellule} id={styles.fixedcolumn}>
                                                        Par : {dmd.prenom_demandeAbsence} {dmd.nom_demandeAbsence} <br />
                                                        <i className="fa-solid fa-calendar-days"></i> {moment(dmd.dateDemande_demandeAbsence, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                    </td>
                                                    <td>{dmd.nombreJour_demandeAbsence} (Jour)</td>
                                                    <td>
                                                        <select className={styles.inputGlobalChoix} onChange={(e) => {listAction = AddObject({"id": dmd.id, "action": e.target.value, "absence": dmd}, listAction);}}>
                                                            <option></option>
                                                            {/* <option className="Valider">Valider</option>
                                                            <option className="Refuser">Refuser</option> */}
                                                              {listActiontacheAdministration &&
                                                                listActiontacheAdministration.map(
                                                                    actiontacheAdministration => <option value={actiontacheAdministration.id}>{actiontacheAdministration.nom}</option>
                                                                )
                                                                }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <textarea type="text" className={styles.inputGlobal} onChange={(e) => {listCommentaire = AddObject({"id": dmd.id, "commentaire": e.target.value, "absence": dmd}, listCommentaire);}}/>
                                                    </td>
                                                </tr>
                                            )) : <span></span>
                                        }
                                    </tbody>
                                </table>
                          </div>
                        </div>
                        </div>
                <div className={styles.buttonSectionOK}>
                      <button onClick={btnValider} className={styles.BtnOk}>Ok</button>
                </div>
                </div>



                    </>:
                    <></>
        }

            </div>

        </div>
    </div>
  )

      }
    
    


}

export default ListeTachesSection