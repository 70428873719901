import React from "react";
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from '../../../APIService.js';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import StylesM from './DashboardAdministrationSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import TopMenu from "../../Salarie/SalarieGlobal/TopMenu/TopMenu";


function DashboardAdministrationSection() {

    const [employes, setEmployes] = useState([]);
    const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager']);
    const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()
    const [idMasquer, setIdMasquer] = useState([]);
    const [idSupprimer, setIdSupprimer] = useState([]);
    const [idChangeAffichage, setIdChangeAffichage] = useState();
    const [idSupprimerValeur, setIdSupprimerValeur] = useState();
    const [nombrePageArray, setNombrePageArray] = useState([])
    const [selectedNombrePageValeur, setSelectedNombrePageValeur] = useState()
    const [nombrePageValeur, setNombrePageValeur] = useState(0)
    const [matriculeRecherche, setMatriculeRecherche] = useState()
    const [nomRecherche, setNomRecherche] = useState()
    const [prenomRecherche, setPrenomRecherche] = useState()
    const [etablissementRecherche, setEtablissementRecherche] = useState()
    const [departementRecherche, setDepartementRecherche] = useState()
    const [categorieRecherche, setCategorieRecherche] = useState()
    const [contratRecherche, setContratRecherche] = useState()
    const [horaireRecherche, setHoraireRecherche] = useState()
    const [employesList, setEmployesList] = useState()
    const [defaultNombrePageValeur, setDefaultNombrePageValeur] = useState()
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  
    var varMatriculeRecherche = ''
    var varNomRecherche = ''
    var varPrenomRecherche = ''
    var varEtablissementRecherche = ''
    var varDepartementRecherche = ''
    var varContratRecherche = ''
    var varHoraireRecherche = ''
    
  
      // const doc = new jsPDF('landscape', '', 'a4');
    const doc = new jsPDF();
  
  
      // var pdfjs = document.getElementById('panelTable');
  
    function createData(emps){
      var data = []
  
      emps.map(emp => data.push([emp.matricule_employe, emp.nom_employe, emp.prenom_employe, emp.etablissement_employe, emp.departement_employe, emp.typeContrat_employe, emp.plageHoraire_employe]))
  
      return data
    }
    
    const btnGeneratePdf = (e) => {
      // doc.text("MY DATA", 10, 10)
      // doc.table(20, 20, employesList)
      // if(employesList){
      //   employesList.map(emp =>
      //     doc.text(emp.nom_employe, 10, 20)
      //     )
      // }
      // doc.text(pdfjs.innerText, 10, 20)
      doc.autoTable({ 
        head: [['Matricule', 'Nom', 'Prénom', 'Etablissement', 'Département', 'Contrat', 'Horaire']],
        body: createData(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false))
      })
  
      doc.save("test.pdf")
    }
  
    useEffect(() => {
      if(valeurRechercheGlobale){
        setEmployesList(employes.filter(resp => resp.matricule_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.nom_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.prenom_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.etablissement_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.departement_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.typeContrat_employe.toLowerCase().includes(valeurRechercheGlobale.toLowerCase()) || resp.plageHoraire_employe.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())))
      }else{
        setEmployesList(employes)
      }
    }, [valeurRechercheGlobale])
    
  
    useEffect(() => {
  
      if(matriculeRecherche){
        varMatriculeRecherche = matriculeRecherche.toString().toLowerCase()
      }else{
        varMatriculeRecherche = ''
      }
  
      if(nomRecherche){
        varNomRecherche = nomRecherche.toString().toLowerCase()
      }else{
        varNomRecherche = ''
      }
  
      if(prenomRecherche){
        varPrenomRecherche = prenomRecherche.toString().toLowerCase()
      }else{
        varPrenomRecherche = ''
      }
  
      if(etablissementRecherche){
        varEtablissementRecherche = etablissementRecherche.toString().toLowerCase()
      }else{
        varEtablissementRecherche = ''
      }
      
      if(departementRecherche){
        varDepartementRecherche = departementRecherche.toString().toLowerCase()
      }else{
        varDepartementRecherche = ''
      }
  
      
  
      if(contratRecherche){
        varContratRecherche = contratRecherche.toString().toLowerCase()
      }else{
        varContratRecherche = ''
      }
  
      if(horaireRecherche){
        varHoraireRecherche = horaireRecherche
      }else{
        varHoraireRecherche = ''
      }
  
      
      setEmployesList(employes.filter(resp => resp.matricule_employe.toLowerCase().includes(varMatriculeRecherche) && resp.nom_employe.toLowerCase().includes(varNomRecherche) && resp.prenom_employe.toLowerCase().includes(varPrenomRecherche) && resp.etablissement_employe.toLowerCase().includes(varEtablissementRecherche) && resp.departement_employe.toLowerCase().includes(varDepartementRecherche) && resp.typeContrat_employe.toLowerCase().includes(varContratRecherche) && resp.plageHoraire_employe.toString().toLowerCase().includes(varHoraireRecherche)))
      
    }, [matriculeRecherche, nomRecherche, prenomRecherche, etablissementRecherche, departementRecherche, contratRecherche, horaireRecherche])
    
  
  
  
  
    useEffect(() => {
     
      APIService.getEmployes(token)
      .then(resp => setEmployes(resp))
      .catch(error => console.log(error))
  
    }, [idChangeAffichage])
  
    useEffect(() => {
  
      if(token['mytoken']){
        APIService.getEmployes(token)
      .then(resp => setEmployes(resp))
      .catch(error => console.log(error))
  
      }
  
    }, [token])
  
  
  
    useEffect(() => {
      if(employes){
          setSelectedNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
          setNombrePageArray(range(1, 1))
          setNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
          setDefaultNombrePageValeur(employes.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)
  
          setEmployesList(employes)
      }
    }, [employes])
  
  
    useEffect(() => {
      if(idMasquer && employesList && selectedNombrePageValeur){
          setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/selectedNombrePageValeur)))
  
      }
    }, [idMasquer, employesList])
    
  
    const btnMasquer = (e) => {
      
      setIdMasquer(idMasquer.concat(parseInt(e.target.value)))
      
    }
  
    const btnRoload = () => {
      if(employes){
        setEmployesList(employes)
        setIdMasquer([])
      }
      
    }
  
    const btnSupprimer = (e) => {
  
      setIdSupprimerValeur(parseInt(e.target.value))
  
      // APIService.SupprimerEmploye(e.target.value, token)  
      
      // setIdSupprimer(idSupprimer.concat(parseInt(e.target.value)))
      
    }
  
    var listData = {}
    const btnSupprimerOui = (e) => {
      listData = employesList.filter(emp => emp.id === parseInt(e.target.value))[0]
      // listData.state_employe = 0
      // delete listData.id;
      // console.log(listData)
  
      // APIService.ModifierEmploye(parseInt(e.target.value), listData, token) 
      
      APIService.ModifierEmploye(parseInt(e.target.value),
      {matricule_employe:listData.matricule_employe, dateEntree_employe:listData.dateEntree_employe, dateModification_employe:listData.dateModification_employe, dateFinModification_employe:listData.dateFinModification_employe,dateSortie_employe:listData.dateSortie_employe,
      nom_employe:listData.nom_employe, prenom_employe:listData.prenom_employe, sexe_employe:listData.sexe_employe, numeroSS_employe:listData.numeroSS_employe, cle_employe:listData.cle_employe, dateNaissance_employe:listData.dateNaissance_employe, 
      paysNaissance_employe:listData.paysNaissance_employe, departementNaissance_employe:listData.departementNaissance_employe, nationnalite1_employe:listData.anneeFormation1_employe, nationnalite2_employe:listData.nationnalite2_employe, 
      situationFamiliale_employe:listData.situationFamiliale_employe, nombreEnfant_employe:listData.nombreEnfant_employe, adresse_employe:listData.adresse_employe, pays_employe:listData.pays_employe, 
      codePostale_employe:listData.codePostale_employe, ville_employe:listData.ville_employe, tel_employe:listData.tel_employe, 
      email_employe:listData.email_employe, etablissement_employe:listData.etablissement_employe, departement_employe:listData.departement_employe, 
      poste_employe:listData.poste_employe, typeContrat_employe:listData.typeContrat_employe, plageHoraire_employe:listData.plageHoraire_employe, jourRepos1_employe:listData.jourRepos1_employe, jourRepos2_employe:listData.jourRepos2_employe, nomRue_employe:listData.nomRue_employe, NRue_employe:listData.NRue_employe, complementAdresse_employe:listData.complementAdresse_employe, chez_employe:listData.chez_employe, telephoneFixe_employe:listData.telephoneFixe_employe,formation1_employe:listData.formation1_employe, anneeFormation1_employe:listData.anneeFormation1_employe, formation2_employe:listData.formation2_employe, anneeFormation2_employe:listData.anneeExperience2_employe,formation3_employe:listData.formation3_employe, anneeFormation3_employe:listData.anneeExperience3_employe,formation4_employe:listData.formation4_employe, anneeFormation4_employe:listData.anneeFormation4_employe,
      experience1_employe:listData.experience1_employe, anneeExperience1_employe:listData.anneeExperience1_employe, experience2_employe:listData.experience2_employe, anneeExperience2_employe:listData.anneeExperience2_employe,experience3_employe:listData.experience3_employe, anneeExperience3_employe:listData.anneeExperience3_employe,experience4_employe:listData.experience4_employe, anneeExperience4_employe:listData.anneeExperience4_employe, 
      langue1_employe:listData.langue1_employe, niveauLangue1_employe:listData.niveauLangue1_employe,
      langue2_employe:listData.langue2_employe, niveauLangue2_employe:listData.niveauLangue2_employe,
      langue3_employe:listData.langue3_employe, niveauLangue3_employe:listData.niveauLangue3_employe,
      langue4_employe:listData.langue4_employe, niveauLangue4_employe:listData.niveauLangue4_employe,
      iban_employe:listData.iban_employe, banque_employe:listData.banque_employe,
      domiciliation_employe:listData.domiciliation_employe, numeroCompte_employe:listData.numeroCompte_employe,
      codeBanque_employe:listData.codeBanque_employe, codeGuichet_employe:listData.codeGuichet_employe,
      cleRib_employe:listData.cleRib_employe, state_employe:0, modeTravail_employe : listData.modeTravail_employe, competence_employe: listData.competence_employe,
      heurePrisePoste_employe: listData.heurePrisePoste_employe,            
      motifSortie_employe:3, matricule_manager: token['matriculeManager']},token)
      
      
      
      // APIService.SupprimerEmploye(e.target.value, token)  
      
      setIdSupprimer(idSupprimer.concat(parseInt(e.target.value)))
  
      setIdSupprimerValeur()
  
    }
  
    const btnSupprimerNon = (e) => {
  
      setIdSupprimerValeur()
      
    }
  
    function range(start, end) {
      return Array(end - start + 1). fill(). map((_, idx) => start + idx)
    }
  
  
    function setNombrePage(num) {

      setSelectedNombrePageValeur(parseInt(num))
      setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/parseInt(num))))
      setNombrePageValeur(parseInt(num))
      
    }

    // useEffect(() => {
    //   if(nombrePageValeur && selectedNombrePageValeur){
    //     console.log("nombrePageValeur :", nombrePageValeur)
    //     console.log("selectedNombrePageValeur :", selectedNombrePageValeur)

    //   }
      
    // }, [nombrePageValeur, selectedNombrePageValeur])
    
    const handleChangeNombrePage = (e) => {
      setAfficherChecked(false)
      setSelectedNombrePageValeur(parseInt(e.target.value))
      setNombrePageArray(range(1, Math.ceil(parseInt(employesList.filter(resp => resp.state_employe === true && idMasquer.includes(resp.id) === false && idSupprimer.includes(resp.id) === false).length)/parseInt(e.target.value))))
      setNombrePageValeur(parseInt(e.target.value))
      
  
    }
  
    const btnNombrePage = (e) => {
      setNombrePageValeur(parseInt(e.target.value))
      
    }

    function btnNombrePageFunction(pageNum){
      setNombrePageValeur(parseInt(pageNum))
    }
  
  
    const btnFichePersonnel = (e) => {
      
  
      setToken('idFichePersonnel', e.target.value)
      console.log(e.target.name)
  
      window.location.href = '/donneesPersonnelles'
  
    }

    const [afficherChecked, setAfficherChecked] = useState(false)
    const [show, setShow] = useState(false)
    function showMenu() {
      if(show){
        setShow(false)
      }else{
        setShow(true)

      }
    }
    
    // Administration 

    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    if(token['mytoken']){
        APIService.getUsers(token['mytoken'])
            .then(resp => {
                if(isMounted) {
                    setUsers(resp);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                if(isMounted) {
                    setError(err);
                    setIsLoading(false);
                }
            });
    }

    // Cleanup function
    return () => {
        isMounted = false;
    };
}, [token['mytoken']]);
    
  return (
    <div className={StylesM.classCard}>


        <div className={StylesM.topBar}>

             <TopMenu page="salarie" />

        </div>


      <div className={StylesM.toolsBar}>

      <div id={StylesM.classTopNavRechPC} style={{color:"rgb(218, 165, 32)", backgroundColor:"rgb(218, 165, 32, 20%)"}}>
       
         
            
            <span className={StylesM.inputgroup}>
              <input type="checkbox" className={StylesM.inputGlobal8} checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} /> 
              <span className={StylesM.inputGlobal77} >Tou afficher	</span>

            </span>
            <span   className={StylesM.inputgroup}>
                <label className={StylesM.label}>Nombre de lignes : </label>
                                      <select value={nombrePageValeur} onChange={handleChangeNombrePage} className={StylesM.test}>
                                          <option ></option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="5">5</option>
                                          <option value="10">10</option>
                                          <option value="25">25</option>

                                      </select>
              </span>
          
            <span className={StylesM.inputgroup}>
                <label className={StylesM.label} htmlFor="" >Filtrer les lignes : </label>
                <input type="text"  placeholder="Rechercher" value={valeurRechercheGlobale} onChange={e => setValeurRechercheGlobale(e.target.value)} className={StylesM.test}/>
            </span>
          
          <span  className={StylesM.inputgroup} style={{textAlign:"right", fontWeight:"bold"}}>
                  <span className="col">
                    <button title="Ajouter un Utilisateur" className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fa-solid fa-user-plus"></i></span></button>
                  </span>
                  <span className="col">
                  <button  title="Reload" onClick={btnRoload} className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-sync-alt"></i></span></button>

                  </span>
                  <span className="col">
                  <button title="Pdf" onClick={btnGeneratePdf} className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-file-pdf"></i></span></button>
                  </span>
                  <span className="col">
                  <button title="Excel" className={StylesM.idBtnIcon}><span className={StylesM.idIconControl}><i class="fas fa-file-excel"></i></span></button>

                  </span>
          </span>
        
        

      </div>


      <div id={StylesM.classTopNavRechMobile}>

      <div className={StylesM.barrebas1}>

      <span  className={StylesM.inputgroupI} >

       
                 
                          <input type="checkbox"  id={StylesM.test} checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} className={StylesM.inputGlobal3}/>
                  

                  <span className={StylesM.toutafficher1} id={StylesM.label}> 
                    Tout afficher	
                  </span>

        
        </span>


      <span  className={StylesM.inputgroupI} >

              

              <label>Nombre de lignes : </label>
              
              
                        <select value={nombrePageValeur} onChange={handleChangeNombrePage} >
                            <option ></option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>

                        </select>

              

      </span>

      <span className={StylesM.RightTopBar}>

                            {
                      show ?
                      <span 
                      id={StylesM.btnPlus} style={{backgroundColor:"goldenrod", color:"white"}} 
                      onClick={() => showMenu()}>
                      <FontAwesomeIcon icon={faPlus} className={StylesM.iconplus} style={{color: "black",fontSize:"22px"}} />          </span>:
                      <span 
                      id={StylesM.btnPlus} style={{fontWeight:"bold", color:"white"}}
                      onClick={() => showMenu()}>
                      <FontAwesomeIcon icon={faPlus}style={{color: "black"}} />          </span>
                      }

      </span>


      </div>

</div>

      </div>


      <div className={StylesM.classTable}>


        <div className={StylesM.divTableOverflow}>
      <div id={StylesM.idTestTable}>
      <table class="table">
      <thead>
            <tr id={StylesM.idForStyleTh}>
                                  <th>Id 
                                  </th>
                                  <th>Username
                                  </th>
                                  <th>Role
                                  </th>
                                  
                                  <th className={StylesM.fix}>
                                  Action
                                  </th>
            </tr>
            <tr>
              <th>
                <input type="text"  id={StylesM.idInputRecherche} value={matriculeRecherche} onChange={e => setMatriculeRecherche(e.target.value)} />
              </th>
                                  <th>
                                  <input type="text" id={StylesM.idInputRecherche}  value={nomRecherche} onChange={e => setNomRecherche(e.target.value)}/>
                                  </th>
                                  <th>
                                  <input type="text"   id={StylesM.idInputRecherche}  value={prenomRecherche} onChange={e => setPrenomRecherche(e.target.value)}/>
                                  </th>
                                  
                                  
                                  <th className={StylesM.fix}>
                                  </th>
            </tr>
          </thead>
          <tbody>
           

            
                            
           {users ? 

           users.map(

            emp =>
           
               <tr>
               <td ><span className="btnFichiePersonnelStyle" value={emp.id} onClick={(e) => {btnFichePersonnel(e);
      setToken('matriculeAvenant', emp.matricule_employe);
      setToken('listMatriculeNomPrenom', [emp.matricule_employe, emp.nom_employe, emp.prenom_employe]); 
    }}>{emp.id}</span></td>
               <td><span className="btnFichiePersonnelStyle" value={emp.id}  onClick={(e) => {btnFichePersonnel(e); setToken('matriculeAvenant', emp.matricule_employe);
      setToken('listMatriculeNomPrenom', [emp.matricule_employe, emp.nom_employe, emp.prenom_employe]);
    }}>{emp.username}</span></td>
               <td><span className="btnFichiePersonnelStyle" value={emp.id} onClick={(e) => {btnFichePersonnel(e); setToken('matriculeAvenant', emp.matricule_employe);
      setToken('listMatriculeNomPrenom', [emp.matricule_employe, emp.nom_employe, emp.prenom_employe]);
    }}>{emp.role}</span></td>
              
               <td className={StylesM.fix}>
               <a href="#" data-tip="edit"><i class="fas fa-edit"></i></a>
                <span id="btnWithoutStyleSalarie" value={emp.id} onClick={btnMasquer} data-tip="masquer" class="fa-solid fa-eye-slash"></span>
                <a href="#" data-tip="envoyer "><i class="fa-solid fa-paper-plane"></i></a>
                <span id="btnWithoutStyleSalarie" value={emp.id} onClick={btnSupprimer} data-tip="supprimer" class="fas fa-trash"></span>
                           <select data-tip="Autre">
                               <option data-tip="Autre" value="" ></option>
                           </select>
                
                   
                   {
                       idSupprimerValeur === emp.id ? 
                       <span>
                           <br />
                           <div id='alertClass'>

                               <p className="alert alert-warning">
                               Voulez-vous vraiment supprimer l'employé sélectionné ?
                               <br />
                               <button class="btnConfirmationSuppressionNon" onClick={btnSupprimerNon}>Non</button><button class="btnConfirmationSuppressionOui" value={emp.id} onClick={btnSupprimerOui}>Oui</button>
                               </p>
                               <hr/>

                           </div>
                       </span>
                       
                       :<span></span>
                   }
               </td>
           </tr>                        
           )
            : <span></span>

           }

                  

          </tbody>
        </table>
      </div>


      </div>      
      
   

      </div>



      <div className={StylesM.bottomToolsBar}>

      <div className={StylesM.barrebas}>
      <span className={StylesM.inputgroup1} >

              <span className={StylesM.leftBottomBar}>

              
              <input type="checkbox" className={StylesM.inputGlobal8}
              checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}}/> 
              <span className={StylesM.inputGlobal77} > Tout afficher	</span>

            </span>


              

              </span>

              <span style={{width:"auto", fontWeight:"bold"}} className={StylesM.inputgroup1}  id={StylesM.inputgroupIp2} >
                <label className={StylesM.label}>Nombre de lignes: </label>
                                      <select value={nombrePageValeur} onChange={handleChangeNombrePage} className={StylesM.test}>
                                          <option ></option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="5">5</option>
                                          <option value="10">10</option>
                                          <option value="25">25</option>

                                      </select>
              </span>

              <span className={StylesM.inputgroup2}  >
              <span className={StylesM.centerBottomBar}>

                  <span className={StylesM.pagination} >

                  {nombrePageArray && nombrePageArray[0] === selectedPageNumber  ? 
                  
                  <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}} disabled> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>
                  
                  : 
                  <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber-1)); setSelectedPageNumber(selectedPageNumber-1)}}> <FontAwesomeIcon icon={faArrowLeft} style={{color: "black"}} />  </button>
                  
                  }

              { selectedPageNumber > 2 ? <>...</> :<></>}


            {nombrePageArray ? nombrePageArray.map(nbr => 


                   <>
                   
                   
                   {  


                  Math.abs(selectedPageNumber - nbr) <= 2 ? 

                  <button className={
                    selectedPageNumber === nbr ? StylesM.classSelectedPageNumber : StylesM.classPageNumber
                  } value={selectedNombrePageValeur*nbr} onClick={(e) => {btnNombrePage(e); setSelectedPageNumber(nbr)}}>{nbr}</button> : <></>



                  }
                   
                   
                   </>
            
            
            
              
              
              
              ) : <></>} 




                { nombrePageArray && nombrePageArray.length-selectedPageNumber > 2 ? <>...</> :<></>}


                {nombrePageArray && nombrePageArray[nombrePageArray.length - 1] === selectedPageNumber  ? 
                  
                  <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}} disabled><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}}/> </button>
                  
                  : 

                  <button className={StylesM.btnPagination}  onClick={() => {btnNombrePageFunction(selectedNombrePageValeur*(selectedPageNumber+1)); setSelectedPageNumber(selectedPageNumber+1)}}><FontAwesomeIcon icon={faArrowRight} style={{color: "black"}} /> </button>

                  
                  }

                  </span>


              </span>
              </span>
              <span className={StylesM.inputgroup1}  id={StylesM.inputgroupIp}  >
              <span className={StylesM.rightBottomBar}>

                    
                 { 
              idMasquer.length>0 ? <span className={StylesM.creeravenat} >Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.creeravenat}>Salariés masqués : 0 </span>
                 }
                    </span> 

              </span>



      </div>



{/* 
            <table id={StylesM.idBarBottomPC} style={{width:"100%", color:"rgb(218, 165, 32)", backgroundColor:"rgb(218, 165, 32, 20%)"}}>
        <tr>
          <td style={{textAlign:"left"}} id={StylesM.tableRepeter}>

            <span className="col" style={{ width:"100%",fontWeight:"bold"}}>
            <span className="col">
              <input type="checkbox" checked={afficherChecked} onChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); setNombrePage(defaultNombrePageValeur)}}} className={StylesM.inputGlobal3}/> 
              <span className={StylesM.toutafficher2} > Tout afficher	</span>

            </span>

            <span className="col" style={{width:"auto", color:"red"}}>
                <label className={StylesM.nombredelignes2}>Nombre de lignes : </label>
                                      <select value={nombrePageValeur} onChange={handleChangeNombrePage} className={StylesM.inputGlobal2}>
                                          <option ></option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="5">5</option>
                                          <option value="10">10</option>
                                          <option value="25">25</option>

                                      </select>
              </span>


          </span>

          </td>
          <td  style={{textAlign:"left", width:"30%"}}>
            <span >
            {nombrePageArray ? nombrePageArray.map(nbr => <button className="col" style={{border:"none", backgroundColor:"transparent"}}  value={selectedNombrePageValeur*nbr} onClick={btnNombrePage}>{nbr}</button>) : <span></span>}
            </span>
          </td>
          <td style={{textAlign:"right"}}>
          <span style={{fontWeight:"bold"}}>
          {
              idMasquer.length>0 ? <span className={StylesM.nombredelignes3}>Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.nombredelignes3}>Salariés masqués : 0 </span>
            }
          </span>
          </td>
        </tr>

            </table>   */}
      
          {/* <div id={StylesM.classBottomNavRechMobile}> */}

        {/* <span id={StylesM.idPagination}>
          {nombrePageArray ? nombrePageArray.map(nbr => <button className="col" style={{border:"none", backgroundColor:"transparent", padding:"0",fontWeight:"bold"}}  value={selectedNombrePageValeur*nbr} onClick={btnNombrePage}>{nbr}</button>) : <span></span>}
        </span> */}

        {/* <span  id={StylesM.idMasquer}>
          {
              idMasquer.length>0 ? <span className={StylesM.nombredelignes3}>Salariés masqués : {idMasquer.length} </span>: <span className={StylesM.nombredelignes3}>Salariés masqués : 0 </span>
            }
        </span> */}

          {/* </div> */}

      </div>

    </div>
  )
}

export default DashboardAdministrationSection