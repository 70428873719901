import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import AbsenceSection from "./AbsenceSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function Absence(){

    return(
        <div>
            <NavBarGlobal  section = {<AbsenceSection />}/>
        </div>
    )
}

export default Absence;