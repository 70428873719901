import React from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import NavBarGlobal from "./../../Global/NavBar/NavBarGlobal/NavBarGlobal.jsx";

import EmbaucheSection from "./EmbaucheSection";

function Embauche(){

    return (
        <div>
            <NavBarGlobal section = {<EmbaucheSection />}/>
        </div>
    )
}


export default Embauche;