import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import ActiontacheAdministrationSection from './ActiontacheAdministrationSection.jsx';



export const ActiontacheAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<ActiontacheAdministrationSection />} />
    {/* ok action tache  */}
   
 </div>
)}


export default ActiontacheAdministration
