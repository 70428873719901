import React from "react";
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from '../../../APIService.js';

import styles from './NombreLignesAdministrationSection.module.css';
import {useNavigate} from 'react-router-dom';




function NombreLignesAdministrationSection() {
    const [token, setToken, removeToken] = useCookies(['mytoken']);
    const [listNombreLignesAdministration, setListNombreLignesAdministration] = useState(null)
    const [showAjouter, setShowAjouter] = useState(false)
    const [nombreLignes, setNombreLignes] = useState(null)
    const [idModif, setIdModif] = useState(null); 
    const [nombreLigneModif, setNombreLignesModif] = useState(null); 


    let navigate = useNavigate()

    useEffect(() => {

        if(token['mytoken']){
            APIService.getNombreLignesAdministration(token)
            .then(resp => setListNombreLignesAdministration(resp))
        }
      
    }, [token['mytoken']])

    const btnShowAjouter = () => {
   
    if (showAjouter) {
        setShowAjouter(false);
        setIdModif(null);  
        setNombreLignesModif(null);
      } else {
        setShowAjouter(true);
      }
    };

    function handelAjouterNombreLignesAdministration(e){
        e.preventDefault();

        if (nombreLignes) {
          let formData = new FormData();
          formData.append('nombre_Lingnes', nombreLignes);
      
              APIService.postNombreLignesAdministration(formData, token).then(() => {
              APIService.getNombreLignesAdministration(token).then((resp) => setListNombreLignesAdministration(resp));
            })
            .then(

                () =>
                {   
                    setNombreLignes('');
                    setIdModif(null);
                    setShowAjouter(false);
                }
            )          
      
          
        } else {
          console.log('Le nombre de Lingnes est obligatoire');
        }
      };
      

      
        function handelModifierNombreLignesAdministration(e){
            e.preventDefault();

        if (nombreLignes) {
            let formData = new FormData();
            formData.append('nombre_Lingnes', nombreLignes);

            APIService.putNombreLignesAdministration(idModif, formData, token)
              .then(() => {
                APIService.getNombreLignesAdministration(token).then((resp) => setListNombreLignesAdministration(resp));
              })
              .then(

                () =>
                {   
                    setNombreLignes('');
                    setIdModif(null);
                    setShowAjouter(false);
                }
            )
            
      }

    }


    
    const handelSupprimerNombreLignesAdministration = (id) => {
        if (id) {
          APIService.deleteNombreLignesAdministration(id, token).then(() => {
            APIService.getNombreLignesAdministration(token).then((resp) => setListNombreLignesAdministration(resp));
          });
        }
      };
    
   
    
    
  return (
    <div className={styles.classCard}>


        <div className={styles.classMenu}>

             <button  onClick={() => navigate('/donnees')} style={{cursor:"pointer"}}>Sexe</button>
             <button  onClick={() => navigate('/languesAdministartion')} style={{cursor:"pointer"}} >Langue</button>
             <button  onClick={() => navigate('/situationFamilialeAdministration')} style={{cursor:"pointer"}} >sitiationfamiliale</button>
             <button  onClick={() => navigate('/TypeContrats')} style={{cursor:"pointer"}} >TypeContrats</button>
             <button  onClick={() => navigate('/jours')} style={{cursor:"pointer"}}>jours</button>
             <button  onClick={() => navigate('/temps')} style={{cursor:"pointer"}}>temps</button>
             <button  onClick={() => navigate('/banques')} style={{cursor:"pointer"}}>banques</button>
             <button   onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button>
             {/* <button  onClick={() => navigate('/etablissement')} style={{cursor:"pointer"}}>Etablissement</button> */}
             <button  onClick={() => navigate('/departement')} style={{cursor:"pointer"}}>Departement</button>
             <button  onClick={() => navigate('/poste')} style={{cursor:"pointer"}}>Poste</button>
             <button onClick={() => navigate('/nationalite')} style={{cursor:"pointer"}} >Nationalite</button>
             <button onClick={() => navigate('/pays')} style={{cursor:"pointer"}} >pays</button>
             <button className={styles.classBtnActive} >Statut Administration</button>












        </div>


        <div className={styles.classTable}>

            <table className={styles.tableStyle}>
                <thead>
                    <tr>

                        <th>id</th>
                        <th>nombre de Lingnes</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {
                        listLangue && 

                        listLangue.map(
                            lg => 

                            <tr>
                                    <td>{lg.id}</td>
                                    <td>{lg.nom}</td>
                                    <td>
                                        <button onClick = {() => handelSupprimerLangue(lg.id)}>Supprimer</button>
                                        <button >Modifier</button>


                                    </td>
                            </tr>


                        )


                    } */}

{
listNombreLignesAdministration &&
          listNombreLignesAdministration.map((lg) => (
            <tr key={lg.id}>
              <td>{lg.id}</td>
              <td>{lg.nombre_Lingnes}</td>
              <td>
                <button onClick={() => handelSupprimerNombreLignesAdministration(lg.id)}>Supprimer</button>
                <button onClick={() => {setIdModif(lg.id); setNombreLignes(lg.nombre_Lingnes); setShowAjouter(true)}}>Modifier</button>
              </td>
            </tr>
          ))}
                  
                </tbody>
            </table>

        </div>
        <div className={styles.classAjouter}>
            <div>
                <button onClick = {btnShowAjouter} >Ajouter</button>
            </div>

            {
                showAjouter && 

                <div>

                        <form  onSubmit = {e => {
                        if(idModif){
                            handelModifierNombreLignesAdministration(e)
                        } else {handelAjouterNombreLignesAdministration(e)}
                                             } }>
                        Nom : <input type="text" value={nombreLignes} onChange = {e => setNombreLignes(e.target.value)} />
                        </form>
                    <div>
                    
                        <button onClick = { e =>

                            idModif ?

                            handelModifierNombreLignesAdministration(e)
                            :
                            handelAjouterNombreLignesAdministration(e)
                            
                            
                            }>
                               
                                {
                                    idModif ? <>Modifier</>
                                    : <>Ajouter</>
                                }
                            </button>
                            
                    </div>

               </div>
            }

        </div>

    </div>
  )
}


export default NombreLignesAdministrationSection
