import React from "react";
// import NavBarGlobal from "./../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import DashboardSection from './DashboardSection.jsx';
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";



export const Dashboard = () => {
  return (
  <div>

    <NavBarGlobal section = {<DashboardSection />} />
   
 </div>
)}
export default Dashboard;





















// import React from 'react';
// import {useCookies} from 'react-cookie';
// import {useState, useEffect} from 'react';
// import APIService from './../../../APIService';
// import GestionTempsNavBar from './../GestionDeTempsGlobal/NavBar/GestionTempsNavBar.jsx';
// import GestionTempsCard from './../GestionDeTempsGlobal/Card/GestionTempsCard.jsx';
// import DashboardSection from './DashboardSection.jsx';


// function Dashboard() {
//     const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
//     const [role, setRole] = useState();

//     useEffect(() => {

//       if(token['mytoken']){

//         APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));

  
//       }
  
//     }, [token])

//     useEffect(() => {
//       if(role){
//         if(role !== "Manager"){
//           window.location.href = "/declaration";
//         }
//       }
//     }, [role])
    
    
  
//     if(role === "Manager"){
//     return (
//       <div class='bodback'>
//        <GestionTempsNavBar />
//        <GestionTempsCard section={DashboardSection} page="dashboard"/>
//       </div>
//     )
//   }

// }

// export default Dashboard;