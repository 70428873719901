import React from 'react';
import "./../../../App.css";
import {useState} from 'react';
import Users from "./../../img/users.png";
import Usersb from "./../../img/users-blanc.png";
import GestionB from "./../../img/gestion-blanc.png";
import GestionN from "./../../img/gestion-noir.png";
import PlanificateurN from "./../../img/planificateur-noir.png";
import PlanificateurB from "./../../img/planificateur-blanc.png";
import Styles from './AcceuilStyle.module.css';
import AccueilTopNavBar from './AccueilTopNavBar';

// import '../App.css';
function AccueilStyle() {
      const [etatTest1, setEtatTest1] = useState(false)

      const test1 = () => {
        
          setEtatTest1(true)
        
      }

      const test2 = () => {
        
        setEtatTest1(false)
      
    }
    const [etattest, setEtattest] = useState(false)

      const test3 = () => {
        
          setEtattest(true)
        
      }

      const test4 = () => {
        
        setEtattest(false)
      
    }
      const [etatTest2, setEtatTest2] = useState(false)

      const test5 = () => {
        
          setEtatTest2(true)
        
      }

      const test6 = () => {
        
        setEtatTest2(false)
      
    }

      const AdminBtn = () => {

        window.location.href = '/admin'

      }

      const Barresbtn = () => {

        window.location.href = '/Fichierbarres'

      }
      const Services = () => {

        window.location.href = '/Services'

      }
       
  return (
    
      <div 
      >
    <div className={Styles.classDivContainer}>

  


       <span className={Styles.cardAdminPc}>

                <div className={Styles.container1} >
                    <div 
                        id={Styles.Cmodule1} onMouseOver={test1} onMouseOut={test2}>
                          
                          {etatTest1 === false ? 
                          

                                <img src={PlanificateurN} className={ Styles.imageN}/>
                            
                          :
                            <div style={{textAlign:"center"}}>
                                    <img src={PlanificateurB} className={ Styles.imageN} />
                                    <h1 id={Styles.module1}>Module</h1>
                                    <h2 id={Styles.nameModuleStyle1}>Plannification</h2>
                            </div>
                          }
                    </div>
            </div>

       </span>

       <span className={Styles.cardAdminPc}>

                <div className={Styles.container1} >
                    <div 
                        id={Styles.Cmodule1} onMouseOver={test3} onMouseOut={test4} onClick={AdminBtn}>
                          
                          {etattest === false ? 
                          
                            <div>

                                <img src={Users} className={ Styles.imageN}/>
                            
                            </div> 
                          :
                            <div style={{textAlign:"center"}}>
                                    <img src={Usersb} className={ Styles.imageN}/>
                                    <h1 id={Styles.module2}>Module</h1>
                                    <h2 id={Styles.nameModuleStyle2}>Administration des personnels</h2>
                            </div>
                          }
                    </div>
            </div>

       </span>

       <span className={Styles.cardAdminPc}>

                <div className={Styles.container1} >
                    <div 
                        id={Styles.Cmodule1} onMouseOver={test5} onMouseOut={test6}>
                          
                          {etatTest2 === false ? 
                          
                            <div>

                                <img src={GestionN} className={ Styles.imageN}/>
                            
                            </div> 
                          :
                            <div style={{textAlign:"center"}}>
                                    <img src={GestionB} className={ Styles.imageN}/>
                                    <h1 id={Styles.module3}>Module</h1>
                                    <h2 id={Styles.nameModuleStyle3}>Gestion des talents</h2>
                            </div>
                          }
                    </div>
            </div>

       </span>


       
      </div>
      
      </div>
  )
  
}

export default AccueilStyle

