import React from "react";
import styles from './LineFilterTableInput.module.css'

function LineFilterTableInput(props) {

  return (
    
    <span className={styles.inputgroup}>
            <label className={styles.label} htmlFor="" >Filtrer les lignes  </label>
            <input type="text"  placeholder="Rechercher" value={props.valeurRechercheGlobale} onChange={props.OnChangeRechGlobale} className={styles.test}/>
    </span>

  )
}

export default LineFilterTableInput