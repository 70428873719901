import React from "react";
// import NavBarGlobal from "../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import HistoriqueActiviteSection from "./HistoriqueActiviteSection.jsx";
import NavBarGlobal from "../../../Global/NavBar/NavBarGlobal/NavBarGlobal";



export const NavBar = () => {
  return (
  <div>

    <NavBarGlobal section = {<HistoriqueActiviteSection />} />
   
 </div>
)}
export default NavBar;






















// import React, {useEffect, useState} from 'react';
// import {useCookies} from 'react-cookie';
// import APIService from './../../../../APIService';
// import GestionTempsNavBar from './../../GestionDeTempsGlobal/NavBar/GestionTempsNavBar.jsx';
// import GestionTempsCard from './../../GestionDeTempsGlobal/Card/GestionTempsCard.jsx';
// import HistoriqueActiviteSection from './HistoriqueActiviteSection.jsx';
// import NavBarGlobal from "../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal.jsx";



// function HistoriqueActivite() {
//     const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
//     const [role, setRole] = useState();


//     useEffect(() => {

//       if(token['mytoken']){

//         APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));

  
//       }
  
//     }, [token])

//     useEffect(() => {
//       if(role){
//         if(role !== "Manager"){
//           window.location.href = "/";
//         }
//       }
//     }, [role])

//     if(role === "Manager"){
        
//   return (
//     <div class='bodback'>
      
//       <NavBarGlobal section = {<HistoriqueActiviteSection />} />
      

//     </div>
//   )

//       }
    


// }


// export default HistoriqueActivite;