import React, { useState, useEffect, Children } from "react";
import ReactDOM from "react-dom";
import styles from "./PopupComponent.module.css"
import { CSSTransition } from "react-transition-group";
import APIService from "../../APIService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const PopupComponent = ({ width, height, onClose, show,Children,id,notifications,popupStyle,headerDate}) => {
  console.log("notif ", notifications,"id ", id ,"children", Children)
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (show) {
      togglePopup();
    }
  }, [show]);

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };



  useEffect(() => {

    if(notifications){
      console.log("Popup notifications : ", notifications[0].props.children,"header date",headerDate[0].props.children[1])
    }
    
  }, [notifications])

  const [expandedNotification, setExpandedNotification] = useState(null);

  const toggleContent = (resp) => {
    setExpandedNotification(expandedNotification === resp ? null : resp);
  };

  
if(show){
  return(
  <>
  <div id={id} className={styles.modal_} onClick={onClose}>
      {width && height ? (
        <>
          <div className={styles.popup} style={{ width, height,maxWidth:"400px" ,maxHeight: "600px", overflowY: "hidden",...popupStyle}} onClick={(e) => e.stopPropagation()}>
              <div className={styles.header}>
                <span>
                  {headerDate[0].props.children[1]}
                </span>
              </div>
              <div id={styles.content}>
                        <div className={styles.testpopup}>
                          {notifications && 
                          notifications.map(resp => 
                                <>
                                <div className={styles.containerStyle}>
                                  <div className={styles.containerNotif}>
                                    <div className={styles.firstPartNotif}>
                                      {expandedNotification !== resp && resp.props.children[0].length >= 40 ? (
                                        <>
                                        <div className={styles.separateNotifPlus}>
                                          <div className={styles.separateNotif}>
                                              {resp.props.children[0].slice(0, 30)} ...
                                          </div>
                                          <div className={styles.separatePlus}>
                                            <span className={styles.plusButton}>
                                          <i class="fa-solid fa-circle-plus" onClick={() => toggleContent(resp)}></i>
                                          </span>
                                          </div>
                                        </div>
                                        </>):
                                        (<>
                                          <div className={styles.fullNotif}>
                                          {resp.props.children[0]}
                                          </div>
                                        </>)}
                                     </div>
                                    <div className={styles.secondPartHourAction}>
                                      <div className={styles.hourZone}>
                                      <i class="fas fa-clock"></i>&nbsp; {resp.props.children[2]}
                                      </div>
                                      <div className={styles.actionZone}>
                                          <button className={styles.actionButton} onClick={resp.props.children[3].props.onClick}>action</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </>
                            )
                          }
                        </div>
              </div>
          </div>
          </>):(
    <>:</>)}
    </div>
  </>
  )
} else {
  return(
    <></>
  )
}
};
export default PopupComponent;