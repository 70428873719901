
import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import ActionsAdministrationSection from "./ActionsAdministrationSection.jsx";



export const ActionsAdministration = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<ActionsAdministrationSection />} />
    
   
 </div>
)}


export default ActionsAdministration


