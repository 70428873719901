import React from 'react';
import styles from "./TopMenuSalarie.module.css"
import {useState, useEffect, useRef} from 'react';

function TopMenuSalarie(props) {

   
  // const [show, setShow] = useState(false);
  // const popupRef = useRef(null);
  // const barButtonRef = useRef(null);

  // const handleClick = (event) => {
  //   if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)) {
  //     setShow(false);
  //   }
  // };

  // const showMenu = () => {
  //   setShow(prevShow => !prevShow);
  // };

  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener('mousedown', handleClick);
  //   } else {
  //     document.removeEventListener('mousedown', handleClick);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClick);
  //   };
  // }, [show]);
// testtttt
      const [show, setShow] = useState(false);
      const popupRef = useRef(null);
      const barButtonRef = useRef(null);

      const showComponents = () => {
        setShow(prevShow => !prevShow);
        if (show) {
          console.log("Show True : ", show)
              popupRef.current.classList.remove(styles.fadeOut);
          setTimeout(() => {
                popupRef.current.style.display = 'none';
                console.log("New ref after .51s : ", popupRef.current.style.display)
            }, 510); 
      }else{
        console.log("Show False : ", show)
        popupRef.current.classList.add(styles.fadeIn);
        popupRef.current.style.display = 'block';
      }
      };

      const handleClick = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !barButtonRef.current.contains(event.target)
      ) {
        showComponents()
        }
      };
      
      useEffect(() => {
        if (show) {
          document.addEventListener('mousedown', handleClick);
        // setCaretDirection('up');
        } else {
          document.removeEventListener('mousedown', handleClick);
        // setCaretDirection('down');
        }
        return () => {
          document.removeEventListener('mousedown', handleClick);
        };
      }, [show]);
// testtttt

  const [showAdministrationMenu, setShowAdministrationMenu] = useState(false);
  const [showPlannificationMenu, setShowPlannificationMenu] = useState(false);
  const [showGestionMenu, setShowGestionMenu] = useState(false);

  const [styleAdministration, setStyleAdministration] = useState()
  const [stylePlanification, setStylePlanification] = useState({backgroundColor:"transparent"})
  const [styleGestion, setStyleGestion] = useState({backgroundColor:"transparent"})

  function showMenuAdministraion() {
    if(showAdministrationMenu){
      setShowAdministrationMenu(false)
      setStyleAdministration({})


    }else{
      setShowAdministrationMenu(true)
      setStyleAdministration({backgroundColor:"#ddd", color:"black"})

      setShowPlannificationMenu(false)
      setStylePlanification({})

      setShowGestionMenu(false)
      setStyleGestion({})


    }
  }


  function showMenuPlannification() {
    if(showPlannificationMenu){
      setShowPlannificationMenu(false)
      setStylePlanification({})


    }else{
      setShowPlannificationMenu(true)
      setStylePlanification({backgroundColor:"#ddd", color:"black"})

      setShowAdministrationMenu(false)
      setStyleAdministration({})

      setShowGestionMenu(false)
      setStyleGestion({})
      
    }
  }

  function showMenuGestion() {
    if(showGestionMenu){
      setShowGestionMenu(false)
      setStyleGestion({})


    }else{
      setShowGestionMenu(true)
      setStyleGestion({backgroundColor:"#ddd", color:"black"})


      setShowPlannificationMenu(false)
      setStylePlanification({})

      setShowAdministrationMenu(false)
      setStyleAdministration({})
    }
  }
  var styleDonnees= {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};
    var styleSalarie= {backgroundColor:"",color: "rgb(255, 255, 255,60%)"};
    var styleContrat = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};
    var styleFormation = {backgroundColor:"",color: "rgb(255, 255, 255,80%)"};



    if(props.page){
        if(props.page === "donnees"){
            styleDonnees = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
        }
        if(props.page === "salarie"){
          styleSalarie = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
        }
        if(props.page === "contrat"){
            styleContrat = {borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
        }
        if(props.page === "formation"){
            styleFormation ={borderLeft: "1px solid rgba(128,128,128,30%)",borderRight: "1px solid rgba(128,128,128,30%)",borderBottom: "2px solid goldenrod",boxShadow: "0px 6px 12px -6px rgba(0,0,0,0.3)",backgroundColor:"goldenrod",color:"white",transform:"scale(1.06)"};
        }
      }
  return (
    <div className={styles.sectionStyle}>
      <div className={styles.emptycontainer}>
        <div className={styles.firstPart}></div>
        <div className={styles.container}>
            {/* <div className={styles.topMenuContainer} id={styles.idTopNavBar}> */}
                      {
                    props.page === "salarie" ?
                    <>
            <div className={styles.topMenuContainer_} id={styles.idTopNavBar}>
                      <div className={styles.vide}></div>
                      <div className={styles.column} style={styleSalarie}>
                        <a href="salarie" className={styles.txtLinkBtn}>
                          <button className={styles.classBtnCalendrier}  >
                           Dashboard 
                          </button>
                              </a>
                      </div>
                      <div className={styles.vide}></div>
                      </div>
                      </>:
                      <>
            <div className={styles.topMenuContainer} id={styles.idTopNavBar}>

                      <div className={styles.vide}></div>
                      <div className={styles.column} id="donnees" style={styleDonnees}>
                        <a href="salarie" className={styles.txtLinkBtn}>
                            <button className={styles.classBtnCalendrier} >
                            Dashboard 
                            </button> </a>
                      </div>
                      <div className={styles.vide}></div>
                        <div className={styles.column} id="contrat" style={styleContrat}>
                        <a href="contrat" className={styles.txtLinkBtn}>
                            <button className={styles.classBtnCalendrier} >
                                 Contrat
                            </button> </a>
                      </div>
                      <div className={styles.vide}></div>
                      <div className={styles.column} id="formation" style={styleFormation}>
                        <a href="statut" className={styles.txtLinkBtn}>
                            <button className={styles.classBtnCalendrier} >
                                Formation 
                            </button> </a>
                      </div>  
                      <div className={styles.vide}></div>
                      </div>
                      </>}
            {/* </div> */}
        </div>
        <div className={styles.lastPart}></div>
      </div>
      {/* ****************MOBILE ************** */}
      <div className={styles.emptycontainerMobile}>
        <div className={styles.firstPartMobile}>
             <span className={styles.element} ref={barButtonRef} 
             onClick={() => 
            //  showMenu
            showComponents
             ()} style={{ backgroundColor: show ? "rgb(218, 165, 32,80%)" : "" }}>
                  <i className="fa fa-bars"></i>
            </span>
            <div id={styles.itemsMenu}  ref={popupRef}   className={show ? `${styles.active}` : `${styles.hidden}`}>
              <div className={styles.containerPopUP}>
              {
                    props.page === "salarie" && props.page!=="donnees" ?
                    <>
                <div className={styles.buttonMobile} style={styleSalarie}>
                  <a href="salarie"  className={styles.txtLinkBtn} >
                    <button  className={styles.btnMenu} > 
                      Dashboard
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleContrat}>
                  <a href="contrat" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  > 
                      Contrat
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleFormation}>
                  <a href="statut" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}    > 
                      Formation
                    </button>
                  </a>
                </div>
                </>:<>
                <div className={styles.buttonMobile} style={styleDonnees}>
                  <a href="salarie"  className={styles.txtLinkBtn} >
                    <button  className={styles.btnMenu} > 
                      Dashboard
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleContrat}>
                  <a href="contrat" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}  > 
                      Contrat
                    </button> 
                  </a>
                </div>
                <div className={styles.buttonMobile} style={styleFormation}>
                  <a href="statut" className={styles.txtLinkBtn}>
                    <button className={styles.btnMenu}    > 
                      Formation
                    </button>
                  </a>
                </div>
                </>}
                </div>
            </div>
        </div>
        <div className={styles.secondPartMobile}>
                  {
                    props.page == "salarie" || props.page=="donnees" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <div className={styles.btnMenu_}  > 
                    Dashboard
                    </div> 
                    </div>   
                    </>:
                    <></>
                  }
                  {
                    props.page == "contrat" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_} > 
                     Contrat
                    </button> 
                    </div>   
                    </>:<></>
                  }
                  {
                    props.page == "formation" ?
                    <>
                    <div className={styles.buttonShowingMobile}>
                    <button className={styles.btnMenu_}> 
                    Formation
                    </button> 
                    </div>   
                    </>:<></>
                  }
        </div>
      </div>

    </div>
  )
}

export default TopMenuSalarie