import React, {useEffect, useState} from 'react';
import styles from "./DeclarationSection.module.css";
import moment from 'moment';
import {useCookies} from 'react-cookie';
import APIService from './../../../../APIService';
import NavBarGlobal from '../../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal';
import TopMenuGestionDuTemps from '../../TopMenuGestionDuTemps';



function DeclarationSection() {
  const [token, setToken, removeToken] = useCookies(['mytoken', 'id', 'matriculeEmployeAttente', 'matriculeManager', 'matriculeActuel']);
  const [employes, setEmployes] = useState([]);
  const [employesList, setEmployesList] = useState([]);
  const [employesListResult, setEmployesListResult] = useState([]);
  const [inputDate, setInputDate] = useState()
  const [listDayDate, setListDayDate] = useState()
  const [listDayDateHist, setListDayDateHist] = useState()
  const [inputHeureDebut, setInputHeureDebut] = useState()
  const [inputHeureFin, setInputHeureFin] = useState()
  const [inputHeureCalculated, setInputHeureCalculated] = useState()
  const [inputTache, setInputTache] = useState()
  const [inputStyleLundi, setInputStyleLundi] = useState(styles.inputDate)
  const [inputStyleMardi, setInputStyleMardi] = useState(styles.inputDate)
  const [inputStyleMercredi, setInputStyleMercredi] = useState(styles.inputDate)
  const [inputStyleJeudi, setInputStyleJeudi] = useState(styles.inputDate)
  const [inputStyleVendredi, setInputStyleVendredi] = useState(styles.inputDate)
  const [inputStyleSamedi, setInputStyleSamedi] = useState(styles.inputDate)
  const [nombreHeure, setNombreHeure] = useState()
  const [inputMatriculeRecherche, setInputMatriculeRecherche] = useState("")
  const [inputNomPrenomRecherche, setInputNomPrenomRecherche] = useState("")
  const [inputNomRecherche, setInputNomRecherche] = useState("")
  const [etatMatriculeRecherche, setEtatMatriculeRecherche] = useState(false)
  const [etatNomPrenomRecherche, setEtatNomPrenomRecherche] = useState(false)
  const [etatNomRecherche, setEtatNomRecherche] = useState(false)
  const [matriculeTargetDeclaration, setMatriculeTargetDeclaration] = useState()
  const [nomDeclaration, setNomDeclaration] = useState();
  const [prenomDeclaration, setPrenomDeclaration] = useState();
  const [role, setRole] = useState();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [listStatutActiviteAdministration, setListStatutActiviteAdministration] = useState(null)




  useEffect(() => {

    if(token['mytoken']){

    APIService.getEmployes(token)
    .then(resp => setEmployes(resp))
    .catch(error => console.log(error))

    APIService.getRoleEmploye(token).then(resp => setRole(resp[0]["role_employe"]));
    
    }

  }, [token])

  useEffect(() => {

    if(token['mytoken']){
        APIService.getStatutActiviteAdministration(token)
        .then(resp => setListStatutActiviteAdministration(resp))
    }
  
}, [token['mytoken']])


  useEffect(() => {
    if(role && token['matriculeActuel']){
      console.log("role :", role)
      if(role === "Salarie"){

        setMatriculeTargetDeclaration(token["matriculeActuel"]);


        if(document.getElementById("idMatriculeRecherche")){
          let elem = document.getElementById("idMatriculeRecherche");
          elem.parentNode.removeChild(elem);
        }
        if(document.getElementById("idNomPrenomRecherche")){
          let elem = document.getElementById("idNomPrenomRecherche");
          elem.parentNode.removeChild(elem);
        }
        if(document.getElementById("idNomRecherche")){
          let elem = document.getElementById("idNomRecherche");
          elem.parentNode.removeChild(elem);
        }
           

        if(document.getElementById("listeTaches")){
            let elem = document.getElementById("listeTaches");
            elem.parentNode.removeChild(elem);
          }
        if(document.getElementById("historiqueAbsence")){
            let elem = document.getElementById("historiqueAbsence");
            elem.parentNode.removeChild(elem);
          }
        if(document.getElementById("historiqueActivite")){
          let elem = document.getElementById("historiqueActivite");
          elem.parentNode.removeChild(elem);
        }
        if(document.getElementById("dashboard")){
          let elem = document.getElementById("dashboard");
          elem.parentNode.removeChild(elem);
        }
      }
    }
  }, [role, token])


  useEffect(() => {
    if(employes){
      setEmployesList(employes.filter(resp => resp.matricule_manager === token['matriculeManager'] && resp.state_employe === true))
    }
  }, [employes])

  useEffect(() => {
    if(employes && role){
      if(role === "Manager"){
        employes.filter(resp => resp.matricule_employe === token['matriculeManager'] && resp.state_employe === true).map(resp => setNomDeclaration(resp.nom_employe))
        employes.filter(resp => resp.matricule_employe === token['matriculeManager'] && resp.state_employe === true).map(resp => setPrenomDeclaration(resp.prenom_employe))
      } else {

        if(role === "Salarie"){
          employes.filter(resp => resp.matricule_employe === token['matriculeActuel'] && resp.state_employe === true).map(resp => setNomDeclaration(resp.nom_employe))
          employes.filter(resp => resp.matricule_employe === token['matriculeActuel'] && resp.state_employe === true).map(resp => setPrenomDeclaration(resp.prenom_employe))
        } 

      }
    }
  }, [employes, role])

  useEffect(() => {
    if(inputMatriculeRecherche){
      setEtatMatriculeRecherche(true)
      setEtatNomPrenomRecherche(false)
      setEtatNomRecherche(false)

    }
    
  }, [inputMatriculeRecherche])

  useEffect(() => {
    if(inputNomPrenomRecherche){
      setEtatNomPrenomRecherche(true)
      setEtatMatriculeRecherche(false)
      setEtatNomRecherche(false)
    }
    
  }, [inputNomPrenomRecherche])

  useEffect(() => {
    if(inputNomRecherche){
      setEtatNomRecherche(true)
      setEtatMatriculeRecherche(false)
      setEtatNomPrenomRecherche(false)

    }
    
  }, [inputNomRecherche])
  
  
  

  useEffect(() => {

    if(inputMatriculeRecherche || inputNomPrenomRecherche || inputNomRecherche){
      setEmployesListResult(employesList.filter(resp => resp.matricule_employe.toLowerCase().includes(inputMatriculeRecherche.toLowerCase()) 
      && (resp.nom_employe.toLowerCase().includes(inputNomPrenomRecherche.toLowerCase()) 
          || resp.prenom_employe.toLowerCase().includes(inputNomPrenomRecherche.toLowerCase())) 
      ))
    }
    
  }, [inputMatriculeRecherche, inputNomPrenomRecherche, inputNomRecherche])
  

  useEffect(() => {
    if(JSON.stringify(listDayDate) === JSON.stringify(listDayDateHist)){
      setListDayDateHist(listDayDate);
    }else{

      setListDayDateHist(listDayDate);

      // Initialisation des styles des dates
      setInputStyleLundi(styles.inputDate)
      setInputStyleMardi(styles.inputDate)
      setInputStyleMercredi(styles.inputDate)
      setInputStyleJeudi(styles.inputDate)
      setInputStyleVendredi(styles.inputDate)
      setInputStyleSamedi(styles.inputDate)
    }
    
  }, [listDayDate])
  


  function diffDate(debut, fin){
    var xDebut = debut.split(":")
    var xD = parseInt(xDebut[1]) + parseInt(xDebut[0]*60)
    var xFin = fin.split(":")
    var xF = parseInt(xFin[1]) + parseInt(xFin[0]*60)
    var d = Math.abs(xF - xD)
    var h = parseInt(d/60)
    setNombreHeure(parseFloat(h.toString()+ '.' +parseInt((parseInt(d%60) * 100)/60).toString())) 
    var m = parseInt(d%60)

    var t = `${h}:${m}`

    return t
  }
  useEffect(() => {
    if(inputHeureDebut && inputHeureFin){
      setInputHeureCalculated( diffDate(inputHeureDebut, inputHeureFin) )
    }
    
  }, [inputHeureDebut, inputHeureFin])
  


  useEffect(() => {
    var day;
    var dateOffset;
    var myDate;
    var listDate = {"Lundi":"", "Mardi":"", "Mercredi":"", "Jeudi":"", "Vendredi":"", "Samedi":""}
    

    if(inputDate){

      myDate = new Date(inputDate);
      day = new Date(inputDate).getUTCDay();
      dateOffset = (24*60*60*1000) * (day-1);

      myDate.setTime(myDate.getTime() - dateOffset);
      listDate["Lundi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Mardi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Mercredi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Jeudi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Vendredi"]=moment(myDate).format("DD/MM/YYYY")

      myDate.setTime(myDate.getTime() + (24*60*60*1000));
      listDate["Samedi"]=moment(myDate).format("DD/MM/YYYY")


      setListDayDate(listDate)

    }
  
  }, [inputDate])
  
  
  

  const btnValider = () => {
    var data = {matricule_employe:"", tache_declarationActivite:"", heureDebut_declarationActivite:"", heureFin_declarationActivite:"", nombreHeure_declarationActivite:"", dateTache_declarationActivite:"", matricule_manager:"", dateDeclaration_declarationActivite:"", nom_declarationActivite:"", prenom_declarationActivite:"", 
    action_declarationActivite:null, 
    commentaireValidation_declarationActivite:null, nomValidation_declarationActivite:null, prenomValidation_declarationActivite:null, 
    dateValidation_declarationActivite:null}
    if(token['matriculeActuel'] && inputDate && inputHeureDebut && inputHeureFin && inputHeureCalculated && inputTache){
      if(matriculeTargetDeclaration){
        data["matricule_employe"]=matriculeTargetDeclaration;
      }else{
        data["matricule_employe"] = token['matriculeActuel'];
        
      }
      data["tache_declarationActivite"]=inputTache;
      data["heureDebut_declarationActivite"]=inputHeureDebut;
      data["heureFin_declarationActivite"]=inputHeureFin;
      data["nombreHeure_declarationActivite"]=nombreHeure;
      data["dateTache_declarationActivite"]=inputDate;
      data["matricule_manager"] = token['matriculeManager']; //Cas 1: En tant que manager.
      data["dateDeclaration_declarationActivite"] =moment().format("YYYY-MM-DD");
      data["nom_declarationActivite"] = nomDeclaration;
      data["prenom_declarationActivite"] = prenomDeclaration;

      APIService.InsererDeclarationActivite(data, token);


      if(inputDate === moment(listDayDate["Lundi"], "DD/MM/YYYY").format("YYYY-MM-DD")){
        console.log(inputDate ,"===", listDayDate["Lundi"])
        setInputStyleLundi(styles.inputDateVert)
      }
  
      if(inputDate === moment(listDayDate["Mardi"], "DD/MM/YYYY").format("YYYY-MM-DD")){
        setInputStyleMardi(styles.inputDateVert)
      }
  
      if(inputDate === moment(listDayDate["Mercredi"], "DD/MM/YYYY").format("YYYY-MM-DD")){
        setInputStyleMercredi(styles.inputDateVert)
      }
  
      if(inputDate === moment(listDayDate["Jeudi"], "DD/MM/YYYY").format("YYYY-MM-DD")){
        setInputStyleJeudi(styles.inputDateVert)
      }
  
      if(inputDate === moment(listDayDate["Vendredi"], "DD/MM/YYYY").format("YYYY-MM-DD")){
        setInputStyleVendredi(styles.inputDateVert)
      }
  
      if(inputDate === moment(listDayDate["Samedi"], "DD/MM/YYYY").format("YYYY-MM-DD")){
        setInputStyleSamedi(styles.inputDateVert)
      }
      setIsButtonClicked(false);
    }else{
      setIsButtonClicked(true);
      console.log("Tous les champs sont obligatoires !");
    }
    
  }
  return (

    <div className={styles.classCard} onClick={() => {setEmployesListResult()}}>
      <div className={styles.generalContainer}>
          <div className={styles.firstPart}>
          <TopMenuGestionDuTemps page="declarationActivite"/>
          </div>
          <div className={styles.secondPart}>
            <div className={styles.generalFirst}>
              <div className={styles.diviser}>
                <div className={styles.container}>
                  <div id="idMatriculeRecherche" className={styles.inputgroup}>
                    <span htmlFor="" className={styles.label}>Matricule</span>
                    <input className={styles.test} type="text" placeholder="Rechercher" value={inputMatriculeRecherche} onChange={e => setInputMatriculeRecherche(e.target.value)}/>
                  </div>
                  <div id="idNomPrenomRecherche" className={styles.inputgroup}>
                    <span htmlFor="" className={styles.label}>Prénom</span>
                    <input className={styles.test} type="text" placeholder="Rechercher" value={inputNomPrenomRecherche} onChange={e => setInputNomPrenomRecherche(e.target.value)} />
                  </div>
                  <div id="idNomPrenomRecherche" className={styles.inputgroup}>
                    <span htmlFor="" className={styles.label}>Nom</span>
                    <input className={styles.test} type="text" placeholder="Rechercher" value={inputNomRecherche} onChange={e => setInputNomRecherche(e.target.value)} />
                  </div>
                  <div className={styles.inputgroup}>
                    <span htmlFor="" className={styles.label}>Date</span>
                    <input id={styles.dateInput}  className={styles.test} placeholder="" type="date" onChange={e => setInputDate(e.target.value)} />
                  </div>
                </div>
                {/* **********************Mobile Inputs second part************************** */}

                <div className={styles.containerMobile2_}>
                  <div className={styles.group_}>
                    <div className={styles.labelMobile_}>Matricule</div>
                    <input className={styles.inputMobile_} type="text" value={inputMatriculeRecherche} onChange={e => setInputMatriculeRecherche(e.target.value)} required/>
                    <span className={styles.highlight_}></span>
                    <span className={styles.bar_}></span>
                  </div>
                  <div className={styles.group_}>
                    <div className={styles.labelMobile_}>Prénom</div>
                    <input className={styles.inputMobile_} type="text" value={inputNomPrenomRecherche} onChange={e => setInputNomPrenomRecherche(e.target.value)} required/>
                    <span className={styles.highlight_}></span>
                    <span className={styles.bar_}></span>
                  </div>
                  <div className={styles.group_}>
                    <div className={styles.labelMobile_}>Nom</div>
                    <input className={styles.inputMobile_} type="text" value={inputNomRecherche} onChange={e => setInputNomRecherche(e.target.value)} required/>
                    <span className={styles.highlight_}></span>
                    <span className={styles.bar_}></span>
                  </div>
                  <div className={styles.group_}>
                    <div className={styles.labelMobile_}>Date</div>
                    <input className={styles.inputMobile_} type="date" onChange={e => setInputDate(e.target.value)}/>
                    <span className={styles.highlight_}></span>
                    <span className={styles.bar_}></span>
                  </div>
                  </div>
                {/* *********************CardRecherche****************** */}
                <div className={styles.containerData}>
                    <div className={styles.matriculelist}>
                    {employesListResult && inputMatriculeRecherche && etatMatriculeRecherche ? 
                      <div id={styles.cardRechercheMatriculePopUp}>
                          {employesListResult.map(emp => 
                              <span>
                              <div onClick={ () => setMatriculeTargetDeclaration(emp.matricule_employe)}  id={styles.rechercheBtn}>{emp.matricule_employe} : {emp.nom_employe}, {emp.prenom_employe}</div>
                              </span>
                              )}
                      </div>
                      :<span></span>}
                      </div>
                      <div className={styles.Prenomlist}>
                      {employesListResult && inputNomPrenomRecherche && etatNomPrenomRecherche ? 
              
                        <div id={styles.cardRechercheMatriculePopUp}>
                            {employesListResult.map(emp => 
                              <span>
                                <div onClick={ () => setMatriculeTargetDeclaration(emp.matricule_employe)} id={styles.rechercheBtn}>{emp.matricule_employe} : {emp.nom_employe}, {emp.prenom_employe}</div>
                              </span>
                              )}
                        </div>
                        :<span></span>}
                      </div>
                      <div className={styles.Nomlist}>
                      {employesListResult && inputNomRecherche && etatNomRecherche ? 
              
                        <div id={styles.cardRechercheMatriculePopUp}>
                            {employesListResult.map(emp => 
                              <span>
                                <div onClick={ () => setMatriculeTargetDeclaration(emp.matricule_employe)} id={styles.rechercheBtn}>{emp.matricule_employe} : {emp.nom_employe}, {emp.prenom_employe}</div>
                              </span>
                              )}
                        </div>
                        :<span></span>}
                      </div>
                      <div>
                      </div>
                </div>
                {/* *********************CardRechercheMobile****************** */}
                <div className={styles.containerMobileData}>
                  <div className={styles.groupDataMatricule}>
                  {
                  (employesListResult && inputMatriculeRecherche && etatMatriculeRecherche) || (employesListResult && inputNomPrenomRecherche && etatNomPrenomRecherche) || (employesListResult && inputNomRecherche && etatNomRecherche)
                  
                  ? 
                        <div id={styles.cardRechercheMatriculePopUp}>
                            {employesListResult.map(emp => 
                                <span>
                                <div onClick={ () => setMatriculeTargetDeclaration(emp.matricule_employe)}  id={styles.rechercheBtn}>{emp.matricule_employe} : {emp.nom_employe}, {emp.prenom_employe}</div>
                                </span>
                                )}

                        </div>
                        :<span></span>}
                  </div>
                 <div>
                 </div>
                </div>
              </div>
            </div>
          </div> 
        <div className={styles.thirdPart}>
          <div className={styles.forms}>
            {/* *******1st form***** */}
            <div className={styles.firstform}>
              <div className={styles.divide}>
                <div className={styles.first}>
                Tâche
                </div>
                <div className={styles.second}>
                  <div className={styles.formGroup}>
                    <div className={styles.container1}>
                      <div className={styles.zoneInput}>
                        <div className={styles.fileNameTxtStyle1}>
                          Salarié Ciblé
                        </div>
                                <div className={styles.goldenInput1}>
                          <span id={styles.inputNombreheure}>
                            {employesList && matriculeTargetDeclaration ? (
                              employesList
                                .filter(resp => resp.matricule_employe === matriculeTargetDeclaration)
                                .map(emp => (
                                  <span id={styles.textesalarie}>
                                    {emp.matricule_employe} - {emp.nom_employe} {emp.prenom_employe}
                                  </span>
                                ))
                            ): (
                              <span> --- </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {isButtonClicked && (!matriculeTargetDeclaration || matriculeTargetDeclaration === '') && (
                      <div className={styles.container22}>
                      <div className={styles.zoneInput}>
                              <span ></span>
                              <span className={styles.textdecoration1}> Le salarié est obligatoire ! </span>
                          </div>
                          </div>
            )}
                    <div className={styles.container1}>
                      <div className={styles.zoneInput}>
                        <div className={styles.fileNameTxtStyle1}>
                          Statut d'activité
                        </div>
                        <select onChange={e => setInputTache(e.target.value)} className={styles.goldenInput1}>
                          {/* <option></option>
                          <option value="Jour férié Travaillé">Jour férié Travaillé</option>
                          <option value="Jour de repos 1 travaillé">Jour de repos 1 travaillé</option>
                          <option value="Jour de repos 2 travaillé">Jour de repos 2 travaillé</option>
                          <option value="Heure supplémentaire">Heure supplémentaire</option> */}
                           {listStatutActiviteAdministration &&
                              listStatutActiviteAdministration.map(
                                statutActiviteAdministration => <option value={statutActiviteAdministration.id}>{statutActiviteAdministration.nom}</option>
                              )
                              }
                        </select>
                      </div>
                    </div>
                    {isButtonClicked && !inputTache && (
                <div className={styles.container22}>
                <div className={styles.zoneInput}>
                        <span ></span>
                        <span className={styles.textdecoration1}>Le statut est obligatoire ! </span>
                    </div>
                    </div>
                )}
                                <div className={styles.modern_container}>
                        <div className={styles.modern_inputs}>
                          <span className={styles.modern_label}>
                            Horaire
                          </span>
                          <div class={styles.modern_input_wrapper}>
                            <input type="time" className={styles.modern_input_left} id={styles.inputHeureDebut} onChange={e => setInputHeureDebut(e.target.value)} />
                            <span className={styles.timeSeparator}>-</span>
                            <input type="time" className={styles.modern_input_right} id={styles.inputHeureFin} onChange={e => setInputHeureFin(e.target.value)}/>
                          </div>
                        </div>
                      </div>
                      {isButtonClicked && (!inputHeureDebut || !inputHeureFin) && (
                  <div className={styles.container22}>
                  <div className={styles.zoneInput}>
                          <span ></span>
                          <span className={styles.textdecoration1}>L'horaire est obligatoire ! </span>
                      </div>
                      </div>
                )}
                    <div className={styles.container1}>
                      <div className={styles.zoneInput}>
                        <div className={styles.fileNameTxtStyle1}>
                          Nombre d'heure
                        </div>
                        <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1}  value={inputHeureCalculated} disabled/>
                      </div>
                    </div>
                    <div  style={{width:"50%", margin:"auto"}}>
                      <div>
                      <button onClick={btnValider} className={styles.BtnValider}>Valider</button>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            {/* *******2nd form***** */}
            <div className={styles.secondform}>
              <div className={styles.divide2}>
                <div className={styles.first2}>
                Statut d'activité
                </div>
                <div className={styles.second2}>
                    {inputDate && listDayDate ? 
                  <div className={styles.ajouter}>
                    <div className={styles.centeredContent}>
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Lundi
                          </div>
                          <input id={inputStyleLundi} type="text" className={styles.goldenInput_}  value={listDayDate["Lundi"]} disabled/>
                        </div>
                      </div>
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Mardi
                          </div>
                          <input type="text" id={inputStyleMardi} className={styles.goldenInput_}  value={listDayDate["Mardi"]} disabled/>
                        </div>
                      </div> 
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Mercredi
                          </div>
                          <input type="text" id={inputStyleMercredi} className={styles.goldenInput_}  value={listDayDate["Mercredi"]} disabled/>
                        </div>
                      </div> 
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Jeudi
                          </div>
                          <input type="text" id={inputStyleJeudi} className={styles.goldenInput_}  value={listDayDate["Jeudi"]} disabled/>
                        </div>
                      </div> 
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Vendredi
                          </div>
                          <input type="text" id={inputStyleVendredi} className={styles.goldenInput_}  value={listDayDate["Vendredi"]} disabled/>
                        </div>
                      </div>
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Samedi
                          </div>
                          <input type="text" id={inputStyleSamedi} className={styles.goldenInput_}  value={listDayDate["Samedi"]} disabled/>
                        </div>
                      </div>
                    </div>
                  </div> 
                 : <span></span>}
                </div>
              </div>
            </div>
        </div>
        </div>
        {/* *************Mobile******* */}
         <div className={styles.thirdPartMobile}>
              <div className={styles.mobile}>
                <div className={styles.formMobile}>
                    <div className={styles.firstFormMobile}>
                    Tâche
                    </div>
                    <div className={styles.secondFormMobile}>
                    <div className={styles.formGroup}>
                        <div className={styles.container1}>
                          <div className={styles.zoneInput}>
                            <div className={styles.fileNameTxtStyle1}>
                              Salarié Ciblé
                            </div>
                                    <div className={styles.goldenInput1}>
                              <span id={styles.inputNombreheure}>
                                {employesList && matriculeTargetDeclaration ? (
                                  employesList
                                    .filter(resp => resp.matricule_employe === matriculeTargetDeclaration)
                                    .map(emp => (
                                      <span>
                                        {emp.matricule_employe} - {emp.nom_employe} {emp.prenom_employe}
                                      </span>
                                    ))
                                ): (
                                  <span> --- </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {isButtonClicked && (!matriculeTargetDeclaration || matriculeTargetDeclaration === '') && (
                          <div className={styles.container22}>
                          <div className={styles.zoneInput}>
                                  <span ></span>
                                  <span className={styles.textdecoration1}> Le salarié est obligatoire ! </span>
                              </div>
                              </div>
                          )}
                        <div className={styles.container1}>
                          <div className={styles.zoneInput}>
                            <div className={styles.fileNameTxtStyle1}>
                              Statut d'activité
                            </div>
                            <select onChange={e => setInputTache(e.target.value)} className={styles.goldenInput1}>
                              <option></option>
                              {/* <option value="Jour férié Travaillé">Jour férié Travaillé</option>
                              <option value="Jour de repos 1 travaillé">Jour de repos 1 travaillé</option>
                              <option value="Jour de repos 2 travaillé">Jour de repos 2 travaillé</option>
                              <option value="Heure supplémentaire">Heure supplémentaire</option> */}
                              {listStatutActiviteAdministration &&
                              listStatutActiviteAdministration.map(
                                statutActiviteAdministration => <option value={statutActiviteAdministration.id}>{statutActiviteAdministration.nom}</option>
                              )
                              }
                            </select>
                          </div>
                        </div>
                        {isButtonClicked && !inputTache && (
                    <div className={styles.container22}>
                    <div className={styles.zoneInput}>
                            <span ></span>
                            <span className={styles.textdecoration1}>Le statut est obligatoire ! </span>
                        </div>
                        </div>
                    )}
                                    <div className={styles.modern_container}>
                            <div className={styles.modern_inputs}>
                              <span className={styles.modern_label}>
                                Horaire
                              </span>
                              <div class={styles.modern_input_wrapper}>
                                <input type="time" className={styles.modern_input_left} id={styles.inputHeureDebut} onChange={e => setInputHeureDebut(e.target.value)} />
                                <span className={styles.timeSeparator}>-</span>
                                <input type="time" className={styles.modern_input_right} id={styles.inputHeureFin} onChange={e => setInputHeureFin(e.target.value)}/>
                              </div>
                            </div>
                          </div>
                          {isButtonClicked && (!inputHeureDebut || !inputHeureFin) && (
                      <div className={styles.container22}>
                      <div className={styles.zoneInput}>
                              <span ></span>
                              <span className={styles.textdecoration1}>L'horaire est obligatoire ! </span>
                          </div>
                          </div>
                    )}
                        <div className={styles.container1}>
                          <div className={styles.zoneInput}>
                            <div className={styles.fileNameTxtStyle1}>
                              Nombre d'heure
                            </div>
                            <input type="text" id={styles.inputNombreheure} className={styles.goldenInput1}  value={inputHeureCalculated} disabled/>
                          </div>
                        </div>
                          <div style={{width:"90%", margin:"auto"}}> 
                          <button onClick={btnValider} className={styles.BtnValider}>Valider</button>
                          </div>
                      </div>
                    </div>
                    <div className={styles.thirdFormMobile}>
                    Statut d'activité
                    </div>
                    <div className={styles.fourthFormMobile}>
                    {inputDate && listDayDate ? 
                  <div className={styles.ajouter}>
                    <div className={styles.centeredContent}>
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Lundi
                          </div>
                          <input id={inputStyleLundi} type="text" className={styles.goldenInput_}  value={listDayDate["Lundi"]} disabled/>
                        </div>
                      </div>
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Mardi
                          </div>
                          <input type="text" id={inputStyleMardi} className={styles.goldenInput_}  value={listDayDate["Mardi"]} disabled/>
                        </div>
                      </div> 
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Mercredi
                          </div>
                          <input type="text" id={inputStyleMercredi} className={styles.goldenInput_}  value={listDayDate["Mercredi"]} disabled/>
                        </div>
                      </div> 
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Jeudi
                          </div>
                          <input type="text" id={inputStyleJeudi} className={styles.goldenInput_}  value={listDayDate["Jeudi"]} disabled/>
                        </div>
                      </div> 
                      <div className={styles.container_}>
                        <div className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Vendredi
                          </div>
                          <input type="text" id={inputStyleVendredi} className={styles.goldenInput_}  value={listDayDate["Vendredi"]} disabled/>
                        </div>
                      </div>
                      <div  className={styles.container_}>
                        <div id={styles.marginofDays} className={styles.zoneInput_}>
                          <div className={styles.fileNameTxtStyle_}>
                          Samedi
                          </div>
                          <input type="text" id={inputStyleSamedi} className={styles.goldenInput_}  value={listDayDate["Samedi"]} disabled/>
                        </div>
                      </div>
                    </div>
                  </div> 
                 : <span></span>}
                    </div>
                </div>
              </div>
        </div>
      </div>
      </div>
  )
}
export default DeclarationSection