import React from 'react';
import {useCookies} from 'react-cookie';
import {useState, useEffect} from 'react';
import APIService from './../../../../APIService.js';
import styles from './SalarieNavBar.module.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Women from "./../../../img/women.jpg";
import { FaBell } from "react-icons/fa";
import Salary from "./../../../img/salary.png";
import Embauche from "./../../../img/embauche.png";
import Gargent from "./../../../img/gestion-de-largent.png";
import Gtemps from "./../../../img/gestionnaire-de-temps.png";
import Gprojet from "./../../../img/gestion-de-projet.png";





// import "./admin.css";
import moment from 'moment';
import 'moment/locale/fr' 

moment.locale('fr')

function SalarieNavBar() {
    const contentStyle = { textAlign : "center" , fontSize: "1vw", color: "black"};

    const [employe, setEmploye] = useState([])
    const [token, setToken, removeToken] = useCookies(['mytoken', 'username', 'matriculeManager', 'matriculeEmployeAttente', 'matriculeActuel'])
    const [users, setUsers] = useState([])
    const [afficher, SetAfficher] = useState(false)

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          window.location.href = '/'
          localStorage.clear()
  
      }
    }, [token])
    
    
    useEffect(() => {
        APIService.getUsers(token['mytoken'])
      .then(resp => setUsers(resp))
    },token['mytoken'])


    useEffect(() => {

        
        if(users.length){
          SetAfficher(true)
        }
          
        
    }, [users])


   
  
    const LogoutBtn = () => {
      removeToken(['mytoken'])
      localStorage.clear()
  
    }
  

    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
      }
      
      /* Set the width of the side navigation to 0 */
    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }

    const [etatMenu, setEtatMenu] = useState(false)


    const btnMenu = () => {
    if(etatMenu === true){

        setEtatMenu(false)

    }else{
        setEtatMenu(true)

    }
    }
    

    const [etatOpen, setEtatOpen] = useState(false)

  const openSideNavBar = () => {

    if(!etatOpen){
      document.getElementById(styles.idSideNav).style.width = "9vw";
      document.getElementById(styles.idSideNav).style.transition = ".5s";
      document.getElementById(styles.idTxtSideBarEmbauche).style.display = "block";
      document.getElementById(styles.idTxtSideBarSalarie).style.display = "block";
      document.getElementById(styles.idTxtSideBarGestionTemps).style.display = "block";
      document.getElementById(styles.idTxtSideBarPaie).style.display = "block";
      document.getElementById(styles.idTxtSideBarGestionTaches).style.display = "block";




      setEtatOpen(true)
    }else{

      document.getElementById(styles.idSideNav).style.width = "4vw";
      document.getElementById(styles.idSideNav).style.transition = ".5s";
      document.getElementById(styles.idTxtSideBarEmbauche).style.display = "none";
      document.getElementById(styles.idTxtSideBarSalarie).style.display = "none";
      document.getElementById(styles.idTxtSideBarGestionTemps).style.display = "none";
      document.getElementById(styles.idTxtSideBarPaie).style.display = "none";
      document.getElementById(styles.idTxtSideBarGestionTaches).style.display = "none";





      setEtatOpen(false)

    }
    

  }

  // Affichage des notifications 
  const [allEmployes, setAllEmployes] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [employeCible, setEmployeCible] = useState([])

    useEffect(() => {
      if(!token['mytoken'] || token['mytoken']==='undefined'){
          // navigate('/')
          window.location.href = '/'
          localStorage.clear()
      }
    }, [token])




    useEffect(() => {
      APIService.getUsers(token['mytoken'])
    .then(resp => setAllUsers(resp))
    },token['mytoken'])

    useEffect(() => {

      APIService.getEmploye(APIService.getEmployeId(allUsers, token), token)
      .then(resp => setEmployeCible(resp))

    },[allUsers])

  const [notificationCount, setNotificationCount] = useState(0)
  const [notifications, setNotifications] = useState([])
  const [allNotificationsBar, setAllNotificationsBar] = useState([])
  const [etatNotification, setEtatNotification] = useState(false)


  useEffect(() => {

    APIService.getAllNotifications(token)
    .then(resp => setAllNotificationsBar(resp))


  },[etatNotification, token])


  const btnNotification = () => {
    if(etatNotification){

      setEtatNotification(false)


    }else{
      allNotificationsBar.map(resp => APIService.ModifierNotification(resp.id, {username:resp.username,matricule_employe:resp.matricule_employe,notification:resp.notification,dateNotification:resp.dateNotification,statutNotification:true}, token))
      setEtatNotification(true)
    }
  }


  const btnNotificationEvent = (e) => {

    allNotificationsBar.filter(nots => nots.id === parseInt(e.target.name)
      ).map(
      
      resp => APIService.ModifierNotification(resp.id, {username:resp.username,matricule_employe:resp.matricule_employe,notification:resp.notification,dateNotification:resp.dateNotification,statutNotification:resp.statutNotification, estVu:true}, token)
    )

    setToken('matriculeEmployeAttente', e.target.value)
    window.location.href = '/saisie'

  }

  if(afficher){

    return (
        <div>
            <div className="col" id={styles.idSideNav}>
    
    <nav>
        <a href="/accueil">
          <div align="center" className={styles.classHomeLogo}> 
            <i className={`fas fa-home-alt nav-logo-icon`} title="Accueil"></i>
          </div>
        </a>
      <table align="center" className={styles.tableSidebar}>
        
        
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <a href="/embauche">
                <img className={styles.logoSideBarAll} src={Embauche} title="Embauche"></img>
                <h3 id={styles.idTxtSideBarEmbauche}>Embauche</h3>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <a href="/salarie">
                <img className={styles.logoSideBarAll} src={Salary} title="Salarié"></img>
                <h3 id={styles.idTxtSideBarSalarie}>Salarié</h3>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <a href="/dashboard">
                <img className={styles.logoSideBarAll} src={Gtemps} title="Gestion de temps"></img>
                <h3 id={styles.idTxtSideBarGestionTemps}>Gestion de temps</h3>
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <img className={styles.logoSideBarAll} src={Gargent} title="Paie"></img>
              <h3 id={styles.idTxtSideBarPaie}>Paie</h3>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.classLinkSideBar}>
              <img className={styles.logoSideBarAll} src={Gprojet} title="Calendrier et gestion des tâches"></img>
              <h3 id={styles.idTxtSideBarGestionTaches}>Calendrier et tâches</h3>
            </div>
          </td>
        </tr>
        <tr>
          <td>
          </td>
        </tr>
        <tr>
          <td>
            <button className={styles.classbtnLogOutSideBar} onClick={LogoutBtn}>
            <div align="center" className={styles.classLogoutLogo}> 
                <i className={`fas fa-sign-out nav-link-icon`} title="Se déconnecter"></i>
            
    
            </div>
            </button>
          </td>
        </tr>
      </table>
    
      
       
    </nav>
    
    </div>
    <div className="col">
                <div className="row">
                  <nav id={styles.idNav}>
                    
                  <table className={styles.tableNotifProfile} align="right">
                    
                      <tr>
                        <td id={styles.idIconBarControl}>
                          <button id={styles.idBtnIconBar} onClick={openSideNavBar}>
                            <i className="fas fa-bars" id={styles.idIconBar}></i>
                          </button>
                        </td>
                        <td className={styles.menuNav}>
                          
                          <Popup  trigger=
                                  {<button className={styles.btnChoix2}><span className={styles.textBtnChoix}>Administration du personnel</span></button>}
                                  {...{contentStyle}}
                                  position="bottom"
                                  >
                                  <div>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Embauche</span></button>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Salarié</span></button>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Gestion de temps</span></button>
                                  </div>
                          </Popup>
                          <Popup  trigger=
                                  {<button className={styles.btnChoix2}><span className={styles.textBtnChoix}>Planification</span></button>}
                                  {...{contentStyle}}
                                  position="bottom"
                                  >
                                  <div>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Embauche</span></button>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Salarié</span></button>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Gestion de temps</span></button>
                                  </div>
                          </Popup>
                          <Popup  trigger=
                                  {<button className={styles.btnChoix2}><span className={styles.textBtnChoix}>Gestion des talents</span></button>}
                                  {...{contentStyle}}
                                  position="bottom"
                                  >
                                  <div>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Embauche</span></button>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Salarié</span></button>
                                    <button className={styles.btnPrincipale}><span className={styles.textBtnChoix}>Gestion de temps</span></button>
                                  </div>
                          </Popup>
                        </td>
                        <td className={styles.widthTD}>
                        <Popup  trigger=
                                  {
                                    <button className={styles.tdNotifIcon}>
                                        <FaBell className={styles.iconProfileStyle} />
                                        <sup id={styles.supStyle}>{allNotificationsBar.filter(nots => nots.username === token['username']).length}</sup>
                                    </button>
                                  }
                                  {...{ textAlign : "center" , fontSize: "1vw", color: "black", width: "6vw"}}
                                  position="bottom"
                                  >
                                  <div className={styles.containerOfNotifications}>
                                  
    
                                      
    
                                      {allNotificationsBar.filter(nots => nots.username === token['username']).sort((a, b) => a.dateNotification > b.dateNotification ? -1:1).map(resp => <>
                                      {resp.estVu === false ? 
    
                                      <button className={styles.btnPrincipaleNonVue} 
                                      
                                      name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                      
                                      >
                                        <span className={styles.textBtnNotif}>
                                          <br />
                                            {resp.notification}
                                          
                                          <hr/>
                                          {resp.dateNotification ? <>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</>
                                          : <>XX/XX/XXXX xx:xx:xx</>}
    
    
                                        </span>
                                      </button>
                                      :
                                      <button className={styles.btnPrincipaleVue} name={resp.id} value={resp.matricule_employe} onClick={btnNotificationEvent} 
                                      
                                      >
                                        <span className={styles.textBtnNotif}>
                                          <br />
                                        {resp.notification}
                                      <hr/>
                                      {resp.dateNotification ? <span>{moment(resp.dateNotification).format("DD/MM/YYYY HH:mm:ss")}</span>
                                      : <span>XX/XX/XXXX xx:xx:xx</span>}
                                      </span>
                                      </button> }
                                      <br />
                                      </>)}
                                   
                                  </div>
                          </Popup>
                          
                        </td>
                        <td className={styles.widthTD}>
                          <Popup  trigger=
                                  {
                                    <button className={styles.btnProfile} onClick={btnMenu}>
                                      <img src={Women} alt="" />
                                    </button>
                                  }
                                  {...{ textAlign : "center" , fontSize: "1vw", color: "black", width: "2vw !important"}}
                                  position="bottom"
                                  >
                                  <div>
                                    <div id={styles.txtProfile}>
                                      {employeCible.prenom_employe}, {employeCible.nom_employe} 
                                      <hr />
                                      Etablissement : {employeCible.etablissement_employe} 
                                      <hr />
                                      <button className={styles.btnPrincipale} onClick={LogoutBtn}>Se déconnecter</button>
                                      
                                    </div>
                                  </div>
                          </Popup>
                        </td>
                      </tr>
                    </table>
                 
                  
    
    
                    
    
    
                  
    
                  </nav>
    
                </div></div>
        </div>
      )
  }
 
}

export default SalarieNavBar