import React from 'react';
import styles from './Delete.module.css'

function Delete(props) {
  return (
    <button id={styles.deleteButton} onClick={() => props.deleteData()}>
      <i class="fa-solid fa-trash-can"></i>
    </button>
  )
}

export default Delete