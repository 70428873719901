import react from "react";
// import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import PrimesSection from "./PrimesSection";
import NavBarGlobal from "../../Global/NavBar/NavBarGlobal/NavBarGlobal";

function Primes(){

    return(
        <div>
            <NavBarGlobal  section = {<PrimesSection />}/>
        </div>
    )
}

export default Primes;