import react, {useEffect ,useRef, useState} from "react";
import styles from "./RemunerationSection.module.css";
import {useCookies} from 'react-cookie';
import APIService from "./../../../APIService";
import moment from 'moment';
import NavBarGlobal from "../../GlobalComponents/NavBar/NavBarGlobal/NavBarGlobal";
import TopMenuPaie from "../TopMenuPaie";
import { useNavigate } from "react-router-dom";
import LineFilterTableInput from "../../GlobalComponents/lineFilterTableInput/LineFilterTableInput";
import PaginationTable from "../../GlobalComponents/paginationTable/PaginationTable";
import GeneralCardTest from "../../GlobalComponents/generalCard/GeneralCardTest.jsx";
import FourthSectionTablePage from "../../GlobalComponents/fourthSectionTablePage/FourthSectionTablePage.jsx"
import CheckboxTableInput from "../../GlobalComponents/checkboxTableInput/CheckboxTableInput.jsx";
import SelectNbreLigneTableInput from "../../GlobalComponents/selectNbreLigneTableInput/SelectNbreLigneTableInput.jsx";
import SecondSectionTablePage from "../../GlobalComponents/secondSectionTablePage/SecondSectionTablePage.jsx"
import TableComponent from "../../GlobalComponents/tableComponenet/TableComponent.jsx";
import FourthSectionTablePageTablet from "../../GlobalComponents/fourthSectionTablePageTablet/FourthSectionTablePageTablet";
import FourthSectionTablePageMobile from "../../GlobalComponents/fourthSectionTablePageMobile/FourthSectionTablePageMobile";
import FourthSectionTablePageMobileInversePetit from "../../GlobalComponents/fourthSectionTablePageMobileInversePetit/FourthSectionTablePageMobileInversePetit";
import FourthSectionTablePageMobileInverseGrand from "../../GlobalComponents/fourthSectionTablePageMobileInverseGrand/FourthSectionTablePageMobileInverseGrand";
import SecondSectionTablePageTablet from "../../GlobalComponents/secondSectionTablePageTablet/SecondSectionTablePageTablet";
import SecondSectionTablePageMobile from "../../GlobalComponents/secondSectionTablePageMobile/SecondSectionTablePageMobile";
import SecondSectionTablePageMobileInversePetit from "../../GlobalComponents/secondSectionTablePageMobileInversePetit/SecondSectionTableMobilePageInversePetit";
import SecondSectionTablePageMobileInverseGrand from "../../GlobalComponents/secondSectionTablePageMobileInverseGrand/SecondSectionTablePageMobileInverseGrand";
import ListColumns from "../../GlobalComponents/listColumns/ListColumns.jsx";
import DescriptionPaieComponent from "../../GlobalComponents/descriptionPaieComponent/DescriptionPaieComponent.jsx";
import PopupComponentGstTemps from "../../GlobalComponents/popupComponentGstTemps/PopupComponentGstTemps.jsx";
import ButtonValider from "../../GlobalComponents/buttonValider/ButtonValider.jsx";
function RemunerationSection(){
    const today = new Date("2999-12-31").toISOString().slice(0, 10);
    const [token, setToken, removeToken] = useCookies(['mytoken', 'matriculePaie']);
    const [matriculePaie, setMatriculePaie] = useState();
    const [listRemuneration, setListRemuneration] = useState();
    const [listRemunerationEtat, setListRemunerationEtat] = useState([]);
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState(today);
    const [salaireBase, setSalaireBase] = useState();
    const [dateDebutDelimiter, setDateDebutDelimiter] = useState();
    const [dateFinDelimiter, setDateFinDelimiter] = useState();
    const [salaireBaseDelimiter, setSalaireBaseDelimiter] = useState();
    const [idModifier, setIdModifier] = useState();
    const [idDelimiter, setIdDelimiter] = useState();
    const [etatAjouter, setEtatAjouter] = useState(false)
    const [idSupprimerAlert, setIdSupprimerAlert] = useState();
    const [etatSupprimer, setEtatSupprimer] = useState();
    const [isIconUp, setIsIconUp] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if(token['matriculePaie']){
            setMatriculePaie(token['matriculePaie'])
        }
    }, [token['matriculePaie']])
    
    useEffect(() => {
      if(matriculePaie){
        console.log(dateFin)
        console.log("Matricule paie : ", matriculePaie);
        APIService.getRemunerationPaie(matriculePaie, token).then(
            resp => setListRemuneration(resp));
      }
    }, [matriculePaie, listRemunerationEtat])

    useEffect(() => {

        if(JSON.stringify(listRemunerationEtat) !== JSON.stringify(listRemuneration)){
            setListRemunerationEtat(listRemuneration)
        }
    }, [listRemuneration])
    


    const btnEnregistrer = () => {
        let body = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            salaireBase : 0
        }

        let bodyDelimiter = {
            matricule_employe : "",
            matricule_manager : "", 
            dateDebut : "",
            dateFin : "",
            salaireBase : 0
        }

        if(dateDebut && (dateFin || today) && salaireBase){
            body.matricule_employe = matriculePaie;
            body.matricule_manager = matriculePaie;
            body.dateDebut = dateDebut;
            if(dateFin) {
                body.dateFin = dateFin
            } else {
                body.dateFin = today
            }
            body.salaireBase = salaireBase;

            if(idModifier){

                APIService.putRemunerationPaie(idModifier, body, token);
                setIdModifier();
                setShow(false)
                setDateDebut("")
                setSalaireBase("")
                setDateFin("")
            } else {
                if(idDelimiter){

                    bodyDelimiter.matricule_employe = matriculePaie;
                    bodyDelimiter.matricule_manager = matriculePaie;
                    bodyDelimiter.dateDebut = dateDebutDelimiter;
                    bodyDelimiter.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                    bodyDelimiter.salaireBase = salaireBaseDelimiter;
                   

                    APIService.putRemunerationPaie(idDelimiter, bodyDelimiter, token);
                    APIService.postRemunerationPaie(body, token);

                    setIdDelimiter();
                    setShow(false)
                    setDateDebut("")
                    setSalaireBase("")
                setDateFin("")


                } else {
                    listRemuneration.map(
                        resp => {
                            let bodyTemp = {
                                matricule_employe : "",
                                matricule_manager : "", 
                                dateDebut : "",
                                dateFin : "",
                                salaireBase : 0
                            }
                setDateDebut("")
                setSalaireBase("")



                            if(new Date(resp.dateFin) > new Date(dateDebut)){
                                bodyTemp.matricule_employe = resp.matricule_employe;
                                bodyTemp.matricule_manager = resp.matricule_manager;
                                bodyTemp.dateDebut = resp.dateDebut;
                                bodyTemp.dateFin = new Date(new Date(dateDebut).setDate(new Date(dateDebut).getDate() - 1)).toISOString().split('T')[0];
                                bodyTemp.salaireBase = resp.salaireBase;

                                APIService.putRemunerationPaie(resp.id, bodyTemp, token);

                            }
                        }
                    )
                    APIService.postRemunerationPaie(body, token);

                }
            }

            APIService.getRemunerationPaie(matriculePaie, token).then(
                resp => {setListRemuneration(resp); setListRemunerationEtat(resp);});



            
        }
    }
    function btnSupprimer(idRem) {
        APIService.deleteRemunerationPaie(idRem, token);
        APIService.getRemunerationPaie(matriculePaie, token).then(resp => {setListRemuneration(resp); setListRemunerationEtat(resp);});
        setIdModifier();
        setIdDelimiter(); 
    }

    const btnModifier = (e) => {

        if(!etatAjouter){
            setEtatAjouter(true);
        }

        listRemuneration.filter(
            resp => resp.id === parseInt(e.target.value)

        ).map(resp => {
            
            setIdModifier(resp.id);
            setDateDebut(resp.dateDebut);
            setDateFin(resp.dateFin);
            setSalaireBase(resp.salaireBase);
            setIdDelimiter();
        })
    }
    const btnDelimiter = (e) => {
        if(!etatAjouter) {
            setEtatAjouter(true);
        }
        listRemuneration.filter(
            resp => resp.id === parseInt(e.target.value)
        ).map(resp => {
            setIdDelimiter(resp.id);
            setDateDebutDelimiter(resp.dateDebut);
            setDateFinDelimiter(resp.dateFin);
            setSalaireBaseDelimiter(resp.salaireBase);
            setDateDebut("");
            setDateFin(resp.dateFin);
            setSalaireBase(resp.salaireBase);
            setIdModifier();
        })
    }
// re *************
    const [show, setShow] = useState(false)

    const btnAjouter = () => {
        if(etatAjouter){
            setEtatAjouter(prevState => !prevState);
            setIsIconUp(prevState => !prevState);
            setShow(prevState => !prevState);
        } else {
            setDateDebut("")
            setDateFin("")
            setSalaireBase("")
            setEtatAjouter(prevState => !prevState);
            setIsIconUp(prevState => !prevState);
            setShow(prevState => !prevState);
        }
        setIdModifier();
        setIdDelimiter();
        setDateDebut("")
        setDateFin("")
        setSalaireBase("")
    }

    const handleDeleteClick = (id) => {
      setIdSupprimerAlert(id);
    }
    
    const handleCancelClick = () => {
      setIdSupprimerAlert(null);
      setClickedRow(null);
    }
    
    const handleConfirmClick = () => {
      if (idSupprimerAlert !== null) {
        btnSupprimer(idSupprimerAlert);
        setIdSupprimerAlert(null);
      }
    }
    const [clickedRow, setClickedRow] = useState(null);
    const handleRowClick = (id) => {
        if (clickedRow === id) {
          setClickedRow(null);
          setIdSupprimerAlert(null);
        } else {
          setClickedRow(id);
          setIdSupprimerAlert(id);
        }
      }
// *****************************************************
const [listRemunerationResult, setListRemunerationResult] = useState(null)
const [valeurRechercheGlobale, setValeurRechercheGlobale] = useState()

useEffect(() => {

  if(matriculePaie){
    APIService.getRemunerationPaie(matriculePaie, token).then(
        resp => setListRemuneration(resp));
  }
}, [matriculePaie,listRemunerationEtat])

// useEffect(() => {
//   if(listRemuneration){
//   listRemuneration.map(resp =>{
//     resp.matricule_manager="ABC"

//       }
//   )
//   setListRemunerationResult(listRemuneration)

// }

// },[listRemuneration])




useEffect(() => {
  if(valeurRechercheGlobale && listRemuneration){
    setListRemunerationResult(
      listRemuneration.filter(resp =>  
         
            (Object.keys(headerTest).includes("dateFin") && resp.dateFin.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("dateDebut") && resp.dateDebut.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("matricule_employe") && resp.matricule_employe.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("matricule_manager") && resp.matricule_manager.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase())) ||
            (Object.keys(headerTest).includes("salaireBase") && resp.salaireBase.toString().toLowerCase().includes(valeurRechercheGlobale.toLowerCase()))

             )

            )
  }else{
    if(listRemuneration){
      setListRemunerationResult(listRemuneration)
    }
  }
}, [valeurRechercheGlobale,listRemuneration])

// Pagination

const [index, setIndex] = useState(0)
const [page, setPage] = useState(null)
const [pageTickets, setPageTickets] = useState([])
const [nombrePageArray_, setNombrePageArray_] = useState([])
const [nombrePageValeurFixed, setNombrePageValeurFixed] = useState(null)
const [afficherChecked, setAfficherChecked] = useState(false)


function transformNumberToArray(n) {
   const array = [];
   for (let i = 1; i <= n; i++) {
       array.push(i);
   }
   return array;
}


useEffect(() => {
 if(listRemunerationResult && parseInt(index) !== 0 && page !== 0 )

 {
   let indexInt = parseInt(index);

   setPageTickets(listRemunerationResult.slice(page*indexInt - indexInt, page*indexInt))
   setNombrePageArray_(transformNumberToArray(Math.ceil(listRemunerationResult.length/parseInt(index))))

 } else {

   if( listRemunerationResult && parseInt(index) === 0){
     setPage(1)
     setPageTickets(listRemunerationResult)
     setNombrePageArray_([])
   


   }

 }

}, [listRemunerationResult, index, page])




useEffect(() => {
 if(afficherChecked && listRemunerationResult){
   setNombrePageValeurFixed(0);
   setIndex(0);
 }
}, [afficherChecked, listRemunerationResult])

const handleChangeNombrePage = (e) => {
 setAfficherChecked(false)
 setNombrePageValeurFixed(parseInt(e.target.value))
 setIndex(e.target.value)
 setPage(1);
}

const [dataUsed, setDataUsed] = useState(null)

useEffect(() => {

 if(pageTickets){

   setDataUsed(pageTickets)
 }

}, [pageTickets])


// *********************************************************



// ****************************************************
const [showTest, setShowTest] = useState(false)
const [idTest, setIdTest] = useState()
const [errorMessage, setErrorMessage] = useState('');
// const [errorMessageDelimiter, setErrorMessageDelimiter] = useState('');

       const handleDateDebutChange = (e) => {
        const startDate = e.target.value;
        if (startDate > dateFin) {
            setErrorMessage("date debut doit etre inferieure a date fin ! ");
        } else {
            setErrorMessage("");
        }
        setDateDebut(startDate);
    };
  //   const handleDateDebutChangeDelimiter = (e) => {
  //     const startDate = e.target.value;
  //     if (startDate > dateFin) {
  //         setErrorMessageDelimiter("date debut doit etre inferieure a date fin ! ");
  //     } else {
  //         setErrorMessageDelimiter("");
  //     }
  //     setDateDebut(startDate);
  // };


      const deleteButton = (id) => {
        if(id && token){
          console.log("delete button",id)
          setShowTest(true); 
          setIdTest(id)
        }
      }
      const deleteButtonOui=()=>{
         APIService.deleteRemunerationPaie(idTest, token);
          APIService.getRemunerationPaie(matriculePaie, token).then(resp => {setListRemuneration(resp); setListRemunerationEtat(resp);});
          setIdModifier();
          setIdDelimiter(); 
          setShowTest(false)

      }
      const deleteButtonNon=()=>{
        setIdTest();
        setShowTest(false)

     }
      const modifyButton = (id) => {
        if(id){
          console.log("modify button",id)
          if(!etatAjouter){
            setEtatAjouter(true);
        }
        if(!show){
          setShow(true)
        }else{
          setShow(!show)
        }
          setErrorMessage("");
        const selectedResp = listRemuneration.find(resp => resp.id === parseInt(id));
         console.log("modify button", selectedResp)
        if (selectedResp) {
            setIdModifier(selectedResp.id);
            setDateDebut(selectedResp.dateDebut);
            setDateFin(selectedResp.dateFin);
            setSalaireBase(selectedResp.salaireBase);
            setIdDelimiter();

        }
        }
        
      }
      const hideButton = (id) => {
        if(id){
          console.log("hide button",id)
        }
      }
      const sendButton = (id) => {
        if(id){
          console.log("send button",id)
        }
      }
      const delimiterButton = (id) => {
        if(id){
          console.log("modify button",id)
          if(!etatAjouter){
            setEtatAjouter(true);
        }
        if(!show){
          setShow(true)
        }else{
          setShow(!show)
        }
        setErrorMessage("");
        const selectedResp = listRemuneration.find(resp => resp.id === parseInt(id));
         console.log("Delimited button", selectedResp)
        if (selectedResp) {
          setIdDelimiter(selectedResp.id);
            setDateDebutDelimiter(selectedResp.dateDebut);
            setDateFinDelimiter(selectedResp.dateFin);
            setSalaireBaseDelimiter(selectedResp.salaireBase);
            setDateDebut("");
            setDateFin(selectedResp.dateFin);
            setSalaireBase(selectedResp.salaireBase);
            setIdModifier();
        }
        }
      }



      const [headerTest, setHeaderTest] = useState({dateDebut:"Date de Début",dateFin:"Date de Fin"
      ,salaireBase:"Valeur"
    });
    //   const test={ dateDebut:"Date de Début",dateFin:"Date de Fin"
    //   ,salaireBase:"Valeur"
    // }
       console.log("test list remuneration",listRemuneration)
    
       const componentsList={ 1: "Send",2:"Delete",3:"Modify",4:"Hide",5:"Delete",6:"Delimiter"}
       const methodsList={ 1: sendButton,2:deleteButton,3:modifyButton,4:hideButton,5:deleteButton,6:delimiterButton}
       

    return(
        // <div className={styles.classCard}>
        //     <div className={styles.generalContainer}>
        //         <div className={styles.firstPart}>
        //             <TopMenuPaie page="remuneration"/>
        //         </div>
        //         <div className={styles.secondPart}>
        //                 <div className={styles.tableAndFormSection}>
        //                     <div className={styles.buttonAndFormSection}>
        //                         <div className={styles.buttonSection_}>
        //                             <button className={styles.btnAjouter} onClick = {btnAjouter}>
        //                                                     Ajouter une nouvelle ligne &nbsp; {' '}
        //                                                     {isIconUp ? (
        //                                                         <i className="fa-solid fa-caret-up"></i>
        //                                                     ) : (
        //                                                         <i className="fa-solid fa-caret-down"></i>
        //                                                     )}
        //                             </button>
        //                         </div>
        //                         <div className={styles.formSection}>
        //                             <br />
        //                             {idModifier ? 
        //                                 <div className={styles.modifiableTexte2}>
        //                                     <i class="fa-solid fa-pencil"></i> &nbsp;
        //                                     Modifier</div>

        //                                 :
        //                                 <>
        //                                 {
        //                                     idDelimiter ?
        //                                     <div className={styles.modifiableTexte2}>
        //                                         <i class="fa-solid fa-scissors"></i> &nbsp;
        //                                         Délimiter</div> :<></>
        //                                 }
                                        
        //                                 </>
        //                             }
                                    
        //                             {
        //                                 etatAjouter ? 
        //                                 <>
        //                                 <div className={styles.modern_container}>
        //                                     <div className={styles.modern_inputs}>
        //                                     <span className={styles.modern_label}>
        //                                     Période
        //                                     </span>
        //                                     <div class={styles.modern_input_wrapper}>
        //                                         <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
        //                                         <span className={styles.timeSeparator}>-</span>
        //                                         <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
        //                                     </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className={styles.container1}>
        //                                     <div className={styles.zoneInput}>
        //                                         <div className={styles.fileNameTxtStyle1}>
        //                                         Salaire de base
        //                                         </div>
        //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
        //                                     </div>
        //                                 </div>
        //                                 <div className={styles.btnSave}>
        //                                     <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
        //                                 </div>
                                         
        //                                  </>:<></>
        //                                 }
                                        

        //                         </div>

        //                     </div>
        //                         <div className={styles.tableSection}>
        //                             <div className={styles.tableZone} >
        //                             <table className={styles.classTable}>
        //                             <thead>
        //                                 <tr>
        //                                     <th>Date de début</th>
        //                                     <th>Date de fin</th>
        //                                     <th>Valeur</th>
        //                                     <th>Action</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 {
        //                                     listRemuneration ? 
                                        
        //                                     listRemuneration.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                            
        //                                         <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
        //                                             <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
        //                                             <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
        //                                             <td>{resp.salaireBase}</td>
        //                                             <td>
        //                                                 <button id={styles.trashButton} value={resp.id} onClick={ () => 
        //                                                     handleRowClick(resp.id)
        //                                                     }>
        //                                                         <i class="fa-solid fa-trash-can"></i>
        //                                                         </button>
        //                                                         {idSupprimerAlert === resp.id && (
        //                                                             <>
        //                                                             <div className={styles.overlay}></div>
        //                                                             <div className={styles.popup}>
        //                                                                 <div id={styles.supprimerTexte}>Supprimer</div>
        //                                                                 <br />
        //                                                                 <div className={styles.buttonsPopup}>
        //                                                                 <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
        //                                                                 <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
        //                                                                 </div>
        //                                                             </div>
        //                                                             </>
        //                                                             )}


        //                                                     {
        //                                                     moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
        //                                                     <>
        //                                                             &nbsp;
        //                                                             <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
        //                                                              <i class="fa-solid fa-pencil"></i>
        //                                                                 </button>


        //                                                             &nbsp;

        //                                                             <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
        //                                                             <i class="fa-solid fa-scissors"></i>
        //                                                                 </button>

        //                                                     </>:<></>
        //                                                     }
        //                                                 </td>
        //                                         </tr>
        //                                     )
                                        
                                            
        //                                     :<></>

        //                                 }
                                        
        //                             </tbody>
        //                             </table>
        //                             </div>
        //                         </div>
        //                 </div>
        //                 {/* **************** Mobile ******** */}
        //                 <div className={styles.tableAndFormSectionMobile}>
        //                     <div className={styles.tableSection}>
        //                             <div className={styles.tableZone} >
        //                             <table className={styles.classTable}>
        //                             <thead>
        //                                 <tr>
        //                                     <th>Date de début</th>
        //                                     <th>Date de fin</th>
        //                                     <th>Valeur</th>
        //                                     <th>Action</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 {
        //                                     listRemuneration ? 
                                        
        //                                     listRemuneration.sort((a, b) => a.dateDebut > b.dateDebut ? -1:1).map(resp => 
                                            
        //                                         <tr className={clickedRow === resp.id ? styles.clickedRow : ''} key={resp.id}>
        //                                             <td>{moment(resp.dateDebut).format("DD/MM/YYYY")}</td>
        //                                             <td>{moment(resp.dateFin).format("DD/MM/YYYY")}</td>
        //                                             <td>{resp.salaireBase}</td>
        //                                             <td>
        //                                                 <button id={styles.trashButton} value={resp.id} onClick={ () => 
        //                                                     handleRowClick(resp.id)
        //                                                     }>
        //                                                         <i class="fa-solid fa-trash-can"></i>
        //                                                         </button>
        //                                                         {idSupprimerAlert === resp.id && (
        //                                                             <>
        //                                                             <div className={styles.overlay}></div>
        //                                                             <div className={styles.popup}>
        //                                                                 <div id={styles.supprimerTexte}>Supprimer</div>
        //                                                                 <br />
        //                                                                 <div className={styles.buttonsPopup}>
        //                                                                 <button id={styles.buttonNon} onClick={handleCancelClick}>Non</button> 
        //                                                                 <button id={styles.buttonOui} onClick={handleConfirmClick}>Oui</button>
        //                                                                 </div>
        //                                                             </div>
        //                                                             </>
        //                                                             )}


        //                                                     {
        //                                                     moment(resp.dateFin).format("DD/MM/YYYY") === "31/12/2999" ?
        //                                                     <>
        //                                                             &nbsp;
        //                                                             <button id={styles.modifiableButton} value={resp.id} onClick={btnModifier}>
        //                                                              <i class="fa-solid fa-pencil"></i>
        //                                                                 </button>

                                                                
        //                                                             &nbsp;
                                                                
        //                                                             <button id={styles.delimitButton} value={resp.id} onClick={btnDelimiter}>
        //                                                             <i class="fa-solid fa-scissors"></i>
        //                                                                 </button>
                                                                    
        //                                                     </>:<></>
        //                                                     }
                                                        
                                                        


                                                        
        //                                                 </td>
        //                                         </tr>
        //                                     )
                                        
                                            
        //                                     :<></>

        //                                 }
                                        
        //                             </tbody>
        //                             </table>
        //                             </div>
        //                     </div>
        //                     <div className={styles.buttonAndFormSection}>
        //                         <div className={styles.buttonSection_}>
        //                             <button className={styles.btnAjouter} onClick = {btnAjouter}>
        //                                                     Ajouter une nouvelle ligne &nbsp; {' '}
        //                                                     {isIconUp ? (
        //                                                         <i className="fa-solid fa-caret-up"></i>
        //                                                     ) : (
        //                                                         <i className="fa-solid fa-caret-down"></i>
        //                                                     )}
        //                             </button>
        //                         </div>
        //                         <div className={styles.formSection}>
        //                             <br />
        //                             {idModifier ? 
        //                                 <div className={styles.modifiableTexte2}>
        //                                     <i class="fa-solid fa-pencil"></i> &nbsp;
        //                                     Modifier</div>

        //                                 :
        //                                 <>
        //                                 {
        //                                     idDelimiter ?
        //                                     <div className={styles.modifiableTexte2}>
        //                                         <i class="fa-solid fa-scissors"></i> &nbsp;
        //                                         Délimiter</div> :<></>
        //                                 }
                                        
        //                                 </>
        //                             }
                                    
        //                             {
        //                                 etatAjouter ? 
        //                                 <>
        //                                 <div className={styles.modern_container}>
        //                                     <div className={styles.modern_inputs}>
        //                                     <span className={styles.modern_label}>
        //                                     Période
        //                                     </span>
        //                                     <div class={styles.modern_input_wrapper}>
        //                                         <input type="date" className={styles.modern_input_left} value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} />
        //                                         <span className={styles.timeSeparator}>-</span>
        //                                         <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
        //                                     </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className={styles.container1}>
        //                                     <div className={styles.zoneInput}>
        //                                         <div className={styles.fileNameTxtStyle1}>
        //                                         Salaire de base
        //                                         </div>
        //                                         <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
        //                                     </div>
        //                                 </div>
        //                                 <div className={styles.btnSave}>
        //                                     <button className={styles.btnAjouter_} onClick={btnEnregistrer}>Enregistrer</button>
        //                                 </div>
                                         
        //                                  </>:<></>
        //                                 }
                                        

        //                         </div>

        //                     </div>
                                
        //                 </div>
        //         </div>
        //     </div>
        // </div>
        <div>
            <div className={styles.web} >

            <GeneralCardTest 
            section1={
            <TopMenuPaie page="remuneration"/>
            } 
            section2={
            <SecondSectionTablePage 
            nbreComponents={4}
            component1={<DescriptionPaieComponent/>
            } 
            component2={ <ListColumns listColumn={listRemunerationResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
            component3={<>
            <div className={styles.inputgroup}  >
                    <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
                        <div className={styles.zoneComponent}>
                            <div className={styles.text}>
                                <span className={styles.creeravenat_}>
                                Ajouter une nouvelle ligne 
                                </span> 
                            </div>
                            <div className={styles.iconeRight}>
                            {isIconUp ? (
                                <i className="fa-solid fa-caret-up"></i>
                                ) : (
                                <i className="fa-solid fa-caret-down"></i>
                                )}

                            </div>
                        </div>
                    </div>
            </div>
            <PopupComponentGstTemps onClose={() => {
                setShow(false);
                setIsIconUp(false);
                setIdModifier();
                setIdDelimiter();
            }} show={show} width="400px" height="250px"
            popUpHeader={
              idModifier ? (
                  <>
                      <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
                  </>
              ) : idDelimiter ? (
                  <>
                      <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
                  </>
              ) : (
                  <></>
              )
            }
                                                            
                                                            >
            <div className={
            ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

              }>
                                                    <div className={styles.modern_container}>
                                                        <div className={styles.modern_inputs}>
                                                        <span className={styles.modern_label}>
                                                        Période
                                                        </span>
                                                        <div class={styles.modern_input_wrapper}>
                                                            <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                            onChange={handleDateDebutChange}
                                                            // {(e) => setDateDebut(e.target.value)}
                                                            />
                                                            <span className={styles.timeSeparator}>-</span>
                                                            <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                                        </div>
                                                        </div>
                                                    </div>
                                                    {errorMessage && idModifier &&
                                                        <div className={styles.errorMessageStyle}>
                                                          {errorMessage}
                                                        </div>
                                                    }
                                                      {errorMessage && idDelimiter &&
                                                        <div className={styles.errorMessageStyle}>
                                                          {errorMessage}
                                                        </div>
                                                    }

                                                    <div className={styles.container1}>
                                                        <div className={styles.zoneInput}>
                                                            <div className={styles.fileNameTxtStyle1}>
                                                            Salaire de base
                                                            </div>
                                                            {
                                                              idDelimiter ?
                                                              <>
                                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)} disabled/>
                                                              </>:<>
                                                            <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
                                                              </>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <div className={styles.btnsubmit2}>
                                                        <button type='submit' id={(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? styles.disabledButton : styles.btnRecherche2} onClick={btnEnregistrer}>
                                                        <span className={styles.text2}>Enregistrer</span>
                                                        </button>         
                                                    </div> */}
                                                    <div  className={styles.divBtnSubmit}>
                                                          {(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                          <>
                                                          <div className={styles.sectionButtonEnregistrer}> 
                                                            <div></div>
                                                            <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                            </div>
                                                          </>
                                                          :<>
                                                          <div className={styles.sectionButtonEnregistrer}> 
                                                            <div></div>
                                                            <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                            </div>
                                                          </>
                                                        }
                                                    </div>
                                                    

                                            </div>
            </PopupComponentGstTemps>

            {showTest && (
                            <PopupComponentGstTemps
                                onClose={() => {setShowTest(false);setIdTest()}}
                                show={showTest}
                                width="400px"
                                height="250px"
                                popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                            >
                                <div className={styles.popUpDeleteContent}>
                                  <div className={styles.firstPartDelete}>
                                    <div className={styles.textDelete}>
                                    Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                                    </div>
                                  </div>
                                  <div className={styles.thirdPartDelete}>
                                  {/* <div className={styles.divNon}>
                                      <button className={styles.buttonNon} onClick= {() => deleteButtonNon()}>
                                        Annuler
                                      </button>

                                    </div> */}
                                    <ButtonValider textButton={"Annuler"}
                                    //  buttonColor={"rgb(174 172 172)"}
                                      buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                                    <div></div>
                                    {/* <div className={styles.divOui}>
                                      <button className={styles.buttonOui} onClick={() => deleteButtonOui()}>
                                        Oui, Supprimer
                                      </button>

                                    </div> */}
                                    <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                                  </div>
                                </div>



                          </PopupComponentGstTemps>
                        )}
            </>}  
            component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
            }
            />}
            section3={
            <>
            {
              ( dataUsed && headerTest) && 
                <TableComponent data={dataUsed} cols={headerTest} 
                addAction={true} 
                contentAction={methodsList}
                componentsList={componentsList}
                clickableRow={false}
                idDelete={idTest}
                idModify={idModifier}
                idDelimit={idDelimiter}
                //  condition={showTest}

            />  
            }
            </>    
            } 
            section4={
                      <FourthSectionTablePage 
                      component1={ 
                      <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
                      } 
                      component2={
                      <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
                    } 
                      component3={
                      <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
                    }
                      /> 
                    } 
            NbreSections={4} 
            prctgeSection1={8}
            prctgeSection2={6} 
            prctgeSection3={80}
            prctgeSection4={6}
            />
            </div>
<div className={styles.tablet}>
<GeneralCardTest 
section1={
<TopMenuPaie page="remuneration"/>
}  
section2={
  <SecondSectionTablePageTablet 
  nbreComponents={4}
  component1={<DescriptionPaieComponent/>
  } 
  component2={
     <ListColumns listColumn={listRemunerationResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
  component3={<>
  <div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="400px" height="250px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
  <div className={
  ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

    }>
                                          <div className={styles.modern_container}>
                                              <div className={styles.modern_inputs}>
                                              <span className={styles.modern_label}>
                                              Période
                                              </span>
                                              <div class={styles.modern_input_wrapper}>
                                                  <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                  onChange={handleDateDebutChange}
                                                  // {(e) => setDateDebut(e.target.value)}
                                                  />
                                                  <span className={styles.timeSeparator}>-</span>
                                                  <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                              </div>
                                              </div>
                                          </div>
                                          {errorMessage && idModifier &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }
                                            {errorMessage && idDelimiter &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }

                                          <div className={styles.container1}>
                                              <div className={styles.zoneInput}>
                                                  <div className={styles.fileNameTxtStyle1}>
                                                  Salaire de base
                                                  </div>
                                                  {
                                                    idDelimiter ?
                                                    <>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)} disabled/>
                                                    </>:<>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
                                                    </>
                                                  }
                                              </div>
                                          </div>
                                          {/* <div className={styles.btnsubmit2}>
                                              <button type='submit' id={(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? styles.disabledButton : styles.btnRecherche2} onClick={btnEnregistrer}>
                                              <span className={styles.text2}>Enregistrer</span>
                                              </button>         
                                          </div> */}
                                          <div  className={styles.divBtnSubmit}>
                                                {(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                <>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                                :<>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                              }
                                          </div>
                                          

                                  </div>
  </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="400px"
                      height="250px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                        {/* <div className={styles.divNon}>
                            <button className={styles.buttonNon} onClick= {() => deleteButtonNon()}>
                              Annuler
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Annuler"}
                          //  buttonColor={"rgb(174 172 172)"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          {/* <div className={styles.divOui}>
                            <button className={styles.buttonOui} onClick={() => deleteButtonOui()}>
                              Oui, Supprimer
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
  </>}  
  component4={
  <LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
  }
  />}
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
            {
              ( dataUsed && headerTest) && 
                <TableComponent data={dataUsed} cols={headerTest} 
                addAction={true} 
                contentAction={methodsList}
                componentsList={componentsList}
                clickableRow={false}
                idDelete={idTest}
                idModify={idModifier}
                idDelimit={idDelimiter}
                //  condition={showTest}

            />  
            }
            </div>   
                } 
section4={
        <FourthSectionTablePageTablet 
        component1={ 
          <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
          component2={
          <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
        } 
          component3={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }/>

        } 
NbreSections={4} 
prctgeSection1={8}
prctgeSection2={6} 
prctgeSection3={80}
prctgeSection4={6}
/>
</div>
<div className={styles.mobile}>
<GeneralCardTest 
 section1={
<TopMenuPaie page="remuneration"/>
}  
section2={
  <SecondSectionTablePageMobile
  nbreComponents={5}
  component1={<DescriptionPaieComponent/>
  } 
  component2={
    <>
<div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="250px" height="200px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
  <div className={
  ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

    }>
                                          <div className={styles.modern_container}>
                                              <div className={styles.modern_inputs}>
                                              <span className={styles.modern_label}>
                                              Période
                                              </span>
                                              <div class={styles.modern_input_wrapper}>
                                                  <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                  onChange={handleDateDebutChange}
                                                  // {(e) => setDateDebut(e.target.value)}
                                                  />
                                                  <span className={styles.timeSeparator}>-</span>
                                                  <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                              </div>
                                              </div>
                                          </div>
                                          {errorMessage && idModifier &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }
                                            {errorMessage && idDelimiter &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }

                                          <div className={styles.container1}>
                                              <div className={styles.zoneInput}>
                                                  <div className={styles.fileNameTxtStyle1}>
                                                  Salaire de base
                                                  </div>
                                                  {
                                                    idDelimiter ?
                                                    <>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)} disabled/>
                                                    </>:<>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
                                                    </>
                                                  }
                                              </div>
                                          </div>
                                          {/* <div className={styles.btnsubmit2}>
                                              <button type='submit' id={(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? styles.disabledButton : styles.btnRecherche2} onClick={btnEnregistrer}>
                                              <span className={styles.text2}>Enregistrer</span>
                                              </button>         
                                          </div> */}
                                          <div  className={styles.divBtnSubmit}>
                                                {(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                <>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                                :<>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                              }
                                          </div>
                                          

                                  </div>
  </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="250px"
                      height="200px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                        {/* <div className={styles.divNon}>
                            <button className={styles.buttonNon} onClick= {() => deleteButtonNon()}>
                              Annuler
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Annuler"}
                          //  buttonColor={"rgb(174 172 172)"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          {/* <div className={styles.divOui}>
                            <button className={styles.buttonOui} onClick={() => deleteButtonOui()}>
                              Oui, Supprimer
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
              </>

   }
  component3={
     <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>

  }  
  component4={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
  }
  component5={ 
    <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
    } 
  />}
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
    ( dataUsed && headerTest) && 
      <TableComponent data={dataUsed} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={false}
      idDelete={idTest}
      idModify={idModifier}
      idDelimit={idDelimiter}
      //  condition={showTest}

  />  
  }
  </div>   
                } 
section4={
        <FourthSectionTablePageMobile 
          component1={
            <ListColumns listColumn={listRemunerationResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />
        } 
          component2={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }
        />
        } 
NbreSections={4} 
prctgeSection1={7}
prctgeSection2={6} 
prctgeSection3={81}
prctgeSection4={6}
/>
</div>
<div className={styles.mobileInversePetit}>
<GeneralCardTest 
section1={
    <TopMenuPaie page="remuneration"/>
} 
section2={
<SecondSectionTablePageMobileInversePetit 
nbreComponents={5}
component1={<DescriptionPaieComponent/>
} 
component2={
  <>
<div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="300px" height="180px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
  <div className={
  ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

    }>
                                          <div className={styles.modern_container}>
                                              <div className={styles.modern_inputs}>
                                              <span className={styles.modern_label}>
                                              Période
                                              </span>
                                              <div class={styles.modern_input_wrapper}>
                                                  <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                  onChange={handleDateDebutChange}
                                                  // {(e) => setDateDebut(e.target.value)}
                                                  />
                                                  <span className={styles.timeSeparator}>-</span>
                                                  <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                              </div>
                                              </div>
                                          </div>
                                          {errorMessage && idModifier &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }
                                            {errorMessage && idDelimiter &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }

                                          <div className={styles.container1}>
                                              <div className={styles.zoneInput}>
                                                  <div className={styles.fileNameTxtStyle1}>
                                                  Salaire de base
                                                  </div>
                                                  {
                                                    idDelimiter ?
                                                    <>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)} disabled/>
                                                    </>:<>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
                                                    </>
                                                  }
                                              </div>
                                          </div>
                                          {/* <div className={styles.btnsubmit2}>
                                              <button type='submit' id={(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? styles.disabledButton : styles.btnRecherche2} onClick={btnEnregistrer}>
                                              <span className={styles.text2}>Enregistrer</span>
                                              </button>         
                                          </div> */}
                                          <div  className={styles.divBtnSubmit}>
                                                {(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                <>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                                :<>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                              }
                                          </div>
                                          

                                  </div>
  </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="300px"
                      height="180px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                        {/* <div className={styles.divNon}>
                            <button className={styles.buttonNon} onClick= {() => deleteButtonNon()}>
                              Annuler
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Annuler"}
                          //  buttonColor={"rgb(174 172 172)"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          {/* <div className={styles.divOui}>
                            <button className={styles.buttonOui} onClick={() => deleteButtonOui()}>
                              Oui, Supprimer
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
              </>
}

component3={<LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
}
component4={ <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}} />} 
component5={<SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage} />} 
/>

}  
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
 {
    ( dataUsed && headerTest) && 
      <TableComponent data={dataUsed} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={false}
      idDelete={idTest}
      idModify={idModifier}
      idDelimit={idDelimiter}
      //  condition={showTest}

  />  
  }
</div>
                } 
section4={
        <FourthSectionTablePageMobileInversePetit 
        component1={ 
           <ListColumns listColumn={listRemunerationResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}

          component2={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }
        
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div>
<div className={styles.mobileInverseGrand}>
<GeneralCardTest 
section1={
<TopMenuPaie page="remuneration"/>
}
section2={
<SecondSectionTablePageMobileInverseGrand 
nbreComponents={4}
component1={<DescriptionPaieComponent/>
} 
component2={
  <ListColumns listColumn={listRemunerationResult}  setHeader={setHeaderTest} addDefaultColumns={true} listDefaultColumns={headerTest} />}
component3={
  <>
  <div className={styles.inputgroup}  >
          <div  className={styles.creeravenat} style={{display:"flex",width:"100%",height:"100%"}} onClick = {btnAjouter}>
              <div className={styles.zoneComponent}>
                  <div className={styles.text}>
                      <span className={styles.creeravenat_}>
                      Ajouter une nouvelle ligne 
                      </span> 
                  </div>
                  <div className={styles.iconeRight}>
                  {isIconUp ? (
                      <i className="fa-solid fa-caret-up"></i>
                      ) : (
                      <i className="fa-solid fa-caret-down"></i>
                      )}

                  </div>
              </div>
          </div>
  </div>
  <PopupComponentGstTemps onClose={() => {
      setShow(false);
      setIsIconUp(false);
      setIdModifier();
      setIdDelimiter();
  }} show={show} width="350px" height="200px"
  popUpHeader={
    idModifier ? (
        <>
            <i class="fa-solid fa-pencil"></i> &nbsp;Modifier
        </>
    ) : idDelimiter ? (
        <>
            <i class="fa-solid fa-scissors"></i> &nbsp;Délimiter
        </>
    ) : (
        <></>
    )
  }
                                                  
                                                  >
  <div className={
  ((errorMessage && idModifier) || (errorMessage && idDelimiter) ? styles.formSectionAddErrorMsg : styles.formSection)

    }>
                                          <div className={styles.modern_container}>
                                              <div className={styles.modern_inputs}>
                                              <span className={styles.modern_label}>
                                              Période
                                              </span>
                                              <div class={styles.modern_input_wrapper}>
                                                  <input type="date" className={styles.modern_input_left} value={dateDebut} 
                                                  onChange={handleDateDebutChange}
                                                  // {(e) => setDateDebut(e.target.value)}
                                                  />
                                                  <span className={styles.timeSeparator}>-</span>
                                                  <input type="date" className={styles.modern_input_right}  value={dateFin ? dateFin : today} onChange={(e) => setDateFin(e.target.value)} disabled/> 
                                              </div>
                                              </div>
                                          </div>
                                          {errorMessage && idModifier &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }
                                            {errorMessage && idDelimiter &&
                                              <div className={styles.errorMessageStyle}>
                                                {errorMessage}
                                              </div>
                                          }

                                          <div className={styles.container1}>
                                              <div className={styles.zoneInput}>
                                                  <div className={styles.fileNameTxtStyle1}>
                                                  Salaire de base
                                                  </div>
                                                  {
                                                    idDelimiter ?
                                                    <>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInputDisabled} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)} disabled/>
                                                    </>:<>
                                                  <input type="number" id={styles.inputNombreheure} className={styles.goldenInput1} value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
                                                    </>
                                                  }
                                              </div>
                                          </div>
                                          {/* <div className={styles.btnsubmit2}>
                                              <button type='submit' id={(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? styles.disabledButton : styles.btnRecherche2} onClick={btnEnregistrer}>
                                              <span className={styles.text2}>Enregistrer</span>
                                              </button>         
                                          </div> */}
                                          <div  className={styles.divBtnSubmit}>
                                                {(!dateDebut || !salaireBase || (errorMessage && idModifier) || (errorMessage && idDelimiter)) ? 
                                                <>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"#b9b8b8"} buttonTextColor={"grey"} cursorChoice={"not-allowed"}  onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                                :<>
                                                <div className={styles.sectionButtonEnregistrer}> 
                                                  <div></div>
                                                  <ButtonValider textButton={"Enregistrer"} buttonColor={"rgb(218, 165, 32)"} buttonTextColor={"white"} hoverShadowColor="rgba(217, 166, 38, 0.5)" onClickButton={btnEnregistrer}/>
                                                  </div>
                                                </>
                                              }
                                          </div>
                                          

                                  </div>
  </PopupComponentGstTemps>

  {showTest && (
                  <PopupComponentGstTemps
                      onClose={() => {setShowTest(false);setIdTest()}}
                      show={showTest}
                      width="350px"
                      height="200px"
                      popUpHeader={<><i class="fa-solid fa-trash-can"></i> &nbsp; Delete </>}
                  >
                      <div className={styles.popUpDeleteContent}>
                        <div className={styles.firstPartDelete}>
                          <div className={styles.textDelete}>
                          Êtes-vous sûr de vouloir <span className={styles.redText}> &nbsp; Supprimer &nbsp;</span> cette ligne  ?
                          </div>
                        </div>
                        <div className={styles.thirdPartDelete}>
                        {/* <div className={styles.divNon}>
                            <button className={styles.buttonNon} onClick= {() => deleteButtonNon()}>
                              Annuler
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Annuler"}
                          //  buttonColor={"rgb(174 172 172)"}
                            buttonTextColor={"GREY"} hoverShadowColor="#e4e2e2" onClickButton={() => deleteButtonNon()}/>
                          <div></div>
                          {/* <div className={styles.divOui}>
                            <button className={styles.buttonOui} onClick={() => deleteButtonOui()}>
                              Oui, Supprimer
                            </button>

                          </div> */}
                          <ButtonValider textButton={"Oui, Supprimer"} buttonColor={"#f11d21"} buttonTextColor={"white"} hoverShadowColor="rgba(241, 29, 33,0.5)" onClickButton={() => deleteButtonOui()}/>
                        </div>
                      </div>



                </PopupComponentGstTemps>
              )}
  </>
}
component4={ 
  <LineFilterTableInput  valeurRechercheGlobale={valeurRechercheGlobale} OnChangeRechGlobale={e => setValeurRechercheGlobale(e.target.value)}  />
} 
/>

} 
section3={
  <div style={{display:"inline-grid",width:"100%",height:"100%"}} >
  {
    ( dataUsed && headerTest) && 
      <TableComponent data={dataUsed} cols={headerTest} 
      addAction={true} 
      contentAction={methodsList}
      componentsList={componentsList}
      clickableRow={false}
      idDelete={idTest}
      idModify={idModifier}
      idDelimit={idDelimiter}
      //  condition={showTest}

  />  
  }
</div>  
                } 
section4={
        <FourthSectionTablePageMobileInverseGrand  
        component1={ 
          <CheckboxTableInput afficherChecked={afficherChecked} handleChange={() => {if(afficherChecked){setAfficherChecked(false)} else {setAfficherChecked(true); }}}/>
          } 
          component2={
          <SelectNbreLigneTableInput nombrePageFixed={nombrePageValeurFixed} handleOnChange={handleChangeNombrePage}/>
        } 
          component3={
          <PaginationTable page={page} setPage={setPage} nombrePageArray={nombrePageArray_}  />
        }
        /> 
        } 
NbreSections={4} 
prctgeSection1={10}
    prctgeSection2={9} 
    prctgeSection3={72}
    prctgeSection4={9}
/>
</div> 
</div>
    )
}

export default RemunerationSection;