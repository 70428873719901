import React from "react";
import NavBarGlobal from "../global/NavBar/NavBarGlobal/NavBarGlobal.jsx";
import LangueAdministrationsSection from './LangueAdministrationsSection.jsx';



export const LangueAdministrations = () => {


    
  return (
  <div>

    <NavBarGlobal section = {<LangueAdministrationsSection />} />
    
   
 </div>
)}


export default LangueAdministrations
